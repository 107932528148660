import { axiosInstance } from '../config'

//问诊医生列表
export const getdoctorlist = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/docterlist', data)
}
//修改用户信息
export const changeuser = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/patient-update', data)
}
// 查询医生接诊次数	
export const getDocjznum = (docterId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/get-docjznum?docterId=${docterId}`)
}
// 查询医生当日接诊次数
export const getDayDocjznum = (docterId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/get-day-docjznum?docterId=${docterId}`)
}
//保存视频问诊视频时长
export const savevideoTime = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/save-video-time`,data)
}