import React, { Component } from 'react'
import { Modal,  } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./style.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon3 from "../../../static/img/yy/five.png"
import { prescribed } from '../../../api/hospitalapi/chat'
import { oneDocpj, gzDoc, getGzdoc } from "../../../api/hospitalapi/user"
import { getdoctorlist } from "../../../api/hospitalapi/home"
import Nodata from "../../../component/Nodata";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientId: localStorage.getItem("patientId"),
            docList: [],
            list: [],
            timeNow:''
        }
    }
    componentDidMount = () => {
        const { patientId, docList } = this.state
        let date=new Date()
        let hour=date.getHours()
        let min=date.getMinutes()
        // console.log(hour*60+min)
        this.setState({
            timeNow: hour*60+min
        })
        if (!patientId) {
            Toast.show({
                content: "当前未登录，请先登录",
                duration: 1000
            })
            this.props.history.push("/login")
        } else {
            getGzdoc({ patientId }).then(res => {
                // console.log(res);
                let arr = []
                if (res.code == 1) {
                    let a = res.data[0][0].gzDocterId.split(",")
                    // console.log(a);
                    this.setState({
                        docList: a
                    })
                    getdoctorlist().then(res1 => {
                        // console.log(res1);
                        if (res1.code == 1) {
                            for (let i = 0; i < res1.data[0].length; i++) {
                                for (let j = 0; j < a.length; j++) {
                                    if (res1.data[0][i].docterId == parseInt(a[j])) {
                                        arr.push(res1.data[0][i])
                                    }

                                }
                            }
                        }
                        // console.log(arr);
                        this.setState({
                            list: arr
                        })
                    })
                }
            })
        }
    }
    toinfo=(item)=>{
        const { list,timeNow } = this.state
        if (item.docterStartDisturb&&(timeNow>=(parseInt(item.docterStartDisturb.split(':')[0]*60)+parseInt(item.docterStartDisturb.split(':')[1]))&&
            item.docterEndDisturb&&(timeNow<=parseInt(item.docterEndDisturb.split(':')[0]*60)+parseInt(item.docterEndDisturb.split(':')[1]))&&item.docterDisturb==1)
        ){
            Toast.info('医生休息中，无法进行咨询')
        }else {
            localStorage.setItem("item", JSON.stringify(item))
            this.props.history.push(`/doctorinfo?gzDocter=${1}`)
        }
    }
    render() {
        const { list,timeNow } = this.state
        return (
            <div className='setbox'>
                <div className='box-top box-top2'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine")} />   关注医生
                </div>
                <div className='setprescribed-outer'>
                {list.length?list.map(item => (
                    <div className='setprescribed' onClick={()=>this.toinfo(item)}>
                        <div className='info-1'>
                            <div className='box-container'>
                                <div className='container-box'>
                                    <img style={{ borderRadius: "50%" }} src={item.dcocterAvatar} alt="" />
                                    <div className='box-text'>
                                        <div>
                                            <p className='box-name'>
                                                <span>{item.docterName}</span><span>{item.title}</span><span>{item.depName}</span>
                                            </p>
                                            <div className='box-title'>
                                                {item.docterStartDisturb&&(timeNow>=(parseInt(item.docterStartDisturb.split(':')[0]*60)+parseInt(item.docterStartDisturb.split(':')[1]))&&
                                                    item.docterEndDisturb&&(timeNow<=parseInt(item.docterEndDisturb.split(':')[0]*60)+parseInt(item.docterEndDisturb.split(':')[1]))&&item.docterDisturb==1)?
                                                    <span style={{border:'1px solid #999',padding:'2px 5px',color:'#999'}}>休息中</span>:null} <span className='yy'>{item.hospital}</span>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='box-evaluate'>
                                <div>
                                    <img src={icon3} alt="" /> <span style={{ color: '#1F87FF' }}>4.8 </span><span> / </span>
                                </div>
                                <div>
                                    <span>接诊次数 </span> <span style={{ color: '#1F87FF' }}>{item.countNum} </span><span> / </span>
                                </div>

                                <div>
                                    <span>回复速度:</span> <span style={{ color: '#1F87FF' }}>较快</span>
                                </div>
                            </div>
                            <div className='box-introduce'>
                                <span>专业擅长：</span> {item.expert}
                            </div>
                        </div>
                    </div>)):<Nodata type='医生'/>}
                </div>
            </div>
        )
    }
}
