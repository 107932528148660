import React, { Component } from 'react'
import { Modal, } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./healthcard.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/jiankangka.png"
import {getAll} from '../../../api/hospitalapi/electronic'
/*
*  健康卡
* */

export default class healthcard extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:[]
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        getAll({patientId:userId}).then(res=>{
            // console.log(res)
            if (res&&res.data&&res.data[0].length){
                this.setState({
                    list:res.data[0]
                })
            }
        })
    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification=()=>{
        const {value1,value2} =this.state
        // console.log(value1,value2);
    }
    changevalue=(e,code)=>{
        if (code==1) {
            this.setState({
                value1:e.target.value
            })
        }else{
            this.setState({
                value2:e.target.value
            })
        }
    }
    tolook=(consultationId,prescribingId)=>{
        localStorage.setItem('detailContent0',prescribingId)
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo",
            params: {
                yktext: 1,
                detailContent:consultationId,
            }
        })
    }
    showNews=(id)=>{
        this.props.history.push(`/healthcardnews?id=${id}`)
    }
    addCard=()=>{
        const { list} = this.state
        if (list.length==0||list.length<5){
            this.props.history.push("/healthcard2")
        }else{
            Toast.info('每个用户最多申请5张电子健康卡')
        }
    }
    goBack=()=>{
        // window.history.back()
        this.props.history.push("/")
    }
    render() {
        const { list} = this.state
        return (
            <div className='setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.goBack()} />   电子健康卡
                </div>
                <div className='healthcard-outer'>
                    {list.length?list.map(item=>(<div className='dz-jiankangka' onClick={()=>this.showNews(item.id)}>
                        {/*<img src={icon3}/>*/}
                        <p className='dz-jiankangka-name'>{item.patientName}（电子健康卡）</p>
                        <p className='dz-jiankangka-hos'>中江民瑞医院</p>
                        <p className='dz-jiankangka-id'>{item.idCard}</p>
                    </div>)):null}
                </div>
                <div className='add-jiankangka1' onClick={() => this.addCard()}>
                    <img src={icon2}/>
                    <span>电子健康卡</span>
                </div>
            </div>
        )
    }
}
