import React ,{ useState, useEffect } from 'react'
import kefu from '../../../static/img/kefu.png'
import you from '../../../static/img/you.png'
import defaultImg from '../../../static/img/default.png'
import sure from '../../../static/img/sure.png'
import './style.less'

export default function Agree(props){
    const cartArr1 = props.location.state
    const pharmacyName1 = props.location.pharmacyName

    let cartArr;  //先定义一个存储参数的变量
    let pharmacyName;
    if(pharmacyName1&&cartArr1){  //判断是否有参数
        cartArr=cartArr1.medList;
        pharmacyName=pharmacyName1;
        localStorage.setItem('cartArr',JSON.stringify(cartArr));
        localStorage.setItem('pharmacyName', pharmacyName);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        cartArr =JSON.parse(localStorage.getItem('cartArr')); 
        pharmacyName = localStorage.getItem('pharmacyName');//当state没有参数时，取sessionStorage中的参数
    }
    const backPage=()=>{
        window.history.back()
    }

    const toMedicine = (medId) => {
        props.history.push(`/med/${medId}`)
    }

    let total=0;
    cartArr&&cartArr.map((item, index) => (
        total+=item.medPrice/100*item.medQuantity
    ))
    
    return(
        <>
            <div className='header1'>
                <div className='drawback-header'>
                    <div className='back-icon flexr' onClick={backPage}>
                        <i className='icon-xiangzuo iconfont'></i>
                    </div> 
                    <div className='order-kefu'>
                        <img src={kefu} alt="" />
                    </div>
                </div>
                <p className='drawback'>商家同意退款<img className='jujue-img' src={you} alt="" /></p>
                <div className='teshu-drawback'>
                    <p className='teshu-drawback-title'>请选择退货方法</p>
                    <p className='teshu-drawback-explain'>商家收到退回商品后才能将退款返还至您的平台账户中</p>
                    <div className='xian'></div>
                    <p className='teshu-drawback-select'>
                        <input type="radio" name='a' />
                        <span>委托配送骑手退货（需支付骑手相应费用）</span>
                    </p> 
                    <div className='xian'></div>
                    <p className='teshu-drawback-select'>
                        <input type="radio" name='a' />
                        <span>自己到购买药店退货</span>
                    </p> 
                </div>
                <div className='pharmacy'>
                    {/*  onClick={() => props.history.push(`/shop/${orderDetail.pharmacyId}`)} */}
                    <div>
                        <span className='phname'>{pharmacyName}</span>
                        <i className='iconfont icon-xiangyou'></i>
                    </div>
                    <div>
                    {cartArr && cartArr.map((item, index) => (
                       <div className='pharmacy-name'>
                          <div key={index} className='order-meditem flex-apart-row'
                            onClick={()=>toMedicine(item.medId)}>
                                <div className='flexr'>
                                    <img src={item.medPhoto?item.medPhoto:defaultImg} alt=''
                                    onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                                    <div className='flexr-right'>
                                        <p>
                                            <span className='name'>
                                                {item.medClassify==='1'?<span className='chufang'>处方药</span>:null}
                                                {item.medName}
                                            </span>
                                            <span className='price'>
                                                {/* {item.originPrice?<span>￥{item.originPrice/100}</span>:null} */}
                                                <span style={{marginRight:'10px',textDecoration:"line-through",color:'#999'}}>￥30.00</span>
                                                ￥{item.medPrice/100}
                                            </span>
                                        </p>
                                        <p className='guige'>{item.medSpec}</p>
                                        <span className='money'>x{item.medQuantity}</span>
                                    </div>
                                    
                                </div>
                                {item.length>1&&index!=item.length-1?<div className='xian'></div>:null}
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className='xian'></div>
                    <div className='all-select'>
                        <p></p>
                        <p>
                            <span>退款金额：</span>
                            ￥<span className='total' style={{marginRight:'20px'}}>{total}</span>
                        </p>
                    </div>
                </div>
                <div className='sure-bottom'>
                    <img src={sure} alt="" />
                    <span>确定</span>
                </div>
            </div>
        </>
    )
}