import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import { Modal } from "antd-mobile-v5"
import "./healthcardnews.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/jiankangka.png"
import {getAll,healthClinic,healthClinicAdd,isClinic,refreshCard,allHealthclinic,hisToArchives,havenameClinic} from '../../../api/hospitalapi/electronic'
import getUrl from '../../../component/GetUrlParam'
import {jumpUrl} from "../../../publicurl"
import {Toast} from "antd-mobile";

/*
*  挂号
* */

export default class healthcardnews extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:'',
            id:'',
            visible:false,
            clinicId:'',
            idCard:'',
            clinicList:'',
            name:''
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        this.setState({
            id:getUrl('id', this.props.location.search)
        },()=>{
            getAll({patientId:userId, id:getUrl('id', this.props.location.search)
            }).then(res=>{
                // console.log(res)
                if (res&&res.data&&res.data[0].length){
                    this.setState({
                        list:res.data[0][0]
                    })
                }
            })
            this.toAllclinic()
        })
    }
    //查询所有已绑定就诊卡
    toAllclinic=async ()=>{
        let res=await allHealthclinic({healthId:this.state.id})
        // console.log(res)
        if (res&&res.data&&res.data[1]){
            this.setState({
                clinicList:res.data[1]
            })
        }else{
            this.setState({
                clinicList:''
            })
        }
    }
    tolook=(consultationId,prescribingId)=>{
        localStorage.setItem('detailContent0',prescribingId)
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo",
            params: {
                yktext: 1,
                detailContent:consultationId,
            }
        })
    }
    //绑定就诊卡
    toAddhealthclinic=async ()=>{
        const { clinicId,idCard,id,name,userId } = this.state
        if(idCard&&name){
            let res1=await hisToArchives(
                idCard,
                name
            )
            // console.log(res1)
            // let res1=await isClinic({
            //     cardNumber:clinicId,
            //     idCard:idCard
            // })
            if (res1&&res1.data&&res1.data[0].length){
                let res2=await healthClinic({
                    hisNumber:res1.data[0][0].编号,
                    healthId:id
                })
                // console.log(res2)
                if (res2&&res2.data&&res2.data[1]&&res2.data[1][0].sumCount1==0) {
                    let res4=await havenameClinic({
                        patientName:name,
                        cardNumber:res1.data[0][0].卡片编码,
                        hisNumber:res1.data[0][0].编号,
                        patientId:userId,
                        idCard:idCard
                    })
                    // console.log(res4)
                }
                if (res2&&res2.data&&res2.data[0]&&res2.data[0][0].sumCount==0){
                    healthClinicAdd({hisNumber:res1.data[0][0].编号,
                        healthId:id}).then(res3=>{
                        // console.log(res3)
                        if (res3&&res3.code==1){
                            this.setState({
                                visible:false
                            },()=>{
                                Toast.info('就诊卡绑定成功')
                                this.toAllclinic()
                            })
                        }
                    })
                }else{
                    Toast.info('就诊卡已绑定，请勿重复操作')
                }
            }else{
                Toast.info('就诊卡不存在，请检查信息输入是否正确')
            }
        }else{
            Toast.info('请填写完整就诊卡信息')
        }
    }
    //取消绑定
    toRefund=()=>{
        this.setState({
            visible:false
        })
    }
    //刷新就诊卡
    toRefresh=async (item)=>{
        const { clinicId,idCard,id,name,userId } = this.state
        let res1=await hisToArchives(
            item.id_card,
            item.patient_name
        )
        // console.log(res1)
        if (res1&&res1.data&&res1.data[0].length){
            if (res1.data[0][0].卡片编码==item.card_number){

            }else{
                refreshCard({id:item.id,cardNumber:res1.data[0][0].卡片编码}).then(res=>{
                    // console.log(res)
                    this.toAllclinic()
                })
            }
        }
        // console.log(item)
    }
    // toRefund0=()=>{
    //     this.setState({
    //         visible:false
    //     })
    // }
    // toNorefund=(e)=>{
    //     e.stopPropagation()
    //
    // }
    changeValue=(i,v)=>{
        // console.log(v.target.value)
        if (i=='clinic'){
            this.setState({
                clinicId:v.target.value.replace(/\s*/g,""),
            })
        }else if(i=='idCard'){
            this.setState({
                idCard:v.target.value.replace(/\s*/g,"")
            })
        }else if(i=='name'){
            this.setState({
                name:v.target.value.replace(/\s*/g,"")
            })
        }
    }
    render() {
        const { list,visible,clinicList } = this.state
        return (
            <div className='setbox healthcardnews-outer'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/healthcard")} />   电子健康卡信息
                </div>
                <div className='jkk-ewm'>
                    <QRCode
                        id="qrCodeDa"
                        // value={`http://h5.sczhongyuan.net/index.html#/login?userId=${userId}`}
                        value={jumpUrl+`/index.html#/lookhealthcard?healthCardNumber=${list.healthCardNumber}`}
                        // value={`http://h5.sczhongyuan.net/index.html#/login/loginyw`}
                        fgColor="#000000" //二维码的颜色
                    />
                </div>
                <p className='show-ewm'>就诊时出示此二维码</p>
                <div className='one-news'>
                    <p>
                        <span>姓名</span>
                        <span>{list.patientName}</span>
                    </p>
                    <p>
                        <span>身份证号</span>
                        <span>{list.idCard}</span>
                    </p>
                    <p>
                        <span>手机号</span>
                        <span>{list.phone1}</span>
                    </p>
                </div>
                <div className='jzk' onClick={()=>this.setState({
                    visible:true
                })}>绑定就诊卡</div>
                {clinicList?clinicList.map(item=>(<div className='dz-jiuzhenka' onClick={()=>this.toRefresh(item)}>
                    {/*<img src={icon3}/>*/}
                    <p className='dz-jiankangka-name'>
                        <span>{item.patient_name}（就诊卡）</span>
                        {item.id_card==list.idCard1?<span>主卡</span>:<span></span>}
                        </p>
                    <p className='dz-jiankangka-hos'>编号 {item.his_number}</p>
                    {item.card_number?<p className='dz-jiankangka-id'>就诊卡号 {item.card_number}</p>:
                        <p className='dz-jiankangka-id'>暂无就诊卡号，可去实体医院申领</p>}
                </div>)):null}
                {visible?<div className='bangding-out' >
                    <div className='bangding-inner'>
                        <h3>就诊卡信息</h3>
                        <p>
                            <span>
                                姓名
                            </span>
                            <input placeholder='请输入就诊卡姓名' onChange={(v)=>this.changeValue('name',v)}/>
                        </p>
                        {/*<p>*/}
                        {/*    <span>*/}
                        {/*        就诊卡号*/}
                        {/*    </span>*/}
                        {/*    <input placeholder='请输入就诊卡号' onChange={(v)=>this.changeValue('clinic',v)}/>*/}
                        {/*</p>*/}
                        <p>
                            <span>
                                身份证号
                            </span>
                            <input placeholder='请输入身份证号' onChange={(v)=>this.changeValue('idCard',v)}/>
                        </p>
                        <div className='bottom'>
                            <p onClick={()=>this.toRefund()}>取消绑定</p>
                            <p onClick={()=>this.toAddhealthclinic()}>确认绑定</p>
                        </div>
                    </div>
                </div>:null}
            </div>
        )
    }
}
