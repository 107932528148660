import React, {Component} from 'react';
import SignatureCanvas from 'react-signature-canvas'
import {imgToCanvas, rotateBase64Img, dealImage, dealParams,getBase64} from '../../../api/config'
import {updateStaff,selectPhaid,setStaffsi,selectPharmacistId,setPharmacistSi,updatePharmacistSi} from '../../../api/hospitalapi/offline'
import { Empty } from 'antd-mobile-v5'
import search from "../../../static/img/home/search1.png";
import icon2 from "../../../static/img/icon/false1.png";
import icon3 from "../../../static/img/icon/qm-update.png";
import icon4 from "../../../static/img/icon/tianjia.png";
import fhhome from "../../../static/img/icon/fhhome.png";
import icon9 from '../../../static/img/yy/qianming.png';
import icon5 from "../../../static/img/icon/cx.png";
import {ImagePicker, Toast,Popover,Button } from "antd-mobile"
import {uploaddoctor, uploadpic} from "../../../api/hospitalapi/upload"
import {getBase64Image} from '../../../api/config'
import {zipImg} from '../../../component/zipImage'
import getUrl from "../../../component/GetUrlParam";
import './style.less'
let isClick = false
export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            pharmacyId: localStorage.getItem("pharmacyId"),
            trimmedDataURL: null,
            img:'',
            pha_staff:localStorage.getItem("pha_staff")
            ? localStorage.getItem("pha_staff")
            : "",
            // qmType:1,
            pha_pharmacist:localStorage.getItem("pha_pharmacist")
            ? localStorage.getItem("pha_pharmacist")
            : "",
            isButtonClicked: false,
        }
    }

    componentDidMount = async () =>{
        if (getUrl("qmType", this.props.location.search)) {
            this.setState({
                qmType: getUrl("qmType", this.props.location.search),
            });
        }
        if (getUrl("id", this.props.location.search)) {
            this.setState({
                id: getUrl("id", this.props.location.search),
            });
        }
    }


    handleInputChange = (e, arrayName) => {
        let value = e.target.value.trim().replace(/[^\u4e00-\u9fa5a-zA-Z]+/g, '');
        // .replace(/[^\u4e00-\u9fa5a-zA-Z]+/g, '')
        this.setState({ [arrayName]: [value] }, () => {
          localStorage.setItem(arrayName, value);
        });
    };


    

    trim = () => {
        if (isClick) {
            return; // 在节流期间内已触发过保存操作，直接返回，不执行保存操作
        }

        const { pha_staff } = this.state
        let aaa = this.sigCanvas.isEmpty();
        let img0=''
        let img1=''

        // console.log( this.sigCanvas.getTrimmedCanvas().toDataURL("image/png"),'31===============');
        if (aaa){
            Toast.info('请先签名')
        }else{
            // debugger
            this.setState({
                trimmedDataURL: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
            },()=>{
                let res0= this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
                // console.log(res0,'55=============');
                //顺时针旋转90度
                let res1=rotateBase64Img(res0,90,callback)
                // console.log(res1,'58============');
                function callback(val){
                    img1=val
                }
                setTimeout(()=>{
                    let a=dealImage(img1,callback1)
                    // console.log(a)
                    function callback1(val) {
                        //base64转png图片
                        let res2= imgToCanvas(val)
                        let file=res2
                        // if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                        zipImg(file).then(res => {
                            // console.log(res)
                            let formData = new FormData()
                            formData.append('file', res)
                            // console.log(formData)
                            uploadpic(formData).then(res1 => {
                                // console.log(res1,'74======')
                                if(res1&&res1.code == 2) {
                                    img0=res1.data
                                }
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                },500)
                setTimeout(()=>{
                    // console.log(img0,'71=======')
                    if (img0) {
                        let staff_img0=''
                        getBase64(img0, async (value) => {
                            // console.log(value)
                            staff_img0 = value.split(',')[1]
                            if (staff_img0){
                                this.props.history.push(`/signaturedetails?qmType=${1}&id=${getUrl("id", this.props.location.search)}&img0=${img0}`)
                            }
                        })

                    }
                },1000)

            });

        }

        isClick = true;
        setTimeout(() => {
            isClick = false; // 节流时间过后，将 isClick 设为 false，可以再次触发保存操作
        }, 500); // 3000毫秒为节流的时间间隔，根据需要进行调整
    };


    trims = () => {
        if (isClick) {
            return; // 在节流期间内已触发过保存操作，直接返回，不执行保存操作
        }
        const { pha_pharmacist } = this.state
        let aaa = this.sigCanvas.isEmpty();
        let img0=''
        let img1=''

        if (aaa){
            Toast.info('请先签名')
        }else{
            this.setState({
                trimmedDataURL: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
            },()=>{
                let res0= this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
                //顺时针旋转90度
                let res1=rotateBase64Img(res0,90,callback)
                function callback(val){
                    img1=val
                }
                setTimeout(()=>{
                    let a=dealImage(img1,callback1)
                    // console.log(a)
                    function callback1(val) {
                        //base64转png图片
                        let res2= imgToCanvas(val)
                        let file=res2
                        // if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                        zipImg(file).then(res => {
                            // console.log(res)
                            let formData = new FormData()
                            formData.append('file', res)
                            // console.log(formData)
                            uploadpic(formData).then(res1 => {
                                // console.log(res1,'74======')
                                if(res1&&res1.code == 2) {
                                    img0=res1.data
                                }
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                },500)
                setTimeout(()=>{
                    // console.log(img0,'229=======')
                    if (img0) {
                        let staff_img0=''
                        getBase64(img0, async (value) => {
                            // console.log(value)
                            staff_img0 = value.split(',')[1]
                            if (staff_img0){
                                this.props.history.push(`/signaturedetails?qmType=${2}&id=${getUrl("id", this.props.location.search)}&img0=${img0}`)
                            }
                        })

                    }
                },1000)

            });
        }

        isClick = true;
        setTimeout(() => {
            isClick = false; // 节流时间过后，将 isClick 设为 false，可以再次触发保存操作
        }, 500); // 3000毫秒为节流的时间间隔，根据需要进行调整
        
    };


    render() {
       let { trimmedDataURL,qmType }= this.state
    //    console.log(qmType,'159=======');
        return (
            <div >
                <div className='home-header-addmed-outer'>
                    <div className='top-tit'>
                        <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                        <span className='title-middle'>手写签名</span>
                    </div>


                    {qmType == 2 && (
                        <>
                            <div className='middle-con'>
                                <div className='qm-tit'>
                                    <img src={icon9}/>
                                </div>
                                <div className='qianmingban'>
                                    <div>
                                        <SignatureCanvas
                                            penColor="#000"  // 笔刷颜色
                                            minWidth={3} // 笔刷粗细
                                            maxWidth={5}
                                            ref={(ref) => { this.sigCanvas = ref }} //用于调用插件的API
                                            canvasProps={{
                                                width: window.outerWidth*.7,
                                                height: window.outerHeight*.8, 
                                                style:{
                                                    position:'absolute',
                                                    top:'5vh',
                                                    left:'10vw',
                                                    backgroundColor:'#F3F3F3', 
                                                },
                                                className: 'sigCanvas'}} />
                                    </div>    
                                </div>
                                <div className='xiayibu-qc'>
                                    <p className='xiayibu' 
                                        onClick={this.trims} 
                                    >
                                        下一步
                                    </p>
                                    <p className='qc' onClick={()=>this.sigCanvas.clear()}>清除</p>
                                </div>
                            </div>
                        </>
                    )}

                    {qmType == 1 && (
                        <>
                            <div className='middle-con'>
                                <div className='qm-tit'>
                                    <img src={icon9}/>
                                </div>
                                <div className='qianmingban'>
                                    <div>
                                        <SignatureCanvas
                                            penColor="#000"  // 笔刷颜色
                                            minWidth={3} // 笔刷粗细
                                            maxWidth={5}
                                            ref={(ref) => { this.sigCanvas = ref }} //用于调用插件的API
                                            canvasProps={{
                                                width: window.outerWidth*.7,
                                                height: window.outerHeight*.8, 
                                                style:{
                                                    position:'absolute',
                                                    top:'5vh',
                                                    left:'10vw',
                                                    backgroundColor:'#F3F3F3', 
                                                },
                                                className: 'sigCanvas'}} />
                                    </div>    
                                </div>
                                <div className='xiayibu-qc'>
                                    <p className='xiayibu' 
                                        onClick={this.trim} 
                                    >
                                        下一步
                                    </p>
                                    <p className='qc' onClick={()=>this.sigCanvas.clear()}>清除</p>
                                </div>
                            </div>
                        </>
                    )}




                    
                    
                </div>
            </div>
            
        );
    }
}

