import { axiosInstance } from '../config'
// 查询用户所有电子健康卡信息
export const getAll = (patientId,id,healthCardNumber) => {
    return axiosInstance.get(`/api/hosp/electronic/get-all?patientId?=${patientId}&id?=${id}&healthCardNumber?=${healthCardNumber}`)
}
//预约挂号医生信息
export const getAllregister = (data) => {
    return axiosInstance.post('/api/hosp/register-record/get-allregister', data)
}
//挂号医生详情
export const hospitaldocterDetails = (data) => {
    return axiosInstance.post('/api/hosp/register-record/hospitaldocter-details', data)
}
//预约挂号
export const addPayment = (data) => {
    return axiosInstance.post('/api/hosp/register-record/add-payment', data)
}
//修改挂号状态
export const changeStatus = (data) => {
    return axiosInstance.post('/api/hosp/register-record/change-status', data)
}
//查询预约详情
export const oneRecordDetails = (outpatientNumber) => {
    return axiosInstance.get(`/api/hosp/register-record/one-record-details?outpatientNumber=${outpatientNumber}`)
}
//取消预约
export const quxiaoRecord = (data) => {
    return axiosInstance.post('/api/hosp/register-record/quxiao-record', data)
}
//查询用户一个月预约详情
export const monthRecordDetails = (data) => {
    return axiosInstance.post('/api/hosp/register-record/month-record-details', data)
}
//查询医生某天挂号剩余数量
export const lastNum = (data) => {
    return axiosInstance.post('/api/hosp/register-record/last-num', data)
}
//支付成功修改医生可预约数量
export const changeNum = (data) => {
    return axiosInstance.post('/api/hosp/register-record/change-num', data)
}
//订单支付成功修改状态
export const payUpdateState= (data) => {
    return axiosInstance.post('/api/hosp/Consultation/pay_update_state', data)
}
// 自提订单保存字体码
export const zitiNum=(data) => {
    return axiosInstance.post('/api/hosp/Consultation/ziti_num', data)
}