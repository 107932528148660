import React, { Component } from 'react'
import "./style.less"
import { Form, Input, Button, TextArea } from "antd-mobile-v5"
import icon1 from "../../static/img/yy/fanhuihei.png"
import { Toast,InputItem } from 'antd-mobile'
import {adduserinfo,changeuserinfo,getMemberNews} from "../../api/hospitalapi/user"
import {addElectronicIdcard, isElectronic} from "../../api/hospitalapi/electronic";
import {getProvince,getCity,getDistrict} from "../../api/request/qualifications";
import getUrl from '../../component/GetUrlParam'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            name: "",
            name0: 0,
            sex: "",
            date: "",
            city: "",
            city1: "",
            city0: 0,
            phone: "",
            phone0: 0,
            pasthistory: "",
            pasthistory0:0,
            allergichistory: "",
            allergichistory0: 0,
            familyhistory: "",
            familyhistory0: 0,
            liver: "",
            kidney: "",
            pasthistorycode: false,
            allergichistorycode: false,
            familyhistorycode: false,
            pasthistorycode0: 0,
            allergichistorycode0: 0,
            familyhistorycode0: 0,
            livercode: false,
            kidneycode: false,
            livercode0: 0,
            kidneycode0: 0,
            patientId:localStorage.getItem("patientId"),
            list:'',
            idcard:'',
            idcard0:0,
            guardianName:'',//监护人姓名（患者年龄小于6岁，需填此项）
            guardianName0:0,
            guardianIdcardNum:'',//监护人身份证号（患者年龄小于6岁，需填此项）
            guardianIdcardNum0:0,
            age:6,
            memberId:'',
            province:'',
            county:'',
            provinces:'',
            cities:'',
            counties:'',
            parentId1:'',
            parentId2:'',
            cityId:''
        }
    }
    componentDidMount = async () => {
        if (getUrl('memberId', this.props.location.search)){
            this.setState({
                memberId:getUrl('memberId', this.props.location.search)
            })
            let res=await getMemberNews(getUrl('memberId', this.props.location.search))
            if (res&&res.data&&res.data[0]){
                let b=res.data[0][0].patient_birthday
                let a=(new Date(b)).getFullYear()+'-'+(((new Date(b)).getMonth()+1)<10?'0'+((new Date(b)).getMonth()+1):((new Date(b)).getMonth()+1))+'-'+((new Date(b)).getDate()<10?'0'+(new Date(b)).getDate():(new Date(b)).getDate())
                this.setState({
                    list:res.data[0][0],
                    province:res.data[0][0].patient_province,
                    city1:res.data[0][0].patient_cities,
                    county:res.data[0][0].patient_county,
                    cityId:res.data[0][0].city_id,
                    date:a
                },()=>{
                    // 将出生日期的字符串通过"-"分割成数组
                    const strBirthdayArr = a.split("-")
                    // 拿到出生日期的年
                    const birthYear = strBirthdayArr[0]
                    // 拿到出生日期的月
                    const birthMonth = strBirthdayArr[1]
                    // 拿到出生日期的日
                    const birthDay = strBirthdayArr[2]
                    // 创建一个时间对象
                    const d = new Date()
                    // 拿到当前时间的年
                    const nowYear = d.getFullYear()
                    // 拿到当前时间的月
                    const nowMonth = d.getMonth() + 1
                    // 拿到当前时间的日
                    const nowDay = d.getDate()
                    // 如果出生日期的年等于当前时间的年
                    if(nowYear === birthYear){
                        this.setState({
                            age:0
                        })
                        return 0 // 返回周岁年龄 0,并终止函数执行
                    }
                    // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
                    const ageDiff = nowYear - birthYear ; // 年之差
                    // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
                    // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
                    // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
                    if(nowMonth != birthMonth){
                        // 拿到出生日期的日与当前时间的月之差
                        const monthDiff = nowMonth - birthMonth; // 月之差
                        // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
                        this.setState({
                            age:monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                        })
                        return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    }
                    // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
                    const dayDiff = nowDay - birthDay;
                    // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
                    this.setState({
                        age:dayDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    })
                    return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
                })
            }
        }
        getProvince().then(res=>{
            if (res&&res.data&&res.data[0]){
                this.setState({
                    provinces:res.data[0]
                },()=>{
                    res.data[0].map(item=>{
                        if (item.name===this.state.province) {
                            this.setState({
                                parentId1:item.id,
                                parentId2:'',
                                counties:'',
                                cityId:item.id,
                            },()=>{
                                let params={parentId:item.id}
                                getCity(params).then(res=>{
                                    this.setState({
                                        cities:res.data[0]
                                    },()=>{
                                        res.data[0].map(item=>{
                                            if (item.name===this.state.city1) {
                                                this.setState({
                                                    parentId2:item.id,
                                                    parentId1:'',
                                                    cityId:item.id,
                                                },()=>{
                                                    getDistrict({parentId:item.id}).then(res=>{
                                                        this.setState({
                                                            counties:res.data[0]
                                                        })
                                                    })
                                                })
                                            }
                                        })
                                    })
                                })
                            })
                        }
                    })
                })
            }
        })
    }
    onFinish = (value) => {
        // console.log(value);
    }
    setvalue = (e, type) => {
        switch (type) {
            case "pasthistory":
                if (e.target.value) {
                    this.setState({
                        pasthistory: e.target.value,
                        pasthistory0:2
                    })
                }else{
                    this.setState({
                        pasthistory: e.target.value,
                        pasthistory0:1
                    })
                }
                break;
            case "allergichistory":
                if (e.target.value) {
                    this.setState({
                        allergichistory: e.target.value,
                        allergichistory0:2
                    })
                }else{
                    this.setState({
                        allergichistory: e.target.value,
                        allergichistory0:1
                    })
                }
                break;
            case "familyhistory":
                if (e.target.value) {
                    this.setState({
                        familyhistory: e.target.value,
                        familyhistory0:2
                    })
                }else{
                    this.setState({
                        familyhistory: e.target.value,
                        familyhistory0:1
                    })
                }
                break;
            case "liver":
                this.setState({
                    liver: e.target.value
                })
                break;
            case "kidney":
                this.setState({
                    kidney: e.target.value
                })
                break;
            case "name":
                if (e.target.value) {
                    this.setState({
                        name: e.target.value,
                        name0:2
                    })
                    
                }else{
                    this.setState({
                        name: e.target.value,
                        name0:1
                    })
                }
                break;
            case "guardianName":
                if (e.target.value) {
                    this.setState({
                        guardianName: e.target.value,
                        guardianName0:2
                    })

                }else{
                    this.setState({
                        guardianName: e.target.value,
                        guardianName0:1
                    })
                }
                break;
            case "sex":
                    this.setState({
                        sex: e.target.value,
                    })
                break;
            case "date":
                let b=e.target.value.replaceAll("-", "")
                let a=(new Date()).getFullYear()+'-'+(((new Date()).getMonth()+1)<10?'0'+((new Date()).getMonth()+1):((new Date()).getMonth()+1))+'-'+((new Date()).getDate()<10?'0'+(new Date()).getDate():(new Date()).getDate())
                let c=a.replaceAll("-", "")

                if (b>c) {
                    this.setState({
                        date: a,
                        age:0
                    })
                    Toast.info('请选择正确的出生日期')
                }else{
                    // 将出生日期的字符串通过"-"分割成数组
                    const strBirthdayArr = e.target.value.split("-")
                    // 拿到出生日期的年
                    const birthYear = strBirthdayArr[0]
                    // 拿到出生日期的月
                    const birthMonth = strBirthdayArr[1]
                    // 拿到出生日期的日
                    const birthDay = strBirthdayArr[2]
                    // 创建一个时间对象
                    const d = new Date()
                    // 拿到当前时间的年
                    const nowYear = d.getFullYear()
                    // 拿到当前时间的月
                    const nowMonth = d.getMonth() + 1
                    // 拿到当前时间的日
                    const nowDay = d.getDate()
                    // 如果出生日期的年等于当前时间的年
                    if(nowYear === birthYear){
                        this.setState({
                            date: e.target.value,
                            age:0
                        })
                        return 0 // 返回周岁年龄 0,并终止函数执行
                    }
                    // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
                    const ageDiff = nowYear - birthYear ; // 年之差
                    // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
                    // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
                    // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
                    if(nowMonth != birthMonth){
                        // 拿到出生日期的日与当前时间的月之差
                        const monthDiff = nowMonth - birthMonth; // 月之差
                        // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
                        this.setState({
                            date: e.target.value,
                            age:monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                        })
                        return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    }
                    // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
                    const dayDiff = nowDay - birthDay;
                    // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
                    this.setState({
                        date: e.target.value,
                        age:dayDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    })
                    return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
                }
                break;
            case "city":
                if (e.target.value) {
                    this.setState({
                        city: e.target.value,
                        city0:2
                    })
                }else{
                    this.setState({
                        city: e.target.value,
                        city0:1
                    })
                }
                break;
            case "idcard":
                if (e.target.value) {
                    this.setState({
                        idcard: e.target.value,
                        idcard0:2
                    })
                }else{
                    this.setState({
                        idcard: e.target.value,
                        idcard0:1
                    })
                }
                break;
            case "guardianIdcardNum":
                if (e.target.value) {
                    this.setState({
                        guardianIdcardNum: e.target.value,
                        guardianIdcardNum0:2
                    })
                }else{
                    this.setState({
                        guardianIdcardNum: e.target.value,
                        guardianIdcardNum0:1
                    })
                }
                break;
            case "phone":
                if (e.target.value.length) {
                    this.setState({
                        phone: e.target.value,
                        phone0:2
                    })
                }else{
                    this.setState({
                        phone: e.target.value,
                        phone0:2
                    })
                }
                break;

        }
    }
    // 点击修改
    uploadvalue0= async()=>{
        const { name, sex,name0, sex0,idcard,idcard0, guardianName,city1,province,county,cityId,guardianName0,guardianIdcardNum,guardianIdcardNum0,date,list, city, phone,city0, phone0,pasthistory0,allergichistory0,familyhistory0, pasthistory,pasthistorycode0,allergichistorycode0,familyhistorycode0, allergichistory, familyhistory, liver,livercode0,kidneycode0, kidney ,patientId,pasthistorycode,allergichistorycode,familyhistorycode} = this.state
        let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        var sexAndAge = {};
        let a=0
        let b=((name0==0)||(name0==2))
        let d=((city0==0)||(city0==2))
        let e=((phone0==0)||(phone0==2))
        let f=((pasthistory0==0)||(pasthistory0==2))
        let g=((allergichistory0==0)||(allergichistory0==2))
        let h=((familyhistory0==0)||(familyhistory0==2))
        let i=((idcard0==0)||(idcard0==2))
        let j=((guardianName0==0)||(guardianName0==2))
        let k=((guardianIdcardNum0==0)||(guardianIdcardNum0==2))
        if( b&&d&&e&&f&&g&&h&&i&&(this.state.age>=6||(j&&k&&(guardianName?guardianName:list.guardianName)&&(guardianIdcardNum?guardianIdcardNum:list.guardianIdcardNum)))&&province&&city1&&county&&cityId){
            if ((phone0==2&&reg.test(phone))||reg.test(!phone0&&list.patient_phone)) {
                if ((idcard&&p.test(idcard))||(!idcard0&&list.patient_id_card)) {
                    if (this.state.age<6){
                        if (guardianIdcardNum&&!p.test(guardianIdcardNum)) {
                            Toast.show("请输入正确的监护人身份证号")
                            return ;
                        }
                    }
                    //获取用户身份证号码
                    var userCard = idcard?idcard:list.patient_id_card;
                    //如果身份证号码为undefind则返回空
                    if (!userCard) {
                        return sexAndAge;
                    }
                    //获取到性别
                    if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
                        sexAndAge.sex = '男'
                    } else {
                        sexAndAge.sex = '女'
                    }
                    //获取出生年月日
                    //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
                    var yearBirth = userCard.substring(6, 10);
                    var monthBirth = userCard.substring(10, 12);
                    var dayBirth = userCard.substring(12, 14);
                    //获取当前年月日并计算年龄
                    var myDate = new Date();
                    var monthNow = myDate.getMonth() + 1;
                    var dayNow = myDate.getDay();
                    var yearNow = myDate.getFullYear();
                    var age=yearBirth+'-'+monthBirth+'-'+dayBirth
                    if (yearNow<yearBirth||((yearNow==yearBirth)&&(monthNow<monthBirth))||((yearNow==yearBirth)&&(monthNow==monthBirth)&&(dayNow<dayBirth))) {
                        a=-1
                    }else{
                        sexAndAge.age = age;
                        a=1
                    }
                    if (sexAndAge&&sexAndAge.age&&sexAndAge.age==date&&sexAndAge.sex&&sexAndAge.sex==(sex?sex:list.patient_sex)){
                        const res=await changeuserinfo({
                            memberId:list.member_id,
                            patientName:name?name:list.patient_name,
                            patientSex:sex?sex:list.patient_sex,
                            patientBirthday:date?date:list.patient_birthday,
                            patientCity:province+city1+county,
                            patientPhone:phone?phone:list.patient_phone,
                            pastHistory:pasthistorycode0==2?1:list.past_history==1&&pasthistorycode0==0?1:'0',
                            pastHistoryNote:pasthistorycode0==1?'无':list.past_history==0&&(pasthistorycode0==0||pasthistorycode0==1)?'无':pasthistorycode0==2&&list.past_history==0?pasthistory:pasthistorycode0==2&&list.past_history==1?(pasthistory?pasthistory:list.past_history_note):list.past_history==1&&pasthistorycode0==0?(pasthistory?pasthistory:list.past_history_note):'无',
                            allergicHistory:allergichistorycode0==2?1:list.allergic_history==1&&allergichistorycode0==0?1:'0',
                            allergicHistoryNote:allergichistorycode0==1?'无':list.allergic_history==0&&(allergichistorycode0==0||allergichistorycode0==1)?'无':allergichistorycode0==2&&list.allergic_history==0?allergichistory:allergichistorycode0==2&&list.allergic_history==1?(allergichistory?allergichistory:list.allergic_history_note):list.allergic_history==1&&allergichistorycode0==0?(allergichistory?allergichistory:list.allergic_history_note):'无',
                            familyGeneticHistory:familyhistorycode0==2?1:list.family_genetic_history==1&&familyhistorycode0==0?1:'0',
                            familyGeneticHistoryNote:familyhistorycode0==1?'无':list.family_genetic_history==0&&(familyhistorycode0==0||familyhistorycode0==1)?'无':familyhistorycode0==2&&list.family_genetic_history==0?familyhistory:familyhistorycode0==2&&list.family_genetic_history==1?(familyhistory?familyhistory:list.family_genetic_history_note):list.family_genetic_history==1&&familyhistorycode0==0?(familyhistory?familyhistory:list.family_genetic_history_note):'无',
                            abnormalLiverFunction:livercode0==1?'0':list.abnormal_liver_function==0&&livercode0==0?'0':1,
                            abnormalRenalFunction:kidneycode0==1?'0':list.abnormal_renal_function==0&&kidneycode0==0?'0':1,
                            patientDefault:1,
                            // age:this.state.age,
                            patientIdCard:idcard?idcard:list.patient_id_card,
                            guardianName:age>=6?"":guardianName?guardianName:list.guardianName,
                            guardianIdcardNum:age>6?"":guardianIdcardNum?guardianIdcardNum:list.guardianIdcardNum,
                            patientProvince:province,
                            patientCities:city1,
                            patientCounty:county,
                            cityId
                        })
                        if(res.code==1){
                            // if (this.props.location.params&&this.props.location.params.patientManagement) {
                            //     this.props.history.replace("/mine")
                            //     this.props.history.push("/mine/patientManagement")
                            // }else{
                            //     this.props.history.push("/writelist")
                            // }
                            window.history.back()
                            Toast.success("修改成功")
                        }
                    }else{
                        Toast.show("证件号和出生日期或性别不一致")
                    }
                }else{
                    Toast.show("请输入正确的身份证号")
                }
            }else{
                Toast.fail("请填写正确的手机号")
            }
        }else{
            Toast.fail("请将信息填写完整",1)
        }
    }
    //点击新增
    uploadvalue =async () => {
        const { name, sex, date,guardianName,guardianIdcardNum, city,city1,province,county,cityId, phone,idcard, pasthistory, allergichistory, familyhistory, liver,livercode0,kidneycode0, kidney ,patientId,pasthistorycode,allergichistorycode,familyhistorycode} = this.state
        let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        var sexAndAge = {};
        let a=0
        if( name&& date&&province&&city1&&county&&idcard&& phone&&(pasthistorycode?pasthistory:1)&&(allergichistorycode?allergichistory:1)&&(familyhistorycode?familyhistory:1)&&(this.state.age>=6||(this.state.age<6&&guardianName&&guardianIdcardNum))){
            if (reg.test(phone)) {
                if (p.test(idcard)) {
                    if (this.state.age<6){
                        if (!p.test(guardianIdcardNum)) {
                            Toast.show("请输入正确的监护人身份证号")
                            return ;
                        }
                    }
                    //获取用户身份证号码
                    var userCard = idcard;
                    //如果身份证号码为undefind则返回空
                    if (!userCard) {
                        return sexAndAge;
                    }
                    //获取到性别
                    if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
                        sexAndAge.sex = '男'
                    } else {
                        sexAndAge.sex = '女'
                    }
                    //获取出生年月日
                    //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
                    var yearBirth = userCard.substring(6, 10);
                    var monthBirth = userCard.substring(10, 12);
                    var dayBirth = userCard.substring(12, 14);
                    //获取当前年月日并计算年龄
                    var myDate = new Date();
                    var monthNow = myDate.getMonth() + 1;
                    var dayNow = myDate.getDay();
                    var yearNow = myDate.getFullYear();
                    var age=yearBirth+'-'+monthBirth+'-'+dayBirth
                    if (yearNow<yearBirth||((yearNow==yearBirth)&&(monthNow<monthBirth))||((yearNow==yearBirth)&&(monthNow==monthBirth)&&(dayNow<dayBirth))) {
                        a=-1
                    }else{
                        sexAndAge.age = age;
                        a=1
                    }
                    if (sexAndAge&&sexAndAge.age&&sexAndAge.age==date&&sexAndAge.sex&&sexAndAge.sex==(sex?sex:'男')){
                        const res=await adduserinfo({
                            patientId,
                            patientName:name,
                            patientSex:sex?sex:'男',
                            patientBirthday:date,
                            patientCity:province+city1+county,
                            patientPhone:phone,
                            pastHistory:pasthistory==""?0:1,
                            pastHistoryNote:pasthistory==""?'无':pasthistory,
                            allergicHistory:allergichistory==""?0:1,
                            allergicHistoryNote:allergichistory==""?'无':allergichistory,
                            familyGeneticHistory:familyhistory==""?0:1,
                            familyGeneticHistoryNote:familyhistory==""?'无':familyhistory,
                            abnormalLiverFunction:livercode0==2?1:0,
                            abnormalRenalFunction:kidneycode0==2?1:0,
                            patientDefault:1,
                            // age:this.state.age,
                            patientIdCard:idcard,
                            guardianName,
                            guardianIdcardNum,
                            patientProvince:province,
                            patientCities:city1,
                            patientCounty:county,
                            cityId
                        })
                        if(res.code==1){
                            // if (this.props.location.params&&this.props.location.params.patientManagement0) {
                            //     this.props.history.replace("/mine")
                            //     this.props.history.push("/mine/patientManagement")
                            // }else{
                            //     this.props.history.push("/writelist")
                            // }
                            window.history.back()
                            Toast.success("保存成功")
                        }
                    }else{
                        Toast.show("证件号和出生日期或性别不一致")
                    }

                }else{
                    Toast.show("请输入正确的身份证号")
                }

            }else{
                Toast.fail("请填写正确的手机号")
            }
        }else{
            Toast.fail("请将信息填写完整",1)
        }
    }
    gobank=()=>{
        window.history.back()
    }
    //选择城市
    selectAddress = (e, address) => {
        const {provinces,cities,counties} =this.state
        switch (e) {
            case 'province':
                this.setState({
                    province:address.target.value,
                })
                provinces.map(item=>{
                    if (item.name===address.target.value) {
                        this.setState({
                            parentId1:item.id,
                            parentId2:'',
                            counties:'',
                            cityId:item.id,
                            city1:'',
                            county:''
                        },()=>{
                            let params={parentId:item.id}
                            getCity(params).then(res=>{
                                this.setState({
                                    cities:res.data[0]
                                })
                            })
                        })
                    }
                })
                break;
            case 'city':
                this.setState({
                    city1:address.target.value
                })
                cities.map(item=>{
                    if (item.name===address.target.value) {
                        this.setState({
                            parentId2:item.id,
                            parentId1:'',
                            cityId:item.id,
                            county:''
                        },()=>{
                            getDistrict({parentId:item.id}).then(res=>{
                                this.setState({
                                    counties:res.data[0]
                                })
                            })
                        })
                    }
                })
                break;
            case 'county':
                this.setState({
                    county:address.target.value
                })
                counties.map(item=>{
                    if (item.name===address.target.value) {
                        this.setState({
                            cityId:item.id,
                        })
                    }
                })
                break;
            default:
                break;
        }
    }
    render() {
        const { name,age, list,sex, date, city,phone0, phone, pasthistory0,pasthistory, allergichistory,allergichistory0, familyhistory,familyhistory0, liver,
            kidney, pasthistorycode, allergichistorycode, familyhistorycode,pasthistorycode0, allergichistorycode0, familyhistorycode0, livercode0, kidneycode0,
            provinces,cities,counties,province,county,city1,cityId} = this.state
        let id1=0
        let id2=0
        let id3=0
        return (
            <div className='patientbox' onCut={(e)=>{
                e.preventDefault()
                return false}}>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>就诊人信息</h1>
                </div>
                <div className='box-tips'>
                    为了医生安全的诊疗，请填写真实信息
                </div>
                <div className='box-information'>
                    <h1>基础信息</h1>
                    <div className='information-item'>
                        <div>问诊人<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="text"  placeholder='请填写真实姓名' defaultValue={list?list.patient_name:''} onChange={e => this.setvalue(e, "name")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>性别<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="radio" name="sex" value="男" checked={list&&list.patient_sex=='男'&&!sex?1:sex=='男'?1:!list&&!sex?1:0} onChange={e=>this.setvalue(e, "sex")} /><span>男</span>
                            <input type="radio" name="sex" value="女" checked={list&&list.patient_sex=='女'&&!sex?1:sex=='女'?1:0} onChange={e=>this.setvalue(e, "sex")} /><span>女</span>
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>出生日期<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="date" value={date} onChange={e => this.setvalue(e, "date")} />
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>身份证号<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            <input type="text" placeholder='请输入身份证号' defaultValue={list?list.patient_id_card:''} onChange={e => this.setvalue(e, "idcard")} />
                        </div>
                    </div>
                    {/*<div className='information-item'>*/}
                    {/*    <div>当前城市<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>*/}
                    {/*    <div>*/}
                    {/*        <input type="text" placeholder='请输入城市' defaultValue={list?list.patient_city:''} onChange={e => this.setvalue(e, "city")} />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='information-item'>
                        <div>当前城市<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div className='select-area'>
                            <select className="select1" onChange={(e)=>this.selectAddress("province",e)} value={province}>
                                {province?null:<option value="">选择省</option>}
                                {provinces&&provinces.map(item => (
                                    <option value={item.name} key={id1++}>{item.name}</option>
                                ))}
                            </select>
                            <select className="select1" onChange={(e)=>this.selectAddress("city",e)} value={city1}>
                                {city1?null:<option value="">选择市</option>}
                                {cities&&cities.map(item => (
                                    <option value={item.name} key={id2++}>{item.name}</option>
                                ))}
                            </select>
                            <select className="select1" onChange={(e)=>this.selectAddress("county",e)} value={county}>
                                {county?null:<option value="">选择区</option>}
                                {counties&&counties.map(item => (
                                    <option value={item.name} key={id3++}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='information-item'>
                        <div>手机号码<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        <div>
                            {list&&!phone&&!phone0?<input type="text" placeholder='请输入手机号码' defaultValue={list?list.patient_phone:''} onChange={e => this.setvalue(e, "phone")} />
                            :<input type="number" placeholder='请输入手机号码' maxLength={11} defaultValue={list?list.patient_phone:''} onChange={e => this.setvalue(e, "phone")} />}
                        </div>
                    </div>
                    {age<6?<>
                        <div className='information-item'>
                            <div>监护人姓名<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                            <div>
                                <input className='jhr' type="text" placeholder='请输入监护人姓名（小于6岁必填）' defaultValue={list?list.guardianName:''} onChange={e => this.setvalue(e, "guardianName")} />
                            </div>
                        </div>
                        <div className='information-item'>
                            <div>监护人身份证号<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                            <div>
                                <input className='jhr' type="text" placeholder='请输入监护人身份证号（小于6岁必填）' defaultValue={list?list.guardianIdcardNum:''} onChange={e => this.setvalue(e, "guardianIdcardNum")} />
                            </div>
                        </div>
                    </>:null}
                </div>
                <div className='box-information'>
                    <h1>病史信息</h1>
                    <div className='information-item'>
                        <div>既往史<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="pasthistory" checked={list&&list.past_history==0&&pasthistorycode0==0?1:pasthistorycode0==1?1:0} onClick={() => this.setState({
                                pasthistorycode: false,pasthistorycode0: 1,pasthistory:0
                            })} /><span>无</span>
                            <input type="radio" name="pasthistory" checked={list&&list.past_history==1&&pasthistorycode0==0?1:pasthistorycode0==2?1:0} onClick={() => this.setState({
                                pasthistorycode: true,pasthistorycode0: 2
                            })} /><span>有</span>
                        </div>:<div>
                            <input type="radio" name="pasthistory" defaultChecked onClick={() => this.setState({
                                pasthistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="pasthistory" onClick={() => this.setState({
                                pasthistorycode: true
                            })} /><span>有</span>
                        </div>}
                        {pasthistorycode||(list&&list.past_history==1&&pasthistorycode0==0) ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史(必填)' value={list&&list.past_history==1&&(pasthistory0==0)?list.past_history_note:pasthistory} onChange={e => this.setvalue(e, "pasthistory")} />
                            </div> : null}
                    </div>
                    <div className='information-item'>
                        <div>过敏史<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="allergichistory" checked={list&&list.allergic_history==0&&allergichistorycode0==0?1:allergichistorycode0==1?1:0} onClick={() => this.setState({
                                allergichistorycode: false,allergichistorycode0:1,allergichistory0:0
                            })} /><span>无</span>
                            <input type="radio" name="allergichistory" checked={list&&list.allergic_history==1&&allergichistorycode0==0?1:allergichistorycode0==2?1:0} onClick={() => this.setState({
                                allergichistorycode: true,allergichistorycode0:2
                            })} /><span>有</span>

                        </div>:<div>
                            <input type="radio" name="allergichistory" defaultChecked onClick={() => this.setState({
                                allergichistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="allergichistory" onClick={() => this.setState({
                                allergichistorycode: true
                            })} /><span>有</span>

                        </div>}
                        {
                            allergichistorycode||(list&&list.allergic_history==1&&allergichistorycode0==0) ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的过敏史(必填)' value={list&&list.allergic_history==1&&(allergichistory0==0)?list.allergic_history_note:allergichistory} onChange={e => this.setvalue(e, "allergichistory")} />
                            </div> : null
                        }
                    </div>
                    <div className='information-item'>
                        <div>家族遗传史<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="familyhistory" checked={list&&list.family_genetic_history==0&&familyhistorycode0==0?1:familyhistorycode0==1?1:0} onClick={() => this.setState({
                                familyhistorycode: false,familyhistorycode0:1,familyhistory0:0
                            })} /><span>无</span>
                            <input type="radio" name="familyhistory" checked={list&&list.family_genetic_history==1&&familyhistorycode0==0?1:familyhistorycode0==2?1:0} onClick={() => this.setState({
                                familyhistorycode: true,familyhistorycode0:2
                            })} /><span>有</span>
                        </div>:<div>
                            <input type="radio" name="familyhistory" defaultChecked onClick={() => this.setState({
                                familyhistorycode: false
                            })} /><span>无</span>
                            <input type="radio" name="familyhistory" onClick={() => this.setState({
                                familyhistorycode: true
                            })} /><span>有</span>
                        </div>}
                        {
                            familyhistorycode ||(list&&list.family_genetic_history==1&&familyhistorycode0==0) ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的家族遗传史(必填)' value={list&&list.family_genetic_history==1&&(familyhistory0==0)?list.family_genetic_history_note:familyhistory} onChange={e => this.setvalue(e, "familyhistory")} />
                            </div> : null
                        }
                    </div>
                    <div className='information-item'>
                        <div>肝功能异常<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="liver" checked={list&&list.abnormal_liver_function==0&&livercode0==0?1:livercode0==1?1:0} onClick={() => this.setState({
                                livercode: false,livercode0:1
                            })} /><span>无</span>
                            <input type="radio" name="liver" checked={list&&list.abnormal_liver_function==1&&livercode0==0?1:livercode0==2?1:0} onClick={() => this.setState({
                                livercode: true,livercode0:2
                            })} /><span>有</span>
                        </div>:<div>
                            <input type="radio" name="liver" checked={livercode0==1||livercode0==0?1:0} onClick={() => this.setState({
                                livercode: false,livercode0:1
                            })} /><span>无</span>
                            <input type="radio" name="liver" checked={livercode0==2?1:0} onClick={() => this.setState({
                                livercode: true,livercode0:2
                            })} /><span>有</span>
                        </div>}
                        {/* {
                            livercode ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史' value={liver} onChange={e => this.setvalue(e, "liver")} />
                            </div> : null
                        } */}
                    </div>
                    <div className='information-item'>
                        <div>肾功能异常<span style={{color:'#ff0000',fontSize:'14px',marginLeft:'5px'}}>*</span></div>
                        {list?<div>
                            <input type="radio" name="kidney" checked={list&&list.abnormal_renal_function==0&&kidneycode0==0?1:kidneycode0==1?1:0} onClick={() => this.setState({
                                kidneycode: false,kidneycode0:1
                            })} /><span>无</span>
                            <input type="radio" name="kidney" checked={list&&list.abnormal_renal_function==1&&kidneycode0==0?1:kidneycode0==2?1:0} onClick={() => this.setState({
                                kidneycode: true,kidneycode0:2
                            })} /><span>有</span>
                        </div>:
                            <div>
                                <input type="radio" name="kidney" checked={kidneycode0==1||kidneycode0==0?1:0} onClick={() => this.setState({
                                    kidneycode: false,kidneycode0:1
                                })} /><span>无</span>
                                <input type="radio" name="kidney" checked={kidneycode0==2?1:0} onClick={() => this.setState({
                                    kidneycode: true,kidneycode0:2
                                })} /><span>有</span>
                            </div>}
                        {/* {
                            kidneycode ? <div className='item-value'>
                                <input type="text" placeholder='请写出你的既往史' value={kidney} onChange={e => this.setvalue(e, "kidney")} />
                            </div> : null
                        } */}
                    </div>
                </div>
                <div className='box-btn'>
                    {list?<button onClick={this.uploadvalue0}>保存</button>:<button onClick={this.uploadvalue}>保存</button>}
                </div>
            </div>
        )
    }
}
