import React, { Component } from 'react'
import "./style.less"
import { Popup, Input, Button, TextArea } from "antd-mobile-v5"
import Debounce from 'lodash.debounce';
import icon1 from "../../static/img/yy/fanhuihei.png"
import {Toast, Modal, Checkbox} from 'antd-mobile'
import getUrl from '../../component/GetUrlParam'
import { wxConfig } from '../../component/scan/index'
import {defaultMember} from '../../api/hospitalapi/meds'
import {addAllmeds} from '../../api/hospitalapi/order'
import {newState,getnewslist,isChineseMeds,conPharmacyPatient} from '../../api/hospitalapi/chat'
import {allOfflinedoc,nowState,updateStateprice,updateDoctor} from '../../api/hospitalapi/offline'
import HeaderNav from '../../component/HeaderNav'
import {Link} from "react-router-dom";
import jia2 from "../../static/img/jia2.png";
import icon2 from "../../static/img/close1.png";
import wzz from "../../static/img/wzz.png";
import awaitDoc from "../../static/img/await-doc.gif";
import noDoc from "../../static/img/nodoc.png";
import {addchat} from "../../api/hospitalapi/chat";
import icon3 from "../../static/img/contact-complaint.png";
const CheckboxItem = Checkbox.CheckboxItem
const alert = Modal.alert
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientId: localStorage.getItem("patientId1"),
            pharmacyId: localStorage.getItem("pharmacyId"),
            patientPhone: localStorage.getItem("patientPhone1"),
            consultationId: localStorage.getItem("consultationId") ,
            visible1: false,
            visible2:false,
            visible5:false,
            awaitdoc: 2,//2等待医生接诊中
            timer0:'',
            consultationNumber:'',
            docterId:'',
            ischinese:[],
            randomNum:'',//随机数
            videocode:localStorage.getItem("videocode") ? localStorage.getItem("videocode") : 0,
            visibleHome:false,
            pharmacypatientinfo:[]
        }
        this.handleFresh = Debounce(this.handleFresh.bind(this), 1000);
    }

    componentDidMount = async () => {
        const {patientId, selectmeds,consultationId} = this.state

        // 生成随机数：5,6,7随机一个
        const randomNum = Math.floor(Math.random() * 3) + 5;
        this.setState({
            randomNum:randomNum
        })

        // 查询问诊基本信息
        let resphaname = await conPharmacyPatient({consultationId: consultationId})

        let res05 = await isChineseMeds({consultation_id: consultationId})
        

        this.setState({
            ischinese:res05.data[0],
            pharmacypatientinfo:resphaname.data[0]
        })

        if (getUrl('tradeno', this.props.location.search)) {
            let res=await updateStateprice({
                consultationAmount:getUrl('actufee', this.props.location.search),
                consultationNumber:getUrl('tradeno', this.props.location.search)
            })
            if (res.code==1){
                localStorage.removeItem('selectmeds')
                localStorage.removeItem('listicd')
                localStorage.removeItem('illmiaosu')
                localStorage.removeItem('miaosu')
                this.toAwaittime()
            }
        }
        if (getUrl('docterId', this.props.location.search)) {
            this.setState({
                docterId:getUrl('docterId', this.props.location.search)
            })
        }
       this.toAwaittime()
       this.startMinuteTimer()
    }
    componentWillUnmount = () => {
        const {timer0,timer} = this.state
        window.clearTimeout(timer0)
        window.clearTimeout(timer);
    }

    //查询问诊单是否有医生接诊，有就跳转到聊天
    toAwaittime = () => {
        const {consultationId, timer0, patientId, docterId} = this.state
        window.clearTimeout(timer0)
        nowState(consultationId).then(res => {
            if (res && res.data && res.data[0] && res.data[0].length) {
                if (res.data[0][0].docterId&&!docterId){
                    this.setState({
                        docterId:res.data[0][0].docterId
                    })
                }
                if (res.data[0][0].consultationStu == '0' || res.data[0][0].consultationStu == '3') {
                    getnewslist({userId: patientId, consultationId}).then(res1 => {
                        if (res1 && res1.data && res1.data[0] && res1.data[0].length) {
                            localStorage.setItem("item", JSON.stringify(res1.data[0][0]))
                            localStorage.setItem('docterId', res1.data[0][0].docter_id)
                            this.props.history.replace(`/drugselection`)
                            this.props.history.push(`/offlineDoctorchat?consultationId=${consultationId}&docterId=${res1.data[0][0].docter_id}&docuserType=${2}`)
                        }
                    })
                } else if(res.data[0][0].consultationStu == '4'){
                    this.props.history.push(`/drugselection`)
                }else {
                    const newTimer = window.setTimeout(() => {
                        this.setState({
                            timer0: newTimer
                        },()=>{
                            this.toAwaittime();
                        })
                    }, 3000);
                    // this.toAwaittime()

                }
            }
        })
    }

    // 计时器
    startMinuteTimer = () => {
        this.timer = setTimeout(() => {
            this.setState((prevState) => ({
                randomNum: Math.max(prevState.randomNum - 1, 3)
            }), () => {
                this.startMinuteTimer(); 
            });
        }, 180000); // 每分钟执行一次
    };
    
    //取消问诊
    toquxiao = async () => {
        const {consultationId, timer0,patientId} = this.state
        nowState(consultationId).then(res => {
            if (res && res.data && res.data[0] && res.data[0].length) {
                if (res.data[0][0].consultationStu=='-1'){
                    alert('确认取消问诊吗？', '', [
                        {text: '取消', onPress: () => console.log('cancel')},
                        {
                            text: '确认', onPress: async () => {
                                window.clearTimeout(timer0)
                                let res = await newState({consultationId, consultationStu: '4'})
                                if (res && res.code == 1) {
                                    localStorage.removeItem('selectmeds')
                                    Toast.info('取消成功')
                                    this.props.history.push(`/drugselection`)
                                }
                            }
                        },
                    ])
                }else if (res.data[0][0].consultationStu == '0' || res.data[0][0].consultationStu == '3') {
                    Toast.info('订单已被接诊，无法取消')
                    window.clearTimeout(timer0)
                    getnewslist({userId: patientId, consultationId}).then(res1 => {
                        if (res1 && res1.data && res1.data[0] && res1.data[0].length) {
                            localStorage.setItem("item", JSON.stringify(res1.data[0][0]))
                            localStorage.setItem('docterId', res1.data[0][0].docter_id)
                            this.props.history.replace(`/drugselection`)
                            this.props.history.push(`/offlineDoctorchat?consultationId=${consultationId}&docterId=${res1.data[0][0].docter_id}&docuserType=${2}`)
                        }
                    })
                } else {
                    // this.toAwaittime()
                }
            }
        })
    }
    //重新问诊
    toNewWz = async () => {
        const {consultationId, docterId, patientId, index} = this.state
        let res = await newState({consultationId, consultationStu: '-1'})
        if (res && res.code == 1) {
            this.setState({
                awaitdoc: 2
            })
            this.toAwaittime()
        }
    }
    goback = async ()=>{
        const {timer0,consultationId,ischinese} = this.state
        window.clearTimeout(timer0)
        if(ischinese.length){
            this.props.history.push(`/drugselection?medType=2`)
        }else{
            this.props.history.push(`/drugselection`)
        }
        
    }
    // 复制客服电话
    copykefuhone = () => {
        const spanText = document.getElementById('contactPhone'). innerText;
        const oInput = document.createElement('input');
        oInput.value = spanText;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        document.body.removeChild(oInput);
        Toast.info('复制成功')
    }
    toselectDoctor = async () =>{
        const {coordinationcode, consultationId,docterId} = this.state
        if (docterId){
            this.props.history.replace(`/offawait?docterId=${docterId}`)
        }else{
            this.props.history.replace(`/offawait`)
        }
        this.props.history.push(`/appointedmedicalpractitioner?awaitcode=${1}&consultationId=${consultationId}`)
    }
    
    // 确认退出视频问诊
    sureReturn = () =>{
        const {consultationId, timer0,patientId,timer} = this.state
        this.setState({
            visibleHome:false
        },()=>{
            window.clearTimeout(timer0)
            window.clearTimeout(timer)
            newState({consultationId, consultationStu: '4'}).then((res)=>{
                if (res && res.code == 1) {
                    localStorage.removeItem('selectmeds')
                    this.props.history.push(`/drugselection`)
                }
            })
        });
    };
    cancelReturn = () =>{
        this.setState({
            visibleHome:false
        });
    }

    // 刷新页面

    handleFresh=()=>{
        this.toAwaittime()
    }

    

    render() {
        const {docterId, awaitdoc, visible5, isuse, isadverse, selectmeds, miaosu, selectcode, visible2,listchose,randomNum,videocode,visibleHome,pharmacypatientinfo} = this.state
 
        return (
            <div className='patientinformation-outer'>
                {/*<div className='offawait-top'>*/}
                {/*    <img src={icon1} alt="" onClick={this.goback}/>*/}
                {/*</div>*/}
                <div className='consulation-offawait-tips-box'>
                    {pharmacypatientinfo.length && pharmacypatientinfo[0].pharmacy_name} / {pharmacypatientinfo.length && pharmacypatientinfo[0].patientName} / {pharmacypatientinfo.length && pharmacypatientinfo[0].consultation_number}
                </div>
                {visibleHome ? (
                    <>
                        <div className="cons-type-box-wrap">
                            <div className="cons-type-box">
                                <p className="cons-type-tit">提醒</p>
                                <p className="cons-type-tip">请确认是否退出视频问诊，退出后需重新排队</p>
                                <p className="cons-type">
                                    <span className="type-one" onClick={() => this.cancelReturn()}>取消</span>
                                    <span className="type-two" onClick={() => this.sureReturn()}>确认</span>
                                </p>
                            </div>
                        </div>
                    </>
                ) : null}
                {
                    awaitdoc == 2 ? <div className='await-doc-outer'>
                            <p className='await-doc-img'>
                                <img src={awaitDoc}/>
                            </p>
                            {videocode == 1 ? (
                                <p className='await-doc-xt'>您前面还有 <span style={{fontWeight:'600',fontSize:'20px',color:'red'}}>{randomNum}</span> 位患者正在 <span style={{fontWeight:'600',fontSize:'20px',color:'red'}}>视频问诊</span></p>
                            ) : (
                                <p className='await-doc-xt'>系统正在为你匹配医生</p>
                            )}
                            <p className='await-doc'>医生火速接诊中，请务离开此界面...</p>
                            {videocode == 1 ? (
                                <p className='await-quxiao' onClick={this.toquxiao}>取消问诊，前往图文问诊</p>
                            ) : (
                                <p className='await-quxiao' onClick={this.toquxiao}>取消问诊</p>
                            )}  
                        {docterId ? <p className='two-await-shouye'>
                            <p className='await-shouye await-shouye01' onClick={this.goback}>
                                返回首页
                            </p>
                            <p className='await-shouye await-shouye01' onClick={this.toselectDoctor}>
                                重选医生
                            </p>
                        </p>:videocode == 1 ? <p className='await-shouye' onClick={()=>{
                            this.setState({
                                visibleHome:true
                            })
                        }}>
                            返回首页</p> : <p className='await-shouye' onClick={this.goback}>
                            返回首页</p>}

                        
                        <p className='await-shouye1' >
                            <span>返回首页后，可点击</span>
                            <img src={wzz} onClick={()=>{
                                this.props.history.push(`/offnews`)
                            }}/>
                            <span>查看本次问诊状态</span>
                        </p>
                        <p className='await-fresh' onClick={this.handleFresh}>刷新</p>
                            <p className='contact-service' style={{position:'fixed',bottom:'20px',marginLeft:'50vw',
                                transform: 'translateX(-50%)'}}>
                                <span>如有问题或建议，点击</span>
                                <span onClick={()=>this.setState({
                                    visible5:true
                                })}>联系我们</span>
                            </p>
                            {visible5 ? <div className='contact-complaint-outer'>
                                <div className='contact-complaint-inner'>
                                    <img className='close' src={icon2} onClick={() => this.setState({
                                        visible5: false,
                                    })}/>
                                    <h2>联系或投诉</h2>
                                    <p className='copy-phone'>
                                        <span>客服电话：</span>
                                        <span id='contactPhone'>19048012565</span>
                                        <i className='iconfont icon-baocun' onClick={() => this.copykefuhone()}/>
                                    </p>
                                    <p className='tips-contact-complaint'>在使用中如有问题或疑问，您可以拨打电话联系我们。</p>
                                    <p className='chat-contact-complaint'>
                                        <img src={icon3}/>
                                    </p>
                                    <p className='tips-contact-complaint'>添加客服微信，及时处理您的问题</p>
                                </div>
                            </div> : null}
                        </div> :
                        awaitdoc == 3 ? <div className='await-doc-outer'>
                            <p className='await-doc-img'>
                                <img src={noDoc}/>
                            </p>
                            <p className='await-doc-xt'>很遗憾，暂时没有医生接诊</p>
                            <p className='await-doc await-doc1' onClick={this.toNewWz}>重新匹配医生</p>
                            <p className='await-quxiao1'>点击按钮可以从新匹配医生</p>
                        </div> : null}

            </div>
        )
    }
}
