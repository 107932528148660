import React, { Component } from 'react'
import "./style.less"
import chamedstyle from "./chamedstyle.less";
import {Popup, Input, Button, SpinLoading, Modal} from "antd-mobile-v5"
import icon1 from "../../static/img/icon/false1.png"
import { Toast,InputItem,  } from 'antd-mobile'
import getUrl from '../../component/GetUrlParam'
import { wxConfig } from '../../component/scan/index'
import {storeMedPage,allAiorders,allChaMedList,selectForbi,storeMedPages,selectRegulatory} from '../../api/hospitalapi/meds'
import {getnewslist} from '../../api/hospitalapi/chat'
import HeaderNav from '../../component/HeaderNav'
import '../../static/fonts/font_3834206_a7s5cgww9g/iconfont.css'
import {Link} from "react-router-dom";
import saoma from "../../static/img/home/saoma.png";
import search from "../../static/img/home/search1.png";
import addmeds from "../../static/img/home/addmeds.png";
import yaodian from "../../static/img/home/yaodian.png";
import jia from "../../static/img/jia.png";
import jian from "../../static/img/jian.png";
import zysc from "../../static/img/zysc.png";
import wzz from "../../static/img/wzz.png";
import defaultImage from "../../static/img/defaultImage.jpg";
import zydefaultImage from "../../static/img/zydefaultImage.png";
import huanzhe from "../../static/img/huanzhe.png";
import {getuserinfo, inspectonly, register, phoneLogin, verificationcode} from "../../api/hospitalapi/login";
import Nodata from "../../component/Nodata";
import ChaAlert from "./chamed/ChaAlert";
import {allNewslist,addPatientOpenid,offDetail,saveCode} from "../../api/hospitalapi/offline";

import vConsole from "../../../src/api/vconsole";

const alert = Modal.alert

function urlParamsToObject(url) {
    const params = new URLSearchParams(url);
    console.log(params); //URLSearchParams { 'name' => 'binjie09', 'age' => '25', 'gender' => 'male' }
    const paramObj = {};
    for (let [key, value] of params) {
      paramObj[key] = decodeURIComponent(value);
    }
    return paramObj;
  }
  
//   // 使用示例
//   const url = new URL('https://example.com/?name=binjie09&age=25&gender=male');
//   const paramsObj = urlParamsToObject(url);
//   console.log(paramsObj);



export default class index extends Component {
    constructor(props) {
        super(props)

        const obj=urlParamsToObject(props.history.location.search)


        this.state = {
            patientId: localStorage.getItem("patientId1"),
            pharmacyId: localStorage.getItem("pharmacyId"),
            pharmacyName: localStorage.getItem("pharmacyName"),
            contactPhone: localStorage.getItem("contactPhone") ? localStorage.getItem("contactPhone") : '',
            storeContactCode: localStorage.getItem("storeContactCode") ? localStorage.getItem("storeContactCode") : '',
            medsname: '',
            page: 1,
            size: 20,
            medList: [],
            findcode: 0,
            selectmeds: localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")) : [],
            allcount: localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")).length : 0,//选择总种类
            
            supervisionType: localStorage.getItem("supervisionType"),//药品类型  1中药 2西药 3成药(三医)
            visible1: false,
            visible2: false,
            visible3: false,
            scanMed: '',
            orderList: '',
            isscan: false,
            isloading: true,
            storeyzm: '',
            medicalNsurancecoordination: '',
            medType: obj.medType||1,//1西药  2中药
            showAlert: false,//弹窗
            chamedList:[],//存储中药列表
            selectedMed: [],
            chamedarr: localStorage.getItem("chamedarr") && localStorage.getItem("chamedarr") != 'undefined' ? JSON.parse(localStorage.getItem("chamedarr")) : [],
            allchamedcount:localStorage.getItem("chamedarr") && localStorage.getItem("chamedarr") != 'undefined' ? JSON.parse(localStorage.getItem("chamedarr")).length : 0,
            visible4:false,
            alertStates: {},
            forArr:[],
            findcode1:0,
        }
        this.previous = 0//上次执行扫码函数的时间戳
        this.inputRef = React.createRef();
    }


    componentDidMount = async () => {
        const {medType}=this.state

        if (getUrl('medicalNsurancecoordination', this.props.location.search)) {
            this.setState({
                medicalNsurancecoordination: getUrl('medicalNsurancecoordination', this.props.location.search),
            })
        }
        // console.log(this.props.location.pathname.replace(this.props.location.pathname + '?random=' + Math.random()))
        // if (!getUrl('random', this.props.location.search)) { // 首次打开时是没有random参数的，所以接下来地址加上参数，然后重新加载，重新加载时已经又random了就不会再执行这里了。
        //     this.props.history.replace(this.props.location.pathname + '&random=' + Math.random())
        //     window.location.reload()
        // }
        if (getUrl('patientPhone', this.props.location.search)) {
            localStorage.removeItem('selectmeds')
            localStorage.removeItem('listicd')
            localStorage.removeItem('chamedarr')
            const res = await inspectonly(getUrl('patientPhone', this.props.location.search))
            if (res.data[0].length == 0) {
                const res1 = await register({
                    phone: getUrl('patientPhone', this.props.location.search)
                })
                if (res1.code == 1) {
                    phoneLogin({patientPhone: getUrl('patientPhone', this.props.location.search)}).then(res => {
                        // console.log(res);
                    })
                    const res2 = await getuserinfo({
                        patientPhone: getUrl('patientPhone', this.props.location.search)
                    })
                    if (res2.code == 1) {
                        addPatientOpenid({
                            idNumber: getUrl('idCard', this.props.location.search),
                            openid: getUrl('openId', this.props.location.search),
                            patientId: res2.data[0][0].patientId
                        }).then(res3 => {
                            // console.log(res3)
                        })
                        localStorage.setItem("patientPhone1", res2.data[0][0].patientPhone)
                        localStorage.setItem("patientAvatar", res2.data[0][0].patientAvatar)
                        localStorage.setItem("patientName", res2.data[0][0].patientName)
                        localStorage.setItem("patientId1", res2.data[0][0].patientId)
                        localStorage.setItem("storeContactCode", res2.data[0][0].storeContactCode)
                        localStorage.setItem("pharmacyId", getUrl('pharmacyId', this.props.location.search))
                        // this.allmeds()
                        let res0 = await offDetail(getUrl('pharmacyId', this.props.location.search))
                        // console.log(res0)
                        if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
                            this.setState({
                                pharmacyName: res0.data[0][0].pharmacyName,
                                contactPhone: res0.data[0][0].contactPhone,
                                contacts: res0.data[0][0].contacts,
                            })
                            localStorage.setItem("pharmacyName", res0.data[0][0].pharmacyName)
                            localStorage.setItem("contactPhone", res0.data[0][0].contactPhone)
                            localStorage.setItem("contacts", res0.data[0][0].contacts)
                            const params = {
                                pharmacyId: getUrl('pharmacyId', this.props.location.search),
                                page: 1,
                                size: 20,
                                // tab1: 1,
                                content: '',
                                medId: ''
                            }
                            let res30 = await storeMedPage(params)
                            if (res30 && res30.data && res30.data[0] && res30.data[0].length) {
                                this.props.history.replace('/drugselection')
                                window.location.reload()
                                // localStorage.setItem('pharmacyName',res.data[0][0].pharmacyName)
                                // localStorage.setItem('province',res.data[0][0].province)
                                // localStorage.setItem('city',res.data[0][0].city)

                                // localStorage.setItem('district',res.data[0][0].district)
                                let timer = setTimeout(() => {
                                    window.clearTimeout(timer)
                                    res30.data[0].map(item => {
                                        (localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")) : []).map(i => {
                                            if (item.medId == i.medId) {
                                                item.count = i.count
                                            }
                                        })
                                    })
                                    this.setState({
                                        medList: [...res30.data[0]],
                                        page: (Math.ceil(res30.data[0].length / 20)),
                                        findcode: 0,
                                        storeContactCode: res2.data[0][0].storeContactCode,
                                        patientId: localStorage.getItem("patientId1"),
                                        pharmacyId: localStorage.getItem("pharmacyId")
                                    })
                                    let timer1 = setTimeout(() => {
                                        window.clearTimeout(timer1)
                                        this.setState({
                                            isloading: false,
                                        })
                                    }, 200)
                                }, 200)

                            } else {
                                window.location.reload()
                            }
                            this.toGetnewslist()
                        } else if (res0.data[0].length == 0 && getUrl('pharmacyId', this.props.location.search)) {
                            Toast.info('该店铺不存在，请重新扫码')
                            return;
                        }
                    } else {
                        Toast.show({
                            content: "网络错误",
                            duration: 800
                        })
                    }
                }
                // console.log(res1);
            } else {
                phoneLogin({patientPhone: getUrl('patientPhone', this.props.location.search)}).then(res => {
                    // console.log(res);
                    if (res && res.code == 1) {
                        getuserinfo({
                            patientPhone: getUrl('patientPhone', this.props.location.search)
                        }).then(async res2 => {
                            // console.log(res2)
                            if (res2.code == 1) {
                                addPatientOpenid({
                                    idNumber: getUrl('idCard', this.props.location.search),
                                    openid: getUrl('openId', this.props.location.search),
                                    patientId: res2.data[0][0].patientId
                                }).then(res3 => {
                                    // console.log(res3)
                                })
                                localStorage.setItem("patientPhone1", res2.data[0][0].patientPhone)
                                localStorage.setItem("patientAvatar", res2.data[0][0].patientAvatar)
                                localStorage.setItem("patientName", res2.data[0][0].patientName)
                                localStorage.setItem("patientId1", res2.data[0][0].patientId)
                                localStorage.setItem("storeContactCode", res2.data[0][0].storeContactCode)
                                localStorage.setItem("pharmacyId", getUrl('pharmacyId', this.props.location.search))
                                // window.location.reload()
                                // this.allmeds()
                                let res0 = await offDetail(getUrl('pharmacyId', this.props.location.search))
                                // console.log(res0)
                                if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
                                    this.setState({
                                        pharmacyName: res0.data[0][0].pharmacyName,
                                        contactPhone: res0.data[0][0].contactPhone,
                                        contacts: res0.data[0][0].contacts,
                                    })
                                    localStorage.setItem("contactPhone", res0.data[0][0].contactPhone)
                                    localStorage.setItem("pharmacyName", res0.data[0][0].pharmacyName)
                                    localStorage.setItem("contacts", res0.data[0][0].contacts)
                                    const params = {
                                        pharmacyId: getUrl('pharmacyId', this.props.location.search),
                                        page: 1,
                                        size: 20,
                                        // tab1: 1,
                                        content: '',
                                        medId: ''
                                    }
                                    let res30 = await storeMedPage(params)
                                    if (res30 && res30.data && res30.data[0] && res30.data[0].length) {
                                        this.props.history.replace('/drugselection')
                                        window.location.reload()
                                        let timer = setTimeout(() => {
                                            window.clearTimeout(timer)
                                            res30.data[0].map(item => {
                                                (localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")) : []).map(i => {
                                                    if (item.medId == i.medId) {
                                                        item.count = i.count
                                                    }
                                                })
                                            })
                                            this.setState({
                                                medList: [...res30.data[0]],
                                                page: (Math.ceil(res30.data[0].length / 20)),
                                                findcode: 0,
                                                storeContactCode: res2.data[0][0].storeContactCode,
                                                patientId: localStorage.getItem("patientId1"),
                                                pharmacyId: localStorage.getItem("pharmacyId")
                                            })
                                            let timer1 = setTimeout(() => {
                                                window.clearTimeout(timer1)
                                                this.setState({
                                                    isloading: false,
                                                })
                                            }, 200)
                                        }, 200)
                                        // localStorage.setItem('pharmacyName',res.data[0][0].pharmacyName)
                                        // localStorage.setItem('province',res.data[0][0].province)
                                        // localStorage.setItem('city',res.data[0][0].city)
                                        // localStorage.setItem('district',res.data[0][0].district)

                                    } else {
                                        window.location.reload()
                                    }
                                    this.toGetnewslist()
                                } else if (res0.data[0].length == 0 && getUrl('pharmacyId', this.props.location.search)) {
                                    Toast.info('该店铺不存在，请重新扫码')
                                    return;
                                }
                            } else {
                                Toast.show({
                                    content: "网络错误",
                                    duration: 800
                                })
                            }
                        })
                    }
                })

            }
            //     }else if(res0.data[0].length==0&&getUrl('pharmacyId', this.props.location.search)){
            //         Toast.info('该店铺不存在，请重新扫码')
            //         return ;
            //     }
            // }else{
            //     // window.location.reload()
            // }

        } else if (getUrl('medId', this.props.location.search)) {
            const {pharmacyId, medsname, selectmeds} = this.state

            // console.log(pharmacyId, getUrl('medId', this.props.location.search))

            let medIds = JSON.parse(getUrl('medId', this.props.location.search))
            // let medIds =  JSON.Array(getUrl('medId', this.props.location.search))
            // let medIdsArray = medIds.split(',');

            const params = {
                pharmacyId: pharmacyId,
                page: 1,
                size: 20,
                // tab1: 1,
                content: medsname,
                medId: medIds
                // medId:[2098,2372,10926]
                // medId: getUrl('medId', this.props.location.search)
            }
            let res = await storeMedPages(params)
            if (res && res.data && res.data[0] && res.data[0].length) {
                // res.data[0][0].count = 1

                res.data[0].map(item=>{
                    (localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")) : []).map(i => {
                        if (item.medId == i.medId) {
                            item.count = i.count
                        }
                    })
                })

                // selectmeds.map(i => {
                //     if (res.data[0][0].medId == i.medId) {
                //         res.data[0][0].count = i.count
                //     }
                // })


                this.setState({
                    visible2: true,
                    // scanMed: res.data[0][0],
                    scanMed: res.data[0],
                    medList: [...res.data[0]],
                    isloading: true,
                    page: (Math.ceil(res.data[0].length / 20)),
                    findcode: 0,
                    pharmacyId: localStorage.getItem("pharmacyId")
                })
                // this.props.history.replace('/drugselection')
                let timer3 = setTimeout(() => {
                    window.clearTimeout(timer3)
                    this.setState({
                        isloading: false,
                    })
                }, 300)
            } else {
                Toast.info('没有查找到药品')
                this.allmeds()
            }
            this.toGetnewslist()
        } else {
            this.allmeds()
            this.toGetnewslist()
        }
    }

    //查询是否有进行中的订单
    toGetnewslist = async () => {
        const res = await allNewslist({
            userId: localStorage.getItem("patientId1")
        })
        // console.log(res)
        if (res && res.data && res.data[0] && res.data[1]) {
            this.setState({
                orderList: [...res.data[0], ...res.data[1]],
                // consultationId:res.data[0][0].consultation_id
            })
        }
    }
    //搜索药品 
    allmeds = async () => {
        const {pharmacyId, medsname, selectmeds,chamedarr} = this.state
        const params = {
            pharmacyId: pharmacyId ? pharmacyId : getUrl('pharmacyId', this.props.location.search),
            page: 1,
            size: 20,
            // tab1: 1,
            content: medsname,
            medId: '',
        }
        let res = await storeMedPage(params)
        // console.log(res,'368==西药搜索')
        if (res && res.data && res.data[0] && res.data[0].length) {
            // localStorage.setItem('pharmacyName',res.data[0][0].pharmacyName)
            // localStorage.setItem('province',res.data[0][0].province)
            // localStorage.setItem('city',res.data[0][0].city)
            // localStorage.setItem('district',res.data[0][0].district)
            res.data[0].map(item => {
                (localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")) : []).map(i => {
                    if (item.medId == i.medId) {
                        item.count = i.count
                    }
                })
            })
            this.setState({
                medList: [...res.data[0]],
                isloading: false,
                page: (Math.ceil(res.data[0].length / 20)),
                findcode: [...res.data[0]].length && medsname ? 1 : 0
            })
        } else {
            this.setState({
                medList: [],
                isloading: false,
                page: 1,
                findcode: 0
            })
        }


        // 中药药品列表
        // console.log(params,'398========');
        let res31=await allChaMedList(params);
        // console.log(res31,'中药列表====中药搜素');
        let chamedList=[];
        if (res31 && res31.data && res31.data[0] && res31.data[0].length) {
            chamedList=res31.data[0]
            res31.data[0].map(item => {
                (localStorage.getItem("chamedarr") && localStorage.getItem("chamedarr") != 'undefined' ? JSON.parse(localStorage.getItem("chamedarr")) : []).map(i => {
                    if (item.pro_id == i.pro_id) {
                        item.jiacountValue = i.jiacountValue
                    }
                })
            })
            this.setState({
                chamedList:[...res31.data[0]],
                isloading: false,
                page: (Math.ceil(res31.data[0].length / 20)),
                findcode1: [...res31.data[0]].length && medsname ? 1 : 0
            })
        }else {
            this.setState({
                chamedList: [],
                isloading: false,
                page: 1,
                findcode1: 0
            })
        }


    }
    onscroll = e => {
        const {medList,chamedList} = this.state
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1) {
            // document.body.style.overflow='hidden';
            // document.addEventListener("pointerdown",this.mo(e),false);//禁止页面滑动
            // document.addEventListener("pointerup",this.mo(e),false);//禁止页面滑动
            if (medList.length < 100) {
                this.pageMedlist()
            }
        }


        // 中药列表
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 1) {
            if (chamedList.length < 1000) {
                this.pageMedlist()
            }
        }

    }

    mo = (e) => {
        e.preventDefault();
    };

    pageMedlist = () => {
        const {pharmacyId, page, size, medList, medsname, selectmeds,chamedList} = this.state
        const params = {
            pharmacyId: pharmacyId,
            page: page + 1,
            size: size,
            // tab1: 1,
            content: medsname,
            medId: ''
        }
        storeMedPage(params).then(res => {
            if (res && res.data && res.data[0]) {
                let data0 = res.data[0]
                let data1 = [...medList, ...data0]
                data1.map(item => {
                    selectmeds.map(i => {
                        if (item.medId == i.medId) {
                            item.count = i.count
                        }
                    })
                })
                this.setState({
                    medList: [...medList, ...data0],
                    isloading: false,
                    page: (Math.ceil([...medList, ...data0].length / 20))
                })
            }

        })


        // 中药
        allChaMedList(params).then(res => {
            if (res && res.data && res.data[0]) {
                let data0 = res.data[0]
                let data1 = [...chamedList, ...data0]
                data1.map(item => {
                    selectmeds.map(i => {
                        if (item.medId == i.medId) {
                            item.count = i.count
                        }
                    })
                })
                this.setState({
                    chamedList: [...chamedList, ...data0],
                    isloading: false,
                    page: (Math.ceil([...chamedList, ...data0].length / 20))
                })
            }

        })

    }
    //扫码
    cameraScan = async () => {

        const {pharmacyId, medsname, selectmeds, patientId} = this.state 
        if (patientId) {
            wxConfig()

        } else {
            Toast.info('登录信息已过期，请先退出界面重新登录')
        }
    }

    handleKeyPress = (e) => {
        if (e.key === " ") { // 检测用户按下的键是否是空格键
          e.preventDefault(); // 阻止默认行为
        }
    }

    setValue = (e) => {
        e.preventDefault();
        /**
         * @param inputLock: 用于标记是否是非直接的文字输入
         */
        // var node = document.querySelector('#iosinput');
        // var inputLock = false;
        // var that = this
        // node.addEventListener('compositionstart', function(){
        //     inputLock = true;
        // })
        // node.addEventListener('compositionend', function(){
        //     inputLock = false;
        //     // if(!inputLock ) {
        //     // }
        // })
        //
        // node.addEventListener('input', function (){
        //     //function函数this指向function
        //     if(!inputLock ){
        //         that.setState({
        //             medsname:this.value
        //         })
        //     }
        // })


        // const value = e.target.value.trim().replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "");
        const value = e.target.value.replace(/\s/g, ""); // 使用正则表达式替换所有空格
        this.setState({
          medsname: value
        });
        // if (!value.includes(" ")) {    // 判断输入的值是否包含空格
        //     this.setState({
        //       medsname: value
        //     });
        //   }

        // this.setState({
        //     //禁止输入表情
        //     medsname: e.target.value.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "")
        // })
    }

    toSearch = () => {
        this.props.history.replace('/drugselection')
        var s = document.getElementById('iosinput').value;
        if (s === '') {
            return;
        }
        this.allmeds()
        document.getElementById('iosinput').blur();
        // alert('搜索' + document.getElementById('iosinput').value)
        // alert('搜索' + document.getElementById('iosinput').value, '', [
        //     { text: '取消', onPress: () => { } },
        //     {
        //         text: '确定', onPress: () => {
        //             this.allmeds()
        //         }
        //     },
        // ])

    }
    // 中药搜索
    toSearchzy = () => {
        this.props.history.replace('/drugselection')
        var s = document.getElementById('iosinput').value;
        if (s === '') {
            return;
        }
        this.allmeds()
        document.getElementById('iosinput').blur();
    }
    // 点击减少商品
    delitem = (item) => {
        const {selectmeds, allcount, supervisionType, medList} = this.state
        let array = selectmeds
        let array1 = []
        let code = false
        array.forEach(sun => {
            if (sun.medId == item.medId) {
                if (sun.count > 1) {
                    sun.count -= 1
                    array1.push(sun)
                } else {
                    sun.count -= 1
                    this.setState({
                        allcount: allcount - 1
                    })
                }
            } else {
                array1.push(sun)
            }
        })
        medList.map(item => {
            array.map(i => {
                if (i.supervisionType == 2) {
                    code = true
                }
                if (item.medId == i.medId) {
                    item.count = i.count
                }
            })
        })
        if (array1.length == 1) {
            this.setState({
                allcount: 1,
                supervisionType: array1[0].supervisionType
            })
        }
        this.setState({
            supervisionType: code ? 2 : array1.length ? array1[0].supervisionType : '',
            selectmeds: array1,
            medList
        }, () => {
            if (array1.length) {
                localStorage.setItem("supervisionType", code ? 2 : array1[0].supervisionType)
                localStorage.setItem("selectmeds", JSON.stringify(array1))
            } else {
                localStorage.removeItem('selectmeds')
                localStorage.removeItem('supervisionType')
            }
        })
    }

    
    delitem1 = (item) => {
        const {selectmeds, allcount, supervisionType, medList} = this.state
        let array = item
        if (array.count >= 1) {
            array.count -= 1
        }
        this.setState({
            scanMed: array,
        })
    }
// 点击商品计入列表
    pushitem = async (item) => {
        const {selectmeds, allcount, supervisionType, medList,pharmacyId} = this.state

        let array = selectmeds
        let code2 = false

        let med_name1 = item.medComName ;
        let med_name2 = item.medName;


        const nowTime = new Date()
        const targetTime = new Date('2024-08-27T22:00:00')
        // 查询是否监管药品
        let resjg=await selectRegulatory({
            medComName:med_name1,
        });

        if(nowTime >= targetTime){
            if(resjg.data[0] && resjg.data[0][0]){
                Toast.info("监管药品暂未开通线上就诊");
                return;
            }
        }
        
        
        


        let forArr = []

        let res05=await selectForbi({
                med_name1:med_name1,
                med_name2:med_name2,
                pharmacyId:pharmacyId
            });
        if((res05.data[0].length && item.medComName == res05.data[0][0].med_name) && res05.data[1].length==0){
            Toast.info("所选药品暂未开通线上问诊，如有需要请至线下问诊咨询");
            return;
        }
        if((res05.data[0].length && item.medName == res05.data[0][0].med_name) && res05.data[1].length==0){
            Toast.info("所选药品暂未开通线上问诊，如有需要请至线下问诊咨询");
            return;
        }


        if (array.length) {
            let code = false
            let code1 = false
            if (!item.supervisionType) {
                Toast.fail("只能添加药品，不能添加器械")
            } 
            // else if ((supervisionType && supervisionType == 1 && item.supervisionType != 1) || (supervisionType && supervisionType != 1 && item.supervisionType == 1)) {
            //     Toast.fail("只能添加同类药品且总数量不超过5种药品1")
            // } 
            else {
                array.forEach(sun => {
                    if (sun.medId == item.medId) {
                        // if (item.stockNum>sun.count){
                        sun.count += 1
                        code = true
                        code1 = true
                        // }else{
                        //     sun.count=item.stockNum
                        //     code1=true
                        //     Toast.info('库存不足，不能继续添加')
                        // }
                    }
                })
                if (!code && !code1) {
                    item.count = 1
                    array.push(item)
                    this.setState({
                        allcount: allcount + 1
                    })
                    Toast.success("添加成功", 1)
                }
            }
        } else {
            item.count = 1
            array.push(item)
            this.setState({
                allcount: 1,
                supervisionType: item.supervisionType ? item.supervisionType : supervisionType
            })
            Toast.success("添加成功", 1)
        }
        medList.map(item => {
            array.map(i => {
                if (i.supervisionType == 2) {
                    code2 = true
                }
                if (item.medId == i.medId) {
                    item.count = i.count
                }
            })
        })

        this.setState({
            supervisionType: code2 ? 2 : array[0].supervisionType,
            selectmeds: array,
            medList
        }, () => {
            localStorage.setItem("selectmeds", JSON.stringify(array))
            localStorage.setItem("supervisionType", code2 ? 2 : array[0].supervisionType)
        })
    }
    // 点击商品计入列表--扫码药品
    pushitem1 = (item) => {
        const {selectmeds, scanMed, allcount, supervisionType, medList} = this.state
        let oneMed = item
        // let array=selectmeds
        let code2 = false
        // if(array.length){
        let code = false
        let code1 = false
        if (!item.supervisionType) {
            Toast.fail("只能添加药品，不能添加器械")
        } else if ((supervisionType && supervisionType == 1 && item.supervisionType != 1) || (supervisionType && supervisionType != 1 && item.supervisionType == 1)) {
            Toast.fail("只能添加同类药品且总数量不超过5种药品")
        } else {
            oneMed.count += 1
        }
        this.setState({
            scanMed: oneMed,
        })

    }
    //确认添加扫描商品
    scanSure = () => {
        const {selectmeds, supervisionType, scanMed, medList} = this.state
        let array = selectmeds
        let array1 = []
        let code2 = false
        if (array.length) {
            let code = false
            array.forEach(sun => {
                if (sun.medId == scanMed.medId) {
                    if (scanMed.count > 0) {
                        sun.count = scanMed.count
                        code = true
                        array1.push(sun)
                    } else {
                        sun.count = 0
                        code = true
                    }

                } else {
                    array1.push(sun)
                }
            })
            if (!code) {
                if (scanMed.count > 0) {
                    array.push(scanMed)
                    array1 = array
                }
                this.setState({
                    allcount: array1.length
                })
                Toast.success("添加成功", 1)
            }
        } else {
            if (scanMed.count > 0) {
                array.push(scanMed)
                array1 = array
            }
            this.setState({
                allcount: 1,
            })
            Toast.success("添加成功", 1)
        }
        if (array1.length) {
            medList.map(item => {
                array1.map(i => {
                    if (i.supervisionType == 2) {
                        code2 = true
                    }
                    if (item.medId == i.medId) {
                        item.count = i.count
                    }
                })
            })
        } else {
            medList.map(item => {
                item.count = 0
            })
        }
        this.setState({
            visible2: false,
            supervisionType: code2 ? 2 : array1[0].supervisionType,
            selectmeds: array1,
            medList
        }, () => {
            localStorage.setItem("selectmeds", JSON.stringify(array1))
            localStorage.setItem("supervisionType", code2 ? 2 : array1[0].supervisionType)
            this.allmeds()
            this.props.history.push('/drugselection')
        })
    }
    toSubmit = (e) => {
        const {selectmeds, allcount, supervisionType, medicalNsurancecoordination} = this.state
        // if (orderList){
        //     Toast.info("已有进行中的订单，无法继续提交需求")
        // }else{
        e.stopPropagation()
        e.preventDefault();
        localStorage.removeItem("videocode");
        localStorage.removeItem("obj");
        if (allcount > 5) {
            Toast.info("只能添加同类药品且总数量不超过5种药品")
        } else if (allcount == 0) {
            Toast.info("请先选择药品")
        } else {
            let phatime = Date.parse(new Date()) / 1000
            if (medicalNsurancecoordination) {
                this.props.history.replace(`/drugselection?medicalNsurancecoordination=${medicalNsurancecoordination}`)
                this.props.history.push(`/patientinformation?medicalNsurancecoordination=${medicalNsurancecoordination}&phatime=${phatime}`)
            } else {
                this.props.history.replace('/drugselection')
                this.props.history.push(`/patientinformation?phatime=${phatime}`)
            }
            // window.history.go(0)
            localStorage.setItem("selectmeds", JSON.stringify(selectmeds))
        }
        // }

    }

    // 中药提交需求跳转页面
    toSubmitCha = (e) => {
        const {selectmeds, allcount, supervisionType, medicalNsurancecoordination,chamedarr,allchamedcount,medType} = this.state
        e.stopPropagation()
        e.preventDefault();
        localStorage.removeItem("videocode");
        localStorage.removeItem("obj");
        if (chamedarr.length > 48) {
            Toast.info("中药最多添加48味,请删减药品")
        }else if (chamedarr.length == 0) {
            Toast.info("请先选择药品")
        } else {
            let phatime = Date.parse(new Date()) / 1000
            if (medicalNsurancecoordination) {
                this.props.history.replace(`/drugselection?medicalNsurancecoordination=${medicalNsurancecoordination}`)
                this.props.history.push(`/patientinformation?medicalNsurancecoordination=${medicalNsurancecoordination}&phatime=${phatime}`)
            } else {
                this.props.history.replace('/drugselection')
                this.props.history.push(`/patientinformationzhong?phatime=${phatime}&medType=${medType}`)
            }
            // window.history.go(0)
            localStorage.setItem("chamedarr", JSON.stringify(chamedarr))
        }

    }


    toDefult = () => {
        this.setState({
            visible2: false,
            selectmeds: localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")) : []
        })
        this.allmeds()
    }
    //进入问诊聊天页面
    toOrder = async () => {
        const {consultationId} = this.state
        this.props.history.replace(`/drugselection`)
        this.props.history.push(`/offnews`)
    }
    //店员查看所有问诊入口
    toStore = async () => {
        const {contactPhone, storeContactCode} = this.state
        let b = (new Date()).getTime()
        if (storeContactCode == contactPhone.substr(-4)) {
            this.props.history.replace(`drugselection`)
            this.props.history.push(`/shopassistant?timecode=${b}`)
        } else {
            this.setState({
                visible3: true
            })
        }

    }
    toDefult1 = () => {
        this.setState({
            visible3: false,
            storeyzm: ''
        })
    }
    storeYzm = (e) => {
        this.setState({
            storeyzm: e.target.value
        })
    }
    yzmSure = () => {
        const {storeyzm, contactPhone, patientId} = this.state
        if (storeyzm) {
            if (storeyzm == contactPhone.substr(-4)) {
                this.setState({
                    visible3: false,
                }, async () => {
                    let res = await saveCode({storeContactCode: storeyzm, patientId})
                    if (res.code == 1) {
                        localStorage.setItem("storeContactCode", storeyzm)
                        let b = (new Date()).getTime()
                        this.props.history.push(`/shopassistant?timecode=${b}`)
                        localStorage.removeItem("pha_pharmacist");
                        localStorage.removeItem("pha_staff");
                    }
                })
            } else {
                Toast.info('店铺验证码错误')
            }
        } else {
            Toast.info('请输入店铺验证码')
        }

    }
    ceshiclick = async () => {
        let res = await allAiorders(4332)
    }


    // 出现中药克数弹窗
    handlePopupShow = async (item) => {
        const {selectedMed,chamedList,chamedarr,pharmacyId} = this.state

        let med_name1 = item.pro_com_name;
        let med_name2 = item.pro_name;


        const nowTime = new Date()
        const targetTime = new Date('2024-08-27T22:00:00')
        // 查询是否监管药品
        let resjg=await selectRegulatory({
            medComName:med_name1,
        });

        if(nowTime >= targetTime){
            if(resjg.data[0] && resjg.data[0][0]){
                Toast.info("监管药品暂未开通线上就诊");
                return;
            }
        }


        let forArr = []

        let res05=await selectForbi({
                med_name1:med_name1,
                med_name2:med_name2,
                pharmacyId:pharmacyId
            });

        if((res05.data[0].length && item.pro_com_name == res05.data[0][0].med_name) && res05.data[1].length==0){
            Toast.info("所选药品暂未开通线上问诊，如有需要请至线下问诊咨询");
            return;
        }
        if((res05.data[0].length && item.pro_name == res05.data[0][0].med_name) && res05.data[1].length==0){
            Toast.info("所选药品暂未开通线上问诊，如有需要请至线下问诊咨询");
            return;
        }
        if(chamedarr.length + 1 > 48){
            Toast.info("中药最多添加48味")
            return;
        }

        this.setState({ 
            showAlert: true,
            selectedMed:item
        });
       
    }
    // 取消弹窗
    handleAlertClose = (id) => {
        const { alertStates } = this.state;
        this.setState({
            showAlert: false,
          alertStates: {
            ...alertStates,
            [id]: false
          }
        });
    }

    handleAlertConfirm = (count) => {
        const {selectedMed,chamedList,chamedarr}=this.state

        const index = chamedarr.findIndex((med) => med.pro_id === selectedMed.pro_id);
        if (index !== -1) {
            // 如果存在相同的 pro_id，则更新 count 值
            chamedarr[index].jiacountValue = count;
        } else {
            // 否则，将 selectedMed 添加到 chamedarr 数组中
            selectedMed.jiacountValue = count;
            chamedarr.push(selectedMed);
        }

        // 更新 chamedList 中的 jiacountValue 值
        const updatedChamedList = chamedList.map((med) => {
            if (med.pro_id === selectedMed.pro_id) {
            return {
                ...med,
                jiacountValue: count,
            };
            } else {
            return med;
            }
        });

        // 存储有数量的数据
        // const chamedarr = [selectedMed];
        this.setState({
            showAlert:false,
            selectedMed:selectedMed,
            chamedarr:chamedarr,
            chamedList: updatedChamedList,  // 更新 chamedList
        });
        localStorage.setItem('chamedarr', JSON.stringify(chamedarr));
    };
    
    // 删除克数
    handleShancShow = (item)=>{
        const {chamedarr,allchamedcount,chamedList} = this.state
        item.jiacountValue = null;
        const updatedChamedarr = chamedarr.filter((med) => med.pro_id !== item.pro_id);
        const updatedchamedList = chamedList.map((med) => {
            if (med.pro_id === item.pro_id) {
              med.jiacountValue = null;
            }
            return med;
        });
        this.setState({ 
            chamedarr: updatedChamedarr,
            chamedList:updatedchamedList,
            allchamedcount: allchamedcount - 1
        },()=>{
            localStorage.setItem('chamedarr', JSON.stringify(updatedChamedarr));
        });
    }
    // 阻止回车键盘事件
    handleJiacountKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault(); // 阻止默认的回车换行事件
        }

        if (e.key === ' ' || (e.target.innerText.length >= 3 && e.key !== 'Backspace' && e.key !== 'Delete')) {
            e.preventDefault();
        }
    
        const pattern = /^[0-9]$/;
        if (!pattern.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
            e.preventDefault();
        }

    }
    handleJiacountKeyPress = (e) => {

        // 检查是否为空格键
        if (e.key === ' ') {
            // 阻止默认事件
            e.preventDefault();
        }

        const pattern = /^[0-9]$/;
      
        if (!pattern.test(e.key) || e.target.innerText.length >= 3) {
          e.preventDefault();
        }
    };
    // 更新购物车的数量
    handleJiacountChange = (e, item) => {
        const { selectedMed, chamedList, chamedarr } = this.state;
        const pro_id = item.pro_id;
        const newValue = e.target.innerText;
        // 保存原本的值
        const originalValue = item.jiacountValue;
        
        // 判断输入值是否为大于0的数且不以零开头的数字
        const pattern = /^(?!0)\d+$/;
        if (!pattern.test(newValue)) {
            // 将原本的值赋值给输入框
            e.target.innerText = originalValue;
            return; // 停止执行后面的代码
        }
        // 如果输入的数字大于3，保留前3位数字
        if (newValue.length > 3) {
            e.target.innerText = originalValue;
            return;
        }

        const arr=Array.isArray(selectedMed)?selectedMed:[selectedMed];
        const updatedSelectedMed = arr.map(medItem => {
          if (medItem.pro_id === pro_id) {
            return {
              ...medItem,
              jiacountValue: newValue
            }
          }
          return medItem;
        });
        
        const updatedChamedList = chamedList.map(chamedItem => {
          if (chamedItem.pro_id === pro_id) {
            return {
              ...chamedItem,
              jiacountValue: newValue
            }
          }
          return chamedItem;
        });
        
        const updatedChamedarr = chamedarr.map(chamedItem => {
          if (chamedItem.pro_id === pro_id) {
            return {
              ...chamedItem,
              jiacountValue: newValue
            }
          }
          return chamedItem;
        });
        localStorage.setItem('chamedarr', JSON.stringify(updatedChamedarr));

        this.setState({
          selectedMed: updatedSelectedMed,
          chamedList: updatedChamedList,
          chamedarr: updatedChamedarr
        });
    }

    /**
     * @description 患者logo跳转患者列表
     */
    jumpPatientList = () =>{
        this.props.history.push(`/newpatient?isNewPatient=${1}`)
    }

    render() {
        const {
            medList,
            visible2,
            findcode,
            scanMed,
            pharmacyName,
            visible1,
            selectmeds, medType,
            medsname,
            orderList, visible3,
            isloading,
            showAlert,//弹窗
            chamedList,//中药列表
            jiacountValue,
            selectedMed,
            chamedarr,
            visible4,
            findcode1,
        } = this.state

        let allchamedcount = 0;
        if(chamedarr){
            allchamedcount = chamedarr.length
        }

        let allcount1 = 0
        selectmeds.map(item => {
            allcount1 += item.count
        })
        return (
            <div className='drugselection-outer'>
                {isloading ?
                    <div className='loading-outer'>
                        <SpinLoading/>
                    </div>
                    :
                    <div>
                        <div className='drugselection-search'>
                            <p onClick={() => this.toStore()}><img src={yaodian} loading="lazy" alt="加载"/>当前店铺：{pharmacyName}</p>
                            <div className='home-header1'>
                                <div className='home-header-middle1'>
                                    <div className='search-container'>
                                        {medType == 1 ? <div className='flexr'>
                                            <img src={search} loading="lazy" alt="加载"/>
                                            <form target="#" id="search_from" action="javascript:return true"
                                                  target="frameFile">
                                                <input 
                                                    id='iosinput' 
                                                    ref={this.inputRef}
                                                    defaultValue={medsname}
                                                    onChange={(e) => this.setValue(e)} 
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder='药品名/国药准字/条形码/首字母'/>
                                                <iframe 
                                                    id='frameFile' 
                                                    name='frameFile'
                                                    style={{display: 'none'}}></iframe>
                                            </form>
                                            {medsname ? <img 
                                                            className='false1' 
                                                            src={icon1}
                                                            loading="lazy" alt="加载"
                                                            onClick={() => (this.setState({medsname: ''}, () => {
                                                                 this.allmeds();
                                                                 if (this.inputRef.current) {
                                                                    this.inputRef.current.value = "";
                                                                  }
                                                             }))}/> : null}
                                            <span className='search-word' onClick={() => this.toSearch()}>搜索</span>
                                        </div> : ""}
                                        {medType == 2 ? <div className='flexr'>
                                            <img src={search} loading="lazy" alt="加载"/>
                                            <form target="#" id="search_from" action="javascript:return true"
                                                  target="frameFile">
                                                <input 
                                                    id='iosinput' 
                                                    ref={this.inputRef}
                                                    defaultValue={medsname}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={(e) => this.setValue(e)} 
                                                    placeholder='请输入中药名称/首字母'/>
                                                <iframe 
                                                    id='frameFile' 
                                                    name='frameFile'
                                                    style={{display: 'none'}}></iframe>
                                            </form>
                                            {medsname ? <img 
                                                            className='false1' 
                                                            src={icon1}
                                                            loading="lazy" alt="加载"
                                                            onClick={() => (this.setState({medsname: ''}, () => {
                                                                 this.allmeds();
                                                                 if (this.inputRef.current) {
                                                                    this.inputRef.current.value = "";
                                                                  }
                                                             }))}/> : null}
                                            <span className='search-word' onClick={() => this.toSearchzy()}>搜索</span>
                                        </div> : ""}
                                    </div>
                                </div>
                                <div className='home-header-right12'>  
                                    <div onClick={this.cameraScan} className='saoma12'
                                         style={{display: 'flex', flexDirection: 'column'}}>
                                        <i className='iconfont icon-saoma2'/>
                                    </div>
                                </div>
                                <div className='huanzhe-logo'>
                                        <img className='img-logo' loading="lazy" alt="加载" src={huanzhe} onClick={this.jumpPatientList}/>
                                </div>
                            </div>
                        </div>
                        <div className='top-med-types'>
                            <p className={medType == 1 ? 'med-type-select' : ''} onClick={() => this.setState({
                                medType: 1
                            })}>药品</p>
                            <p className={medType == 2 ? 'med-type-select' : ''} onClick={() => this.setState({
                                medType: 2
                            })}>中药</p>
                        </div>
                        {/* 西药列表 */}
                        {medType == 1 ? <div className={findcode == 1 ? 'meds-meatils' : 'meds-meatils meds-meatils1'}
                                             onScroll={this.onscroll}>
                                {medList.length ?    
                                    <>
                                        {findcode == 1 ? <p className='find'>已为你找到相关药品</p> : null}
                                        {medList.map(item => (<div className='one-med'>
                                            <p className='one-med-img'>
                                                <img src={item.medPhoto ? item.medPhoto : defaultImage} loading="lazy" alt="加载"/>
                                            </p>
                                            <p className='one-med-news'>
                                                <p className='one-med-news-name'>
                                                    <span
                                                        className='name'>{item.medName ? item.medName : item.medComName}</span>
                                                    <span
                                                        className={item.supervisionType == 1 ? 'zy' : item.supervisionType == 2 ? 'xy' : item.supervisionType == 3 ? 'cy' : ''}>
                                            {item.supervisionType == 1 ? '中药' : item.supervisionType == 2 ? '西药' : item.supervisionType == 3 ? '成药' : ''}</span>
                                                </p>
                                                {/*<p className='one-med-news-name1'><span className='name'>{item.medComName}</span></p>*/}
                                                <p className='one-med-news-spec'>{item.medClassify == '1' ?
                                                    <span className='one-med-news-spec-rx'>RX</span>
                                                    : null}<span className='one-med-news-spec1'>{item.medSpec}</span></p>
                                                <p className='one-med-news-company'>{item.medCompany}</p>
                                            </p>
                                            {item.count ? <p className='one-med-count'>
                                                    {/*<span className='jian' onClick={()=>this.delitem(item)}>-</span>*/}
                                                    <img src={jian} onClick={() => this.delitem(item)} loading="lazy" alt="加载"/>
                                                    <span className='count'>{item.count}</span>
                                                    {/*<span className='jia' onClick={()=>this.pushitem(item)}>+</span>*/}
                                                    <img src={jia} onClick={() => this.pushitem(item)} loading="lazy" alt="加载"/>
                                                </p> :
                                                <p className='one-med-count1'>
                                                    <img src={jia} onClick={() => this.pushitem(item)} loading="lazy" alt="加载"/>
                                                </p>}
                                        </div>))}
                                    </> : <Nodata type='药房搜索'/>}
                            </div> : ""}
                        {/* 中药列表 */}
                        {medType == 2 ? 
                            <div className={findcode1 == 1 ? 'chamedlistbox' : 'chamedlistbox chamedlistbox1'} onScroll={this.onscroll}>
                                {chamedList.length ? 
                                    <>
                                        {findcode1 == 1 ? <p className='find1'>已为你找到相关药品</p> : null}
                                        {chamedList.map(item => (
                                            <div className='chamedbox' key={item.id}>
                                                <div className='one-cha-med-tip'>中药</div>
                                                <div className='one-cha-med-img'>
                                                    <img src={item.pro_photo ? item.pro_photo : zydefaultImage} loading="lazy" alt="加载"/>
                                                </div>
                                                <div className='one-cha-med-name'>
                                                    <span className='chamedname1'>{item.pro_name ? item.pro_name : item.pro_com_name}</span>
                                                </div> 
                                                {item.jiacountValue ? 
                                                    <div className='one-cha-med-count'>
                                                        <div className='one-cha-med-jia'>
                                                            <img loading="lazy" alt="加载" src={zysc} onClick={() => {
                                             
                                                                this.handleShancShow(item)
                                                            }} />
                                                            <span className='jiacount'>{item.jiacountValue}g</span>
                                                            <img loading="lazy" alt="加载" src={jia} onClick={() => this.handlePopupShow(item)} />
                                                            {/* {showAlert && <ChaAlert key={item.id} chaMedName={item.pro_name} onConfirm={this.handleAlertConfirm} />} */}
                                                        </div>
                                                    </div> : 
                                                    <div className='one-cha-med-count'>
                                                        <div className='one-cha-med-jia'>
                                                            <img loading="lazy" alt="加载" src={jia} onClick={() => {
                                                           
                                                                this.handlePopupShow(item);
                                                                // this.setState({
                                                                //     showAlert: true
                                                                // })
                                                            }} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ))}
                                        {showAlert && (
                                            <ChaAlert 
                                                key={selectedMed.id} 
                                                chaMedName={selectedMed.pro_name} 
                                                onConfirm={this.handleAlertConfirm} 
                                                onClose={() => this.handleAlertClose(selectedMed.id)}
                                            />
                                        )}
                                    </> : <Nodata type='药房搜索'/>
                                }
                            </div> : ""}

                        {orderList ? <p className='wzz' onClick={this.toOrder}>
                            <img src={wzz} loading="lazy" alt="加载"/>
                        </p> : null}
                        {/*<p className='lxkf' onClick={()=>this.ceshiclick()}>*/}
                        {/*    测试点击*/}
                        {/*</p>*/}
                        {/* onClick={()=>this.stop()}*/}

                        {/* 西药清单 */}
                        {medType == 1 ?
                            <>
                                <div className='meds-carts'>
                                <div className='meds-carts-left' onClick={() => {
                                    this.setState({
                                        visible1: true
                                    })
                                }}>
                                    <img src={addmeds} loading="lazy" alt="加载"/>
                                    <span>{allcount1}</span>
                                </div>
                                <div className='meds-carts-middle'>
                                    <p>药品清单</p>
                                    <p>只能添加同类药品且种类不超过5</p>
                                </div>
                                <div className='meds-carts-right' onClick={(e) => this.toSubmit(e)}>提交需求</div>
                            </div>
                            <Popup
                                visible={visible1}
                                className='popup'
                                onMaskClick={() => {
                                    this.setState({
                                        visible1: false
                                    })
                                }}
                                bodyStyle={{maxHeight: '68vh', borderTopLeftRadius: '15px', borderTopRightRadius: '15px'}}
                            >
                                <div className='meds-carts-bottom'>
                                    <div className='meds-carts-bottom-left'>
                                        <div className='meds-carts-left'>
                                            <img src={addmeds} loading="lazy" alt="加载"/>
                                            <span>{allcount1}</span>
                                        </div>
                                        <p>药品清单</p>
                                    </div>
                                    <p>只能添加同类药品且种类不超过5</p>
                                </div>
                                {selectmeds.length ?
                                    <div style={{
                                        paddingLeft: '15px',
                                        paddingTop: '5px',
                                        maxHeight: '50vh',
                                        overflow: 'scroll'
                                    }}>
                                        {selectmeds.map(item => (<div className='one-med'>
                                            <p className='one-med-img'>
                                                <img src={item.medPhoto ? item.medPhoto : defaultImage} loading="lazy" alt="加载"/>
                                            </p>
                                            <p className='one-med-news'>
                                                <p className='one-med-news-name'>
                                                    <span
                                                        className='name'>{item.medName ? item.medName : item.medComName}</span>
                                                    <span
                                                        className={item.supervisionType == 1 ? 'zy zz' : item.supervisionType == 2 ? 'xy zz' : item.supervisionType == 3 ? 'cy zz' : ''}>
                                                {item.supervisionType == 1 ? '中药' : item.supervisionType == 2 ? '西药' : item.supervisionType == 3 ? '成药' : ''}</span>
                                                </p>
                                                <p className='one-med-news-spec'>{item.medClassify == '1' ?
                                                    <span className='one-med-news-spec-rx'>RX</span>
                                                    : null}<span className='one-med-news-spec1'>{item.medSpec}</span></p>
                                                <p className='one-med-news-company'>{item.medCompany}</p>
                                            </p>
                                            <p className='one-med-count'>
                                                <img src={jian} onClick={() => this.delitem(item)} loading="lazy" alt="加载"/>
                                                <span className='count'>{item.count}</span>
                                                <img src={jia} onClick={() => this.pushitem(item)} loading="lazy" alt="加载"/>
                                            </p>
                                        </div>))}
                                    </div>
                                    : null}
                                <div className='pop-bottom'>
                                    <p className='pop-bottom-left'>已选 （<span>{allcount1}</span>）</p>
                                    <p className='pop-bottom-right' onClick={(e) => this.toSubmit(e)}>提交需求</p>
                                </div>
                            </Popup>
                            </> : ""
                        }

                        {/* 中药清单 */}
                        {medType == 2 ?
                            <>
                                <div className='meds-carts'>
                                <div className='meds-carts-left' onClick={() => {
                                    this.setState({
                                        visible1: true
                                    })
                                }}>
                                    <img src={addmeds} loading="lazy" alt="加载"/>
                                    <span>{allchamedcount}</span>
                                </div>
                                <div className='meds-carts-middle'>
                                    <p>中药清单</p>
                                    <p style={{width:'160px'}}>中药添加不超过48味</p>
                                </div>
                                <div className='meds-carts-right' onClick={(e) => this.toSubmitCha(e)}>提交需求</div>
                                </div>
                            <Popup
                                visible={visible1}
                                className='popup'
                                onMaskClick={() => {
                                    this.setState({
                                        visible1: false
                                    })
                                }}
                                bodyStyle={{maxHeight: '68vh', borderTopLeftRadius: '15px', borderTopRightRadius: '15px'}}
                            >
                                <div className='meds-carts-bottom'>
                                    <div className='meds-carts-bottom-left'>
                                        <div className='meds-carts-left'>
                                            <img src={addmeds} loading="lazy" alt="加载"/>
                                            <span>{allchamedcount}</span>
                                        </div>
                                        <p>中药清单</p>
                                    </div>
                                    <p>中药添加不超过48味</p>
                                </div>
                                {chamedarr.length ?
                                    <div className='cha-med-list-box' style={{
                                        paddingLeft: '15px',
                                        paddingTop: '5px',
                                        maxHeight: '50vh',
                                        overflow: 'scroll',
                                        paddingBottom :'10px'
                                    }}>
                                        {chamedarr.map(item => 
                                            (<div className='cha-med-lists'>
                                                    <p className='cha-med-list-p'>
                                                        <div className='cha-med-list-name'>{item.pro_name ? item.pro_name : item.pro_com_name}</div>
                                                        <div className='cha-med-input'>
                                                            <span 
                                                                className='cha-med-keshu'
                                                                contenteditable="true" 
                                                                onBlur={e => this.handleJiacountChange(e, item)}
                                                                onKeyDown={e => this.handleJiacountKeyDown(e)}
                                                                onKeyPress={e =>this.handleJiacountKeyPress(e)}
                                                                >
                                                                {item.jiacountValue}
                                                            </span>
                                                            <span className='dg'>g</span>
                                                        </div>
                                                        <img src={zysc} onClick={() => this.handleShancShow(item)} loading="lazy" alt="加载"/>
                                                    </p>
                                            </div>))}
                                    </div>
                                    : null}
                                <div className='pop-bottom'>
                                    <p className='pop-bottom-left'>已选 （<span>{allchamedcount}</span>）</p>
                                    <p className='pop-bottom-right' onClick={(e) => this.toSubmitCha(e)}>提交需求</p>
                                </div>
                            </Popup>
                            </> : ""
                        } 


                        {visible2 ? <div className='scan-med-outer'>
                            <div className='scan-med-body'>
                                <p className='scan-med-title'>扫码药品<span>({scanMed.length}种)</span></p>
                                <p className='scan-box'>
                                    {scanMed.map(item=>(
                                        <div className='one-med scan-one-med'>
                                            <p className='one-med-img'>
                                                {/* <img src={item.medPhoto}/> */}
                                                <img src={item.medPhoto ? item.medPhoto : defaultImage} loading="lazy" alt="加载"/>
                                            </p>
                                            <p className='one-med-news'>
                                                <p className='one-med-news-name'>
                                                    <span
                                                        className='name'>{item.medName ? item.medName : item.medComName}</span>
                                                    <span
                                                        className={item.supervisionType == 1 ? 'zy' : item.supervisionType == 2 ? 'xy' : item.supervisionType == 3 ? 'cy' : ''}>
                                                    {item.supervisionType == 1 ? '中药' : item.supervisionType == 2 ? '西药' : item.supervisionType == 3 ? '成药' : ''}</span>
                                                </p>
                                                <p className='one-med-news-spec'>{item.medClassify == '1' ?
                                                    <span className='one-med-news-spec-rx'>RX</span>
                                                    : null}<span className='one-med-news-spec1'>{item.medSpec}</span></p>
                                                <p className='one-med-news-company'>{item.medCompany}</p>
                                            </p>
                                            {item.count ? <p className='one-med-count'>
                                                    {/* <img src={jian} onClick={() => this.delitem1(item)}/> */}
                                                    <img src={jian} onClick={() => this.delitem(item)} loading="lazy" alt="加载"/>
                                                    <span className='count'>{item.count}</span>
                                                    {/* <img src={jia} onClick={() => this.pushitem1(item)}/> */}
                                                    <img src={jia} onClick={() => this.pushitem(item)} loading="lazy" alt="加载"/>
                                                </p> :
                                                <p className='one-med-count1'>
                                                    {/* <img src={jia} onClick={() => this.pushitem1(item)}/> */}
                                                    <img src={jia} onClick={() => this.pushitem(item)} loading="lazy" alt="加载"/>
                                                </p>}
                                        </div>
                                    ))}
                                </p>
                                {/* <div className='one-med scan-one-med'>
                                    <p className='one-med-img'>
                                        <img src={scanMed.medPhoto}/>
                                    </p>
                                    <p className='one-med-news'>
                                        <p className='one-med-news-name'>
                                            <span
                                                className='name'>{scanMed.medName ? scanMed.medName : scanMed.medComName}</span>
                                            <span
                                                className={scanMed.supervisionType == 1 ? 'zy' : scanMed.supervisionType == 2 ? 'xy' : scanMed.supervisionType == 3 ? 'cy' : ''}>
                                            {scanMed.supervisionType == 1 ? '中药' : scanMed.supervisionType == 2 ? '西药' : scanMed.supervisionType == 3 ? '成药' : ''}</span>
                                        </p>
                                        <p className='one-med-news-spec'>{scanMed.medClassify == '1' ?
                                            <span className='one-med-news-spec-rx'>RX</span>
                                            : null}<span className='one-med-news-spec1'>{scanMed.medSpec}</span></p>
                                        <p className='one-med-news-company'>{scanMed.medCompany}</p>
                                    </p>
                                    {scanMed.count ? <p className='one-med-count'>
                                            <img src={jian} onClick={() => this.delitem1(scanMed)}/>
                                            <span className='count'>{scanMed.count}</span>
                                            <img src={jia} onClick={() => this.pushitem1(scanMed)}/>
                                        </p> :
                                        <p className='one-med-count1'>
                                            <img src={jia} onClick={() => this.pushitem1(scanMed)}/>
                                        </p>}
                                </div> */}
                                <p className='tip-pop-button1'>
                                    <span onClick={() => this.toDefult()}>取消</span>
                                    <span onClick={() => this.scanSure()}>确认</span>
                                </p>
                            </div>
                        </div> : null}
                        {visible3 ? <div className='scan-med-outer-yzm'>
                            <div className='scan-med-body'>
                                <p className='scan-med-title'>店铺验证码</p>
                                <div className='store-yzm'>
                                    <input placeholder='请输入店铺验证码' onChange={(e) => this.storeYzm(e)}/>
                                </div>
                                <p className='tip-pop-button1'>
                                    <span onClick={() => this.toDefult1()}>取消</span>
                                    <span onClick={() => this.yzmSure()}>确认</span>
                                </p>
                            </div>
                        </div> : null}
                    </div>}
            </div>
        )
    }
}
