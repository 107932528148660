import React, { Component } from 'react'
import "./style.less"
import { Popup, Rate, TextArea,ImageViewer,SpinLoading } from "antd-mobile-v5"
import { Toast, ImagePicker, } from "antd-mobile"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/touxiang.png"
import { orderGetnews,updatestu ,callWenzhen} from "../../../api/hospitalapi/chat"
import { zipImg } from '../../../component/zipImage'
import { uploadpic } from "../../../api/hospitalapi/upload"
import { phoneLogin } from "../../../api/hospitalapi/login"
import { changeStatus,changeNum,payUpdateState,zitiNum } from "../../../api/hospitalapi/rejister"
import {axiosInstance} from "../../../api/config";
import {jumpUrl,baseUrl} from "../../../publicurl"
import getUrl from '../../../component/GetUrlParam'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: JSON.parse(localStorage.getItem("item")),
            patientPhone:  localStorage.getItem("patientPhone"),
            visible1: false,
            value: "",
            consultationId: localStorage.getItem("consultationId"),
            consultationStu:"",
            detailContent: '',
            detailContent0: localStorage.getItem("detailContent0"),
            listtext:JSON.parse(localStorage.getItem("listtext"))?JSON.parse(localStorage.getItem("listtext")):{},
            newslist: [],
            userpic: localStorage.getItem("patientAvatar"),
            userId:localStorage.getItem("patientId"),
            tradeno:''
        }
    }
    componentWillMount = () => {
        const {patientPhone} = this.state
        if (getUrl('tradeno', window.location.href)){
            this.setState({
                tradeno:getUrl('tradeno', window.location.href)
            })
            if (getUrl('tradeno', window.location.href).slice(0,2)=='WZ'){
                orderGetnews({consultationNumber:getUrl('tradeno', window.location.href)}).then(res=>{
      
                    if (res&&res.data&&res.data[0]){
                        updatestu({
                            consultationId:res.data[0][0].consultation_id,
                            consultationStu:'-1'
                        }).then(res1=>{
               
                            if (res1&&res1.code==1){
                                this.props.history.push(`/doctorchat?consultationId=${res.data[0][0].consultation_id}&docterId=${res.data[0][0].docter_id}`)
                            }
                        })
                    }
                })
            }else if(getUrl('tradeno', window.location.href).slice(0,2)=='GH'){
                let a=getUrl('tradeno', window.location.href)
                changeNum({outpatientNumber:getUrl('tradeno', window.location.href)}).then(res=>{
                    if (res&&res.code==1){
                        changeStatus({state:1,outpatientNumber:getUrl('tradeno', window.location.href)}).then(res1=>{
                            if (res1&&res1.code==1){
                                this.props.history.push(`/mine/historicaldetails?outpatientNumber=${a}`)
                            }
                        })
                    }
                })
            }else if(getUrl('tradeno', window.location.href).slice(0,2)=='DD'){
                let a=getUrl('tradeno', window.location.href)
                payUpdateState({orderNumber:getUrl('tradeno', window.location.href)}).then(res=>{
                    if (res&&res.code==1){
                        //随机生成自提码
                        var Num="";
                        for(var i=0;i<4;i++)
                        {
                            Num+=Math.floor(Math.random()*10);
                        }
                        zitiNum({orderNumber:a,orderShippingCode:Num}).then(res1=>{
                            if (res1&&res1.code==1){
                                this.props.history.push(`/mine/order/id?tradeno=${a}`)
                            }
                        })
                    }
                })
            }else if(getUrl('tradeno', window.location.href).slice(0,4)=='SPWZ'){
                orderGetnews({consultationNumber:getUrl('tradeno', window.location.href)}).then(res=>{
  
                    if (res&&res.data&&res.data[0]){
                        updatestu({
                            consultationId:res.data[0][0].consultation_id,
                            consultationStu:'-1'
                        }).then(res1=>{
         
                            if (res1&&res1.code==1){

                                // let params1={
                                //     //评价页面URL地址
                                //     evaluateUrl:encodeURIComponent(`https://h5.yyj-health.com/index.html#/evaluate?consultationNumber=${res1.data[1][0].consultationNumber}&docterPhone=${res1.data[1][0].docterPhone}&consultationId=${res.data[0][0].consultation_id}`),
                                //     //拒绝回调URL地址
                                //     rejectCallback:encodeURIComponent(`https://h5.yyj-health.com/index.html#/refusaldiagnosis?consultationNumber=${res1.data[1][0].consultationNumber}&consultationId=${res.data[0][0].consultation_id}&consultationAmount=${res1.data[1][0].consultationAmount}`),
                                // }
                                let params1={
                                    //评价页面URL地址
                                    evaluateUrl:jumpUrl+`/index.html#/evaluate?consultationNumber=${res1.data[1][0].consultationNumber}&docterPhone=${res1.data[1][0].docterPhone}&consultationId=${res.data[0][0].consultation_id}&consultationStu=${res.data[0][0].consultation_stu}`,
                                    //拒绝回调URL地址
                                    rejectCallback:jumpUrl+`/index.html#/refusaldiagnosis?consultationNumber=${res1.data[1][0].consultationNumber}&consultationId=${res.data[0][0].consultation_id}&consultationAmount=${res1.data[1][0].consultationAmount}`,
                                }
                                let params2=JSON.stringify(params1)
                                let params={
                                    "code":'FRMXT',
                                    "phone":patientPhone,
                                    "url":jumpUrl+`/index.html#/doctorinfo?consultationNumber=${res1.data[1][0].consultationNumber}`,
                                    "callback":baseUrl+`/api/hosp/video-doc/video-stu?consultationNumber=${res1.data[1][0].consultationNumber}`,
                                    "doctor":res1.data[1][0].docterPhone,
                                    "extra":{
                                        //评价页面URL地址
                                        "evaluateUrl":jumpUrl+`/index.html#/evaluate?consultationNumber=${res1.data[1][0].consultationNumber}&docterPhone=${res1.data[1][0].docterPhone}&consultationId=${res.data[0][0].consultation_id}`,
                                        //拒绝回调URL地址
                                        "rejectCallback":jumpUrl+`/index.html#/refusaldiagnosis?consultationNumber=${res1.data[1][0].consultationNumber}&consultationId=${res.data[0][0].consultation_id}&consultationAmount=${res1.data[1][0].consultationAmount}`,
                                    }
                                }
                  
                                // let res=await callWenzhen(params)

                                new Promise((resolve, reject) => {
                                    // axiosInstance(`https://endpoint.pre.cecdat.com/ep/third-party/diagnose/access`, {
                                    // axiosInstance(`https://ep-endpoint.cecdat.com/ep/third-party/diagnose/access`, {

                                    axiosInstance(`https://egcare-endpoint.yyj-health.com/sec/third-party/video/setting`, {                                                method: 'post',
                                        data: params,
                                        // headers: {
                                        //     'content-type': 'application/json',
                                        //     'Auth-Ep-Client': 'Bearer ' + res9.data.token,
                                        // }

                                    }).then(res => {
                    
                                        if (res&&res.body){
                                            window.location.href =res.body.redirectUrl
                                        }
                                    })
                                })
                                
                                // callWenzhen().then(res9=>{
                                //     if (res9&&res9.data) {
                                //         console.log(res9.data.token)
                                        
                                //     }
                                // })



                                // window.location.href = `https://endpoint.pre.cecdat.com/ep/third-party/diagnose/access?code=${params.code}&phone=${params.phone}&url=${params.url}&doctor=${params.doctor}&callback=${params.callback}&extra=${params2}`
                                // window.location.href = `https://endpoint.pre.cecdat.com/ep/third-party/diagnose/access?code=${params.code}&phone=${params.phone}&url=${params.url}&doctor=${params.doctor}&callback=${params.callback}&extra=${params2}`
                            }
                        })
                    }
                })
            }
        }
    }
    //获取聊天节点设置默认到最底部

    // 返回消息列表
    goback = () => {
        const { timer,item } = this.state
        window.clearInterval(timer)
        this.props.history.push({
            pathname: "/news",
            params: {
                item: item
            }
        })
    }

    render() {
        const { item,tradeno,orderNumber,image,consultationFiles, visible1,index1, docterDiagnosis,imgFd, detailContent, consultationDetail,newslist, userpic, fileList,listtext,consultationStu } = this.state

        return (
            <>
                <div className='chat-center-outer'>
                    <SpinLoading />
                    {tradeno.slice(0,4)=='SPWZ'?<p className='sp'>视频连接中...</p>:
                        <p>loading...</p>}
                </div>
            </>

        )
    }
}
