import React, { Component } from 'react'
import {DatePicker, Popup, Steps, } from "antd-mobile-v5"
import { Tabs,Switch } from 'antd-mobile'
import { Link, withRouter } from 'react-router-dom'
import './style.less'
import getUrl from '../../component/GetUrlParam'
import {allmedsNews,dutyConsultationDetails} from "../../api/hospitalapi/offline";
import {allMedicinal, caWrite, prescribingDetail} from "../../api/hospitalapi/chat";
import {Modal,Toast} from "antd-mobile";
import '../../static/fonts/font_3834206_jahm7n4hzu/iconfont.css'
import moment from 'moment'
import Nodata from "../../component/Nodata";
import icon1 from '../../static/img/yy/pt.png'
import Patientnews from "../../component/offline";
import {getCollectmedicinal,getCollectmedicinalprice} from "../../api/hospitalapi/meds";
const { Step } = Steps
const { alert } = Modal
class Platform extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yslist: [],
            docter: '',
            patientId: localStorage.getItem("patientId1"),
            docterId: localStorage.getItem("docterId"),
            status: "",
            list: "",
            medsNews: '',
            dutycode: '',
            supervisionType: '',
            visible: false,
            visible1: false,
            prescribingId: '',
            prescribingState:'',
            list3: [],
            index:''
        }
    }

    componentDidMount = async () => {
        const {docterId,} = this.state
        if (getUrl('consultationId', this.props.location.search)) {
            let res = await dutyConsultationDetails({consultationId: getUrl('consultationId', this.props.location.search)})
            if (res && res.data && res.data[0] && res.data[0].length) {
                this.setState({
                    list: res.data[0][0]
                })
            }
            this.getAllmeds()
        }
        if (getUrl('prescribingState', this.props.location.search)) {
            this.setState({
                prescribingState: getUrl('prescribingState', this.props.location.search)
            })
        }
        if (getUrl('prescribingId', this.props.location.search)) {
            this.setState({
                prescribingId: getUrl('prescribingId', this.props.location.search)
            }, () => {
                this.toinfo()
            })
        }
    }
    getAllmeds = async () => {
        const {list} = this.state
        let res = await allmedsNews({consultationId: getUrl('consultationId', this.props.location.search)})
        let a = []
        let allarray=[]
        if (res && res.data && res.data[1] && res.data[1].length) {
            let count=0
            res.data[1].map(item => {
                count+=item.med_quantity
                a.push({name:item.med_com_name,spec:item.med_spec,count:item.med_quantity})
                let params = {}
                params.medName = item.med_com_name
                params.medSpec = item.med_spec
                params.medPrice = item.med_price
                params.medCompany = item.med_company
                allarray.push(params)
            })
            this.setState({
                supervisionType: res.data[0][0].supervision_type
            })
        }
        //采集药品查询
        let res2=await getCollectmedicinalprice({allNews:allarray})
        if (res2&&res2.data&&res2.data.length){
            a.forEach(item=>{
                res2.data.forEach(i=>{
                    if (item.name==i.med_com_name && item.spec==i.med_spec){
                        item.price=i.med_price
                        item.state=1
                    }
                })
            })
        }
        this.setState({
            medsNews: a,
        })
    }
    // 查看详情
    toinfo = async () => {
        const {prescribingId} = this.state
        if (prescribingId!='null'){
            let res = await allMedicinal({prescribingId: getUrl('prescribingId', this.props.location.search)})
            if (res.code == 1 && res.data[0]) {
                let res1 = await prescribingDetail({prescribingId: getUrl('prescribingId', this.props.location.search)})
                let time1 = new Date(res1.data[0][0].create_time).getTime()
                if (res1 && res1.data && res1.data[0]) {
                    this.setState({
                        list3: res.data[0],
                        detail: res1.data[0][0],
                        originalPrescribing: res1.data[0][0].original_prescribing ? res1.data[0][0].original_prescribing : '',
                    })
                }

            }
        }
    }
    toTips=(index)=>{
        this.setState({
            index,
            visible1:true
        },()=>{
            let timer = window.setTimeout(()=>{
                window.clearTimeout(timer)
                this.setState({
                    visible1:false
                })
            },2000)
        })
    }
    render() {
        const {
            list, medsNews, supervisionType, dutycode, visible, detail, docterAutograph1, docterAutograph, list3,
            year, month, index,prescribingId,prescribingState,visible1
        } = this.state
        return (
            <div className='dutydetail-outer'>
                <div className='dutydetail-patient-news'>
                    <Patientnews news={list}/>
                </div>
                <div className='dutydetail-body'>
                    <p className='dutydetail-title'>
                        <span></span>
                        <span>病情描述：</span>
                    </p>
                    <p className='dutydetail-ill'>{list.consultation_detail}</p>
                    <p className='dutydetail-title'>
                        <span></span>
                        <span>药品需求： <span
                            className='supervision-type'>({supervisionType == 1 ? '中药' : supervisionType == 2 ? '西药' : '中成药'})</span></span>
                    </p>
                    {medsNews && medsNews.map((i, v) => (<>
                            <p className='dutydetail-ill'>
                                <span>{i.name+' '+i.spec+' X'+i.count}</span>
                                {i.state?<span className='price'>{i.price?'￥'+i.price:'无'}</span>:
                                    <i onClick={()=>this.toTips(v)} className='iconfont icon-tixing1'/>}
                            </p>
                            {visible1&&v==index?<p className='mztips'>该药品不属于门诊统筹</p>:null}
                            {v < medsNews.length - 1 ? <p className='dutydetail-ill-line'></p> : null}
                        </>

                    ))}
                    <p className='dutydetail-title'>
                        <span></span>
                        <span>提交店铺：</span>
                    </p>
                    <p className='dutydetail-ill'>{list.pharmacy_name}</p>
                    {prescribingId&&detail ? <>
                            <div className='dutydetail-doc-pha'>
                                <div style={{marginRight:'40px'}}>
                                    <p className='dutydetail-title'>
                                        <span></span>
                                        <span>开方医生：</span>
                                    </p>
                                    <p className='dutydetail-ill1'>{list.docter_name}</p></div>
                                <>
                                    {detail.pharmacist_name?<div>
                                        <p className='dutydetail-title'>
                                            <span></span>
                                            <span>审方药师：</span>
                                        </p>
                                        <p className='dutydetail-ill1'>{detail.pharmacist_name}</p>
                                    </div>:null}
                                </>


                            </div>
                            <p className='dutydetail-title'>
                                <span></span>
                                <span>处方状态：{detail.prescribing_state == '0' ?
                                    <span className='supervision-type' style={{color: '#F9941C'}}>等待审核</span>
                                    : detail.prescribing_state == '1' ?
                                        <span className='supervision-type' style={{color: '#346EF4'}}>审核通过</span>
                                        : detail.prescribing_state == '-1' ? <span className='supervision-type'
                                                                                   style={{color: '#FF4E4E'}}>审核驳回</span> : null}</span>
                            </p>
                            {detail.prescribing_state == '-1' ?
                                <p className='dutydetail-ill'>驳回原因：{detail.reason}</p> : null}
                        </> :
                        !detail ? <>
                                <div className='dutydetail-doc-pha'>
                                    <div style={{marginRight:'40px'}}>
                                        <p className='dutydetail-title'>
                                            <span></span>
                                            <span>接诊医生：</span>
                                        </p>
                                        <p className='dutydetail-ill1'>{list.docter_name}</p></div>
                                </div>
                                <p className='dutydetail-title'>
                                    <span></span>
                                    <span>问诊状态：{list.consultation_stu == '3' ?
                                        <span className='supervision-type' style={{color: '#F9941C'}}>医生拒诊</span>
                                        :   <span className='supervision-type' style={{color: '#F9941C'}}>医生未开方</span>}</span>
                                </p>
                                {list.consultation_stu == '3' ?<p className='dutydetail-ill'>拒诊原因：{list.consultation_reason}</p>:null}
                            </>
                            : null}
                </div>
            </div>
        )
    }
}

export default withRouter(Platform)