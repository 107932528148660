import React, { Component } from 'react'
import "./style.less"
import icon1 from "../../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../../static/img/head.png"
import icon3 from "../../../../static/img/yy/right.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            patientName:"",
            patientAvatar:""
        }
    }
    componentDidMount = () => {
        this.setState({
            patientPhone: localStorage.getItem("patientPhone"),
            patientName:localStorage.getItem("patientName"),
            patientAvatar:localStorage.getItem("patientAvatar")
        })
    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    render() {
        const { patientPhone ,patientName,patientAvatar} = this.state
        return (
            <div className='securitybox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine/set")} />   账户信息与安全
                </div>
                <div className='box-item'>
                    <div>头像</div> <div onClick={()=>this.props.history.push("/mine/set/security/userpic")}><img src={patientAvatar?patientAvatar:icon2} alt="" className='item-icon' /> <img src={icon3} alt="" /></div>
                </div>
                <div className='box-item'>
                    <div>昵称</div> <div onClick={()=>this.props.history.push("/mine/set/security/username")}>{patientName&&patientName!='null'?patientName:"暂未设置"} <img src={icon3} alt="" /></div>
                </div>
                <div className='box-item'>
                    <div>手机号</div> <div>{patientPhone}</div>
                </div>
                <div className='box-item'>
                    <div>微信公众号</div> {patientPhone ?
                        <div>已绑定 <span style={{ color: "rgb(72, 226, 72)" }} onClick={this.clearuser}>点击解绑</span></div> :
                        <div>未绑定 <span style={{ color: "rgb(72, 226, 72)" }} onClick={() => this.props.history.push("/login")}>点击绑定</span></div>}
                </div>
            </div>
        )
    }
}
