import React, { useState, useEffect,useMemo } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button,ImagePicker } from 'antd-mobile'
import Meditem from '../orderitem/index'

import { adressListRequest } from '../../../api/request/adress'
import { aftersaleList, aftersaleMedList, cancelAftersale } from '../../../api/request/aftersale'

import { oderdetailRequest, upOrderStuRequest } from '../../../api/request/order'
import { storeDetailsRequest } from "../../../api/request/store"
import { addIntegralRequest } from '../../../api/request/integral'
import { pharmacyStorecs } from '../../../api/request/cart'
import getUrl from '../../../component/GetUrlParam'
import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
import '../../../static/css/iconfont.css'
import defaultImg from '../../../static/img/default.png'
import fanhui from '../../../static/img/fanhuihei.png'
import './style.less'
const { alert } = Modal
const RadioItem = Radio.RadioItem


//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const [orderDetail, setOrderDetail] = useState([])
    const [orderAddress, setOrderAddress] = useState(null)
    const [medDetail, setMedDetail] = useState([])
    // const orderNumber = getUrl('tradeno', props.location.search)
    const status = getUrl('status', props.location.search)
    const [visible1, setVisible1] = useState('none');
    const [aftersaleId, setaftersaleId] = useState()
    const [way, setWay] = useState()
    const [reasonsWay, setreasonsWay] = useState(['原路返回','退款到钱包'])
    const [reasonsWay1, setreasonsWay1] = useState()
    const [otherGoods, setotherGoods] = useState([])
    const [array, setArray] = useState([])
    const [array10, setArray10] = useState([])
    const [array11, setArray11] = useState([])
    const [array12, setArray12] = useState([])

    const [array1, setArray1] = useState([])
    const [array2, setArray2] = useState([])
    const [array3, setArray3] = useState([])
    const [array4, setArray4] = useState([])
    const [array5, setArray5] = useState([])
    
// console.log(props);
    // 订单状态
    const [reason1,setReason1]=useState()
    const cartArr=props.location.state
    const cartArr1=props.location.tradeno
    const cartArr2=props.location.arr1
    const cartArr3=props.location.arr2
    const cartArr4=props.location.arr3
    const cartArr5=props.location.arr4
    const cartArr6=props.location.arr5


    let orderNumber;  //先定义一个存储参数的变量
    let arr=[];
    let arr1=[];
    let arr2=[];
    let arr3=[];
    let arr4=[];
    let arr5=[];

    if(cartArr1){  //判断是否有参数
        orderNumber=cartArr1;
        arr=cartArr;
        arr1=cartArr2;
        arr2=cartArr3;
        arr3=cartArr4;
        arr4=cartArr5;
        arr5=cartArr6;


        localStorage.setItem('orderNumber2', orderNumber);
        localStorage.setItem('arr', JSON.stringify(arr));
        localStorage.setItem('arr1', JSON.stringify(arr1));
        localStorage.setItem('arr2', JSON.stringify(arr2));
        localStorage.setItem('arr3', JSON.stringify(arr3));
        localStorage.setItem('arr4', JSON.stringify(arr4));
        localStorage.setItem('arr5', JSON.stringify(arr5));


    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        orderNumber = localStorage.getItem('orderNumber2');
        arr = JSON.parse(localStorage.getItem('arr'));
        arr1 = JSON.parse(localStorage.getItem('arr1'));
        arr2 = JSON.parse(localStorage.getItem('arr2'));
        arr3 = JSON.parse(localStorage.getItem('arr3'));
        arr4 = JSON.parse(localStorage.getItem('arr4'));
        arr5 = JSON.parse(localStorage.getItem('arr5'));

    }
    useEffect(() => {
        oderdetailRequest(orderNumber).then(res => {
            // console.log(res);
            if (res.code==1) {
                setMedDetail(res.data[1])

                let a=[]
                let b=[]
                let c=[]
        
                let a1=[]
                let a2=[]
                let a3=[]
                let a4=[]
                let a5=[]
        
                arr.map(item=>{
                    if (item.aftersale_type=="商品类型") {
                        a.push(item)
                    }
                    if (item.aftersale_type=="发票类型") {
                        b.push(item)
                    }
                    if (item.aftersale_type=="资质类型") {
                        c.push(item)
                    }
                })
                arr1.map(item=>{
                    if (item.aftersale_type=="商品类型") {
                        a1.push(item)
                    }
                    if (item.aftersale_type=="发票类型") {
                        b.push(item)
                    }
                    if (item.aftersale_type=="资质类型") {
                        c.push(item)
                    }
                })
                arr2.map(item=>{
                    if (item.aftersale_type=="商品类型") {
                        a2.push(item)
                    }
                    if (item.aftersale_type=="发票类型") {
                        b.push(item)
                    }
                    if (item.aftersale_type=="资质类型") {
                        c.push(item)
                    }
                })
                arr3.map(item=>{
                    if (item.aftersale_type=="商品类型") {
                        a3.push(item)
                    }
                    if (item.aftersale_type=="发票类型") {
                        b.push(item)
                    }
                    if (item.aftersale_type=="资质类型") {
                        c.push(item)
                    }
                })
                arr4.map(item=>{
                    if (item.aftersale_type=="商品类型") {
                        a4.push(item)
                    }
                    if (item.aftersale_type=="发票类型") {
                        b.push(item)
                    }
                    if (item.aftersale_type=="资质类型") {
                        c.push(item)
                    }
                })
                arr5.map(item=>{
                    if (item.aftersale_type=="商品类型") {
                        a5.push(item)
                    }
                    if (item.aftersale_type=="发票类型") {
                        b.push(item)
                    }
                    if (item.aftersale_type=="资质类型") {
                        c.push(item)
                    }
                })
                let a11=a1.concat(a,a2,a3,a4,a5)
                
                // console.log(b);
                setArray11(b);
                setArray10(a11)
                setArray(a)
                setArray1(a1)
                setArray2(a2)
                setArray3(a3)
                setArray4(a4)
                setArray5(a5)
                setArray12(c)
            }
        })
}, [])
useEffect(() => {
    let b=JSON.parse(JSON.stringify(medDetail))
    for (let i = 0; i < b.length; i++) {
        for (let j = 0; j < array10.length; j++) {
           if (b[i].medId==array10[j].med_id) {
                b.splice(i,1)
           }
        }
    }
    setOrderDetail(b);
}, [array10])

    const toMedicine = (medId) => {
        props.history.push(`/med/${medId}`)
    }
    const fanhui1=()=>{
        props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
    }
    const toRefund=(e,orderNumber,medId) => {
        e.stopPropagation()
        // console.log(orderNumber);
        const cartArr = [{
            pharmacy: {
                orderNumber: orderNumber,
                medId: medId
            },
        }]
        // console.log(cartArr);
        props.history.push({ pathname: '/aftermarket2', state: cartArr })
    }
    const toRefund1=(e,orderNumber,medId,aftersaleId) => {
        e.stopPropagation()
        // console.log(orderNumber);
        const cartArr = [{
            pharmacy: {
                orderNumber: orderNumber,
                medId: medId,
                aftersaleId:aftersaleId
            },
        }]
        // console.log(cartArr);
        props.history.push({ pathname: '/aftermarket2', state: cartArr })
    }
    const goodsrejected=(e,orderNumber,medId,aftersaleId)=>{
        e.stopPropagation()
        // console.log(orderNumber);
        const cartArr = [{
            pharmacy: {
                orderNumber: orderNumber,
                medId: medId,
                aftersaleId:aftersaleId
            },
        }]
        // console.log(cartArr);
        props.history.push({ pathname: '/goodsrejected', state: cartArr })
    }
    const cancelAftersales=(e,orderNumber,medId,aftersaleId)=>{
        e.stopPropagation()
        cancelAftersale({aftersaleId}).then(res=>{
            // console.log(res);
            props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
        })
    }
    const refundFreight=()=>{

    }
    const invoice=(e)=>{
        e.stopPropagation()
        props.history.push({ pathname: '/invoiceAfterSales', state: orderNumber })
    }
    const qualifications=(e)=>{
        e.stopPropagation()
        props.history.push({ pathname: '/qualificationsAfterSales', state: orderNumber })
    }
    const invoiceChehui=(e)=>{
        e.stopPropagation()
        cancelAftersale({aftersaleId:array11[0].aftersale_id}).then(res=>{
            // console.log(res);
            props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
        })
    }
    const qualificationsChehui=(e)=>{
        e.stopPropagation()
        cancelAftersale({aftersaleId:array12[0].aftersale_id}).then(res=>{
            // console.log(res);
            props.history.push(`/mine/order/id?tradeno=${orderNumber}`)
        })
    }
// console.log(array11);
return (
    <div>
        <div>
            <div className='pifa-shou'>
                <div className='shou-top'>
                    <img src={fanhui} onClick={()=>fanhui1()} alt="" />
                    <p>申请售后</p>  
                </div>
                <div className='yunfei'>
                    <p className='yunfei-left'>
                        <p className='yunfei-left-price'>运费 10.00元</p>
                        <p className='yunfei-left-shuoming'>如已凑单达到起配金额，可申请退运费</p>
                    </p>
                    <p className='yunfei-right' onClick={(e)=>refundFreight(e)}>退运费</p>
                </div>
                <div className='yunfei'>
                    <p className='yunfei-left'>
                        <p className='yunfei-left-price'>发票相关</p>
                        <p className='yunfei-left-shuoming'>无票/错票/换专票</p>
                    </p>
                    {array11.length==0?<p className='yunfei-right' onClick={(e)=>invoice(e)}>申请售后</p>:null}
                    {array11.length>0&&array11[0].aftersale_state=='1'? <p className='yunfei-right yunfei-right1' onClick={(e)=>invoiceChehui(e)}>撤回申请</p>:null}
                    {array11.length>0&&array11[0].aftersale_state=='3'? <p className='yunfei-right yunfei-right1'>售后失败</p>:null}
                    {array11.length>0&&array11[0].aftersale_state=='6'? <p className='yunfei-right yunfei-right1'>售后中</p>:null}
                    {array11.length>0&&array11[0].aftersale_state=='8'? <p className='yunfei-right yunfei-right1'>售后完成</p>:null}
                </div> 
                <div className='yunfei'>
                    <p className='yunfei-left'>
                        <p className='yunfei-left-price'>资质相关</p>
                        <p className='yunfei-left-shuoming'>混发/未发资质</p>
                    </p>
                    {array12.length==0?<p className='yunfei-right' onClick={(e)=>qualifications(e)}>申请资质</p>:null}
                    {array12.length>0&&array12[0].aftersale_state=='1'?<p className='yunfei-right yunfei-right1'  onClick={(e)=>qualificationsChehui(e)}>撤回申请</p>:null}
                    {array12.length>0&&array12[0].aftersale_state=='3'?<p className='yunfei-right yunfei-right1'>售后失败</p>:null}
                    {array12.length>0&&array12[0].aftersale_state=='6'?<p className='yunfei-right yunfei-right1'>售后中</p>:null}
                    {array12.length>0&&array12[0].aftersale_state=='8'?<p className='yunfei-right yunfei-right1'>售后完成</p>:null}
                </div>
                <div className='pifa-dingdan'>
                    <p className='goods-xg'>商品相关</p>
                    <p className='goods-sh'>订单商品如有售后需求，请申请售后</p>
                    {medDetail === null ? null :
                        <>
                        {medDetail.map((item, index) => (
                                <div key={index} className='pifa-order-meditem'
                                onClick={()=>toMedicine(item.medId)}>
                                    <div className='flexr'>
                                        <div>
                                            <img src={item.medPhoto?item.medPhoto:defaultImg} alt=''
                                                onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                                            {item.medState==2?<p>申请退货数x{item.medRefundQuantity}</p>:null}
                                        </div>
                                        <div className='order-meditem-pifa'>
                                            <p className='left'>
                                                <span className='name'>
                                                    {item.medClassify==='1'?<span className='chufang'>处方药</span>:null}
                                                    {item.medName}
                                                </span>
                                                <span className='l'>规格：<span>{item.medSpec}</span></span>
                                                <span className='l'>单价：<span>￥{item.medPrice/100}</span></span>
                                                <span className='l'>效期：<span>无</span></span>
                                            </p>
                                            <p className='right'>
                                                <span className='count'>x{item.medQuantity}</span>
                                                <span className='price'>
                                                    <span  className='originPrice'>
                                                    {item.originPrice?<span>￥{item.originPrice/100}</span>:null}</span>
                                                    <span  className='money'>￥{item.medPrice/100}</span>
                                                </span>
                                                {orderDetail.map(v=>(
                                                    <>
                                                        {item.medId==v.medId?
                                                            <span onClick={(e)=>toRefund(e,item.orderNumber,item.medId)} style={{marginLeft:'30px',marginTop:'20px',border:'1px solid #666',padding:'3px'}}>申请售后</span>:null}
                                                    </>
                                                ))}
                                                {array10.length>0?
                                                <>
                                                    {array10.map(i=>(
                                                        <>
                                                            {item.medId==i.med_id?
                                                            <>
                                                                {i.aftersale_state=='1'?<span onClick={(e)=>cancelAftersales(e,item.orderNumber,item.medId,i.aftersale_id)} style={{marginLeft:'20px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>取消售后</span>:null}
                                                                {i.aftersale_state=='2'?<span onClick={(e)=>goodsrejected(e,item.orderNumber,item.medId,i.aftersale_id)} style={{marginLeft:'20px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>等待退货</span>:null}
                                                                {i.aftersale_state=='5'?<span style={{marginLeft:'20px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>货物已退回</span>:null}
                                                                {i.aftersale_state=='6'?<span style={{marginLeft:'20px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>售后处理中</span>:null}
                                                                {i.aftersale_state=='7'?<span style={{marginLeft:'20px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>商家已收到货</span>:null}
                                                                {i.aftersale_state=='8'?<span style={{marginLeft:'20px',marginTop:'20px',color:'#ff8383',border:'1px solid #ff8383',padding:'3px'}}>售后完成</span>:null}
                                                            </>:null}
                                                        </>
                                                    ))}
                                                </>
                                                :null}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='xian'></div>
                                </div>
                            ))}
                        </>}
                </div>
            </div>
        </div>
    </div>
    )
}