import React, {useState, useEffect, Component} from 'react'

import Head from '../../component/Head'
import Nodata from '../../component/Nodata'

import { msglistRequest, updateMsgRequest } from '../../api/request/msg'

import './style.less'
import getUrl from "../../component/GetUrlParam";
import {allGhDeperdment} from "../../api/hospitalapi/chat";
import icon1 from "../../static/img/yy/fanhuihei.png";
import icon2 from "../../static/img/yy/entrance-card1.png";
import icon4 from "../../static/img/yy/entrance-card2.png";
import icon3 from "../../static/img/yy/doc.png";
import icon5 from "../../static/img/yy/yygh.png";
import icon6 from "../../static/img/yy/ghjl.png";
import icon7 from "../../static/img/yy/ghnews.png";
import icon8 from "../../static/img/yy/bgcx.png";
import icon9 from "../../static/img/yy/mzjf.png";
import icon10 from "../../static/img/yy/ykcf.png";
import icon11 from "../../static/img/yy/gyjl.png";
import icon12 from "../../static/img/yy/wzjl.png";
import icon13 from "../../static/img/yy/gzys.png";
import {Toast} from "antd-mobile";

export default class entrance extends Component {
    constructor() {
        super()
        this.state = { 
            userId: localStorage.getItem("patientId"),
        }
    }
    componentDidMount =async () => {
        const {userId} =this.state

    }
    setActiveKey=(activeKey,id)=>{
        this.setState({
            activeKey:activeKey.toString()
        })
        // console.log(activeKey,id)
        this.props.history.replace(`/appointment?index=${activeKey}`)
        this.props.history.push(`/register?id0=${id}&index=${activeKey}`)
    }
    toLogin=()=>{
        Toast.info('请先登录')
        this.props.history.push('/login')
    }
    toNodata=()=>{
        Toast.info('此功能暂未开通')
    }
    render() {
        const { userId} = this.state
        return (
            <div className='entrance-outer'>
                {!userId?
                    <div className='entrance-body'>
                        <p className='entrance-body-top'>
                            <span>云益健互联网医院</span>
                            <span onClick={()=>this.props.history.push('/login')}>未登录</span>
                        </p>
                        <div className='entrance-body-img ' onClick={()=>this.toLogin()}>
                            <img src={icon4}/>
                        </div>
                        <p className='entrance-body-fw'>院内服务</p>
                        <div className='entrance-body-doc0'>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img style={{marginTop:'3px'}} src={icon5}/>
                                <span>预约挂号</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img src={icon6}/>
                                <span>挂号记录</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img src={icon8}/>
                                <span>报告查询</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img src={icon9}/>
                                <span>门诊缴费</span>
                            </div>
                        </div>
                        <p className='entrance-body-fw'>线上服务</p>
                        <div className='entrance-body-doc0'>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img src={icon3}/>
                                <span>名医问诊</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img style={{marginTop:'5px'}} src={icon7}/>
                                <span>问诊消息</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img style={{marginTop:'5px'}} src={icon10}/>
                                <span>已开处方</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img style={{marginTop:'5px'}} src={icon11}/>
                                <span>购药记录</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img style={{marginTop:'5px'}} src={icon12}/>
                                <span>问诊记录</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toLogin()}>
                                <img style={{marginTop:'5px'}} src={icon13}/>
                                <span>关注医生</span>
                            </div>
                        </div>
                    </div>:
                    <div className='entrance-body'>
                        <p className='entrance-body-top'>
                            <span>云益健互联网医院</span>
                            <span style={{color:"#656565"}}>已登录</span>
                        </p>
                        <div className='entrance-body-img '>
                            <img src={icon4} onClick={()=> this.props.history.push('/healthcard')}/>
                        </div>
                        <p className='entrance-body-fw'>院内服务</p>
                        <div className='entrance-body-doc0'>
                            <div className='entrance-body-doc1' onClick={()=>this.props.history.push('/appointment')}>
                                <img style={{marginTop:'3px'}} src={icon5}/>
                                <span>预约挂号</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.props.history.push('/mine/historicalregistration')}>
                                <img src={icon6}/>
                                <span>挂号记录</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toNodata()}>
                                <img src={icon8}/>
                                <span>报告查询</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toNodata()}>
                                <img src={icon9}/>
                                <span>门诊缴费</span>
                            </div>
                        </div>
                        <p className='entrance-body-fw'>线上服务</p>
                        <div className='entrance-body-doc0'>
                            <div className='entrance-body-doc1' onClick={()=>this.props.history.push('/')}>
                                <img src={icon3}/>
                                <span>名医问诊</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.props.history.push('/news')}>
                                <img style={{marginTop:'5px'}} src={icon7}/>
                                <span>问诊消息</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.props.history.push('/mine/prescribed')}>
                                <img style={{marginTop:'5px'}} src={icon10}/>
                                <span>已开处方</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.toNodata()}>
                                <img style={{marginTop:'5px'}} src={icon11}/>
                                <span>购药记录</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.props.history.push('/mine/record')}>
                                <img style={{marginTop:'5px'}} src={icon12}/>
                                <span>问诊记录</span>
                            </div>
                            <div className='entrance-body-doc1' onClick={()=>this.props.history.push('/mine/gzDocter')}>
                                <img style={{marginTop:'5px'}} src={icon13}/>
                                <span>关注医生</span>
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
}