import React, { Component } from 'react'
import "./style.less"
import { Popup, Rate, TextArea,ImageViewer,SpinLoading } from "antd-mobile-v5"
import { Toast, ImagePicker, } from "antd-mobile"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/refundspwz.png"
import icon3 from "../../static/img/spwzfh.png"
import { orderGetnews,updatestu,refundApply } from "../../api/hospitalapi/chat"
import { zipImg } from '../../component/zipImage'
import { uploadpic } from "../../api/hospitalapi/upload"
import { phoneLogin } from "../../api/hospitalapi/login"
import { changeStatus,changeNum,payUpdateState,zitiNum } from "../../api/hospitalapi/rejister"
import moment from 'moment'
import getUrl from '../../component/GetUrlParam'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: JSON.parse(localStorage.getItem("item")),
            patientPhone:  localStorage.getItem("patientPhone"),
            visible1: false,
            value: "",
            consultationId: localStorage.getItem("consultationId"),
            consultationStu:"",
            detailContent: '',
            detailContent0: localStorage.getItem("detailContent0"),
            listtext:JSON.parse(localStorage.getItem("listtext"))?JSON.parse(localStorage.getItem("listtext")):{},
            newslist: [],
            userpic: localStorage.getItem("patientAvatar"),
            userId:localStorage.getItem("patientId"),
            tradeno:''
        }
    }
    componentWillMount = async () => {
        const {patientPhone} = this.state
        if (getUrl('consultationId', this.props.location.search)){
            const res = await updatestu({
                consultationStu: 3,
                consultationId:getUrl('consultationId', this.props.location.search),
                consultationReason: '患者症状不明'
            })
            if (res && res.code == 1) {
                let params = {
                    client_id: 5000,
                    client_trade_no:getUrl('consultationNumber', this.props.location.search),
                    total_fee:getUrl('consultationAmount', this.props.location.search),
                    timestamp: moment().format('YYYYMMDDHHmmss'),
                }
                refundApply(params).then(res1 => {
                    // console.log(res1)
                    if (res1 && res1.code == 1) {
                        // Toast.info('退款成功')
                    }
                })
            }
        }
    }

    render() {
        const { item,tradeno,orderNumber,image,consultationFiles, visible1,index1, docterDiagnosis,imgFd, detailContent, consultationDetail,newslist, userpic, fileList,listtext,consultationStu } = this.state
        // console.log(consultationStu);
        // console.log(item,listtext);
        return (
            <>
                <div className='setbox' style={{backgroundColor:'#fff'}}>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={() => this.props.history.push("/")} />   退款告知
                    </div>
                    <div className='center-img'>
                        <img src={icon2}/>
                    </div>
                    <p className='tips'>医生已拒绝本次问诊服务，相应费用稍后原路退回</p>
                    <div className='back-img'>
                        <img src={icon3} onClick={()=>this.props.history.push('/')}/>
                    </div>
                </div>
            </>
        )
    }
}
