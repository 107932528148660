import React ,{Component}from 'react'
import {Route,Switch} from 'react-router-dom'

import AuthRoute from '../component/authroute/authroute'
import Introduce from '../container/Introduce/index'
import Home from '../container/Home/index'

import Shopcart from '../application/cart/index'
import Health from '../container/Health/index'
import Healthpost from '../container/Health/Post/index'
import Healthrmd from '../container/Health/Recommand/index'
import Fillorder from '../application/cart/fillorder'
import Mine from '../container/Mine/index'
import Collection from '../container/Mine/collection/index'

// import Orderdetail from '../container/Mine/order/orderdetail/index'
import Orderdetail from '../application/order/orderdetail'
import AppleRefund from '../container/Mine/order/refund'

import ElectronicInvoice from '../container/Mine/electronicInvoice/index'


import Adressedit from '../container/Mine/adress/adressedit/index'
import Coupon from '../container/Mine/coupon/index'
import Viewrecord from '../container/Mine/viewrecord/index'
import Set from '../container/Mine/set/index'
import Login from '../container/Login/login'
import Login1 from '../container/Login/login1'



// import Register from '../container/Register/register'
import Search from '../container/Search/index'
import Tidings from '../container/Tidings/index'
import Entrance from '../container/Entrance/index'
import Allentrance from '../container/Allentrance/index'
import Med from '../application/medicine/index'
import Medlist from '../application/medicine/medlist'
import Recipel from '../application/medicine/recipel'
import Adduser from '../application/medicine/adduser'
import Userlist from '../application/medicine/userlist'
import Shop from '../application/shop/index'
import Shopinfo from "../application/shop/shopinfo/index"
import Shopmed from '../application/shop/shopmed'
import Inquiry from '../container/Inquiry/index'
import InquiryHospial from '../container/Inquiry/onhospital/index'
import InquiryHospialId from '../container/Inquiry/onhospital/onhospital-id'
import InquiryDisease from '../container/Inquiry/ondisease/index'
import InquiryDoctor from '../container/Inquiry/doctor/index'
import PatientInfo from '../container/Inquiry/patient/patientinfo'
import InquiryListImageText from '../container/Inquiry/patient/inquirylist1'
import InquiryListPhone from '../container/Inquiry/patient/inquirylist2'
import InquiryPay from '../container/Inquiry/patient/pay'
import Notfound from '../container/Notfound/index'
import Drag from '../container/Notfound/drag'
import Seckill from '../container/Home/Activity/seckill'
import Register from '../container/Home/Operation/register'
import Registrationconfirmation from '../container/Home/Operation/registrationconfirmation'
import Healthcard from '../container/Home/Operation/healthcard'
import Lookhealthcard from '../container/Home/Operation/lookhealthcard'
import Healthcard2 from '../container/Home/Operation/healthcard2'
import Healthcardnews from '../container/Home/Operation/healthcardnews'
import Queryreport from '../container/Home/Operation/queryreport'
import Queryreportnews from '../container/Home/Operation/queryreportnews'
import Queryreportdetails from '../container/Home/Operation/queryreportdetails'
import Outpatientpayment from '../container/Home/Operation/outpatientpayment'
import Invoicedetails from '../container/Home/Operation/invoicedetails'
import Cliniccard from '../container/Home/Operation/cliniccard'
import Cliniccard2 from '../container/Home/Operation/cliniccard2'
import Paymentdetails from '../container/Home/Operation/paymentdetails'
import Appointment from '../container/Home/Operation/appointment'
import yygActivity from '../container/activity/index'
import Actpage from '../container/activity/actpage'
import Comment from '../application/order/comment/index'
import SendComment from '../application/order/comment/comment'
import logistics from "../application/order/logistics"
import aftermarket from "../application/order/aftermarket/index"
import aftermarket2 from "../application/order/aftermarket/aftermarket2/index"
import invoiceAfterSales from "../application/order/aftermarket/invoiceAfterSales/index"
import qualificationsAfterSales from "../application/order/aftermarket/qualificationsAfterSales/index"
import goodsrejected from "../application/order/aftermarket/goodsrejected/index"
import getUserInfo from "../container/fanhui/getUserInfo"
import Consultate from '../application/order/consultate/index'
import Drawback from '../application/order/drawback/index'
import Agree from '../application/order/agree/index'
import Refund from '../application/order/refund1/index'

import Nowaddress from '../application/order/nowaddress/index'
import Refuse from '../application/order/refuse/index'
import Qualifications from '../application/qualifications/index'
import Upload from '../application/qualifications/upload/index'
import Await from '../application/qualifications/await/index'
import storeinfor from "../application/minepf/storeinfor/index"
import storeticket from "../application/minepf/storeticket/index"
import zhuanedit from "../application/minepf/storeticket/zhuanedit/index"
import moedit from "../application/minepf/storeticket/moedit/index"
import storemoney from "../application/minepf/storemoney/index"
import Withdrawal from "../application/minepf/storemoney/Withdrawal/index"
import txDetail from "../application/minepf/storemoney/Withdrawal/txDetail/index"
import bill from "../application/minepf/bill"
import billinfo from "../application/minepf/bill/billinfo"

import detailed from "../application/minepf/storemoney/detailed/index"
import storeSH from "../application/minepf/storeSH/index"
import storeSHin from "../application/minepf/storeSH/storeSHin/index"
import addpass from "../container/Mine/set/addpass/index"

import Region from "../application/region"

import Departmenttype from "../container/Home/Departmenttype/index"
import order from "../container/order"
import doctorinfo from "../application/doctorinfo"
import doctorchat from "../application/doctorchat"
import offlineDoctorchat from "../application/offlineDoctorchat"
import refusaldiagnosis from "../application/refusaldiagnosis"
import evaluate from "../application/evaluate"

import security from "../container/Mine/set/security"
import username from "../container/Mine/set/security/username"
import userpic from "../container/Mine/set/security/userpic"
import chat from "../application/doctorinfo/chat"
import chat1 from "../application/doctorinfo/chat1"

import chatinfo from "../application/doctorinfo/chat/chatinfo"
import chiosestore from "../application/chiosestore"
import submit from "../application/submit"
import prescriptionlist from "../application/prescriptionlist"
import writelist from "../application/writelist"
import payover from "../application/payover"
import risknotification from "../application/risknotification"
import patient from "../application/addpatient"
import newpatient from "../application/newpatient"
import appointedmedicalpractitioner from "../application/appointedmedicalpractitioner"
import patientlist from "../application/patientlist"
import drugselection from "../application/drugselection"
import patientinformation from "../application/patientinformation"
import offawait from "../application/offawait"
import shopassistant from "../application/shopassistant"
import storeaddmeds from "../application/shopassistant/storeaddmeds"
import shopassistantdetail from "../application/shopassistantdetail"
import allprescribed from "../application/allprescribed"
import allprescribeddetail from "../application/allprescribeddetail"
import news from "../container/news"
import offnews from "../container/offnews"
import prescribed from '../container/Mine/prescribed/index'
import gzDocter from '../container/Mine/gzDocter/index'
import adress from '../container/Mine/adress/index'
import record from '../container/Mine/record/index'
import historicalregistration from '../container/Mine/historicalregistration/index'
import historicaldetails from '../container/Mine/historicaldetails/index'
import patientManagement from '../container/Mine/patientManagement/index'
import drugrecord from '../container/Mine/drugrecord/index'
import Order from '../container/Mine/order/index'
import privacyagreement from "../application/privacyagreement"
import agrenagreement from "../application/agrenagreement"

import patientinformationzhong from "../application/patientinformationzhong"
// 设置签名
import setsignature from "../application/shopassistant/setsignature"
import setsignnaturedetail from "../application/shopassistant/setsignnaturedetail"
import signaturedetails from "../application/shopassistant/signaturedetails"


import WesternMedicine from '../application/shopassistant/storeaddmeds/western-medicine'
import TraditionalMedicine from '../application/shopassistant/storeaddmeds/traditional-medicine'
import ShowUsMedicine from '../application/shopassistant/storeaddmeds/show-us-medicine'
import ShowZhMedicine from '../application/shopassistant/storeaddmeds/show-zh-medicine'
import MedicineSearch from '../application/shopassistant/storeaddmeds/medicine-search'

// 微信扫码登录
import wxlogin from "../application/wxlogin"

import entrypage from "../component/EntryPage/index"



class SubRouter  extends Component{
    render(){
        return(
            <div>
                <AuthRoute></AuthRoute>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route path='/news' component={news}/>
                    <Route path='/offnews' component={offnews}/>
                    <Route path='/privacyagreement' component={privacyagreement}/>
                    <Route path='/agrenagreement' component={agrenagreement}/>
                    <Route path='/introduce' component={Introduce}/>
                    <Route path='/submit' component={submit}/>
                    <Route path='/patient' component={patient}/>
                    <Route path='/newpatient' component={newpatient}/>
                    <Route path='/appointedmedicalpractitioner' component={appointedmedicalpractitioner}/>
                    <Route path='/patientlist' component={patientlist}/>
                    <Route path='/drugselection' component={drugselection}/>
                    <Route path='/patientinformation' component={patientinformation}/>
                    <Route path='/patientinformationzhong' component={patientinformationzhong}/>

                    <Route path='/offawait' component={offawait}/>
                    <Route path='/shopassistant' component={shopassistant}/>
                    
                    <MedicineSearch path='/MedicineSearch' component={MedicineSearch}/>
                    {/* 添加西药 */}
                    {/* <Route exact path='/westernm/:medId/:medName/:medApproval/:supervisionType/:unit/:packingUnit/:medCompany' component={WesternMedicine} /> */}
                    <Route exact path="/westernm" component={WesternMedicine} />
                    {/* 添加中药 */}
                    <Route path='/traditionalm' component={TraditionalMedicine} />
                    {/* 西药药品添加记录查询 */}
                    <Route path='/ShowUsMedicine' component={ShowUsMedicine} />
                    {/* 中药药品添加记录查询 */}
                    <Route path='/ShowZhMedicine' component={ShowZhMedicine} />
                    {/* 药品添加 */}

                    <Route path='/storeaddmeds' component={storeaddmeds}/>
                    {/* 设置签名 */}
                    <Route path='/setsignature' component={setsignature}/>
                    <Route path='/setsignnaturedetail' component={setsignnaturedetail}/>
                    <Route path='/signaturedetails' component={signaturedetails}/>
                    {/* 微信扫码登录 */}
                    <Route path='/wxlogin' component={wxlogin}/>
                    <Route path='/entrypage' component={entrypage}/>
                    
                    <Route path='/shopassistantdetail' component={shopassistantdetail}/>
                    <Route path='/allprescribed' component={allprescribed}/>
                    <Route path='/allprescribeddetail' component={allprescribeddetail}/>
                    <Route path='/writelist' component={writelist}/>
                    <Route path='/payover' component={payover}/>
                    <Route path='/risknotification' component={risknotification}/>
                    <Route path='/prescriptionlist' component={prescriptionlist}/>
                    <Route path='/chiosestore' component={chiosestore}/>
                    <Route path='/departmenttype' component={Departmenttype}/>
                    <Route path='/order' component={order}/>
                    <Route exact path='/doctorinfo' component={doctorinfo}/>
                    <Route exact path='/doctorchat' component={doctorchat}/>
                    <Route exact path='/offlineDoctorchat' component={offlineDoctorchat}/>
                    <Route exact path='/refusaldiagnosis' component={refusaldiagnosis}/>
                    <Route exact path='/evaluate' component={evaluate}/>
                    <Route exact path='/doctorinfo/chat' component={chat}/>
                    <Route exact path='/doctorinfo/chat1' component={chat1}/>
                    <Route path='/doctorinfo/chat/chatinfo' component={chatinfo}/>
                    <Route path='/getUserInfo' component={getUserInfo}/>
            
                    <Route path='/shopcart' component={Shopcart}/>
                    <Route exact path='/health' component={Health}/>
                    <Route path='/health/post/:topicId' component={Healthpost}/>
                    <Route path='/health/recommand' component={Healthrmd}/>
                    <Route path='/fillorder' component={Fillorder}/>
                    <Route exact path='/mine' component={Mine}/>
                    <Route exact path='/mine/set/security' component={security}/>
                    <Route exact path='/mine/set/security/username' component={username}/>
                    <Route exact path='/mine/set/security/userpic' component={userpic}/>
                    <Route path='/mine/collection' component={Collection}/>
                    <Route path='/mine/prescribed' exact component={prescribed}/>
                    <Route path='/mine/gzDocter' exact component={gzDocter}/>
                    <Route path='/mine/adress' exact component={adress}/>
                   
                    <Route path='/mine/order/id' component={Orderdetail}/>
                    <Route path='/mine/order/aftermarket' component={aftermarket}/>
                    <Route path='/aftermarket2' component={aftermarket2}/>
                    <Route path='/invoiceAfterSales' component={invoiceAfterSales}/>
                    <Route path='/qualificationsAfterSales' component={qualificationsAfterSales}/>
                    <Route path='/goodsrejected' component={goodsrejected}/>
                    
                    <Route path='/mine/bill' exact component={bill}/>
                    <Route path='/mine/bill/billinfo' component={billinfo}/>
                    <Route path='/mine/order/logistics/:id?' component={logistics}/>
                    <Route path='/mine/order/apple_refund' component={AppleRefund}/>

                    <Route path='/mine/electronicInvoice' component={ElectronicInvoice}/>
                    <Route path='/mine/adress/edit/:type/:addressId' component={Adressedit}/>
                    <Route path='/mine/coupon' component={Coupon}/>
                    <Route path='/mine/storeinfor' component={storeinfor}/>
                    <Route path='/mine/storeticket' exact component={storeticket}/>
                    <Route path='/mine/storeticket/zhuanedit' component={zhuanedit}/>
                    <Route path='/mine/storeticket/moedit' component={moedit}/>
                    <Route path='/mine/storemoney' exact component={storemoney}/>
                    <Route path='/mine/storemoney/Withdrawal' exact component={Withdrawal}/>
                    <Route path='/mine/storemoney/Withdrawal/txDetail' exact component={txDetail}/>
                    <Route path='/mine/storemoney/detailed' exact component={detailed}/>
                    <Route path='/mine/storeSH' exact component={storeSH}/>
                    <Route path='/mine/storeSH/storeSHin' component={storeSHin}/>
                    <Route path='/mine/viewrecord' component={Viewrecord}/>
                    <Route path='/mine/record' component={record}/>
                    <Route path='/mine/historicalregistration' component={historicalregistration}/>
                    <Route path='/mine/historicaldetails' component={historicaldetails}/>

                    <Route path='/mine/patientManagement' component={patientManagement}/>
                    <Route path='/mine/drugrecord' component={drugrecord}/>
                    <Route exact path='/mine/order' component={Order}/>
                    
                    <Route path='/mine/set' exact component={Set}/>
                    <Route path='/mine/set/addpass' component={addpass}/>
                    <Route path='/login' exact component={Login}/>
                    <Route path='/login1' exact component={Login1}/>

                  
                    {/* <Route path='/register' component={Register}></Route> */}
                    <Route path='/search' component={Search}></Route>
                    <Route path='/tidings' component={Tidings}></Route>
                    <Route path='/entrance' component={Entrance}></Route>
                    <Route path='/allentrance' component={Allentrance}></Route>
                    <Route exact path='/med/:medId' component={Med}></Route>
                    <Route exact path='/medlist/:categoryId/:classifyId/:content' component={Medlist}></Route>
                    <Route path='/med/recipel' component={Recipel}></Route>
                    <Route path='/med/adduser' component={Adduser}></Route>
                    <Route path='/med/userlist' component={Userlist}></Route>
                    <Route exact path='/shop/:pharmacyId' component={Shop}></Route>
                    <Route  path='/shopinfo' component={Shopinfo}></Route>
                    <Route path='/shop/shopmed' component={Shopmed}></Route>
                    <Route exact path='/inquiry' component={Inquiry}></Route>
                    <Route exact path='/inquiry/hospital' component={InquiryHospial}></Route>
                    <Route path='/inquiry/hospital/id' component={InquiryHospialId}></Route>
                    <Route path='/inquiry/disease' component={InquiryDisease}></Route>
                    <Route path='/inquiry/doctor' component={InquiryDoctor}/>
                    <Route path='/inqui ry/patientinfo' component={PatientInfo}></Route>
                    <Route path='/inquiry/list/imagetext' component={InquiryListImageText}></Route>
                    <Route path='/inquiry/list/phone' component={InquiryListPhone}></Route>
                    <Route path='/inquiry/pay' component={InquiryPay}></Route>
                    <Route path='/drag' component={Drag}></Route>
                    <Route path='/seckill' component={Seckill}></Route>
                    <Route path='/register' component={Register}></Route>
                    <Route path='/registrationconfirmation' component={Registrationconfirmation}></Route>
                    <Route path='/healthcard' component={Healthcard}></Route>
                    <Route path='/lookhealthcard' component={Lookhealthcard}></Route>
                    <Route path='/healthcard2' component={Healthcard2}></Route>
                    <Route path='/healthcardnews' component={Healthcardnews}></Route>
                    <Route path='/queryreport' component={Queryreport}></Route>
                    <Route path='/queryreportdetails' component={Queryreportdetails}></Route>
                    <Route path='/invoicedetails' component={Invoicedetails}></Route>
                    <Route path='/cliniccard' component={Cliniccard}></Route>
                    <Route path='/cliniccard2' component={Cliniccard2}></Route>
                    <Route path='/queryreportnews' component={Queryreportnews}></Route>
                    <Route path='/outpatientpayment' component={Outpatientpayment}></Route>
                    <Route path='/paymentdetails' component={Paymentdetails}></Route>
                    <Route path='/appointment' component={Appointment}></Route>
                    <Route path='/activity' component={yygActivity}></Route>
                    <Route path='/actpage' component={Actpage}></Route>
                    <Route path='/comment' component={Comment}></Route>
                    <Route path='/drawback' component={Drawback}></Route>
                    <Route path='/agree' component={Agree}></Route>
                    <Route path='/refund1' component={Refund}></Route>

                    <Route path='/nowaddress' component={Nowaddress}></Route>
                    <Route path='/refuse' component={Refuse}></Route>
                    <Route path='/qualifications' component={Qualifications}></Route>
                    <Route path='/upload' component={Upload}></Route>
                    <Route path='/await' component={Await}></Route>
                    <Route path='/region' component={Region}></Route>

                     <Route path='/consultate' component={Consultate}></Route>
                    <Route path='/sendcomment' component={SendComment}></Route>
                    <Route path='*' component={Notfound}></Route>
                </Switch>
            </div>
        )
    }
}

export default SubRouter