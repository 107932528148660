import React, { Component} from 'react'
import { Flex, Toast, Button } from 'antd-mobile'
import { Link,withRouter } from 'react-router-dom'
import { Badge, SideBar } from 'antd-mobile-v5'
import FootNav from '../../component/FootNav'
import Nodata from "../../component/Nodata";
import { getdoctorlist } from "../../api/hospitalapi/home"
import { callWenzhen } from "../../api/hospitalapi/chat"
import { queryDepartment ,ejDepartment} from "../../api/hospitalapi/department"
import './style1.less'
import "../../static/css/iconfont.css"
import icon1 from "../../static/img/yy/jt.png"
import icon2 from "../../static/img/yy/zh.png"
import icon3 from "../../static/img/yy/jg.png"
import icon4 from "../../static/img/yy/ts.png"
import icon5 from "../../static/img/yy/five.png"
import icon6 from "../../static/img/yy/bt.png"
import icon7 from "../../static/img/keshi.png"
import icon8 from "../../static/img/jkk.png"

import { arrayOf } from 'prop-types'
import {axiosInstance} from "../../api/config";
class Platform extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yslist: [],
            jzcount: 0,
            list: [],
            activeKey: "1",//一级科室id
            list2: [],
            visiable: 'none',
            visiable1: 'none',
            depId: '',
            depName: '',
            orderAmount: '',
            docname: '',
            patientId: localStorage.getItem("patientId"),
            category1: [{key: 1, name: '在线问诊'}, {key: 2, name: '复诊续方'}, {key: 3, name: '在线问诊'}],
            // category2: [{key: 1, name: '预约挂号'}, {key: 4, name: '门诊缴费'}, {key: 3, name: '报告查询'}],
            category2: [{key: 1, name: '预约挂号'}, {key: 3, name: '报告查询'}],
            // activity: [{key: 1, name: '我的就诊卡'}, {key: 2, name: '电子健康卡'}],
            activity: [{key: 2, name: '电子健康卡'}],
            timeNow: '',
            video1:''
        }
    }

    componentDidMount = async () => {
        const {patientId} = this.state
        let date = new Date()
        let hour = date.getHours()
        let min = date.getMinutes()
        // console.log(hour * 60 + min)
        this.setState({
            timeNow: hour * 60 + min
        })


        const res2 = await getdoctorlist()
        // console.log(111);
        // console.log(res2);
        this.setState({
            yslist: res2.data[0]
        })
        if (patientId) {
            // let res9=await callWenzhen()
            // if (res9&&res9.data){
            //     console.log(res9.data.token)
            //     new Promise((resolve, reject) => {
            //         axiosInstance(`https://endpoint.pre.cecdat.com/ep/drugstore/diagnose/video`, {
            //             method: 'get',
            //             params:{key:313},
            //             'responseType': "blob",
            //             headers: {
            //                 'content-type': 'application/octet-stream',
            //                 'Auth-Ep-Client': 'Bearer ' + res9.data.token,
            //             }
            //
            //         }).then(res => {
            //             console.log(res);
            //             let that=this
            //             let blob = new Blob([res], {type: "video/ogg"});
            //             const reader = new FileReader();
            //             reader.readAsDataURL(blob);
            //             reader.onload =function (e){
            //                 // console.log(11);
            //                 var dataBase64= e.target.result; //result是你读取到的文件内容，此属性读取完成才能使用
            //                 console.log(dataBase64)
            //                 that.setState({
            //                     video1:dataBase64
            //                 })
            //             }
            //         })
            //     })
            // }
            const res0 = await queryDepartment()
            // console.log(res0);
            if (res0 && res0.data[0].length) {
                let list = res0.data[0]
                list = list.map(item => {
                    return {
                        key: item.dep_id,
                        title: item.dep_name,
                    }
                })
                // console.log(list);
                this.setState({
                    list,
                    activeKey: list[0].key + '',
                })
                // 二级科室
                const res = await ejDepartment({depParentId: list[0].key})
                // console.log(res);
                if (res) {
                    if (res.data[0].length) {
                        let list2 = res.data[0]
                        list2 = list2.map(item => {
                            return {
                                key: item.dep_id + "",
                                title: item.dep_name,
                            }
                        })
                        // console.log(list2);
                        this.setState({
                            list2: list2
                        }, () => {
                            // console.log(list2);
                        })
                    } else {
                        this.setState({
                            list2: []
                        })
                    }
                }
            }
        }
    }
    getBase64(data)
    {
        return new Promise((resolve, reject) => {
            const blob = new Blob([data], {type: "image/jpg"}); //类型一定要写！！！
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    toinfo = (item) => {
        const {patientId, timeNow} = this.state
        if (patientId) {
            if (item.docterStartDisturb && (timeNow >= (parseInt(item.docterStartDisturb.split(':')[0] * 60) + parseInt(item.docterStartDisturb.split(':')[1])) &&
                item.docterEndDisturb && (timeNow <= parseInt(item.docterEndDisturb.split(':')[0] * 60) + parseInt(item.docterEndDisturb.split(':')[1])) && item.docterDisturb == 1)
            ) {
                Toast.info('医生休息中，无法进行咨询')
            } else {
                localStorage.setItem("item", JSON.stringify(item))
                this.props.history.push({
                    pathname: "/doctorinfo"
                })
            }
        } else {
            this.props.history.push("/login")
        }
    }
    // 选择二级科室
    setActiveKey = async (activeKey) => {
        const {list} = this.state
        // console.log(activeKey);
        let a;
        list.map(item => {
            if (item.key == activeKey) {
                a = item
            }
        })
        // console.log(a);
        const res = await ejDepartment({depParentId: activeKey})
        // console.log(res);
        if (res) {
            if (res.data[0].length) {
                let list2 = res.data[0]
                list2 = list2.map(item => {
                    return {
                        key: item.dep_id + "",
                        title: item.dep_name,
                    }
                })
                // console.log(list2);
                this.setState({
                    activeKey,
                    list2: list2
                }, () => {
                    // console.log(list2);
                })
            } else {
                this.setState({
                    activeKey,
                    list2: []
                })
            }
        }
    }
    // 显示科室列表
    department = () => {
        const {patientId} = this.state
        if (!patientId) {
            this.props.history.push("/login")
        } else {
            this.setState({
                visiable: 'block',
                visiable1: 'block',
            })
        }
    }
    // 选择科室
    erjidepart = async (key, title) => {
        let arr = []
        this.setState({
            visiable: 'none',
            visiable1: 'none',
            depId: key,
            depName: title
        })
        const res2 = await getdoctorlist()
        // console.log(111);
        // console.log(res2);
        res2.data[0].map(item => {
            if (item.branch == key) {
                arr.push(item)
            }
        })
        this.setState({
            yslist: arr
        })
    }
    // 综合
    zonghe = async () => {
        const res2 = await getdoctorlist()
        // console.log(111);
        // console.log(res2);
        this.setState({
            yslist: res2.data[0],
            visiable: 'none',
            visiable1: 'none',
            depId: '',
            depName: ''
        })
    }
    // 价格
    price = async () => {
        const {orderAmount} = this.state
        const res2 = await getdoctorlist({orderAmount: orderAmount ? orderAmount : 'up'})
        this.setState({
            yslist: res2.data[0],
            visiable: 'none',
            visiable1: 'none',
            depId: '',
            depName: '',
            orderAmount: !orderAmount ? 'down' : orderAmount == 'up' ? 'down' : 'up'
        })
    }
    // 搜索字
    docName = (e) => {
        this.setState({
            docname: e.target.value,
            visiable: 'none',
            visiable1: 'none',
        })
    }
    // 搜索
    sousuo = async () => {
        const {docname} = this.state
        const res2 = await getdoctorlist({docterName: docname})
        // console.log(res2);
        if (res2 && res2.data && res2.data[0].length) {
            this.setState({
                yslist: res2.data[0],
                visiable: 'none',
                visiable1: 'none',
                depId: '',
                depName: '',
            })
        } else {
            this.setState({
                yslist: [],
                // visiable:'none',
                // visiable1:'none',
                // depId:'',
                // depName:'',
            }, () => {
                Toast.info('该医生或医院不存在')
            })
        }
    }
    // 查询一级科室所有医生
    alldepDocter = async () => {
        const {activeKey} = this.state
        // console.log(activeKey);
        const res2 = await getdoctorlist({depId: activeKey})
        // console.log(res2);
        this.setState({
            yslist: res2.data[0],
            visiable: 'none',
            visiable1: 'none',
            depId: '',
            depName: '',
            docname: ''
        })
    }
    shou = () => {
        this.setState({
            visiable: 'none',
            visiable1: 'none',
            depId: '',
            depName: '',
            docname: ''
        })
    }
    //
    toMedList = (item) => {
        // console.log(item.key)
        switch (item.key) {
            case 1:

                break;
            case 2:

                break;
            case 3:
                break;
            default:
                break;
        }
    }
    toMedList2 = (item) => {
        // console.log(item.key)
        const {patientId} = this.state
        if (patientId) {
            switch (item.key) {
                case 1:
                    this.props.history.push('/appointment')
                    break;
                case 2:

                    break;
                case 3:
                    // this.props.history.push('/queryreport')
                    Toast.info('暂未开通报告查询功能')
                    break;
                case 4:
                    this.props.history.push('/outpatientpayment')
                    break;
                default:
                    break;
            }
        } else {
            // Toast.info('请先登录')
            this.props.history.push('/login')
        }
    }
    //就诊卡、健康卡
    activeClick = (link) => {
        const {patientId} = this.state
        if (patientId) {
            // if (link == 0) {
            //     this.props.history.push('/cliniccard')
            // } else if (link == 1) {
                this.props.history.push('/healthcard')
            // }
        } else {
            // Toast.info('请先登录')
            this.props.history.push('/login')
        }
    }

    render() {
        const {
            yslist,
            list,
            list2,
            timeNow,
            docname,
            activeKey,
            visiable,
            visiable1,
            category1,
            category2,
            activity,
            depName,
            patientId,
            video1
        } = this.state
        // console.log(docname)
        return (
            <div style={{paddingBottom: "40px"}}>
                <div className='home-head'>
                    <span style={{fontSize: '16px', marginRight: '5px'}}>问医</span>
                    <input type="text" placeholder='搜索症状医生、医院等' onChange={(e) => this.docName(e)}/>
                    <span style={{fontSize: '14px', marginLeft: '5px'}} onClick={() => this.sousuo()}>搜索</span>
                </div>
                {/*<div className='home-classification'>*/}
                {/*    <span>{depName?depName:'科室分类'}</span> */}
                {/*    {visiable1=='block'?<img src={icon6}  style={{width:'18px'}} onClick={()=>this.shou()}/>:<img onClick={()=>this.department()} src={icon1} alt="" />}*/}
                {/*</div>*/}
                {/*<div className='other-use'>*/}
                {/*    <ul className='clear-fix'>*/}
                {/*        {*/}
                {/*            category1.map(item => (*/}
                {/*                <li key={item.categoryId}*/}
                {/*                    onClick={() => this.toMedList(item)}>*/}
                {/*                    <img src={icon4} alt=''/>*/}
                {/*                    <span>{item.name}</span>*/}
                {/*                </li>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </ul>*/}
                {/*</div>*/}
                <div className='other-use'>
                    <ul className='clear-fix'>
                        {
                            category2.map(item => (
                                <li key={item.categoryId}
                                    onClick={() => this.toMedList2(item)}>
                                    <img src={icon4} alt=''/>
                                    <span>{item.name}</span>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <Flex justify='between' className='flex-activity'>
                    {
                        activity.map((item, index) => (
                            <Flex.Item key={index}
                                       className='flex-actie-row'
                                // onClick={()=>this.activeClick(activityLink[index])}
                                       onClick={() => this.activeClick(index)}

                            >
                                <span style={{
                                    position: "absolute", color: "#fff",
                                    marginLeft: "10px", marginTop: '10px'
                                }}>{index == 0 ? '电子健康卡' : '我的就诊卡'}</span>
                                <img src={icon8} alt=''/>
                            </Flex.Item>
                        ))
                    }
                </Flex>
                <div className='home-title'>
                    <img src={icon2} alt="" onClick={() => this.zonghe()}/>
                    {visiable1 == 'block' ? <img src={icon7} onClick={() => this.shou()}/> :
                        <img onClick={() => this.department()} src={icon7} alt=""/>}
                    <img src={icon3} onClick={() => this.price()} alt=""/>
                </div>
                {visiable == 'block' ?
                    <div className='typebox'>
                        <div style={{display: 'flex'}} className='depart'>
                            <div className='depart-left'>
                                <SideBar activeKey={activeKey} onChange={(activeKey) => this.setActiveKey(activeKey)}>
                                    {list.map((item) => (
                                        <SideBar.Item key={item.key} title={item.title}/>
                                    ))}
                                </SideBar>
                            </div>
                            <div className='depart-right'>
                                <p className='depart-all' onClick={() => this.alldepDocter()}>全部</p>
                                {list2.map(item => (
                                    <p onClick={() => this.erjidepart(item.key, item.title)}>{item.title}</p>
                                ))}

                            </div>
                        </div>
                    </div> : null}
                {/*<video width='100%' height='200' src={video1} poster={icon3} controls="controls" autoplay="autoplay" playsinline="true">*/}
                {/*</video>*/}


                {yslist.length ? yslist.map((item, index) => {
                    return (
                        <div className='home-container' onClick={() => this.toinfo(item)} key={index}>
                            <div className='container-box'>
                                <img style={{borderRadius: '50%'}}
                                     src={item && item.dcocterAvatar ? item.dcocterAvatar : icon4} alt=""/>
                                <div className='box-text'>
                                    <p className='box-name'>
                                        <span>{item.docterName}</span><span>{item.title}</span><span>{item.depName}</span>
                                    </p>
                                    <div className='box-title'>
                                       <span>{item.hospital}</span>
                                        {item.docterStartDisturb && (timeNow >= (parseInt(item.docterStartDisturb.split(':')[0] * 60) + parseInt(item.docterStartDisturb.split(':')[1])) &&
                                            item.docterEndDisturb && (timeNow <= parseInt(item.docterEndDisturb.split(':')[0] * 60) + parseInt(item.docterEndDisturb.split(':')[1])) && item.docterDisturb == 1) ?
                                            <span style={{
                                                border: '1px solid #999',
                                                padding: '2px 5px',
                                                color: '#999'
                                            }}>休息中</span> : null}
                                    </div>
                                    <div className='box-evaluate'>
                                        <div>
                                            <img src={icon5} alt=""/> <span>4.8</span>
                                        </div>
                                        <div>
                                            <span>接诊次数</span> <span>{item.countNum}</span>
                                        </div>

                                        <div>
                                            <span>回复速度:</span> <span>较快</span>
                                        </div>
                                    </div>
                                    <div className='box-introduce'>
                                        擅长： {item.expert}
                                    </div>
                                    <div className='box-btn'>
                                        <button>图文</button>
                                        <button>视频</button>
                                    </div>
                                    <div className='box-money'>
                                        <p>￥ <span style={{color: "#FF7777"}}>{item.docterPicCharge / 100}元</span> 起</p>
                                        <p>问医生</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) : <Nodata type='医生'/>}
                <FootNav onRef={this.onRef}/>
            </div>
        )
    }
}

export default withRouter(Platform)