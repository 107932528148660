import React, {Component} from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import {resetdirect} from '../../redux/user.redux'

import './style.less'
import '../../static/css/iconfont.css'
import saoma from '../../static/img/home/saoma.png'
class HeaderNav extends Component{
    constructor(props) {
        super(props)
        this.state = {
            props: props.props,
            city: localStorage.posDistrict?localStorage.posDistrict:null,
            userId: localStorage.type==1?localStorage.userId1:localStorage.type==2?localStorage.userId2:localStorage.userId3,
            onclick: props.onclick
        }
    }

    render(){
        const { props, city, userId, onclick } = this.state

        return(
            <div id='home-header' className='flex-apart-row'>
                {/*<div className='home-header-left flexr'>*/}
                {/*    {city ? <div>{city}</div> : null}*/}
                {/*    /!* <i className='iconfont icon-xiangxia'></i> *!/*/}
                {/*</div>*/}
                <div className='home-header-middle'>
                    <div className='search-container'>
                        <Link to='/search' className='flexr'>
                            <i className='iconfont icon-suosou'></i>
                            <input type='text' readOnly placeholder='搜索药名'/>
                            <span className='search-word'>搜索</span>
                        </Link>
                    </div>
                </div>
                <div className='home-header-right'>
                    {/*<Link onClick={()=>{*/}
                    {/*    if(userId) {*/}
                    {/*        props.history.push('/tidings')*/}
                    {/*    } else {*/}
                    {/*        this.props.redirectDispatch(localStorage.env==='single'?`/?env=single&pharmacyId=${localStorage.pharmacyId}`:'/')*/}
                    {/*        props.history.push('/login')*/}
                    {/*    }*/}
                    {/*}}>*/}
                    {/*    <i className='iconfont icon-xiaoxi1'></i>*/}
                    {/*</Link>*/}
                    <div onClick={onclick} className='saoma'>
                        <img src={saoma}/>
                        <span>扫码</span>
                    </div>
                </div>

            </div>
        )
    }
}
    
// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
    return {
        redirectDispatch(data) {
            dispatch(resetdirect(data))
        },
    }
}

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav)