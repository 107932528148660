import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { Button } from 'antd-mobile'
import { Popup, Space, Toast } from 'antd-mobile-v5'

import Tag from '../../baseui/tag/index'
import { meddetailRequest } from "../../api/request/med"
import { querycartRequest, updatecartRequest, addcartRequest, cartlistRequest, addcartidentical } from '../../api/request/cart'

import './rmd-med.less'
import '../../static/css/iconfont.css'
import defaultImg from '../../static/img/default.png'
import addImg from '../../static/img/home/add.png'
import closeimg from "../../static/img/home/close.png"

function RecommendMed(props) {
    let pharmacyId = '1'
    const recommand = props.recommand
    const getcartnum = props.getcartnum
    const sunthis = props.sunthis
    const getCartList = props.getCartList
    const VIP = localStorage.userVip

    const [visible1, setvisible1] = useState(false)
    const [meditem, setMeditem] = useState(null)
    const [mednum, setMednum] = useState(null)
    const [num, setNum] = useState(1)
    const [cartlist, setcartlist] = useState(null)
    const userId = localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3
    const userId2 = localStorage.getItem("userId2")
    const userId1 = localStorage.getItem("userId1")
    const type = localStorage.type
    // const medId = props.match.params.medId
    // const{redirectDispatch}=props
    const medDetail = (medId) => {
        props.history.push(`/med/${medId}`)
    }
    useEffect(() => {
        if (type == "2" || type == "1") {
            getcart()
        }
    }, [])
    //获取用户购物车数据
    const getcart = async () => {
        const res = await cartlistRequest(userId, pharmacyId)
        //  console.log(res);
        setcartlist(res.data[0])
    }

    // const addCart = (e, val) => {
    //     e.stopPropagation()
    //     const params = {
    //         userId: localStorage.userId,
    //         pharmacyId: val.pharmacyId,
    //         medId: val.medId,
    //         medQuantity: 1
    //     }
    //     querycartRequest(params.userId, params.medId).then(res => {
    //         if(res.code === 1) {
    //             if(res.data[0].length > 0) {
    //                 let data = res.data[0][0]
    //                 const quantity = data.medQuantity + 1
    //                 updatecartRequest(quantity, data.cartId).then(res1 => {
    //                     if(res1.code === 1) {
    //                         Toast.info('已加入购物车', 1)
    //                     }
    //                 })
    //             } else {
    //                 addcartRequest(params).then(res => {
    //                     if(res.code === 1) {
    //                         Toast.info('已加入购物车', 1)
    //                     }
    //                 })
    //             }
    //         }
    //     })
    // }
    //console.log(recommand);
    useEffect(() => {
        // console.log(11);
     
    }, [mednum])
    //显示加购物车页面
    const openaddcar = async (e, v) => {
        if (type == "2" && userId2) {
            e.stopPropagation()
            const res = await meddetailRequest(v.medId)
            //console.log(cartlist);
            setMeditem(res.data[0][0])
            //setMednum(res.data[3][0].stockNum)
            if (cartlist.length) {
                let nownum = 0
                cartlist.forEach(item => {
                    if (item.medId == res.data[0][0].medId) {
                        nownum = item.medQuantity
                    }
                });
                //console.log(res.data[3][0].stockNum - nownum);
                setMednum(res.data[3][0].stockNum - nownum)
            } else {
                setMednum(res.data[3][0].stockNum)
            }
            getcart()
            //console.log(res)
            setvisible1(true)
        } else if (type == "1" && userId1) {
            e.stopPropagation()
            const res = await meddetailRequest(v.medId)
            //console.log(res);
            setMeditem(res.data[0][0])
            setMednum(res.data[3][0].stockNum)
            //console.log(res)
            setvisible1(true)
        } else {
            e.stopPropagation()
            Toast.show({
                content: "请先登录"
            })
            props.history.push("/login/Login1")
        }

        //console.log(v);
    }
    //改变加入数量
    const cahngenum = (e) => {
        // console.log(mednum);
        if (e.target.value) {
            if (isNaN(e.target.value)) {
                Toast.show({
                    content: `请输入数字`,
                    duration: 800
                })
            } else {
                if (parseInt(e.target.value) > mednum) {
                    Toast.show({
                        content: `该药品库存只剩${mednum}`,
                        duration: 800
                    })
                    setNum(mednum)
                } else {
                    setNum(parseInt(e.target.value))
                }
            }
        } else {
            setNum("")
        }


    }
    const movenum = (code) => {
        if (code == 1) {
            if (num > 1) {
                setNum(num - 1)
            }
        } else {
            if (num >= mednum) {
                Toast.show({
                    content: `该药品库存只剩${mednum}`,
                    duration: 800
                })
                setNum(mednum)
            } else {
                setNum(Number(num) + 1)
            }
        }
    }
    //点击添加购物车
    const goshopcar = async () => {

        if (userId) {
            if (num > mednum) {
                if (mednum == 0) {
                    Toast.show({
                        content: "该药品已售完"
                    })
                } else {
                    Toast.show({
                        content: `该药品库存只剩${mednum}`
                    })
                }

            } else {
                if (num == 0) {
                    Toast.show({
                        content: "请输入大于0的数"
                    })
                } else {
                    getcart()
                    let code = false
                    // console.log(cartlist);
                    cartlist.forEach(item => {
                        if (item.medId == meditem.medId) {
                            code = true
                        }
                    });
                    if (num > mednum) {
                        Toast.show({
                            content: `该商品当前仅剩${mednum}`
                        })
                    } else {
                        if (code) {
                            // console.log(num);
                            const res = await addcartidentical({
                                userId: userId,
                                pharmacyId: meditem.pharmacyId,
                                medId: meditem.medId,
                                medQuantity: num
                            })
                            // console.log(res);
                        } else {
                            const res = await addcartRequest({
                                userId: userId,
                                pharmacyId: meditem.pharmacyId,
                                medId: meditem.medId,
                                medQuantity: num
                            })
                            //console.log(res);
                        }

                        if (sunthis) {
                            sunthis.getarrlist()
                        }
                        if (getcartnum) {
                            getcartnum()
                        }
                        if (getCartList) {
                            //console.log(getCartList);
                            getCartList(userId)
                        }
                        Toast.show({
                            content: '添加成功',
                            duration: 1000
                        })
                        getcart()
                        setNum(1)
                        //Toast.success("加入成功")
                        setvisible1(false)
                    }
                }

            }

        } else {
            Toast.show({
                content: '请先登录',
                type: "success",
                duration: 1000
            })
            //Toast.fail("请先登录")
            props.history.push("/login")
        }

    }
    return (
        <div className='recommend-med-area flexr'>
            {recommand.map((v, index) => {
                return (
                    <div className='recommend-meditem flexc' key={index} style={{ height: localStorage.type == "2" ? "300px" : "265px" }}
                        onClick={() => medDetail(v.medId)}>
                        <img className="recommend-meditem-img" onClick={(e) => openaddcar(e, v)} src={addImg} alt="" />
                        <div className='recommend-img'>
                            <img src={v.medPhoto ? v.medPhoto : defaultImg} alt=''
                                onError={(e) => { e.target.onerror = null; e.target.src = defaultImg }} />
                        </div>
                        <div className='recommend-info'>
                            <div className='yaopin-name'>
                                {v.medClassify === '1' ?
                                    <div>
                                        <span className='chufangyao'>处方药</span>
                                        <span>{v.medName ? v.medName.length < 10 ? v.medName : v.medName.substring(0, 10) + "..." : null}</span>
                                        {/* <span>{v.medSpec}</span> */}
                                    </div>
                                    : <div><span>{v.medName ? v.medName.length < 10 ? v.medName : v.medName.substring(0, 10) + "..." : null}</span>
                                        {/* <span>{v.medSpec}</span> */}
                                    </div>
                                }
                                {/* <p style={{marginTop:'5px'}}>{v.medCompany}</p> */}
                            </div>
                            {/* <p>{v.medName}</p> */}
                            {/* <span>{v.medSpec}</span> */}
                            {type == "2" ?
                                <>
                                    <p style={{ marginTop: "5px", fontSize: "12px", color: "#999999", height: "15px" }}>{v.medSpec}</p>
                                    <p style={{ fontSize: "12px", color: "#999999" }}>{v.medCompany ? v.medCompany.length < 10 ? v.medCompany : v.medCompany.substring(0, 10) + "..." : null}</p>
                                </> : null
                            }


                            <div className='recommend-meditem-row flex-row'>
                                {type == "2" ? <>
                                    {userId2 ? <span className='recommend-price'>¥{v.medPrice / 100}
                                        {v.originPrice === v.medPrice ? null :
                                            <span className='recommend-spike'>¥{v.originPrice / 100}</span>}</span> :
                                        <span className='recommend-price' style={{ fontSize: "13px" }}>登陆后可见</span>
                                    }
                                </> : null}
                                {type == "1" ? <>
                                    <span className='recommend-price'>¥{v.medPrice / 100}
                                        {v.originPrice === v.medPrice ? null :
                                            <span className='recommend-spike'>¥{v.originPrice / 100}</span>}
                                    </span>
                                    {/* {userId1 ? <span className='recommend-price'>¥{v.medPrice / 100}
                                        {v.originPrice === v.medPrice ? null :
                                            <span className='recommend-spike'>¥{v.originPrice / 100}</span>}
                                    </span> :
                                        <span className='recommend-price' style={{ fontSize: "13px" }}>登陆后可见</span>
                                    } */}
                                </> : null}

                                <div className='med-viplevel'>
                                    {!VIP || VIP === '0' ? null :
                                        <Tag type='vip' text={`LV${VIP}会员`} />
                                    }
                                </div>
                                {/* {v.originPrice === v.medPrice ? null :
                                    <span className='recommend-spike'>¥{v.originPrice / 100}</span>} */}
                                {/* <div className='recommand-buy flex-row'
                                onClick={(e)=>addCart(e, v)}>
                                    <i className='iconfont icon-tubiaozhizuomoban'></i>
                                </div> */}
                                {/* <img src={addImg} onClick={()=>addCart(e, v)} alt="" /> */}
                            </div>
                        </div>
                    </div>
                )
            })}

            <Popup
                visible={visible1}
                className="popup"
                onMaskClick={() => {
                    setvisible1(false)
                    setNum(1)
                }}
                bodyStyle={{ minHeight: '60vh', borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
            >
                <div className="addbox">
                    <div className="addbox-top" style={{ padding: "10px", textAlign: "right" }}>
                        <img src={closeimg} onClick={() => {
                            setvisible1(false)
                            setNum(1)
                        }} style={{ width: "18px" }} alt="" />
                    </div>
                    <div style={{ width: "100%", paddingTop: "0px", paddingLeft: "20px", height: "100px", display: "flex", flexWrap: "nowrap", alignItems: "center" }}>
                        <img src={meditem ? meditem.medPhoto ? meditem.medPhoto : defaultImg : null} style={{ width: "80px", height: "80px", marginRight: "10px" }} alt="" />
                        <div>
                            <p style={{ fontSize: "18px", fontWeight: 600 }}>{meditem ? meditem.medComName : null}</p>
                            <p style={{ fontSize: "24px" }}>
                                <span style={{ marginRight: "10px" }}>原价</span><span style={{ color: "#FF5555" }}>￥{meditem ? meditem.medPrice / 100 : null}</span>
                            </p>
                        </div>

                    </div>
                    <div style={{ paddingLeft: "20px", lineHeight: "25px", marginBottom: "10px" }}>
                        <p>
                            库存 <span style={{ marginLeft: "10px" }}>{mednum ? mednum : 0}</span>
                        </p>
                        <p>
                            效期 <span style={{ marginLeft: "10px" }}>{meditem ? meditem.expiration : null}</span>
                        </p>
                    </div>
                    <div style={{ width: "100%", marginBottom: "30px", paddingRight: "20px", paddingLeft: "20px", display: "flex", flexWrap: "nowrap", alignItems: "center", justifyContent: "space-between" }}>
                        <p>购买数量</p>
                        <p>
                            <button onClick={() => movenum(1)} style={{ width: "36px", height: "21px", border: "none", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }}>-</button><input type="text" value={num} onChange={(e) => cahngenum(e)} style={{ height: "20px", textAlign: "center", width: "50px", borderWidth: "1px", borderColor: "rgb(239, 239, 239)" }} /><button onClick={() => movenum(2)} style={{ width: "36px", height: "21px", border: "none", borderTopRightRadius: "10px", borderBottomRightRadius: "10px" }}>+</button>
                        </p>
                    </div>
                    <div style={{ paddingLeft: "20px", marginBottom: "70px" }}>
                        <span style={{ marginRight: "10px", fontSize: "15px" }}>合计</span>  <span style={{ color: "#FF5555", fontSize: "15px" }}>￥{meditem ? num * meditem.medPrice / 100 : null} </span>
                    </div>
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <button onClick={goshopcar} style={{ width: "330px", border: "none", height: "42px", backgroundColor: "#FF5555", borderRadius: "21px" }}>确定</button>
                    </div>

                </div>
            </Popup>
        </div>
    )
}

export default withRouter(RecommendMed)