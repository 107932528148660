import React, { Component } from 'react'
import { Modal,CascadePicker,DatePickerView ,DatePicker } from "antd-mobile-v5"
import { InputItem, Picker, Toast  } from 'antd-mobile'
import "./lookhealthcard.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/you.png"
import icon4 from "../../../static/img/sl.png"
import {getProvince,getCity} from '../../../api/hospitalapi/address'
import {addElectronicIdcard, scanDetails} from '../../../api/hospitalapi/electronic'
import {logDOM} from "@testing-library/react";
import getUrl from '../../../component/GetUrlParam'
/*
*  挂号
* */

export default class Lookhealthcard extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            patientId: localStorage.getItem("patientId"),
            list:[],
            name:'',
            idcard:'',
            borthDate:'',
            sexAll:[
                { label: '男', value: '男' },
                { label: '女', value: '女'}
            ],
            sex:'',
            phone:'',
            nationAll:[
                { label: '汉族', value: '汉族'},
                { label: '满族', value: '满族'},
                { label: '蒙古族', value: '蒙古族'},
                { label: '回族', value: '回族'},
                { label: '藏族', value: '藏族'},
                { label: '维吾尔族', value: '维吾尔族'},
                { label: '苗族', value: '苗族'},
                { label: '彝族', value: '彝族'},
                { label: '壮族', value: '壮族'},
                { label: '布依族', value: '布依族'},
                { label: '侗族', value: '侗族'},
                { label: '瑶族', value: '瑶族'},
                { label: '白族', value: '白族'},
                { label: '土家族', value: '土家族'},
                { label: '哈尼族', value: '哈尼族'},
                { label: '哈萨克族', value: '哈萨克族'},
                { label: '傣族', value: '傣族'},
                { label: '黎族', value: '黎族'},
                { label: '傈僳族', value: '傈僳族'},
                { label: '佤族', value: '佤族'},
                { label: '畲族', value: '畲族'},
                { label: '高山族', value: '高山族'},
                { label: '拉祜族', value: '拉祜族'},
                { label: '水族', value: '水族'},
                { label: '东乡族', value: '东乡族'},
                { label: '纳西族', value: '纳西族'},
                { label: '景颇族', value: '景颇族'},
                { label: '柯尔克孜族', value: '柯尔克孜族'},
                { label: '土族', value: '土族'},
                { label: '达斡尔族', value: '达斡尔族'},
                { label: '仫佬族', value: '仫佬族'},
                { label: '羌族', value: '羌族'},
                { label: '布朗族', value: '布朗族'},
                { label: '撒拉族', value: '撒拉族'},
                { label: '毛南族', value: '毛南族'},
                { label: '仡佬族', value: '仡佬族'},
                { label: '锡伯族', value: '锡伯族'},
                { label: '阿昌族', value: '阿昌族'},
                { label: '普米族', value: '普米族'},
                { label: '朝鲜族', value: '朝鲜族'},
                { label: '塔吉克族', value: '塔吉克族'},
                { label: '怒族', value: '怒族'},
                { label: '乌孜别克族', value: '乌孜别克族'},
                { label: '俄罗斯族', value: '俄罗斯族'},
                { label: '鄂温克族', value: '鄂温克族'},
                { label: '德昂族', value: '德昂族'},
                { label: '保安族', value: '保安族'},
                { label: '裕固族', value: '裕固族'},
                { label: '京族', value: '京族'},
                { label: '塔塔尔族', value: '塔塔尔族'},
                { label: '独龙族', value: '独龙族'},
                { label: '鄂伦春族', value: '鄂伦春族'},
                { label: '赫哲族', value: '赫哲族'},
                { label: '门巴族', value: '门巴族'},
                { label: '珞巴族', value: '珞巴族'},
                { label: '基诺族', value: '基诺族'},
            ],
            nation:'',
            residence:'',//户籍地
            address:'',
            visible:false,
            visible1:false,
            visible2:false,
            visible3:false,
            visible4:false,
            value:'',
            options : [
                {
                    label: '浙江',
                    value: '浙江',
                    children: [
                        {
                            label: '杭州',
                            value: '杭州',
                        },
                        {
                            label: '宁波',
                            value: '宁波',}
                    ],
                },
                {
                    label: '江苏',
                    value: '江苏',
                    children: [
                        {
                            label: '南京',
                            value: '南京',
                        },
                        {
                            label: '苏州',
                            value: '苏州',
                        },
                    ],
                },
            ],
            provinces:'',
            cities:'',
            healthCardNumber:''
        }
    }
    componentDidMount = () => {
        const {patientId} =this.state
        this.setState({
            healthCardNumber:getUrl('healthCardNumber', this.props.location.search)
        },()=>{
            scanDetails({healthCardNumber:getUrl('healthCardNumber', this.props.location.search)
        }).then(res=>{
                // console.log(res)
                if (res&&res.data&&res.data[0].length){
                    this.setState({
                        list:res.data[0][0]
                    })
                }
            })
        })
    }
    toBack=()=>{
        const {list}=this.state
        this.props.history.push(`/healthcardnews?id=${list.id}`)
    }


    render() {
        const { list } = this.state
        const now = new Date()
        const now1 = new Date('1800-01-01')
        return (
            <div className='healthcard2-setbox'>
                <div className='healthcard2-top'>
                    <img src={icon1} alt="" onClick={() => this.toBack() }/>   电子健康卡
                </div>
                <div className='Lookhealthcard-news'>
                    <p>
                        <span>姓名</span>
                        <span>{list.patientName}</span>
                    </p>
                    <p>
                        <span>证件类型</span>
                        <span>中国大陆居民身份证</span>
                    </p>
                    <p>
                        <span>证件号</span>
                        <span>{list.idCard1}</span>
                    </p>
                    <p>
                        <span>出生日期</span>
                        <span>{list.birthday}</span>
                    </p>
                    <p>
                        <span>性别</span>
                        <span>{list.sex}</span>
                    </p>
                    <p>
                        <span>手机号</span>
                        <span>{list.phone}</span>
                    </p>
                    <p>
                        <span>户籍地</span>
                        <span>{list.registeredResidence}</span>
                    </p>
                    <p>
                        <span>民族</span>
                        <span>{list.nation}</span>
                    </p>
                    <p>
                        <span>地址</span>
                        <span>{list.address}</span>
                    </p>

                </div>
            </div>
        )
    }
}
