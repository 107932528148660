import React, {useState, useEffect, Component} from 'react'

import Head from '../../component/Head'
import Nodata from '../../component/Nodata'

import { msglistRequest, updateMsgRequest } from '../../api/request/msg'

import './style.less'
import getUrl from "../../component/GetUrlParam";
import {allGhDeperdment} from "../../api/hospitalapi/chat";
import icon1 from "../../static/img/yy/fanhuihei.png";
import icon2 from "../../static/img/yy/yy-bgc.png";
import icon3 from "../../static/img/yy/ylpt.png";
import icon4 from "../../static/img/yy/hzh5.png";
import icon5 from "../../static/img/yy/doch5.png";
import icon6 from "../../static/img/yy/phah5.png";
import ga from "../../static/img/yy/ga.png"
import {Toast} from "antd-mobile";
import {zfUrl,jumpUrl1,jumpUrl2} from "../../publicurl";

export default class entrance extends Component {
    constructor() {
        super()
        this.state = {
            userId: localStorage.getItem("patientId"),
        }
    }
    componentDidMount =async () => {
        const {userId} =this.state

    }
    setActiveKey=(activeKey,id)=>{
        this.setState({
            activeKey:activeKey.toString()
        })
        // console.log(activeKey,id)
        this.props.history.replace(`/appointment?index=${activeKey}`)
        this.props.history.push(`/register?id0=${id}&index=${activeKey}`)
    }
    toDoc=()=>{
        window.location.href = `${jumpUrl2}/index.html#/`
    }
    toPha=()=>{
        window.location.href = `${jumpUrl1}/index.html#/`
    }
    render() {
        const { userId} = this.state
        return (
            <div className='allentrance-outer'>
                <div className='allentrance-img'>
                    <img src={icon3}/>
                </div>
                <div className='allentrance-three'>
                    <img src={icon4} onClick={()=>this.props.history.push('/entrance')}/>
                    <img src={icon5} onClick={()=>this.toDoc()}/>
                    <img src={icon6} onClick={()=>this.toPha()}/>
                </div>
                <div className='allentrance-bottom'>
                    <p style={{textAlign:'center',lineHeight:'24px'}}>
                        <img style={{width:'20px',marginRight:'5px',position:'relative',top:"5px"}} src={ga} alt="" />
                        <a href="https://beian.miit.gov.cn/#/Integrated/index">蜀ICP备2022000233号-1</a>
                    </p>
                    <p>增值电信业务经营许可证编号：川B2-20220303</p>
                    <p>信息系统安全等级保护备案编号：51060013003-21001</p>
                    <p>Copyright中江云益健技术开发团队</p>
                </div>
            </div>
        )
    }
}