import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../../../../static/img/yy/fanhuihei.png"
import { uploadpic } from "../../../../../api/hospitalapi/upload"
import { ImagePicker, Toast } from "antd-mobile"
import { changeuser } from "../../../../../api/hospitalapi/home"
import { zipImg } from '../../../../../component/zipImage'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            fileList:[]
        }
    }
    imgChange = async (files, type, index) => {
        if (type == "add") {
            let file = files[files.length - 1].file
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if (file.size < 1024 * 1024) {
                    let formData = new FormData()
                    const res = await zipImg(file)
                    // console.log(res);
                    formData.append('file', res)
                    const res2 = await uploadpic(formData)
                    //  console.log(res2);
                    this.setState({
                        fileList: [...files]
                    })
                    const res3 = await changeuser({
                        patientId: localStorage.getItem("patientId"),
                        patientAvatar: res2.data
                    })
                    if (res3.code == 1) {
                        Toast.success("修改成功", 1)
                        localStorage.setItem("patientAvatar",res2.data)
                    }
                }else{
                    Toast.show('请上传小于1M的图片', 1)
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            this.setState({
                fileList: []
            })
        }
    }
    gobank=()=>{
        window.history.back()
    }
    render() {
        const {fileList} =this.state
        return (
            <div className='userpicbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>更换头像</h1> 
                    {/* <span onClick={this.updata}>保存</span>' */}
                </div>
                <div className='box-pic'>
                    <ImagePicker
                        files={fileList}
                        length={1}
                        style={{ width: "100px", height: "100px", display: "inline-block", verticalAlign: "top" }}
                        onChange={this.imgChange}
                        selectable={fileList.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png' />
                        <p>上传头像</p>
                </div>
            </div>
        )
    }
}
