import { withRouter } from 'react-router-dom'
import { Toast, Modal,ImagePicker} from 'antd-mobile'
import Button from '../../baseui/fuctionbutton/index'
import React,{useState,useEffect} from 'react'
import { oderdetailRequest,applyRefundRequest,addRemittance,upOrderStuRequest,delRemittance,remittanceList,changenum} from '../../api/request/order'
import './myorder.less'
import '../../static/css/iconfont.css'
import defaultImg from '../../static/img/default.png'
import { logDOM } from '@testing-library/react'
import {zfUrl,code} from "../../publicurl"
import { uploadImg } from '../../api/request/upload'
import { zipImg } from '../../component/zipImage/index'
import close from '../../static/img/close.png'
import {  updateAftersale, cancelAftersale, aftersaleList } from '../../api/request/aftersale'
const { alert } = Modal
const newList = (orderNumber, medList) => {
    // console.log(medList);
    let list = []
    medList.forEach(item => {
        if(item.orderNumber === orderNumber) {
            list.push(item)
        }
    })
    return list
}
// const time = localStorage.getItem("nowTime")

//0 全部订单 1 待评价 2 退款
function Orderitem(props) {
    const userId = localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3
    const { state, onclick, delOrder, cancelRefund, pharmacy, medList, refreshList, applyRefund } = props
    const newMedList = newList(pharmacy.orderNumber, medList)
    const [files1, setFiles1] = useState([])
    const [evaluatePic1, setEvaluatePic1] = useState([])
    const [visible2, setVisible2] = useState('none');
    const [remittanceId, setremittanceId] = useState()
    const [aftersaleId, setaftersaleId] = useState()
    const [listMed, setlistMed] = useState()
    const [oneMed, setoneMed] = useState()
    // const [visible1, setVisible1] = useState('none');
    // const [refundModal, setRefundModal] = useState(false)

// useEffect(() => {
//     let arr=[]
//     let arr1=[]
//     let arr2=[]
//     let arr3=[]
//     let arr4=[]
//     newMedList&&newMedList.map(item=>{
//         if (item.orderNumber==pharmacy.orderNumber){
//             arr.push(item)
//         }
//     })
//     console.log(arr)
//     // if (state==0){
//     //     setoneMed(arr[0])
//     // }else if(state==1){
//     //     arr.map(item1=>{
//     //         if (item1.orderState=='-2'){
//     //             arr1.push(item1)
//     //         }
//     //     })
//     //     setoneMed(arr1[0])
//     // }else if(state==2){
//     //     arr.map(item1=>{
//     //         if (item1.orderState=='1'||item1.orderState=='0'){
//     //             arr2.push(item1)
//     //         }
//     //     })
//     //     setoneMed(arr2[0])
//     // }else if(state==3){
//     //     arr.map(item1=>{
//     //         if (item1.orderState=='2'){
//     //             arr3.push(item1)
//     //         }
//     //     })
//     //     setoneMed(arr3[0])
//     // }else if(state==4){
//     //     arr.map(item1=>{
//     //         if (item1.orderState=='4'){
//     //             arr4.push(item1)
//     //         }
//     //     })
//     //     setoneMed(arr4[0])
//     // }
//     setlistMed(arr)
// }, [])
    // console.log(oneMed)
    const updateOrder = () => {
        let orderMedList = []
        newMedList.forEach(item => {
            orderMedList.push({ 
                orderMedId: item.orderMedId,
                medId: item.medId,
                medName: item.medName,
                medPhoto: item.medPhoto,
                medSpec: item.medSpec,
                medPrice: item.medPrice,
                cartPrice: item.medPrice*item.medQuantity,
                medQuantity: item.medQuantity
            })
        })
        const cartArr = [{
            pharmacy: {
                orderNumber: pharmacy.orderNumber,
                orderShipping: pharmacy.orderShipping,
                pharmacyId: pharmacy.pharmacyId,
                pharmacyName: pharmacy.pharmacyName,
                payMethod: pharmacy.payMethod,
                freight: pharmacy.freight
            },
            medList: orderMedList
        }]
        props.history.push({ pathname: '/fillorder', state: cartArr })
    }
    const payFor = (e,orderNumber, orderAmount) => {
        e.stopPropagation()
        const orderName = `${newMedList[0].medName}等`
        let b=(new Date()).getTime()
        window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${orderNumber}&total_fee=${orderAmount}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
    }

    const confirm = (e, orderNumber) => {
        refreshList(e, orderNumber)
    }
    const refundApply = (e, orderNumber) => {
        e.stopPropagation()
        applyRefund(e, orderNumber)
    }
    
    const buyAgin = () => {
        let orderMedList = []
        newMedList.forEach(item => {
            orderMedList.push({
                cartId: 0,
                medId: item.medId,
                medName: item.medName,
                medPhoto: item.medPhoto,
                medSpec: item.medSpec,
                medPrice: item.medPrice,
                cartPrice: item.medPrice*item.medQuantity,
                medQuantity: item.medQuantity
            })
        })
        const cartArr = [{
            pharmacy: {
                orderShipping: pharmacy.orderShipping,
                pharmacyId: pharmacy.pharmacyId,
                pharmacyName: pharmacy.pharmacyName,
                payMethod: pharmacy.payMethod,
                freight: pharmacy.freight,
            },
            medList: orderMedList
        }]
        // console.log(cartArr.length);
        props.history.push({ pathname: '/fillorder', state: cartArr })
    }
    const toComment = (orderNumber) => {
        props.history.push(`/comment?orderNumber=${orderNumber}`)
    }
// 退款
    const sureRefund = () => {
        // setVisible1('block')
        if (localStorage.type==='2') {
            alert('选择退款路径？', '', [
                { text: '取消申请', onPress: () => console.log('cancel'), style: 'default' },
                {   
                    text: '原路返回', onPress: () =>{
                        const params = {
                            orderState: '5',
                            refundReason: '',
                            orderNumber: pharmacy.orderNumber,
                            refundOriginator:0,
                            refundDestination:0,
                        }
                        applyRefundRequest(params).then(res => {
                            // console.log(res);
                            if (res.code === 1) {
                                window.location.reload()
                            }
                        })
                    }
                },
                {
                    text: '退款到钱包', onPress: () => {
                        const params = {
                            orderState: '5',
                            refundReason: '',
                            orderNumber: pharmacy.orderNumber,
                            refundOriginator:0,
                            refundDestination:1,
                        }
                        applyRefundRequest(params).then(res => {
                            // console.log(res);
                            if (res.code === 1) {
                                window.location.reload()
                            }
                        })
                    }
                },
            ])
        }else{
            alert('确认退款？', '', [
                { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
                {
                    text: '确认', onPress: () => {
                        const params = {
                            orderState: '5',
                            refundReason: '',
                            orderNumber: pharmacy.orderNumber,
                            refundOriginator:0
                        }
                        applyRefundRequest(params).then(res => {
                            // console.log(res);
                            if (res.code === 1) {
                                window.location.reload()
                            }
                        })
                    }
                },
            ])
        }
        
    }

    const imgChange1 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList =evaluatePic1
        if(type === 'add') {
            let file = files[files.length-1].file
            // console.log(file);
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    // console.log(res1);
                    // console.log(files);
                    if(res1.code === 2) {
                        setFiles1(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic1(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles1(files)
            imgList.splice(index, 1)
            setEvaluatePic1(imgList)
            Toast.hide()
        }
    }
    const dianji=()=>{
        setVisible2('none')
    }
    const dianji1=(e)=>{
        e.stopPropagation()
    }
    const uploadPz=()=>{
        if (evaluatePic1.length>0) {
            let data={
                orderNumber:pharmacy.orderNumber,
                remittancePath:evaluatePic1
            }
            addRemittance(data).then(res=>{
                // console.log(res);
                if (res.code==1) {
                    upOrderStuRequest({ orderState: '-5', orderNumber: pharmacy.orderNumber }).then(res1 => {
                        if (res1.code==1) {
                            window.location.reload()
                        }
                    })
                }
            })
        }else{
            Toast.info('未上传转账凭证')
        }
    }
    const delPz=()=>{
        alert('确认删除凭证？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '确认', onPress: () => {
                    remittanceList({orderNumber:pharmacy.orderNumber}).then(res=>{
                        // console.log(res);
                        if (res.data[0].length) {
                            delRemittance({remittanceId:res.data[0][0].remittance_id,orderNumber:pharmacy.orderNumber}).then(res1=>{
                                // console.log(res1);
                                if (res1.code==1) {
                                    window.location.reload()
                                }
                            })
                        }
                    })
                }
            },
        ])
       
    }
    // 上传支付凭证
    const sureUp=()=>{
        setVisible2('block')
        remittanceList({orderNumber:pharmacy.orderNumber}).then(res=>{
            // console.log(res);
            if (res.data[0].length) {
                let b=res.data[0][0].remittance_id
                setremittanceId(b)
            }
        })
        aftersaleList({userId,orderTime:'down'}).then(res=>{
            // console.log(res);
            if (res.code==1) {
                if (res.data[0].length>0) {
                    // setVisible1('block')
                    let a=[]
                    res.data[0].map(item=>{
                        if (item.order_number===pharmacy.orderNumber) {
                            a.push(item)
                            setaftersaleId(a[0].aftersale_id)
                        }
                    })
                }
            }
        })
    }
    const afterMarket1=()=>{
        alert('是否确认取消售后申请？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '确认', onPress: () => {
                    cancelAftersale({aftersaleId}).then(res=>{
                        // console.log(res);
                        if (res.code==1) {
                            upOrderStuRequest({ orderState: '0', orderNumber: pharmacy.orderNumber }).then(res1 => {
                                if (res1.code==1) {
                                    props.history.push(`/mine/order`)
                                }
                            })
                        }
                    })
                }
            },
        ])
        
    }
    // 取消订单
    const sureRefund1 = () => {
        alert('是否确认取消订单？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '确认', onPress: () => {
                    const array=[]
                    oderdetailRequest(pharmacy.orderNumber).then(res=>{
                        // console.log(res);
                        res.data[1].forEach(item=>{
                            array.push({
                                stockNum:item.medQuantity,
                                med_id:item.medId
                            })
                        })
                        changenum({array}).then(res1=>{
                            if (res1.code==1) {
                                upOrderStuRequest({ orderState: '4', orderNumber: pharmacy.orderNumber }).then(res2 => {
                                    // console.log(res2);
                                    if (res2.code === 1) {
                                        window.location.reload()
                                        // Toast.info('已确认收货', 1)
                                    }
                                })
                            }
                        })
                    })
                }
            },
        ])
    }
    const confirmReceipt1=()=>{
        props.history.push(`/mine/order/aftermarket?tradeno=${pharmacy.orderNumber}`)
    }
    const afterMarket = (orderNumber) => {
        props.history.push(`/mine/order/aftermarket?tradeno=${orderNumber}`)
    }
    // console.log(newMedList)
    return(
        <>
            <div className='order-whole flexc'>
                <div className='order-alone flexr'
                onClick={()=>onclick(pharmacy.orderNumber)}>
                    <div className='order1-left flexc'>
                        <div className='order-shopname' >
                            {/*<img src={pharmacy.medPhoto} alt="" />*/}
                            <div className='order-shopname-left'>
                                    订单号：{pharmacy.orderNumber}
                            </div>
                            <div className='order-right flexc'>
                                {state<=5?
                                    <div className='order-state-row'>
                                        {state===0 && pharmacy.orderState==='-2'?<span>待付款</span>:null}
                                        {state===0 && pharmacy.orderState==='-1'?<span style={{marginLeft:'-25px'}}>商家已接单</span>:null}
                                        {state===0 && pharmacy.orderState==='1'?<span className='quxiao'>待自提</span>:null}
                                        {state===0 && pharmacy.orderState==='0'?<span className='quxiao'>待收货</span>:null}
                                        {state===0 && pharmacy.orderState==='4'?<span className='quxiao'>已取消</span>:null}
                                        {state===0 && pharmacy.orderState==='2'?<span className='quxiao'>已完成</span>:null}
                                        {state===0 && pharmacy.orderState==='3'?<span className='quxiao'>已完成</span>:null}
                                        {state===0 && pharmacy.orderState==='6'?<span>退款成功</span>:null}
                                        {state===0 && pharmacy.orderState==='7'?<span style={{marginLeft:'-15px'}}>退款失败</span>:null}
                                        {state===0 && pharmacy.orderState==='-3'?<span style={{marginLeft:'-25px'}}>待接收订单</span>:null}
                                        {state===1?
                                            <div className='order-state-row'>
                                                <div className='order-state-row'>
                                                    {pharmacy.orderState==='-2'?<span style={{marginLeft:'-10px'}} className='refund-apply'>待付款</span>:null}
                                                </div>
                                            </div>
                                            :null}
                                        {state===2?
                                            <div className='order-state-row'>
                                                <div className='order-state-row'>
                                                    {pharmacy.orderState==='0'?<span style={{marginLeft:'-10px'}} className='refund-apply'>配送中</span>:null}
                                                    {pharmacy.orderState==='1'?<span style={{marginLeft:'-10px'}} className='refund-apply'>待自提</span>:null}
                                                </div>
                                            </div>
                                        :null}
                                        {state===3?
                                        <div className='order-state-row'>
                                            <div className='order-state-row' style={{marginLeft:'-10px'}}>
                                                {pharmacy.orderState==='2'?<span style={{marginLeft:'-10px'}} className='refund-apply'>已完成</span>:null}
                                            </div>
                                        </div>
                                        :null}
                                        {state===4?
                                            <div className='order-state-row'>
                                                {pharmacy.orderState==='4'?<span style={{marginLeft:'-10px'}} className='refund-apply'>已取消</span>:null}
                                            </div>
                                        :null}
                                    </div>
                                :null}
                            </div>
                        </div>
                        <div className='flexr' >
                        {newMedList&&newMedList.length > 0?
                            newMedList.map((item, index) => (
                                pharmacy.orderNumber == item.orderNumber&&index==0 ?
                            <div className='med-col' key={index}  >
                                <div className='myorder-contend'>
                                    <div className='myorder-contend-left'>
                                        <div className='med-detail flexc'>
                                            <p>{newMedList[0].medName}{newMedList[0].medSpec}</p>
                                            {newMedList.length>1?<span style={{color: '#9A9A9A',marginTop:'20px'}}>其余{newMedList.length-1}件药品</span>:null}
                                            <p style={{color: '#2DD1AF',marginTop:'20px'}}>{pharmacy.orderShipping=='2'?'配送':'自提'}</p>
                                        </div>
                                    </div>
                                    <div className='order-show-num'>
                                        <span>x{pharmacy.quantity}</span>
                                        <p>¥{(pharmacy.orderAmount/100).toFixed(2)}</p>
                                    </div>
                                </div>
                                {/*}*/}
                            </div>: null))
                        : null}
                        </div>
                    </div>
                    <div className='order-fuction flexr'>
                        {state===0?
                        <div className='order-fuction-contend'>
                            {/*{pharmacy.orderState!='0'&&pharmacy.orderState!='1'?*/}
                            {/*<>*/}
                            {/*    <p onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除</p>*/}
                            {/*/!*<div>*!/*/}
                            {/*/!*    /!* <p className='a' onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除记录</p>*!/*/}
                            {/*/!*    <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>查看详情</p> *!/*!/*/}
                            {/*/!*    <p className='a' onClick={()=>buyAgin()}>重新下单</p>*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*</>:null}*/}
                            {pharmacy.orderState==='-2'?
                            <>
                                <p style={{marginLeft:'5px'}} onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除</p>
                            <div>
                            {/* <Button name='修改订单'
                                onClick={updateOrder}/> */}
                                {/* <Button name='去支付' basecolor='#297FE3'
                                onClick={()=>payFor(pharmacy.orderNumber, pharmacy.orderAmount)}/> */}
                                {/* <p className='a' onClick={updateOrder}>修改订单</p> */}
                                <p className='b' onClick={(e)=>payFor(e,pharmacy.orderNumber, pharmacy.orderAmount)}>去支付</p>
                            </div></>:
                                pharmacy.orderState!='1'&&pharmacy.orderState!='0'&&pharmacy.orderState!='-3'?
                            <>
                                <p style={{marginLeft:'5px'}} onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除</p>
                                <div>
                                    {/*<p className='a' onClick={(e)=>refundApply(e, pharmacy.orderNumber)}>取消订单</p>*/}
                                    <p className='b'>查看详情</p>
                                    {/*<p className='b' onClick={(e)=>confirm(e, pharmacy.orderNumber)}>确认收货</p>*/}
                                </div>
                            </>:<>
                                    <p></p>
                                    <div>
                                        {/*<p className='a' onClick={(e)=>refundApply(e, pharmacy.orderNumber)}>取消订单</p>*/}
                                        <p className='b'>查看详情</p>
                                        {/*<p className='b' onClick={(e)=>confirm(e, pharmacy.orderNumber)}>确认收货</p>*/}
                                    </div>
                                </>
                            }
                        </div>
                        :null}
                        {state===1?
                        <div className='order-fuction-contend'>
                            <p style={{marginLeft:'5px'}} onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除</p>
                            {pharmacy.orderState==='-2'?
                            <div>
                                <p className='b' onClick={(e)=>payFor(e,pharmacy.orderNumber, pharmacy.orderAmount)}>去支付</p>
                            </div>:null}
                        </div>
                        :null}
                        {state===2?
                        <div className='order-fuction-contend'>
                            <p></p>
                            <div>
                                <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>查看详情</p>
                            </div>
                            {/* {pharmacy.orderState==='6'? <div>
                                <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>退款详情</p>
                            </div>:null} */}
                        </div>
                        :null}
                        {state===3|| state===4?
                            <div className='order-fuction-contend'>
                                <p style={{marginLeft:'5px'}} onClick={(e)=>delOrder(e, pharmacy.orderNumber)}>删除</p>
                                <div>
                                    <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>查看详情</p>
                                </div>
                                {/* {pharmacy.orderState==='6'? <div>
                                <p className='b' onClick={()=>onclick(pharmacy.orderNumber)}>退款详情</p>
                            </div>:null} */}
                            </div>
                            :null}
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Orderitem)