import React ,{ useState, useEffect }from 'react'

import Head from '../../../component/Head'
import ChooseButon from '../../../baseui/choosebutton/index'
import './style.less'

import { addressLists ,updateAdressRequest} from '../../../api/hospitalapi/address'
import Nodata from "../../../component/Nodata";

export default function Viewrecord(props) {
    const userId =localStorage.getItem('patientId')
    const [addressList, setAddressList] = useState([])
    const [count, setCount] = useState([])


    useEffect(() => {
        // console.log(userId);
        if (userId){
            addressLists(userId).then(res => {
                // console.log(res);
                if(res.code === 1 && res.data[0].length > 0) {
                    // if (res.data[0].length == 1) {
                    //     res.data[0][0].addressDefault='1'
                    let count1=0
                    res.data[0].map(item=>{
                        if (item.addressDefault=='1') {
                            count1 += 1
                        }
                    })
                    setCount(count1)
                    // console.log(count1);
                    // if (count1==0) {
                    //     const params = {
                    //         addressId: res.data[0][0].addressId,
                    //         addressDefault:'1',
                    //         province: res.data[0][0].province,
                    //         city: res.data[0][0].city,
                    //         district: res.data[0][0].district,
                    //         address: res.data[0][0].address,
                    //         addressee: res.data[0][0].addressee,
                    //         phone: res.data[0][0].phone,
                    //     }
                    //     console.log(params)
                    //     updateAdressRequest(params).then(res => {
                    //         console.log(res);
                    //     })
                    // }
                    setAddressList(res.data[0])
                }
            })
        }else{
            props.history.push(`/login`)
        }

    }, [count,userId])
    const editAddress = (type, id=0) => {
        props.history.push(`/mine/adress/edit/${type}/${id}`)
    }
    // 获取用户添加的地址渲染到界面
    const adressitem=()=>{
        return(
            <div className='screen flexc adresslist'>
                {addressList.length?addressList.map((v, index)=>(
                    <div className='adressitem' key={index}>
                        <div className='adress-area flexr'>
                            {v.addressDefault==='1'?<div>默认</div>:null}
                            <span>{v.province+v.city+v.district}</span>
                        </div>
                        <span className='adress-street'>{v.address}</span>
                        <div className='adress-person-info'>
                            <span>{v.addressee} </span>
                            <span> {v.phone}</span>
                        </div>
                        <div className='adress-edit'
                        onClick={()=>editAddress('refrash', v.addressId)}
                        to='/mine/adress/edit/refrash'>
                            <i className='iconfont icon-bianji'></i>
                        </div>
                    </div>
                )):<Nodata type='地址'/>}
            </div>

        )
    }
    
    return(
        <div>
            <Head title='我的地址'/>
            {adressitem()}
            <div onClick={()=>editAddress('add')}>
                <ChooseButon flex='col'>
                    <div>+添加地址</div>
                </ChooseButon>
            </div>
        </div>
    )
}