import { axiosInstance, } from '../config'
// 单个药店所有药品
export const storeMedPage = (data) => {
    return axiosInstance.get(`/faas/hosp/wzpatient/medlists?pharmacyId=${data.pharmacyId}&page=${data.page}&size=${data.size}&content=${data.content}&medId=${data.medId}`)
    // return axiosInstance.post(`/faas/hosp/wzpatient/medlists?pharmacyId=${data.pharmacyId}&page=${data.page}&size=${data.size}&content=${data.content}`,data.medId)
}
//获取默认就诊人信息
export const defaultMember = (patientId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/default-member?patientId=${patientId}`)
}
//查询药品对应的所有疾病信息
export const getAllills = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/get-all-ills1`,data)
}
//查询药品对应的所有疾病信息
export const getMedsills = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/get-meds-ills`,data)
}
//高危药品查询
export const getHighmeds = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/get-high-meds`,data)
}
//采集药品查询
export const getCollectmedicinal = (data) => {
    return axiosInstance.post(`/faas/hosp/offline-pharmacy/get-collect-medicinal`,data)
}
// 查询二级分类下面的所有药品及对应icd	/faas/hosp/wzpatient/all-classifyid
export const allClassifyid = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/all-classifyid`,data)
}
//查询用户当天是否开过特殊或高危药品
export const isHightmed = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/is-hightmed`,data)
}
//采集药品价格查询
export const getCollectmedicinalprice = (data) => {
    return axiosInstance.post(`/faas/hosp/offline-pharmacy/get-collect-medicinal-price`,data)
}
// ai查询所有待接诊图文订单
export const allAiorders = (consultationId) => {
    return axiosInstance.get(`/api/hosp/ai-prescription/all-ai-orders?consultationId=${consultationId}`)
}

//精神类药品查询
export const getSpiritmeds = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/get-spirit-meds`, data);
  };

//注射剂药品查询getInjectionmeds
export const getInjectionmeds = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/get-injection-meds`, data);
};

// 新高危药品查询 getHighRiskmeds
export const getHighRiskmeds = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/get-highrisk-meds`, data);
};


// 查询中药药品列表
// export const allChaMedList = (data) => {
//     return axiosInstance.get(`/api/hosp/pharmacy/chamedlists`, data);
// };

export const allChaMedList = (data) => {
    return axiosInstance.get(`/faas/hosp/wzpatient/chamedlists?pharmacyId=${data.pharmacyId}&page=${data.page}&size=${data.size}&content=${data.content}&medId=${data.medId}`)
}

// 中药对应的疾病
export const allChamed = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/all-chamed`, data);
};

// 查询禁用商品
export const selectForbi = (data) => {
    return axiosInstance.post(`/api/hosp/forbidden/select-forbid-meds`,data)
}

//查询疾病对应的科室
export const disDepartment = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/select-dis-department', data)
}

// 单个药店所有药品2
export const storeMedPages = (data) => {
    // return axiosInstance.get(`/faas/hosp/wzpatient/medlists?pharmacyId=${data.pharmacyId}&page=${data.page}&size=${data.size}&content=${data.content}&medId=${data.medId}`)
    return axiosInstance.post(`/faas/hosp/wzpatient/scan-medlists`,data)
}


// 患者查询药品用法用量	
export const drugsUsage = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/drugs-usage', data)
}

// 查询该药品用户自选疾病是否已经存在   
export const isExistCus = (data) => {
    return axiosInstance.post('/api/hosp/custom-disease/is-exist-cus', data)
}


// 查询选择的是否是监管药品
export const selectRegulatory = (data) => {
    return axiosInstance.post(`/api/hosp/regulatory-drugs/regulatory-drugs-chose`,data)
}
