import { axiosInstance } from '../config'
//查询订单列表
export const oderlistRequest = (data) => {
    return axiosInstance.post('/api/hosp/order/list',data)
}
//查询订单详情
export const orderDetails = (orderNumber) => {
    return axiosInstance.get(`/api/hosp/order/details?orderNumber=${orderNumber}`)
}
//删除订单
export const delOrderRequest = (orderNumber) => {
    return axiosInstance.get(`/api/hosp/order/delete?orderNumber=${orderNumber}`)
}
//添加线下药房订单及药品
export const addAllmeds = (data) => {
    return axiosInstance.post('/api/hosp/order-management/add-allmeds',data)
}
//电子病历保存
export const saveIcd = (data) => {
    return axiosInstance.post(`/api/hosp/wzdocter/save-icd`,data)
}
//提交问诊信息
export const submitOrdernews = (data) => {
    // return axiosInstance.post(`/faas/hosp/wzpatient/submit-ordernews`,data)
}

//提交多个问诊信息
export const subMoreInfo = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/sub-more-info`,data)
}

// 拆分问诊单
// export const subChaifen=(data)=>{
//     return axiosInstance.post(`/faas/hosp/consultation-splitting/split-data`,data)
// }

// 提交中药问诊信息
export const submitChaMedOrder = (data) => {
    return axiosInstance.post(`/faas/hosp/wzpatient/submit-chamed-order`,data)
}


// 拆分问诊单2.0
// export const subChaifen=(data)=>{
//     return axiosInstance.post(`/faas/hosp/consultation-splitting/split-data-plus`,data)
// }

// 拆分问诊单3.0
export const subChaifen=(data)=>{
    return axiosInstance.post(`/faas/hosp/consultation-splitting/split-data-three-version`,data)
}

// 记录用户提交日志
export const sumbitLogs=(data)=>{
    return axiosInstance.post(`/faas/hosp/wzpatient/user-submit-logs`,data)
}

// 中药材开方是否符合配伍禁忌
export const chineseMedsTaboo=(data)=>{
    return axiosInstance.post(`/faas/hosp/wzpatient/chinese-meds-taboo`,data)
}

