import React ,{ useState, useEffect } from 'react'
import { Toast, Checkbox, Switch, InputItem,ImagePicker } from 'antd-mobile'
import './style.less'
import ReactDOM from 'react-dom';
import DatePicker from 'react-mobile-datepicker';
import next from '../../../static/img/next.png'
import finish from '../../../static/img/finish.png'

import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
import { uploadQualifications } from '../../../api/request/qualifications'
import { render } from 'less';
import { qualificationsList,updateQualifications } from '../../../api/request/qualifications'
import { confirm } from 'antd-mobile-v5/es/components/modal/confirm';

const CheckboxItem = Checkbox.CheckboxItem
export default function Upload(props){
    const userId=localStorage.getItem("userId2")
    const [files1, setFiles1] = useState([])
    const [files2, setFiles2] = useState([])
    const [files7, setFiles7] = useState([])
    const [files8, setFiles8] = useState([])
    const [files9, setFiles9] = useState([])
    const [evaluatePic1, setEvaluatePic1] = useState([])//营业执照照片
    const [evaluatePic2, setEvaluatePic2] = useState([])//营业执照照片
    const [evaluatePic7, setEvaluatePic7] = useState([])//身份证正面照
    const [evaluatePic8, setEvaluatePic8] = useState([])//身份证反面照
    const [evaluatePic9, setEvaluatePic9] = useState([])//委托书照
    const [time10,setTime10]=useState('')//营业执照起始时间
    const [time11,setTime11]=useState('')//营业执照结束时间
    const [time20,setTime20]=useState('')
    const [time21,setTime21]=useState('')
    const [time70,setTime70]=useState('')
    const [time71,setTime71]=useState('')
    const [time80,setTime80]=useState('')
    const [time81,setTime81]=useState('')
    const [islongterm,setIslongterm]=useState(0)
    const [licenseNumber,setLicenseNumber]=useState('')//营业执照证件号
    const [cardNumber,setCardNumber]=useState('')//身份证号
    const [businessNumber,setbusinessNumber]=useState('')//药品经营许可证
    const cartArr=props.location.state
    // console.log(cartArr);
    let user;  //先定义一个存储参数的变量
    let area;
    let province;
    let city;
    let county;
    let address;
    let person;
    let phone;
    if(cartArr){  //判断是否有参数
        user=cartArr.user;//采购商
        area=cartArr.province+cartArr.city+cartArr.county;//所在区域
        province=cartArr.province;
        city=cartArr.city;
        county=cartArr.county;
        address=cartArr.address;//详细地址
        person=cartArr.person;//采购负责人
        phone=cartArr.phone;//联系方式
        localStorage.setItem('qualificationsUser', user);
        localStorage.setItem('qualificationsArea', area);
        localStorage.setItem('qualificationsProvince', province);
        localStorage.setItem('qualificationsCity', city);
        localStorage.setItem('qualificationsCounty', county);
        localStorage.setItem('qualificationsAddress', address);
        localStorage.setItem('qualificationsPerson', person);
        localStorage.setItem('qualificationsPhone', phone);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        user = localStorage.getItem('qualificationsUser');
        area = localStorage.getItem('qualificationsArea');
        province = localStorage.getItem('qualificationsProvince');
        city = localStorage.getItem('qualificationsCity');
        county = localStorage.getItem('qualificationsCounty');
        address = localStorage.getItem('qualificationsAddress');
        person = localStorage.getItem('qualificationsPerson');
        phone = localStorage.getItem('qualificationsPhone');//当state没有参数时，取sessionStorage中的参数
    }
    const [list,setList]=useState([])
    useEffect(() => {
        qualificationsList().then(res=>{
            // console.log(res);
            for (let i = 0; i < res.data[0].length; i++) {
                if (res.data[0][i].user_id == userId) {
                    setList(res.data[0][i])
                    setEvaluatePic1([res.data[0][i].license_pic])
                    setLicenseNumber(res.data[0][i].license_number)
                    let a1=res.data[0][i].entrust_start_time
                    setTime10((new Date(a1).toLocaleDateString()).replaceAll("/", "-"))
                    if (res.data[0][i].license_end_time) {
                        let a2=new Date(res.data[0][i].license_end_time).toLocaleDateString()
                        setTime11((new Date(a2).toLocaleDateString()).replaceAll("/", "-"))
                    }
                    setEvaluatePic2([res.data[0][i].business_pic])
                    setbusinessNumber(res.data[0][i].business_number)
                    let a7=res.data[0][i].bus_start_time
                    setTime20((new Date(a7).toLocaleDateString()).replaceAll("/", "-"))
                    let a8=res.data[0][i].bus_end_time
                    setTime21((new Date(a8).toLocaleDateString()).replaceAll("/", "-"))

                    setEvaluatePic7([res.data[0][i].card_reverse])
                    setCardNumber(res.data[0][i].card_number)
                    let a3=new Date(res.data[0][i].card_start_time).toLocaleDateString()
                    setTime70((new Date(a3).toLocaleDateString()).replaceAll("/", "-"))
                    setEvaluatePic8([res.data[0][i].card_front])
                    let a4=new Date(res.data[0][i].card_end_time).toLocaleDateString()
                    setTime71((new Date(a4).toLocaleDateString()).replaceAll("/", "-"))
                    setEvaluatePic9([res.data[0][i].entrust_pic])
                    let a5=new Date(res.data[0][i].entrust_start_time).toLocaleDateString()
                    setTime80((new Date(a5).toLocaleDateString()).replaceAll("/", "-"))
                    let a6=new Date(res.data[0][i].entrust_end_time).toLocaleDateString()
                    setTime81((new Date(a6).toLocaleDateString()).replaceAll("/", "-"))
                }

            }
            
        })
    }, [])
    // console.log(time20);
    const longTime=(e)=>{
        // console.log(e);
        if (e.target.checked) {
            setIslongterm(1)
        }else if (!e.target.checked){
            setIslongterm(0)
        }
        // console.log(islongterm,time11);
    }

    const nextStep=()=>{
        const params = {
            userId: userId,
            companyName: user,//采购商
            companyLocation: area,//所在区域
            province:province,
            Province:province,
            city:city,
            City:city,
            district:county,
            District:county,
            companyAddress: address,//详细地址
            Contacts: person,//采购负责人
            contactPhone: phone,//联系方式

            businessNumber:businessNumber?businessNumber:list.business_number,
            busStartTime:time20,//营业执照起始时间
            busEndTime:time21,//营业执照结束时间
            businessPic:evaluatePic2[0],

            licenseNumber:licenseNumber?licenseNumber:list.license_number,
            licenseStartTime:islongterm==0?time10:null,//营业执照起始时间
            licenseEndTime:islongterm==0?time11:null,//营业执照结束时间
            isLongterm:islongterm,
            licensePic:evaluatePic1[0],
            
            cardNumber:cardNumber?cardNumber:list.card_number,
            cardStartTime:time70,//身份证起始时间
            cardEndTime:time71,//身份证结束时间
            cardReverse:evaluatePic7[0],
            cardFront:evaluatePic8[0],

            entrustStartTime:time80,//委托书起始时间
            entrustEndTime:time81,//委托书结束时间
            entrustPic:evaluatePic9[0],
        }
        
        const params1 = {
            userId: userId,
            companyName: user,//采购商
            companyLocation: area,//所在区域
            province:province,
            city:city,
            district:county,
            companyAddress: address,//详细地址
            Contacts: person,//采购负责人
            contactPhone: phone,//联系方式
            licenseNumber:licenseNumber?licenseNumber:list.license_number,
            licenseStartTime:islongterm==0?time10:null,//营业执照起始时间
            licenseEndTime:islongterm==0?time11:null,//营业执照结束时间
            isLongterm:time10&&time11?null:islongterm,
            licensePic:evaluatePic1[0],
            businessNumber:businessNumber?businessNumber:list.business_number,
            busStartTime:time20,//营业执照起始时间
            busEndTime:time21,//营业执照结束时间
            businessPic:evaluatePic2[0],
            cardNumber:cardNumber?cardNumber:list.card_number,
            cardStartTime:time70,//身份证起始时间
            cardEndTime:time71,//身份证结束时间
            cardReverse:evaluatePic7[0],
            cardFront:evaluatePic8[0],
            entrustStartTime:time80,//委托书起始时间
            entrustEndTime:time81,//委托书结束时间
            entrustPic:evaluatePic9[0]
        }
        for(let item in params1) {
            if(params1[item] === '') {
                alert('营业执照（起始日期、结束日期）或者长期，必须二选一，其余均为必填，请检查是否填写完整？', '', [
                    { text: '确认', onPress: () => console.log('cancel'), style: 'default' },
                ])
                return
            }
        }
        if (list&&list.examine==='-1') {
            updateQualifications(params).then(res => {
                // console.log(3,res);
                if(res.code === 1) {
                    // this.props.history.go(-1)
                    // console.log(res);
                    localStorage.setItem("type",2)
                //    props.history.push("/mine/storeSH")
                props.history.push({ pathname: '/await', state: params })
                }
            })
        }else{
            // 资质上传
            uploadQualifications(params).then(res => {
                // console.log(1,res);
                if(res.code === 1) {
                    // this.props.history.go(-1)
                    // console.log(res);
                    localStorage.setItem("type",2)
                //    props.history.push("/mine/storeSH")
                props.history.push({ pathname: '/await', state: params })
                }
            })
        }
    }
    const imgChange1 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = []
        if(type === 'add') {
            let file = files[files.length-1].file
            // console.log(file);
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    if(res1.code === 2) {
                        setFiles1(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic1(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles1(files)
            imgList.splice(index, 1)
            setEvaluatePic1(imgList)
            Toast.hide()
        }
    }
    const imgChange2 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = []
        if(type === 'add') {
            let file = files[files.length-1].file
            // console.log(file);
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    if(res1.code === 2) {
                        setFiles2(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic2(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles2(files)
            imgList.splice(index, 1)
            setEvaluatePic2(imgList)
            Toast.hide()
        }
    }
    const imgChange7 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = []
        if(type === 'add') {
            let file = files[files.length-1].file
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    if(res1.code === 2) {
                        setFiles7(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic7(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles7(files)
            imgList.splice(index, 1)
            setEvaluatePic7(imgList)
            Toast.hide()
        }
    }
    const imgChange8 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = []
        if(type === 'add') {
            let file = files[files.length-1].file
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    if(res1.code === 2) {
                        setFiles8(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic8(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles8(files)
            imgList.splice(index, 1)
            setEvaluatePic8(imgList)
            Toast.hide()
        }
    }
    const imgChange9 = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = []
        if(type === 'add') {
            let file = files[files.length-1].file
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    // console.log(res1);
                    if(res1.code === 2) {
                        setFiles9(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic9(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles9(files)
            imgList.splice(index, 1)
            setEvaluatePic9(imgList)
            Toast.hide()
        }
    }
    // 填写信息
    const handleChange=(key,val)=>{
        switch (key) {
            case 'licenseNumber':
                setLicenseNumber(val)
                break;
            case 'cardNumber':
                setCardNumber(val)
            break;
            case 'businessNumber':
                setbusinessNumber(val)
            break;
            default:
                break;
        }
    }
     // 日期选择
    const chiose10 = (e) => {
        // console.log(e.target.value)
        setTime10(e.target.value)
    }
    useEffect(() => {
        if (time10) {
            const num = time11.replaceAll("-", "")
            const num2 = time10.replaceAll("-", "")
            if (num <= num2) {
                Toast.fail("到期时间需大于初始时间")
                setTime11('')
            }
        } else {
            Toast.fail("初始时间还未选择")
            setTime11('')
        }
    }, [time11])
    const chiose11 = (e) => {
        setTime11(e.target.value)
    }
    const chiose20 = (e) => {
        // console.log(e.target.value)
        setTime20(e.target.value)
    }
    useEffect(() => {
        if (time20) {
            const num = time21.replaceAll("-", "")
            const num2 = time20.replaceAll("-", "")
            if (num <= num2) {
                Toast.fail("到期时间需大于初始时间")
                setTime21('')
            }
        } else {
            Toast.fail("初始时间还未选择")
            setTime21('')
        }
    }, [time21])
    const chiose21 = (e) => {
        setTime21(e.target.value)
    }
    const chiose70 = (e) => {
        // console.log(e.target.value.replaceAll("-", ""))
        setTime70(e.target.value)
    }
    useEffect(() => {
        if (time70) {
            const num = time71.replaceAll("-", "")
            const num2 = time70.replaceAll("-", "")
            if (num <= num2) {
                Toast.fail("到期时间需大于初始时间")
                setTime71('')
            }
        } else {
            Toast.fail("初始时间还未选择")
            setTime71('')
        }
    }, [time71])
    const chiose71 = (e) => {
        setTime71(e.target.value)
    }
    const chiose80 = (e) => {
        // console.log(e.target.value.replaceAll("-", ""))
        setTime80(e.target.value)
    }
    useEffect(() => {
        if (time80) {
            const num = time81.replaceAll("-", "")
            const num2 = time80.replaceAll("-", "")
            if (num <= num2) {
                Toast.fail("到期时间需大于初始时间")
                setTime81('')
            }
        } else {
            Toast.fail("初始时间还未选择")
            setTime81('')
        }
    }, [time81])
    const chiose81 = (e) => {
        setTime81(e.target.value)
    }
   
    return(
        <>
            <div className='outer1'>
                <header>
                    <h2>资质上传</h2>
                    <p className='step'>
                        <span>采购商信息</span>
                        <span className='xian'></span>
                        <span className='cg-news'>相关证件</span>
                        <span className='xian'></span>
                        <span>等待审核</span>
                    </p>
                    {/* <input type="date" /> */}

                </header>
                <p className='certificates'>公司相关证件，上传后不能随意更改，需要提交申请</p>
                <div className='license'>
                    <p className='business'> 
                        <span className='must'>*</span>
                        <span className='dobusiness'>营业执照</span>
                    </p>
                    <InputItem placeholder='请填写统一社会信用代码' value={licenseNumber}
                            onChange={v=>handleChange('licenseNumber',v)}
                        ></InputItem>
                    <div className='begin-end-time'>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time10} onChange={e => chiose10(e)}/>
                        <div className='time-xian'></div>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time11} onChange={e => chiose11(e)}/>
                        <div className='long-time'>
                            <CheckboxItem style={{border:'none'}} onChange={(e)=>{longTime(e)}}>长期</CheckboxItem>
                        </div>
                    </div>
                    <div className='sendcomment-photo'>
                        <ImagePicker
                        files={files1}
                        length={1}
                        onChange={imgChange1}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files1.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png'/>
                        {list?<img src={evaluatePic1} style={{width:'60px',height:'60px',marginLeft:'-15px',marginTop:'-10px'}} alt="" />:null}
                        <p>上传证件</p>
                    </div>
                    <div className='upload-word'>
                        <p>上传大小2M以内</p>
                        <p>字迹清晰</p>
                    </div>
                </div>
                <div className='license'>
                    <p className='business'> 
                        <span className='must'>*</span>
                        <span className='dobusiness'>药品经营许可证</span>
                    </p>
                    <InputItem placeholder='请填写药品经营许可证号' value={businessNumber}
                            onChange={v=>handleChange('businessNumber',v)}
                        ></InputItem>
                    <div className='begin-end-time'>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time20} onChange={e => chiose20(e)}/>
                        <div className='time-xian'></div>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time21} onChange={e => chiose21(e)}/>
                    </div>
                    <div className='sendcomment-photo'>
                        <ImagePicker
                        files={files2}
                        length={1}
                        onChange={imgChange2}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files2.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png'/>
                        {list?<img src={evaluatePic2} style={{width:'60px',height:'60px',marginLeft:'-15px',marginTop:'-10px'}} alt="" />:null}
                        <p>上传证件</p>
                    </div>
                    <div className='upload-word'>
                        <p>上传大小2M以内</p>
                        <p>字迹清晰</p>
                    </div>
                </div>
                <div className='purchasing-personnel-idcard'>
                    <p className='business'> 
                        <span className='must'>*</span>
                        <span className='dobusiness'>采购人员身份证</span>
                    </p>
                    <InputItem placeholder='请填写身份证号'  value={cardNumber}
                            onChange={v=>handleChange('cardNumber',v)}
                        ></InputItem>
                    <div className='begin-end-time'>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time70} onChange={e => chiose70(e)}/>
                        <div className='time-xian'></div>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time71} onChange={e => chiose71(e)}/>
                    </div>
                    <div className='sendcomment-photo'>
                        <ImagePicker
                        files={files7}
                        length={1}
                        onChange={imgChange7}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files7.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png'/>
                        {list?<img src={evaluatePic7} style={{width:'60px',height:'60px',marginLeft:'-15px',marginTop:'-10px'}} alt="" />:null}
                        <p>上传正面</p>
                    </div>
                    <div className='sendcomment-photo-back'>
                        <ImagePicker
                        files={files8}
                        length={1}
                        onChange={imgChange8}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files8.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png'/>
                        {list?<img src={evaluatePic8} style={{width:'60px',height:'60px',marginLeft:'-15px',marginTop:'30px'}} alt="" />:null}
                        <p>上传背面</p>
                    </div>
                    <div className='upload-word1'>
                        <p>上传大小2M以内</p>
                        <p>字迹清晰</p>
                    </div>
                </div>
                <div className='purchase-power-of-attorney'>
                    <p className='business'> 
                        <span className='must'>*</span>
                        <span className='dobusiness'>采购委托书</span>
                    </p>
                    <div className='begin-end-time'>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time80} onChange={e => chiose80(e)}/>
                        <div className='time-xian'></div>
                        <input type="date" style={{width:'120px',height:'30px',marginTop:'5px'}} value={time81} onChange={e => chiose81(e)}/>
                    </div>
                    <div className='sendcomment-photo'>
                        <ImagePicker
                        files={files9}
                        length={1}
                        onChange={imgChange9}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files9.length < 1}
                        accept='image/gif,image/jpeg,image/jpg,image/png'/>
                        {list?<img src={evaluatePic9} style={{width:'60px',height:'60px',marginLeft:'-15px',marginTop:'-10px'}} alt="" />:null}
                        <p>上传证件</p>
                    </div>
                    <div className='upload-word'>
                        <p>上传大小2M以内</p>
                        <p>字迹清晰</p>
                    </div>
                </div>
                <div className='next' onClick={()=>nextStep()}>
                    {user&&area&&address&&person&&phone&&time10&&evaluatePic1[0]&&time70&&time71&&evaluatePic7[0]
                    &&evaluatePic8[0]&&evaluatePic9[0]&&time80&&time81?<img src={finish} alt="" />:<img src={next} alt="" />}
                    <span>下一步</span>
                </div>
            </div>
        </>
    )
}