
import { axiosInstance } from '../config'
// 查询用户所有电子健康卡信息
export const getAll = (data) => {
    return axiosInstance.post('/api/hosp/electronic/get-all',data)
}
// 新增电子健康卡
export const addElectronicIdcard = (data) => {
    return axiosInstance.post('/api/hosp/electronic/add-electronic-idcard', data)
}
//未实名绑定就诊卡
export const nonameClinic = (data) => {
    return axiosInstance.post('/api/hosp/electronic/noname-clinic', data)
}
//实名就诊卡添加
export const havenameClinic = (data) => {
    return axiosInstance.post('/api/hosp/electronic/havename-clinic', data)
}
//查询所有就诊卡
export const allClinic = (patientId,cardNumber) => {
    return axiosInstance.get(`/api/hosp/electronic/all-clinic?patientId=${patientId}&cardNumber?=${cardNumber}`)
}
//扫码查询健康卡详情
export const scanDetails = (data) => {
    return axiosInstance.post('/api/hosp/electronic/scan-details', data)
}
//修改默认健康卡
export const changeDefault = (data) => {
    return axiosInstance.post('/api/hosp/electronic/change-default', data)
}
//用户查询报告列表
export const allReports = (healthCard) => {
    return axiosInstance.get(`/api/hosp/inspection/all-reports?healthCard=${healthCard}`)
}
//查询报告检查项
export const getInspectionItem = (id) => {
    return axiosInstance.get(`/api/hosp/inspection/get_inspection_item?id=${id}`)
}
//查询最大报告详情
export const outerDetails = (id) => {
    return axiosInstance.get(`/api/hosp/inspection/outer-details?id=${id}`)
}
//查询健康卡和就诊卡是否已绑定
export const healthClinic = (data) => {
    return axiosInstance.post('/api/hosp/electronic/health_clinic', data)
}
//电子健康卡绑定就诊卡
export const healthClinicAdd = (data) => {
    return axiosInstance.post('/api/hosp/electronic/health_clinic_add', data)
}
//查询就诊卡是否存在
export const isClinic = (data) => {
    return axiosInstance.post('/api/hosp/electronic/is-clinic', data)
}
//查看健康卡关联的所有就诊卡
export const allHealthclinic = (data) => {
    return axiosInstance.post('/api/hosp/electronic/all-healthclinic', data)
}
//微信退款
export const refundApply = (data) => {
    return axiosInstance.post('/faas/hosp/register-record/refund-apply', data)
}
//用户查询缴费列表
export const allOutpatients = (cardNumber) => {
    return axiosInstance.get(`/api/hosp/outpatient-payment/all-outpatients?cardNumber=${cardNumber}`)
}
//查询缴费详情
export const outpatientPaymentDetails = (data) => {
    return axiosInstance.post('/api/hosp/outpatient-payment/outpatient-payment-details', data)
}
//查询电子健康卡是否创建
export const isElectronic = (data) => {
    return axiosInstance.post('/api/hosp/electronic/is-electronic', data)
}
//病员档案
export const hisToArchives = (idCard,name) => {
    return axiosInstance.get(`/api/hosp/hospital-his/his-to-archives?idCard=${idCard}&name=${name}`)
}
//插入病员数据-临时
export const addhispatientTemporary = (data) => {
    return axiosInstance.post('/api/hosp/hospital-his/add-his-patient-temporary', data)
}
//刷新就诊卡数据
export const refreshCard = (data) => {
    return axiosInstance.post('/api/hosp/electronic/refresh-card', data)
}