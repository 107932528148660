import React, { Component } from 'react'
import { Button, Space, Rate, } from "antd-mobile-v5"
import { Toast } from "antd-mobile"

import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/ts.png"
import icon3 from "../../static/img/yy/five.png"
import icon4 from "../../static/img/yy/pic.png"
import icon5 from "../../static/img/yy/phone.png"
import icon6 from "../../static/img/yy/zixun.png"
import icon7 from "../../static/img/yy/zxhui.png"
import icon9 from "../../static/img/yy/shipin.png"
import icon8 from "../../static/img/scgz.png"

import { Modal } from 'antd-mobile';
import {
    whethernews,
    addchat,
    getchat,
    getId,
    callWenzhen,
    phoneTowenzheb,
    orderGetnews, updatestu
} from "../../api/hospitalapi/chat"
import { oneDocpj ,gzDoc,getGzdoc} from "../../api/hospitalapi/user"
import {getDayDocjznum} from "../../api/hospitalapi/home"
import "./style.less"
import Nodata from "../../component/Nodata";
import getUrl from '../../component/GetUrlParam'
import {code, zfUrl} from "../../publicurl";
const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: JSON.parse(localStorage.getItem("item")),
            patientId: localStorage.getItem("patientId"),
            patientPhone: localStorage.getItem("patientPhone"),
            chatNum:'',
            chatNum1:0,
            list0:[],
            docList:'',
            guanzhu:false,
            timeNow:'',
            record:'',
            doctorchat:'',
            gzDocter:'',
            consultationId:'',
        }
    }
    componentDidMount = async() => {
        const { item, patientId } = this.state
        if (getUrl('record', this.props.location.search)){
            this.setState({
                record:getUrl('record', this.props.location.search)
            })
        }
        if (getUrl('consultationId', this.props.location.search)){
            this.setState({
                consultationId:getUrl('consultationId', this.props.location.search)
            })
        }
        if (getUrl('doctorchat', this.props.location.search)){
            this.setState({
                doctorchat:getUrl('doctorchat', this.props.location.search)
            })
        }
        if (getUrl('gzDocter', this.props.location.search)){
            this.setState({
                gzDocter:getUrl('gzDocter', this.props.location.search)
            })
        }

        if (item){
            let date=new Date()
            let hour=date.getHours()
            let min=date.getMinutes()
            this.setState({
                timeNow: hour*60+min
            })
            // if (!patientId||!item) {
            //     Toast.show({
            //         content:"当前未登录，请先登录",
            //         duration:1000
            //     })
            //     this.props.history.push("/login")
            //   }else{
            const res = await whethernews({
                userId: patientId,
                docterId:item.docterId
            })

            this.setState({
                chatNum: res.data[0][0].chatNum,
                chatNum1: res.data[1][0].chatNum1,
            })

            oneDocpj(item.docterId).then(res=>{
                if (res&&res.code==1) {
                    if (res&&res.data&&res.data[0]){
                        res.data[0].map(item=>{
                            if (item.docEvaluateQuick){
                                item.docEvaluateQuick=item.docEvaluateQuick.split(',')
                            }
                        })
                    }
                    this.setState({
                        list0: res.data[0]
                    })
                }
            })
            getGzdoc({patientId}).then(res=>{
                if (res&&res.data[0][0].gzDocterId) {
                    let a=res.data[0][0].gzDocterId.split(",")
                    if (a.length) {
                        a.map(item0=>{
                            if (parseInt(item0)==item.docterId) {
                                this.setState({
                                    guanzhu: true
                                })
                            }
                        })
                    }
                    this.setState({
                        docList: res.data[0][0].gzDocterId
                    })
                }
            })
            // }
        }
    }
    tochat = async () => {
        const { chatNum,patientId,item,timeNow,record,gzDocter,chatNum1 } = this.state
        
        if (patientId) {
            if (chatNum1==0){
                if (chatNum == 0) {
                    let res=await getDayDocjznum(item.docterId)
                    if (res&&res.data&&res.data[0]&&res.data[1]&&res.data[2]){
                        if (item.docterPicText=='1') {
                            if (res.data[2][0].countNum1 < res.data[1][0].docterUpcount) {
                                if (item.docterStartDisturb && (timeNow >= (parseInt(item.docterStartDisturb.split(':')[0] * 60) + parseInt(item.docterStartDisturb.split(':')[1])) &&
                                    item.docterEndDisturb && (timeNow <= parseInt(item.docterEndDisturb.split(':')[0] * 60) + parseInt(item.docterEndDisturb.split(':')[1])) && item.docterDisturb == 1)
                                ) {
                                    Toast.info('医生休息中，无法进行咨询')
                                } else {
                                    this.props.history.replace(`/doctorinfo?record=${record}&gzDocter=${gzDocter}`)
                                    this.props.history.push(`/writelist?record=${record}&gzDocter=${gzDocter}`)
                                }
                            } else {
                                Toast.info('该医生图文咨询服务今日已达到上限，无法继续咨询')
                            }
                        }else {
                            Toast.info('暂未开通图文问诊服务')
                        }
                    }

                } else {
                    getId({
                        userId: patientId,
                        docterId:item.docterId
                    }).then(res=>{
                        if (res&&res.data&&res.data[0]){
                            this.props.history.replace(`/doctorinfo?record=${record}&gzDocter=${gzDocter}`)
                            this.props.history.push(`/doctorchat?consultationId=${res.data[0][0].consultationId}&docterId=${item.docterId}&record1=${record}&gzDocter=${gzDocter}`)
                        }
                    })

                }
            }else{
                Toast.info('已存在尚未支付问诊订单，请先支付或取消后再继续咨询')
            }

        }else{
            this.props.history.push("/login")
        }
    }
    phoneChat=()=>{
        const { patientPhone,item ,patientId,guanzhu} = this.state
        if (item.docterVoice==0){
            Toast.info('暂未开通电话咨询服务')
        }
    }
    phoneChat2=async ()=>{
        const { patientPhone,item ,timeNow,guanzhu} = this.state
        if (item.docterVideoText==0){
            Toast.info('暂未开通视频咨询服务')
        }else{
            if (item.docterStartDisturb&&(timeNow>=(parseInt(item.docterStartDisturb.split(':')[0]*60)+parseInt(item.docterStartDisturb.split(':')[1]))&&
                item.docterEndDisturb&&(timeNow<=parseInt(item.docterEndDisturb.split(':')[0]*60)+parseInt(item.docterEndDisturb.split(':')[1]))&&item.docterDisturb==1)
            ){
                Toast.info('医生休息中，无法进行咨询')
            }else {
                this.props.history.replace(`/doctorinfo`)
                this.props.history.push(`/writelist?vedio=${1}`)
            }

        }
    }
    guanzhuDoc=()=>{
        const { patientId,item ,docList,guanzhu} = this.state
        if (guanzhu) {
            alert('确认取消关注?','不再关注该医生', [
                { text: '继续关注', onPress: () => console.log('cancel'), style: 'default' },
                { text: '确定取消', onPress: () => {
                    let a=docList.split(",")
                    // let b=a.concat(item.docterId)
                    let c=[]
                    a.map(item0=>{
                        if (parseInt(item0)!=item.docterId &&item0!='NAN') {
                            c.push(parseInt(item0))
                        }
                    })
                    let nArr1 = c.filter((currentValue, currentIndex, selfArr) => {
                        return selfArr.findIndex(x => x === currentValue) === currentIndex
                    });
                    let arr=nArr1.toString()

                    gzDoc({patientId,gzDocterId:arr}).then(res=>{
             
                        if (res.code==1) {
                            this.setState({
                                guanzhu: false
                            })
                        }
                    })
                    
                }},
              ],)
        }else{
            let a=docList.split(",")
            let b=a.concat(item.docterId)
            let c=[]
            b.map(item=>{
                c.push(parseInt(item))
            })
            let nArr1 = c.filter((currentValue, currentIndex, selfArr) => {
                return selfArr.findIndex(x => x === currentValue) === currentIndex
            });
            let arr=nArr1.toString()
            gzDoc({patientId,gzDocterId:arr}).then(res=>{
                if (res.code==1) {
                    this.setState({
                        guanzhu: true
                    })
                }
            })
        }
    }
    toBack=()=>{
        const {record,doctorchat,consultationId,item,gzDocter} = this.state
        if (doctorchat){
            this.props.history.push(`/doctorchat?consultationId=${consultationId}&docterId=${item.docterId}&record=${record}`)
        }else if (record){
            this.props.history.push("/mine/record")
        }else if (gzDocter){
            this.props.history.push("/mine/gzDocter")
        }else{
            this.props.history.push("/")
        }
    }
    render() {
        const { item ,list0,guanzhu} = this.state
        return (
            <div className='infobox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.toBack()} /><h1>医生简介</h1>
                </div>
                <div className='box-info' style={{ padding: "15px" }}>
                    <div className='info-1'>
                        <div className='box-container'>
                            <div className='container-box'>
                                <img style={{borderRadius:"50%"}} src={item&&item.dcocterAvatar} alt="" />
                                <div className='box-text'>
                                    <div>
                                        <p className='box-name'>
                                            <span>{item&&item.docterName}</span><span>{item&&item.title}</span><span>{item&&item.depName}</span>
                                        </p>
                                        <div className='box-title'>
                                            <span className='yy'>{item&&item.hospital}</span>
                                            {guanzhu==false?<span onClick={this.guanzhuDoc} className='guanzhu'>
                                                <img src={icon8} alt="" />
                                                <span>关注</span>
                                            </span>:
                                            <span onClick={this.guanzhuDoc} className='guanzhu1'>
                                                <span className='gz'>√ 已关注</span>
                                            </span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='box-evaluate'>
                            <div>
                                <img src={icon3} alt="" /> <span>4.8</span>
                            </div>
                            <div>
                                <span>接诊次数</span> <span>{item&&item.countNum}</span>
                            </div>

                            <div>
                                <span>回复速度:</span> <span>较快</span>
                            </div>
                        </div>
                        <div className='box-introduce'>
                            <span>专业擅长：</span> {item&&item.expert}
                            {/* <span>查看简介 ＞</span> */}
                        </div>
                    </div>
                    <div className='info-2'>
                        <div className='info2-item'>
                            <img src={icon4} alt="" />
                            <div>
                                <p>图文咨询 <span>￥{(item&&(item.docterPicCharge/100).toFixed(2))+'/48小时'}</span></p>
                                 <p>1对1图文多次沟通</p>
                            </div>
                            <img src={item&&item.docterPicText==0?icon7:icon6} alt="" className='item-img' onClick={this.tochat} />
                        </div>
                        {/*<div className='info2-item'>*/}
                        {/*    <img src={icon5} alt="" />*/}
                        {/*    <div>*/}
                        {/*        <p>电话咨询 <span>{(item&&(item.docterVoiceCharge/100).toFixed(2))+'/15分钟'}</span></p>*/}
                        {/*        <p>预约电话，沟通更清楚</p>*/}
                        {/*    </div>*/}
                        {/*    <img src={item&&item.docterVoice==0?icon7:icon6} alt="" onClick={this.phoneChat} className='item-img' />*/}
                        {/*</div>*/}
                        <div className='info2-item'>
                            <img src={icon9} alt="" />
                            <div>
                                <p>视频咨询 <span>￥{(item&&(item.docterVideoCharge/100).toFixed(2))+'/15分钟'}</span></p>
                                <p>视频问诊，便捷效率</p>
                            </div>
                            <img src={item&&item.docterVideoText==0?icon7:icon6} alt="" className='item-img' onClick={this.phoneChat2} />
                        </div>
                    </div>
                    <p className='info-3'>评价({list0.length?list0.length:0}) </p>
                    <div className={list0.length?'info-4':''}>
                        {list0.length?list0.map(item=>(
                            <div className='info4-item'>
                                <div className='item-top'>
                                    <p>{item.patientName&&isNaN(item.patientName.substr(0,1)) ?item.patientName.slice(0,1)+(item.patientName.length>2?item.patientName.replace(item.patientName.slice(1,item.patientName.length),'*'):'*'):item.patientPhone} <Rate readOnly style={{
                                        '--star-size': '20px',
                                        '--active-color': '#1F87FF',
                                    }} value={item.docEvaluateStar} /></p><p>{item.createTime}</p>
                                </div>
                                <div className='item-btn'>
                                {item.docEvaluateQuick?item.docEvaluateQuick.map(i=>(<button>{i}</button>
                                )):null}
                                </div>
                                <p>{item.docEvaluateText}</p>
                            </div>

                        )):<Nodata type='评价'/>}
                    </div>
                </div>

            </div>
        )
    }
}
