import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import "./paymentdetails.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/head.png"
import {outpatientPaymentDetails} from '../../../api/hospitalapi/electronic'
import getUrl from '../../../component/GetUrlParam'
/*
*  缴费详情
* */

export default class paymentdetails extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode: false,
            value1: "",
            value2: "",
            userId: localStorage.getItem("patientId"),
            userDetails:'',
            medList: [],
            visible: false,
            id:''
        }
    }

    componentDidMount = () => {
        const {userId} = this.state
        if (getUrl('id', this.props.location.search)) {
            this.setState({
                id: getUrl('id', this.props.location.search)
            },()=>{
                outpatientPaymentDetails({id:getUrl('id', this.props.location.search)}).then(res=>{
                    // console.log(res)
                    if (res&&res.data&&res.data[0]){
                        this.setState({
                            userDetails:res.data[0][0]
                        })
                    }
                    if (res&&res.data&&res.data[1]){
                        this.setState({
                            medList:res.data[1]
                        })
                    }
                })
            })
        }
    }

    render() {
        const {userDetails,medList,visible} = this.state
        // console.log(1%2)
        // console.log(4%2)
        // console.log(0%2)
        return (
            <div className='setbox queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/outpatientpayment")}/> 缴费详情
                </div>
                <p className='paymentdetails-news'>就诊信息</p>
                <p className='paymentdetails-top'>
                    <p>
                        <span>医生姓名</span>
                        <span>{userDetails.docterName}</span>
                    </p>
                    <p>
                        <span>就诊科室</span>
                        <span>内科</span>
                    </p>
                    <p>
                        <span>医院名称</span>
                        <span>中江民瑞医院</span>
                    </p>
                    <p>
                        <span>费用类型</span>
                        <span>门诊缴费</span>
                    </p>
                    <p>
                        <span>就诊人</span>
                        <span>{userDetails.patientName}</span>
                    </p>
                    <p>
                        <span>就诊卡号</span>
                        <span>{userDetails.cardNumber}</span>
                    </p>
                </p>
                <p className='paymentdetails-news'>费用详情</p>
                <div className={userDetails.state!=1 ? 'paymentdetails-allmeds1 paymentdetails-allmeds' : 'paymentdetails-allmeds'}>
                    <p className='all-price'>
                        <span className='price-left'>费用</span>
                        <span className='price-right'>
                        <span>总金额：</span>
                        <span>￥{(userDetails.amount/100).toFixed(2)}</span>
                    </span>
                    </p>
                    <p className='xiangmu'>
                        <span className='xiangmu-name'>项目名称</span>
                        <span className='xiangmu-price'>单价(元)</span>
                        <span className='xiangmu-count'>数量/单位</span>
                        <span className='xiangmu-price'>金额(元)</span>
                    </p>
                    {medList.length?medList.map((item,index)=>(<p className={index%2==0?'xiangmu xiangmu1':'xiangmu'}>
                        <span className='xiangmu-name'>{item.name}</span>
                        <span className='xiangmu-price'>{(item.price/100).toFixed(2)}</span>
                        <span className='xiangmu-count'>{item.quantity}/{item.unit}</span>
                        <span className='xiangmu-price'>{(item.price*item.quantity/100).toFixed(2)}</span>
                    </p>)):null}
                </div>
                {userDetails.state==1 ? <div className='pay-sure'>
                    <p>合计 <span style={{color: '#FF3030'}}>￥{(userDetails.amount/100).toFixed(2)}</span></p>
                    <p className='pay'>立即缴费</p>
                </div> :
                <>
                    {/*<div className='look-fapiao'>*/}
                    {/*    <p className='look-fapiao-inner' onClick={() => this.props.history.push("/invoicedetails")}>查看电子发票</p>*/}
                    {/*</div>*/}
                    <p className='paymentdetails-news'>缴费信息</p>
                    <p className='paymentdetails-top'>
                        <p>
                            <span>支付金额</span>
                            <span>￥154.25</span>
                        </p>
                        <p>
                            <span>平台订单号</span>
                            <span>16848515485128471</span>
                        </p>
                        <p>
                            <span>支付流水号</span>
                            <span>678452256391451524554</span>
                        </p>
                        <p>
                            <span>支付时间</span>
                            <span>2022-08-10 14:14:47</span>
                        </p>
                    </p>
                </>}
            </div>
        )
    }
}
