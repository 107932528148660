import React ,{ useState, useRef } from 'react'
import { oderdetailRequest,addPharmacyUser } from '../../../api/request/order'
import { medowncommentRequest } from '../../../api/request/comment'
// import { DemoBlock } from 'demos'
import { InputItem, Checkbox } from 'antd-mobile'
import getUrl from '../../../component/GetUrlParam'
// import Button from '../../../baseui/fuctionbutton/index'
import Head from '../../../component/Head'
import fanhui from '../../../static/img/fanhui.png'
import jiantou from '../../../static/img/jiantou.png'
import jixu from '../../../static/img/jixu.png'
import bianji from '../../../static/img/bianji.png'
import sousuo from '../../../static/img/sousuo.png'

import './style.less'
const CheckboxItem = Checkbox.CheckboxItem

export default function Consultate(props){
    const userId =localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const [druName, setDruName] = useState(null)
    const [druIdcard, setDruIdcard] = useState(null)
    const [druPhone, setDruPhone] = useState(null)
    const [druHistory, setDruHistory] = useState('')
    const [druInformation, setDruInformation] = useState([])
    const [druIsused, setDruIsused] = useState('')
    const [visible1, setVisible1] = useState('none')
    const backPage=()=>{
        window.history.back()
    }

  const  handleChange=(key,val)=>{
        switch (key) {
            case 'druName':
                setDruName(val)
                break;
            case 'druIdcard':
                setDruIdcard(val)
                break;
            case 'druPhone':
                setDruPhone(val)
                break;
            case 'druHistory1':
                setDruHistory('1')
                break;
            case 'druHistory2':
                setDruHistory('0')
            break;
            case 'druInformation':
                setDruInformation(val)
                break;
            default:
                break;
        }
    }

    const addDisease=()=>{
        setVisible1('none')
        // let  hislist= druInformation
        // if(druInformation !== []) {
        //     druInformation.push(hislist)
        //     setDruInformation(hislist)
        //     console.log(druInformation)
        //     for(var i=0; i<hislist.length; i++){
        //         for(var j=i+1; j<hislist.length; j++){
        //             if(hislist[i]==hislist[j]){ 
        //             hislist.splice(j,1);
        //             j--;
        //             }
        //         }
        //     }
        // }
    }
    const nextStep=()=>{
        const params = {
            userId: userId,
            druName:druName,
            druIdcard:druIdcard,
            druPhone:druPhone,
            druHistory:druHistory,
            druInformation:druInformation,
            druIsused:druIsused
        }
        for(let item in params) {
            if(params[item] === '') {
                return
            }
        }
        // 问诊信息上传
        addPharmacyUser(params).then(res => {
  
            if(res.code === 1) {
                // console.log(res);
            }
        })
    }
    return(
        <div>
            <div className='top'>
                <div className='title'>
                    <img src={fanhui} onClick={backPage} alt="" />
                    <h1 >填写问诊信息</h1>
                </div>
                <div className='tep'>
                    <span>填写信息</span>
                    <img src={jiantou} alt="" />
                    <span>医生开方</span>
                    <img src={jiantou} alt="" />
                    <span>医师审方</span>
                    <img src={jiantou} alt="" />
                    <span>药房发货</span>
                </div>
            </div>
            <div className='tips'>
                <h2>依据国家规定，处方药需凭处方购买</h2>
                <p>填写问诊信息后医生将为您开具处方，<span>10分钟</span>未填写将自动退款</p>
            </div>
            <div className='news'>
                <InputItem
                    placeholder='请输入用药人姓名'
                    onChange={v=>handleChange('druName',v)}
                >用药人姓名</InputItem>
                <InputItem
                    placeholder='请输入用药人身份证'
                    onChange={v=>handleChange('druIdcard',v)}
                >身份证号</InputItem>
                <InputItem
                    placeholder='请输入用药人手机号'
                    onChange={v=>handleChange('druPhone',v)}
                >手机号</InputItem>
                <div className='bing'>
                    <span>疾病史/妊娠哺乳</span>
                    <span className="a b">
                        <span>
                            <input type="radio" name='bing' onClick={(v) => handleChange('druHistory1',v)} value='man'/><span className='have'>有</span>
                            <input type="radio" name='bing' onClick={(v) => handleChange('druHistory2',v)} value='woman' /><span>无</span>
                        </span>
                    </span>
                </div>
            </div>
            {/* <div className='news1'>
                <div className='select'>
                    <span className='select-left'>请选择用药人</span>
                    <span className='select-right'><span>+</span>添加</span>
                </div>
                <div className='add'>
                    <p className='add-news'>
                        <div>
                            <h3 className='name'>张华强</h3>
                            <img src={bianji} alt="" />
                        </div>
                        <p><span className='sex'>男</span><span className='nianling'>23岁</span></p>
                    </p>
                    <p className='add-news'>
                        <div>
                            <h3 className='name'>张三</h3>
                            <img src={bianji} alt="" />
                        </div>
                        <p><span className='sex'>男</span><span className='nianling'>23岁</span></p>
                    </p>
                    
                </div>
            </div> */}
            <div className='disease'>
                <p>本次用药的确诊疾病（可多选）</p>
                <div className='confirmed'>
                    <span>鼻炎</span>
                    <span onClick={() => {setVisible1('block')}}>+其他</span>
                </div>
                {visible1=='block'?<div className='bgc-popup' >
                    <div className='popup-top' onClick={() => {setVisible1('none')}}></div>
                    <div  className='popup'>
                        <h3>请添加确诊疾病</h3>
                        {/* <Head searchword='老百姓大药房'  ability='搜索' clickmethod={()=>searchMed}/> */}
                        <div className='sousuo'>
                            <InputItem className='shuru' placeholder='请添加线下确诊的疾病名称'
                            onChange={v=>handleChange('druInformation',v)}
                        ></InputItem><span className='add' onClick={() => addDisease()}>添加</span>
                        </div>
                    </div>
                </div>:null}
            </div>
            <div className='used'>
                <div className='used-left'>
                    <p className='used-left-top'>是否使用过此药品</p>
                    <p className='used-left-bottom'>无相关禁忌症，无过敏史和不良反应</p>
                </div>
                <div className='used-right'>
                    <p>
                        <input type="radio"  onClick={(v) => {setDruIsused('1')}} name='use'/><span className='use'>有</span>
                        <input type="radio" onClick={(v) => {setDruIsused('0')}} name='use' /><span>无</span>
                    </p>
                </div>
            </div>
            <div className='continue'>
                <p>如您使用此服务，视您同意<span>互联网诊疗风险告知及知情同意书</span></p>
                <div onClick={()=>{nextStep()}}>
                    <img src={jixu} alt="" />
                    <span>继续</span>
                </div>
            </div>
        </div>
    )
}
