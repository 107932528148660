import React ,{ useState, useEffect } from 'react'
import { ImagePicker, TextareaItem,Checkbox, Toast} from 'antd-mobile'
import { applyRefundRequest,oderdetailRequest,applyPartRequest } from '../../../api/request/order'
import { uploadImg } from '../../../api/request/upload'
import { zipImg } from '../../../component/zipImage/index'
import kefu from '../../../static/img/kefu.png'
import you from '../../../static/img/you.png'
import defaultImg from '../../../static/img/default.png'
import tuikuanleft from '../../../static/img/tuikuanleft.png'
import tuikuanright from '../../../static/img/tuikuanright.png'
import './style.less'
const CheckboxItem = Checkbox.CheckboxItem

export default function Drawback(props){
    const orderNumber1 = props.location.orderNumber
    const [medDetail, setMedDetail] = useState(null)
    const [files, setFiles] = useState([])
    const userId = localStorage.userId
    const [evaluateText, setEvaluateText] = useState('')
    const [evaluatePic, setEvaluatePic] = useState([])
    const [orderDetail, setOrderDetail] = useState(null)
    const [cartList,setCartList]=useState([])
    const [recommand, setRecommand] = useState([])
    const [pharmacyList,setPharmacyList]=useState([])
    const [cartArr,setCartArr]=useState([])
    const [loading, setLoading] = useState(false)
    const [cartSum,setCartSum]=useState(0)
    const [cartPrice,setCartPrice]=useState(0)
    const [allChecked, setAllChecked] = useState(false)
    const [refresh,setRefresh] = useState(false)
    let orderNumber;  //先定义一个存储参数的变量
    if(orderNumber1){  //判断是否有参数
        orderNumber=orderNumber1;
        sessionStorage.setItem('orderNumber', orderNumber);
    }else{  //这种情况是说的刷新B页面时没有传递来的参数
        orderNumber = sessionStorage.getItem('orderNumber'); //当state没有参数时，取sessionStorage中的参数
    }
    useEffect(() => {
        oderdetailRequest(orderNumber).then(res => {
            let pharmacyList = []
     
            if(res.code === 1) {
                setOrderDetail(res.data[1])
                setMedDetail(res.data[0][0])
                let data1 = res.data[1] ? res.data[1] : []
                data1.forEach(item => {
                    item.checked = false
                    item.cartPrice = item.medPrice*item.medQuantity
                })
                for (let i = 0; i < data1.length; i++) {
                    for (let j = i+1; j < data1.length; j++) {
                        if(data1[i].orderNumber===data1[j].orderNumber){
                            ++i
                        }
                    }
                    pharmacyList.push({
                        orderNumber: data1[i].orderNumber,
                        pharmacyName: data1[i].pharmacyName,
                        promoteFull: data1[i].promoteFull,
                        promoteCut: data1[i].promoteCut,
                        freight: data1[i].freight,
                        checked: false
                    })
                }
                setCartList(data1)
                setPharmacyList(pharmacyList)
            } else {
                setCartList([])
                setPharmacyList([])
                setRecommand([])
            }
            setLoading(true)
            }).catch(err => {
                setLoading(true)
        })
    }, [orderNumber])

    useEffect(()=>{
        refresh && setTimeout(() => setRefresh(false))
    },[refresh])
    const backPage=()=>{
        window.history.back()
    }
//   选择要退的药品
    const changebuy = (e, v, index, i) => {
        let count = 0
        let storeCount = 0
        let storeSum = 0
        let medList = cartList
        let storeList = pharmacyList
        let newCartArr = cartArr
        let SUM = cartSum
        let sumPrice = cartPrice
        const n = newCartArr.findIndex(item => item.pharmacy.orderNumber === v.orderNumber)
        medList[index].checked = e.target.checked
        if(e.target.checked) {
            SUM += v.medQuantity
            sumPrice += v.medQuantity*v.medPrice
            if(n === -1) {
                newCartArr.push({
                    pharmacy: {
                        orderNumber: v.orderNumber,
                        pharmacyName: v.pharmacyName,
                        freight: v.freight
                    },
                    medList: [{
                        cartId: v.cartId,
                        medId: v.medId,
                        medName: v.medName,
                        medPhoto: v.medPhoto,
                        medSpec: v.medSpec,
                        medPacking: v.medPacking,
                        medApproval: v.medApproval,
                        medCompany: v.medCompany,
                        medPrice: v.medPrice,
                        cartPrice: v.cartPrice,
                        medQuantity: v.medQuantity
                    }]
                })
            } else {
                newCartArr[n].medList.push({
                    cartId: v.cartId,
                    medId: v.medId,
                    medName: v.medName,
                    medPhoto: v.medPhoto,
                    medSpec: v.medSpec,
                    medPacking: v.medPacking,
                    medPrice: v.medPrice,
                    cartPrice: v.cartPrice,
                    medQuantity: v.medQuantity
                })
            }
            medList.forEach((item, index) => {
                if(item.checked) {
                    count++
                    if(item.orderNumber === v.orderNumber) {
                        storeCount++
                    }
                }
                if(item.orderNumber === v.orderNumber) {
                    storeSum++
                }
            })
            if(count === medList.length) {
                setAllChecked(true)
            }
            if(storeCount === storeSum) {
                storeList[i].checked = true
            }
        } else {
            SUM -= v.medQuantity
            sumPrice -= v.medQuantity*v.medPrice
            const m = newCartArr[n].medList.findIndex(item => item.medId === v.medId)
            newCartArr[n].medList.splice(m, 1)
            if(newCartArr[n].medList.length === 0) {
                newCartArr.splice(m, 1)
            }
            storeList[i].checked = false
            setAllChecked(false)
        }
        setCartPrice(sumPrice)
        setCartSum(SUM)
        setPharmacyList(storeList)
        setCartList(medList)
        setRefresh(true)
    }
    // 全选
    const allCartChecked = (e) => {
        let medList = cartList
        let storeList = pharmacyList
        let newCartArr = cartArr
        let SUM = 0
        let sumPrice = 0
        medList.forEach(item => {
            item.checked = e.target.checked
        })
        storeList.forEach(item => {
            item.checked = e.target.checked
        })

        if(e.target.checked) {
            medList.forEach(item => {
                if (item.medState===1) {
                    
                    SUM += item.medQuantity
                    sumPrice += item.medQuantity*item.medPrice
                    const n = newCartArr.findIndex(v => v.pharmacy.orderNumber === item.orderNumber)
                    if(n === -1) {
                        newCartArr.push({
                            pharmacy: {
                                orderNumber: item.orderNumber,
                                pharmacyName: item.pharmacyName,
                                freight: item.freight
                            },
                            medList: [{
                                cartId: item.cartId,
                                medId: item.medId,
                                medName: item.medName,
                                medPhoto: item.medPhoto,
                                medSpec: item.medSpec,
                                medPacking: item.medPacking,
                                medApproval: item.medApproval,
                                medCompany: item.medCompany,
                                medPrice: item.medPrice,
                                medPacking: item.medPacking,
                                cartPrice: item.cartPrice,
                                medQuantity: item.medQuantity
                            }]
                        })
                    } else {
                        newCartArr[n].medList.push({
                            cartId: item.cartId,
                            medId: item.medId,
                            medName: item.medName,
                            medPhoto: item.medPhoto,
                            medSpec: item.medSpec,
                            medPacking: item.medPacking,
                            medApproval: item.medApproval,
                            medCompany: item.medCompany,
                            medPrice: item.medPrice,
                            cartPrice: item.cartPrice,
                            medQuantity: item.medQuantity
                        })
                    }
                }
            })
        } else {
            SUM = 0
            sumPrice = 0
            newCartArr = []
        }
        setCartPrice(sumPrice)
        setCartSum(SUM)
        setCartArr(newCartArr)
        setAllChecked(e.target.checked)
        setPharmacyList(storeList)
        setCartList(medList)
        setRefresh(true)
    }
    const healthPost = (val) => {
        setEvaluateText(val)
    }

    const imgChange = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic
        if(type === 'add') {
            let file = files[files.length-1].file
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    if(res1.code === 2) {
                        setFiles(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles(files)
            imgList.splice(index, 1)
            setEvaluatePic(imgList)
            Toast.hide()
        }
    }
    const toMedicine = (medId) => {
        props.history.push(`/med/${medId}`)
    }

    const toCommit = () => {
        if(cartArr.length > 0) {
            let arr=[];
            // orderMedId订单里每个药品的ID，medRefundQuantity是要退款的药品数量，
            // medState是订单里每个药品的状态
            // medState默认是1，表示正常，其它状态值自己决定，不会删除药品，不然没法恢复，
            // medQuantity为0的话判断下，h5不要显示出来就行
            orderDetail.map((item)=>{
                cartArr[0].medList.map((item1)=>{
                //     if (item.medId===item1.medId) {
                        arr.push({
                            orderMedId:item.orderMedId,
                            medState:item.medId===item1.medId?0:item.medState,
                            medRefundQuantity:item.medState===1?0:item.medQuantity,
                        })
                //     }
                })
            })
            
            const params = {
                array:arr
            }
   
            applyPartRequest(params).then(res => {
      
                if (res.code === 1) {
                    Toast.info('已申请退款，正在审核中……')
                    props.history.push({ pathname: '/mine/order'})
                }else{
                    Toast.info('商家拒绝退款……')
                    // props.history.push({ pathname: '/refuse'})
                }
            })
        // else if(cartArr.length===orderDetail.length){
        //     const params1 = {
        //         orderState: '5',
        //         refundReason: '',
        //         orderNumber: orderNumber
        //     }
        //     applyRefundRequest(params1).then(res => {
        //         if (res.code === 1) {
        //             Toast.info('已申请退款，正在审核中……', 1)
        //             // props.history.push('/mine/order/apple_refund')
        //         }
        //     })
        }else{
            Toast.info('你还没有选择药品哦', 1)
        }
    }
   
    return(
        <>
            <div className='header'>
                <div className='drawback-header'>
                    <div className='back-icon flexr' onClick={backPage}>
                        <i className='icon-xiangzuo iconfont'></i>
                    </div> 
                    <div className='order-kefu'>
                        <img src={kefu} alt="" />
                    </div>
                </div>
                <p className='drawback'>申请退款<img className='jujue-img' src={you} alt="" /></p>
                <div className='teshu-drawback'>
                    <p className='teshu-drawback-title'>特殊商品退款</p>
                    <p className='teshu-drawback-explain'>因为药品为特殊商品，只有质量问题商家才可同意退款</p>
                    <p className='teshu-drawback-explain'>商家同意后，用户需将商品退还至商家后，退款才可退回账户</p>
                </div>
                <div className='pharmacy'>
                    {pharmacyList.map((shop,i)=>{
                    return(
                        <div key={i}>
                        <div onClick={() => props.history.push(`/shop/${orderDetail.orderNumber}`)}>
                            {medDetail?<span className='phname'>{medDetail.pharmacyName}</span>:null}
                            <i className='iconfont icon-xiangyou'></i>
                        </div>
                        <div>
                        {orderDetail && orderDetail.map((item, index) => (
                        <div className='pharmacy-name' key={index} >
                            {/*  <img src={noselect} style={{marginTop:'20px',marginRight:'10px'}}/>  */}
                            {/* <input type="checkbox" /> */}
                            {item.medState===1?
                                <>
                                    <div className='cart-item-select'>
                                        <CheckboxItem onChange={(e)=>changebuy(e,item,index,i)} checked={item.checked} className='my-radio' />
                                    </div>
                                    <div className='order-meditem flex-apart-row'
                                    onClick={()=>toMedicine(item.medId)}>
                                        <div className='flexr'>
                                            <img src={item.medPhoto?item.medPhoto:defaultImg} alt=''
                                            onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                                            <div className='flexr-right'>
                                                <p>
                                                    <span className='name'>
                                                        {item.medClassify==='1'?<span className='chufang'>处方药</span>:null}
                                                        {item.medName}
                                                    </span>
                                                    <span className='price'>
                                                        {/* {item.originPrice?<span>￥{item.originPrice/100}</span>:null} */}
                                                        <span style={{marginRight:'10px',textDecoration:"line-through",color:'#999'}}>￥30.00</span>
                                                        ￥{item.medPrice/100}
                                                    </span>
                                                </p>
                                                <p className='guige'>{item.medSpec}</p>
                                                <span className='money'>x{item.medQuantity}</span>
                                            </div>
                                            
                                        </div>
                                        {item.length>1&&i!=item.length-1?<div className='xian'></div>:null}
                                    </div>
                                </>:null
                            //     <>
                            //     <div className='cart-item-select'>
                            //         <CheckboxItem disabled className='my-radio' />
                            //     </div>
                            //     <div className='order-meditem flex-apart-row'
                            //     onClick={()=>toMedicine(item.medId)}>
                            //         <div className='flexr'>
                            //             <img src={item.medPhoto?item.medPhoto:defaultImg} alt=''
                            //             onError={(e) => {e.target.onerror = null;e.target.src=defaultImg}}/>
                            //             <div className='flexr-right'>
                            //                 <p>
                            //                     <span className='name'>
                            //                         {item.medClassify==='1'?<span className='chufang'>处方药</span>:null}
                            //                         {item.medName}
                            //                     </span>
                            //                     <span className='price'>
                            //                         {/* {item.originPrice?<span>￥{item.originPrice/100}</span>:null} */}
                            //                         <span style={{marginRight:'10px',textDecoration:"line-through",color:'#999'}}>￥30.00</span>
                            //                         <span style={{fontSize:'13px'}}>￥{item.medPrice/100}</span>
                            //                         <span style={{position:'absolute',top:'60px',left:'235px',color:'#f00'}}>退款中...</span>
                            //                     </span>
                            //                 </p>
                            //                 <p className='guige'>{item.medSpec}</p>
                            //                 <span className='money'>x{item.medQuantity}</span>
                            //             </div>
                                        
                            //         </div>
                            //         {item.length>1&&i!=item.length-1?<div className='xian'></div>:null}
                            //     </div>
                            // </>
                            }
                            </div>
                            ))}
                        </div>
                        <div className='xian'></div>
                        <div className='all-select'>
                            <p>
                                {/* <input type="checkbox" />
                                <span>全选</span> */}
                                <CheckboxItem checked={allChecked}
                        onChange={(e)=>{allCartChecked(e)}}
                        className='my-radio' >全选</CheckboxItem>
                            </p>
                            <p>
                                <span>退款金额：</span>
                                ￥<span className='total'>{cartPrice/100}</span>
                            </p>
                        </div>
                        </div>)
                        })}
                </div>
                {medDetail === null ? null :
                <div className='sendcomment-yuanyin'>
                    <p className='sure'>退款原因</p>
                    <div className='comment-content'>
                        <div className='sendcomment-area'>
                            <TextareaItem
                            className='sendcomment-area-text'
                            placeholder='补充详细退款原因，有利于商家更快的帮您处理'
                            rows={6}
                            count={100}
                            onChange={healthPost}/>
                        </div>
                        <div className='sendcomment-bottom'>
                            <ImagePicker
                            files={files}
                            length={3}
                            onChange={imgChange}
                            onImageClick={(index, fs) => console.log(index, fs)}
                            selectable={files.length < 6}
                            accept='image/gif,image/jpeg,image/jpg,image/png'/>
                            <p>添加图片</p>
                        </div>
                    </div>
                </div>
                }
                <div className='tuikuan-tijiao'>
                    <p className='tuikuan-left'>
                        <img className='tuikuan-tijiao-left' src={tuikuanleft} alt="" />
                        <p className='tuikuan-money-left'>
                            <span className='tuikuan-money-left-top'>退款金额 ￥{cartPrice/100}</span>
                            <span className='tuikuan-money-left-bottom'>退款成功将退回到平台个人账户中</span>
                        </p>
                    </p>
                    <p className='tuikuan-right' onClick={()=>toCommit()}>
                        <img className='tuikuan-tijiao-right' src={tuikuanright} alt="" />
                        <p className='tuikuan-money-right'>提交</p>
                    </p>
                </div>
            </div>
        </>
    )
}