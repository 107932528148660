import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import "./queryreportdetails.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/head.png"
import {outerDetails, getInspectionItem} from '../../../api/hospitalapi/electronic'
import getUrl from '../../../component/GetUrlParam'
/*
*  挂号
* */

export default class queryreportdetails extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:'',
            id:'',
            reportid:'',
            outerList:'',
            innerList:''
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        if (getUrl('id', this.props.location.search)){
            this.setState({
                id:getUrl('id', this.props.location.search),
                reportid:getUrl('reportid', this.props.location.search),
            },()=>{
                getInspectionItem(getUrl('reportid', this.props.location.search)).then(res=>{
                    // console.log(res)
                    if (res&&res.data&&res.data[0]){
                        this.setState({
                            outerList:res.data[0],
                            innerList:res.data[1]
                        })
                    }
                })
                outerDetails(getUrl('reportid', this.props.location.search)).then(res=>{
                    // console.log(res)
                    if (res&&res.data&&res.data[0]){
                        this.setState({
                            list:res.data[0][0]
                        })
                    }
                })
            })
        }
    }


    goBack=()=>{
        const { id,reportid} = this.state
        this.props.history.push(`/queryreportnews?id=${id}&reportid=${reportid}`)
    }
    render() {
        const { outerList,innerList,list } = this.state
        return (
            <div className='setbox queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.goBack()} />   报告详情
                </div>
                {outerList?outerList.map(item=>(<div>
                    <div className='report-title'>{item.name}</div>
                    <div className='innerport-title'>
                        <p>项目名称</p>
                        <p>结果(参考值)</p>
                    </div>
                    {innerList?innerList.map(i=>(<>
                    {i.category_id==item.id?<div>
                            <div className='innerport'>
                                <p>{i.name}</p>
                                {i.reference_high?<p>{i.result+i.unit+'('+i.reference_low+'~'+i.reference_high+i.unit+')'}</p>:<p>{i.result+i.unit}</p>}
                            </div>

                        </div>:null}
                    </>)):null}
                </div>)):null}
                <p className='queryreportdetails-times'>
                    <p>
                        <span>检查时间</span>
                        <span>{list&&list.inspectTime}</span>
                    </p>
                    <p>
                        <span>报告时间</span>
                        <span>{list&&list.reportTime}</span>
                    </p>
                </p>
            </div>
        )
    }
}
