import React, { Component } from 'react'
import "./style.less"
import { Popup, Rate, TextArea,ImageViewer } from "antd-mobile-v5"
import { Toast, ImagePicker } from "antd-mobile"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/touxiang.png"
import { getchat, addchatnote,getChatnote,allPrespribingids,patientDocdiagnosis,wzstate,getnewslist } from "../../../api/hospitalapi/chat"
import { zipImg } from '../../../component/zipImage'
import { uploadpic } from "../../../api/hospitalapi/upload"
import { phoneLogin } from "../../../api/hospitalapi/login"
import { changeuser } from "../../../api/hospitalapi/home"



import getUrl from '../../../component/GetUrlParam'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: JSON.parse(localStorage.getItem("item")),
            visible1: false,
            value: "",
            consultationId: localStorage.getItem("consultationId"),
            consultationStu:"",
            detailContent: '',
            detailContent0: localStorage.getItem("detailContent0"),
            listtext:JSON.parse(localStorage.getItem("listtext"))?JSON.parse(localStorage.getItem("listtext")):{},
            newslist: [],
            userpic: localStorage.getItem("patientAvatar"),
            userId:localStorage.getItem("patientId"),
            fileList: [],
            consultationDetail:'',
            timer:0,
            docterDiagnosis:'',
            memberId:'',
            imgFd:0,
            index1:-1,
            orderNumber:localStorage.getItem("orderNumber")?localStorage.getItem("orderNumber"):'',
            docterId:'',
            item00:'',
            chatLength:0,
            consultationFiles:'',
            image:{
                url: '',
                visible: false
            },
        }
    }
    componentWillMount = () => {
        let that=this
        const {userId,item,consultationId,orderNumber,docterId}=this.state

        // 获取从零售跳转过来网址携带的信息
        if (getUrl('patientPhone',window.location.href)) {
            window.localStorage.clear()
            const patientName=getUrl('patientName',window.location.href)
            const consultationId=getUrl('consultationId',window.location.href)
            const patientPhone1=getUrl('patientPhone',window.location.href)
            const patientAvatar=getUrl('patientAvatar',window.location.href)
            const patientId=getUrl('patientId',window.location.href)
            const orderNumber=getUrl('orderNumber',window.location.href)
            const docterId=getUrl('docterId',window.location.href)

            const keyWord=decodeURIComponent(patientName)//单个参数解码
            localStorage.setItem("patientPhone", patientPhone1)
            localStorage.setItem("patientAvatar", patientAvatar)
            localStorage.setItem("patientName", keyWord)
            localStorage.setItem("patientId", patientId)
            localStorage.setItem("consultationId", consultationId)
            localStorage.setItem("orderNumber", orderNumber)
            this.setState({
                consultationId:consultationId,
                userpic:patientAvatar,
                userId:patientId,
                docterId:docterId,
                consultationStu:'-1',
                orderNumber:orderNumber
            })

            // 零售跳转问诊手机号自动登录
            phoneLogin({patientPhone:patientPhone1}).then(res=>{

                if (res.code==1&&!patientName) {
                    changeuser({patientId:patientId,patientName:patientPhone1}).then(res1=>{
                        // console.log(res1);
                    })
                }
                if (res.code==1) {
                    //医生获取信息列表
                    getnewslist({
                        userId:patientId
                    }).then(res2=>{
                        if (res2&&res2.data[0].length) {
                            res2.data[0].map(item0=>{
                                if (item0.order_number==orderNumber) {
                                    this.setState({
                                        item00: item0
                                    })
                         
                                    localStorage.setItem("item",JSON.stringify(item0))
                                }
                            })
                        }
                    })

                    //获取问诊信息
                    getchat({
                        userId:patientId,
                        docterId:docterId,
                        orderNumber
                    }).then(res3=>{
             
                        if (res3.data[0].length) {
                            // localStorage.setItem("consultationId", res3.data[0][0].consultationId)
                            getChatnote({
                                consultationId:res3.data[0][0].consultationId,
                            }).then(res4=>{
              
                                localStorage.setItem("listtext",JSON.stringify(res4.data[1][0]))
                                this.setState({
                                    // consultationId: res.data[0][0].consultationId,
                                    consultationStu:res3.data[0][0].consultationStu,
                                    listtext:res4.data[1][0],
                                    consultationDetail:res4.data[0][0].consultationDetail,
                                    memberId:res4.data[0][0].memberId
                                },()=>{
                                    // 获取聊天信息
                                    this.getchatnews()
                                    //获取聊天节点设置默认到最底部
                                    this.scrollToBottom()
                                })
                            })
                        }
                    })
                }
            })
        }else{
            //获取问诊信息
            getchat({
                userId,
                docterId: item.docterId?item.docterId:item.docter_id,
                orderNumber:orderNumber!='null'?orderNumber:''
            }).then(res3=>{
    
                if (res3.data[0].length) {
                    localStorage.setItem("consultationId", res3.data[0][0].consultationId)
                    //获取聊天内容
                    getChatnote({
                        consultationId:res3.data[0][0].consultationId,
                    }).then(res2=>{
       
                        if (res2.code==1) {
             
                            // debugger
                            that.setState({
                                // consultationId: res.data[0][0].consultationId,
                                consultationStu:res3.data[0][0].consultationStu,
                                listtext:res2.data[1][0],
                                consultationDetail:res2.data[0][0].consultationDetail,
                                consultationFiles:res2.data[0][0].consultationFiles,
                                memberId:res2.data[0][0].memberId,
                                docterId: item.docterId?item.docterId:item.docter_id,
                                consultationId:res3.data[0][0].consultationId,
                            },()=>{
               
                                // 获取聊天信息
                                this.getchatnews()
                                localStorage.setItem("listtext",JSON.stringify(res2.data[1][0]))
                            })
                        }
                    })
                }else{
                    this.setState({
                        // consultationId: res.data[0][0].consultationId,
                        consultationStu:'-1',
                    })
                }
                if (res3&&res3.data[1]&&res3.data[1].length==1) {
                    this.setState({
                        consultationStu:'-1',
                    })
                }
            })

            //获取聊天节点设置默认到最底部
            this.scrollToBottom()
        }
    }
    //获取聊天节点设置默认到最底部
    scrollToBottom = () => {
        // const scrollHeight = this.container.scrollHeight
        // const clientHeight = this.container.clientHeight
        // const scrollTop = scrollHeight - clientHeight
        // this.container.scrollTop = scrollTop > 0 ? scrollTop : 0
    }
    // 提交评论
    totalk = () => {
        this.setState({
            visible1: false
        })
    }
    // 查看处方单详情
    toinfo = (detailContent) => {
        const { listtext } = this.state
        const { timer,item } = this.state
        window.clearInterval(timer)

        localStorage.setItem("detailContent0", detailContent)
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo",
            params: {
                // listtext: listtext,
                // detailContent:detailContent,
                a:1
            }
        })
    }
    // 返回消息列表
    goback = () => {
        const { timer,item } = this.state
        window.clearInterval(timer)
        this.props.history.push({
            pathname: "/news",
            params: {
                item: item
            }
        })
    }
    //发送信息
    sendout = () => {
        const { item, consultationId, detailContent, userpic,consultationStu } = this.state

        if (item) {
            if (detailContent) {
                addchatnote({
                    consultationId: consultationId,
                    Identity: 1,
                    detailType: 1,
                    detailContent,

                }).then(res=>{
     
                    // 获取聊天信息
                    this.getchatnews()
                    const newTimer = window.setTimeout(() => {
                        this.scrollToBottom();
                        window.clearTimeout(newTimer)
                    }, 50);
                    this.setState({
                        detailContent: ""
                    })
                })
            } else {
                Toast.fail("请先输入内容", 1)
            }

        } else {
            Toast.fail("数据错误", 1)
            this.props.history.push("/")
        }
    }
    // 获取聊天信息
    getchatnews = () => {
        const { timer,item ,userId,consultationId,memberId,orderNumber,docterId,newslist,chatLength} = this.state
        window.clearInterval(timer)
        // console.log(item);
        getchat({
            userId: userId,
            docterId: docterId?docterId:item.docterId?item.docterId:item.docter_id,
            orderNumber:orderNumber!='null'?orderNumber:''
        }).then(res=>{
   
            if (res.data[1]) {
                this.setState({
                    chatLength:res.data[1].length
                })
                //   console.log(chatLength,res.data[1].length,res.data[1][res.data[1].length-1].Identity=='2');
                if (chatLength!=0&&chatLength<res.data[1].length&&res.data[1][0].Identity=='2') {
                    Toast.info('您有新消息')
                }
            }
            if (res.data[0].length) {
                this.setState({
                    // consultationId: res.data[0][0].consultationId,
                    consultationStu:res.data[0][0].consultationStu,
                    newslist: res.data[1].length?res.data[1]:[],
                })
            }
            allPrespribingids(consultationId?consultationId:item.consultation_id).then(res1=>{
      
                let arr=[]
                if (res1&&res1.data[0].length) {
                    res1.data[0].map(item=>{
                        // 患者获取医生诊断详情
                        patientDocdiagnosis({consultationId: item.detail_content}).then(res2=>{
         
                            arr.push({prescribingId:item.detail_content,docterDiagnosis:res2.data[0][0].docter_diagnosis})
              
                            this.setState({
                                docterDiagnosis:arr
                            })
                        })
                    })
                }
            })
            // 获取问诊单所有电子处方id
            // // 患者获取医生诊断详情
            // let res2=await patientDocdiagnosis({consultationId: consultationId})
            // // console.log(res2);
            // if (res2&&res2.data[0].length) {
            //     this.setState({
            //         docterDiagnosis:res2.data[0][0].docter_diagnosis
            //     })
            // }
            // console.log(res2);

            // console.log(res.data[0][0].consultationStu != '3');
            if (!res.data[0].length||(res.data[0].length&&res.data[0][0].consultationStu != '3')) {
                const newTimer = window.setInterval(() => {
                    this.getchatnews()
                    //   this.scrollToBottom();
                }, 5000);
                // localStorage.setItem('timer',newTimer)
                this.setState({
                    timer: newTimer
                })
            }

            if (res&&res.data[1]&&res.data[1].length>1) {
                wzstate({docterId:docterId?docterId:item.docterId,userId:userId,memberId:memberId}).then(res=>{
       
                    let l=res.data[0].length-1
                    if (res.data[0].length) {
                        this.setState({
                            consultationStu:res.data[0][l].consultationStu,
                        })
                    }
                })
            }
        })
        // }else{
        // }
    }
    // 输入聊天内容
    getdetailContent = (e) => {
        this.setState({
            detailContent: e.target.value
        })
    }
    // 销毁
    componentWillUnmount = () => {
        const { timer } = this.state
        window.clearInterval(timer)
    }
    //图片上传发送
    imgChange = async (files, type, index) => {
        const { item, consultationId, detailContent, userpic } = this.state
        if (type == "add") {
            let file = files[files.length - 1].file
            if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                if (file.size < 1024 * 1024) {
                    let formData = new FormData()
                    const res = await zipImg(file)
                    // console.log(res);
                    formData.append('file', res)
                    const res2 = await uploadpic(formData)
      
                    if (item) {
                        //添加聊天内容
                        const res = await addchatnote({
                            consultationId: consultationId,
                            Identity: 1,
                            detailType: 2,
                            detailContent: res2.data
                        })
                        this.getchatnews()
                        this.setState({
                            detailContent: ""
                        })
                    } else {
                        Toast.fail("数据错误", 1)
                        this.props.history.push("/")
                    }
                }else{
                    Toast.show('请上传小于1M的图片', 1)
                }
            }else{
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            this.setState({
                fileList: []
            })
        }
    }
    imgFd = (index) => {
        const { imgFd } = this.state
        if (imgFd==1) {
            this.setState({
                imgFd: 0,
                index1:-1
            })
        }else{
            this.setState({
                imgFd: 1,
                index1:index
            })
        }
    }
    sxImg = () =>{
        const { imgFd } = this.state
        if (imgFd==1) {
            this.setState({
                imgFd: 0,
                index1:-1
            })
        }
    }
    render() {
        const { item,item00,orderNumber,image,consultationFiles, visible1,index1, docterDiagnosis,imgFd, detailContent, consultationDetail,newslist, userpic, fileList,listtext,consultationStu } = this.state
     
        return (
            <>
                <div className='chatbox' onClick={this.sxImg}>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.goback} />
                        <h1>
                            {item00||item.docter_name?<><span>{item00.docter_name?item00.docter_name:item.docter_name?item.docter_name:''}</span><span>{item?item.docter_title?item.docter_title:item.title:''}</span><span>{item?item.department:''}</span></>:
                                <><span>{item?item.docterName:''}</span><span>{item?item.title:''}</span><span>{item?item.department:''}</span></>}
                        </h1>
                    </div>
                    {consultationStu=='-1'?<div className='box-tips'>病情已发送,等待医生接诊</div>:
                        consultationStu=='0'?<div className='box-tips'>病情已发送,医生接诊中</div>:
                            consultationStu=='3'?<div className='box-tips'>病情已发送,医生已退诊</div>:
                                item.patientNum=='0'?<div className='box-tips'>病情已发送,医生接诊结束</div>:
                                    null}
                    <div className='box-container' ref={el => this.container = el}>
                        <div className='container-dan'>
                            <img src={userpic?userpic:icon2} alt="" />
                            <div className='dan-left'>
                                <div className='left-top'>
                                    <p>图文问诊</p>
                                    <p>￥{item&&(item.docterPicCharge/100).toFixed(2)}</p>
                                </div>
                                <div className='itembox' style={{ backgroundColor: "white" }}>
                                    <div className='left-item'>
                                        <p>患者</p>
                                        <p>{listtext.patient_name}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>确诊疾病</p>
                                        <p style={{wordWrap:'break-word'}}>{consultationDetail}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>既往史</p>
                                        <p style={{wordWrap:'break-word'}}>{listtext.past_history_note}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>过敏史</p>
                                        <p style={{wordWrap:'break-word'}}>{listtext.allergic_history_note}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>家族遗传史</p>
                                        <p style={{wordWrap:'break-word'}}>{listtext.family_genetic_history_note}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>肝功能异常</p>
                                        <p>{listtext.abnormal_liver_function==0?'无':'异常'}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>肾功能异常</p>
                                        <p>{listtext.abnormal_renal_function==0?'无':'异常'}</p>
                                    </div>
                                    {consultationFiles?<div className='left-item'>
                                        <p>电子病历</p>
                                        <p><img src={consultationFiles} alt="" onClick={() => {
                                            this.setState({image:{
                                                    url: consultationFiles,
                                                    visible: true
                                                }})
                                        }}/></p>
                                    </div>:null}
                                    <div className='left-title'>互联网医院将保证你的隐私安全,请放心与医生交流</div>
                                </div>

                            </div>
                        </div>
                        {newslist.length?newslist.map((item1, index) => {
                            return (
                                <div className={item1.Identity == "1" ? "container-right" : "container-left"} key={item1.createTime}>
                                    {item1.Identity == "1"?<img src={userpic!='null' ? userpic : icon2} alt="" />:
                                        <>
                                            {item00||item.docter_avatar?<img src={item.docter_avatar!='null'?item.docter_avatar:icon2} alt="" />:
                                                <img src={item.docterAvatar?item.docterAvatar:item.dcocterAvatar?item.dcocterAvatar:icon2} />}
                                        </>}
                                    {item1.detailType == "1" ? <div className='left-text' style={{wordWrap:'break-word'}}>
                                        {item1.detailContent}
                                    </div> : item1.detailType == "2" ? <div className='left-text'>
                                        <img src={item1.detailContent} alt="" onClick={()=>this.imgFd(index)} className={imgFd==1&&index==index1?'fd-img':'right-img'} />
                                    </div> :item1.detailType == "3" ? <div className='container-cfd'>
                                        {/* <img src={userpic ? userpic : icon2} alt="" /> */}
                                        <div className='dan-left'>
                                            <div className='left-top'>
                                                <p>处方单</p>
                                            </div>
                                            <div className='itembox' style={{ backgroundColor: "white" }}>
                                                <div className='left-item'>
                                                    <p>患者</p>
                                                    <p>
                                                        <span>{listtext.patient_name}</span><span>{listtext.patient_sex}</span><span>{(new Date().getFullYear())-(new Date(listtext.patient_birthday).getFullYear())}</span>
                                                    </p>
                                                </div>
                                                <div className='left-item left-item1'>
                                                    <p>诊断</p>
                                                    {docterDiagnosis&&docterDiagnosis.map(i=>(
                                                        <p>{item1.detailContent==i.prescribingId?i.docterDiagnosis:''}</p>
                                                    ))}
                                                </div>
                                                <div className='left-title'>
                                                    <button onClick={(v)=>this.toinfo(item1.detailContent)}>查看详情</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div> : null}
                                </div>
                            )
                        }):null}
                        {consultationStu=='1'?<div className='container-evaluate'>
                            <div>
                                <h1>请您对医生的这次服务进行评价</h1>
                                <button onClick={() => this.setState({
                                    visible1: true
                                })}>给评价</button>
                            </div>
                        </div>:null}
                    </div>
                    {consultationStu=='0'?<div className='box-bottom'>
                        <input type="text" placeholder='病情描述' value={detailContent} onChange={(e) => this.getdetailContent(e)} />
                        <ImagePicker
                            files={fileList}
                            length={1}
                            style={{ width: "50px", height: "50px", padding: "0", display: "inline-block", verticalAlign: "middle" }}
                            onChange={this.imgChange}
                            selectable={fileList.length < 1}
                            accept='image/gif,image/jpeg,image/jpg,image/png' />
                        <button onClick={this.sendout}>发送</button>
                    </div>:null}
                </div>
                <Popup
                    visible={visible1}
                    className='popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{ minHeight: '40vh' }}
                >
                    <div className='popup-box'>
                        <div className='box-top'>
                            <img src={icon2} alt="" />
                            <div>
                                <h1>评价医生本次服务</h1>
                                <p>
                                    <span>{item.docterName}</span><span>{item.title}</span><span>{item.department}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='pingfen'>
                        <Rate
                            style={{
                                '--active-color': '#1F87FF'
                            }}
                            onChange={val => Toast.show(val.toString())} />
                    </div>
                    <div className='popup-text'>
                        <textarea name="" id="" cols="50" rows="6" placeholder='谈谈你对本次就医医生的评价吧~'>

                        </textarea>

                    </div>
                    <div className='popup-btn'>
                        <button onClick={this.totalk}>提交评论</button>
                    </div>

                </Popup>
                <ImageViewer
                    image={image.url}
                    visible={image.visible}
                    onClose={() => {
                        this.setState({image: {
                                url: '',
                                visible: false
                            }})
                    }}
                />
            </>

        )
    }
}
