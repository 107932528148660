import { axiosInstance ,getvideoSetting} from '../config'

//图片上传
export const uploadpic = (data) => {
    return axiosInstance.post('/api/hosp/upload/picture', data)
}
//3.2.1.获取视频配置
// export const videoSetting = (params,token) => getvideoSetting('https://endpoint.pre.cecdat.com/ep/third-party/video/setting', params,token);
// export const videoSetting = (params,token) => getvideoSetting('https://ep-endpoint.cecdat.com/ep/third-party/video/setting', params,token);

// 2024年3月10日新加
export const videoSetting = (userId) => getvideoSetting(`https://egcare-endpoint.yyj-health.com/sec/third-party/video/setting?institutionCode=ZJYYJ&userId=${userId}`);
