import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import "./queryreportnews.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/head.png"
import {getAll,allReports} from '../../../api/hospitalapi/electronic'
import getUrl from '../../../component/GetUrlParam'
import Nodata from "../../../component/Nodata";
import {Toast} from "antd-mobile";
import moment from "moment";
/*
*  挂号
* */

export default class queryreportnews extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:'',
            reportList:'',
            id:''
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        if (getUrl('id', this.props.location.search)){
            this.setState({
                id:getUrl('id', this.props.location.search)
            })
            getAll({patientId:userId, id:getUrl('id', this.props.location.search)
        }).then(res=>{
                // console.log(res)
                if (res&&res.data&&res.data[0].length){
                    this.setState({
                        list:res.data[0][0]
                    },()=>{
                        this.reportOut()
                    })
                }
            })
        }
    }
    reportOut=async ()=>{
        const {list} =this.state
        if (list){
            let res=await allReports(list.healthCardNumber)
            // console.log(res)
            if (res&&res.data&&res.data[0].length){
                this.setState({
                    reportList:res.data[0]
                })
            }
        }

    }
    toreportDetails=(item)=>{
        const { id } = this.state
        if (item.state==1){
            Toast.info('未完成，暂时无法查看')
        }else{
            this.props.history.push(`/queryreportdetails?id=${id}&reportid=${item.id}`)
        }
    }
    render() {
        const { list,reportList } = this.state
        return (
            <div className='setbox queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/queryreport")} />   报告查询
                </div>
                {list?<div className='name-card'>
                    <p>{list.patientName}</p>
                    <p>电子健康卡</p>
                    <p>{list.idCard1}</p>
                </div>:null}
                <div className='look-time'>
                    <p>查询时间</p>
                    {reportList?<p>{reportList[reportList.length-1].inspectTime}~{moment().add('days', 0).format('YYYY-MM-DD')} ＞</p>:<p>{moment().add('days', 0).format('YYYY-MM-DD')}</p>}
                </div>
                <div className='all-year'>
                    <p className='one-year'>2022年</p>
                    {reportList?reportList.map(item=>(<p className='one-tip'>
                        <p className='one-tip-time'>{item.inspectTime}</p>
                        <div className='one-tip-news'>
                            <h2>{item.name}</h2>
                            <p className={item.state==1?'one-tip-loading':'one-tip-success'}
                               onClick={() => this.toreportDetails(item)}>{item.state==1?'分析中':'已完成 ＞'}</p>
                            <p className='one-tip-time-bottom'>{item.state==1?item.inspectTime:item.reportTime}</p>
                        </div>
                    </p>)):<Nodata type='报告'/>}
                </div>
            </div>
        )
    }
}
