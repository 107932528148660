import React, { Component } from 'react'
import {Modal, Popup} from "antd-mobile-v5"
import { Tabs,Switch } from 'antd-mobile'
import "./outpatientpayment.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/qiehuan.png"
import icon3 from "../../../static/img/yy/paybgc.png"
// import icon3 from "../../../static/img/head.png"
import {prescribed} from '../../../api/hospitalapi/chat'
import {changeDefault, getAll,allOutpatients} from "../../../api/hospitalapi/electronic";
import Nodata from "../../../component/Nodata";
/*
*  门诊缴费
* */

export default class outpatientpayment extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode: false,
            value1: "",
            value2: "",
            userId: localStorage.getItem("patientId"),
            list: [],
            tabs: [{title: '待缴费'}, {title: '已缴费'}],
            userList: '',
            id: '',
            oneUser: "",
            visible1:false,
            a: 0,
            list1:[],
            list2:[]
        }
    }

    componentDidMount = async () => {
        const {userId} = this.state
        let a
        let res = await getAll({patientId: userId})
        // console.log(res)
        if (res && res.data && res.data[0].length) {
            res.data[0].map(item => {
                if (item.electronicDefault == 1) {
                    a = item
                }
            })
            if (!a){
                a=res.data[0][0]
            }
            this.setState({
                userList: res.data[0],
                id: a.id,
                oneUser: a
            })
        }
        this.allOutpatients0()
    }
    allOutpatients0=async ()=>{
        const {oneUser} =this.state
        let arr1=[]
        let arr2=[]
        let res=await allOutpatients(oneUser.healthCardNumber)
        // console.log(res)
        if (res&&res.data[0]){
            res.data[0].map(item=>{
                if (item.state==1){
                    arr1.push(item)
                }else{
                    arr2.push(item)
                }
            })
        }
        this.setState({
            list1:arr1,
            list2:arr2,
        })
    }
    sureChange = () => {
        const {userList, a, allMounthDetails, id, userId} = this.state
        let arr = []
        // allMounthDetails.map(item => {
        //     if (item.clinicId == userList[a].id) {
        //         arr.push(item)
        //     }
        // })
        // console.log(arr)

        this.setState({
            // oneMounthDetails: arr,
            id: userList[a].id,
            oneUser: userList[a],
            visible1: false
        }, () => {
            changeDefault({patientId: userId, id: userList[a].id}).then(res => {
                // console.log(res)
                if (res&&res.code==1){
                    this.allOutpatients0()
                }
            })
        })
    }
    showNews = () => {
        this.props.history.push("/healthcardnews")
    }
    toPaymentdetails=(id)=>{
        this.props.history.push(`/paymentdetails?id=${id}`)
    }
    renderContent = (tab, index) => {
        // console.log(index);
        const {list1, list2, visiable, fapiaoName0, fapiaoDefault} = this.state
        return (
            <>
                {index == 0 ? <div className='payment'>
                    {list1.length?list1.map(item=>(<div className='pay-cart' onClick={() => this.toPaymentdetails(item.id)}>
                            <p className='name'>{item.patientName}</p>
                            <p className='pay-id'>就诊卡号： {item.cardNumber}</p>
                            <p className='topay'>去缴费</p>
                            {/*<p className='pay-id'>缴费时间： {item.payTime}</p>*/}
                            <p className='pay-id'>缴费金额： ￥{(item.amount/100).toFixed(2)}</p>
                        </div>)):null}
                    </div> :
                    index == 1 ? <div className='payment'>
                        {list2.length?list2.map(item=>(<div className='pay-cart' onClick={() => this.toPaymentdetails(item.id)}>
                                <p className='name'>{item.patientName} <span style={{float: 'right'}}>缴费成功</span></p>
                                <p className='pay-id'>就诊卡号： {item.cardNumber}</p>
                                <p className='topay1'>＞</p>
                                <p className='pay-id'>缴费时间： {item.payTime}</p>
                                <p className='pay-id'>缴费金额： ￥{(item.amount/100).toFixed(2)}</p>
                            </div>)):null}
                        </div>
                        : null}
            </>
        )
    }

    render() {
        const {tabs, oneUser,visible1,userList,a} = this.state
        return (
            <div className='setbox queryreport-setbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")}/> 门诊缴费
                </div>
                <div className='outpatientpayment-outer'>
                    {oneUser ? <>
                        <div className='payment-cart' onClick={() => {
                            this.setState({
                                visible1: true
                            })
                        }}>
                            {/*<img className='payment-cart-bgc' src={icon3}/>*/}
                            <p className='name'>{oneUser.patientName} <span></span></p>
                            <p className='qiehuan-user'>
                                <img src={icon2}/>
                                <span>切换就诊人</span>
                            </p>
                            <p className='name'>{oneUser.idCard}</p>
                        </div>
                        <div style={{backgroundColor: "#fff"}}>
                            <Tabs
                                tabs={tabs}
                                tabBarTextStyle={{fontSize: '16px', padding: '0', color: '#333 ', height: '62px'}}
                                tabBarActiveTextColor="#1F87FF"
                                // tabBarInactiveTextColor="#FFFFFF"
                                tabBarBackgroundColor='none'
                                tabBarUnderlineStyle={{
                                    border: '2px #1A73E8 solid',
                                    transform: 'scaleX(0.3)',
                                    bottom: '10px'
                                }}
                                renderTabBar={props => <Tabs.DefaultTabBar {...props} page={6}/>}
                            >
                                {this.renderContent}
                            </Tabs>
                        </div>
                    </> : <Nodata type='挂号'/>}
                </div>

                <Popup
                    visible={visible1}
                    className='popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{height: '40vh'}}
                >
                    <div className='popup-box'>
                        <div className="addbox-top">
                            <span onClick={() => {
                                this.setState({
                                    visible1: false
                                })
                            }}>取消</span>
                            <span onClick={() => this.sureChange()}>确定</span>
                        </div>
                        <div className='box-body'>
                            {userList.length ? userList.map((item, index) => (
                                <div className={index == a ? 'box-register1' : 'box-register'} onClick={() => {
                                    this.setState({
                                        a: index
                                    })
                                }}>
                                    <span>{item.patientName.substring(0, 1) + "*" + item.patientName.substring(2)}</span>
                                    <span>{item.idCard}</span>
                                </div>)) : null}
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}
