import React, { Component } from 'react'
import {DatePicker, Modal, Popup, Rate} from "antd-mobile-v5"
import { Tabs,Switch } from 'antd-mobile'
import "./style.less"
import Nodata from "../../../component/Nodata";
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/qiehuan.png"
import icon3 from "../../../static/img/yy/paybgc.png"
import icon4 from "../../../static/img/yy/ghdate.png"
import icon5 from "../../../static/img/yy/datedown.png"
import {getAll,changeDefault} from '../../../api/hospitalapi/electronic'
import {monthRecordDetails} from '../../../api/hospitalapi/rejister'
/*
*  我的挂号
* */

export default class historicalregistration extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            visible: false,
            visible1: false,
            value1: "",
            value2: "",
            userId: localStorage.getItem("patientId"),
            userList: [],
            oneUser: '',
            id: '',
            hostoryDate: '',
            a: 0,
            nowdate: '',
            oneMounthDetails: [],
            allMounthDetails: [],
        }
    }

    componentDidMount = async () => {
        const {userId} = this.state
        const now = new Date()
        const nowdate0 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))

        let a
        let res = await getAll({patientId: userId})
        // console.log(res)
        if (res && res.data && res.data[0].length) {
            res.data[0].map(item => {
                if (item.electronicDefault == 1) {
                    a = item
                }
            })
            this.setState({
                userList: res.data[0],
                id: a.id,
                oneUser: a
            })
        }
        this.allRecordDetails()
    }
    allRecordDetails=async ()=>{
        const {oneUser,id} = this.state
        const now = new Date()
        const nowdate0 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
        let arr=[]
        let res1 = await monthRecordDetails({clinicId:oneUser.id, clinicTime: nowdate0})
        // console.log(res1)
        if (res1 && res1.data && res1.data[0]) {
            res1.data[0].map(item=>{
                if (item.clinicId == id){
                    arr.push(item)
                }
            })
            // console.log(arr)
            this.setState({
                oneMounthDetails: arr,
                allMounthDetails:res1.data[0]
            })
        }
    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification = () => {
        const {value1, value2} = this.state
        // console.log(value1, value2);
    }
    changevalue = (e, code) => {
        if (code == 1) {
            this.setState({
                value1: e.target.value
            })
        } else {
            this.setState({
                value2: e.target.value
            })
        }
    }
    toHistoryDetails = (outpatientNumber) => {
        this.props.history.push(`/mine/historicaldetails?outpatientNumber=${outpatientNumber}`)
    }
    sureChange = () => {
        const {userList, a,allMounthDetails,id, userId} = this.state
        let arr=[]
        allMounthDetails.map(item=>{
            if (item.clinicId == userList[a].id){
                arr.push(item)
            }
        })
        // console.log(arr)

        this.setState({
            oneMounthDetails: arr,
            id: userList[a].id,
            oneUser: userList[a],
            visible1: false
        }, () => {
            changeDefault({patientId: userId, id: userList[a].id}).then(res => {
                // console.log(res)
                if (res&&res.code==1){
                    this.allRecordDetails()
                }
            })
        })
    }
    goBack=()=>{
        // window.history.back()
        this.props.history.push('/mine')
    }
    render() {
        const {visible, userId, hostoryDate, oneUser, visible1, userList, a, oneMounthDetails, id} = this.state
        const now = new Date()
        const nowdate = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
        const now1 = new Date('2200-12')
        const now2 = new Date('2000-12')
        // console.log(hostoryDate,id)
        return (
            <div className='setbox '>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.goBack()}/> 我的挂号
                </div>
                <div className='historicalregistration-outer'>
                    {oneUser ? <>
                        <div className='historicalregistration-cart' onClick={() => {
                            this.setState({
                                visible1: true
                            })
                        }}>
                            {/*<img className='payment-cart-bgc' src={icon3}/>*/}
                            <p className='name'>{oneUser.patientName}</p>
                            <p className='qiehuan-user'>
                                <img src={icon2}/>
                                <span>切换就诊人</span>
                            </p>
                            <p className='name'>{oneUser.idCard1}</p>
                        </div>
                        <div className='history-date'>
                            <img className='bgc' src={icon4}/>
                            <p className='date-top' onClick={() => this.setState({
                                visible: true
                            })}>
                                <span>{hostoryDate ? hostoryDate : nowdate}</span>
                                <img src={icon5}/>
                            </p>
                            <DatePicker
                                visible={visible}
                                onClose={() => {
                                    this.setState({
                                        visible: false
                                    })
                                }}
                                onConfirm={value => {
                                    this.setState({
                                        hostoryDate: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                    }, () => {
                                        let arr=[]
                                        let data=new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                        monthRecordDetails({clinicId:oneUser.id, clinicTime: data}).then(res1 => {
                                            // console.log(res1)
                                            if (res1 && res1.data && res1.data[0]) {
                                                // this.setState({
                                                //     oneMounthDetails: res1.data[0]
                                                // })
                                                res1.data[0].map(item=>{
                                                    if (item.clinicId == id){
                                                        arr.push(item)
                                                    }
                                                })
                                                // console.log(arr)
                                                this.setState({
                                                    oneMounthDetails: arr,
                                                    allMounthDetails:res1.data[0]
                                                })
                                            }
                                        })

                                    })
                                }}
                                // defaultValue={now}
                                precision='month'
                                max={now1}
                                min={now2}
                            >
                            </DatePicker>
                        </div>
                        {oneMounthDetails.length ? oneMounthDetails.map(item => (
                            // <>
                            //     {item.clinicId == id ?
                            <div className='history-body'
                                 onClick={() => this.toHistoryDetails(item.outpatientNumber)}>
                                <p className='history-title'>
                                    <p className='history-title-left'>
                                        <span>{item.name}</span>
                                        <span>{item.docterName}</span>
                                    </p>
                                    {
                                        item.state == 1 ? <p className='history-title-right'>预约成功</p> :
                                            item.state == 2 ? <p className='history-title-right'>已完成</p> :
                                                item.state == 3 ? <p className='history-title-right'
                                                                     style={{color: "#ABABAB"}}>已取消</p> : null}
                                </p>
                                <p className='history-list'><span>就诊科室：</span><span>{item.name}</span></p>
                                <p className='history-list'><span>预约时间：</span><span>{item.clinicTime}</span></p>
                                <p className='history-list'><span>就诊人：</span><span>{item.patientName}</span></p>
                            </div>
                            // : null}
                            // </>
                        )) : <Nodata type='挂号'/>}
                    </> : <Nodata type='挂号'/>}
                </div>
                <Popup
                    visible={visible1}
                    className='popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{height: '40vh'}}
                >
                    <div className='popup-box'>
                        <div className="addbox-top">
                            <span onClick={() => {
                                this.setState({
                                    visible1: false
                                })
                            }}>取消</span>
                            <span onClick={() => this.sureChange()}>确定</span>
                        </div>
                        <div className='box-body'>
                            {userList.length ? userList.map((item, index) => (
                                <div className={index == a ? 'box-register1' : 'box-register'} onClick={() => {
                                    this.setState({
                                        a: index
                                    })
                                }}>
                                    <span>{item.patientName.substring(0, 1) + "*" + item.patientName.substring(2)}</span>
                                    <span>{item.idCard}</span>
                                </div>)) : null}
                        </div>
                    </div>
                </Popup>
            </div>
        )
    }
}
