import axios from "axios";
import {baseUrl} from "../publicurl"
import qs from 'qs';
const handleHeader = {'content-type': 'application/json'}
const handleHeader2 = {'content-type': 'parameters'}
// axios的实例及拦截器配置
const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 60000,  // 超时，401
});

// request拦截器, 改变url 或 options.  请求之前拦截
axiosInstance.interceptors.request.use(config => {
    let arr = config.url.split("&")
    // console.log(localStorage.getItem('jwtTokenh5'));
    if (localStorage.getItem('jwtTokenh5') && arr[0] != "https://pay.sczyzk.com/pay/weixin/h5/config?client_id=7000") {
        config.headers.token = localStorage.getItem('jwtTokenh5')
    }
    return config
});
// response拦截器, 处理response  请求发出后拦截，对状态码code做处理
axiosInstance.interceptors.response.use(
    async result => {
        if (result.data) {
            if (result.data.code == 98) {//过期
                localStorage.removeItem("jwtTokenh5")
                //拿到token并且储存token到localStorage成功
                localStorage.setItem("jwtTokenh5", ' Bearer ' + result.data.token);
                if (result.config.method == 'post') {
                    return Promise.resolve(post(result.config.url, result.config.data ? JSON.parse(result.config.data) : {}))
                }
                if (result.config.method == 'get') {
                    return Promise.resolve(get(result.config.url, result.config.data ? JSON.parse(result.config.data) : {}))
                }
            } else if (result.data.code == -1) {
                // return Promise.reject(result)//失败
                return result//失败
            } else if (result.data.code == 99) {//token过
                // localStorage.clear()
                localStorage.removeItem("jwtTokenh5")
                if (localStorage.getItem("patientPhone1")) {
                    await postLogin('/api/hosp/wzpatient/phone-login', {patientPhone: localStorage.getItem("patientPhone1")})
                    if (result.config.method == 'post') {
                        return Promise.resolve(post(result.config.url, result.config.data ? JSON.parse(result.config.data) : {}))
                    }
                    if (result.config.method == 'get') {
                        return Promise.resolve(get(result.config.url, result.config.data ? JSON.parse(result.config.data) : {}))
                    }
                    // window.location.href = (`/index.html#/drugselection?pharmacyId=${localStorage.getItem("pharmacyId")}&patientPhone=${localStorage.getItem("patientPhone1")}`)
                } else {
                    localStorage.clear()
                    window.location.href = (`/index.html#/login`)
                }

                // this.history.push('/login')

            } else {
                // return Promise.resolve(result.data)//成功
                return result.data
            }
            // return result;
        } else {
            // notification.error({
            //   description: result.mesg || '您的网络发生异常，无法连接服务器',
            //   message: '远程访问异常',
            // });
            return result;
        }
    },
    err => {
        console.log(err, "网络错误");
    }
);

  //手机号验证码登录
export async function postLogin(url, params) {
  // console.log(url, params);
  return await new Promise((resolve, reject) => {
    axiosInstance(url, {
      method: 'post',
      data: params,
      // header: {'content-type' : 'application/json'}

    }).then(res => {
      console.log(res);
      // debugger
      const  { token }=res;
      // console.log(token);
      //储存token到localStorage
      localStorage.setItem("jwtTokenh5",' Bearer '+token);
      
      if (res.code == 1) {
        resolve(res)
      } else {
        reject(res)
      }
    }).catch(err => reject(err));
  });
}
// post方法
export async function post(url, params) {
  return await new Promise((resolve, reject) => {
    axiosInstance(url, {
      method: 'post',
      data: params,
      header: handleHeader

    }).then(res => {
      if (res.code == -1){
        reject(res)//失败
      }else {
        resolve(res)//成功
      }
    }).catch(err => reject(err));
  });
}
// get方法
export async function get(url, params) {
  return await new Promise((resolve, reject) => {
    axiosInstance(url, {
      method: 'get',
      params: params,
      header: handleHeader
    }).then(res => {
      if (res.code == -1) {
        reject(res)
      } else {
        resolve(res)
      }
    }).catch(err => reject(err));
  });
}

export const getBase64 = (url, callback) => {
  let Img = new Image();
  let dataURL = '';
  Img.setAttribute('crossOrigin', 'Anonymous');
  Img.src = url;

  Img.onload = function () {
    let canvas = document.createElement('canvas'),
    width = Img.width,
    height = Img.height;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
    dataURL = canvas.toDataURL('image/png');
    callback(dataURL);
  };
};

// 压缩base64图片
export const getBase64Image=(file,pictureQuality, callback)=>{
  //声明FileReader文件读取对象
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    // 生成canvas画布
    const canvas = document.createElement('canvas');
    // 生成img
    const img = document.createElement('img');
    img.src = reader.result;
    img.onload = () => {
      const ctx = canvas.getContext('2d');
      //原始图片宽度、高度
      let originImageWidth = img.width, originImageHeight = img.height;
      //默认最大尺度的尺寸限制在（1920 * 1080）
      let maxWidth = 1024, maxHeight = 1024, ratio = maxWidth / maxHeight;
      //目标尺寸
      let targetWidth = originImageWidth, targetHeight = originImageHeight;
      //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
      if (originImageWidth > maxWidth || originImageHeight > maxHeight) {
        //超过最大宽高比例
        if ((originImageWidth / originImageHeight) > ratio) {
          //宽度取最大宽度值maxWidth,缩放高度
          targetWidth = maxWidth;
          targetHeight = Math.round(maxWidth * (originImageHeight / originImageWidth));
        } else {
          //高度取最大高度值maxHeight,缩放宽度
          targetHeight = maxHeight;
          targetWidth = Math.round(maxHeight * (originImageWidth / originImageHeight));
        }
      }
      // canvas对图片进行缩放
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      // 清除画布
      ctx.clearRect(0, 0, targetWidth, targetHeight);
      // 绘制图片
      ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
      // quality值越小,图像越模糊,默认图片质量为0.92
      const imageDataURL = canvas.toDataURL(file.type || 'image/jpeg', pictureQuality);
      // 去掉URL的头,并转换为byte
      const imageBytes = window.atob(imageDataURL.split(',')[1]);
      // 处理异常,将ascii码小于0的转换为大于0
      const arrayBuffer = new ArrayBuffer(imageBytes.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < imageBytes.length; i++) {
        uint8Array[i] = imageBytes.charCodeAt(i);
      }
      let mimeType = imageDataURL.split(',')[0].match(/:(.*?);/)[1];
      let newFile = new File([uint8Array], file.name, {type: mimeType || 'image/jpeg'});
      // console.log('after compress, the file size is : ', (newFile.size / 1024 / 1024) + "M");
      // console.log(newFile,newFile.size/ 1024 / 1024)
      callback(newFile);
    };
  };
}
//获取视频配置
export async function getvideoSetting(url, params,token) {
  return await new Promise((resolve, reject) => {
    axiosInstance(url, {
      method: 'get',
      params: params,
      headers: {
        'Auth-Ep-Client': 'Bearer ' + token,
      }
    }).then(res => {
      if (res.code == -1){
        reject(res)//失败
      }else {
        resolve(res)//成功
      }
    }).catch(err => reject(err));
  });
}
export async function imgToCanvas(base64data,filename) {
  // // 创建img元素
  // const img = document.createElement('img')
  // img.setAttribute('src', base64)
  // await new Promise((resolve) => (img.onload = resolve))
  // // 创建canvas DOM元素，并设置其宽高和图片一样
  // const canvas = document.createElement('canvas')
  //
  // canvas.width = img.width
  // canvas.height = img.height
  // // 坐标(0,0) 表示从此处开始绘制，相当于偏移。
  // // canvas.getContext('2d').drawImage(img, 0, 0)
  // // return canvas
  // let image = new Image()
  // // canvas.toDataURL 返回的是一串Base64编码的URL
  // // 指定格式 PNG
  // image.src = canvas.toDataURL(`image/png`)
  //
  // console.log(image)
  // return image
  // const qs = /data:image\/(\w+);base64,(.*)/.exec(base64data) || [];
  // const [, format, bodyData] = /data:image\/(\w+);base64,(.*)/.exec(base64data) || [];
  // console.log(qs)

  // const path = 'xxx/'+ Date.now() +'.png';
  const base64 = base64data.replace(/^data:image\/\w+;base64,/, "");//去掉图片base64码前面部分data:image/png;base64
  // const dataBuffer = new Buffer(base64, 'base64'); //把base64码转成buffer对象，
  // fs.writeFile(path, dataBuffer, function(err){//用fs写入文件
  //   if(err){
  //     console.log(err);
  //   }else{
  //     console.log('写入成功！');
  //   }
  // })
  const arr = base64data.split(',');
  const mime = arr[0].match(/:(.*?);/)[1] || 'image/png';
  // 去掉url的头，并转化为byte
  const bytes = window.atob(arr[1]);
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length);
  // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
  const ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i+=1) {
    ia[i] = bytes.charCodeAt(i);
  }
  // return ia;

  return (new File([ ia ], '123.png', { type: mime }))
  // return qs
}
export const dealParams = (params) => {
  let new_key = Object.keys(params).sort();
  let new_Obj = {}; //创建一个新的对象，用于存放排好序的键值对

  let toSign = '';

  for (let i = 0; i < new_key.length; i++) {//遍历newkey数组
    new_Obj[new_key[i]] = params[new_key[i]];//向新创建的对象中按照排好的顺序依次增加键值对
  }

  Object.keys(new_Obj).forEach((key) => {
    if (new_Obj[key]) {
      toSign = toSign + '&' + new_Obj[key];
    }
  });
  toSign = toSign.slice(1);
  let qs_params = qs.stringify(new_Obj);
  //签名，键值排序后参数
  return { toSign, params: qs_params };
};
// 通过canvas压缩base64图片
export const  dealImage=(base64, callback, w = 80)=> {
  const newImage = new Image();
  const quality = 0.4;    // 压缩系数0-1之间
  newImage.src = base64;
  newImage.setAttribute("crossOrigin", 'Anonymous');    // url为外域时需要
  let imgWidth;
  let imgHeight;
  newImage.onload = function () {
    // @ts-ignore
    imgWidth = this.width;
    // @ts-ignore
    imgHeight = this.height;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")
    if (Math.max(imgWidth, imgHeight) > w) {
      if (imgWidth > imgHeight) {
        canvas.width = w;
        canvas.height = w * imgHeight / imgWidth;
      } else {
        canvas.height = w;
        canvas.width = w * imgWidth / imgHeight;
      }
    } else {
      canvas.width = imgWidth;
      canvas.height = imgHeight;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // @ts-ignore
    ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
    const newBase64 = canvas.toDataURL("image/png", quality);
    callback(newBase64);
  }
}

/**
 * @description base64图片旋转
 * @params params
 */
export const rotateBase64Img = (src, edg, callback)=> {
  var canvas = document.createElement("canvas");
  var ctx = canvas.getContext("2d");
  var imgW;//图片宽度
  var imgH;//图片高度
  var size;//canvas初始大小
  if (edg % 90 != 0) {
    console.error("旋转角度必须是90的倍数!");
    throw '旋转角度必须是90的倍数!';
  }
  (edg < 0) && (edg = (edg % 360) + 360)
  const quadrant = (edg / 90) % 4; //旋转象限
  console.log(quadrant)
  const cutCoor = {sx: 0, sy: 0, ex: 0, ey: 0}; //裁剪坐标
  var image = new Image();
  image.crossOrigin = "anonymous"
  image.src = src;
  console.log(image.src,'335========');
  image.onload = function () {
    imgW = image.width;
    imgH = image.height;
    size = imgW > imgH ? imgW : imgH;
    canvas.width = size * 2;
    canvas.height = size * 2;
    switch (quadrant) {
      case 0:
        cutCoor.sx = size;
        cutCoor.sy = size;
        cutCoor.ex = size + imgW;
        cutCoor.ey = size + imgH;
        break;
      case 1:
        cutCoor.sx = size - imgH;
        cutCoor.sy = size;
        cutCoor.ex = size;
        cutCoor.ey = size + imgW;
        break;
      case 2:
        cutCoor.sx = size - imgW;
        cutCoor.sy = size - imgH;
        cutCoor.ex = size;
        cutCoor.ey = size;
        break;
      case 3:
        cutCoor.sx = size;
        cutCoor.sy = size - imgW;
        cutCoor.ex = size + imgH;
        cutCoor.ey = size + imgW;
        break;
    }
    console.log(cutCoor)
    ctx.translate(size, size);
    ctx.rotate(edg * Math.PI / 180);
    ctx.drawImage(image, 0, 0);
    var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
    if (quadrant % 2 == 0) {
      canvas.width = imgW;
      canvas.height = imgH;
    } else {
      canvas.width = imgH;
      canvas.height = imgW;
    }
    ctx.putImageData(imgData, 0, 0);
    console.log(canvas.toDataURL())
    callback(canvas.toDataURL())
  };
  console.log(image,'384=======')
  // return canvas.toDataURL()
}

export { axiosInstance };

