import React, { Component } from 'react'
import {DatePicker, Popup, Steps, } from "antd-mobile-v5"
import { Tabs,Switch } from 'antd-mobile'
import { Link, withRouter } from 'react-router-dom'
import './style.less'
import getUrl from '../../component/GetUrlParam'
import {allmedsNews,dutyConsultationDetails,selectFiltering,selectSignaturePic} from "../../api/hospitalapi/offline";
import {allMedicinal,prescribingDetail,caWrite,isChineseMeds,chineseMethod,belongStore,chineseMethodDoc,
    selectPharmaIdStaff,
    selectPharmaIdPha,
    selectPrescPhaSign
} from "../../api/hospitalapi/chat";
import {Modal,Toast} from "antd-mobile";
import moment from 'moment'
import Nodata from "../../component/Nodata";
import icon1 from '../../static/img/yy/pt.png'
import Patientnews from "../../component/offline";
const { Step } = Steps
const { alert } = Modal
class Platform extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yslist: [],
            docter: '',
            docterAvatar: localStorage.getItem("dutyAvatar"),
            docterPhone: localStorage.getItem("dutyPhone"),
            docterId: localStorage.getItem("dutyId"),
            status: "",
            list: "",
            medsNews: '',
            dutycode: '',
            supervisionType: '',
            visible: false,
            prescribingId: '',
            list3: [],
            consultationStu:'',
            ischinese:[],
            chinesemethod:[],//用法用量
            staff_img:'',
            pharmacist_img:'',
            pharmacist_img1:'',
            filteringArr:[],
            pharmacyId: localStorage.getItem("pharmacyId"),
            isPhaExamine: false, //是否需要药店药师审核
            phaStaffIdStoreImg:'', //核对签名
            phaStaffIdStore:'',//如果为null,那么就是自动，存在就是手动
        }
    }

    componentDidMount = async () => {
        const {docterId,pharmacyId} = this.state

        // 查询店铺权限
        const res12 = await selectFiltering({pharmacyId:pharmacyId})
        if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
            this.setState({
                filteringArr:res12.data[0]
            })
        }

        if(this.state.filteringArr.some(item => item.label === '药店药师审核' && item.label_type == 4)){
            this.setState({
                isPhaExamine: true
            })
        }


        if (getUrl('consultationId', this.props.location.search)) {
            let res = await dutyConsultationDetails({consultationId: getUrl('consultationId', this.props.location.search)})
            if (res && res.data && res.data[0] && res.data[0].length) {
                this.setState({
                    list: res.data[0][0],
                    consultationStu:getUrl('consultationStu', this.props.location.search)
                })
            }
            this.getAllmeds()
        }
        if (getUrl('prescribingId', this.props.location.search)) {
            this.setState({
                prescribingId: getUrl('prescribingId', this.props.location.search)
            }, () => {
                this.toinfo()
            })
        }
    }
    getAllmeds = async () => {
        const {medsNews} = this.state
        let res = await allmedsNews({consultationId: getUrl('consultationId', this.props.location.search)})
        let a = []

        let res06 = await isChineseMeds({consultation_id: getUrl("consultationId", this.props.location.search)})

        if(res06.data[0].length){
            if (res && res.data && res.data[1] && res.data[1].length) {
                res.data[1].map(item => {
                    a.push(item.med_com_name + " " + item.med_quantity + "g")
                })
                this.setState({
                    medsNews: a,
                    supervisionType: res.data[0][0].supervision_type
                })
            }
        }else{
            if (res && res.data && res.data[1] && res.data[1].length) {
                res.data[1].map(item => {
                    a.push(item.med_com_name + "  " + item.med_spec + '  X' + item.med_quantity)
                })
                this.setState({
                    medsNews: a,
                    supervisionType: res.data[0][0].supervision_type
                })
            }
        }
        
    }
    // 查看详情
    toinfo = async () => {
        const {prescribingId} = this.state
        if (prescribingId!='null'){
            let res = await allMedicinal({prescribingId: getUrl('prescribingId', this.props.location.search)})
            if (res.code == 1 && res.data[0]) {
                let res1 = await prescribingDetail({prescribingId: getUrl('prescribingId', this.props.location.search)})

                let res04 = await isChineseMeds({consultation_id: res1.data[0][0].consultation_id})
                let res05 = await chineseMethodDoc({consultation_id: res1.data[0][0].consultation_id})
                this.setState({
                    ischinese:res04.data[0],
                    chinesemethod:res05.data[0]
                })

                let time1 = new Date(res1.data[0][0].create_time).getTime()
                if (res1 && res1.data && res1.data[0]) {
                    this.setState({
                        list3: res.data[0],
                        detail: res1.data[0][0],
                        originalPrescribing: res1.data[0][0].original_prescribing ? res1.data[0][0].original_prescribing : '',
                        year: res1.data[0][0].year,
                        month: res1.data[0][0].month,
                        day: res1.data[0][0].day,
                    })
                    if (res1.data[2]) {
                        this.setState({
                            docterAutograph: res1.data[2][0].docterAutograph
                        })
                    }
                    if (res1.data[0][0].pharmacist_id) {
                        caWrite({docterId: res1.data[0][0].pharmacist_id}).then(res2 => {
                            if (res2 && res2.data && res2.data[0]) {
                                this.setState({
                                    docterAutograph1: res2.data[0][0].docterAutograph
                                })
                            }
                        })
                    }
                    if(res1.data[0][0].pha_pharmacist_id_store){
                        // 查询药店药师对应
                        selectPrescPhaSign({ 
                            docter_id:res1.data[0][0].pha_pharmacist_id_store
                        }).then((resPhas)=>{
                            if(resPhas && resPhas.data && resPhas.data[0] && resPhas.data[0][0].docterAutograph3){
                                this.setState({
                                    docterAutograph3:resPhas.data[0][0].docterAutograph3
                                })
                            }
                        })
                    }
                }

            }


            let res06 = await belongStore({prescribingId:res.data[0][0].prescribing_id})
            if(res06.data[0][0].pharmacy_id != null){
                const res12 = await selectFiltering({pharmacyId:res06.data[0][0].pharmacy_id})
                if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
                    this.setState({
                        filteringArr:res12.data[0]
                    })
                }

                // 查询处方单店员、药店药师签名图
                let res88 = await selectSignaturePic({prescribingId:res.data[0][0].prescribing_id})

                if(res88.data && res88.data[0] && res88.data[0][0].phaStaffIdStore){
                    this.setState({
                        phaStaffIdStoreImg:res88.data[1][0].phaStaffIdStoreImg,
                        phaStaffIdStore:res88.data[0][0].phaStaffIdStore
                    })
                }else{
                    this.setState({
                        phaStaffIdStoreImg:''
                    })
                }

                if (this.state.filteringArr.some(item => item.label === "核对/发药")) {
                    if(res88.data[0][0].phyStaffImg){
                        this.setState({
                            staff_img:res88.data[0][0].phyStaffImg
                        })
                    }  
                }else{
                    this.setState({
                        staff_img:'',
                    })
                }

                if (this.state.filteringArr.some(item => item.label === "药店药师审核" && item.label_type == 3)) {
                    if(res88.data[0][0].phyPharmacistImg){
                        this.setState({
                            pharmacist_img:res88.data[0][0].phyPharmacistImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img:"",
                    })
                }
                
                if (this.state.filteringArr.some(item => item.label === "调配")) {
                    if(res88.data[0][0].phyPharmacistsImg){
                        this.setState({
                            pharmacist_img1:res88.data[0][0].phyPharmacistsImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img1:"",
                    })
                }

            }  



        }
    }

    render() {
        const {
            list, medsNews, supervisionType, dutycode, visible, detail, docterAutograph1, docterAutograph, list3,docterAutograph3,
            year, month, day,prescribingId,consultationStu,ischinese,chinesemethod,staff_img,
            pharmacist_img,pharmacist_img1,isPhaExamine,phaStaffIdStoreImg,
            phaStaffIdStore
        } = this.state

        return (
            <div className='dutydetail-outer'>
                <div className='dutydetail-patient-news'>
                    <Patientnews news={list}/>
                </div>
                <div className='dutydetail-body'>
                    <p className='dutydetail-title'>
                        <span></span>
                        <span>病情描述：</span>
                    </p>
                    <p className='dutydetail-ill'>{list.consultation_detail}</p>
                    {ischinese.length ? (
                        <>
                            <p className='dutydetail-title'>
                                <span></span>
                                <span>中药需求：</span>
                            </p>
                            <p className='dutydetail-ill-cha'>
                                {medsNews && medsNews.map((i, v) => (<>
                                        <span className='ill-cha-cont'>
                                            {i}；
                                        </span>
                                    </>
                                ))}
                            </p>
                            <p className='dutydetail-title'>
                                <span></span>
                                <span>用法用量：</span>
                            </p>
                            <p className='dutydetail-ill-cha'>
                                {chinesemethod.filter(i => i.consultation_id == list.consultation_id).map(i=>(
                                    <>
                                        <p className='ill-cha-cont'>
                                            共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，每次{i.at_time}{i.unit}，
                                            {i.med_method}，
                                            {i.med_time == 1
                                            ? "饭前用药"
                                            : i.med_time == 2
                                            ? "饭后用药"
                                            : i.med_time == 3
                                            ? "睡前用药"
                                            : i.med_time == 4
                                            ? "晨起用药"
                                            : ""}
                                        </p>  
                                    </>                 
                                ))}
                            </p>
                            
                        </>
                    ) : (
                        <>
                            <p className='dutydetail-title'>
                                <span></span>
                                <span>西药需求： <span
                                    className='supervision-type'>({supervisionType == 1 ? '中药' : supervisionType == 2 ? '西药' : '中成药'})</span></span>
                            </p>
                            {medsNews && medsNews.map((i, v) => (<>
                                    <p className='dutydetail-ill'>
                                        {i}
                                    </p>
                                    {v < medsNews.length - 1 ? <p className='dutydetail-ill-line'></p> : null}
                                </>

                            ))}
                        </>
                    )}
                    
                    {detail? <>
                                <div className='dutydetail-doc-pha'>
                                    <div style={{marginRight:'40px'}}>
                                        <p className='dutydetail-title'>
                                            <span></span>
                                            <span>开方医生：</span>
                                        </p>
                                        <p className='dutydetail-ill1'>{list.docter_name}</p></div>
                                    <>
                                        {detail.pharmacist_name?<div>
                                            <p className='dutydetail-title'>
                                                <span></span>
                                                <span>审方药师：</span>
                                            </p>
                                            <p className='dutydetail-ill1'>{detail.pharmacist_name}</p>
                                        </div>:null}
                                    </>


                                </div>
                                <p className='dutydetail-title'>
                                    <span></span>
                                    {isPhaExamine ? (
                                        <>
                                            <span>问诊状态：{detail.prescribing_state == '0' || (detail.prescribing_state == '1' && detail.prescring_pha_state == 0)  ?
                                                <span className='supervision-type' style={{color: '#F9941C'}}>处理中
                                                { detail.prescribing_state == '0' ? <span style={{ display:'inline-block',backgroundColor:'rgba(255, 141, 48, 0.1)',padding:'2px',marginLeft:'10px'}}>等待平台药师审核</span> : 
                                                detail.prescribing_state == '1' && detail.prescring_pha_state == 0 ? <span style={{ display:'inline-block',backgroundColor:'rgba(255, 141, 48, 0.1)',padding:'2px',marginLeft:'10px'}}>等待药店药师审核</span> : null}
                                                {/* { detail.prescribing_state == '1' && detail.prescring_pha_state == 0 ? <span style={{ display:'inline-block',backgroundColor:'rgba(255, 141, 48, 0.1)',padding:'2px',marginLeft:'10px'}}>等待药店药师审核</span> : null} */}
                                                </span> 
                                                : (detail.prescribing_state == '1' && detail.prescring_pha_state != 2) || (detail.prescribing_state == '1' && detail.prescring_pha_state == 1) ?
                                                    <span className='supervision-type' style={{color: '#05AC73'}}>开方成功</span>
                                                    : (detail.prescribing_state == '-1' || detail.prescring_pha_state == 2) ? <span className='supervision-type'
                                                                                            style={{color: '#FF4E4E'}}>未成功</span> : null}</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>问诊状态：{detail.prescribing_state == '0' ?
                                                <span className='supervision-type' style={{color: '#F9941C'}}>处理中
                                                    { detail.prescribing_state == '0' ? <span style={{ display:'inline-block',backgroundColor:'rgba(255, 141, 48, 0.1)',padding:'2px',marginLeft:'10px'}}>等待平台药师审核</span> : null}
                                                </span>
                                                : detail.prescribing_state == '1' ?
                                                    <span className='supervision-type' style={{color: '#05AC73'}}>开方成功</span>
                                                    : detail.prescribing_state == '-1' ? <span className='supervision-type'
                                                                                            style={{color: '#FF4E4E'}}>未成功</span> : null}</span>
                                        </>
                                    )}
                                </p>
                                {isPhaExamine ? (
                                    <>
                                        {detail.prescribing_state == '-1' ?
                                            <p className='dutydetail-ill' style={{color: '#FF4E4E'}}>驳回原因：{detail.reason}</p> : 
                                            detail.prescring_pha_state == 2 ? <p className='dutydetail-ill' style={{color: '#FF4E4E'}}>驳回原因：{detail.pha_refu_reson}</p> : null}
                                    </>
                                ) : (
                                    <>
                                        {detail.prescribing_state == '-1' ?
                                            <p className='dutydetail-ill' style={{color: '#FF4E4E'}}>驳回原因：{detail.reason}</p> : null}
                                    </>
                                )}
                            </>:
                                list.docter_id?<div className='dutydetail-doc-pha'>
                                    <div style={{marginRight:'40px'}}>
                                        <p className='dutydetail-title'>
                                            <span></span>
                                            <span>开方医生：</span>
                                        </p>
                                        <p className='dutydetail-ill1'>{list.docter_name}</p></div>
                                </div>:null}
                                {consultationStu=='3'?<>
                                        <p className='dutydetail-title'>
                                            <span></span>
                                            <span>问诊状态：
                                                    <span className='supervision-type' style={{color: '#FF4E4E'}}>未成功</span></span>
                                        </p>
                                        {consultationStu == '3' ?
                                            <p className='dutydetail-ill' style={{color: '#FF4E4E'}}>驳回原因：{list.consultation_reason}</p> : null}
                                </> : (consultationStu == '0' && !detail) ? <>
                                        <p className='dutydetail-title'>
                                            <span></span>
                                            <span>问诊状态：
                                                <span className='supervision-type' style={{color: '#F9941C'}}>处理中</span>
                                                <span style={{ display:'inline-block',backgroundColor:'rgba(255, 141, 48, 0.1)',padding:'2px',marginLeft:'10px',color:'#F9941C'}}>等待医生开方</span>
                                            </span>
                                        </p>
                                </> : null}

                                

                                {prescribingId ?
                                    <p className='supervision-button'>
                                        <button onClick={() => this.setState({
                                            visible: true
                                        })}>查看处方
                                        </button>
                                    </p> : null
                                }
                            </div>
                <Popup
                    visible={visible}
                    onMaskClick={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                    bodyStyle={{maxHeight: '100vh'}}
                >
                    <div className='off-pop'>
                        <div className='offline-popup'>
                            <span className='return-right' onClick={this.props.history.goBack}>❮</span>
                            <span className='title-middle'>处方详情</span>

                        </div>
                        {detail ?
                            <div className='off-body'>
                                <div className='off-body-head'> 
                                    <p className='offprescription-jian'>
                                        <p className='jian-tit'>
                                            <h1 className='title'>中江云益健互联网医院</h1>
                                            <h1 className='title'>处方笺</h1>
                                        </p>
                                    </p>
                                    <p className='chufangnumber'>处方编号：{detail.prescribing_number}</p>
                                    <div className='huanzeinfo'>
                                        <p className='p-one'>
                                            <span>姓名：{detail.patient_name}</span>
                                            <span>性别：{detail.patient_sex == '1' ? '男' : '女'}</span>
                                            <span>年龄：{detail.patient_age} 岁</span>
                                        </p>
                                        <p className='p-one'>
                                            <span>科室：{detail.docter_department}</span>
                                            <span>开具日期：{year}年{month}月{day}日</span>
                                        </p>
                                        <p className='p-one'>
                                            <span>诊断：{detail.docter_diagnosis}</span>
                                        </p>
                                    </div>
                                    <p className='rp-words'>RP</p>
                                    {ischinese.length ? (
                                        <>
                                            <div className='yaopin-zhongyao'>
                                                <div className='zy-lists'>
                                                    {list3 ? list3.map((item, index) => (
                                                        <>
                                                            <p className='zy-yaopin-tit'>
                                                                <span>{item.med_com_name ? item.med_com_name : item.medicinal_name} </span>
                                                                <span>{item.medicinal_quantity}g</span>
                                                            </p>                                              
                                                        </>)) : null}   
                                                </div> 
                                            </div>
                                            <div className='zy-yongfa'>
                                                {chinesemethod.filter(i => i.consultation_id == list.consultation_id).map(i=>(
                                                    <>
                                                        <span>
                                                            共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，每次{i.at_time}{i.unit}，
                                                            {i.med_method}，
                                                            {i.med_time == 1
                                                            ? "饭前用药"
                                                            : i.med_time == 2
                                                            ? "饭后用药"
                                                            : i.med_time == 3
                                                            ? "睡前用药"
                                                            : i.med_time == 4
                                                            ? "晨起用药"
                                                            : ""}
                                                        </span>  
                                                    </>                 
                                                ))}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='yaopin-lists'>
                                                {list3 ? list3.map((item, index) => (
                                                    <>
                                                        <p className='yaopin-tit'>
                                                            <span>{index + 1}.{item.med_com_name ? item.med_com_name : item.medicinal_name} </span>
                                                            <span>{item.medicinal_spec}【x{item.medicinal_quantity}{item.packing_unit && item.packing_unit != "null" ? item.packing_unit : ''}】</span>
                                                        </p>
                                                        <p className='yongfa-methods'>用法用量：{item.medicinal_frequency}，每次{item.medicinal_usage}，{item.medicinal_day}天的量， {item.medicinal_method}</p>
                                                        {item.medicinal_supplementary ?
                                                            <p className='yongfa-methods'>备注：{item.medicinal_supplementary}</p> : null}                                                
                                                    </>)) : null}           
                                            </div>
                                        </>
                                    )}
                                    
                                    <p className='kongbailine'>
                                       <hr></hr>
                                       <p>以下空白</p>
                                       <hr></hr>
                                    </p>
                                    <div className='yizhu'>
                                        <span>医嘱：请严格按照原处方和药品说明书使用，严禁超量超范 围使用；如用药过程中出现病情变化或其他不适症状，请立 即停药并及时就医。</span>
                                    </div>
                                    <div className='qianming'>
                                        <div className='qian-one'>
                                            <p className='qian-one-one'>
                                                <span>医师：</span>
                                                <p className='qian-img'><img src={docterAutograph}/></p>
                                            </p>
                                            <p className='qian-one-one'>
                                                <span>医院药师：</span>
                                                <p className='qian-img'>
                                                    {detail.prescribing_state == 1 ?
                                                        <img src={docterAutograph1}/> :
                                                        <span></span>}
                                                </p>
                                            </p>
                                        </div>
                                        <div className='qian-one'>
                                            <p className='qian-one-two'>
                                                <span>药店药师审核：</span>
                                                <p className='qian-img'>
                                                    { detail.prescring_pha_state == 1 && docterAutograph3 ?
                                                        <img src={docterAutograph3}/> :
                                                        detail.prescring_pha_state == 1 && pharmacist_img ?
                                                        <img src={pharmacist_img}/> :
                                                        <span></span>}
                                                </p>
                                            </p>
                                            <p className='qian-one-two'>
                                                <span>调配：</span>
                                                <p className='qian-img'>
                                                    { detail.prescring_pha_state == 1 && docterAutograph3 ?
                                                        <img src={docterAutograph3}/> :
                                                        detail.prescring_pha_state == 1 && pharmacist_img1 ?
                                                        <img src={pharmacist_img1}/> :
                                                        <span></span>}
                                                </p>
                                            </p>                                                                                            
                                        </div>
                                        <div className='qian-one'>
                                            <p className='qian-one-two'>
                                                <span>核对/发药：</span>
                                                <p className='qian-img'>
                                                    {/* {detail.prescring_pha_state == 1 && staff_img ?
                                                        <img src={staff_img}/> :
                                                        <span></span>} */}
                                                    {detail.prescring_pha_state == 1 && phaStaffIdStore && phaStaffIdStoreImg ? <img src={phaStaffIdStoreImg}/> : 
                                                        detail.prescring_pha_state == 1 && staff_img ? <img src={staff_img}/> : <span></span>}
                                                </p>
                                            </p>
                                            <p className='qian-one-two'>
                                                 <span>金额：</span>
                                            </p>                                                                                     
                                        </div>                                                           
                                    </div>
                                    <div className='zhu-bot2'>
                                        <p>1、医师处方仅开具当日内有效(医生注明除外)</p>
                                        <p>2、处方超过7日用量为病情需要。</p>
                                    </div>
                                    <div className='zhu-bot'>
                                        <span>注：本处方当日生效，仅限{detail.pharmacy_name}使用</span>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                    
                </Popup>
            </div>
        )
    }
}

export default withRouter(Platform)