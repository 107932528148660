import React, { Component } from 'react'
import { Tabs, Badge ,Button} from 'antd-mobile-v5'
import icon1 from "../../static/img/yy/ts.png"
import FootNav from '../../component/FootNav'
import "./style.less"
export default class index extends Component {
  render() {
    return (
      <div className='orderbox'>
        <div className='box-top'>
          订单
        </div>
        <Tabs>
          <Tabs.Tab title='全部' key='all'>
            <div className='order-item'>
              <div className='item-name'>
                <img src={icon1} alt="" />
                <div className='item-name-text'>
                  <p className='text-p1'>
                    <span>朱心寒</span>
                    <span>图文</span>
                  </p>
                  <p className='text-p2'>
                    <span>副主任医师</span>
                    <span>呼吸科</span>
                  </p>
                  <div className='text-ab'>
                    已结束
                  </div>
                </div>
              </div>
              <div className='item-content'>
                <div>
                  <p>病情描述：</p> <p>头痛胸闷</p>
                </div>
                <div>
                  <p>就诊人：</p> <p>张三 27岁 男</p>
                </div>
                <div>
                  <p>下单时间：</p> <p>2021.12.08</p>
                </div>
              </div>
              <div className='item-bottom'>
                <p>删除订单</p>
                <div>
                  <Button size='small'>再次咨询</Button><Button style={{width:"82px",marginLeft:"10px"}} color='primary' size='small' fill='outline'>评价</Button>
                </div>
              </div>
            </div>
          </Tabs.Tab>
          <Tabs.Tab title='待支付' key='wait'>
            西红柿
          </Tabs.Tab>
          <Tabs.Tab title='进行中' key='conduct'>
            蚂蚁
          </Tabs.Tab>
          <Tabs.Tab title='待评价' key='evaluate'>
            蚂蚁
          </Tabs.Tab>
        </Tabs>
        <FootNav  onRef={this.onRef}/>
      </div>
    )
  }
}
