import { axiosInstance,postLogin } from '../config'

//获取登录验证码
export const getlocode = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/loginver?forward_topic=oplog-v1', data)
}
//获取注册验证码
export const getrecode = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/registersms', data)
}
//查看账户是否存在
export const inspectonly = (patientPhone) => {
    return axiosInstance.get(`/api/hosp/wzpatient/only?patientPhone=${patientPhone}`)
}
//验证码验证
export const verificationcode = (userPhone,smsCode) => {
    return axiosInstance.get(`/api/hosp/wzpatient/verify?userPhone=${userPhone}&smsCode=${smsCode}`)
}
//注册
export const register = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/smsregister', data)
}
//登录
export const userlogin = (data) => {
    return postLogin('/api/hosp/wzpatient/smslogin', data)
}
//获取用户信息
export const getuserinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/patientinfo', data)
}
// 更新失效验证码状态 
export const updatesxVerify = (userPhone) => {
    return axiosInstance.get(`/api/hosp/wzpatient/updatesx-verify?userPhone=${userPhone}`)
}
// 手机号登录
export const phoneLogin = (data) => {
    return postLogin('/api/hosp/wzpatient/phone-login', data)
}

// 微信扫码跳转登录wxLogin
// export const wxLogin = (data) => {
//     return postLogin('/api/hosp/wzpatient/phone-login', data)
// }