import React from 'react'
import ReactDOM from 'react-dom'
// import { createStore, applyMiddleware, compose } from 'redux'
// import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import 'lib-flexible'

import store from './redux/store'
import AppRouter from './router/AppRouter'
import './static/css/common.less'

const phonestate=window.localStorage.getItem("userId")
if(phonestate){
  window.localStorage.setItem("phonestate",true)
}

const persistor = persistStore(store)

// ReactDOM.render(
//   (<Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <AppRouter />
//     </PersistGate>
//   </Provider>),
// document.getElementById('root')
// )

// 根据域名动态设置标题
const setTitleByDomain = () => {
  const domain = window.location.hostname;
  console.log('默认标题：',domain);

  let title = '互联网医院'; // 默认标题
  if (domain.includes('c-h5')) {
    title = '三类互联网医院';
  } else if (domain.includes('c-line')) {
    title = '二类互联网医院';
  } else if (domain.includes('h5')) {
    title = '三类互联网医院';
  } else if (domain.includes('line')) {
    title = '二类互联网医院';
  }
  console.log('标题',title);
  
  document.title = title;
};

ReactDOM.render(
  (<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppRouter />
    </PersistGate>
  </Provider>),
  document.getElementById('root')
);

// 在渲染完成后设置标题
setTitleByDomain();