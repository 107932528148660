import { axiosInstance } from '../config'

//添加用药人信息
export const adduserinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-patient-member', data)
}
//获取用药人信息
export const getuserinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-patient-member', data)
}

//修改用药人信息
export const changeuserinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-patient-member', data)
}
// 单个医生评价	
export const oneDocpj = (docterId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/one-docpj?docterId=${docterId}`)
}
// 关注医生	
export const gzDoc = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/gz-doc', data)
}
// 查询关注的医生	
export const getGzdoc = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-gzdoc', data)
}
// 删除用药人	
export const delPatientMember = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/del-patient-member', data)
}
//获取医生信息
export const getDoctorinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/get-doctorinfo', data)
}
//获取用药人信息
export const getMemberNews = (memberId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/get-member-news?memberId=${memberId}`)
}

// 用户端添加操作日志
export const addUsersOpersLogs = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-users-logs', data)
}

// 切换默认就诊人	
export const switchDefaultPatient = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/switch-default-patient', data)
}


