import React, {Component} from 'react';
import { Empty } from 'antd-mobile-v5'
import search from "../../../static/img/home/search1.png";
import icon2 from "../../../static/img/icon/false1.png";
import icon6 from "../../../static/img/icon/qm-update.png";
import icon3 from "../../../static/img/icon/qmicon.png";
import icon4 from "../../../static/img/icon/tianjia.png";
import fhhome from "../../../static/img/icon/fhhome.png";
import phastaff from "../../../static/img/phastaff.png"
import getUrl from "../../../component/GetUrlParam";
import { Switch, Popup } from "antd-mobile-v5"
import {ImagePicker, Toast,Popover,Button,Checkbox } from "antd-mobile"
import {uploaddoctor, uploadpic} from "../../../api/hospitalapi/upload"
import {getBase64Image} from '../../../api/config'
import {zipImg} from '../../../component/zipImage'
import {
    updateStaff,
    selectPhaid,
    setStaffsi,
    selectPharmacistId,
    updataPhaCheck,
    updataStaffCheck,
    selectFiltering,//查询店铺所属权限
    selectIsSignature,//查询处方笺是否需要签名
    updateIsSignture,//修改处方笺签名
    updateIsSigntureStore,//修改店员处方笺签名
} from '../../../api/hospitalapi/offline'
import './style.less'
const CheckboxItem = Checkbox.CheckboxItem;
export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            pharmacyId: localStorage.getItem("pharmacyId"),
            staffarr:[],
            qmType:1,//1店员签名  2药店药师签名
            pharmarr:[],
            pha_staff:'',
            pha_pharmacist:'',
            staff_img:'',
            pharmacist_img:'',
            newsstate: 1,
            filteringArr:[],
            checked1:0,//1：开，0：关  药店药师签名
            checked2:0,//1：开，0：关  店员签名
        }
    }

    componentDidMount = async ()=>{

        if (getUrl("newsstate", this.props.location.search)) {
            this.setState({
                newsstate: getUrl("newsstate", this.props.location.search),
            });
        }

        // 查询店铺处方笺是否需要签字
        const res13 = await selectIsSignature({ pharmacyId:localStorage.getItem("pharmacyId")})
        if(res13.code == 1 && res13.data && res13.data[0] && res13.data[0].length){
            this.setState({
                checked1: res13.data[0][0].is_signature_display,
                checked2: res13.data[0][0].is_signature_display_store
            })
        }

        const res12 = await selectFiltering({pharmacyId:localStorage.getItem("pharmacyId")})
        // console.log('店铺权限:',res12.data[0]);
        if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
            this.setState({
                filteringArr:res12.data[0]
            })
        }

        let staffarr = [];
        let res01= await selectPhaid({pharmacy_id:this.state.pharmacyId});
        if(res01.data[0][0]){
            localStorage.setItem('pha_staff', res01.data[0][0].pha_staff);
            localStorage.setItem('staff_img', res01.data[0][0].staff_img);
            this.setState({
                staffarr:res01.data[0]
            })
        }
        

        let pharmarr = [];
        let res02= await selectPharmacistId({pharmacy_id:this.state.pharmacyId});
        if(res02.data[0][0]){
            localStorage.setItem('pha_pharmacist', res02.data[0][0].pha_pharmacist);
            localStorage.setItem('pharmacist_img', res02.data[0][0].pharmacist_img);
            this.setState({
                pharmarr:res02.data[0]
            })
        }
        
    }

    handlePhaAdd= ()=>{
        this.props.history.push(`/setsignnaturedetail?qmType=${2}`)       
    }
    handlePhaUpdate=(id)=>{
        this.props.history.push(`/signaturedetails?qmType=${2}&id=${id}`)
    }


    handleStfAdd= ()=>{
        this.props.history.push(`/setsignnaturedetail?qmType=${1}`)  
    }
    handleStfUpdate=(id)=>{
        this.props.history.push(`/signaturedetails?qmType=${1}&id=${id}`)
    }


    handleRadio = async (id) => {
        const pharmarr = this.state.pharmarr.map((i) => ({
            ...i,
            ischeck: i.id === id ? 1 : 0,
        }));
        // 修改药店药师选中状态
        let res11 = await updataPhaCheck({
            id:id,
            pharmacy_id:this.state.pharmacyId
        })
        this.setState({ pharmarr, selectPhaId: id });        
    };
    handleRadios = async (id) => {
        const staffarr = this.state.staffarr.map((i) => ({
            ...i,
            ischeck: i.id === id ? 1 : 0,
        }));
        // 修改店员选中状态
        let res11 = await updataStaffCheck({
            id:id,
            pharmacy_id:this.state.pharmacyId
        })
        this.setState({staffarr, selectStaffId: id });

    };

    handleReturn = () => {
        this.props.history.push('/shopassistant');
    };
    // 药店药师签名是否显示
    isCheckedDisplayPhy = async() =>{
        const { checked1 } = this.state
        if(checked1 == 0){
            // 修改药房是否需要显示处方笺签名
            const res14 = await updateIsSignture({
                pharmacyId:this.state.pharmacyId,
                isSignatureDisplay: 1,
            })            
            if(res14.code == 1 && res14.data && res14.data[0] && res14.data[0].length){
                this.setState({
                    checked1:1
                })
            }

        }else{
            const res14 = await updateIsSignture({
                pharmacyId:this.state.pharmacyId,
                isSignatureDisplay: 0,
            })
            if(res14.code == 1 && res14.data && res14.data[0] && res14.data[0].length){
                this.setState({
                    checked1:0
                })
            }
        }
        
    }

    // 店员签名是否显示
    isCheckedDisplayStore = async() => {
        const { checked2 } = this.state
        if(checked2 == 0){
            // 修改药房店员是否需要显示处方笺签名
            const res15 = await updateIsSigntureStore({
                pharmacyId:this.state.pharmacyId,
                isSignatureDisplayStore: 1,
            })
            if(res15.code == 1 && res15.data && res15.data[0] && res15.data[0].length){
                this.setState({
                    checked2:1
                })
            }
        }else{
            const res15 = await updateIsSigntureStore({
                pharmacyId:this.state.pharmacyId,
                isSignatureDisplayStore: 0,
            })
            if(res15.code == 1 && res15.data && res15.data[0] && res15.data[0].length){
                this.setState({
                    checked2:0
                })
            }
        }
    }


    render() {
        const {pharmacyId,staffarr,qmType,pharmarr,newsstate,checked1,checked2}= this.state
        let stfArr = [];
        let pharmArr = [];

        let stfArrs = [];
        let pharmArrs = [];

        staffarr.map(i=>{
            stfArr.push(i.pharmacy_id)
        })

        pharmarr.map(i=>{
            pharmArr.push(i.pharmacy_id)
        })

        staffarr.map(i=>{
            stfArrs.push(i.pha_staff)
        })

        pharmarr.map(i=>{
            pharmArrs.push(i.pha_pharmacist)
        })

        // console.log(pharmarr,'87====================');
        // console.log(pharmArrs.length,'8181818181',pharmArrs,pharmArr);

        return (
            <div>
                <div className='home-header-addmed-outer'>
                    <div className='top-tit'>
                        <img className='return-img' src={fhhome} onClick={this.handleReturn} />
                        <span className='title-middle'>签名列表</span>
                    </div>

                    <div className='middle-setsign'>
                        <div className='qm-tit-two'>    
                            <p className={newsstate == 1 ? 'tit-left' : 'tit-left1'}
                            onClick={() => this.setState({newsstate: 1})}>药店药师签名</p>
                            <p className={newsstate == 2 ? 'tit-right' : 'tit-right1'}
                            onClick={() => this.setState({newsstate: 2})}>店员签名</p>
                        </div>

                        {newsstate == 1 && 
                            <div className='pha-menber'>
                                {pharmArrs.length ? (
                                    <>
                                        {/* <div className='no-pha-qm'>
                                            <input type="radio" className='pha-radio' />
                                            <span>暂时无需药店药师签名</span>
                                        </div> */}
                                        {pharmarr.map((i,index)=>(
                                            <>
                                                <div className='phas' key={index}>
                                                    <p className='phas-one'>
                                                        <span>药师姓名：{i.pha_pharmacist}</span>
                                                        <img src={i.pharmacist_img} />
                                                    </p>
                                                    <p className='phas-two'>
                                                        <p className='two-left'>
                                                            <input 
                                                                type="radio" 
                                                                className='pha-radio'
                                                                // value={i.id}
                                                                onChange={() => this.handleRadio(i.id)}
                                                                checked={i.ischeck == 1 || this.state.selectPhaId === i.id}
                                                             />
                                                            <span>默认药师签名</span>
                                                        </p> 
                                                        <p className='pha-xiugai'>
                                                            <img src={icon3} />
                                                            <span 
                                                                // onClick={()=>this.props.history.push(`/signaturedetails?qmType=${2}&id=${i.id}`)}
                                                                onClick={()=>this.handlePhaUpdate(i.id)}
                                                            >
                                                                修改
                                                            </span>
                                                        </p>
                                                    </p>
                                                </div>
                                            </>
                                        )
                                        )}
                                        
                                        <div className='bottom-add-pha-btn'>
                                            <p className='add-btn' onClick={this.handlePhaAdd}>新增药店药师签名</p>
                                            <p className='switch-btn'>
                                                <span>显示处方笺签字</span>
                                                <Switch 
                                                    checked={checked1} 
                                                    onChange={(e) => this.isCheckedDisplayPhy(e)} 
                                                    uncheckedText='关' 
                                                    checkedText='开' />
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img src={phastaff} />
                                        <p className='pha-no'>暂无信息，请新增药店药师签名</p>
                                        <p className='add-pha-btn' 
                                            // onClick={()=>this.props.history.push(`/setsignnaturedetail?qmType=${2}`)}
                                            onClick={this.handlePhaAdd}
                                        >
                                            新增药店药师签名
                                        </p>
                                    </>
                                )}
                            </div>}

                        {newsstate == 2 && 
                            <div className='pha-menber'>
                                {stfArrs.length ? (
                                    <>
                                        {/* <div className='no-pha-qm'>
                                            <input type="radio" className='pha-radio' />
                                            <span>暂时无需店员签名</span>
                                        </div> */}
                                        {staffarr.map((i,index)=>(
                                            <>
                                                <div className='phas'>
                                                    <p className='phas-one'>
                                                        <span>店员姓名：{i.pha_staff}</span>
                                                        <img src={i.staff_img} />
                                                    </p>
                                                    <p className='phas-two'>
                                                        <p className='two-left'>
                                                            <input 
                                                                type="radio" 
                                                                className='pha-radio' 
                                                                onChange={() => this.handleRadios(i.id)}
                                                                checked={i.ischeck == 1 || this.state.selectStaffId === i.id}
                                                             />
                                                            <span>默认店员签名</span>
                                                        </p> 
                                                        <p className='pha-xiugai'>
                                                            <img src={icon3} />
                                                            <span 
                                                                // onClick={()=>this.props.history.push(`/signaturedetails?qmType=${1}&id=${i.id}`)}
                                                                onClick={()=>this.handleStfUpdate(i.id)}
                                                            >修改</span>
                                                        </p>
                                                    </p>
                                                </div>
                                            </>
                                        ))}
                                    
                                        <div className='bottom-add-pha-btn'>
                                            <p className='add-btn' onClick={this.handleStfAdd}>新增店员签名</p>
                                            <p className='switch-btn'>
                                                <span>显示处方笺签字</span>
                                                <Switch 
                                                    checked={checked2} 
                                                    onChange={(e) => this.isCheckedDisplayStore(e)} 
                                                    uncheckedText='关' 
                                                    checkedText='开' />
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <img src={phastaff} />
                                        <p className='pha-no'>暂无信息，请新增店员签名</p>
                                        <p className='add-pha-btn' 
                                            // onClick={()=>this.props.history.push(`/setsignnaturedetail?qmType=${1}`)}
                                            onClick={this.handleStfAdd}
                                        >
                                            新增店员签名
                                        </p>
                                    </>
                                )}
                            </div>} 

                        <div className='kb'></div>                      
                    </div>
                    
                </div>
            </div>
            
        );
    }
}

