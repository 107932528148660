import React, { Component } from 'react'
import "./style.less"
import {Popup,Rate, Tabs, } from "antd-mobile-v5"
import {
    getnewslist,
    refundApply,
    updatestu,
    userDelwz,
    patientStar,
    lookStar,
    orderGetnews, callWenzhen
} from "../../../api/hospitalapi/chat"
import { getdoctorlist } from "../../../api/hospitalapi/home"
import { Modal,Toast } from 'antd-mobile';
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/touxiang.png"
import Nodata from '../../../component/Nodata'
import { zfUrl,code } from "../../../publicurl"
import getUrl from "../../../component/GetUrlParam";
import {axiosInstance} from "../../../api/config";
import {jumpUrl} from "../../../publicurl"
import moment from 'moment'
const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
          patientId: localStorage.getItem("patientId"),
          newslist: [],
          visible1:false,
          item1:'',
          star:'',
          commentValue:'',
          starValue:'',
            list1:'',
            list2:'',
            list3:'',
            list4:'',
            list:[{value:'回复及时',key:1},{value:'非常热心',key:1},{value:'解答很有帮助',key:1},{value:'服务专业',key:1}]
        }
      }
    
    componentDidMount = async () => {
        const { patientId,timer } = this.state
        if (!patientId) {
          Toast.show("当前未登录，请先登录")
          this.props.history.push("/login")
        }else{
          this.wzNews()
        }
    }
    wzNews=async()=>{
      const { patientId } = this.state
      const res = await getnewslist({
        userId:patientId
      })
 
     if (res&&res.data[0].length) {
        var temp = [];
        var temp1 = [];
        var temp2 = [];
        var temp3 = [];
        var temp4 = [];
        res.data[0].map(item=>{
            if (item.user_delete==1) {
                if (item.consultation_stu=='-2'){
                    temp1.push(item)
                }else if (item.consultation_stu=='0'||item.consultation_stu=='-1'){
                    temp2.push(item)
                }else if (item.consultation_stu=='1'){
                    temp3.push(item)
                }else if (item.consultation_stu=='3'||item.consultation_stu=='4'){
                    temp4.push(item)
                }
                temp.push(item)
            }
        })

        this.setState({
            newslist: temp,
            list1:temp1,
            list2:temp2,
            list3:temp3,
            list4:temp4,
        })
      }
    }
    gobank=()=>{
        this.props.history.push('/mine')
    }
    delOrder=(e,consultationId)=>{
        e.stopPropagation()

        alert('确认删除此条问诊记录吗?','删除问诊记录', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '确定', onPress: () => {
                userDelwz({consultationId}).then(res=>{
            
                    if (res.code==1) {
                        Toast.show("删除成功")
                        this.wzNews()
                    }
                })
            } },
        ],)
    }
    again=(e,docterId,docterSate)=>{
        e.stopPropagation()
        if(docterSate==1){
            localStorage.setItem("docterId",docterId)
            getdoctorlist({docterId}).then(res2=>{
         
                if (res2.code==1) {
                    localStorage.setItem("item",JSON.stringify(res2.data[0][0]))
                    this.props.history.push(`/doctorinfo?record=${1}`)
                }
            })
        }else{
            Toast.show("该医生已下架，无法再次咨询")
        }
    }
    goinfo(e,news){

        e.stopPropagation()
        if(news.docter_state==1){
            localStorage.setItem("item",JSON.stringify(news))
            localStorage.setItem("consultationId",news.consultation_id)
            if(news.consultation_type=='1'){

                this.props.history.replace(`/mine/record`)
                this.props.history.push(`/doctorchat?consultationId=${news.consultation_id}&docterId=${news.docter_id}&record=${1}`)
            }else if(news.consultation_type=='3'){
                orderGetnews({consultationNumber:news.consultation_number}).then(res=>{
      
                    if (res&&res.data&&res.data[0]) {
                        // let params1={
                        //     //评价页面URL地址
                        //     evaluateUrl:encodeURIComponent(`https://h5.yyj-health.com/index.html#/evaluate?consultationNumber=${news.consultation_number}&docterPhone=${res.data[1][0].docter_phone}&consultationId=${news.consultation_id}&consultationStu=${res.data[0][0].consultation_stu}`),
                        //     //拒绝回调URL地址
                        //     rejectCallback:encodeURIComponent(`https://h5.yyj-health.com/index.html#/refusaldiagnosis?consultationNumber=${news.consultation_number}&consultationId=${news.consultation_id}&consultationAmount=${res.data[0][0].consultation_amount}`),
                        // }
                        let params1 = {
                            //评价页面URL地址
                            evaluateUrl: jumpUrl+`/index.html#/evaluate?consultationNumber=${news.consultation_number}&docterPhone=${res.data[1][0].docter_phone}&consultationId=${news.consultation_id}&consultationStu=${res.data[0][0].consultation_stu}`,
                            //拒绝回调URL地址
                            rejectCallback: jumpUrl+`/index.html#/refusaldiagnosis?consultationNumber=${news.consultation_number}&consultationId=${news.consultation_id}&consultationAmount=${res.data[0][0].consultation_amount}`,
                        }
                        let params2 = JSON.stringify(params1)
                        let params = {
                            "code": 'FRMXT',
                            "phone": news.patient_phone,
                            "url": jumpUrl+`/index.html#/doctorinfo?consultationNumber=${news.consultation_number}`,
                            "callback": `https://gateway.yyj-health.com/api/hosp/video-doc/video-stu?consultationNumber=${news.consultation_number}`,
                            "doctor": res.data[1][0].docter_phone,
                            "extra": {
                                //评价页面URL地址
                                "evaluateUrl": jumpUrl+`/index.html#/evaluate?consultationNumber=${news.consultation_number}&docterPhone=${res.data[1][0].docter_phone}&consultationId=${news.consultation_id}`,
                                //拒绝回调URL地址
                                "rejectCallback": jumpUrl+`/index.html#/refusaldiagnosis?consultationNumber=${news.consultation_number}&consultationId=${news.consultation_id}&consultationAmount=${res.data[0][0].consultation_amount}`,
                            }
                        }
                  
                        // let res=await callWenzhen(params)
                        
                        new Promise((resolve, reject) => {
                            // axiosInstance(`https://endpoint.pre.cecdat.com/ep/third-party/diagnose/access`, {
                            // axiosInstance(`https://ep-endpoint.cecdat.com/ep/third-party/diagnose/access`, {
                                axiosInstance(`https://egcare-endpoint.yyj-health.com/sec/third-party/video/setting`, {    
                                method: 'post',
                                data: params,
                                // headers: {
                                //     'content-type': 'application/json',
                                //     'Auth-Ep-Client': 'Bearer ' + res9.data.token,
                                // }

                            }).then(res => {
                    
                                if (res && res.body) {
                                    window.location.href = res.body.redirectUrl
                                }
                            })
                        })

                        // callWenzhen().then(res9 => {
                            
                        //     if (res9 && res9.data) {
                        //         console.log(res9.data.token)
                        //         2024年3月12日
                        //     }
                        // })


                    }
                })
            }
        }else{
            Toast.show("该医生已下架，无法继续咨询")
        }
        
        // this.props.history.push({
        // pathname:"/doctorchat",
        // params:{
        //     news,
        //     docterId:news.docter_id,
        //     record:1
        // }
        // })
    }
    //申请退款
    torefund=async (e,news)=>{
        e.stopPropagation()
        alert('确认申请退款吗?','确认退款', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '确定', onPress: async () => {
                const res = await updatestu({
                    consultationStu: 5,
                    consultationId:news.consultation_id,
                })
  
                if (res && res.code == 1) {
                    let params = {
                        client_id: code,
                        client_trade_no: news.consultation_number,
                        total_fee: news.consultation_amount,
                        timestamp: moment().format('YYYYMMDDHHmmss'),
                    }
                    refundApply(params).then(res1 => {
             
                        if (res1 && res1.code == 1) {
                            this.wzNews()
                            Toast.info('退款成功')
                        }
                    })
                }
            } },
        ],)

    }
    // 评价星级
    starCount =(v) => {
        this.setState({
            star: v
        })
    }
    // 评价内容
    comment = (v) => {
        this.setState({
            commentValue:v.target.value.replace(/\s*/g,"")
        })        
    }
    //快捷评价
    addList=(i,v)=>{
        const {list} = this.state
        list.map((m,n)=>{
            if (n==v){
                if (i.key==1){
                    m.key=2
                }else{
                    m.key=1
                }
            }
        })
        this.setState({
            list
        })
    }
    // 提交评价
    totalk = () => {
        const {item1,commentValue,star,list}=this.state
        let a=[]
        list.map((i,v)=>{
            if (i.key==2){
                a.push(i.value)
            }
        })
        if (commentValue&&star&&parseInt(star)) {
            let params={
                userId:item1.user_id,
                docterId: item1.docter_id,
                consultationId:item1.consultation_id,
                docEvaluateText:commentValue,
                docEvaluateQuick:a.length?a.toString():'',
                docEvaluateStar:star
            }
            patientStar(params).then(res=>{
                if (res.code==1) {
                    lookStar({
                        userId:item1.user_id,
                        docterId: item1.docter_id,
                        consultationId:item1.consultation_id,
                    }).then(res1=>{
                        this.wzNews()
                        if (res1.data[0].length) {
                            this.setState({
                                stateStar:1,
                                starValue:res1.data[0][0]
                            })
                        }
                    })
                }
            })
            this.setState({
                visible1: false
            })
        }else{
            Toast.info('请填写评论和星级')
        }
    }
    //取消订单
    quxiao=async (e,consultationId)=>{
        e.stopPropagation()
        alert('确认取消问诊吗？', '', [
            { text: '取消', onPress: () => console.log('cancel') },
            {
                text: '确认', onPress: () => {
                    updatestu({consultationStu:'4',consultationId}).then(res=>{
                        if(res&&res.code==1){
                            this.wzNews()
                        }
                    })
                }
            },
        ])

    }
    //立即支付
    toPay=(e,item)=>{
        e.stopPropagation()
        let b=(new Date()).getTime()
        let orderName='问诊支付'
        window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${item.consultation_number}&total_fee=${item.consultation_amount}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
    }
    toInchat=(item)=>{
        if (item.consultation_type=='1'){
            if (item.consultation_stu!='-2'){
                if (item.consultation_stu!='4'){
                    localStorage.setItem("item",JSON.stringify(item))
                    localStorage.setItem("consultationId",item.consultation_id)
                    this.props.history.replace(`/mine/record`)
                    this.props.history.push(`/doctorchat?consultationId=${item.consultation_id}&docterId=${item.docter_id}&record=${1}`)
                }else{
                    Toast.show('订单已取消无法进入问诊界面')
                }
            }else{
                Toast.show('请先支付')
            }
        }
    }
    render() {
        const {item1,commentValue,star,list,newslist,visible1,list1,list2,list3,list4}=this.state
        return (
            <div className='recordbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>问诊记录</h1>
                </div>
                <div className='box-content'>
                    <Tabs>
                        <Tabs.Tab title='全部' key='all'>
                            {newslist.length?newslist.map(item=>(<div className='content-item' onClick={()=>this.toInchat(item)}>
                                <div className='content-top'>
                                    <img src={item.docter_avatar} alt="" />
                                    <p className='content-news'>
                                        <p>
                                            <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                            <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                        </p>
                                        <p>
                                            <span>{item.docter_title}</span>
                                             <span style={{marginLeft:'10px'}}>{item.docter_department}</span>
                                        </p>
                                    </p>
                                    <p className='content-over'>{item.consultation_stu=='1'?'已结束':item.consultation_stu=='0'?'进行中':item.consultation_stu=='-1'?'待问诊':
                                        item.consultation_stu=='-2'?'待支付':item.consultation_stu=='5'?'已退款':item.consultation_stu=='4'||item.consultation_stu=='3'?'已取消':null}</p>
                                </div>
                                <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patientName} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                <div className='content-bottom'>
                                    {item.consultation_stu!='4'&&item.consultation_stu!='1'&&item.consultation_stu!='3'?<p></p>:
                                        <p className='content-delete' onClick={(e)=>this.delOrder(e,item.consultation_id)}>删除订单</p>}
                                    {item.consultation_stu=='1'?
                                    <>
                                        {item.doc_evaluate_star?
                                        <p className='content-right'>
                                            <span></span>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id,item.docter_state)}>再次咨询</span>
                                        </p>
                                        :<p className='content-right'>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id,item.docter_state)}>再次咨询</span>
                                            <span className='pingjia' onClick={() => this.setState({
                                                visible1: true,
                                                item1:item
                                            })}>评价</span>
                                        </p>}
                                    </>
                                    :
                                    item.consultation_stu=='-1'?<p className='content-right'>
                                        {item.consultation_type=='1'?<span></span>:<span className='agin' onClick={(e)=>this.goinfo(e,item)}>回到咨询</span>}
                                        {item.consultation_type=='1'?<span className='agin' onClick={(e)=>this.goinfo(e,item)}>回到咨询</span>:
                                            item.consultation_type=='3'?<span className='agin' onClick={(e)=>this.torefund(e,item)}>申请退款</span>
                                                :null}
                                        </p>:
                                        item.consultation_stu=='0'?<p className='content-right'>
                                        <span></span>
                                        {item.consultation_type=='1'?<span className='agin' onClick={(e)=>this.goinfo(e,item)}>回到咨询</span>
                                                :null}
                                </p>:item.consultation_stu=='-2'?<p className='content-right'>
                                        <span className='pingjia' style={{color:'#353A42'}} onClick={(e)=>this.quxiao(e,item.consultation_id)}>取消</span>
                                        <span className='agin' style={{color:'#1F87FF',borderColor:"#1F87FF"}} onClick={(e)=>this.toPay(e,item)}>立即支付</span>
                                    </p>:item.consultation_stu=='4'||item.consultation_stu=='3'?<p className='content-right'>
                                        <span></span>
                                        <span className='agin' style={{color:'#1F87FF',borderColor:"#1F87FF"}} onClick={(e)=>this.again(e,item.docter_id,item.docter_state)}>重新咨询</span>
                                    </p>:null}
                                </div>
                            </div>)):<Nodata type='问诊'/>}
                        </Tabs.Tab>
                        <Tabs.Tab title='待支付' key='no'>
                            {list1.length?list1.map(item=>(
                                <div  onClick={(e)=>this.toInchat(e,item)}>
                                    {item.consultation_stu=='-2'?<div className='content-item'>
                                        <div className='content-top'>
                                            <img src={item.docter_avatar} alt="" />
                                            <p className='content-news'>
                                                <p>
                                                    <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                                    <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                                </p>
                                                <p>
                                                    <span>{item.docter_title}</span>
                                                    <span style={{marginLeft:'10px'}}>{item.docter_department}</span>
                                                </p>
                                            </p>
                                            <p className='content-over'>待支付</p>
                                        </div>
                                        <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                        <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patientName} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                        <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                        <div className='content-bottom'>
                                            <p></p>
                                            {/*<p className='content-delete' onClick={()=>this.delOrder(item.consultation_id)}>删除订单</p>*/}
                                            <p className='content-right'>
                                                <span className='pingjia' style={{color:'#353A42'}} onClick={(e)=>this.quxiao(e,item.consultation_id)}>取消</span>
                                                <span className='agin' style={{color:'#1F87FF',borderColor:"#1F87FF"}} onClick={(e)=>this.toPay(e,item)}>立即支付</span>
                                            </p>
                                        </div>
                                    </div>:null}
                                </div>)):<Nodata type='问诊'/>}
                        </Tabs.Tab>
                        <Tabs.Tab title='进行中' key='pic'>
                            {list2.length?list2.map(item=>(
                            <div  onClick={()=>this.toInchat(item)}>
                                {item.consultation_stu=='0'?<div className='content-item'>
                                    <div className='content-top'>
                                        <img src={item.docter_avatar} alt="" />
                                        <p className='content-news'>
                                            <p>
                                                <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                                <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                            </p>
                                            <p>
                                                <span>{item.docter_title}</span>
                                                <span style={{marginLeft:'10px'}}>{item.docter_department}</span>
                                            </p>
                                        </p>
                                        <p className='content-over'>{item.consultation_stu=='1'?'已结束':item.consultation_stu=='0'?'进行中':null}</p>
                                    </div>
                                    <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                    <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patientName} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                    <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                    <div className='content-bottom'>
                                        <p></p>
                                        {/*<p className='content-delete' onClick={()=>this.delOrder(item.consultation_id)}>删除订单</p>*/}
                                        <p className='content-right'>
                                            <span></span>
                                            {item.consultation_type=='1'?<span className='agin' onClick={(e)=>this.goinfo(e,item)}>回到咨询</span>:null}
                                        </p>
                                    </div>
                                </div>:null}
                            </div>)):<Nodata type='问诊'/>}
                        </Tabs.Tab>
                        <Tabs.Tab title='已完成' key='phone'>
                            {list3.length?list3.map(item=>(
                            <div  onClick={()=>this.toInchat(item)}>
                                {item.consultation_stu=='1'?<div className='content-item'>
                                    <div className='content-top'>
                                        <img src={item.docter_avatar} alt="" />
                                        <p className='content-news'>
                                            <p>
                                                <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                                <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                            </p>
                                            <p>
                                                <span>{item.docter_title}</span>
                                                <span style={{marginLeft:'10px'}}>{item.docter_department}</span>
                                            </p>
                                        </p>
                                        <p className='content-over'>{item.consultation_stu=='1'?'已结束':item.consultation_stu=='0'?'进行中':item.consultation_stu=='-1'?'进行中':null}</p>
                                    </div>
                                    <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                    <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patientName} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                    <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                    <div className='content-bottom'>
                                        {/*<p></p>*/}
                                        <p className='content-delete' onClick={(e)=>this.delOrder(e,item.consultation_id)}>删除订单</p>
                                        {item.doc_evaluate_star?
                                        <p className='content-right'>
                                            <span></span>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id,item.docter_state)}>再次咨询</span>
                                        </p>
                                        :<p className='content-right'>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id,item.docter_state)}>再次咨询</span>
                                            <span className='pingjia' onClick={() => this.setState({
                                                visible1: true,
                                                item1:item
                                            })}>评价</span>
                                        </p>}
                                    </div>
                                </div>:null}
                            </div>)):<Nodata type='问诊'/>}
                        </Tabs.Tab>
                        <Tabs.Tab title='已取消' key='quxiao'>
                            {list4.length?list4.map(item=>(
                                <div  onClick={()=>this.toInchat(item)}>
                                    {item.consultation_stu=='4'||item.consultation_stu=='3'?<div className='content-item'>
                                        <div className='content-top'>
                                            <img src={item.docter_avatar} alt="" />
                                            <p className='content-news'>
                                                <p>
                                                    <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                                    <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                                </p>
                                                <p>
                                                    <span>{item.docter_title}</span>
                                                    <span style={{marginLeft:'10px'}}>{item.docter_department}</span>
                                                </p>
                                            </p>
                                            <p className='content-over'>已取消</p>
                                        </div>
                                        <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                        <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patientName} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                        <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                        <div className='content-bottom'>
                                            <p className='content-delete' onClick={(e)=>this.delOrder(e,item.consultation_id)}>删除订单</p>
                                                <p className='content-right'>
                                                    <span></span>
                                                    <span className='agin' onClick={(e)=>this.again(e,item.docter_id,item.docter_state)}>重新咨询</span>
                                                </p>
                                        </div>
                                    </div>:null}
                                </div>)):<Nodata type='问诊'/>}
                        </Tabs.Tab>
                    </Tabs>
                </div>
                <Popup
                    visible={visible1}
                    className='popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{ minHeight: '40vh' }}
                >
                    <div className='popup-box'>
                        <div className='box-top'>
                            <img src={item1?item1.docter_avatar:icon2} alt="" />
                            <div>
                                <h1>评价医生本次服务</h1>
                                <p>
                                    <span>{item1?item1.docter_name:''}</span><span>{item1?item1.docter_title:''}</span><span>{item1?item1.docter_department:''}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='pingfen'>
                        <Rate
                            style={{
                                '--active-color': '#1F87FF'
                            }}
                            onChange={val => this.starCount(val.toString())} />
                    </div>
                    <div className='pop-tips'>
                        {list.map((i,v)=>(
                            <span className={i.key==1?'before':'end'} onClick={()=>this.addList(i,v)}>{i.value}</span>))}
                    </div>
                    <div className='popup-text'>
                        <textarea name="" id="" rows="5" placeholder='谈谈你对本次就医医生的评价吧~'
                        onChange={val => this.comment(val)}>

                        </textarea>

                    </div>
                    <div className='popup-btn'>
                        <button onClick={this.totalk}>提交评论</button>
                    </div>

                </Popup>
            </div>
        )
    }
}
