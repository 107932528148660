import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import "./queryreport.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/head.png"
import {getAll} from '../../../api/hospitalapi/electronic'
/*
*  挂号
* */

export default class queryreport extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:[]
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        getAll({patientId:userId}).then(res=>{
            // console.log(res)
            if (res&&res.data&&res.data[0].length){
                this.setState({
                    list:res.data[0]
                })
            }
        })
    }
    showNews=()=>{
        this.props.history.push("/healthcardnews")
    }
    toHealthcard=()=>{
        this.props.history.replace(`/queryreport`)
        this.props.history.push(`/healthcard2?queryreport=${1}`)
    }
    render() {
        const { list} = this.state
        return (
            <div className='setbox '>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")} />   报告查询
                </div>
                <div className='look-report'>您要查询的报告</div>
                {list.length?list.map(item=>(<div className='user-name' onClick={() => this.props.history.push(`/queryreportnews?id=${item.id}`)}>
                    <img src={icon3}/>
                    <p>{item.patientName}</p>
                </div>)):null}

                <div className='add-jiankangka' onClick={() => this.toHealthcard()}>
                    添加就诊人电子健康卡
                </div>
            </div>
        )
    }
}
