import React, { Component } from 'react'

import { Empty,Radio,Space,Selector,FloatingBubble  } from 'antd-mobile-v5'
import search from "../../../../static/img/home/search1.png";
import icon2 from "../../../../static/img/icon/false1.png";
import fhhome from "../../../../static/img/icon/fhhome.png";
import { ImagePicker, Toast, Popover, Button, Picker, List } from "antd-mobile"
import { uploaddoctor, uploadpic } from "../../../../api/hospitalapi/upload"
import { getBase64Image } from '../../../../api/config'
import { zipImg } from '../../../../component/zipImage'
import { addChineseDrugs,addWesternDrugstoRetail } from '../../../../api/hospitalapi/drugs';
import MedicineSearch from '../medicine-search'

import '../style.less'
/**
 * 中药添加页面
 */
export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            proName: "",// 中药名称：pro_name
            standards: 1, // 质量标准：standards   pro_approval  1：《中国药典》 2：《全国中药炮制规范》 3：地方《中药炮制规范》
            packingUnit: 1,// 计量单位：packing_unit   //规格(包装) 1：袋 2：盒 3：瓶 4：罐
            proCompany: "",// 生产厂家：pro_company
            placeOrigin: "",// 产地：place_origin
            proStorage: "",// 存储：pro_storage
            proSpec:'',//药品规格：pro_spec
            pharmacyId: localStorage.getItem("pharmacyId"), //店铺id
            medschange: 1,  //1:西/中药添加   2:中药材添加
            pharmacyName: localStorage.getItem("pharmacyName"),

            initialValues:{
                proName: "",// 中药名称：pro_name
                standards: 1, // 质量标准：standards   pro_approval
                packingUnit: 1,// 计量单位：packing_unit
                proCompany: "",// 生产厂家：pro_company
                placeOrigin: "",// 产地：place_origin
                proStorage: "",// 存储：pro_storage
                proSpec: "",//药品规格
            }
        }
    }

    /**
    * 接收用户输入的药品信息
    * 改变状态数据
    * @param {any} value  
    */
    setStateData = (even, stateName) => {
        // debugger

        if (even == null || stateName == null) {
            return
        }


        let stateData = even.target.value

        stateData = stateData.replace(/\s/g, '');


        this.setState({
            [stateName]: stateData
        })

    }

    /**
     * 包装单位选择
     */
    handleSelectChange = (e) => {
        let { packingUnit } = this.state
        this.setState({
            packingUnit:e[0]
        })
    }

    /**
     * 质量标准选择
     */
    handleStandards = (e) => {
        let { standards } = this.state
        this.setState({
            standards:e[0]
        })
    }

    /**
         * 添加中药
         */
    handleSubmit = async () => {
        const {
            proName,// 中药名称：pro_name
            standards,// 质量标准：standards
            packingUnit,// 计量单位：packing_unit
            proCompany,// 生产厂家：pro_company
            placeOrigin,// 产地：place_origin
            proStorage,// 存储：pro_storage
            proSpec,//药品规格
            pharmacyId,
            initialValues,
            pharmacyName

        } = this.state

        let newPackingUnit = packingUnit == 1 ? '袋' : packingUnit == 2 ? '盒' : packingUnit==3 ? '瓶' : packingUnit==4 ? '罐' : '盒'

        let newStandards = standards == 1 ? '《中国药典》' : standards == 2 ? '《全国中药炮制规范》' : standards == 3 ? '地方《中药炮制规范》' : '《中国药典》'

        let params = {
            // pro_name: proName,
            // standards: standards,
            // packing_unit: newPackingUnit,
            // pro_company: proCompany,
            // place_origin: placeOrigin,   //产地
            // pro_storage: proStorage,  //存储
            // pharmacy_id: pharmacyId,


            pro_com_name: proName,    //药品通用名
            category_id: 9,                //一级分类id
            category_name: '中医药馆',              //一级分类名称
            classify_id: 56,                //二级分类ID
            classify_name: '饮片',              //二级分类名称
            classifyson_id: null,             //三级分类ID
            classifyson_name: null,           //三级分类id
            pro_spec: proSpec,          //药品规格
            origin_price: 0,  //建议零售价
            pro_approval: newStandards,  //批准文号
            pro_company: proCompany,    //生产厂家
            unit: newPackingUnit,                 //计量单位
            packing_unit: newPackingUnit ,  //单位
            supervision_type: 1, //药品类别
            pro_qrcode: null,      //药品一维码
            pharmacy_id: pharmacyId,                //店铺ID

            pro_name: proName,
            pro_price: 0,
            pro_packing: null,
            pro_type: '中药',
            pro_dosage_form: null,
            pro_apply_symptoms: null,
            pro_classify: '0',
            create_user: pharmacyName,
            place_origin: placeOrigin,   //产地
            pro_storage: proStorage,  //存储

        }

        for (let key in params) {

            let str = params[key]
            if (("place_origin" != key) && ("pro_storage" != key)) {
                if (!str) {
                    if(key == 'pro_name'){
                        Toast.fail("中药材名称不能为空")
                        return;
                    } else if(key == 'pro_company'){
                        Toast.fail("生产厂家不能为空")
                        return;
                    }
                }
            }


        }


        let res = await addWesternDrugstoRetail(params)
        // console.log(res);
        if (res.code == 1 && res.data.data[0][0].total != 0) {
            Toast.info("您添加的药品已存在，可以正常开方")
        }
        if (res.code == 1 && res.data.data[0][0].total === 0) {
            Toast.success("添加药品成功，可在添加记录查看或者点击申请开方可搜索该药品问诊")
            setTimeout(() => {
                this.setState({
                  ...this.state.initialValues
                });
                this.props.history.replace('/traditionalm') // 重新加载页面
                window.location.reload();
            }, 200); 
        }

        // let res = await addChineseDrugs(params)
        // console.log("添加药品", res);
        // if (res.code == 1 && res.data[0][0].total !=0) {
        //     Toast.info("您添加的药品已存在，可以正常开方")
        // }
        // if (res.code == 1 && res.data[0][0].total === 0) {
        //     Toast.success("添加药品成功，可在添加记录查看或者点击申请开方可搜索该药品问诊")
        //     setTimeout(() => {
        //         this.setState({
        //           ...this.state.initialValues
        //         });
        //         this.props.history.push('/traditionalm') // 重新加载页面
        //         window.location.reload();
        //     }, 200); 
        // }


    }

    render() {
        let { medschange,proName,standards,packingUnit,proCompany,placeOrigin,proStorage,proSpec } = this.state
        return (
            <div>
                {/* 中药添加*/}
                <div className='home-header-addmed-outer11'>
                    <div className='top-tit'>
                        <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                        <span className='title-middle'>添加药品</span>
                        <div 
                            className='add-cord' 
                            onClick={() => this.props.history.push(`/ShowUsMedicine?records=${2}`)}
                        >
                            添加记录
                        </div>
                    </div>
                    <div className='home-header-addmed'>
                        <div className='home-header-addmed-inner'>
                        </div>
                        <div className="top-nav">
                            <div
                                // className={medschange == 1 ? "top-nav-left1" : "top-nav-left"} 
                                className="top-nav-left"
                                onClick={
                                    () => this.props.history.push('/westernm')

                                }>西/中成药添加</div>
                            <div
                                // className={medschange == 2 ? "top-nav-right" : "top-nav-right1"} 
                                className="top-nav-right"
                                onClick={
                                    // () => this.props.history.replace('/traditionalm') 
                                    () => { this.setState({ medschange: 2 }); this.props.history.replace('/traditionalm'); }

                                }>中药饮片添加</div>
                        </div>
                    </div>


                    {/* 中药名称 */}
                    <p className='top-titles'>
                        <span>*</span>
                        <span>中药饮片名称：</span>
                    </p>


                    <p className='top-input'>
                        <input 
                            placeholder='请输入商品通用名' 
                            defaultValue={proName} 
                            onChange={(e) => this.setStateData(e, "proName")} 
                            autoCorrect="off"
                            autoComplete="off"
                            autoCapitalize="none"
                        />
                    </p>

                    {/* 药品规格 */}
                    <p className="top-title">
                        <span>*</span>
                        <span>药品规格：</span>
                    </p>
                    <p className="top-input">
                        <input
                        placeholder="请输入药品规格（例：500g）"
                        defaultValue={proSpec}
                        onChange={(e) => this.setStateData(e, "proSpec")}
                        />
                    </p>

                    {/* 包装 */}
                    <p className='top-title'>
                        <span>*</span>
                        <span>包装：</span>
                    </p>
                    <p className='packgeUnitBox'>
                        <Selector
                            style={{
                                '--border-radius': '100px',
                                '--checked-text-color': '#FFFFFF',
                                '--checked-color': '#1989FA',
                                '--checked-border': 'solid transparent 1px',
                                '--padding': '5px 24px',
                                '--color':'transparent',
                                '--text-color':'#434343',
                                '--border':'solid #DCDEE0 1px'
                            }}
                            showCheckMark={false}
                            columns={4}
                            options={[
                                {
                                    label: '袋',
                                    value: 1,
                                },
                                {
                                    label: '盒',
                                    value: 2,
                                },
                                {
                                    label: '瓶',
                                    value: 3,
                                },
                                {
                                    label: '罐',
                                    value: 4,
                                },
                            ]}
                            defaultValue={[packingUnit]}
                            onChange={(e) => {this.handleSelectChange(e)}}
                        />
                    </p>

                    {/* 生产厂家： */}
                    <p className='top-title'>
                        <span>*</span>
                        <span>生产厂家：</span>
                    </p>
                    <p className='top-input'>
                        <input placeholder='请输入生产厂家' defaultValue={proCompany} onChange={(e) => this.setStateData(e, "proCompany")} />
                    </p>
                    

                    {/* 质量标准： */}
                    <p className='top-title'>
                        <span>*</span>
                        <span>质量标准：</span>
                    </p>
                    <p className='packgeUnitBox'>
                        <Selector
                            style={{
                                '--border-radius': '100px',
                                '--checked-text-color': '#FFFFFF',
                                '--checked-color': '#1989FA',
                                '--checked-border': 'solid transparent 1px',
                                '--padding': '5px 8px',
                                '--color':'transparent',
                                '--text-color':'#434343',
                                '--border':'solid #DCDEE0 1px'
                            }}
                            showCheckMark={false}
                            columns={2}
                            options={[
                                {
                                    label: '《中国药典》',
                                    value: 1,
                                },
                                {
                                    label: '《全国中药炮制规范》',
                                    value: 2,
                                },
                                {
                                    label: '地方《中药炮制规范》',
                                    value: 3,
                                },
                            ]}
                            defaultValue={[standards]}
                            onChange={(e) => {this.handleStandards(e)}}
                        />
                    </p>

                    {/* 产地： */}
                    <p className='top-title'>
                        {/* <span>*</span> */}
                        <span>产地：</span>
                    </p>
                    <p className='top-input'>
                        <input placeholder='请输入产地' defaultValue={placeOrigin} onChange={(e) => this.setStateData(e, "placeOrigin")} />
                    </p>

                    {/* 存储： */}
                    <p className='top-title'>
                        {/* <span>*</span> */}
                        <span>存储：</span>
                    </p>
                    <p className='top-input'>
                        <input placeholder='请输入存储方式' defaultValue={proStorage} onChange={(e) => this.setStateData(e, "proStorage")} />
                    </p>

                    {/* 计量单位： */}
                    {/* <p className='top-title'>
                        <span>*</span>
                        <span>计量单位：</span>
                    </p>
                    <p className='top-input'>
                        <input placeholder='请输入计量单位' defaultValue={packingUnit} onChange={(e) => this.setStateData(e, "packingUnit")} />
                    </p> */}

                    <p className='add-save-tips'>提示：新增商品不可修改，请认真核对信息后确认保存药品</p>
                    <p className='add-save-button'>
                        <button onClick={() => this.handleSubmit()}>保存药品</button>
                    </p>
                </div>

                {/* 悬浮窗-申请问诊 */}
                {/* <p className='sqwz-icon1'
                    onClick={()=>{
                        this.props.history.push(`/drugselection`)
                    }}
                >
                    <span>申请问诊</span>
                </p> */}
                <div onClick={()=>{
                        this.props.history.push(`/drugselection`)
                    }}
                >
                    <FloatingBubble
                        axis='xy'
                        magnetic='x'
                        style={{
                        '--initial-position-bottom': '15px',
                        '--initial-position-right': '15px',
                        '--edge-distance': '15px',
                        '--background': '#FF9239',
                        '--size': '48px'
                        }}
                    >
                        <p>申请<br/>问诊</p>
                    </FloatingBubble>
                </div>
            </div>

        )
    }
}