import React, { Component } from 'react'
import "./style.less"
import {Popup,Rate, Tabs,Toast } from "antd-mobile-v5"
import { getnewslist,userdelWznews,updatestu,userDelwz,patientStar,lookStar } from "../../../api/hospitalapi/chat"
import { oderlistRequest } from "../../../api/hospitalapi/order"
import { Modal } from 'antd-mobile';
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/touxiang.png"
import Nodata from '../../../component/Nodata'
import { zfUrl,code } from "../../../publicurl"
import Order from "./order";
const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientId: localStorage.getItem("patientId"),
            newslist: [],
            visible1:false,
            item1:'',
            star:'',
            commentValue:'',
            starValue:'',
            list1:'',
            list2:'',
            list3:'',
            list4:'',
        }
    }

    componentDidMount = async () => {
        const { patientId,timer } = this.state
        if (!patientId) {
            Toast.show({
                content:"当前未登录，请先登录",
                duration:1000
            })
            this.props.history.push("/login")
        }else{
        }
    }
    allList=async ()=>{
        let params={

        }
        let res=oderlistRequest(params)
        // console.log(res)
    }
    gobank=()=>{
        this.props.history.push("/mine")
    }
    render() {
        const {item1,commentValue,star,newslist,visible1,list1,list2,list3,list4}=this.state
        return (
            <div className='recordbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>购药记录</h1>
                </div>
                <div className='box-content'>
                    <Order list1={list1} list2={list2} list3={list3} list4={list4} newslist={newslist}></Order>
                </div>
            </div>
        )
    }
}
