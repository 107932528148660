import { axiosInstance } from '../config'

/**
 * 新增西药药品
 * @param {JSON} data 
 * @returns 
 */
export const addWesternDrugs = (data) => {
    return axiosInstance.post('/api/hosp/drug-manager/insert-drug', data)
}
/**
 * 新增中药
 * @param {} data 
 * @returns 
 */
export const addChineseDrugs = (data) => {
    return axiosInstance.post('/api/hosp/drug-manager/insert-chinese-drug', data)
}

/**
 * 药品添加记录查询中药

 * @returns 
 */
export const getChineseDrugs = (pharmacyId) => {
    // return axiosInstance.get(`/api/hosp/drug-manager/get-chinese-drug?pharmacy_id=${pharmacyId}`)
    return axiosInstance.get(`/api/hosp/drug-manager/get-drug-zh?pharmacy_id=${pharmacyId}`)
}

/**
 * 药品添加记录查询西药
 * @returns 
 */
export const getWesternDrugs = (pharmacyId) => {
    return axiosInstance.get(`/api/hosp/drug-manager/get-drug?pharmacy_id=${pharmacyId}`)
}

/**
 * 药品检索
 * @returns 
 */
export const searchDrugs = (keyword,page,size) => {
    return axiosInstance.get(`/api/hosp/drug-manager/search-drug?keyword=${keyword}&page=${page}&size=${size}`)
}


/**
 * 新增西药药品至零售平台
 * @param {JSON} params 
 * @returns 
 */
export const addWesternDrugstoRetail = (params) => {
    return axiosInstance.post('/faas/hosp/drug-manager/insert-drug-retail', params)
}


// 
export const getPlatformClassification= (params) => {
    return axiosInstance.get('/api/hosp/third-pharmacies/get-platform-classification', params)
}