import React, { Component } from "react";
import moment from 'moment';
// import vConsole from '../../../src/api/vconsole'
import "./style.less";
import { Popup, Input, Button, TextArea } from "antd-mobile-v5";
import icon1 from "../../static/img/icon/ybtc.png";
import { Toast, Modal, Checkbox } from "antd-mobile";
import getUrl from "../../component/GetUrlParam";
import {zipImg} from '../../component/zipImage'
import { wxConfig } from "../../component/scan/index";
import {uploaddoctor, uploadpic} from "../../api/hospitalapi/upload"
import {
  defaultMember,
  getAllills,
  getHighmeds,
  getCollectmedicinal,
  allClassifyid,
  isHightmed,
  getSpiritmeds,//精神类
  getInjectionmeds,//注射剂药品
  getHighRiskmeds,//新高危药品
  getMedsills,//药品对应的所有疾病
  disDepartment,//查询疾病对应的科室
  drugsUsage,//患者查询药品用法用量
  isExistCus,
} from "../../api/hospitalapi/meds";
import {
  addAllmeds,
  saveIcd,
  submitOrdernews,
  subChaifen,
  sumbitLogs
} from "../../api/hospitalapi/order";
import { newState, getnewslist } from "../../api/hospitalapi/chat";
import {
  allOfflinedoc,
  isSelfphamacy,
  allNowdayorders,
  getOrderallways,//提交问诊前查询药品要用信息
  prescriptionValidation,
  selectDoctor,
  selectFiltering,//查询店铺所属权限
  selectPharmacyAear,
  selectPharmacyName,
  todayBuyMed,
  todayBuyName,
  changeDefaultMember
} from "../../api/hospitalapi/offline";
import HeaderNav from "../../component/HeaderNav";
import { Link } from "react-router-dom";
import jia2 from "../../static/img/jia2.png";
import fhicon1 from "../../static/img/fhicon1.png";
import touxiang from "../../static/img/home/touxiang.png";
import awaitDoc from "../../static/img/await-doc.gif";
import noDoc from "../../static/img/nodoc.png";
import { addchat } from "../../api/hospitalapi/chat";
import defaultImage from "../../static/img/defaultImage.jpg";
import "../../static/fonts/font_3834206_omr9d7z9z9/iconfont.css";
import { code05, zfUrl } from "../../publicurl";
import { gzDoc } from "../../api/hospitalapi/user";
import search from "../../static/img/home/search1.png";
import zysc from "../../static/img/zysc.png";
import gou from "../../static/img/gou.png";
import pingzhen from "../../static/img/pingzhen.png";
import icon2 from "../../static/img/close1.png";
import icon3 from "../../static/img/contact-complaint.png";
import Nodata from "../../component/Nodata";
import { videoSetting } from "../../api/hospitalapi/upload";
const CheckboxItem = Checkbox.CheckboxItem;
const alert = Modal.alert;
let isClick = false
let isClickA = false
export default class index extends Component {
  constructor() {
    super();
    this.state = {
      patientId: localStorage.getItem("patientId1"),
      pharmacyId: localStorage.getItem("pharmacyId"),
      patientPhone: localStorage.getItem("patientPhone1"),
      supervisionType: localStorage.getItem("supervisionType"),
      selectmeds:
        localStorage.getItem("selectmeds") &&
        localStorage.getItem("selectmeds") != "undefined"
          ? JSON.parse(localStorage.getItem("selectmeds"))
          : [],
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      miaosu: localStorage.getItem("miaosu")
        ? localStorage.getItem("miaosu")
        : "", //病情描述
      allmiaosu: "", //病情描述
      selectcode: 0, //是否勾选风险告知书 1是  0否
      isuse: 0, //是否服用过 1是 0否
      isadverse: 0, //是否有不良反应 1有 0无
      defultUser: "",
      awaitdoc: 1, //2等待医生接诊中
      allDocId: "",
      time: 300,
      consultationId: "",
      docterId: "",
      index: 0,
      timer: "",
      // list: [{value: '感冒症状', index: 1}, {value: '心血管疾病', index: 1}, {value: '高血压症状', index: 1}, {
      //     value: '肠胃疾病',
      //     index: 1
      // }, {value: '消化系统疾病', index: 1},
      //     {value: '呼吸系统类', index: 1}, {value: '妇科用药', index: 1},],
      listchose: [],
      list: [],
      icdlists: [],

      tips: 0,
      videocode: localStorage.getItem("videocode") ? localStorage.getItem("videocode") : 0,
      // coordinationcode: 2, //1、医保统筹 2、个人支付
      coordinationcode: localStorage.getItem('visitType') ? localStorage.getItem('visitType') : 2, //1、医保统筹 2、个人支付
      selectdoctor: 1, //1、快速问诊 2、选择医生
      entitycode: 0, //1、复诊 2、首诊
      storeagain: 0, //1、店员重新问诊
      consultationDetail: "",

      spiritMeds: [], //精神类药品，无法提交
      spiritMedslength: "", //精神类药品数量
      spiritMedsArray: [], //精神类药品

      injectionMeds:[],//注射剂药品
      injectionMedsArray: [], //注射剂药品
      injectionMedslength: "", //注射剂药品数量

      highriskMeds:[],//新高危药品
      highriskMedsArray: [], //新高危药品
      highriskMedslength: "", //新高危药品数量

      highMeds: [], //AB级高危药品，无法提交
      highMedsC: [], //c级高危药品，可以提交
      specialMedicine: [], //特殊药品
      highMedslength: "", //高危药品数量

      highMedsArray: [],
      allbeginMeds: [],
      allMeds: [],
      allotherMeds: [],
      onewsMeds: [],
      isnonews: 0, //是否有没维护的商品，0，没有，1有
      icdList: [],
      index0: "",
      icdname: "",
      reviewercode: false, //是否已阅读
      visible6: false,
      visible5: false,
      visible7: false,
      scrollTop: 0,
      docterName: "",
      selectdoctormiaosu: "",
      falseSex: [],
      filteringArr:[],
      arrindex1:[],
      visiblecontype: false,//问诊提醒弹窗
      patientsProvince:'',//就诊人所在省
      patientsCities:'',//就诊人所在市
      patientsCounty:'',//就诊人所在区
      pharmacyNameAll:'',
      isExcessArr:[],//超量数组
      visibleIsExcess:false, //超量提醒弹窗
      sureBtn:true,
      sureBtnCount:0,
      splitBtn:1, //1:需要拆，0，不需要拆
      isExessSplit: 0, // 0：能提交 开关关，1：不能提交：开关开

      medIcdCustomArr:[], //存放用户选择的自定义疾病
      imageUrl:localStorage.getItem("imageUrl") || '',
      isUploaded: localStorage.getItem("isUploaded") || false, // 标识图片是否已上传
      showPreview: false,
      scrolledToBottom:false,//同意书是否滑动到底部
      timeNotice: 10,//同意告知书倒计时
      isRunningTime: false, // 倒计时状态
      noticeBtnState: true, //我已知晓 按钮状态:初始是禁用状态true,能用之后变为false （需要倒计时结束并且用户滑到底部才能变为false）
      filterNotice: false,//告知书权限是否开启，true开启，false未开启,
      isReadNotice:false,//未阅读告知书
      isSetUseday:1,//是否设置用药天数，1：默认(未设置)，2:自定义
      setUseday:'',//设置的天数
    };
    this.useRef = React.createRef();
    this.inputRef = React.createRef();
    this.noticeBoxRef = React.createRef();
  }

  componentDidMount = async () => {
    const { patientId, selectmeds, defultUser } = this.state;

    // console.log(selectmeds,'selectmeds药品清单=='); 

    // 查询店铺名称pharmacy_name
    const res20 = await selectPharmacyName({pharmacyId:localStorage.getItem("pharmacyId")})
    if(res20.code == 1 && res20.data && res20.data[0] && res20.data[0].length){
      this.setState({
        pharmacyNameAll:res20.data[0][0].pharmacy_name,
        isSetUseday:res20.data[0][0].is_set_useday,
        setUseday:parseInt(res20.data[0][0].set_useday),
      })
    }

    // 查询店铺所属区域
    const res16 = await selectPharmacyAear({pharmacyId:localStorage.getItem("pharmacyId")})
    if(res16.code == 1 && res16.data && res16.data[0] && res16.data[0].length){
      this.setState({
        patientsProvince:res16.data[0][0].province,
        patientsCities:res16.data[0][0].city,
        patientsCounty:res16.data[0][0].district
      })
    }
    const res12 = await selectFiltering({pharmacyId:localStorage.getItem("pharmacyId")})
    // console.log(res12.data[0],'res12.data[0]');
    if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
      this.setState({
        filteringArr:res12.data[0]
      })
    }

    if(this.state.filteringArr.some(item => item.label === "超量拦截")){
      // 超量拦截  不存在：表示 开关 关  能提交  存在：开关打开，不能提交
      this.setState({
        isExessSplit: 1
      });
    }


    if (!this.state.filteringArr.some(item => item.label === "拆分开关")) {
      this.setState({
        splitBtn: 0
      });
    }
    if (this.state.filteringArr.some(item => item.label === "复诊/是")) {
      this.setState({
        entitycode: 1
      });
    }
    if (this.state.filteringArr.some(item => item.label === "告知书")) {
      this.setState({
        selectcode: 1,
        filterNotice:true,
        isReadNotice:true
      });
    }else{
      this.setState({
        filterNotice:false
      })
    }
    if (!this.state.filteringArr.some(item => item.label === "问诊方式/图文")) {
      this.setState({
        videocode: 3
      });
    }
    
    if (!this.state.filteringArr.some(item => item.label === "药物过敏")) {
      this.setState({
        isadverse: 2,
        isuse:2
      });
    }

    if (getUrl("illmiaosu", this.props.location.search)) {
      this.setState({
        miaosu: getUrl("illmiaosu", this.props.location.search),
      });
    }
    if (getUrl("storeagain", this.props.location.search)) {
      this.setState({
        storeagain: 1,
        coordinationcode: getUrl(
          "medicalNsurancecoordination",
          this.props.location.search
        ),
        videocode:
          getUrl("consultationType", this.props.location.search) == 1 ? 0 : 1,
      });
    }
    if (getUrl("medicalNsurancecoordination", this.props.location.search)) {
      this.setState({
        coordinationcode: getUrl(
          "medicalNsurancecoordination",
          this.props.location.search
        ),
      });
    }
    if (getUrl("coordinationcode", this.props.location.search)) {
      this.setState({
        coordinationcode: getUrl(
          "coordinationcode",
          this.props.location.search
        ),
      });
    }
    if (getUrl("docterId", this.props.location.search)) {
      this.setState({
        docterId: getUrl("docterId", this.props.location.search),
        docterName: getUrl("docterName", this.props.location.search),
        selectdoctor: 2,
        selectdoctormiaosu: localStorage.getItem("selectdoctormiaosu"),
      });
    }
    if (getUrl("entitycode", this.props.location.search)) {
      this.setState({
        reviewercode:
          getUrl("entitycode", this.props.location.search) == 1 ? true : false,
        entitycode: getUrl("entitycode", this.props.location.search),
      });
    }
    let arr = [];
    let arr1 = [];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        // params.name = i.medComName;
        params.name = i.medComName ? i.medComName : i.medName;
        params.spec = i.medSpec;
        // params.spec = i.medSpec.replace(/\s+/g, '');
        arr.push(params);
      });
    }

    // 精神类药品
    let spiritarr = [];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        params.name = i.medName;
        params.spec = i.medSpec;
        spiritarr.push(params);
      });
    }

    // 注射剂药品
    let injectionarr = [];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        params.name = i.medName;
        params.spec = i.medSpec;
        injectionarr.push(params);
      });
    }

    // 新高危药品查询
    let highriskarr =[];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        params.name = i.medName;
        params.spec = i.medSpec;
        highriskarr.push(params);
      });
    }

    //高危药品查询、特殊药品
    let res01 = await getHighmeds({ allNews: arr });
    if (res01 && res01.data && res01.data.length) {
      this.setState({
        highMeds: res01.data[1],
        highMedsC: res01.data[0],
        specialMedicine: res01.data[2],
        highMedsArray: [...res01.data[0], ...res01.data[1], ...res01.data[2]],
        highMedslength:
          res01.data[1].length + res01.data[0].length + res01.data[2].length,
      });
    }

    // 精神类药品查询
    let res02 = await getSpiritmeds({ allNews: spiritarr });
    if (res02 && res02.data && res02.data.length) {
      this.setState({
        spiritMeds: res02.data[0],
        spiritMedsArray: [...res02.data[0]],
        spiritMedslength: res02.data[0].length,
      });
    }

    // 注射剂药品查询
    let res03=await getInjectionmeds({ allNews: injectionarr });
    if (res03 && res03.data && res03.data.length) {
      this.setState({
        injectionMeds: res03.data[0],
        injectionMedsArray: [...res03.data[0]],
        injectionMedslength: res03.data[0].length,
      });
    }

    // 新高危药品查询
    let res04=await getHighRiskmeds({ allNews: highriskarr });
    if (res04 && res04.data && res04.data.length) {
      this.setState({
        highriskMeds: res04.data[0],
        highriskMedsArray: [...res04.data[0]],
        highriskMedslength: res04.data[0].length,
      });
    }

    if (patientId) {
      let res = await defaultMember(patientId);
      if (res && res.data && res.data[0] && res.data[0].length) {
        let item = res.data[0][0];
        item.age = 1;
        let b = item.patient_birthday;
        let a =
          new Date(b).getFullYear() +
          "-" +
          (new Date(b).getMonth() + 1 < 10
            ? "0" + (new Date(b).getMonth() + 1)
            : new Date(b).getMonth() + 1) +
          "-" +
          (new Date(b).getDate() < 10
            ? "0" + new Date(b).getDate()
            : new Date(b).getDate());
        // 将出生日期的字符串通过"-"分割成数组
        const strBirthdayArr = a.split("-");
        // 拿到出生日期的年
        const birthYear = strBirthdayArr[0];
        // 拿到出生日期的月
        const birthMonth = strBirthdayArr[1];
        // 拿到出生日期的日
        const birthDay = strBirthdayArr[2];
        // 创建一个时间对象
        const d = new Date();
        // 拿到当前时间的年
        const nowYear = d.getFullYear();
        // 拿到当前时间的月
        const nowMonth = d.getMonth() + 1;
        // 拿到当前时间的日
        const nowDay = d.getDate();
        // 如果出生日期的年等于当前时间的年
        if (nowYear === birthYear) {
          item.age = 1;
        } else {
          // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
          const ageDiff = nowYear - birthYear; // 年之差
          // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
          // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
          // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
          if (nowMonth != birthMonth) {
            // 拿到出生日期的日与当前时间的月之差
            const monthDiff = nowMonth - birthMonth; // 月之差
            // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
            item.age = monthDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          } else {
            // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
            const dayDiff = nowDay - birthDay;
            // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
            item.age = dayDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          }
          if (item.age == 0) {
            item.age = 1;
          }
        }
        if (item.age < 0) {
          Toast.show("0岁以下儿童暂时无法添加");
        } else {
          this.setState(
            {
              defultUser: res.data[0][0],
              // coordinationcode:res.data[0][0].patient_id_card?1:2
            },
            () => {
              this.getallIlls();
            }
          );
        }

      }
    }
  };

  // 同意告知书倒计时
  startCountdown = () => {
    this.setState({ isRunningTime: true }); // 设置倒计时状态为正在进行
    this.interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timeNotice === 1) {
          clearInterval(this.interval); // 清除定时器
          return { isRunningTime: false, timeNotice: 0 }; // 倒计时结束，设置为 0
        }
        return { timeNotice: prevState.timeNotice - 1 }; // 减少倒计时时间
        // if (prevState.timeNotice <= 1) {
        //   clearInterval(this.interval); // 清除定时器
        //   return { isRunningTime: false }; // 倒计时结束
        // }
        // return { timeNotice: prevState.timeNotice - 1 }; // 减少倒计时时间
      });
    }, 1000); // 每秒更新一次
  };

  // componentWillUnmount() {
  //   clearInterval(this.interval); // 清除定时器
  // }

  // 以前旧的
  getallIlls = async () => {
    const { patientId, selectmeds, defultUser } = this.state;
    let arr = [];
    let arr1 = [];
    let falseSex = [];

    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        params.name = i.medComName ? i.medComName.replace(/\s+/g, '') : i.medName.replace(/\s+/g, '');
        params.spec = i.medSpec.replace(/\s+/g, '');
        params.classifyId = i.classifyId;
        arr.push(params);
      });
    }

    //查询药品对应疾病
    let res0 = await getAllills({
      allNews: arr,
      patientage:defultUser ? defultUser.age : 20,
      sex: defultUser ? (defultUser.patient_sex == "男" ? 1 : 2) : 3,
    });

    let againarr = [];
    let nArr1 = [];
    let againindex = [];
    let medsname = [];
    let othermedsname = [];
    let nomedsname = [];
    let allindex = [];
    var subset2 = [];
    let larr = [];
    let arr0 = [];


    if (res0.data && res0.data.length) {
      res0.data.forEach((i) => {
        allindex.push(i.index0);
      });
      // currentValue当前正在被处理的元素
      // currentIndex当前正在被处理的元素在数组中的索引
      // selfArr正在被处理的数组本身
      allindex = allindex.filter((currentValue, currentIndex, selfArr) => {
        return selfArr.findIndex((x) => x === currentValue) === currentIndex;
      });

      // 如果存在icd疾病的药品小于用户提交的药品数组
      if (allindex.length < arr.length) {
        let arr2 = [];
        if (arr.length == 1) {
          arr2 = [0];
        } else if (arr.length == 2) {
          arr2 = [0, 1];
        } else if (arr.length == 3) {
          arr2 = [0, 1, 2];
        } else if (arr.length == 4) {
          arr2 = [0, 1, 2, 3];
        } else if (arr.length == 5) {
          arr2 = [0, 1, 2, 3, 4];
        }
        nArr1 = againarr.filter((currentValue, currentIndex, selfArr) => {
          return (
            selfArr.findIndex((x) => x.name === currentValue.name) ===
            currentIndex
          );
        });

        var set2 = new Set(allindex); //包含allindex数组中元素集合Set
        var set1 = new Set(arr2);  //包含arr2数组中元素集合Set

        var subset = [];

        for (let item of set1) {
          if (!set2.has(item)) {
            subset.push(item);  //如果set1的元素不在set2中，则添加到subset
          }
          if (set2.has(item)) {
            subset2.push(item);  // 如果set1的元素在set2中，则添加到subset2
          }
        }

        arr.forEach((item, v) => {
          subset.forEach((i) => {
            if (v == i) {
              nomedsname.push(item.name); //找到不存在疾病的药品
            }
          });
        });

        arr.forEach((item, v) => {
          subset2.forEach((i) => {
            if (v == i) {
              larr.push(item);
            }
          });
        });

        // 将未维护且名字相同的合并在一起显示
        nomedsname = nomedsname.filter(
          (currentValue, currentIndex, selfArr) => {
            return (
              selfArr.findIndex((x) => x === currentValue) === currentIndex
            );
          }
        );

      }

      if (arr.length > 0 && res0.data.length) {
        // for (let i = 0; i < res0.data.length; i++) {
        //   for (let j = 1; j < res0.data.length; j++) {
        //     if ( res0.data[i].icd_id == res0.data[j].icd_id && i != j && res0.data[i].index0 != res0.data[j].index0 ) {
        //       res0.data[j].state = res0.data[i].state;
        //       againarr.push(res0.data[j]);
        //     }
        //   }
        // }
        againarr.map((item) => {
          item.data = 1;
          againindex.push(item.index0);
          againindex.push(item.state);
        });

        let nArr2 = againindex.filter((currentValue, currentIndex, selfArr) => {
          return selfArr.findIndex((x) => x === currentValue) === currentIndex;
        });

        if (allindex.length == arr.length) {
          arr.forEach((i, v) => {
            othermedsname.push({ name: i.name, index: v });
          });
          nArr1=res0.data
        } else {
          if (subset2.length) {
            arr.forEach((item, v) => {
              subset2.forEach((i) => {
                if (v == i) {
                  othermedsname.push({ name: item.name, index: v });
                }
              });
            });

            nArr1=res0.data
          }
        }

      } 
      this.setState({
        allbeginMeds: medsname,   //维护的药品
        allotherMeds: othermedsname,//维护的药品 ----最终渲染的维护药品
        isnonews: nomedsname.length ? nomedsname.length : 0,  //是否维护了
        onewsMeds: nomedsname,//没维护的药品   ----最终渲染的未维护药品
        allMeds: res0.data,  //药品对应的疾病
      });

      let arrayill = [];
      nArr1.forEach((i) => {
        let params1 = {};
        params1.index = 1;
        if (getUrl("consultationDetail", this.props.location.search)) {
          arrayill = getUrl(
            "consultationDetail",
            this.props.location.search
          ).split(",");
          arrayill.forEach((j) => {
            if (j == i.name) {
              params1.index = 2;
            }
          });
        }
        params1.value = i.name;
        let id0 = {};
        id0.icdName = JSON.parse(i.icd_id)[0].icd_name;
        id0.icdValue = JSON.parse(i.icd_id)[0].icd_value;
        params1.id = id0;
        params1.index0 = i.index0;
        params1.data = i.data ? 1 : 0;
        params1.dis_id = i.dis_id;
        params1.sex_flag = i.sex_flag;
        params1.is_acute = i.is_acute;
        arr1.push(params1);
      });

      let code0 = 1;
      if (localStorage.getItem("listicd")) {
        if (
          JSON.parse(localStorage.getItem("listicd")).length == nArr1.length
        ) {
          arr1.forEach((i) => {
            JSON.parse(localStorage.getItem("listicd")).forEach((j) => {
              if (i.icdName != j.icdName) {
                code0 = 2;
              }
            });
          });
        } else {
          code0 = 2;
        }
      } else if (getUrl("consultationDetail", this.props.location.search)) {
        code0 = 1;
        localStorage.setItem("listicd", JSON.stringify(arr1));
      } else {
        code0 = 2;
      }

      this.setState(
        {
          list:
            code0 == 2 || getUrl("storeagain", this.props.location.search)
              ? arr1
              : localStorage.getItem("listicd") &&
                JSON.parse(localStorage.getItem("listicd")),
        },
        () => {
          if (code0 == 1) {
            let arr2 = [];
            let arr3 = [];
            let list0 = localStorage.getItem("listicd")
              ? JSON.parse(localStorage.getItem("listicd"))
              : arr1;
            list0.map((item) => {
              if (item.index == 2) {
                arr2.push(item.value);
                arr3.push(item.id);
              }
            });
            let nArr10 = arr3.filter((currentValue, currentIndex, selfArr) => {
              return (
                selfArr.findIndex(
                  (x) => x.icdValue === currentValue.icdValue
                ) === currentIndex
              );
            });
            this.setState({ listchose: arr2, icdlists: nArr10 });
          }
        }
      );

    } else {
      arr.forEach((item, v) => {
        nomedsname.push(item.name);
      });
      this.setState({
        isnonews: nomedsname.length ? nomedsname.length : 0,  //是否存在没有维护的商品 0：有，1：没有
        onewsMeds: nomedsname,   //没有维护的药品
      });
    }

    // 查询药品对应的所有疾病
    let res10 = await getMedsills({
      allNews: arr,
      patientage:defultUser ? defultUser.age : 20,
      // sex: defultUser ? (defultUser.patient_sex == "男" ? 1 : 2) : 3,
    });

    let notFoundItems = selectmeds.filter((item, n) => {
      return !res10.data.some(elem => elem.index0 === n);
    }); 

    if(notFoundItems){
      this.setState({
        arrindex1:notFoundItems
      })
    }
  
    
    arr = arr.map((item,n) => {
      if (!res10.data.some(elem => elem.index0 === n)) {
        return { ...item, index2: 1 };
      }
      return item;
    });

    // debugger
    if(arr.length){
      arr.forEach((item,i)=>{
        if(res10.data.length == 0){
          if((defultUser && defultUser.patient_sex === '女' && (item.classifyId === 5 || item.classifyId === 7 )) ||
          (defultUser && defultUser.patient_sex === '男' && (item.classifyId === 6 || item.classifyId === 8 ))){
            falseSex.push(item.name)
          }
          this.setState({
            falseSex,
          });
        }else{
          res10.data.forEach((el,j)=>{
            if((i == el.index0 && item.index2 == 1) || 
              (defultUser && defultUser.patient_sex === '女' && (item.classifyId === 5 || item.classifyId === 7 )) ||
              (defultUser && defultUser.patient_sex === '男' && (item.classifyId === 6 || item.classifyId === 8 ))
              ){
              falseSex.push(item.name)
            }
            this.setState({
              falseSex,
            });
          })
          
        }
      })
    }

  };

  fuzhi = (e) => {
    const { listchose, miaosu } = this.state;
    this.setState(
      {
        miaosu: e.replace(/\s*/g, ""),
      },
      () => {}
    );
    localStorage.setItem("miaosu", e.replace(/\s*/g, ""));
  };
  toSelect = () => {
    const { selectcode,filterNotice,isReadNotice } = this.state 
    if(selectcode == 0 && filterNotice == false){
      this.setState({
        visible2:true
      })
      if(isReadNotice==false){
        this.startCountdown();
      }
    }
    this.setState({
      selectcode: this.state.selectcode == 0 ? 1 : 0,
    });
  };
  // 选择视频问诊
  toSelectvideo = () => {
    this.setState({
      videocode: 1,
      visiblecontype:true
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };
  // 选择图文问诊
  toSelecttuwen = () => {
    this.setState({
      videocode: 0,
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };
  // 弹窗选择视频问诊
  toSelectvideotype = () =>{
    this.setState({
      videocode: 1,
      visiblecontype:false
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };
  // 弹窗选择图文问诊
  toSelecttuwentype = () =>{
    this.setState({
      videocode: 0,
      visiblecontype:false
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };

  toAddpatient = () => {
    const {
      coordinationcode,
      miaosu,
      entitycode,
      selectdoctor,
      docterName,
      docterId,
    } = this.state;
    if (selectdoctor == 2) {
      this.props.history.replace(
        `/patientinformation?coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
      );
      this.props.history.push(
        `/newpatient?illmiaosu=${miaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
      );
    } else {
      this.props.history.replace(
        `/patientinformation?coordinationcode=${coordinationcode}&entitycode=${entitycode}`
      );
      this.props.history.push(
        `/newpatient?illmiaosu=${miaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}`
      );
    }
    // window.history.go(0)
  };
  toTips = () => {
    const {
      defultUser,
      listchose,
      coordinationcode,
      allcount,
      isuse,
      isadverse,
      selectmeds,
      entitycode,
      miaosu,
      selectcode,
      spiritMedslength, //精神类药品数量
      onewsMeds,
      onewsMeds1,
      list,
      falseSex,
      filteringArr,
      videocode
    } = this.state;


     // 男性/女性用药提醒
     let onewsMeds2 = [];
     onewsMeds.map((i) => {
       onewsMeds2.push({ value: i, index: 0 });
     });
     onewsMeds2.map((i) => {
       falseSex.map((j) => { 
         if (i.value == j) {
           i.index = 1;
         }
       });
     });

    let tiparr = [];
    onewsMeds2.map((i)=>{
      if(i.index == 1){
        tiparr.push(i.value);
      }
    }) 

    console.log(
      listchose.length
        ? listchose.toString() + (miaosu ? ";" + miaosu : "")
        : miaosu
    );

    let params = {
      miaosu: listchose.length
        ? listchose.toString() + (miaosu ? ";" + miaosu : "")
        : miaosu, //病情描述
      selectcode, //是否勾选风险告知书 1是  0否
      isuse, //是否服用过 1是 0否
      isadverse: isadverse == 0 ? true : false, //是否有不良反应 1有 0无
      defultUser,
      onesmedsarr:onewsMeds2,
      videocode,
    };
    if(tiparr.length){
      if (onewsMeds2.length > 0 ) { 
        let tiparr = [];
        onewsMeds2.map((i)=>{
          if(i.index == 1){
            tiparr.push(i.value);
          }
        })
        const gender = defultUser.patient_sex === "男" ? "女性" : "男性";
        const medication = tiparr.join(", ");
        const message = `【${medication}】为${gender}用药，请删除重新选药问诊`;
        if(medication){
          Toast.info(message);
        }
        return;
      }
    }else{
      if (params.miaosu) {
        if (entitycode == 1) {
          if (selectcode) {
            if (isuse == 0) {
              if(isadverse == 0){
                if (defultUser) {
                } else {
                  Toast.show("请先选择用药人");
                }
              }else{
                Toast.show("病史资料异常，请去医疗机构完成就诊，确认是否对此类药品过敏");
              }
            } else {
              Toast.show("病史资料异常，请去医疗机构完成就诊，确认是否使用过此类药物");
            }
          } else {
            Toast.show("请先阅读并勾选风险告知书");
          }
        } else {
          this.useRef.current.scrollTop = 0;
          Toast.show(
            "是否已在实体医院就诊，本次为复诊？选择是才能在本平台开方，选择否请到线下实体医院就诊。"
          );
        } 
      } else {
        Toast.show("请选择病情描述");
      }
    }
    

  };
  //下一步，去咨询医生
  nextStep = async () => {
    isClick = true
    const {
      tips,
      coordinationcode,
      highMedslength,
      highMeds,
      highMedsC,
      specialMedicine,
      allotherMeds,
      selectmeds,
      defultUser,
      onewsMeds,
      list,
      spiritMedslength, //精神类药品数量
      injectionMeds,//注射剂
      injectionMedslength,//注射剂数量
      falseSex,
      onewsMeds1,
      nArr1,
      filteringArr,
      videocode,
      sureBtn,
      sureBtnCount,
      medIcdCustomArr
    } = this.state;

    if(defultUser.age<7 && defultUser.age>=0){
      if(!defultUser.guardianName){
        Toast.show('患者年龄小于7岁，请先添加患者监护人信息')
        return
      }else if(!defultUser.guardianIdcardNum){
        Toast.show('患者年龄小于7岁，请先添加患者监护人信息')
        return
      }else if(!defultUser.relationship){
        Toast.show('患者年龄小于7岁，请先患者与您的关系')
        return
      }else if(!defultUser.bodyweight){
        Toast.show('患者年龄小于7岁，请先添加患者的体重')
        return
      }
    }

    // 找到选中的疾病
    const choseList = list.find(item => item.index == 2);

    // 再从选中的疾病中判断是否有急性病存在
    if (choseList && list.some(item => item.index == 2 && item.is_acute == 1)) {
      Toast.show("当前用药人处于急性发作期，建议线下就诊或拨打急救电话，避免错过最佳救助时机");
      return;
    }

    if (this.state.filteringArr.some(item => item.label === "手机号") &&
        this.state.filteringArr.some(item => item.label === "身份证号码")) {
      if (defultUser.patient_phone == null && defultUser.patient_id_card == null) {
        Toast.show("根据相关规定，需要填写患者完整的身份证号码/手机号才能提交，请前往患者信息填写");
        return;
      }
    }
    if (this.state.filteringArr.some(item => item.label === "身份证号码")) {
      if (defultUser.patient_id_card == null) {
        Toast.show("根据相关规定，需要填写患者完整的身份证号码才能提交，请前往患者信息填写");
        return;
      }
    }
    if (this.state.filteringArr.some(item => item.label === "手机号")) {
      if (defultUser.patient_phone == null) {
        Toast.show("根据相关规定，需要填写患者完整的手机号才能提交，请前往患者信息填写");
        return;
      }
    }

    if(videocode == 3){
      Toast.show("请选择问诊方式")
      return;
    }


    if (tips) {
      Toast.info("请勿重复提交");
    } else {
      let arr = [];
      let arr1 = [];
      let tbclass = []; //头孢类
      let qmsclass = []; //青霉素类  头孢类与青霉素类不能一起开
      let sxclass = []; //沙星类
      let tnbclass = []; //糖尿病类  沙星类与糖尿病类不能一起开
      let kjxyclass = []; //抗菌消炎类的同类不能一起开
      let qrclass = []; //清热类（二类）
      let byclass = []; //补益类   清热类（二类）与补益类不能一起开
      let ansbnclass = []; //安神补脑类  清热类（二类）与安神补脑类不能一起开

      list.map((i) => {
        if (i.index == 2) {
          arr.push(i.index0);  //将选择了疾病的索引放到arr数组中
          arr1.push(i.id);
        }
      });


      let medIcdCountMap = {};
      list.map((i) => {
        if (i.index == 2) {
          const medName = i.index0;
          const icdValue = i.id;

          if (medIcdCountMap.hasOwnProperty(medName)) {
            medIcdCountMap[medName]++;
          } else {
            medIcdCountMap[medName] = 1;
          }
        }
      });



      // 判断是否有某个药品对应的 icd 数量大于 3
      const exceedMsg = Object.keys(medIcdCountMap).find(
        (medName) => medIcdCountMap[medName] > 3
      );

      if (exceedMsg) {
        Toast.show("单个药品病情描述不能超过3个，请删减");
        return;
      }

      arr = arr.filter((currentValue, currentIndex, selfArr) => {
        return selfArr.findIndex((x) => x === currentValue) === currentIndex;
      });
      
      arr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
          currentIndex
        );
      });
      

      let res0;
      if (defultUser.patient_id_card) {
        res0 = await isHightmed({
          patientIdcardNum: defultUser.patient_id_card,
        });
      }
      


      // 男性/女性用药提醒
      let onewsMeds2 = [];
      onewsMeds.map((i) => {
        onewsMeds2.push({ value: i, index: 0 });
      });
      onewsMeds2.map((i) => {
        falseSex.map((j) => { 
          if (i.value == j) {
            i.index = 1;
          }
        });
      });

      let tiparr = [];
      onewsMeds2.map((i)=>{
        if(i.index == 1){
          tiparr.push(i.value);
        }
      }) 

      if(tiparr.length){
        if (onewsMeds2.length > 0 ) { 
          let tiparr = [];
          onewsMeds2.map((i)=>{
            if(i.index == 1){
              tiparr.push(i.value);
            }
          })
          const gender = defultUser.patient_sex === "男" ? "女性" : "男性";
          const medication = tiparr.join(", ");
          const message = `【${medication}】为${gender}用药，请删除重新选药问诊`;
          if(medication){
            Toast.info(message);
          }
          return;
        }
      }else{
        if (arr.length < selectmeds.length) {
          Toast.info("所有药品都必须选择病情描述");
          return;
        } else {
          // if (arr1.length < selectmeds.length) { 
            let kjxyclass0 = [];
            let kjxyclass1 = [];
            let kjxyclass2 = [];
            selectmeds.forEach((i) => {
              if (i.classifysonId == 67) {
                tnbclass.push(i.medComName);
              } else if (i.classifyId == 2) {
                kjxyclass2.push(i);
                kjxyclass0.push(i.classifysonId);
                if (kjxyclass0.length) {
                  kjxyclass0.forEach((item) => {
                    if (
                      kjxyclass0.indexOf(item) != kjxyclass0.lastIndexOf(item) &&
                      kjxyclass1.indexOf(item) == -1
                    ) {
                      kjxyclass1.push(item);
                    }
                  });
                }

                if (kjxyclass1.length) {
                  kjxyclass2.forEach((item) => {
                    kjxyclass1.forEach((j) => {
                      if (item.classifysonId == j) {
                        kjxyclass.push(item.medComName);
                      }
                    });
                  });
                }
                if (i.classifysonId == 16) {
                  tbclass.push(i.medComName);
                } else if (i.classifysonId == 15) {
                  qmsclass.push(i.medComName);
                } else if (i.classifysonId == 18) {
                  sxclass.push(i.medComName);
                }
              } else if (i.classifyId == 107) {
                qrclass.push(i.medComName);
              } else if (i.classifysonId == 121) {
                byclass.push(i.medComName);
              } else if (i.classifysonId == 100) {
                ansbnclass.push(i.medComName);
              }
            });
              let containsHighMeds = false;
              let containsSpecial = false;
  
              if (
                (highMeds.length || highMedsC.length) &&
                defultUser.patient_id_card &&
                res0 &&
                res0.data &&
                res0.data[0] &&
                res0.data[0].length
              ) {
                for (let i = 0; i < res0.data[0].length; i++) {
                  const item = res0.data[0][i];
                  if ( highMeds.includes(item.med_com_name) || highMedsC.includes(item.med_com_name)) {
                    this.setState({
                      tips:0,
                    })
                    Toast.info("此身份证今日已购买含特殊/高危药品，用户每天最多购买一盒特殊/高危药品");
                    return
                  }
                }
              }
              if (containsHighMeds || (specialMedicine.length && defultUser.patient_id_card && res0 && res0.data && res0.data[1] && res0.data[1].length && res0.data[1][0].med_com_name == highMedsC)) {
                this.setState({
                  tips: 0,
                });
                Toast.info("此身份证今日已购买含特殊/高危药品，用户每天最多购买一盒特殊/高危药品");
              }
  
              if(specialMedicine.length &&
                  defultUser.patient_id_card &&
                  res0 &&
                  res0.data[1] &&
                  res0.data[1].length){
                    for (let i = 0; i < res0.data[1].length; i++) {
                      const item = res0.data[1][i];
                      if (specialMedicine.includes(item.med_com_name)) {
                        this.setState({
                          tips:0,
                        })
                        Toast.info("此身份证今日已购买含特殊/高危药品，用户每天最多购买一盒特殊/高危药品");
                        return
                      }
                    }
                  }
                  if (containsSpecial || (specialMedicine.length && defultUser.patient_id_card && res0 && res0.data && res0.data[1] && res0.data[1].length && res0.data[1][0].med_com_name == highMedsC)) {
                    this.setState({
                      tips: 0,
                    });
                    Toast.info("此身份证今日已购买含特殊/高危药品，用户每天最多购买一盒特殊/高危药品");
                  }

              else {
                if (
                  (coordinationcode == 1 && defultUser.patient_id_card) ||
                  coordinationcode == 2
                ) {
                  if (coordinationcode == 1) {
                    let allarray = [];
                    if (selectmeds.length) {
                      selectmeds.forEach((i) => {
                        let params = {};
                        // params.medName = i.medComName;
                        params.medName = i.medComName ? i.medComNamen :i.medName;
                        params.medSpec = i.medSpec;
                        params.medPrice = i.medPrice;
                        params.medCompany = i.medCompany;
                        allarray.push(params);
                      });
                    }

                    let res = await getCollectmedicinal({ allNews: allarray });

                    if (res && res.data && res.data.length) {
                      alert(
                        "提示",
                        "【" +
                          res.data.toString() +
                          "】 不属于门诊统筹范围，不在报销范围，是否继续购买？",
                        [
                          {
                            text: "取消",
                            onPress: () =>
                              this.setState({
                                tips: 0,
                              }),
                            style: "default",
                          },
                          {
                            text: "确定",
                            onPress: async () => {
                              this.setState(
                                {
                                  tips: 1,
                                },
                                async () => {
                                  this.toAwaitDoc();
                                }
                              );
                            },
                          },
                        ]
                      );
                    } else {
                      this.setState(
                        {
                          tips: 1,
                        },
                        async () => {
                          this.toAwaitDoc();
                        }
                      );
                    }
                  } else {
                    this.setState(
                      {
                        tips: 1,
                      },
                      async () => {
                        this.toAwaitDoc();
                      }
                    );
                  }
                } else {
                  this.setState({
                    tips: 0,
                  });
                  Toast.info("医保统筹需填写身份证号码");
                }
              }
            // }
          // } else {
          //   Toast.info("已选疾病不能超过三种，请核对所选疾病或减少药品");
          // }
        }
      }

      
    }
  };

  //创建订单和问诊单（无医生id）
  toAwaitDoc = async () => {
    if(isClick){
      const {
        defultUser,
        index,
        icdlists,
        supervisionType,
        videocode,
        docterId,
        docterName,
        patientId,
        patientPhone,
        pharmacyId,
        selectdoctor,
        coordinationcode,
        isadverse,
        highMedsArray,
        selectmeds,
        miaosu,
        highMeds,
        highMedsC,
        highMedslength,
        list,
        specialMedicine,
        listchose,
        entitycode,
        patientsProvince,
        patientsCities,
        patientsCounty,
        pharmacyNameAll,
        isExcessArr,
        visibleIsExcess,
        sureBtn,
        sureBtnCount,
        splitBtn,
        medIcdCustomArr,
        imageUrl,
        isSetUseday,
        setUseday
      } = this.state;   
      

      let params = {
        userId: parseInt(patientId),
        orderShipping: "1",
        phone: patientPhone,
        pharmacyId: parseInt(pharmacyId),
        orderPrice: 0,
        orderAmount: 0,
        supervisionType: parseInt(supervisionType),
      };
      let listchose0 = [];
      listchose0 = listchose.filter((currentValue, currentIndex, selfArr) => {
        return selfArr.findIndex((x) => x === currentValue) === currentIndex;
      });
      let array1 = [];
      let array2 = [];
      let array3 = [];
      let classifyId0 = "";
      let codeId = 1;
      if (defultUser.patient_sex == "男") {
        classifyId0 = 7;
      } else {
        classifyId0 = 8;
      }
      let code0 = 1;
      let code1 = 2;
      let code2 = 2;
      let code3 = 2;
      // let code4 = 2;
      // let code5 = 2;
      let count2 = 0;
      let childrencode = 0;
  
      let highMedscount = 0;
  
      let highountArr=[];
      let highcCountArr=[];
      let specialCountArr=[];
  
      selectmeds.forEach((item,key) => {
  
        if (specialMedicine.length) {
          specialMedicine.forEach((i) => {
            if (item.medComName.replace(/^\s*|\s*$/g, "") == i.replace(/^\s*|\s*$/g, "")) {
              specialCountArr.push({
                medComName: item.medComName,
                count: item.count
              });
            }
          });
        }
        if (highMeds.length) {
          highMeds.forEach((i) => {
            if (item.medComName.replace(/^\s*|\s*$/g, "") == i.replace(/^\s*|\s*$/g, "")) {
              highountArr.push({
                medComName: item.medComName,
                count: item.count
              });
            }
          });
        }
        if (highMedsC.length) {
          highMedsC.forEach((i) => {
            if (item.medComName.replace(/^\s*|\s*$/g, "") == i.replace(/^\s*|\s*$/g, "")) {
              highcCountArr.push({
                medComName: item.medComName,
                count: item.count
              });
            }
          });
        }
  
        
        if (highMedsArray.length) {
          highMedsArray.forEach((i) => {
            if (
              item.medComName.replace(/^\s*|\s*$/g, "") ==
              i.replace(/^\s*|\s*$/g, "")
            ) {
              highMedscount += item.count;
            }
          });
        }
        if (
          item.classifyId == 56 ||
          item.classifyId == 6 ||
          item.classifyId == 9 ||
          item.classifyId == 10 ||
          item.classifyId == 105 
        ) {
          code1 = 1;
        }
        if (item.classifyId == 114) {
          //注射药品
          code2 = 1;
          count2 += 1;
        }
        if (item.classifyId == 9) {
          //儿童用药
          childrencode = 1;
        }
        if (item.classifyId == 11 || item.classifyId == 116) {
          //内科
          code3 = 1;
        }

        let a = item.medComName + item.medSpec;
        array2.push(a);
  
        let icdId = {};
        let disease = '';
        let disId = '';
        list.map((i) => {
          if (i.index == 2 && i.index0 == key) {
            icdId.icd_name = i.id.icdName;
            icdId.icd_value = i.id.icdValue;
            disease=i.value;
            disId=i.dis_id;
            return;
          }
        });
        array3.push({
          medComName: item.medComName,
          medSpec: item.medSpec,
          classifysonName: item.classifysonName,
          medQuantity: item.count,
          icdId: JSON.stringify([icdId]),
        });
  
        array1.push({
          medId: item.medId,
          medName: item.medName ? item.medName : item.medComName,
          medSpec: item.medSpec ? item.medSpec : null,
          medPacking: item.medPacking ? item.medPacking : null,
          medApproval: item.medApproval ? item.medApproval : null,
          medCompany: item.medCompany ? item.medCompany : null,
          medPrice: item.medPrice ? item.medPrice : 0,
          medQuantity: item.count,
          libraryId: item.libraryId ? item.libraryId : "0",
          medEnterprise: item.medEnterprise ? item.medCompany : null,
          thirdPartyId: item.thirdPartyId ? item.thirdPartyId : "0",
          medQrcode: item.medQrcode ? item.medQrcode : null,
          medComName: item.medComName ? item.medComName : item.medName,
          medDosageForm: item.medDosageForm ? item.medDosageForm : null,
          medType: item.medType ? item.medType : null,
          contacts: localStorage.getItem("contacts"),
          medApplySymptoms: item.medApplySymptoms ? item.medApplySymptoms : null,
          supervisionType: parseInt(item.supervisionType),
          packingUnit: item.packingUnit ? item.packingUnit : null,
          isOtc: item.medClassify == "1" ? 2 : 1,
          icdId:icdId,
          disease:disease,
          disId:disId,
          serial_number:0,
          classifyId: item.classifyId
        });
      });


      // 提醒用户是否有药品超量
      let medInfo = []; //存放用户提交的药品通用名、规格、药品疾病
      for (let i = 0; i < array1.length; i++) {
         medInfo.push([
         array1[i].medComName,
         array1[i].medSpec,
         array1[i].disId,
       ]);
      }

      let usageRes=await drugsUsage({ 'medInfo': medInfo });
      let Usage = usageRes.data[0];
      let processed = {};
      let processedLarge = {};
      let nameLarge={}
      let nameMedLargeUage={}
      // console.log('用药方法：',Usage)
      let medLargeDayUse = ''
      Usage.map(usageData => {
        // let medLargeDayUse = ''
        if (usageData.med_large_day && usageData.med_all) {          
          if(isSetUseday=='2' && (usageData.med_large_day > setUseday)){
            medLargeDayUse = setUseday
          }else{
            medLargeDayUse = usageData.med_large_day
          }
          
          let frequency = usageData.med_frequency.slice(0, 2);
          let frequency2 = usageData.med_frequency.slice(2);
          
          let medusage = parseFloat(usageData.med_usage);
          
          let medlargeusage = parseFloat(usageData.med_large_usage);
          
          let medFrequency = parseFloat(frequency2);
          if (medFrequency) {
            if(!processed[usageData.med_name.replace(/\s*/g, '') + usageData.med_spec.replace(/\s*/g, '')]){
              if((medFrequency * medusage * medLargeDayUse / usageData.med_all).toFixed(2)){
                  processed[usageData.med_name.replace(/\s*/g, '') + usageData.med_spec.replace(/\s*/g, '')] =  (medFrequency * medusage * medLargeDayUse / usageData.med_all).toFixed(2);
              }
            }
            
            if(!processedLarge[usageData.med_name.replace(/\s*/g, '') + usageData.med_spec.replace(/\s*/g, '')]){
              if((medFrequency * medlargeusage * medLargeDayUse / usageData.med_all).toFixed(2)){
                processedLarge[usageData.med_name.replace(/\s*/g, '') + usageData.med_spec.replace(/\s*/g, '')] =  (medFrequency * medlargeusage * medLargeDayUse / usageData.med_all).toFixed(2);
                nameLarge[usageData.med_name.replace(/\s*/g, '')] =  (medFrequency * medlargeusage * medLargeDayUse / usageData.med_all).toFixed(2);
                nameMedLargeUage[usageData.med_name.replace(/\s*/g, '')]=medLargeDayUse ? medLargeDayUse : '7'
              }
            }
            
          }
        }
      });

      // console.log('array1',setUseday,medLargeDayUse)
      // return 
      // 比较用户提交的药品是否有需要超量拆分
      let maxDataLargeSplit=''
      array1.forEach(item => {
        if(nameMedLargeUage[item.medComName] >= '84'){
          maxDataLargeSplit = nameLarge[item.medComName] < 1 ? Math.ceil(parseFloat(nameLarge[item.medComName])) :Math.floor(parseFloat(nameLarge[item.medComName])); //向下取整(不足一盒的向上取整，够一盒的向下取证)
        }else{
          maxDataLargeSplit = Math.ceil(parseFloat(nameLarge[item.medComName])); //向上取整
        }
        // maxDataLargeSplit = nameLarge[item.medComName] < 1 ? Math.ceil(parseFloat(nameLarge[item.medComName])) :Math.floor(parseFloat(nameLarge[item.medComName])); //向下取整(不足一盒的向上取整，够一盒的向下取证)
        // console.log('maxDataLargeSplit',maxDataLargeSplit,'maxDataLargeSplit1--',maxDataLargeSplit1);
        
        if(splitBtn == 1){
          item.isExcess = 2
        }else{
          if (item.medQuantity > maxDataLargeSplit) { //表示如果用户购买的数量大于最大能够够买的数量，那么标记这单为超量的问诊
            item.isExcess = 1     //isExcess  1:表示超量  2：表示不超量
            item.maxDataLargeSplit = maxDataLargeSplit
          }else{
            item.isExcess = 2
            item.maxDataLargeSplit = maxDataLargeSplit
          }
        }
      });

      // 找到超量的药品，提醒是否需要删减
      let medIsExcess = [];//超量药品
      for (let i = 0; i < array1.length; i++) {
        if (array1[i].isExcess == 1) {
          medIsExcess.push(array1[i].medComName);
          let item = {
            medComName: array1[i].medComName,
            maxDataLargeSplit: array1[i].maxDataLargeSplit,
            packingUnit: array1[i].packingUnit,
            classifyId: array1[i].classifyId
          };
          isExcessArr.push(item);
        }
      }


      if(isExcessArr.length>0 && sureBtnCount == 0){
        this.setState({
          sureBtn:false
        }) 
      }


    // 查找isCustomDisId存在且index==2的表示是用户选择的自定义疾病，通过index0找到对应的药品是哪一个，组成medIcdCustomArr数组
    // medIcdCustomArr格式是：med_id、med_com_name、med_spec、packing_unit、dis_id

    for (const item of list) {
      // 如果index 为 2 且 isCustomDisId 存在的，去找对应的药品，将药品信息和icd存在medIcdCustomArr中
      if (item.index == 2 && item.isCustomDisId == 1) {
        for (const medItem of selectmeds) {
          if (medItem.index1 == item.index0) {
            const isExistCusRes = await isExistCus({
              disName: item.value,
              disId: item.dis_id,
              medId: medItem.medId,
              medComName: medItem.medComName,
              medSpec: medItem.medSpec,
            });
  
            // console.log('isExistCusRes', isExistCusRes.data[0][0]);
            if (isExistCusRes.data[0][0].total == 0) {
              let medInfo = {
                icd_id: item.id,
                icd_value: item.value,
                dis_id: item.dis_id,
                med_id: medItem.medId,
                med_com_name: medItem.medComName,
                med_spec: medItem.medSpec,
                packing_unit: medItem.packingUnit,
                icd_name_value:`${item.id.icdName}_${item.id.icdValue}`
              };
              medIcdCustomArr.push(medInfo);
            }
          }
        }
      }
    }

    isClick = false

      if(defultUser.patient_id_card){
        // 查询该患者今天是否已经购买过药品
        let resName = await todayBuyName({ 
          patientIdcardNum:defultUser.patient_id_card,
          array2
        }) 
        if(resName && resName.data && resName.data[0][0].total > 0){
          if(resName && resName.data && resName.data[2][0].cTotal > 0){
            code0 = 2;
          }
        }
      }


      if(this.state.sureBtn){
        // 将当前患者更改为默认就诊人
        changeDefaultMember({
          memberId: defultUser.member_id,
          patientDefault: 1,
          patientId:patientId,
        }).then((resMember)=>{
          console.log(resMember,'resMember');
        })
        if (code2 == 1 && !defultUser.patient_id_card) {
          this.setState({
            tips: 0,
          });
          Toast.info("含注射药品需填写身份证号码");
        } else {
            if(specialCountArr.some(obj=>obj.count>1) ||
              highountArr.some(obj=>obj.count>1) ||
              highcCountArr.some(obj=>obj.count>1)
            ){
              this.setState({
                tips: 0,
              });
              const highMeds = [...specialCountArr, ...highountArr, ...highcCountArr];
              const highMedsNames = highMeds
                .filter(obj => obj.count > 1) // 添加条件语句过滤 count 大于 1 的对象
                .map(obj => obj.medComName)
                .join(", ");
              
              Toast.info(
                highMedsNames + "属于特殊/高危药品，一天只能购买一盒"
              );
            } else {
              if (highMedsArray.length && !defultUser.patient_id_card) {
                this.setState({
                  tips: 0,
                });
                Toast.info("含特殊/高危药品需填写身份证号码");
              } else {
                if (highMeds.length && coordinationcode == 1) {
                  this.setState({
                    tips: 0,
                  });
                  Toast.info(
                    highMeds.toString() + "属于A/B级高危药品，无法使用医保统筹"
                  );
                } else {
                  if (highMedsC.length && coordinationcode == 1) {
                    alert(
                      "提示",
                      highMedsC.toString() + "属于C级高危药品，确认使用医保统筹",
                      [
                        {
                          text: "取消",
                          onPress: () =>
                            this.setState({
                              tips: 0,
                            }),
                          style: "default",
                        },
                        {
                          text: "确定",
                          onPress: async () => {
                            if (code0 == 2) {
                              alert(
                                "提示弹窗",
                                "该患者已提交过相同药品的问诊，确认是否再次提交？",
                                [
                                  {
                                    text: "取消",
                                    onPress: () =>
                                      this.setState({
                                        tips: 0,
                                      }),
                                  },
                                  {
                                    text: "确认",
                                    onPress: async () => {
                                      let res00 = await isSelfphamacy(
                                        localStorage.getItem("pharmacyId")
                                      );
                                      if (res00.code == 1) {
                                        if (
                                          res00 &&
                                          res00.data &&
                                          res00.data[0] &&
                                          (res00.data[0][0].is_self_operated == 1 ||
                                            (res00.data[1] &&
                                              res00.data[1][0].iscode == 1))
                                        ) {
                                          let res = await getOrderallways({
                                            arrayMeds: array3,
                                            sex:
                                              defultUser.patient_sex == "男"
                                                ? 1
                                                : 2,
                                          });
                                            let paramsall = {
                                              memberIdNew:defultUser.member_id,
                                              submitPort:2,
                                              isExcessArr:isExcessArr ? JSON.stringify(isExcessArr) : null,
                                              userId: patientId,
                                              hasHighmed: highMedsArray.length ? 1 : 2,
                                              isSpecialmed: specialMedicine.length ? 1 : 2,
                                              pharmacyName:pharmacyNameAll,
                                              pharmacyId: parseInt(pharmacyId),
                                              docterId: docterId,
                                              isChildrenmed: childrencode ? 1 : 2,
                                              isAppointDoctor:selectdoctor == 2 ? 1 : 2,
                                              consultationFiles: imageUrl ? imageUrl : "",
                                              consultationAmount: 0,
                                              // consultationDetail: listchose0.toString(),
                                              isclassifyId: code1 == 1 ? 1 : 2,
                                              // isSurgery: code4 == 1 ? 1 : 2,
                                              isInternal: code1 == 1 ? 2 : code2 == 1 || code3 == 1 ? 1 : 2,
                                              consultationType: videocode ? "3" : "1",
                                              consultationStu: "-1",
                                              patientName: defultUser.patient_name,
                                              pregnancyStatus: defultUser.pregnancy_status,
                                              patientSex: defultUser.patient_sex == "男" ? 1 : 2,
                                              patientIdcardNum: defultUser.patient_id_card,
                                              patientBirthday:new Date(defultUser.patient_birthday).getFullYear() + "-" +
                                                (new Date(defultUser.patient_birthday).getMonth() + 1 < 10
                                                  ? "0" + (new Date( defultUser.patient_birthday ).getMonth() + 1)
                                                  : new Date(defultUser.patient_birthday).getMonth() + 1) +
                                                "-" +
                                                (new Date(defultUser.patient_birthday).getDate() < 10 ? "0" +
                                                    new Date(defultUser.patient_birthday).getDate()
                                                  : new Date(defultUser.patient_birthday).getDate()),
                                              patientCity: defultUser.patient_city,
                                              patientPhone: defultUser.patient_phone,
                                              pastHistory: defultUser.past_history,
                                              pastHistoryNote: defultUser.past_history_note,
                                              allergicHistory: defultUser.allergic_history,
                                              allergicHistoryNote: defultUser.allergic_history_note,
                                              familyGeneticHistory: defultUser.family_genetic_history,
                                              familyGeneticHistoryNote: defultUser.family_genetic_history_note,
                                              abnormalLiverFunction: defultUser.abnormal_liver_function,
                                              abnormalRenalFunction: defultUser.abnormal_renal_function,
                                              patientAge: defultUser.age,
                                              cityId: defultUser.city_id,
                                              patientProvince:defultUser.patient_province && defultUser.patient_province == patientsProvince ? defultUser.patient_province : patientsProvince,//就诊人所在省
                                              patientCities:defultUser.patient_cities && defultUser.patient_cities == patientsCities ? defultUser.patient_cities : patientsCities,//就诊人所在市
                                              patientCounty:defultUser.patient_county && defultUser.patient_county == patientsCounty ? defultUser.patient_county : patientsCounty,//就诊人所在区
                                              medicalnsuranceCoordination:
                                                coordinationcode,
                                              orderFormType: 2,
                                              guardianName: defultUser.guardianName,//监护人姓名
                                              guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
                                              bodyweight: defultUser.bodyweight,//体重
                                              bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
                                              bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
                                            };
                                            if (array1.length == 0) {
                                              this.props.history.push(
                                                `/drugselection`
                                              );
                                              Toast.show("请先选择药品");
                                            } else {
                                              sumbitLogs({
                                                arrayMeds:array3,
                                                sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                                params,//基础参数
                                                array1:array1,//药品参数
                                                paramsall,//问诊参数
                                              }).then((reslogs)=>{
                                                // console.log('reslogs--1',reslogs);
                                              })

                                              subChaifen
                                              ({
                                                arrayMeds:array3,
                                                sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                                params,//基础参数
                                                array1:array1,//药品参数
                                                paramsall,//问诊参数
                                                // isCheck,//是否检查
                                                medIcdCustomArr:medIcdCustomArr,
                                              }).then((res2) => {
                                                  // console.log(res2,'提交1-----------------------');
  
                                                  if(res2.data == "包含超量商品不能拆分"){
                                                    Toast.show("根据相关规定，您提交的处方药数量过多，请进行删减")
                                                    setTimeout(() => {
                                                      window.location.reload(); // 自动刷新页面
                                                    }, 300); // 设置定时器
                                                    return;
                                                  }
  
                                                  if(res2.data == "根据相关规定，您提交的处方药数量过多，请酌量进行删减"){
                                                    Toast.show("根据相关规定，您提交的处方药数量过多，请酌量进行删减")
                                                    setTimeout(() => {
                                                      window.location.reload(); // 自动刷新页面
                                                    }, 300); // 设置定时器
                                                    return;
                                                  }
                                                  if(res2.data == "您购买商品已超出最大量，请进行删减"){
                                                    Toast.show("您购买商品已超出最大量，请进行删减")
                                                    setTimeout(() => {
                                                      window.location.reload(); // 自动刷新页面
                                                    }, 300); // 设置定时器
                                                    return;
                                                  }
                                                  if ( res2 && res2.data && res2.data[0] && res2.data[0].consultationId ) {
                                                    localStorage.setItem("consultationId", res2.data[0].consultationId);
                                                    localStorage.removeItem("selectmeds");
                                                    localStorage.removeItem("listicd");
                                                    localStorage.removeItem(
                                                      "illmiaosu"
                                                    );
                                                    localStorage.removeItem(
                                                      "miaosu"
                                                    );
                                                    localStorage.removeItem(
                                                      "selectdoctormiaosu"
                                                    );
                                                    // localStorage.removeItem("videocode");
                                                    this.setState({
                                                      consultationId:
                                                        res2.data[0]
                                                          .consultationId,
                                                      awaitdoc: 2,
                                                    });
                                                    // this.toAwaittime();
                                                    if (res2.data.length > 5) {
                                                      let count = res2.data.length - 4
                                                      Toast.show(`您的问诊已被拆分成${count}个，请在进行中和等待中列表查看`);
                                                      this.props.history.replace(`/offawait`);
                                                    } else {
                                                      this.toAwaittime();
                                                    }
                                                  }else{
                                                    Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                                    this.setState({
                                                      tips: 0,
                                                    });
                                                  }
                                                }).catch((res) => {
                                                  Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                                  this.setState({
                                                    tips: 0,
                                                  });
                                                });
                                            }
                                          // }
                                        } else {
                                          this.setState(
                                            {
                                              // visible3:true,
                                              tips: 0,
                                            },
                                            () => {
                                              Toast.show(
                                                "该店铺为非会员，无法进行问诊"
                                              );
                                            }
                                          );
                                        }
                                      } else {
                                        this.setState({
                                          // visible3:true,
                                          tips: 0,
                                        });
                                      }
                                    },
                                  },
                                ]
                              );
                            } else {
                              let res00 = await isSelfphamacy(
                                localStorage.getItem("pharmacyId")
                              );
  
                              if (res00.code == 1) {
                                if (
                                  res00 &&
                                  res00.data &&
                                  res00.data[0] &&
                                  (res00.data[0][0].is_self_operated == 1 ||
                                    (res00.data[1] && res00.data[1][0].iscode == 1))
                                ) {
                                  let res = await getOrderallways({
                                    arrayMeds: array3,
                                    sex: defultUser.patient_sex == "男" ? 1 : 2,
                                  });
  
                                    let paramsall = {
                                      memberIdNew:defultUser.member_id,
                                      submitPort:2,
                                      isExcessArr:isExcessArr ? JSON.stringify(isExcessArr) : null,
                                      userId: patientId,
                                      hasHighmed: highMedsArray.length ? 1 : 2,
                                      isSpecialmed: specialMedicine.length ? 1 : 2,
                                      pharmacyName:pharmacyNameAll,
                                      pharmacyId: parseInt(pharmacyId),
                                      docterId: docterId,
                                      isChildrenmed: childrencode ? 1 : 2,
                                      isAppointDoctor: selectdoctor == 2 ? 1 : 2,
                                      consultationFiles: imageUrl ? imageUrl : "",
                                      isclassifyId: code1 == 1 ? 1 : 2,
                                      // isSurgery: code4 == 1 ? 1 : 2,
                                      isInternal:
                                        code1 == 1
                                          ? 2
                                          : code2 == 1 || code3 == 1
                                          ? 1
                                          : 2,
                                      consultationAmount: 0,
                                      // consultationDetail: listchose0.toString(),
                                      consultationType: videocode ? "3" : "1",
                                      consultationStu: "-1",
                                      patientName: defultUser.patient_name,
                                      pregnancyStatus: defultUser.pregnancy_status,
                                      patientSex:
                                        defultUser.patient_sex == "男" ? 1 : 2,
                                      patientIdcardNum: defultUser.patient_id_card,
                                      patientBirthday:
                                        new Date(
                                          defultUser.patient_birthday
                                        ).getFullYear() +
                                        "-" +
                                        (new Date(
                                          defultUser.patient_birthday
                                        ).getMonth() +
                                          1 <
                                        10
                                          ? "0" +
                                            (new Date(
                                              defultUser.patient_birthday
                                            ).getMonth() +
                                              1)
                                          : new Date(
                                              defultUser.patient_birthday
                                            ).getMonth() + 1) +
                                        "-" +
                                        (new Date(
                                          defultUser.patient_birthday
                                        ).getDate() < 10
                                          ? "0" +
                                            new Date(
                                              defultUser.patient_birthday
                                            ).getDate()
                                          : new Date(
                                              defultUser.patient_birthday
                                            ).getDate()),
                                      patientCity: defultUser.patient_city,
                                      patientPhone: defultUser.patient_phone,
                                      pastHistory: defultUser.past_history,
                                      pastHistoryNote: defultUser.past_history_note,
                                      allergicHistory: defultUser.allergic_history,
                                      allergicHistoryNote:
                                        defultUser.allergic_history_note,
                                      familyGeneticHistory:
                                        defultUser.family_genetic_history,
                                      familyGeneticHistoryNote:
                                        defultUser.family_genetic_history_note,
                                      abnormalLiverFunction:
                                        defultUser.abnormal_liver_function,
                                      abnormalRenalFunction:
                                        defultUser.abnormal_renal_function,
                                      patientAge: defultUser.age,
                                      cityId: defultUser.city_id,
                                      patientProvince:defultUser.patient_province && defultUser.patient_province == patientsProvince ? defultUser.patient_province : patientsProvince,//就诊人所在省
                                      patientCities:defultUser.patient_cities && defultUser.patient_cities == patientsCities ? defultUser.patient_cities : patientsCities,//就诊人所在市
                                      patientCounty:defultUser.patient_county && defultUser.patient_county == patientsCounty ? defultUser.patient_county : patientsCounty,//就诊人所在区
                                      orderFormType: 2,
                                      medicalnsuranceCoordination: coordinationcode,
                                      guardianName: defultUser.guardianName,//监护人姓名
                                      guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
                                      bodyweight: defultUser.bodyweight,//体重
                                      bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
                                      bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
                                      
                                    };
                                    if (array1.length == 0) {
                                      this.props.history.push(`/drugselection`);
                                      Toast.show("请先选择药品");
                                    } else {
                                      
                                      // submitOrdernews
                                      // ({
                                      //   params,
                                      //   array1: array1,
                                      //   paramsall,
                                      // })

                                      sumbitLogs({
                                        arrayMeds:array3,
                                        sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                        params,//基础参数
                                        array1:array1,//药品参数
                                        paramsall,//问诊参数
                                      }).then((reslogs)=>{
                                        // console.log('reslogs--2',reslogs);
                                      })

                                      subChaifen
                                      ({
                                        arrayMeds:array3,
                                        sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                        params,//基础参数
                                        array1:array1,//药品参数
                                        paramsall,//问诊参数
                                        // isCheck,//是否检查
                                        medIcdCustomArr:medIcdCustomArr,
                                      })
                                        .then((res2) => {
                                          // console.log(res2,'提交2-----------------------');
  
                                          if(res2.data == "包含超量商品不能拆分"){
                                            Toast.show("根据相关规定，您提交的处方药数量过多，请进行删减")
                                            setTimeout(() => {
                                              window.location.reload(); // 自动刷新页面
                                            }, 300); // 设置定时器
                                            return;
                                          }
  
                                          if(res2.data == "根据相关规定，您提交的处方药数量过多，请酌量进行删减"){
                                            Toast.show("根据相关规定，您提交的处方药数量过多，请酌量进行删减")
                                            setTimeout(() => {
                                              window.location.reload(); // 自动刷新页面
                                            }, 300); // 设置定时器
                                            return;
                                          }
                                          if(res2.data == "您购买商品已超出最大量，请进行删减"){
                                            Toast.show("您购买商品已超出最大量，请进行删减")
                                            setTimeout(() => {
                                              window.location.reload(); // 自动刷新页面
                                            }, 300); // 设置定时器
                                            return;
                                          }
                                          if ( res2 && res2.data && res2.data[0] && res2.data[0].consultationId ) {
                                            localStorage.setItem(
                                              "consultationId",
                                              res2.data[0].consultationId
                                            );
                                            localStorage.removeItem("selectmeds");
                                            localStorage.removeItem("listicd");
                                            localStorage.removeItem("illmiaosu");
                                            localStorage.removeItem("miaosu");
                                            localStorage.removeItem(
                                              "selectdoctormiaosu"
                                            );
                                            // localStorage.removeItem("videocode");
                                            localStorage.removeItem("imageUrl");
                                            localStorage.removeItem("isUploaded");
                                            this.setState({
                                              consultationId:
                                                res2.data[0].consultationId,
                                              awaitdoc: 2,
                                            });
                                            // this.toAwaittime();
                                            if (res2.data.length > 5) {
                                              let count = res2.data.length - 4
                                              Toast.show(`您的问诊已被拆分成${count}个，请在进行中和等待中列表查看`);
                                              this.props.history.replace(`/offawait`);
                                            } else {
                                              this.toAwaittime();
                                            }
                                          }else{
                                            Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                            this.setState({
                                              tips: 0,
                                            });
                                          }
                                        })
                                        .catch((res) => {
                                          Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                          this.setState({
                                            // visible3:true,
                                            tips: 0,
                                          });
                                        });
                                    }
                                  // }
                                } else {
                                  this.setState(
                                    {
                                      // visible3:true,
                                      tips: 0,
                                    },
                                    () => {
                                      Toast.show("该店铺为非会员，无法进行问诊");
                                    }
                                  );
                                }
                              } else {
                                this.setState({
                                  // visible3:true,
                                  tips: 0,
                                });
                              }
                            }
                          },
                        },
                      ]
                    );
                  } else {
                    if (code0 == 2) {
                      alert(
                        "提示弹窗",
                        "该患者已提交过相同药品的问诊，确认是否再次提交？",
                        [
                          {
                            text: "取消",
                            onPress: () =>
                              this.setState({
                                tips: 0,
                              }),
                          },
                          {
                            text: "确认",
                            onPress: async () => {
                              let res00 = await isSelfphamacy(
                                localStorage.getItem("pharmacyId")
                              );
  
                              if (res00.code == 1) {
                                if (
                                  res00 &&
                                  res00.data &&
                                  res00.data[0] &&
                                  (res00.data[0][0].is_self_operated == 1 ||
                                    (res00.data[1] && res00.data[1][0].iscode == 1))
                                ) {
                                  let res = await getOrderallways({
                                    arrayMeds: array3,
                                    sex: defultUser.patient_sex == "男" ? 1 : 2,
                                  });
  
                                    let paramsall = {
                                      memberIdNew:defultUser.member_id,
                                      submitPort:2,
                                      isExcessArr:isExcessArr ? JSON.stringify(isExcessArr) : null,
                                      userId: patientId,
                                      isChildrenmed: childrencode ? 1 : 2,
                                      hasHighmed: highMedsArray.length ? 1 : 2,
                                      isSpecialmed: specialMedicine.length ? 1 : 2,
                                      pharmacyName:pharmacyNameAll,
                                      pharmacyId: parseInt(pharmacyId),
                                      docterId: docterId,
                                      isAppointDoctor: selectdoctor == 2 ? 1 : 2,
                                      consultationFiles: imageUrl ? imageUrl : "",
                                      consultationAmount: 0,
                                      // consultationDetail: listchose0.toString(),
                                      isclassifyId: code1 == 1 ? 1 : 2,
                                      // isSurgery: code4 == 1 ? 1 : 2,
                                      isInternal:
                                        code1 == 1
                                          ? 2
                                          : code2 == 1 || code3 == 1
                                          ? 1
                                          : 2,
                                      consultationType: videocode ? "3" : "1",
                                      consultationStu: "-1",
                                      patientName: defultUser.patient_name,
                                      pregnancyStatus: defultUser.pregnancy_status,
                                      patientSex:
                                        defultUser.patient_sex == "男" ? 1 : 2,
                                      patientIdcardNum: defultUser.patient_id_card,
                                      patientBirthday:
                                        new Date(
                                          defultUser.patient_birthday
                                        ).getFullYear() +
                                        "-" +
                                        (new Date(
                                          defultUser.patient_birthday
                                        ).getMonth() +
                                          1 <
                                        10
                                          ? "0" +
                                            (new Date(
                                              defultUser.patient_birthday
                                            ).getMonth() +
                                              1)
                                          : new Date(
                                              defultUser.patient_birthday
                                            ).getMonth() + 1) +
                                        "-" +
                                        (new Date(
                                          defultUser.patient_birthday
                                        ).getDate() < 10
                                          ? "0" +
                                            new Date(
                                              defultUser.patient_birthday
                                            ).getDate()
                                          : new Date(
                                              defultUser.patient_birthday
                                            ).getDate()),
                                      patientCity: defultUser.patient_city,
                                      patientPhone: defultUser.patient_phone,
                                      pastHistory: defultUser.past_history,
                                      pastHistoryNote: defultUser.past_history_note,
                                      allergicHistory: defultUser.allergic_history,
                                      allergicHistoryNote:
                                        defultUser.allergic_history_note,
                                      familyGeneticHistory:
                                        defultUser.family_genetic_history,
                                      familyGeneticHistoryNote:
                                        defultUser.family_genetic_history_note,
                                      abnormalLiverFunction:
                                        defultUser.abnormal_liver_function,
                                      abnormalRenalFunction:
                                        defultUser.abnormal_renal_function,
                                      patientAge: defultUser.age,
                                      cityId: defultUser.city_id,
                                      patientProvince:defultUser.patient_province && defultUser.patient_province == patientsProvince ? defultUser.patient_province : patientsProvince,//就诊人所在省
                                      patientCities:defultUser.patient_cities && defultUser.patient_cities == patientsCities ? defultUser.patient_cities : patientsCities,//就诊人所在市
                                      patientCounty:defultUser.patient_county && defultUser.patient_county == patientsCounty ? defultUser.patient_county : patientsCounty,//就诊人所在区
                                      medicalnsuranceCoordination: coordinationcode,
                                      orderFormType: 2,
                                      guardianName: defultUser.guardianName,//监护人姓名
                                      guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
                                      bodyweight: defultUser.bodyweight,//体重
                                      bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
                                      bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
                                    };
                                    if (array1.length == 0) {
                                      this.props.history.push(`/drugselection`);
                                      Toast.show("请先选择药品");
                                    } else {
                                    //   submitOrdernews
                                    //  ({
                                    //     params,
                                    //     array1: array1,
                                    //     paramsall,
                                    //   })

                                    sumbitLogs({
                                      arrayMeds:array3,
                                      sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                      params,//基础参数
                                      array1:array1,//药品参数
                                      paramsall,//问诊参数
                                    }).then((reslogs)=>{
                                      // console.log('reslogs--3',reslogs);
                                    })

                                    subChaifen
                                      ({
                                        arrayMeds:array3,
                                        sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                        params,//基础参数
                                        array1:array1,//药品参数
                                        paramsall,//问诊参数
                                        // isCheck,//是否检查
                                        medIcdCustomArr:medIcdCustomArr,
                                      })
                                        .then((res2) => {
                                          // console.log(res2,'提交3-----------------------');
  
                                          if(res2.data == "包含超量商品不能拆分"){
                                            Toast.show("根据相关规定，您提交的处方药数量过多，请进行删减")
                                            setTimeout(() => {
                                              window.location.reload(); // 自动刷新页面
                                            }, 300); // 设置定时器
                                            return;
                                          }
  
                                          if(res2.data == "根据相关规定，您提交的处方药数量过多，请酌量进行删减"){
                                            Toast.show("根据相关规定，您提交的处方药数量过多，请酌量进行删减")
                                            setTimeout(() => {
                                              window.location.reload(); // 自动刷新页面
                                            }, 300); // 设置定时器
                                            return;
                                          }
                                          if(res2.data == "您购买商品已超出最大量，请进行删减"){
                                            Toast.show("您购买商品已超出最大量，请进行删减")
                                            setTimeout(() => {
                                              window.location.reload(); // 自动刷新页面
                                            }, 300); // 设置定时器
                                            return;
                                          }
                                          if ( res2 && res2.data && res2.data[0] && res2.data[0].consultationId ) {
                                            localStorage.setItem( "consultationId", res2.data[0].consultationId );
                                            localStorage.removeItem("selectmeds");
                                            localStorage.removeItem("listicd");
                                            localStorage.removeItem("illmiaosu");
                                            localStorage.removeItem("miaosu");
                                            localStorage.removeItem("selectdoctormiaosu");
                                            localStorage.removeItem("imageUrl");
                                            localStorage.removeItem("isUploaded");
                                            // localStorage.removeItem("videocode");
                                            this.setState({
                                              consultationId:
                                                // res2.data[2][0].consultationId,
                                                res2.data[0].consultationId,
                                              awaitdoc: 2,
                                            });
                                            // this.toAwaittime();
                                            if (res2.data.length > 5) {
                                              let count = res2.data.length - 4
                                              Toast.show(`您的问诊已被拆分成${count}个，请在进行中和等待中列表查看`);
                                              this.props.history.replace(`/offawait`);
                                            } else {
                                              this.toAwaittime();
                                            }
                                          }else{
                                            Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                            this.setState({
                                              tips: 0,
                                            });
                                          }
                                        })
                                        .catch((res) => {
                                          Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                          this.setState({
                                            // visible3:true,
                                            tips: 0,
                                          });
                                        });
                                    }
                                  // }
                                } else {
                                  this.setState(
                                    {
                                      // visible3:true,
                                      tips: 0,
                                    },
                                    () => {
                                      Toast.show("该店铺为非会员，无法进行问诊");
                                    }
                                  );
                                }
                              } else {
                                this.setState({
                                  // visible3:true,
                                  tips: 0,
                                });
                              }
                            },
                          },
                        ]
                      );
                    } else {
                      let res00 = await isSelfphamacy(
                        localStorage.getItem("pharmacyId")
                      );
  
                      if (res00.code == 1) {
                        if (
                          res00 &&
                          res00.data &&
                          res00.data[0] &&
                          (res00.data[0][0].is_self_operated == 1 ||
                            (res00.data[1] && res00.data[1][0].iscode == 1))
                        ) {
                          let res = await getOrderallways({
                            arrayMeds: array3,
                            sex: defultUser.patient_sex == "男" ? 1 : 2,
                          });
                            let paramsall = {
                              memberIdNew:defultUser.member_id,
                              submitPort:2,
                              isExcessArr:isExcessArr ? JSON.stringify(isExcessArr) : null,
                              userId: patientId,
                              hasHighmed: highMedsArray.length ? 1 : 2,
                              isSpecialmed: specialMedicine.length ? 1 : 2,
                              pharmacyName: pharmacyNameAll,
                              pharmacyId: parseInt(pharmacyId),
                              docterId: docterId,
                              isChildrenmed: childrencode ? 1 : 2,
                              isAppointDoctor: selectdoctor == 2 ? 1 : 2,
                              consultationFiles: imageUrl ? imageUrl : "",
                              isclassifyId: code1 == 1 ? 1 : 2,
                              // isSurgery: code4 == 1 ? 1 : 2,
                              isInternal:
                                code1 == 1 ? 2 : code2 == 1 || code3 == 1 ? 1 : 2,
                              consultationAmount: 0,
                              // consultationDetail: listchose0.toString(),
                              consultationType: videocode ? "3" : "1",
                              consultationStu: "-1",
                              patientName: defultUser.patient_name,
                              pregnancyStatus: defultUser.pregnancy_status,
                              patientSex: defultUser.patient_sex == "男" ? 1 : 2,
                              patientIdcardNum: defultUser.patient_id_card,
                              patientBirthday:
                                new Date( defultUser.patient_birthday ).getFullYear() + "-" +
                                (new Date(defultUser.patient_birthday).getMonth() + 1 < 10
                                  ? "0" +
                                    (new Date( defultUser.patient_birthday ).getMonth() + 1)
                                  : new Date( defultUser.patient_birthday ).getMonth() + 1) + "-" +
                                (new Date(defultUser.patient_birthday).getDate() < 10 ? "0" +
                                new Date(defultUser.patient_birthday).getDate() : 
                                new Date( defultUser.patient_birthday).getDate()),
                              patientCity: defultUser.patient_city,
                              patientPhone: defultUser.patient_phone,
                              pastHistory: defultUser.past_history,
                              pastHistoryNote: defultUser.past_history_note,
                              allergicHistory: defultUser.allergic_history,
                              allergicHistoryNote: defultUser.allergic_history_note,
                              familyGeneticHistory:
                                defultUser.family_genetic_history,
                              familyGeneticHistoryNote:
                                defultUser.family_genetic_history_note,
                              abnormalLiverFunction:
                                defultUser.abnormal_liver_function,
                              abnormalRenalFunction:
                                defultUser.abnormal_renal_function,
                              patientAge: defultUser.age,
                              cityId: defultUser.city_id,
                              patientProvince:defultUser.patient_province && defultUser.patient_province == patientsProvince ? defultUser.patient_province : patientsProvince,//就诊人所在省
                              patientCities:defultUser.patient_cities && defultUser.patient_cities == patientsCities ? defultUser.patient_cities : patientsCities,//就诊人所在市
                              patientCounty:defultUser.patient_county && defultUser.patient_county == patientsCounty ? defultUser.patient_county : patientsCounty,//就诊人所在区
                              orderFormType: 2,
                              medicalnsuranceCoordination: coordinationcode,
                              guardianName: defultUser.guardianName,//监护人姓名
                              guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
                              bodyweight: defultUser.bodyweight,//体重
                              bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
                              bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
                            };
                            if (array1.length == 0) {
                              this.props.history.push(`/drugselection`);
                              Toast.show("请先选择药品");
                            } else {
                              sumbitLogs({
                                arrayMeds:array3,
                                sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                params,//基础参数
                                array1:array1,//药品参数
                                paramsall,//问诊参数
                              }).then((reslogs)=>{
                                // console.log('reslogs--4',reslogs);
                              })

                              subChaifen
                              ({
                                arrayMeds:array3,
                                sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                                params,//基础参数
                                array1:array1,//药品参数
                                paramsall,//问诊参数
                                // isCheck,//是否检查
                                medIcdCustomArr:medIcdCustomArr,
                              })
                                .then((res2) => {
                                  // console.log(res2,'提交4-----------------------');
                                  
                                  if(res2.data == "包含超量商品不能拆分"){
                                    Toast.show("根据相关规定，您提交的处方药数量过多，请进行删减")
                                    setTimeout(() => {
                                      window.location.reload(); // 自动刷新页面
                                    }, 300); // 设置定时器
                                    return;
                                  }
  
                                  if(res2.data == "根据相关规定，您提交的处方药数量过多，请酌量进行删减"){
                                    Toast.show("根据相关规定，您提交的处方药数量过多，请酌量进行删减")
                                    setTimeout(() => {
                                      window.location.reload(); // 自动刷新页面
                                    }, 300); // 设置定时器
                                    return;
                                  }
                                  if(res2.data == "您购买商品已超出最大量，请进行删减"){
                                    Toast.show("您购买商品已超出最大量，请进行删减")
                                    setTimeout(() => {
                                      window.location.reload(); // 自动刷新页面
                                    }, 300); // 设置定时器
                                    return;
                                  }
                                  // Toast.info(res2.data)
                                  if ( res2 && res2.data && res2.data[0] && res2.data[0].consultationId ) {
                                    localStorage.setItem( "consultationId", res2.data[0].consultationId);
                                    
                                    localStorage.removeItem("selectmeds");
                                    localStorage.removeItem("listicd");
                                    localStorage.removeItem("illmiaosu");
                                    localStorage.removeItem("miaosu");
                                    localStorage.removeItem("selectdoctormiaosu");
                                    localStorage.removeItem("imageUrl");
                                    localStorage.removeItem("isUploaded");
                                    // localStorage.removeItem("videocode");
                                    this.setState({
                                      consultationId:
                                        res2.data[0].consultationId,
                                      awaitdoc: 2,
                                    });
                                    // this.toAwaittime();
                                    if (res2.data.length > 5) {
                                      let count = res2.data.length - 4
                                      Toast.show(`您的问诊已被拆分成${count}个，请在进行中和等待中列表查看`);
                                      this.props.history.replace(`/offawait`);
                                    } else {
                                      this.toAwaittime();
                                    }
                                  }else{
                                    Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                    this.setState({
                                      tips: 0,
                                    });
                                  }
                                })
                                .catch((res) => {
                                  Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                                  this.setState({
                                    // visible3:true,
                                    tips: 0,
                                  });
                                });
                            }
                          // }
                        } else {
                          this.setState(
                            {
                              tips: 0,
                            },
                            () => {
                              Toast.show("该店铺为非会员，无法进行问诊");
                            }
                          );
                        }
                      } else {
                        this.setState({
                          tips: 0,
                        });
                      }
                    }
                  }
                }
              }
            }
        }
      }else{
        if(medIsExcess.length>0){
          this.setState({
            visibleIsExcess:true,
          }) 
        }
      }
      
    }
   
  };

  //查询问诊单是否有医生接诊，有就跳转到聊天
  toAwaittime = async () => {
    const { consultationId, icdlists, patientId, docterId,videocode } = this.state;
    this.props.history.replace(`/drugselection`);
    if (docterId) {
      this.props.history.push(`/offawait?docterId=${docterId}`);
    } else {
      this.props.history.push(`/offawait`);
    }
  };

  //病情描述选择快捷
  changeKj = (value,index0, dis_id) => {
    const { list, miaosu } = this.state;
    let arr = [];
    let arr1 = [];

    // 找到点击的对象
    // 找到点击的对象以及具有相同 value 的对象
    // clickedItem:被点击的对象
    // sameValueItems:值相同的对象
    let flag =  list.every(item=>{
      return item.index === 1
    })
    let clickedItem =  list.find((item) => item.value === value && item.index0 === index0 && item.dis_id == dis_id );
    let sameValueItems =flag? list.filter((item) => item.value === value) : [clickedItem];

    if (clickedItem) {
      // 找到具有相同 index0 值的对象
      // clickedItem.index=2;
      let sameIndex0Items = list.filter((item) => item.index0 === clickedItem.index0);
      sameValueItems.forEach((item) => {
        item.index=2;
      });
      // 将具有相同 index0 值的对象的 index 设置为 1
      sameIndex0Items.forEach((item) => {
        if (item !== clickedItem) {
          item.index = 1;
          sameValueItems.forEach((i) => {
            list.forEach((j)=>{
              if(i.index0 == j.index0  && i.value !== j.value){
                j.index = 1;
              }
            })
          })
        }
      });
    }

    // 更新相关的状态
    list.forEach((item) => {
      if (item.index === 2) {
        arr.push(item.value);
        arr1.push(item.id);
      }
    });


    let nArr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
        currentIndex
      );
    });
    this.setState({ list, listchose: arr, icdlists: nArr1 }, () => {
      localStorage.setItem("listicd", JSON.stringify(list));
    });
  };
  //未维护药品病情描述搜索
  selectKj = async (item) => {
    const { selectmeds, icdname, defultUser,list } = this.state;
    let classifyId = 0;
    let index0 = 0;
    
    let selectmedsArr = selectmeds.map((item,n) => {
      if (!list.some(elem => elem.index0 === n)) {
        return { ...item, index3: 1 };
      }
      return item;
    });
    
    selectmedsArr.map((i, v) => {
      let medName = i.medComName ? i.medComName : i.medName;
      if (medName == item && i.index3 == 1) {
        classifyId = i.classifyId;
        index0 = i.index1;
      }
    });
    let res = await allClassifyid({
      classifyId,
      sex: defultUser.patient_sex == "男" ? 1 : 2,
      patientage:defultUser ? defultUser.age : 20,
    });
    let nArr1 = [];
    if (res && res.data && res.data[0] && res.data[0].length) { 
      // 其他所有疾病列表nArr1
      nArr1 = res.data[0].filter((currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.name === currentValue.name) ===
          currentIndex
        );
      });
    }

    this.setState({
      index0,
      visible4: true,
      icdList: nArr1.length ? nArr1 : res.data[0],
      classifyId,
      medname: item,
    });
  };

  //已经维护药品自定义选择病情描述
  selectKjCustom = async (item,key) => {
    const { selectmeds, icdname, defultUser,list,allotherMeds  } = this.state;
    let classifyId = 0;
    let index0 = 0;
    let selectmedsArr = selectmeds.map((item,n) => {
      if (!list.some(elem => elem.index0 === n)) {
        return { ...item, index3: n };
      }
      return item;
    });

    selectmedsArr.map((i, v) => {
      let medName = i.medComName ? i.medComName : i.medName;
      let indexCount = i.index3 ? i.index3 : i.index1
      if (medName == item && indexCount == v) {
        classifyId = i.classifyId;
        if(i.index1 != key){
          index0 = key;
        }else{
          index0 = i.index1;
        }        
      }
    });

    let res = await allClassifyid({
      classifyId,
      sex: defultUser.patient_sex == "男" ? 1 : 2,
      patientage:defultUser ? defultUser.age : 20,
    });
    let nArr1 = [];
    if (res && res.data && res.data[0] && res.data[0].length) {
      nArr1 = res.data[0].filter((currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.name === currentValue.name) ===
          currentIndex
        );
      });
    }

    this.setState({
      index0,
      visible4: true,
      icdList: nArr1.length ? nArr1 : res.data[0],
      classifyId,
      medname: item,
    });
  };


  // 搜索疾病
  toSearch = async () => {
    const { icdname, classifyId, defultUser } = this.state;
    let res = await allClassifyid({
      classifyId,
      icdname,
      sex: defultUser.patient_sex == "男" ? 1 : 2,
      patientage:defultUser ? defultUser.age : 20,
    });

    let nArr1 = [];
    if (res && res.data && res.data[0] && res.data[0].length) {
      nArr1 = res.data[0].filter((currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.name === currentValue.name) ===
          currentIndex
        );
      });
    }
    this.setState({
      icdList: nArr1.length ? nArr1 : res.data[0],
    });
  };
  
  // 选择自定义疾病
  toSelecticd = async (item) => {
    let { list, index0, allotherMeds, medname, onewsMeds,medIcdCustomArr } = this.state;

    // console.log('选择的自定义疾病：',item);

    let icdId = item.icd_id && JSON.parse(item.icd_id)[0];
    let id = { icdName: icdId.icd_name, icdValue: icdId.icd_value };
    let array = [];

    // 判断 allotherMeds 中是否已经存在该药品
    const exists = allotherMeds.some(item => item.name == medname);
    if (!exists) {
      allotherMeds.push({ name: medname, index: index0, selectcode: 1 });
    }

    onewsMeds.map((item) => {
      if (item != medname) {
        array.push(item);
      }
    });

    list.push({ 
      id: id, 
      index: 1, 
      index0, 
      value: item.name, 
      data: 0, 
      dis_id:item.disId,
      is_acute:item.is_acute,
      isCustomDisId:1, 
    });

    // 如果选择的疾病等于已存在的疾病，那么只组要将已维护的疾病选中，如果不存在的，则添加进去，
    // 重新选择后，将之前已选择的index变为1，
    // index0:表示药品索引，index:1 表示未选中，index:2 表示选中
    // 选的疾病  已经存在(没选中)，已经存在(选中)，

    let arr = [];
    let arr1 = [];
    const i = item.name;

    list.forEach((item,el) => {
      // 将list数组中index0等于索引（找到对应的药品），并且选择+选中的疾病名称不等于list中已经存在的，将他们的选中状态变为1
      if(item.index0 === index0 && item.value !== i) {
        item.index = 1;
      }
    });
 

    const seen = new Map();
    list = list.filter(item => {
      if (item.value == i && item.index0 == index0) {
        if (!seen.has(`${i}-${index0}`)) {
          seen.set(`${i}-${index0}`, item.index);
          return true;
        }
        return false;
      }
      return true;
    });

    // 需要先找到list下对应的index0中是否存在已经选中的疾病
    const index0Map = new Map();
    list.forEach(item => {
        if (!index0Map.has(item.index0)) {
            index0Map.set(item.index0, new Set());
        }
        const indexSet = index0Map.get(item.index0);
        indexSet.add(item.index);
    });

    // 看是否有 index0 下的 index 为 2
    const indexWithTwoExists = Array.from(index0Map.values()).some(indexSet => indexSet.has(2));
    // console.log(indexWithTwoExists); // 如果存在，则true，否则false


    // 如果indexWithTwoExists为真，那么就只需要改变index0等于item.index0,否则item.index  =  2

    list.map((item) => {
      if(item.value == i){    
        if(indexWithTwoExists){
          if (item.index0 == index0) {
            item.index = 2;
          }else{
            item.index = 1
          }
        }else{
          item.index  =  2;
        }
      }
      if (item.index == 2) {
        arr.push(item.value);
        arr1.push(item.id);
      }
    });

    let nArr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
        currentIndex
      );
    });

    this.setState(
      {
        visible4: false,
        list: list,
        listchose: arr,
        icdlists: nArr1,
        allotherMeds,
        onewsMeds: array,
      },
      () => {
        localStorage.setItem("listicd", JSON.stringify(list));
      }
    );
  };


  selectAgain = (name, value,disId,e) => {

    const { list, index0, allotherMeds, medname, onewsMeds } = this.state;
    
    let array = [];
    let array1 = [];
    onewsMeds.push(name);
    allotherMeds.map((item) => {
      if (item.name != name) {
        array.push(item);
      }
    });

    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i].value === value && list[i].dis_id === disId && i == e) {
        list[i].index=1
        list.splice(i, 1);
      }
    }


    list.map((item) => {
      if (item.value != value) {
        array1.push(item);
      }else if (item.value === value && item.dis_id) {
        array1.push(item);
      }
    });

    let arr = [];
    let arr1 = [];
    array1.map((item) => {
      if (item.index == 2) {
        arr.push(item.value);
        arr1.push(item.id);
      }
    });
    let nArr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
        currentIndex
      );
    });

    this.setState(
      {
        allotherMeds: array,
        onewsMeds,
        list: array1,
        listchose: arr,
        icdlists: nArr1,
      },
      () => {
        localStorage.setItem("listicd", JSON.stringify(array1));
      }
    );
  };

  setValue = (e) => {
    this.setState({
      icdname: e.target.value.replace(
        /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,
        ""
      ),
    });
  };

  reducecount = (item) => {
    const { selectmeds, coordinationcode, reviewercode, entitycode } =
      this.state;
    if (item.count > 1) {
      selectmeds.map((i) => {
        if (i.medId == item.medId) {
          i.count = i.count - 1;
        }
      });
      this.setState(
        {
          selectmeds,
        },
        () => {
          localStorage.setItem("selectmeds", JSON.stringify(selectmeds));
        }
      );
    } else {
      alert(
        "提示",
        selectmeds.length > 1
          ? "确认删除该药品吗?"
          : "确认删除该药品吗？确认后将跳转到首页重新选药",
        [
          {
            text: "取消",
            onPress: () => console.log("cancel"),
            style: "default",
          },
          {
            text: "确定",
            onPress: () => {
              let array = [];
              selectmeds.map((i) => {
                if (i.medId != item.medId) {
                  array.push(i);
                }
              });
              this.setState(
                {
                  selectmeds: array,
                },
                async () => {
                  let arr = [];
                  if (array.length) {
                    array.forEach((i) => {
                      let params = {};
                      // params.name = i.medComName;
                      params.name = i.medComName ? i.medComName : i.medName;
                      params.spec = i.medSpec;
                      arr.push(params);
                    });
                  }
                  //高危药品查询
                  let res01 = await getHighmeds({ allNews: arr });
                  if (res01 && res01.data && res01.data.length) {
                    this.setState({
                      highMeds: res01.data[1],
                      highMedsC: res01.data[0],
                      specialMedicine: res01.data[2],
                      highMedslength:
                        res01.data[1].length +
                        res01.data[0].length +
                        res01.data[2].length,
                    });
                  }
                  localStorage.setItem("selectmeds", JSON.stringify(array));
                  if (array.length == 0) {
                    localStorage.removeItem("selectmeds");
                    localStorage.removeItem("listicd");
                    this.props.history.push("/drugselection");
                    localStorage.removeItem("selectdoctormiaosu");
                  } else {
                    localStorage.removeItem("list");
                    localStorage.removeItem("listicd");
                    if (entitycode == 1) {
                      this.props.history.replace(
                        `/patientinformation?coordinationcode=${coordinationcode}&reviewercode=${reviewercode}&entitycode=${entitycode}`
                      );
                    } else {
                      this.props.history.replace(
                        `/patientinformation?coordinationcode=${coordinationcode}&reviewercode=${reviewercode}`
                      );
                    }
                    window.location.reload();
                  }
                }
              );
            },
          },
        ]
      );
    }
  };
  addcount = (item) => {
    const { selectmeds } = this.state;
    selectmeds.map((i) => {
      if (i.medId == item.medId) {
        i.count = i.count + 1;
      }
    });
    this.setState(
      {
        selectmeds,
      },
      () => {
        localStorage.setItem("selectmeds", JSON.stringify(selectmeds));
      }
    );
  };
  // 顶部返回首页
  toReturnHome = () =>{
    this.props.history.replace('/drugselection')
    localStorage.removeItem("videocode");
    localStorage.removeItem("obj");
  }
  //去支付
  topay = async () => {
    const {
      defultUser,
      index,
      docterId,
      selectdoctor,
      supervisionType,
      videocode,
      patientId,
      patientPhone,
      pharmacyId,
      coordinationcode,
      icdlists,
      selectmeds,
      tips,
      miaosu,
      listchose,
      pharmacyNameAll,
      imageUrl
    } = this.state;
    let params = {
      userId: parseInt(patientId),
      orderShipping: "1",
      phone: patientPhone,
      pharmacyId: parseInt(pharmacyId),
      orderPrice: 0,
      orderAmount: 0,
      supervisionType: parseInt(supervisionType),
    };
    let array1 = [];
    let array2 = [];
    let classifyId0 = "";
    let codeId = 1;
    let code0 = 1;
    let code1 = 2;
    let code2 = 2;
    let code3 = 2;
    let count2 = 0;

    selectmeds.forEach((item) => {
      if (
        item.classifyId == 56 ||
        item.classifyId == 6 ||
        item.classifyId == 9 ||
        item.classifyId == 10 ||
        item.classifyId == 105
      ) {
        code1 = 1;
      }
      if (item.classifyId == 114) {
        //注射药品
        code2 = 1;
        count2 += 1;
      }
      if (item.classifyId == 11 || item.classifyId == 116) {
        //内科
        code3 = 1;
      }
      let a = item.medComName + item.medSpec;
      array2.push(a);
      array1.push({
        medId: item.medId,
        medName: item.medName ? item.medName : item.medComName,
        medSpec: item.medSpec ? item.medSpec : null,
        medPacking: item.medPacking ? item.medPacking : null,
        medApproval: item.medApproval ? item.medApproval : null,
        medCompany: item.medCompany ? item.medCompany : null,
        medPrice: item.medPrice ? item.medPrice : 0,
        medQuantity: item.count,
        libraryId: item.libraryId ? item.libraryId : "0",
        medEnterprise: item.medEnterprise ? item.medCompany : null,
        thirdPartyId: item.thirdPartyId ? item.thirdPartyId : "0",
        medQrcode: item.medQrcode ? item.medQrcode : null,
        medComName: item.medComName ? item.medComName : item.medName,
        medDosageForm: item.medDosageForm ? item.medDosageForm : null,
        medType: item.medType ? item.medType : null,
        contacts: localStorage.getItem("contacts"),
        medApplySymptoms: item.medApplySymptoms ? item.medApplySymptoms : null,
        supervisionType: parseInt(item.supervisionType),
        packingUnit: item.packingUnit ? item.packingUnit : null,
        isOtc: item.medClassify == "1" ? 2 : 1,
        serial_number:0,
      });
    });
    addAllmeds({ ...params, array: array1 }).then((res) => {
      if (res && res.code == 1) {
        addchat({
          userId: patientId,
          pharmacyName: pharmacyNameAll,
          pharmacyId: parseInt(pharmacyId),
          docterId: docterId,
          isAppointDoctor: selectdoctor == 2 ? 1 : 2,
          consultationFiles: imageUrl ? imageUrl : "",
          consultationAmount: 0,
          consultationDetail: listchose.length
            ? listchose.toString() + (miaosu ? ";" + miaosu : "")
            : miaosu,
          isclassifyId: code1 == 1 ? 1 : 2,
          isInternal: code1 == 1 ? 2 : code2 == 1 || code3 == 1 ? 1 : 2,
          consultationType: videocode ? "3" : "1",
          consultationStu: "-2",
          patientName: defultUser.patient_name,
          patientSex: defultUser.patient_sex == "男" ? 1 : 2,
          patientIdcardNum: defultUser.patient_id_card,
          patientBirthday:
            new Date(defultUser.patient_birthday).getFullYear() +
            "-" +
            (new Date(defultUser.patient_birthday).getMonth() + 1 < 10
              ? "0" + (new Date(defultUser.patient_birthday).getMonth() + 1)
              : new Date(defultUser.patient_birthday).getMonth() + 1) +
            "-" +
            (new Date(defultUser.patient_birthday).getDate() < 10
              ? "0" + new Date(defultUser.patient_birthday).getDate()
              : new Date(defultUser.patient_birthday).getDate()),
          patientCity: defultUser.patient_city,
          patientPhone: defultUser.patient_phone,
          pastHistory: defultUser.past_history,
          pastHistoryNote: defultUser.past_history_note,
          allergicHistory: defultUser.allergic_history,
          allergicHistoryNote: defultUser.allergic_history_note,
          familyGeneticHistory: defultUser.family_genetic_history,
          familyGeneticHistoryNote: defultUser.family_genetic_history_note,
          abnormalLiverFunction: defultUser.abnormal_liver_function,
          abnormalRenalFunction: defultUser.abnormal_renal_function,
          patientAge: defultUser.age,
          cityId: defultUser.city_id,
          medicalnsuranceCoordination: coordinationcode,
          orderFormType: 2,
          orderNumber1: res.data[1][0].orderNumber,
          guardianName: defultUser.guardianName,//监护人姓名
          guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
          bodyweight: defultUser.bodyweight,//体重
          bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
          bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
        }).then((res2) => {
          if (res2 && res2.data && res2.data[2] && res2.data[2].length) {
            localStorage.setItem(
              "consultationId",
              res2.data[2][0].consultationId
            );

            this.setState(
              {
                consultationId: res2.data[2][0].consultationId,
                visible3: false,
                tips: 1,
              },
              async () => {
                if (icdlists.length) {
                  let res3 = await saveIcd({
                    consultationId: res2.data[2][0].consultationId,
                    electronicIcd: JSON.stringify(icdlists),
                    type: 1,
                  });
                }
                let orderName = "未续费店铺问诊支付";
                let b = new Date().getTime();
                window.location.href = `${zfUrl}/route?client_id=${code05}&pharmacyId=${localStorage.getItem(
                  "pharmacyId"
                )}&channel=&client_trade_no=${
                  res2.data[2][0].consultationNumber
                }&total_fee=${1}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
              }
            );
          }
        });
      }
    });
  };
  // 复制客服电话
  copykefuhone = () => {
    const spanText = document.getElementById("contactPhone").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.info("复制成功");
  };
  onscroll = (e) => {
    const { flag } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState();
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      5
    ) {
    }
  };

  toselectDoctor = async () => {
    const { coordinationcode, miaosu, entitycode,selectmeds,list,defultUser } = this.state;

    localStorage.setItem("list", JSON.stringify(list));

    let arr=[]
    list.map((i) => {
      if (i.index == 2) {
        arr.push(i.index0);
      }
    });
    arr = arr.filter((currentValue, currentIndex, selfArr) => {
      return selfArr.findIndex((x) => x === currentValue) === currentIndex;
    });
    if(arr.length<selectmeds.length){
      Toast.info("每个药品需选择一个疾病诊断");
          return;
    }
   
    
    const hasClassifyId9 = selectmeds.some((item) => item.classifyId === 9);
    if (hasClassifyId9) {

      // 儿童用药判断
      const filteredItems = selectmeds.filter(item => item.classifyId !== 9);
      if(filteredItems.length>0 && selectmeds.some(item => item.classifyId == 9)){
        // 获取筛选后的数据在 selectmeds 中的索引数组
        const indexArray = filteredItems.map(item => selectmeds.indexOf(item));

        const listEr = list.filter(item => indexArray.includes(item.index0));

        // 查询疾病对应的科室
        let disArrEr = [];
        listEr.map((i)=>{
            if(i.index == 2){
                disArrEr.push(i);
            }
        })

        let disDepartArrEr = [];
        for (let i = 0; i < disArrEr.length; i++) {
            let dis_id = disArrEr[i].dis_id;
            let res09 = await disDepartment({dis_id});
            disDepartArrEr.push(res09.data[0]);
        }    

        let resArrEr = [];
        for (let i = 0; i < disDepartArrEr.length; i++) {
            const filteredItems = disDepartArrEr[i].filter(item => item.dep_name === '内科');
            resArrEr = resArrEr.concat(filteredItems);  
        }

        if(resArrEr.length>0){
          Toast.show('所选药品暂仅支持快速问诊');
          return;
        } 
      }   


    }
    else {
      let disArr = [];
      list.map((i)=>{
        if(i.index == 2){
            disArr.push(i);
        }
      })       
      

      let disDepartArr = [];
      for (let i = 0; i < disArr.length; i++) {
        let dis_id = disArr[i].dis_id;
        let res04 = await disDepartment({dis_id});
        disDepartArr.push(res04.data[0]);
      }


      let resArr = [];
      for (let i = 0; i < disDepartArr.length; i++) {
        let innerArr = disDepartArr[i];  
        for (let j = 0; j < innerArr.length; j++) {
          if (innerArr[j].dep_name === '外科') {
            innerArr[j].dep_name = '中医科';
          }
        }

        let intersection = [];

        if (i === 0) {
          intersection = innerArr;
        } else {
          // 与前面的数组每项比较，找到交集的部分
          intersection = disDepartArr[i - 1].filter(item => {
            return innerArr.find(elem => elem.dep_name === item.dep_name);
          });
        }
      
        if (intersection.length === 0) {
          Toast.show('所选药品暂仅支持快速问诊');
          return;
        }
      }
    }



    this.props.history.replace(
      `/patientinformation?coordinationcode=${coordinationcode}&entitycode=${entitycode}&patientage=${defultUser.age}`
    );
    this.props.history.push(
      `/appointedmedicalpractitioner?coordinationcode=${coordinationcode}&entitycode=${entitycode}&patientage=${defultUser.age}`
    );
  };

  // 取消超量提示
  handleExcessCancel = async () => {
    const { sureBtn,visibleIsExcess,tips,sureBtnCount,isExcessArr } = this.state
    isClick = true
    this.setState({
      visibleIsExcess:false,
      sureBtn:false,
      sureBtnCount:0,
      isExcessArr:[],
    })
    window.location.reload();
  }
  // 确认超量提示
  handleExcessSure = () => {
    const { sureBtn,visibleIsExcess,tips,isExcessArr,sureBtnCount } = this.state
    isClick = true
    this.setState({
      tips:0,
      visibleIsExcess:false,
      sureBtn:true,
      isExcessArr:[],
      sureBtnCount:1
    }, async ()=>{
      this.toAwaitDoc();
    })
    
  }

  // 就诊凭证上传
  handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (!file || !file.type.startsWith('image/')) {
      // 如果选择的文件不是图片类型，给出错误提示
      Toast.show('仅支持图片上传')
      return;
    }

    zipImg(file).then(res => {
      let formData = new FormData()
      formData.append('file', res)
      uploadpic(formData).then(res1 => {
          if(res1&&res1.code == 2) {
            const imageUrl = res1.data; // 从服务器返回的数据中获取上传后的图片地址
            localStorage.setItem("imageUrl", imageUrl); 
            this.setState({ imageUrl , isUploaded: true }); // 将图片地址保存到组件状态中
            localStorage.setItem("isUploaded", true); 
          }
      }).catch(err => {
        console.error(err,123,'931=====');
      });
    })

  };

  handleYlimg =() =>{
    const {imageUrl} = this.state;
    this.setState(prevState => ({
      showPreview: !prevState.showPreview
    }));

    if (this.state.showPreview) {
      this.setState({
        showPreview: false
      });
    }


  }

  handleJzimg =() =>{
    const {imageUrl} = this.state;
    localStorage.removeItem("imageUrl");
    localStorage.removeItem("isUploaded");
    this.setState({
      imageUrl:'',
      isUploaded: false,
    })
  }

  // 告知书滚动到底部
  onscrollNotice = e => {
    const { visible2,scrolledToBottom } = this.state;
    // e.target.scrollHeight,//滚动元素的内容高度，包括未在视口内显示的部分
    // e.target.scrollTop, //元素顶部到可视区域顶部的距离，向下滚动时，值会增加，向上滚动时，会减少
    // e.target.clientHeight, //可见高度，用户当前能看到的高度，是固定的
    // console.log(e.target.scrollHeight ,e.target.scrollTop, e.target.clientHeight,'--------------');
    
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 3 && visible2) {
      // console.log('按钮可以使用');
      this.setState({
        scrolledToBottom:true
      })
    }

  }

  // 关闭告知书
  handleCloseVis = () => {
    clearInterval(this.interval);
    this.setState({
      visible2:false,
      isReadNotice:true,
      selectcode: 1,
    })
  }
  // 打开风险告知书
  handleOpenVis = ()=>{
    const {isReadNotice} = this.state
    this.setState({
      visible2:true
    })
    if(isReadNotice==false){
      this.startCountdown();
    }
  }

  render() {
    const {
      defultUser,
      coordinationcode,
      icdlists,
      isnonews,
      falseSex,
      list,
      allbeginMeds,
      allotherMeds,
      onewsMeds,
      isuse,
      videocode,
      icdList,
      icdname,
      visible6,
      reviewercode,
      isadverse,
      entitycode,
      selectmeds,
      selectdoctor,
      miaosu,
      allMeds,
      visible7,
      docterName,
      selectdoctormiaosu,
      selectcode,
      storeagain,
      visible5,
      visible2,
      visible3,
      visible4,
      listchose,
      allDoctors,
      arrindex1,
      notFoundItems,
      visiblecontype,
      visibleIsExcess,
      isExcessArr,
      isExessSplit,
      medIcdCustomArr,
      imageUrl,
      isUploaded,
      scrolledToBottom,
      timeNotice,
      isRunningTime,
      noticeBtnState,
      filterNotice,
      isReadNotice,
      isSetUseday,
      setUseday,
    } = this.state;

    let a = 0;
    selectmeds.length &&
      selectmeds.map((item) => {
        if (allbeginMeds.length) {
          allbeginMeds.map((i) => {
            if (i == item.medComName) {
              item.data = 1;
            }
          });
        }
        a += item.count;
      });

    
    const selectmedsWithIndex = selectmeds.map((item, index) => {
      item.index1 = index;
      return item;
    });

    let nArr1 = selectmeds    

    nArr1 = nArr1.map((item,n) => {
      if (!list.some(elem => elem.index0 === n)) {
        return { ...item, index2: 1 };
      }
      return item;
    });  


    let b = listchose.length ? listchose.toString().length : 0;
    let list1 = [];
    list.forEach((i) => {
      if (i.index == 2) {
        list1.push(i);
      }
    });
    list1 = list1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.value === currentValue.value) ===
        currentIndex
      );
    });

    let onewsMeds1 = [];
    onewsMeds.map((i) => {
      onewsMeds1.push({ value: i, index: 0 });
    });

    onewsMeds1.map((i) => {
      falseSex.map((j) => {
        if (i.value == j) {
          i.index = 1;
        }
      });
    });

    const isExcessArrList = isExcessArr.map(item => `${item.medComName}最多${item.maxDataLargeSplit}${item.packingUnit}`).join('、');

    return (
      <>
      
        <p
          className="patientinformation-outer"
          onScroll={(e) => this.onscroll(e)}
          ref={this.useRef}
        >
          {/* 超量提醒弹窗  visibleIsExcess */}
          {visibleIsExcess ? (
            <>
              {isExessSplit == 0 ? (
                <div className="cons-type-box-wrap">
                  <div className="cons-type-box">
                  <p className="cons-type-tit">提醒</p>
                  <p className="cons-type-tip">根据相关规定，您提交的处方药数量过多：<span className="tip-tuwen">{isExcessArrList}</span>；请确认是否继续咨询。</p>
                  <p className="cons-type">
                    <span className="type-one" onClick={() => this.handleExcessCancel()}>取消</span>
                    <span className="type-two" onClick={() => this.handleExcessSure()}>确认</span>
                  </p>
                  </div>
                </div>
              ) : 
                <div className="cons-type-box-wrap">
                  <div className="cons-type-box">
                  <p className="cons-type-tit">提醒</p>
                  <p className="cons-type-tip">根据相关规定，您提交的处方药数量过多：<span className="tip-tuwen">{isExcessArrList}</span>；请删减药品。</p>
                  <p className="cons-type">
                    <span className="type-three" onClick={() => this.handleExcessCancel()}>确定</span>
                  </p>
                  </div>
                </div>}
              
            </>
          ) : null}

          {visiblecontype ? (
            <>
              <div className="cons-type-box-wrap">
                <div className="cons-type-box">
                <p className="cons-type-tit">提醒</p>
                <p className="cons-type-tip">选择视频问诊等待时间可能较长，建议您使用<span className="tip-tuwen">图文问诊</span>更方便快捷。</p>
                <p className="cons-type">
                  <span className="type-one" onClick={() => this.toSelectvideotype()}>视频问诊</span>
                  <span className="type-two" onClick={() => this.toSelecttuwentype()}>图文问诊</span>
                </p>
                </div>
              </div>
            </>
          ) : null}

          {visible2 ? (
            <div
              className="notice-box"
              style={{ backgroundColor: "#fff" }}
            >
              <p 
                className="notice-box-content"
                // ref={this.noticeBoxRef} 
                onScroll={(e) => this.onscrollNotice(e)}
              >
                <h2 className="risk-title" style={{ marginTop: "10px" }}>
                  风险告知及患者知情同意书
                </h2>
                <p>亲爱的患者及家属朋友，您好！</p>
                <p>
                  欢迎来到中江云益健互联网医院问诊平台（下称“平台”或“我们”）。本平台上的互联网医院为患者（下称“患者”或“您”）提供包括但不限于互联网诊疗咨询、复诊开方、健康管理等服务（下称“在线诊疗”）。我们特将在线诊疗的相关重要事项告知您及您的家属，请认真阅读并理解，尤其是下划线部分文字。
                </p>
                <p>一、在线诊疗具有相当的局限性</p>
                <p>
                  由于在线诊疗过程中不能进行现场查体，对某些隐蔽性症状体征可能会有所疏忽，不能及时在线开展实验室生化检查和影像检查等，不能对患者急危重症采取快速现场应对；故医方在线诊疗服务中能够采取的有效措施较为有限。
                </p>
                <p>
                  在线诊疗中医生获取的信息完全有赖于您及您家属通过视频、图文的准确表述；故请您和您的家属务必配合医务人员进行必要、准确的病情描述。若因您及您家属对病情的描述不准确、不完整、不配合等，均有可能因对病情描述不当导致误诊误治的不利后果；若因此则需患者对此承担相应责任。
                </p>
                <p>二、疾病的自身风险</p>
                <p>
                  疾病的病程演变是复杂多变，且往往因为个体具有一定差异性。同时，常会有病情恶化、治疗不效、合并症加重、并发症出现，医疗意外等风险随时发生。有的可能是可以预见，有的是不能预见，有的是难以预见也难以预防的，这些都是疾病本身产生的风险。若您在在线诊疗中或在线诊疗后，若发现自身的症状和体征发生改变或恶化，或有明显的身体不适，请您立刻中断在线诊疗或停止相应治疗方案，并及时就近急诊就医，以免贻误病情。
                </p>
                <p>三、线上行为的当然效果</p>
                <p>
                  由于线上诊疗中，不同于线下现场诊疗，我们虽然已经对患者的实名信息予以核验，但平台难以时时核验患者端操作人的实人身份。对于患者端操作人开展病情告知、医患问答、发表选择和决定性意见、签署平台内的各种法律文件和办理在线诊疗中的流程手续，互联网医院将视同为该患者端操作人为患者本人，或已经取得患者本人充分授权，或作为患者的监护人有权操作；故该患者端操作人所进行的任何操作行为，对患者均具有相应的法律效力。
                </p>
                <p>
                  若患者端操作并非患者本人，为患者的授权人或患者的监护人，则应充分了解患者的症状体征，并尽量全面完整的提供相应的线下诊疗书面资料，以便接诊医生获取充分疾病信息并开展相应诊治措施。
                </p>
                <p>四、对个人健康信息的处理及保护</p>
                <p>
                  我们非常重视并致力于对个人健康信息的保护，且遵循合法、正当、必要的原则来收集您提交的各项个人健康信息，用于线上医生为您服务。我们采取符合业界标准、合理可行的方式对患者的个人健康信息进行存储及保护。
                </p>
                <p>五、在线就诊关于精麻类药品的说明</p>
                <p>
                  根据《四川省互联网医院管理办法》第三十条规定“互联网医院不得开展以下服务：首诊患者诊疗服务；危急重症患者诊疗服务；麻醉药品、精神药品等用药风险较高、有其他特殊管理规定的药品处方开具和配送（用于检查类且交付实体医疗机构使用的药品除外）。互联网医院不开具精麻类的药品。
                </p>
                <p> 六、其他在线诊疗的风险告知</p>
                <p>
                  疾病的治愈需要患者谨遵医嘱、健康管理方案，并积极配合。如果患者未完全遵医嘱，则可能导致诊疗效果不理想，甚至出现病情反复、恶化等不良后果。
                </p>
                <p>
                  患者自采药品的品牌、规格、性状、使用方式等可能影响本次诊疗方案的效果，同时还可能出现危害生命健康的风险。请遵医嘱，若医生告知此次处方不适用等情况时，请在所在药店问询店员更换药品。
                </p>
                <p>
                  本患者知情同意书未能一一列出所有风险，如果患者有疑问应与医生讨论。
                </p>
                <p>
                  您理解并同意，互联网医院将对您的个人健康信息等与病历相关的信息依法予以保存，该信息将保存至中江云益健互联网医院平台的健康档案中心（该名称如发生变化则适用新的名称）；您在享受中江云益健互联网医院平台内为您展示的其他服务时，您可以选择调用或使用在本次服务中的相关个人信息，帮助您便捷得享受其他服务；例如：您在使用中江云益健互联网医院平台提供的线上问诊服务时，可以调用互联网医院电子档案的就诊人的姓名、身份照号、性别、年龄等信息，便于您准确和及时得提供信息；您在使用线上购药的服务时，可以调用互联网医院及电子档案的就诊人的姓名、身份照号、性别、年龄、历史就诊信息或主诉描述等信息，便于您快速问诊或选择对应的药品；届时您将可以直接选择调用健康档案内的信息，或者自行填写新的信息，我们均按您的需求予以支持。
                </p>
                <p>七、您确认如下：</p>
                <p>
                  若您选择在本平台上的互联网医院享受在线诊疗服务，您确认您已经在线下实体医疗机构进行过诊疗且有明确临床诊断结论。您可以提供或上传您的相关的病历资料，愿意接受在线诊疗服务。您确认既往发生过与本次发病类似的常见病、慢性病症，且本次参与在线诊疗的症状和体征均和既往线下就诊情况基本一致，您不会向医生提供虚假、不实的信息。您愿意接受互联网医院提供旳在线诊疗服务，并协助平台上的互联网医院完善健康档案。
                </p>
                <p>
                  您确认已经完全理解并同意以上全部內容，已经知悉相关的风险，愿意接受在线诊疗服务，并知悉和同意本风险告知及知情同意书。
                </p>
              </p>
              {filterNotice ? (
                <>
                  <p className="notice-btn">
                    <Button 
                      block shape='rounded' color='primary'
                      style={{ width:'30vw'}}
                      onClick={this.handleCloseVis}
                    >
                      我已知晓
                    </Button>
                  </p>
                </>
              ) : (
                <>
                  <p className="notice-btn">
                    <Button 
                      block shape='rounded' color='primary'
                      style={{ width:'30vw'}}
                      disabled={timeNotice == 0 && scrolledToBottom ? false : true}
                      onClick={this.handleCloseVis}
                    >
                      我已知晓{timeNotice == 0 && !isRunningTime ? null : "(" + timeNotice + "s)"}
                    </Button>
                  </p>
                </>
              )}
              
            </div>
          ) : null}

          <>
            <div className="patientinformation-top-bgc">
              <p className="patientinformation-top">
                <span className="fanhui" onClick={() => this.toReturnHome()}>
                  <img className="left-jt1" src={fhicon1} />
                  <span>返回</span>
                </span>
                <span onClick={() => this.toAddpatient()}>选择/新增患者＞</span>
              </p>
              {!defultUser ? (
                <p
                  className="patientinformation-user"
                  onClick={() => this.toAddpatient()}
                >
                  <img className="add-img" src={jia2} />
                  <p className="add-news">添加患者信息</p>
                </p>
              ) : (
                <p
                  className={
                    defultUser.patient_sex == "男"
                      ? "patientinformation-user"
                      : "patientinformation-user patientinformation-user01"
                  }
                  onClick={() => this.toAddpatient()}
                  // style={{backgroundColor:'yellow'}}
                >
                  <p className="news-user-top-right">
                    {defultUser.relationship === null && (
                      <span
                        style={{
                          display: "inline-block",
                          width: "8px",
                          height: "14px",
                          // overflow: "hidden",
                        }}
                      >
                        &nbsp;
                      </span>
                    )}
                    {defultUser.relationship !== null && (
                      <span
                        style={{
                          backgroundColor: "#FFEED6",
                          padding: "2px 8px",
                          color: "#FFA625",
                          fontWeight: "500",
                          // fontSize: "12px",
                          borderRadius: "0 10px 0 10px",
                        }}
                      >
                        {defultUser.relationship}
                      </span>
                    )}
                  </p>
                  <p className="news-user-top">
                    <span className="news-user-left">
                      <img src={touxiang} />
                      <span>{defultUser.patient_name}</span>
                    </span>
                    <span className="news-user-right">
                      <span>{defultUser.patient_sex}</span>
                      <span>{defultUser.age}岁</span>
                      {defultUser.patientIdCard ? (
                        <span>{defultUser.patientIdCard}</span>
                      ) : (
                        <span style={{ backgroundColor: "#fff" }}></span>
                      )}
                    </span>
                  </p>
                  <p className="news-user-two">
                    <p>
                      疾病史：
                      {defultUser.past_history_note != "无" ? (
                        <span className="news-user-blue">
                          {defultUser.past_history_note}
                        </span>
                      ) : (
                        <span>{defultUser.past_history_note}</span>
                      )}
                    </p>
                    <p>
                      过敏史：
                      {defultUser.allergic_history_note != "无" ? (
                        <span className="news-user-blue">
                          {defultUser.allergic_history_note}
                        </span>
                      ) : (
                        <span>{defultUser.allergic_history_note}</span>
                      )}
                    </p>
                  </p>
                  <p className="news-user-two">
                    <p>
                      肝肾功能：
                      {defultUser.abnormal_liver_function == 0 ? (
                        "无异常"
                      ) : (
                        <span className="news-user-blue">异常</span>
                      )}
                    </p>
                    <p>
                      {defultUser.patient_sex == "女" ? (
                        <p>
                          怀孕情况：
                          {defultUser.pregnancy_status == 1
                            ? "未怀孕"
                            : defultUser.pregnancy_status == 2
                            ? "备孕中"
                            : defultUser.pregnancy_status == 3
                            ? "已怀孕"
                            : "哺乳期"}
                        </p>
                      ) : null}
                    </p>
                  </p>
                </p>
              )}
            </div>
            <div className="med-lists-title-way-med">
              {/* <p className="med-lists-title">
                <p className="med-lists-title-left">
                  <span className="dian"></span>
                  <span className="name">选择支付方式</span>
                </p>
                <p className="med-lists-title-right">
                  （医保统筹需填写身份证号码）
                </p>
              </p>
              <p className="medical-nsurance-coordination">
                <p
                  className="all-pay-ways"
                  onClick={() =>
                    this.setState({
                      coordinationcode: 2,
                    })
                  }
                >
                  <span className={coordinationcode == 2 ? "blue-pay" : ""}>
                    个人支付
                  </span>
                </p>
                <p
                  className="all-pay-ways"
                  onClick={() =>
                    this.setState({
                      coordinationcode: 1,
                    })
                  }
                >
                  <span className={coordinationcode == 1 ? "blue-pay" : ""}>
                    门诊统筹
                  </span>
                </p>
              </p>
              <p className="line"></p> */}
              <p className="is-has-entity-med">
                <span className="is-has-entity-left">是否已在实体医院就诊，本次为复诊</span>
                <span className="is-has-entity-right">
                  <span className="is-has-entity-right-radio">
                    <input
                      type="radio"
                      name="entity"
                      checked={entitycode == 1 ? true : false}
                      onClick={() =>
                        this.setState({
                          entitycode: 1,
                          visible6: reviewercode ? false : true,
                          
                        })
                      }
                    />
                    <span>是</span>
                  </span>
                  <span className="is-has-entity-right-radio">
                    <input
                      type="radio"
                      name="entity"
                      checked={entitycode == 2 ? true : false}
                      onClick={() =>
                        this.setState({
                          entitycode: 2,
                        })
                      }
                    />
                    <span>否</span>
                  </span>
                </span>
              </p>
              {entitycode != 1 ? (
                <p className="use-med-tips-med">
                  为保障您的用药安全，初诊请至线下医院就诊开药
                </p>
              ) : null}
            </div>
            {/*<p className='med-lists-title'>*/}
            {/*    <p className='med-lists-title-left'>*/}
            {/*        <span className='dian'></span>*/}
            {/*        <span className='name'>选择支付方式</span>*/}
            {/*    </p>*/}
            {/*    <p className='med-lists-title-right'>（医保统筹需填写身份证号码）</p>*/}
            {/*</p>*/}
            {/*<p className='medical-nsurance-coordination'>*/}
            {/*    <p className={coordinationcode == 2 ? 'coordination coordination1' : 'coordination coordinationc2'}*/}
            {/*       onClick={() => this.setState({*/}
            {/*           coordinationcode: 2*/}
            {/*       })}>*/}
            {/*        {coordinationcode == 2 ? <img src={icon1}/> : null}*/}
            {/*        <span>个人支付</span>*/}
            {/*    </p>*/}
            {/*    <p className={coordinationcode == 1 ? 'coordination coordination1' : 'coordination coordinationc2'}*/}
            {/*       onClick={() => this.setState({*/}
            {/*           coordinationcode: 1*/}
            {/*       })}>*/}
            {/*        {coordinationcode == 1 ? <img src={icon1}/> : null}*/}
            {/*        <span>门诊统筹</span>*/}
            {/*    </p>*/}

            {/*</p>*/}
            <p className="med-lists-title">
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">选择问诊方式</span>
              </p>
            </p>

            <p className="wenzhen-way">
              {videocode == 0 ? (
                <p
                  className="wenzhen-way-one wenzhen-way-one0"
                  onClick={() => this.toSelecttuwen()}
                >
                  <p className="select-way">
                    <span>选择 ✔</span>
                  </p>
                  <p className="tuwen">
                    <i className="iconfont icon-tuwenxiangqing" />
                    <span>图文问诊</span>
                  </p>
                  <p className="doc">医生根据病例问诊开方</p>
                </p>
              ) : (
                <p
                  className="wenzhen-way-one wenzhen-way-one00"
                  onClick={() => this.toSelecttuwen()}
                >
                  <p className="tuwen">
                    <i className="iconfont icon-tuwenxiangqing" />
                    <span>图文问诊</span>
                  </p>
                  <p className="doc">医生根据病例问诊开方</p>
                </p>
              )}
              {videocode == 1 ? (
                <p
                  className="wenzhen-way-one wenzhen-way-one1"
                  onClick={() => this.toSelectvideo()}
                >
                  <p className="select-way">
                    <span>选择 ✔</span>
                  </p>
                  <p className="video">
                    <i className="iconfont icon-shipinhuiyi" />
                    <span>视频问诊</span>
                  </p>
                  <p className="doc">医生面对面开方</p>
                </p>
              ) : (
                <p
                  className="wenzhen-way-one wenzhen-way-one11"
                  onClick={() => this.toSelectvideo()}
                >
                  <p className="video">
                    <i className="iconfont icon-shipinhuiyi" />
                    <span>视频问诊</span>
                  </p>
                  <p className="doc">医生面对面开方</p>
                </p>
              )}
            </p>
            <p className="med-lists-title">
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">药品清单</span>
                <span className="med-lists-title-right">
                  {selectmeds.length}类药品，共计{a}件
                </span>
              </p>
              <p className="med-lists-title-right-right" onClick={() => this.toReturnHome()}>
                <img className="chamed-lists-img" src={jia2} />
                <span className="name">添加药品</span>
              </p>
            </p>
            <div className="allmeds-list">
              {selectmeds.length
                ? selectmeds.map((item, index) => (
                    <>
                      <div className="one-med one-med1">
                        <p className="one-med-img">
                          <img
                            src={item.medPhoto ? item.medPhoto : defaultImage}
                          />
                        </p>
                        <p className="one-med-news">
                          <p className="one-med-news-name">
                            <span className="name">
                              {item.medName ? item.medName : item.medComName}
                            </span>
                            <span
                              className={
                                item.supervisionType == 1
                                  ? "zy zz1"
                                  : item.supervisionType == 2
                                  ? "xy zz1"
                                  : item.supervisionType == 3
                                  ? "cy zz1"
                                  : ""
                              }
                            >
                              {item.supervisionType == 1
                                ? "中药"
                                : item.supervisionType == 2
                                ? "西药"
                                : item.supervisionType == 3
                                ? "成药"
                                : ""}
                            </span>
                          </p>
                          <p className="one-med-news-spec">
                            {item.medClassify == "1" ? (
                              <span className="one-med-news-spec-rx">RX</span>
                            ) : null}
                            <span className="one-med-news-spec1">
                              {item.medSpec}
                            </span>
                          </p>
                          <p className="one-med-news-company">
                            {item.medCompany}
                          </p>
                        </p>
                        <p className="med-count">
                          <span
                            className="jian"
                            onClick={() => this.reducecount(item)}
                          >
                            -
                          </span>
                          <span className="count">{item.count}</span>
                          <span
                            className="jia"
                            onClick={() => this.addcount(item)}
                          >
                            +
                          </span>
                        </p>
                      </div>
                      {index + 1 < selectmeds.length ? (
                        <p className="xian"></p>
                      ) : null}
                    </>
                  ))
                : null}
              {storeagain ? (
                <p className="update-meds">
                  <button
                    onClick={() =>
                      this.props.history.push(
                        `/drugselection?medicalNsurancecoordination=${coordinationcode}`
                      )
                    }
                  >
                    修改药品
                  </button>
                </p>
              ) : null}
            </div>


            {/* 请选择线下已确诊疾病 */}
            <p className="med-lists-title-one" style={{ marginTop: 0 }}>
              <p className="ill-history-title">
                <span>
                  请选择线下已确诊疾病<span style={{ color: "red" }}>*</span>
                </span>
                <span className="tt">每个药品需选择一个疾病诊断</span>
              </p>
              <p className="med-lists-title-right"></p>
            </p>
            <div className="ill-history">
              {defultUser ? (
                <>
                  <p className="ill-history-kuaijie">

                    {allotherMeds.length ? (
                      <>
                        {allotherMeds.map((m,n)=>(
                          <>
                            <p className="allbegin-meds">
                              <i className="iconfont icon-yaopin" />
                              <span>{m.name}</span>
                            </p>
                            {list.map((i,v)=>(
                              <>
                                {i.index0 == m.index ? (
                                  <span
                                    className={
                                      i.index == 1 ? "nocolor" : "hascolor"
                                    }
                                    onClick={() => {
                                      this.changeKj(i.value,i.index0,i.dis_id);
                                      this.setState({ selectdoctor: 1,docterName:null });
                                    }}
                                  >
                                    {i.value}
                                  </span>
                                ) : null}
                              </>
                            ))}
                            <span
                              className="nocolor"
                              onClick={() => {
                                // console.log('选择+---1');
                                this.selectKjCustom(m.name,m.index)
                                this.setState({ selectedItemValueCus: m.name });
                              }}
                            >
                              选择+
                            </span>

                          </>
                        ))}
                      </>
                    ) : null}
                  </p>

                  {/* 这是未维护的 */}
                  {onewsMeds1.length ? (
                    <p className="ill-history-kuaijie">
                      {onewsMeds1.map((item, v) => (
                        <>
                          {nArr1.map((m, n) => (
                            <>
                              {m.name == item.value ? (
                                <>
                                  <p className="allbegin-meds">
                                    <i className="iconfont icon-yaopin" />
                                    <span>{m.medComName}</span>
                                  </p>
                                  {list.map((i, v) => (
                                    <>
                                      {i.index0 == n ? (
                                        <span
                                          className={
                                            i.index == 1
                                              ? "nocolor"
                                              : "hascolor"
                                          }
                                          onClick={() => {
                                            this.changeKj(i.value,i.index0, i.dis_id);
                                            this.setState({ selectdoctor: 1,docterName:null });
                                          }}
                                        >
                                          {i.value}
                                        </span>
                                      ) : null}

                                    </>
                                  ))}

                                  <span
                                    className="nocolor"
                                    onClick={() => {
                                      // console.log('选择+---3',item);
                                      this.selectKjCustom(item.value)
                                      this.setState({ selectedItemValueCus: item.value });
                                    }}
                                  >
                                    选择+
                                  </span>

                                </>
                              ) : null}
                            </>
                          ))}
                          <p className="allbegin-meds">
                            <i className="iconfont icon-yaopin" />
                            <span>{item.value}</span>
                          </p>
                          {item.index == 0 ? (
                            <span
                              className="nocolor"
                              onClick={() => {
                                // console.log('选择+---4');
                                this.selectKj(item.value)
                                this.setState({ selectedItemValue: item.value });
                              }}
                            >
                              选择+
                            </span>
                          ) : (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#ff5555",
                              }}
                            >
                              <i
                                style={{ fontSize: "14px", marginRight: "2px" }}
                                className="iconfont icon-gantanhao"
                              />
                              <span>
                                {defultUser && defultUser.patient_sex == "女"
                                  ? "该药品为男性用药，请删除重新选药"
                                  : "该药品为女性用药，请删除重新选药"}
                              </span>
                            </span>
                          )}
                           </>
                      ))}
                    </p>
                  ) : null}

                </>
              ) : (
                <>
                  <p className="add-patientill">请添加患者信息后再选择疾病</p>
                </>
              )}
            </div>


            {/* 医生选择 */}
            <div className="ill-history-doc">
              <p className="med-lists-title">
                <p className="med-lists-title-left">
                  <span className="dian"></span>
                  <span className="name">医生选择</span>
                </p>
                <p className="med-lists-title-right"></p>
              </p>
              <p className="medical-nsurance-coordination">
                  <p
                    className={
                      selectdoctor == 1
                        ? "coordination coordination1"
                        : "coordination coordinationc2"
                    }
                    onClick={() =>
                      this.setState({
                        selectdoctor: 1,
                        docterId: "",
                        docterName: "",
                      })
                    }
                  >
                    {selectdoctor == 1 ? <img src={icon1} /> : null}
                    <span className="doc-select">
                      <span>快速问诊</span>
                      <span>随机分配医生，快速接诊</span>
                    </span>
                  </p>
                  <p
                    className={
                      selectdoctor == 2
                        ? "coordination coordination1"
                        : "coordination coordinationc2"
                    }
                    onClick={() => {
                      this.toselectDoctor()
                    }}
                  >
                    {selectdoctor == 2 ? <img src={icon1} /> : null}
                    {!docterName ? (
                      <span className="doc-select">
                        <span>选择医生</span>
                        <span>指定医生接诊</span>
                      </span>
                    ) : (
                      <span className="doc-select">
                        <span>{docterName}</span>
                        <span>
                          {selectdoctormiaosu.length <= 10
                            ? selectdoctormiaosu
                            : selectdoctormiaosu.substr(0, 10) + "..."}
                        </span>
                      </span>
                    )}
                  </p>
              </p>  
            </div>


            {/* 病史资料 */}
            <p className="med-lists-title" style={{ marginTop: 0 }}>
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">病史资料</span>
              </p>
              <p className="med-lists-title-right"></p>
            </p>
            <div className="ill-history">
            <p className="ill-history-title-one">
                <span className="ill-history-title">上传就诊凭证</span>
                {/* <span className="chose-tian">(选填)</span> */}
                <span className="right-tip-upload">上传线下就诊历史处方</span>
              </p>
              <p className="pingzhentit">
                <p className="pingzhen">
                  <p className="pingzhen-cont">
                    {isUploaded ? (
                      <>
                        <>
                          <img
                            className="yulan-img"
                            src={imageUrl}
                            alt="预览图"
                            onClick={this.handleYlimg}
                          />
                          {this.state.showPreview && (
                            <div className="preview">
                              <img className="preview-image" src={this.state.imageUrl} alt="预览" onClick={this.handleYlimg} />
                            </div>
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        <label htmlFor="imageInput">
                          {imageUrl ? (
                            <img className="yulan-img" src={this.state.imageUrl} alt="" />
                          ) : (
                            <img src={pingzhen} alt="" />
                          )}
                        </label>
                        <input
                          id="imageInput"
                          type="file"
                          style={{ opacity: 0, position: 'absolute', top: 0, left: 0 }}
                          ref={this.fileInputRef}
                          onChange={this.handleImageUpload}
                        />
                      </>
                    )}
                  </p>
                </p>
                {imageUrl ? <p className="pingzhen-conts1"><img className="pzsc" src={zysc} onClick={this.handleJzimg} /></p> :null}
              </p>

              <p className="ill-history-title">是否使用过此类药物？</p>
              <p className="ill-history-ishave ill-history-ishave1">
                <span
                  className={isuse == 0 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isuse: 0 })}
                >
                  是
                </span>
                <span
                  className={isuse == 1 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isuse: 1 })}
                >
                  否
                </span>
              </p>
              <p className="xian-ziliao"></p>
              <p className="ill-history-title">是否对此类药品过敏？</p>
              <p className="ill-history-ishave ill-history-ishave1">
                <span
                  className={isadverse == 1 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isadverse: 1 })}
                >
                  是
                </span>
                <span
                  className={isadverse == 0 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isadverse: 0 })}
                >
                  否
                </span>
              </p>
            </div>


            <div className="patientinformation-bottom">
              <CheckboxItem
                checked={selectcode == 1}
                onClick={() => this.toSelect()}
                className="my-radio"
              />
              <p>
                我已阅读并同意{" "}
                <a onClick={this.handleOpenVis}>
                  《风险告知及患者知情同意书》
                </a>
                ，并确认以上信息填写属实。
              </p>
            </div>
            <p className="contact-service">
              <span>如有问题或建议，点击</span>
              <span
                onClick={() =>
                  this.setState({
                    visible5: true,
                  })
                }
              >
                联系我们
              </span>
            </p>


            <div className="patientinformation-sure">
              {(listchose.length
                ? listchose.toString() + (miaosu ? ";" + miaosu : "")
                : miaosu) &&
              selectcode &&
              isuse == 0 &&
              isadverse == 0 &&
              defultUser &&
              entitycode == 1 ? (
                <p
                  className="patientinformation-sure-bottom"
                  onClick={() => this.nextStep()}
                >
                  确认/咨询医生
                </p>
              ) : (
                <p
                  className="patientinformation-sure-bottom1"
                  onClick={() => this.toTips()}
                >
                  确认/咨询医生
                </p>
              )}
            </div>
          </>
          {/*:*/}
          {/*    awaitdoc == 2 ? <div className='await-doc-outer'>*/}
          {/*            <p className='await-doc-img'>*/}
          {/*                <img src={awaitDoc}/>*/}
          {/*            </p>*/}
          {/*            <p className='await-doc-xt'>系统正在为你匹配医生</p>*/}
          {/*            <p className='await-doc'>医生火速接诊中，请务离开此界面...</p>*/}
          {/*            <p className='await-quxiao' onClick={this.toquxiao}>取消问诊</p>*/}
          {/*        </div> :*/}
          {/*        awaitdoc == 3 ? <div className='await-doc-outer'>*/}
          {/*            <p className='await-doc-img'>*/}
          {/*                <img src={noDoc}/>*/}
          {/*            </p>*/}
          {/*            <p className='await-doc-xt'>很遗憾，暂时没有医生接诊</p>*/}
          {/*            <p className='await-doc await-doc1' onClick={this.toNewWz}>重新匹配医生</p>*/}
          {/*            <p className='await-quxiao1'>点击按钮可以从新匹配医生</p>*/}
          {/*        </div> : null}*/}

          {visible6 ? (
            <div className="Reviewer-sure-outer">
              <div className="Reviewer-sure-inner">
                <h2>问诊须知</h2>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    1.按照国家相关法律法规，本次医疗服务，仅向复诊与服用过所需药品并无不良反应的患者提供。{" "}
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    2.患者需在实体医院就诊且已有明确诊断，取得病例记录或诊断处方后方可使用平台服务。未在实体医院就诊，将无法使用该服务。
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    3.因您购买的药品为处方类药物，平台将收集您的用户信息(患者姓名，性别，联系方式，患者身份证号，用药信息，疾病史就诊凭证等)，前述信息您同意授权向互联网医院提供，且前述信息仅该药店及互联网医院可见。{" "}
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    4.本次您提供填写的信息将会被严格保密除为您提供诊疗服务使用，不会用作其他目的。
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    5.患者需承诺填写的内容均为真实有效，否则将承担一切法律责任。
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    6.本平台仅提供在线问诊开方服务，不提供药品销售，如用药过程中出现病情变化或其它不适症状，请立即停药并及时到医院就医。
                  </span>
                </p>
                <p
                  className="already-Reviewer"
                  onClick={() =>
                    this.setState({
                      visible6: false,
                      reviewercode: true,
                      // selectcode:1
                    })
                  }
                >
                  我已确定
                </p>
              </div>
            </div>
          ) : null}
          {visible5 ? (
            <div className="contact-complaint-outer">
              <div className="contact-complaint-inner">
                <img
                  className="close"
                  src={icon2}
                  onClick={() =>
                    this.setState({
                      visible5: false,
                    })
                  }
                />
                <h2>联系或投诉</h2>
                <p className="copy-phone">
                  <span>客服电话：</span>
                  <span id="contactPhone">19048012565</span>
                  <i
                    className="iconfont icon-baocun"
                    onClick={() => this.copykefuhone()}
                  />
                </p>
                <p className="tips-contact-complaint">
                  在使用中如有问题或疑问，您可以拨打电话联系我们。
                </p>
                <p className="chat-contact-complaint">
                  <img src={icon3} />
                </p>
                <p className="tips-contact-complaint">
                  添加客服微信，及时处理您的问题
                </p>
              </div>
            </div>
          ) : null}
          <Popup
            visible={visible4}
            onMaskClick={() => {
              this.setState({
                visible4: false,
              });
            }}
            bodyStyle={{
              height: "60vh",
              borderTopRightRadius: "14px",
              borderTopLeftRadius: "14px",
              overflow: "scroll",
            }}
          >
            <div className="icdoffline-popup">
              <h1 className="title" style={{ color: "#177FFF" }}>
                请选择确诊疾病
              </h1>
              <i
                className="iconfont icon-guanbi2fill"
                style={{
                  float: "right",
                  marginRight: "12px",
                  marginTop: "-18px",
                  fontSize: "18px",
                  color: "#AEAEAE",
                }}
                onClick={() => {
                  this.setState({
                    visible4: false,
                  });
                }}
              />
              <div className="home-header-icd">
                <div className="search-container">
                  <div className="flexr">
                    <img src={search} />
                    <input
                      id="iosinput"
                      ref={this.inputRef}
                      defaultValue={icdname}
                      style={{ lineHeight: "20px" }}
                      onChange={(e) => {
                        this.setValue(e)
                      }}
                      placeholder="请输入线下确诊的疾病"
                    />
                    {icdname ? (
                      <i
                        className="iconfont icon-guanbi2fill"
                        style={{
                          float: "right",
                          marginRight: "12px",
                          marginTop: "2px",
                          fontSize: "18px",
                          color: "#AEAEAE",
                        }}
                        onClick={() => {
                          this.setState({ icdname: "" })
                          if (this.inputRef.current) {
                            this.inputRef.current.value = "";
                          }
                          const { selectedItemValue,selectedItemValueCus } = this.state; // 获取存储的selectedItemValue
                          if (selectedItemValue) {
                            this.selectKj(selectedItemValue); // 调用selectKj方法并传递selectedItemValue
                          }
                          if(selectedItemValueCus){
                            this.selectKjCustom(selectedItemValueCus);
                          }
                        }}
                      />
                    ) : null}

                    <span
                      className="search-icd"
                      onClick={() => {
                        this.toSearch()
                      }}
                    >
                      搜索
                    </span>
                  </div>
                </div>
              </div>
              {icdList.length ? (
                icdList.map((item) => (
                  <p className="icd-name">
                    <p onClick={() => {
                      this.toSelecticd(item);
                      if (this.inputRef.current) {
                        this.inputRef.current.value = "";
                      }
                    }}>
                      <span>{item.name}</span>
                      <input type="radio" name="icdcode" />
                    </p>
                  </p>
                ))
              ) : (
                <Nodata type="疾病" />
              )}
            </div>
          </Popup>
          <Popup
            visible={visible3}
            onMaskClick={() => {
              this.setState({
                visible3: false,
              });
            }}
            bodyStyle={{
              Height: "40vh",
              borderTopRightRadius: "14px",
              borderTopLeftRadius: "14px",
            }}
          >
            <div className="offline-popup">
              <h1 className="title" style={{ color: "#177FFF" }}>
                支付提示
              </h1>
              <i
                className="iconfont icon-guanbi2fill"
                style={{
                  position: "absolute",
                  right: "12px",
                  top: "12px",
                  fontSize: "18px",
                  color: "#AEAEAE",
                }}
                onClick={() => {
                  this.setState({
                    visible3: false,
                  });
                }}
              />
            </div>
            <p className="pay-tips">该店铺为未签约用户，支付两元即可问诊</p>
            <p className="pay-money">￥2.00</p>
            <p className="pay-button">
              <button onClick={() => this.topay()}>确认支付</button>
            </p>
          </Popup>
        </p>
      </>
    );
  }
}
// []
