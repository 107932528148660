import React, { Component } from 'react'
import "./style.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import { Toast } from "antd-mobile-v5"
import { getlocode, getrecode, inspectonly, verificationcode, register, userlogin, getuserinfo,updatesxVerify } from "../../api/hospitalapi/login"
import { duration } from 'moment'
import localStorage from 'redux-persist/es/storage'
import { lte } from 'lodash'
export default class login extends Component {
	constructor() {
		super()
		this.state = {
			number: "",
			status: false,
			code: "",
			time: 0,
			type: 0,
			valid:true,//获取验证码状态位
			valid1:true,//登录或注册状态位
			timer:''
		}
	}

	getinputvalue = (e) => {
		if (e.target.value.length>11) {
			this.setState({
				number: e.target.value.toString().substr(0,11)
			})
		}else{
			this.setState({
				number: e.target.value
			})
		}
	}
	getcodenum = (e) => {
		this.setState({
			code: e.target.value
		})
	}
	//点击获取验证码
	code = async () => {
		const { number,status,time } = this.state
		
		const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
		if (status) {
			this.setState({
				type: 1
			})
			Toast.show({
				content: `请勿重复点击`,
				duration: 1000
			})
		} else {
			if (number) {
				if (reg_tel.test(number)) {
					this.setState({
						status: true,
					})
					// if (status) {
					// 	this.setState({
					// 		type: 1
					// 	})
					// 	Toast.show({
					// 		content: `请勿重复点击`,
					// 		duration: 1000
					// 	})
					// } else {
					const res01 = await inspectonly(number)
					// console.log(res01);
					if (res01.code == 1) {
						if (res01.data[0].length) {
							this.setState({
								type: 1
							})
							const res0 = await updatesxVerify(number)
							// console.log(res0.code == 1);
							if (res0.code == 1)
								this.setState({
									status: true,
								}, () => {
									getlocode({
										userPhone: number
									}).then(res => {
										// console.log(res);
										if (res.code == 1) {
											this.setState({
												time: 180
											}, () => {
												let code = this.state.time
												const timer = setInterval(() => {
													code -= 1
													this.setState({
														time: code,
														timer: timer
													})
													if (code == 0) {
														clearInterval(timer)
														// updatesxVerify(number).then(res=>{
														// 	console.log(res);
														// })
														this.setState({
															status: false,
															type: 0
														})
													}
												}, 1000);
											})
										}
									})
								})
						// }
					} else {
						this.setState({
							type: 0
						})
						const res0 = await updatesxVerify(number)
						// console.log(res0.code == 1);
						if (res0.code == 1) {
							this.setState({
								status: true,
							}, () => {
								getrecode({
									userPhone: number
								}).then(res => {
									if (res.code == 1) {
										this.setState({
											time: 180
										}, () => {
											let code = this.state.time
											const timer = setInterval(() => {
												code -= 1
												this.setState({
													time: code,
													timer: timer
												})
												if (code == 0) {
													clearInterval(timer)
													// updatesxVerify(number).then(res=>{
													// 	console.log(res);
													// })
													this.setState({
														status: false
													})
												}
											}, 1000);
										})
									} else {
										Toast.show({
											content: "您验证码发送太频繁，请稍后再试",
											duration: 800
										})
									}
								})
							})
						}
					}
					}
				} else {
					Toast.show({
						content: "请输入正确的手机号",
						duration: 800
					})
				}

			} else {
				Toast.show({
					content: "请输入手机号",
					duration: 800
				})
			}
		}
	}
	// 节流，一秒内只执行一次获取验证码操作
	getcode = () => {
		const { type, status, time,valid } = this.state
		if (!valid) {
			return ;
		}else{
			if (status) {
				Toast.show({
					content: `请${time}秒后点击获取`,
					duration: 1000
				})
			}else{
				this.setState({
					valid :false//工作期间状态码设置为false
				})
				setTimeout(() => {
					this.code()
					this.setState({
						valid :true
					})
				}, 500)
			}
		}
	}
	//点击登录或注册
	login = async () => {
		const { type, number, code } = this.state
		if (code) {
			if (type == 0) {
				const res = await verificationcode(number, code)
				// console.log(res);
				if (res.data[0].length&&res.data[0][0].smsCode==code) {
					const res1 = await register({
						phone: number
					})
					if (res1.code == 1) {
						userlogin({patientPhone:number,smsCode:code}).then(res=>{
							// console.log(res);
						})
						const res2 = await getuserinfo({
							patientPhone: number
						})
						if (res2.code == 1) {
							localStorage.setItem("patientPhone", res2.data[0][0].patientPhone)
							localStorage.setItem("patientAvatar", res2.data[0][0].patientAvatar)
							localStorage.setItem("patientName", res2.data[0][0].patientName)
							localStorage.setItem("patientId", res2.data[0][0].patientId)
							this.props.history.push("/")
						} else {
							Toast.show({
								content: "网络错误",
								duration: 800
							})
						}
					}
					// console.log(res1);
				} else {
					Toast.show({
						content: "验证码错误",
						duration: 800
					})
				}
			} else {
				const res = await verificationcode(number, code)
				// console.log(res);
				if (res.data[0].length&&res.data[0][0].smsCode==code) {
					userlogin({patientPhone:number,smsCode:code}).then(res=>{
						// console.log(res);
					})
					const res2 = await getuserinfo({
						patientPhone: number
					})
					if (res2.code == 1) {
						localStorage.setItem("patientPhone", res2.data[0][0].patientPhone)
						localStorage.setItem("patientAvatar", res2.data[0][0].patientAvatar)
						localStorage.setItem("patientName", res2.data[0][0].patientName)
						localStorage.setItem("patientId", res2.data[0][0].patientId)
						this.props.history.push("/")
					} else {
						Toast.show({
							content: "网络错误",
							duration: 800
						})
					}

					// const res1=await userlogin({
					// 	patientPhone:number,
					// 	smsCode:code
					// })
					// if(res1.code==1){

					// }
					// console.log(res1);
				} else {
					Toast.show({
						content: "验证码错误",
						duration: 800
					})
				}
			}
		} else {
			Toast.show({
				content: "请输入验证码",
				duration: 800
			})
		}

	}
	// 节流，一秒内只执行一次登录或注册操作
	userLogin = () => {
		const { type, number, code,valid1 } = this.state
		if (!valid1) {
			return ;
		}else{
			this.setState({
				valid1 :false//工作期间状态码设置为false
			})
			setTimeout(() => {
				this.login()
				this.setState({
					valid1 :true
				})
			}, 500)
		}
	}
	//点击重新获取验证码
	codeagain = async () => {
		const { type, number, time } = this.state
		if (time == 0) {
			// let res
			// if (res == 0) {
			// 	res = await getrecode({
			// 		userPhone: number
			// 	})
			// } else {
			const res0=await updatesxVerify(number)
			// console.log(res0.code==1);
			if (res0.code==1) {
				let	res = await getlocode({
					userPhone: number
				})
				// console.log(res,number);
				if (res.code == 1) {
					Toast.show({
						content: "获取成功",
						duration: 800
					})
					let code = 180
					const timer = setInterval(() => {
						code -= 1
						this.setState({
							time: code,
							timer:timer
						})
						if (code == 0) {
							clearInterval(timer)
						}
					}, 1000);
				}
			} else {
				Toast.show({
					content: "网络错误",
					duration: 800
				})
			}
		} else {
			Toast.show({
				content: `${time}秒后再次获取`,
				duration: 800
			})
		}
	}
	// 节流，一秒内只执行一次获取验证码操作
	getcodeagain=()=>{
		const { type, status, time,valid } = this.state
		if (!valid) {
			return ;
		}else{
			if (status) {
				Toast.show({
					content: `请${time}秒后点击获取`,
					duration: 1000
				})
			}else{
				this.setState({
					valid :false//工作期间状态码设置为false
				})
				setTimeout(() => {
					this.code()
					this.setState({
						valid :true
					})
				}, 500)
			}
		}
	}
	componentWillUnmount = () => {
        const { timer } = this.state
        window.clearInterval(timer)
    }
	render() {
		const { number, status, code, time } = this.state
		return (
			<>
				{status == false ?
					<div className='loginbox'>
						<div className='box-top'>
							<img src={icon1} alt="" onClick={() => this.props.history.goBack()} />	绑定手机号
						</div>
						<div className='box-num'>
							<input type="number" placeholder='请输入手机号' value={number} onChange={(e) => this.getinputvalue(e)} />
						</div>
						<div className='box-btn'>
							<button onClick={this.code}>获取短信验证码</button>
						</div>
						<div className='box-a'>
							完成注册即代表你同意 <a onClick={() => this.props.history.push("/privacyagreement")}>《平台隐私政策》</a>
							<a onClick={() => this.props.history.push("/agrenagreement")}>《软件许可及用户协议》</a>
						</div>
					</div> :
					<div className='loginbox'>
						<div className='box-top'>
							<img src={icon1} alt="" onClick={() => this.props.history.push("/mine")} />	绑定手机号
						</div>
						<div className='box-title1'>
							输入验证码
						</div>
						<div className='box-title2'>
							短信验证码已发送至   {number.substring(0, 3)}****{number.substring(7, 11)}
						</div>
						<div className='box-num'>
							<input type="number" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
							<span className='code' onClick={this.getcodeagain}>
								{time == 0 ? "重新获取" : `${time}秒后重新获取`}
							</span>
						</div>
						<div className='box-btn'>
							<button onClick={this.userLogin}>下一步</button>
						</div>
					</div>
				}

			</>

		)
	}
}
