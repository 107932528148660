export const baseUrl0="https://h5.sczyzk.com" 

// 微信授权登录跳转
export const baseUrl2="https://pay.sczyzk.com/pay/weixin/user"

// 问诊跳转
export const wzUrl="http://wz.sczyzk.com"

// 支付
export const zfUrl="https://pay.sczyzk.com/pay"
// export const zfUrl="https://gateway.yyj-health.com"

// 跳转 店铺未续费支付问诊
export const code05="5005"
//测试
// export const code05="7005"

// 以上正式测试不用切换





// 正式 服务器地址
// export const baseUrl="https://gateway.yyj-health.com"

// 跳转参数正式
// export const code="5000"

//正式 跳转地址
// export const jumpUrl="https://h5.yyj-health.com"

// 正式 跳转地址 (药师端)
// export const jumpUrl1="https://pha.yyj-health.com"

//正式 跳转地址(医生端)
// export const jumpUrl2="https://doc.yyj-health.com"




// //测试 服务器地址
// export const baseUrl="https://c-gateway.yyj-health.com"

// // 测试
// export const code="7000"
// //测试  跳转地址
// export const jumpUrl="https://c-h5.yyj-health.com"

// //测试  跳转地址(药师端)
// export const jumpUrl1="http://192.168.10.220:4004"

// // 测试  跳转地址(医生端)
// export const jumpUrl2="http://192.168.10.220:4003" 



// 后期使用双系统时
const visitType = localStorage.getItem('visitType') ? localStorage.getItem('visitType') : 2
// visitType 为 1：表示门特复诊  为 2：普通复诊

// 测试跳转地址
export const getJumpUrl = () => {
    if (visitType == 1) {
      return 'https://c-line.yyj-health.com'
    } else {
      return 'https://c-h5.yyj-health.com'
    }
}
export const jumpUrl = getJumpUrl()

console.log('jumpUrl',jumpUrl);

// 测试服务器地址
export const getBaseUrl = () => {
    if (visitType == 1) {
      return 'https://c-gateway.yyj-health.com'
    } else {
      return 'https://c-gateway.yyj-health.com'
    }
}
export const baseUrl = getBaseUrl()

// 测试
export const code="7000"
//测试跳转地址(药师端)
export const jumpUrl1="http://192.168.10.220:4004"
// 测试跳转地址(医生端)
export const jumpUrl2="http://192.168.10.220:4003" 




//ca  url正式
//ca  url测试
// export const code1="1000"
// export const code2="3000"