import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import "./cliniccard.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/jiankangka.png"
import {allClinic} from '../../../api/hospitalapi/electronic'
/*
*  就诊卡
* */

export default class cliniccard extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:''
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        allClinic(userId).then(res=>{
            // console.log(res)
            if (res&&res.data&&res.data[0].length){
                this.setState({
                    list:res.data[0]
                })
            }
        })
    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification=()=>{
        const {value1,value2} =this.state
        // console.log(value1,value2);
    }
    changevalue=(e,code)=>{
        if (code==1) {
            this.setState({
                value1:e.target.value
            })
        }else{
            this.setState({
                value2:e.target.value
            })
        }
    }
    tolook=(consultationId,prescribingId)=>{
        localStorage.setItem('detailContent0',prescribingId)
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo",
            params: {
                yktext: 1,
                detailContent:consultationId,
            }
        })
    }
    showNews=()=>{
        this.props.history.push("/healthcardnews")
    }
    render() {
        const { list} = this.state
        return (
            <div className='setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")} />   就诊卡
                </div>
                <div className='cliniccard-outer'>
                    {list?list.map(item=>(<div className='dz-jiuzhenka'>
                        {/*<img src={icon3}/>*/}
                        <p className='dz-jiankangka-name'>{item.patient_name}（就诊卡）</p>
                        <p className='dz-jiankangka-id'>就诊卡号 {item.card_number}</p>
                    </div>)):null}
                </div>
                <div className='add-jiankangka1' onClick={() => this.props.history.push("/cliniccard2")}>
                    <img src={icon2}/>
                    <span>就诊卡绑定</span>
                </div>
            </div>
        )
    }
}
