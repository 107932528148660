import React, { Component } from 'react';
import { Redirect, Route, Switch, HashRouter } from 'react-router-dom'
import { Empty } from 'antd-mobile-v5'
import search from "../../../static/img/home/search1.png";
import icon2 from "../../../static/img/icon/false1.png";
import fhhome from "../../../static/img/icon/fhhome.png";
import { ImagePicker, Toast, Popover, Button, Picker, List } from "antd-mobile"
import { uploaddoctor, uploadpic } from "../../../api/hospitalapi/upload"
import { getBase64Image } from '../../../api/config'
import { zipImg } from '../../../component/zipImage'
import { addWesternDrugs } from '../../../api/hospitalapi/drugs';

import { medLevel2Category, medLevel3Category, medLevel1Category } from '../../../api/request/med'

import  WesternMedicine  from './western-medicine'
import  TraditionalMedicine  from './traditional-medicine'
import './style.less'

export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            code: 1,
            dateList: '',
            browseList: '',
            page: 1,
            size: 20,
            countallNum: 0,//接诊总数
            countNum: 0,
            createTime: '',
            prescribingState: '',
            visible: false,
            visible1: false,
            visible2: false,
            flag: false,
            listsum: '',
            list3: [],
            newsstate: 1,
            prescribingId: '',
            fileList1: [],
            fileList2: [],
            evaluatePic1: [],
            evaluatePic2: [],

            proComName: "",     //药品通用名
            proClassify: "",    //药品规格
            proSpec: null,        //建议零售价
            originPrice: "",    //批准文号
            proApproval: "",    //生产厂家
            proCompany: "",     //计量单位
            unit: "",           //单位
            packingUnit: "",    //包装单位
            supervisionType: "",//药品类别
            proQrcode: "",      //药品一维码

            categoryId: null,                //一级分类id
            categoryName: "",              //一级分类名称
            classifyId: null,                //二级分类ID
            classifyName: "",              //二级分类名称
            classifysonId: null,             //三级分类ID
            classifysonName: "",           //三级分类id
            pharmacyId: "",                //店铺ID
            // createTime: "",                //创建时间
            data: [],
            medschange:1,  //1:西/中药添加   2:中药材添加
        }
    }


    

    render() {

        let {
            medname, evaluatePic1, fileList1, fileList2, evaluatePic2,
            proComName, proClassify, proSpec, originPrice, proApproval, proCompany, unit, packingUnit, supervisionType,medschange
        } = this.state

        
        return (
            <div>
                {/* 西药添加 */}
                <div className='home-header-addmed-outer11'>
                    <div className='top-tit'>
                        <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                        <span className='title-middle'>添加药品</span>
                        <div className='add-cord' onClick={() =>this.props.history.push('/ShowZhMedicine')}>
                            添加记录
                        </div>
                    </div>
                    <div className='home-header-addmed'>
                        <div className='home-header-addmed-inner'>
                        </div>                  
                        <div className="top-nav">
                            <div 
                                className={medschange == 1 ? "top-nav-left1" : "top-nav-left"} 
                                onClick={
                                    () =>this.props.history.push('/westernm')
                                   
                                }>西/中成药添加</div>
                            <div 
                                className={medschange == 2 ? "top-nav-right" : "top-nav-right1"} 
                                onClick={
                                    // () => this.props.history.replace('/traditionalm') 
                                    ()=>{  this.setState({medschange:2}) ;this.props.history.replace('/traditionalm') ;}
                                    
                                }>中药饮片添加</div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

