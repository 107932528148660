import React from 'react'
import {NavLink} from "react-router-dom"
import './style.less'
import '../../static/css/iconfont.css'
import {cartlistRequest} from "../../api/request/cart"

export default class FootNav extends React.Component{
    constructor(props){
        super(props);
        this.state={
            cartnum:null,
            userId :localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3,
            pharmacyId:localStorage.type=="1"?1:localStorage.type=="2"?1:3,
        }
    }
    componentDidMount=async()=>{
        //console.log(this.props.onRef);
        if (this.props.onRef) {
            this.props.onRef(this)
        }
       
        // this.getarrlist()
        // const {userId,pharmacyId}=this.state
        // const res=await cartlistRequest(userId,pharmacyId)
        // this.setState({
        //     cartnum:res.data[0].length
        // })
    }
    // getarrlist=async()=>{
    //     const {userId,pharmacyId}=this.state
    //     const res=await cartlistRequest(userId,pharmacyId)
    //     this.setState({
    //         cartnum:res.data[0].length
    //     })
    // }
    render(){
        const {cartnum,patientId}=this.state
        return(
            <div className="nav-footer">
                <div className='nav-footer-tab'>
                    <NavLink exact to='/' className='nav-a' activeClassName="selectednav nav-1">
                        <div></div>
                        <span>首页</span>
                    </NavLink>
                </div>
                <div className='nav-footer-tab'>
                    <NavLink exact to="/news" className='nav-b' activeClassName="selectednav nav-2">
                        <div></div>
                        <span>消息</span>
                    </NavLink>
                </div>
                {/* <div className='nav-footer-inner'>
                    <NavLink exact to="/health">
                        <div className='nav-3'></div>
                    </NavLink>
                </div> */}
                {/* <div className='nav-footer-tab'>
                    <NavLink exact to="/shopcart"  className='nav-d' activeClassName="selectednav nav-4">
                        <p className="nav-footer-tab-p">{cartnum}</p>
                        <div></div>
                        <span>购物车</span>
                    </NavLink>
                </div> */}
                <div className='nav-footer-tab'>
                    <NavLink exact to="/mine"  className='nav-e' activeClassName="selectednav nav-5">
                        <div></div>
                        <span>我的</span>
                    </NavLink>
                </div>
            </div>
        )
    }
}