import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import { Icon ,Tabs,Toast} from 'antd-mobile';
import { setTimeout } from 'core-js';
// import {CalendarUtil}  from './calendar';
import "./register.less"
import  GetCNDate  from "../../../component/Week";
// import { active } from 'sortablejs';
// import Angle from "./angle";
import moment from 'moment'
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/yuyue.png"
import icon3 from "../../../static/img/yy/noyuyue.png"
import icon4 from "../../../static/img/yy/ts.png"
import {prescribed} from '../../../api/hospitalapi/chat'
import {getdoctorlist} from "../../../api/hospitalapi/home";
import {getAllregister} from "../../../api/hospitalapi/rejister";
import getUrl from '../../../component/GetUrlParam'
import Nodata from "../../../component/Nodata";
/*
*  挂号
* */

export default class register extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:[],
            //划动X坐标
            startX:undefined,
            startY:undefined,
            //划动结束点坐标
            endX:undefined,
            endY:undefined,
            //当前或参数日期
            playDecoration:null,
            //是历类型以 true:月，false:周
            calendarType:false,
            //是否开始划动
            transFlag:false,
            //参数日期
            current:new Date(),
            //选中的日期
            activeDate:new Date(),
            //第几周
            activeWeekNo:undefined,
            activeIndex:1,
            a:0,
            data:'',
            data0:'',
            tabId:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16],
            tabs:[],
            nowdate:''
        }
    }
    componentDidMount =async () => {
        const {userId,tabId} =this.state
        let date=new Date()
        let arr=[]
        const startDate = moment().format('HH')
        const startDate1 =moment().add('days', 1).format('MM.DD')
        const week=moment(startDate1).format('d')
        // console.log(startDate,startDate1)
        this.setState({
            data:startDate1,
            data0:startDate1,
            nowdate:startDate
        })
        tabId.map(i=>{
            let params={}
            // const startDate = moment(date).day(i).format('YYYY-MM-DD ')
            const startDate = moment().add('days', i).format('YYYY-MM-DD')
            const startDate1 = moment().add('days', i).format('MM.DD')
            const week=moment(startDate).format('d')
            params.data=startDate1
            params.count=0
            if (week==1){
                params.title='周一'
            }else if (week==2){
                params.title='周二'
            }else if (week==3){
                params.title='周三'
            }else if (week==4){
                params.title='周四'
            }else if (week==5){
                params.title='周五'
            }else if (week==6){
                params.title='周六'
            }else if (week==0){
                params.title='周日'
            }
            arr.push(params)
        })
        // console.log(arr)
        this.setState({
            tabs:arr
        })
        // console.log(startDate,week)
        // const res2 = await getdoctorlist()
        // // console.log(111);
        // console.log(res2);
        // this.setState({
        //     list: res2.data[0]
        // })
        const res3 = await getAllregister({id:getUrl('id0', this.props.location.search)})
        // console.log(111);
        // console.log(res3);
        if (res3&&res3.data&&res3.data[0]){
            this.setState({
                list: res3.data[0],
            })
        }
    }

    toRegister=(docterId,id,quantity)=>{
        const { nowdate,data,data0 } = this.state
        // if (nowdate<15||data>data0){
            if (quantity>0){
                this.props.history.replace(`/register?id0=${getUrl('id0', this.props.location.search)}&index=${getUrl('index', this.props.location.search)}`)
                this.props.history.push(`/registrationconfirmation?docterId=${docterId}&id=${id}&id0=${getUrl('id0', this.props.location.search)}&index=${getUrl('index', this.props.location.search)}`)
            }else{
                Toast.info('医生预约人数已达到上限，无法继续预约')
            }
        // }else{
        //     Toast.info('当天15点后无法预约第二天的医生')
        // }
    }
    docName=(e)=>{
        this.setState({
            value1:e.target.value
        })
    }
    onSearch=async()=>{
        const { tabs,list,value1 } = this.state
        const res3 = await getAllregister({content:value1})
        // console.log(111);
        // console.log(res3);
        if (res3&&res3.data&&res3.data[0]){
            this.setState({
                list: res3.data[0],
            })
        }
    }
    renderContent = (tab, index) => {
        const { tabs,list,data } = this.state
        return (
            <div className='my-order-item'
                 onScroll={onscroll}>
                {tabs.length?tabs.map(item=>(<p className='date-all'>
                    <span>{item.title}</span>
                    <span>{item.date}</span>
                </p>)):null}
                {list.length?list.map(item=>(<div className='register-outer'>
                    <p className='register-pic'>
                        <img src={item.dcocterAvatar?item.dcocterAvatar:icon4}/>
                    </p>
                    <p className='register-details'>
                        <p className='register-mews'>
                            <p className='name'>{item.docterName}</p>
                            <p className='ys'>{item.title}</p>
                            <p className='ys'>{item.department}</p>
                            <p className='yuyue'>
                                <img src={item.quantity>0?icon2:icon3}/>
                                <span className={item.quantity>0?'yuyue-top':'yuyue-top1'}>
                                     <span className='yuyue-count'>{item.quantity}/{item.number}</span>
                                    <span onClick={() => this.toRegister(item.docterPhone)}>预约</span>
                                </span>
                            </p>
                        </p>
                        <p className='yuyue-hospital'>中江民瑞医院</p>
                        <p className='yuyue-sc'>擅长:{item.introduce}
                        </p>
                    </p>
                </div>)):null}
            </div>
        )
    }
    goBack=()=>{
        this.props.history.push(`/appointment?index=${getUrl('index', this.props.location.search)}`)
    }
    render() {
        const { list,a,tabs,data,data0} = this.state
        // console.log(list,data.split('.')[0],data0)
        let month
        switch (data.split('.')[0]) {
            case '01':
                month='一月'
                break;
            case '02':
                month='二月'
                break;
            case '03':
                month='三月'
                break;
            case '04':
                month='四月'
                break;
            case '05':
                month='五月'
                break;
            case '06':
                month='六月'
                break;
            case '07':
                month='七月'
                break;
            case '08':
                month='八月'
                break;
            case '09':
                month='九月'
                break;
            case '10':
                month='十月'
                break;
            case '11':
                month='十一月'
                break;
            case '12':
                month='十二月'
                break;
            default:
                break;
        }
        return (
            <React.Fragment>
            <div className='setbox queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.goBack()} />   预约挂号
                </div>
                {list.length?<>
                    <div className='register-head'>
                        <i className='iconfont icon-suosou' onClick={()=>this.onSearch()}></i>
                        <input type="text" placeholder='搜索擅长、科室、医生等' onChange={(e)=>this.docName(e)}/>
                    </div>
                    {/*<Tabs tabs={tabs}*/}
                    {/*      initialPage={tabId}*/}
                    {/*      tabBarTextStyle={{ fontSize: '14px', padding: '0', height: '30px' }}*/}
                    {/*      tabBarActiveTextColor="#0B1523"*/}
                    {/*      tabBarInactiveTextColor="#353A42"*/}
                    {/*      tabBarUnderlineStyle={{ border: 'none', transform: 'scaleX(0.25)' }}*/}
                    {/*      renderTabBar={props => <Tabs.DefaultTabBar {...props} page={7} />}*/}
                    {/*      onChange={this.tabChange}>*/}
                    {/*    {this.renderContent}*/}
                    {/*</Tabs>*/}
                    <div className='month'>{month}</div>
                    <div className='my-order-item'
                         onScroll={onscroll}>
                        <p className='date-all-outer'>
                            {tabs.length?tabs.map((item,index)=>(<p className={index==a?'date-all1':'date-all'} onClick={()=>{
                                this.setState({
                                    a:index,
                                    data:item.data
                                })}}>
                                <p>{item.title}</p>
                                <p>{item.data}</p>
                            </p>)):null}
                        </p>

                        {list.length?list.map(item=>(
                            <>
                                {item.dutyTime==data?<div className='register-outer'>
                                    <p className='register-pic'>
                                        <img src={item.dcocterAvatar?item.dcocterAvatar:icon4}/>
                                    </p>
                                    <p className='register-details'>
                                        <p className='register-mews'>
                                            <p className='name'>{item.docterName}</p>
                                            <p className='ys'>{item.title}</p>
                                            <p className='ys'>{item.name}</p>
                                            <p className='yuyue'>
                                                <img src={item.quantity>0?icon2:icon3}/>
                                                <span  className={item.quantity>0?'yuyue-top':'yuyue-top1'}>
                                            <span className='yuyue-count'>{item.quantity}/{item.number}</span>
                                            <span onClick={() => this.toRegister(item.docterId,item.id,item.quantity)}>预约</span>
                                        </span>
                                            </p>
                                        </p>
                                        <p className='yuyue-hospital'>中江民瑞医院</p>
                                        <p className='yuyue-sc'>擅长:{item.introduce}
                                        </p>
                                    </p>
                                </div>:null}
                            </>
                        )):null}
                    </div>
                </>:<Nodata type='挂号2'/>}

            </div>
    </React.Fragment>

    )
    }
}
