import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../../../../static/img/yy/fanhuihei.png"
import { changeuser } from "../../../../../api/hospitalapi/home"
import {Toast} from "antd-mobile"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            value: ""
        }
    }
    changevalue = (e) => {
        this.setState({
            value: e.target.value
        })
    }
    updata = async () => {
        const { value } = this.state
        const patientId = localStorage.getItem("patientId")
        if (patientId) {
            const res = await changeuser({
                patientId,
                patientName: value
            })
            if(res.code==1){
                localStorage.setItem("patientName",value)
                Toast.success("设置成功",1)
            }
        } else {
            Toast.fail("请先绑定手机号",1)
        }

    }
    gobank=()=>{
        window.history.back()
    }
    render() {
        const { value } = this.state
        return (
            <div className='usernamebox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>更换昵称</h1> <span onClick={this.updata}>保存</span>
                </div>
                <div className='box-name'>
                    <input type="text" value={value} placeholder='请输入昵称' onChange={(e) => this.changevalue(e)} />
                </div>
            </div>
        )
    }
}
