import React, {Component} from 'react';
import SignatureCanvas from 'react-signature-canvas'
// import Vconsole from "../../../api/vconsole";
import {imgToCanvas, rotateBase64Img, dealImage, dealParams,getBase64} from '../../../api/config'
import {
    updateStaff,
    selectPhaid,
    setStaffsi,
    selectPharmacistId,
    setPharmacistSi,
    updatePharmacistSi,
    addPhaMenber,
    selectPhainfo,
    selectPhaAl,
    updatePhaMenber,
    selectStfinfo,
    selectStfAl,
    updateStfMenber,
    addStfMenber
} from '../../../api/hospitalapi/offline'
import { Empty } from 'antd-mobile-v5'
import search from "../../../static/img/home/search1.png";
import icon2 from "../../../static/img/icon/false1.png";
import icon3 from "../../../static/img/icon/qm-update.png";
import icon4 from "../../../static/img/icon/tianjia.png";
import fhhome from "../../../static/img/icon/fhhome.png";
import icon9 from '../../../static/img/yy/qianming.png';
import icon5 from "../../../static/img/icon/cx.png";
import {ImagePicker, Toast,Popover,Button } from "antd-mobile"
import {uploaddoctor, uploadpic} from "../../../api/hospitalapi/upload"
import {getBase64Image} from '../../../api/config'
import {zipImg} from '../../../component/zipImage'
import getUrl from "../../../component/GetUrlParam";
import './style.less'
let isClick = false
export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            pharmacyId: localStorage.getItem("pharmacyId"),
            trimmedDataURL: null,
            img:'',
            pha_staff:"",
            // qmType:1,
            pha_pharmacist:"",
            isButtonClicked: false,
            // img0:'',
            pha_img:"",
            stf_img:"",
            isError: false
        }
    }

    componentDidMount = async () =>{
        if (getUrl("qmType", this.props.location.search)) {
            this.setState({
                qmType: getUrl("qmType", this.props.location.search),
            });
        }
        if (getUrl("img0", this.props.location.search)) {
            this.setState({
                img0: getUrl("img0", this.props.location.search),
            });
        }
        if (getUrl("id", this.props.location.search)) {
            this.setState({
                id: getUrl("id", this.props.location.search),
            });
        }

        // 查询对应药店药师信息
        let res11 = await selectPhainfo({
            id:getUrl("id", this.props.location.search)
        }) 
        if(res11.data[0].length){
            this.setState({
                pha_pharmacist:res11.data[0][0].pha_pharmacist,
                pha_img:res11.data[0][0].pharmacist_img,
            })
        }

        // 查询对应店员信息
        let res14 = await selectStfinfo({
            id:getUrl("id", this.props.location.search)
        }) 
        if(res14.data[0].length){
            this.setState({
                pha_staff:res14.data[0][0].pha_staff,
                stf_img:res14.data[0][0].staff_img,
            })
        }


    }

    handleInputChange = (e, arrayName) => {
        let value = e.target.value.trim().replace(/[^\u4e00-\u9fa5a-zA-Z]+/g, '');
        // console.log(value,'value');
        this.setState({ [arrayName]: [value] }, () => {
          localStorage.setItem(arrayName, value);
        },()=>{
            // console.log(this.state[arrayName]);
        });
    };

    trim = () => {        
        if (isClick) {
            return; // 在节流期间内已触发过保存操作，直接返回，不执行保存操作
        }

        const { pha_staff,stf_img,isError } = this.state

        if (!pha_staff[0]) {
            Toast.fail('请输入姓名');
            this.setState({ isError: true });
            return;
        }
        this.setState({ isError: false });
        
        // 查询是否已存在该店员签名
        selectStfAl({
            id: getUrl("id", this.props.location.search)
        }).then(res15=>{
            // console.log(res15.data[0].length,'121=========');
            if(res15.data[0].length != 0){
                
                if(this.state.pharmacyId == 170000){
                    Toast.show("该店铺暂时不能修改店员签名")
                    this.props.history.push(`/setsignature?newsstate=${2}`)
                }else{
                    // 修改店员签名及信息
                    updateStfMenber({
                        id:getUrl("id", this.props.location.search),
                        pha_staff:pha_staff,
                        staff_img:getUrl("img0", this.props.location.search) ? getUrl("img0", this.props.location.search) : stf_img,
                    }).then(res16=>{
                        // console.log(res16,'131======')
                        if(res16 && res16.code==1){
                            Toast.show("签名修改成功")
                            this.props.history.push(`/setsignature?newsstate=${2}`)
                        }
                    })
                }
            }else{
                if(this.state.pharmacyId == 170000){
                    Toast.show("该店铺暂时不能添加店员签名")
                    this.props.history.push(`/setsignature?newsstate=${2}`)
                }else{
                    // 新增店员签名
                    // console.log(getUrl("img0", this.props.location.search),'139======');
                    addStfMenber({
                        pharmacyId:this.state.pharmacyId,
                        pha_staff:pha_staff,
                        staff_img:getUrl("img0", this.props.location.search),
                    }).then(res17=>{
                        // console.log(res17,'145=====')
                        if(res17 && res17.code==1){
                            Toast.show("签名保存成功")
                            this.props.history.push(`/setsignature?newsstate=${2}`)
                        }
                    })
                }
            }
        })
        

        isClick = true;
        setTimeout(() => {
            isClick = false; // 节流时间过后，将 isClick 设为 false，可以再次触发保存操作
        }, 500); // 3000毫秒为节流的时间间隔，根据需要进行调整
    };


    trims = () => {
        
        if (isClick) {
            return; // 在节流期间内已触发过保存操作，直接返回，不执行保存操作
        }
        const { pha_pharmacist,pha_img,isError } = this.state

        // console.log(pha_pharmacist,getUrl("img0", this.props.location.search),'197========');

        // console.log(pha_pharmacist,'169=========');
        if (!pha_pharmacist[0]) {
            Toast.fail('请输入姓名');
            this.setState({ isError: true });
            return;
        }
        this.setState({ isError: false });

        // 查询是否已存在该药店药师
        selectPhaAl({
            id: getUrl("id", this.props.location.search)
        }).then(res12=>{
            // console.log(res12.data[0].length,'227=========');
            if(res12.data[0].length != 0){
                if(this.state.pharmacyId == 170000){
                    Toast.show("该店铺暂时不能修改签名")
                    this.props.history.push("/setsignature")
                }else{
                    // 修改药店药师及信息
                    updatePhaMenber({
                        id:getUrl("id", this.props.location.search),
                        // pharmacyId:this.state.pharmacyId,
                        pha_pharmacist:pha_pharmacist,
                        pharmacist_img:getUrl("img0", this.props.location.search) ? getUrl("img0", this.props.location.search) : pha_img,
                    }).then(res13=>{
                        // console.log(res13,'237======')
                        if(res13 && res13.code==1){
                            Toast.show("签名修改成功")
                            this.props.history.push("/setsignature")
                        }
                    })
                }
                
            }else{
                if(this.state.pharmacyId == 170000){
                    Toast.show("该店铺暂时不能添加签名")
                    this.props.history.push("/setsignature")
                }else{
                    // 新增药店药师签名
                    // console.log(getUrl("img0", this.props.location.search),'245======');
                    addPhaMenber({
                        pharmacyId:this.state.pharmacyId,
                        pha_pharmacist:pha_pharmacist,
                        pharmacist_img:getUrl("img0", this.props.location.search),
                    }).then(res10=>{
                        // console.log(res10,'250=====')
                        if(res10 && res10.code==1){
                            Toast.show("签名保存成功")
                            this.props.history.push("/setsignature")
                        }
                    })
                }
            }
        })
        

        isClick = true;
        setTimeout(() => {
            isClick = false; // 节流时间过后，将 isClick 设为 false，可以再次触发保存操作
        }, 500); // 3000毫秒为节流的时间间隔，根据需要进行调整
        
    };


    render() {
       let { 
        trimmedDataURL,
        qmType,
        img0,
        pha_pharmacist,
        pha_img,
        stf_img,
        isError
        }= this.state
        return (
            <div >
                <div className='home-header-addmed-outer'>
                    <div className='top-tit'>
                        <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                        {qmType == 2 ? <span className='title-middle'>药店药师签名</span> : <span className='title-middle'>店员签名</span>}
                    </div>

                    {qmType == 1 && (
                        <>
                            <div className='middle-three1'>
                                {/* <p className='member1-tit1'>
                                    <span className='yuan1'></span>
                                    <span>店员签名</span>
                                </p> */}

                                <div className='tiaopeiqm'>
                                    {/* <div className="information-item"> */}
                                    <div className={!isError ? 'information-item' : 'information-error'}>
                                        <div className='name'>
                                            <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                                            <span>姓名</span>
                                        </div>
                                        <div className='name2'>
                                            <input
                                                type="text"
                                                placeholder="请输入姓名"
                                                // defaultValue={name}
                                                defaultValue={this.state.pha_staff} 
                                                onChange={(e) => this.handleInputChange(e, "pha_staff")}
                                                autoCorrect="off"
                                                autoComplete="off"
                                                autoCapitalize="none"
                                            />
                                        </div>
                                    </div>
                                    {/* <p className="xian"></p> */}
                                    <div className='qm-mianban'>
                                        <div className='qm'>
                                            <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                                            <span>签名</span>
                                        </div>
                                        <div className='qm-right' onClick={()=>this.props.history.push(`/setsignnaturedetail?qmType=${1}&id=${getUrl("id", this.props.location.search)}`)}>
                                            <img src={icon5} />
                                            <span>重新签名</span>
                                        </div>
                                    </div>
                                    <div className='qmmb' 
                                        onClick={()=>this.props.history.push(`/setsignnaturedetail?qmType=${1}&id=${getUrl("id", this.props.location.search)}`)}>
                                        {img0 ? <img src={img0} /> : <img src={stf_img}/>}
                                    </div>
                                </div>

                                <div className='baocun' onClick={this.trim}>保存</div>
                            </div>
                        </>
                    )}

                    {qmType == 2 && (
                        <>
                            <div className='middle-three2'>
                                <div className='tiaopeiqm'>
                                    {/* <div className="information-item"> */}
                                    <div className={!isError ? 'information-item' : 'information-error'}>
                                        <div className='name'>
                                            <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                                            <span>姓名</span>
                                        </div>
                                        <div className='name2'>
                                            <input
                                                type="text"
                                                placeholder="请输入姓名"
                                                // defaultValue={name}
                                                defaultValue={this.state.pha_pharmacist} 
                                                onChange={(e) => this.handleInputChange(e, "pha_pharmacist")}
                                                autoCorrect="off"
                                                autoComplete="off"
                                                autoCapitalize="none"
                                            />
                                        </div>
                                    </div>
                                    {/* <p className="xian"></p> */}
                                    <div className='qm-mianban'>
                                        <div className='qm'>
                                            <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                                            <span>签名</span>
                                        </div>
                                        <div className='qm-right' onClick={()=>this.props.history.push(`/setsignnaturedetail?qmType=${2}&id=${getUrl("id", this.props.location.search)}`)}>
                                            <img src={icon5} />
                                            <span>重新签名</span>
                                        </div>
                                    </div>
                                    <div className='qmmb' 
                                        onClick={()=>this.props.history.push(`/setsignnaturedetail?qmType=${2}&id=${getUrl("id", this.props.location.search)}`)}
                                    >
                                        {img0 ? <img src={img0} /> : <img src={pha_img}/>}
                                    </div>
                                </div>

                                <div className='baocun' onClick={this.trims}>保存</div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            
        );
    }
}

