import React, { Component } from 'react'
import {DatePicker, } from "antd-mobile-v5"
import { Tabs,Switch ,Toast,Modal} from 'antd-mobile'
import "./style.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/qiehuan.png"
import icon3 from "../../../static/img/yy/ghselect.png"
import icon4 from "../../../static/img/yy/quxiaoselect.png"
import icon5 from "../../../static/img/yy/datedown.png"
import {oneRecordDetails,quxiaoRecord} from '../../../api/hospitalapi/rejister'
import {refundApply} from '../../../api/hospitalapi/electronic'
import getUrl from '../../../component/GetUrlParam'
import moment from 'moment'
const alert = Modal.alert
/*
*  我的挂号
* */

export default class historicaldetails extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            visible: false,
            value1: "",
            value2: "",
            userId: localStorage.getItem("patientId"),
            list: '',
            hostoryDate: '',
            outpatientNumber: ''
        }
    }

    componentDidMount = () => {
        const {userId} = this.state
        if (getUrl('outpatientNumber', this.props.location.search)) {
            this.setState({
                outpatientNumber:getUrl('outpatientNumber', this.props.location.search)
            },()=>{
                oneRecordDetails(getUrl('outpatientNumber', this.props.location.search)).then(res => {
                    // console.log(res)
                    if (res && res.data && res.data[0]) {
                        this.setState({
                            list: res.data[0][0]
                        })
                    }
                })
            })
        }
    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification = () => {
        const {value1, value2} = this.state
        // console.log(value1, value2);
    }
    changevalue = (e, code) => {
        if (code == 1) {
            this.setState({
                value1: e.target.value
            })
        } else {
            this.setState({
                value2: e.target.value
            })
        }
    }
    toRefund=()=>{
        const {list, outpatientNumber} = this.state
        if ((new Date(list.clinicTime+" 00:00:00")).getTime()-(new Date()).getTime()>32400000){
            alert('确认取消预约吗？', '', [
                { text: '取消', onPress: () => console.log('cancel')},
                {
                    text: '确认', onPress: () => {
                        quxiaoRecord({id:list.id,outpatientNumber}).then(res0=>{
                            // console.log(res0)
                            if (res0&&res0.code==1){
                                oneRecordDetails(outpatientNumber).then(res => {
                                    // console.log(res)
                                    if (res && res.data && res.data[0]) {
                                        this.setState({
                                            list: res.data[0][0]
                                        },()=>{
                                            let params={
                                                client_id:5000,
                                                client_trade_no:outpatientNumber,
                                                total_fee:res.data[0][0].cost,
                                                timestamp: moment().format('YYYYMMDDHHmmss'),
                                            }
                                            refundApply(params).then(res1=>{
                                                // console.log(res1)
                                                if (res1&&res1.code==1){
                                                    Toast.info('挂号订单取消成功')
                                                }
                                            })
                                        })
                                    }
                                })
                            }
                        })
                    }
                },
            ])
        }else{
            Toast.info('预约前一天15点后无法取消预约')
        }
    }
    render() {
        const {list, hostoryDate} = this.state
        return (
            <div className='setbox '>
                <div className='box-top'>
                    <img src={icon1} alt=""
                         onClick={() => this.props.history.push("/mine/historicalregistration")}/> 挂号详情
                </div>
                <div className='historicaldetails-outer'>
                    {list?<>
                        {list && list.state == 1 ?<div className='gh-select'>
                                <img src={icon3}/>
                                <p>预约成功</p>
                            </div>:
                            list && list.state == 2 ?<div className='gh-select'>
                                    <img src={icon3}/>
                                    <p>已完成</p>
                                </div>:
                                list && list.state == 3 ?<div className='gh-select'>
                                    <img src={icon4}/>
                                    <p>已取消</p>
                                </div>:null}
                        <div className='historydetails-body'>
                            <p className='history-title'>
                                <p className='history-title-left'>
                                    <span>{list.name}</span>
                                    <span>{list.docterName}</span>
                                </p>
                                <p className='history-title-right'></p>
                            </p>
                            <p className='history-list'><span>医生姓名</span><span>{list.docterName}</span></p>
                            <p className='history-list'><span>职称</span><span>{list.docterTitle}</span></p>
                            <p className='history-list'><span>就诊科室</span><span>{list.name}</span></p>
                            <p className='history-list'><span>医院名称</span><span>中江民瑞医院</span></p>
                            <p className='history-list'><span>预约时间</span><span>{list.clinicTime}</span></p>
                            <p className='history-list'><span>费用</span><span>￥{(list.cost / 100).toFixed(2)}</span></p>
                            <p className='history-list'><span>就诊人</span><span>{list.patientName}</span></p>
                            <p className='history-list'><span>身份证号</span><span>{list.idCard}</span></p>
                            <p className='history-list'><span>单号</span><span>{list.outpatientNumber}</span></p>
                        </div>
                    </>:null}
                </div>
                {list && list.state == 1 ? <div onClick={()=>this.toRefund()} className='refund'> 取消预约</div> : null}
            </div>
        )
    }
}
