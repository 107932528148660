import React ,{ useState, useEffect } from 'react'
import { ImagePicker, TextareaItem, Toast } from 'antd-mobile'

import { upOrderStuRequest } from '../../../api/request/order'
import { commentmedRequest, medCommentDetail } from '../../../api/request/comment'
import { uploadImg } from '../../../api/request/upload'

import { zipImg } from '../../../component/zipImage/index'
import getUrl from '../../../component/GetUrlParam'

import defaultImg from '../../../static/img/default.png'
import pingjia from '../../../static/img/pingjia.png'
import pingjia2 from '../../../static/img/pingjia2.png'

import './style.less'
import Logo from '../../../component/logo/logo'

export default function Comment(props){
    const star = [1, 2, 3, 4, 5]
    const orderMedId = getUrl('orderMedId', props.location.search)
    const orderNumber = getUrl('orderNumber', props.location.search)
    const last = getUrl('last', props.location.search)
    const [medDetail, setMedDetail] = useState(null)
    const [pharmacy, setPharmacy] = useState(null)
    const [files, setFiles] = useState([])
    const userId = localStorage.userId
    const [evaluateText, setEvaluateText] = useState('')
    const [evaluatePic, setEvaluatePic] = useState([])
    const [starCount, setStarCount] = useState(0)

    useEffect(() => {
        medCommentDetail(orderMedId).then(res => {
    
            if(res.code === 1 && res.data[0].length > 0) {
                setMedDetail(res.data[0][0])
                setPharmacy(res.data[1][0])
            }
        })
    }, [orderMedId])

    const backPage=()=>{
        window.history.back()
    }

    const healthPost = (val) => {
        setEvaluateText(val)
    }

    const subMit = () => {
        const params = {
            orderMedId: orderMedId,
            userId: userId,
            evaluateText: evaluateText,
            evaluatePic: evaluatePic.join(','),
            evaluateStar: starCount,
            medId: medDetail.medId,
            medName: medDetail.medName,
            medPhoto: medDetail.medPhoto,
            medSpec: medDetail.medSpec,
            pharmacyId: pharmacy.pharmacyId,
            pharmacyName: pharmacy.pharmacyName
        }
        if(params.evaluateStar === 0) {
            Toast.info('尚未评价', 1)
        } else {
            commentmedRequest(params).then(res => {

                if(res.code === 1) {
                    if(last) {
                        upOrderStuRequest({orderState:'3',orderNumber:orderNumber}).then(res => {
                            // console.log(res)
                        })
                    }
                    backPage()
                }
            })
        }
    }

    const imgChange = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = evaluatePic
        if(type === 'add') {
            let file = files[files.length-1].file
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    if(res1.code === 2) {
                        setFiles(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setEvaluatePic(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles(files)
            imgList.splice(index, 1)
            setEvaluatePic(imgList)
            Toast.hide()
        }
    }
    
    const commentStar = (item) => {
        setStarCount(item)
    }

    return(
        <>
            <div className='sendcomment-header'>
                <div className='back-icon flexr' onClick={backPage}>
                    <i className='icon-xiangzuo iconfont'></i>
                </div> 
                <div className='sendcomment-icon flex-row' onClick={subMit}>提交</div>
            </div>
            <p className='commit1'>评价</p>
            {medDetail === null ? null :
            <div className='sendcomment-med flexr'>
                <p className='sure'>您对商家/商品满意吗？</p>
                <div className='sendcomment-img'>
                    {/* <img src={medDetail.medPhoto?medDetail.medPhoto:defaultImg} alt=''
                    onError={(e)=>{e.target.onerror = null;e.target.src=defaultImg}}/> */}
                    <span className='yaodian-img'></span>
                    <span className='yaodian-name'>{pharmacy&&pharmacy.pharmacyName}</span>
                </div>
                <div className='sendcomment-detail'>
                    {/* <div>{medDetail.medName}</div>
                    <div>{medDetail.medSpec}</div> */}
                    <p>满意度</p>
                    <div className='sendcomment-star flexr'>
                        <div className='flexr'>
                        {star.map((item, index) => (
                            <div key={index}
                            className={item<=starCount?'sendcomment-active':''}
                            onClick={()=>commentStar(item)}>
                            {item <= starCount ?
                                // <i className='iconfont icon-xingxing'/> :
                                // <i className='iconfont icon-tuanduicankaoxian-'/>
                                <img style={{width:'20px', marginRight:'6px'}} src={pingjia} alt="" />:
                                <img style={{width:'20px', marginRight:'6px'}} src={pingjia2} alt="" />
                            }
                            </div>
                        ))}
                        </div>
                        <div className='sendcomment-good'>{starCount===1?'非常差':starCount===2?'差':starCount===3?'中':starCount===4?'好':starCount===5?'非常好':''}</div>
                    </div>
                </div>
                <div className='comment-content'>
                    <div className='sendcomment-area'>
                        <TextareaItem
                        className='sendcomment-area-text'
                        placeholder='商品好，服务满意，推荐给大家'
                        rows={6}
                        count={100}
                        onChange={healthPost}/>
                    </div>
                    <div className='sendcomment-bottom'>
                        <ImagePicker
                        files={files}
                        length={3}
                        onChange={imgChange}
                        onImageClick={(index, fs) => console.log(index, fs)}
                        selectable={files.length < 6}
                        accept='image/gif,image/jpeg,image/jpg,image/png'/>
                        <p>添加图片</p>
                    </div>
                </div>
            </div>
            }
        </>
    )
}