import React ,{ useState, useEffect } from 'react'

import { oderdetailRequest } from '../../../api/request/order'
import { medowncommentRequest } from '../../../api/request/comment'
import getUrl from '../../../component/GetUrlParam'
import Head from '../../../component/Head'
import Button from '../../../baseui/fuctionbutton/index'
import defaultImg from '../../../static/img/default.png'
import pingjia from '../../../static/img/pingjia.png'
import pingjia2 from '../../../static/img/pingjia2.png'
import './style.less'

export default function Comment(props){
    const orderNumber = getUrl('orderNumber', props.location.search)
    const [orderDetail, setOrderDetail] = useState(null)
    const [medList, setMedList] = useState(null)
    const userId = localStorage.userId
    const [medid, setMedId] = useState([])
    const [star, setStar] = useState([])
    const [medList1, setMedList1] = useState([])//已评价药品
    const [medList0, setMedList0] = useState([])//未评价药品
    const star1 = [1, 2, 3, 4, 5]
    var star2=[]
    var starid=[]
    let medList00=[];
    let medList11=[];

    useEffect(() => {
        oderdetailRequest(orderNumber).then(res => {
     
            if(res.code === 1 && res.data[0].length > 0) {
                setOrderDetail(res.data[0][0])
                setMedList(res.data[1])
                res.data[1].forEach(item => {
                    if(item.commented === '1') {
                        medList11[medList11.length]=item;
                        setMedList1(medList1.concat(medList11))
                        const params = {
                            userId: userId,
                            medId: item.medId,
                        }
                        // 查看单个商品的评价
                        medowncommentRequest(params).then(res1 => {
                    
                            if(res1.code === 1 && res1.data[0].length > 0) {
                                let i=res1.data[0].length-1;
                                star2[star2.length]=res1.data[0][i].evaluateStar;
                                starid[starid.length]=res1.data[0][i].medId;
                                setMedId(medid.concat(starid))
                                setStar(star.concat(star2))
                            }
                        })
                    }else{
                        medList00[medList00.length]=item;
                        setMedList0(medList0.concat(medList00))
                    }
                })
            }
        })
    }, [orderNumber])
 
    const medComment = (item) => {
        let i = 0
        medList.forEach(item => {
            if(item.commented === '1') {
                i++;
            }
        })
        const last = i === medList.length
        props.history.push(`/sendcomment?orderMedId=${item.orderMedId}&orderNumber=${orderNumber}&last=${last}`)
    }
   let i;
    return(
        <div className='outer'>
        <Head title='评价晒单'></Head>
            <div className='order-comment-screen' style={{marginTop:'10px'}}>
            {medList === null ? null :
                <div>
                    {medList1.map((item, index) => (
                        <div style={{background:"#efefef"}}>
                            <div className='comment-pharmacy'  style={{padding:'10px 15px'}}>
                                <span>{orderDetail.pharmacyName}</span>
                                <i className='iconfont-s icon-xiangyou'></i>
                            </div>
                            <div className='xing'>
                                <div className='xing-left'>{item.medName}</div>
                                {item.medId===medid[index]?
                                <div className='xing-right'>
                                    {star1.map((item1, index1) => (
                                    <div>
                                        {item1 <= star[index] ?
                                            <img style={{width:'20px', marginRight:'6px'}} src={pingjia} alt="" />:
                                            <img style={{width:'20px', marginRight:'6px'}} src={pingjia2} alt="" />
                                        }
                                    </div>
                                    ))}
                                </div>:null}
                            </div>
                        </div>
                    ))}
                    {medList0.map((item, index) => (
                       <div style={{background:"#efefef",marginTop:'10px'}}>
                           <div className='comment-pharmacy' style={{padding:'10px 15px'}}>
                                <span>{orderDetail.pharmacyName}</span>
                                <i className='iconfont-s icon-xiangyou'></i>
                            </div>
                            <div key={index} className='comment-med-list flexr'>
                                <div className='flexr'>
                                    <div className='comment-med-img'>
                                        <img src={item.medPhoto?item.medPhoto:defaultImg} alt=''
                                        onError={(e)=>{e.target.onerror = null;e.target.src=defaultImg}}/>
                                    </div>
                                    <div className='comment-med-detail'>
                                        <div>{item.medName}</div>
                                        <div>{item.medSpec}</div>
                                        <div>评价可得5积分</div>
                                    </div>
                                </div>
                                <div>
                                    <Button name='评价' basecolor='#1F87FF'
                                    onClick={()=>medComment(item)} />
                                </div>
                            </div> 
                        </div>
                    ))}
                </div>
            }
            </div>
        </div>
    )
}