import React, { useRef, useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Toast } from 'antd-mobile'

import Goods from '../../component/Goods/index'
import Swiper from '../../component/Swiper/index2'
import Table from '../../baseui/table/index'

import { resetdirect } from '../../redux/user.redux'

import { meddetailRequest } from '../../api/request/med'
import { medcommentRequest } from '../../api/request/comment'
import { fllowRequest, deletecollectRequest } from '../../api/request/fllow'
import { addBrowsingRequest } from '../../api/request/history'
import { addcartRequest, updatecartRequest, querycartRequest,cartlistRequest,cartGoodslist,delCartRequest } from '../../api/request/cart'
import getUrl from '../../component/GetUrlParam'
import { wxConfig } from '../../component/scan/index'
import Evaluate from '../evaluate'
import './style.less'
import '../../static/css/iconfont.css'

import defaultImg from '../../static/img/default.png'
import juanImg from '../../static/img/juan.png'
import gouwucar from '../../static/img/gouwucar.png'
import { number } from 'prop-types'

function Medcine(props) {
    const env = localStorage.env || getUrl('env', props.location.search)
    const [userId, setuserId] = useState("")
    const userId1=localStorage.getItem("userId1")
    const userId2=localStorage.getItem("userId2")
    const type = localStorage.type
    useEffect(() => {
        switch (localStorage.type) {
            case "1":
                setuserId(localStorage.userId1)
                return
            case "2":
                setuserId(localStorage.userId2)
                return
            case "3":
                setuserId(localStorage.userId3)
                return
        }
       
    }, [])
    //const userId = localStorage.userId
    const medId = props.match.params.medId
    const userVip = localStorage.userVip
    // const address = localStorage.address
    const tablist = [{ id: 'med-introduce', name: '商品' }, { id: 'med-comment', name: '评价' }, { id: 'med-detail', name: '详情' }]
    const [isFollow, setIsFollow] = useState(false)
    const [dataLoading, setDataLoad] = useState(false)
    const [evaluate, setEvaluate] = useState(false)
    const [collectionId, setCollectId] = useState(0)
    const [medDetail, setMedDetail] = useState(null)
    const [pharmacyId, setPharmacyId] = useState(null)
    const [pharmacy, setPharmacy] = useState(null)
    const [recommend, setRecommend] = useState([])
    const [medComment, setMedComment] = useState([])
    const [nowIndex, setNowIndex] = useState(0)
    const [count, setCount] = useState(0)
    const [count1, setCount1] = useState(0)
    const [allprice, setAllprice] = useState(0)
    const [stockNum, setstockNum] = useState(0)

    
    const [visible, setVisible] = useState('none')

    // const [startY, setStartY] = useState(0)
    const [refresh, setRefresh] = useState(false)
    const headerRef1 = useRef()
    const headerRef2in1 = useRef()
    const headerRef2in2 = useRef()
    const anchorPoint = useRef()

    const { redirectDispatch } = props

    useEffect(() => {
        queryMedDetail(medId, userId ? userId : 0)
        if (userId) {
            addBrowsingRequest({ userId: userId, medId: medId }).then(res => {
                // console.log(res)
            })
        }
        
    }, [medId, userId])

    useEffect(() => {
        refresh && setTimeout(() => setRefresh(false))
    }, [refresh])

    const queryMedDetail = async (medId, userId) => {
        const res = await Promise.all([
            meddetailRequest(medId),
            medcommentRequest({ medId: medId, userId: userId, star: [1, 2, 3, 4, 5] })
        ])
        // console.log(res);
        if (res[0].code === 1 && res[0].data[0].length > 0) {
            let data1 = res[0].data[0][0]
            let data2 = res[0].data[1]
            let data3 = res[0].data[2]
            let data4 = res[0].data[3]

            data1.medPiclist = data1.medPiclist ? data1.medPiclist.split(',') : [defaultImg]
            // console.log(data1);
            setMedDetail(data1)
            setPharmacyId(data1.pharmacyId)
            if (data4&&data4[0]) {
                setstockNum(data4[0].stockNum)
            }
            
            document.title = data1.pharmacyName
            setPharmacy(data3 && data2.length > 0 ? data2[0] : null)
            setRecommend(data3 && data3.length > 0 ? data3 : data2)
            const sareParam = {
                title: data1.medName,
                desc: data1.medName + ' ' + data1.medSpec,
                link: encodeURIComponent(document.URL),
                imgUrl: data1.medPhoto
            }
            wxConfig('share', sareParam)
        }

        if (res[1].code === 1) {
            let data = res[1].data
            if (data[0].length > 0) {
                data[0].forEach(item => {
                    item.evaluatePic = item.evaluatePic ? item.evaluatePic.split(',') : []
                })
            }
            setMedComment(data)
            setIsFollow(res[1].data[5].length === 0 ? false : true)
            setCollectId(res[1].data[5].length === 0 ? 0 : res[1].data[5][0].collectionId)
        }
        setDataLoad(true)
        
    }
        
    // console.log(stockNum);
    const fllowMedRequest = () => {
        const params = {
            type: 1,
            userId: userId,
            medId: medId
        }
        if (isFollow) {
            const delparam = {
                userId: userId,
                checkAll: '0',
                type: '1',
                collectionId: collectionId
            }
            deletecollectRequest(delparam).then(res => {
                setIsFollow(false)
            })
        } else {
            fllowRequest(params).then(res => {
                if (res.code === 1) {
                    setCollectId(res.data[0][0])
                    setIsFollow(true)
                }
            })
        }
        setRefresh(true)
    }
    
    const juanRequest = () => { }
    useEffect(() => {
        cartlistRequest(userId ,pharmacyId).then(res=>{
            setCount(res.data[0].length)
        })
        cartGoodslist(userId ,medId,pharmacyId).then(res=>{
            if (res.data[0]) {
                res.data[0].map((item)=>{
                    if (item.medId==medId) {
                        setCount1(item.medQuantity)
                        setAllprice(Math.floor(item.medQuantity*item.medPrice/100*100)/100)
                        setVisible('block')
                    }
                })
            }
        })
    }, [medDetail,count])
    const buyNow = (num_package) => {
        const cartArr = [{
            pharmacy: {
                pharmacyId: medDetail.pharmacyId,
                pharmacyName: medDetail.pharmacyName,
                freight: medDetail.freight
            },
            medList: [{
                medId: medDetail.medId,
                medName: medDetail.medName,
                medPhoto: medDetail.medPhoto,
                medSpec: medDetail.medSpec,
                medPacking: medDetail.medPacking,
                medApproval: medDetail.medApproval,
                medCompany: medDetail.medCompany,
                medPrice: medDetail.medPrice,
                medQuantity: num_package ? num_package : 1,
                cartPrice: medDetail.medPrice * (num_package ? num_package : 1),

            }]
        }]
        if (userId) {
            props.history.push({ pathname: '/fillorder', state: cartArr })

        } else {
            redirectDispatch(props.location.pathname)
            props.history.push('/login/Login1')
        }
    }

    const addShopCart = () => {
        if (stockNum>0) {
            const params = {
                userId: userId,
                pharmacyId: medDetail.pharmacyId,
                medId: medId,
                medQuantity: 1
            }
            if (params.userId) {
                querycartRequest(userId, medId).then(res => {
          
                    if (res.code === 1 && res.data[0].length === 0) {
                        addcartRequest(params).then(res1 => {
                            if (res1.code === 1) {
                                setAllprice(Math.floor(medDetail.medPrice/100*100)/100)
                                // Toast.info('已加入购物车', 1)
                                cartlistRequest(userId ,pharmacyId).then(res=>{
                                    setCount(res.data[0].length)
                                })
                            }
                        })
                    } else if (res.code === 1 && res.data[0].length > 0) {
                        let data = res.data[0][0]
                        const quantity = data.medQuantity + 1
                        updatecartRequest(quantity, data.cartId).then(res1 => {
               
                            if (res1.code === 1) {
                                setAllprice(Math.floor(quantity*medDetail.medPrice/100*100)/100)
                                // Toast.info('已加入购物车', 1)
                                cartGoodslist(userId ,medId,pharmacyId).then(res2=>{
                                    if (res2.data[0]) {
                                        res2.data[0].map((item)=>{
                                            if (item.medId==medId) {
                                                setCount1(item.medQuantity)
                                                setVisible('block')
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            } else {
                redirectDispatch(props.location.pathname)
                props.history.push('/login/Login1')
            }
        }else{
            Toast.info('库存为0')
        }
    }
    const backPage = () => {
        window.history.back()
    }

    const handleScroll = useCallback((pos) => {
        let minScrollY = -50
        let percent = Math.abs(pos.y / minScrollY)
        let headerDom1 = headerRef1.current
        let headerDom2in1 = headerRef2in1.current
        let headerDom2in2 = headerRef2in2.current
        if (pos.y > minScrollY && pos.y < 0) {
            headerDom1.style.opacity = Math.min(1, percent)
            headerDom2in1.style.color = '#FFFFFF'
            headerDom2in1.style['background-color'] = '#C2C5CB'
            headerDom2in2.style.color = '#FFFFFF'
            headerDom2in2.style['background-color'] = '#C2C5CB'
        } else if (pos.y < minScrollY && pos.y < 0) {
            headerDom1.style.opacity = 1
            headerDom2in1.style['background-color'] = '#FFFFFF'
            headerDom2in1.style.color = '#0B1523'
            headerDom2in2.style['background-color'] = '#FFFFFF'
            headerDom2in2.style.color = '#0B1523'
        }
    }, [])

    const movetotab = (name, index) => {
        if (name) {
            setNowIndex(index)
            // 找到锚点
            let anchorElement = document.getElementById(name)
            // 如果对应id的锚点存在，就跳转到锚点
            if (anchorElement) {
                if (anchorElement.offsetTop === 220) {
                    anchorPoint.current.scrollTo(0, 0)
                } else {
                    anchorPoint.current.scrollTo(0, anchorElement.offsetTop - 100)
                }
            }
            setRefresh(true)
        }
    }

    const onscroll = e => {
        let scrollTop = e.target.scrollTop
        let minScrollY = -50
        let percent = Math.abs(scrollTop / minScrollY)
        let headerDom1 = headerRef1.current
        let headerDom2in1 = headerRef2in1.current
        let headerDom2in2 = headerRef2in2.current
        if (scrollTop > 5) {
            headerDom1.style.opacity = Math.min(1, percent)
            headerDom2in1.style['background-color'] = '#FFFFFF'
            headerDom2in1.style.color = '#0B1523'
            headerDom2in2.style['background-color'] = '#FFFFFF'
            headerDom2in2.style.color = '#0B1523'
        } else if (scrollTop < 5) {
            headerDom1.style.opacity = 0
            headerDom2in1.style.color = '#FFFFFF'
            headerDom2in1.style['background-color'] = '#C2C5CB'
            headerDom2in2.style.color = '#FFFFFF'
            headerDom2in2.style['background-color'] = '#C2C5CB'
        }
        if (scrollTop > 120 && scrollTop <= 480) {
            setNowIndex(0)
        }
        if (scrollTop > 480 && scrollTop <= 980) {
            setNowIndex(1)
        }
        if (scrollTop > 980) {
            setNowIndex(2)
        }
    }

    const evaluateDetail = () => {
        // props.history.push(`/evaluate/${medId}`)
        setEvaluate(!evaluate)
        setRefresh(true)
    }

    const RenderHeader = () => {
        return (
            <div id='med-header'>
                <div className='med-header-icon flex-apart-row'>
                    <div className='round-icon flex-row' onClick={backPage} ref={headerRef2in1}>
                        <i className='icon-xiangzuo iconfont'></i>
                    </div>
                    <div className='round-icon flex-row' ref={headerRef2in2}>
                        <i className='icon-fenxiang iconfont'></i>
                    </div>
                </div>

                <div className='header-tab ' ref={headerRef1}>
                    {tablist.map((v, index) => {
                        return (
                            <div key={index} onClick={() => movetotab(v.id, index)}>
                                <span>{v.name}</span>
                                {index === nowIndex ? <div className='active-line'></div> : null}
                            </div>
                        )
                    })
                    }
                </div>

            </div>
        )
    }
    // console.log(medDetail);
    const RenderBrief = () => {
        return (
            <>
                <div id='med-introduce'>
                    <div className='med-brand flexc'>
                        <div className='med-brand-fee flexr'>
                            {type=="1"? <p>￥{(medDetail.medPrice / 100)}</p>:null}
                            {type=="2"? <p>￥{medDetail.toB === 1 && !userId2 ? "登录可见" : (medDetail.medPrice / 100)}</p>:null}
                            {medDetail.medPrice != medDetail.originPrice ? <p className='price'>￥{medDetail.toB === 1 && !userId ? "" : (medDetail.originPrice / 100)}</p> : null}
                            {!userVip || userVip === '0' ? null :
                                <div>Lv{userVip}会员</div>
                            }
                        </div>
                        {/* {!userId ? null : */}
                        <div className='med-brand-title flex-apart-row'>

                            <div className={isFollow ? 'collected' : ''}
                                onClick={() => fllowMedRequest()}>
                                <i className={`${isFollow ? 'icon-xingxing' : 'icon-tuanduicankaoxian-'} iconfont med-collet`}></i>
                                <p>收藏</p>
                            </div>
                        </div>
                        {/* } */}
                        <div className='yaopin-name'>
                            {medDetail.medClassify === '1' ?
                                <span className='chufang'>处方药</span> : null}
                            <span className='xiangqing'>{medDetail.medName} {medDetail.medSpec}</span>
                        </div>
                    </div>
                    {medDetail.medClassify === '1' ? <div className='recipel-warn'>本品需凭处方购买，请预约后如实填报实际情况，确保医师正确判断病情及开方后对药物审核。</div> : null}
                    {/* <div className='med-vip flex-apart-row'
                    onClick={()=>Toast.info('管理员未开放', 1)}>
                        <span>开通VIP可以优惠￥6.00</span>
                        <span>立即开通</span>
                    </div> */}
                    {/* {medDetail.toB===1?<div className='med-tab3'>
                        <p>件量:</p>
                        <span>{medDetail.num_package}个</span>
                    </div>:''} */}
                    {/* {medDetail.medIf===0?<div className='med-tab3'>
                        <p>主治</p>
                        <span>{medDetail.medExplain}</span>
                    </div>:''} */}
                    {/* <div className='code-factory flex-apart-row'>
                        <p>{medDetail.medApproval}</p>
                        {medDetail.medIf===0?<span>{medDetail.medCompany}</span>:null}
                    </div> */}
                </div>
                <div id='med-specs'>
                    <div className='med-tab2'>
                        <p>优惠</p>
                        {medDetail.promoteFull ?
                            <span>满{medDetail.promoteFull}减{medDetail.promoteCut}</span> :
                            <span>无</span>
                        }
                        <img className='img' src={juanImg} onClick={() => juanRequest()} alt="" />
                    </div>
                    {/* {medDetail.medIf===0?<div className='med-tab2'>
                        <p>规格</p>
                        <span>{medDetail.medSpec}</span>
                    </div>:null} */}
                    {/* <div className='med-tab2'>
                        <p>运费</p>
                        <span>{medDetail.freight===0?'免运费':medDetail.freight}</span>
                    </div> */}
                    {/* <div className='med-tab2'>
                        <p>送至</p>
                        <span>{address===0?null:address}</span>
                    </div> */}
                </div>
                <div id='med-save'>
                    {medDetail.medIf === 0 ? <div className='med-tab2'>
                        <p>规格</p>
                        <span>{medDetail.medSpec}</span>
                    </div> : null}
                    <div className='med-tab2 code-factory flex-apart-row'>
                        <p>{medDetail.medApproval.substr(0, 4)}</p>
                        <span>{medDetail.medApproval.substr(4)}</span>
                    </div>
                    <div className='med-tab2 sc'>
                        <p>生产厂家</p>
                        {medDetail.medIf === 0 ? <span>{medDetail.medCompany}</span> : null}
                    </div>
                    {localStorage.type==='2'?<div className='med-tab2 sc'>
                        <p>库存</p>
                        <span>{stockNum}</span>
                    </div>:''}
                    {localStorage.type==='2'?<div className='med-tab2 guoqi'>
                        <p>有效期</p>
                        <span>{medDetail.expiration}</span>
                    </div>:''}
                </div>
            </>

        )
    }

    const RenderComment = () => {
        return (
            <div id='med-comment' className='flexc'>
                <div className='med-comment-title flex-apart-row'>
                    <div className='flex-row'>
                        <p>评价</p>
                        <span>（{medComment[1][0].total}）</span>
                    </div>
                    <div className='flex-row'
                        onClick={() => evaluateDetail()}>
                        <span>查看更多</span>
                        <i className='icon-xiangyou iconfont'></i>
                    </div>

                </div>
                {/* <div className='med-comment-sort flexr'>
                    <div>全部</div>
                    <div>好评({medComment[4][0].great})</div>
                    <div>差评({medComment[2][0].bad})</div>
                </div> */}
                {medComment[0].length === 0 ? null :
                    <div className='med-customer-commit flexc'>
                        <div className='customer-head flexr'>
                            <div className='customer-img'>
                                {medComment[0][0].userAvatar ?
                                    <img src={medComment[0][0].userAvatar} alt='' />
                                    : null
                                }
                            </div>
                            <div className='customer-info flexc'>
                                <p>{medComment[0][0].userName || medComment[0][0].userAcount}</p>
                                <span>{medComment[0][0].createTime}</span>
                            </div>
                        </div>
                        <p>{medComment[0][0].evaluateText}</p>
                    </div>
                }
                <div>

                </div>
            </div>
        )
    }
    const RenderShop = () => {
        return (
            <div>
                <div id='med-belongshop' className='flexr'
                    onClick={() => toPharmacy()}>
                    <img src={medDetail.pharmacyPhoto} alt='' />
                    <div>
                        <p>{medDetail.pharmacyName}</p>
                        <span>{pharmacy === null ? '0' : pharmacy.followNumber > 9999 ? `${(pharmacy.followNumber / 10000).toFixed(1)}万` : pharmacy.followNumber}人关注</span>
                    </div>
                </div>
                <div id='med-belongshop-recommend'>
                    <p>店铺推荐</p>
                    <Goods recommend={recommend}
                        onclick={() => anchorPoint.current.scrollTo(0, 0)} />
                </div>
            </div>

        )
    }
    const RenderDetail = () => {
        return (
            <div id='med-detail' className='flexc'>
                <p>说明书</p>
                <Table medDetail={medDetail} />
                <span>商品详情</span>
                <div className='med-html-details'
                    dangerouslySetInnerHTML={{ __html: medDetail.medDescribe }} />
            </div>
        )
    }

    const toPharmacy = () => {
        if (env === 'single') {
            props.history.push(`/?env=single&pharmacyId=${medDetail.pharmacyId}`)
        } else {
            props.history.push(`/shop/${medDetail.pharmacyId}`)
        }
    }

    const toDoctor = () => {
        // window.location.href='http://wz.sczyzk.com/#/user/home'
        Toast.info('该店铺暂未开启医师问诊服务', 1)
    }

    const toCart = () => {
        props.history.push('/shopcart')
    }
     //改变加入数量（输入数量）
     const cahngenum = (e) => {
        let b
  
        if (stockNum<=parseInt(e.target.value)) {
            Toast.info('库存只剩'+stockNum)
            setCount1(stockNum)
            b=stockNum
        }else if(parseInt(e.target.value)<0){
            Toast.info('数量不能为负')
            b=1
        }
        else{
            setCount1(e.target.value)
            b=e.target.value
        }
        let a=b
        querycartRequest(userId, medId).then(res => {
            if (res.code === 1 && res.data[0].length > 0) {
                let data = res.data[0][0]
                const quantity =a
                updatecartRequest(quantity, data.cartId).then(res1 => {
                    // console.log(res1);
                    if (res1.code === 1) {
                        setAllprice(Math.floor(quantity*medDetail.medPrice/100*100)/100)
                        cartGoodslist(userId ,medId,pharmacyId).then(res2=>{
                            if (res2.data[0]) {
                                res2.data[0].map((item)=>{
                                    if (item.medId==medId) {
                                        setCount1(item.medQuantity)
                                        setVisible('block')
                                    }
                                })
                            }
                        })
                    }
                })
            }
        })
    }
    
    const movenum = (code) => {
        if (code == 1) {//数量减
            if (userId &&count1>=1) {
                querycartRequest(userId, medId).then(res => {
                    if (res.code === 1 && res.data[0].length > 0) {
                    let data = res.data[0][0]
                    const quantity = count1>1?count1-1:0
                        updatecartRequest(quantity, data.cartId).then(res1 => {
                            if (res1.code === 1) {
                                setAllprice(Math.floor(quantity*medDetail.medPrice/100*100)/100)
                                cartGoodslist(userId ,medId,pharmacyId).then(res2=>{
                                    if (res2.data[0]) {
                                        res2.data[0].map((item)=>{
                                            if (item.medId==medId) {
                              
                                                if (item.medQuantity==0) {
                                                    delCartRequest({cartId:item.cartId}).then(res3=>{
                                                        window.location.reload()
                                                    })
                                                }else{
                                                    setCount1(item.medQuantity)
                                                    setVisible('block')
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        } else {//数量加
            if (userId) {
                if (parseInt(count1)+1>stockNum) {
                    Toast.info('库存只剩'+stockNum)
                }
                querycartRequest(userId, medId).then(res => {
                        if (res.code === 1 && res.data[0].length > 0) {
                        let data = res.data[0][0]
                        const quantity = parseInt(count1)+1>=stockNum?stockNum:parseInt(count1)+1
                        updatecartRequest(quantity, data.cartId).then(res1 => {
                            if (res1.code === 1) {
                                setAllprice(Math.floor(quantity*medDetail.medPrice/100*100)/100)
                                cartGoodslist(userId ,medId,pharmacyId).then(res2=>{
                                    if (res2.data[0]) {
                                        res2.data[0].map((item)=>{
                                            if (item.medId==medId) {
                                                setCount1(item.medQuantity)
                                                setVisible('block')
                                            }
                                        })
                                    }
                                })
                            }
                        })
                    }
                })
            }
        }
    }
    return (
        <>
            {evaluate ?
                <Evaluate onclick={evaluateDetail}
                    userId={userId}
                    medId={medId}
                    medComment={medComment} /> :
                <div ref={anchorPoint} onScroll={onscroll}
                    style={{
                        width: '100%',
                        height: '100vh',
                        overflow: 'auto'
                    }}>
                    {RenderHeader()}
                    <div id='med-img'>
                        <Swiper banners={dataLoading ? medDetail.medPiclist : []} />
                    </div>
                    {dataLoading ? RenderBrief() : null}
                    {dataLoading ? RenderComment() : null}
                    {dataLoading ? RenderShop() : null}
                    {dataLoading ? RenderDetail() : null}
                </div>
            }
            <div className='med-buy-tab'>
                {type != "2" ? dataLoading ? medDetail.medDelete === '1' ?
                    <div className='med-buy-btn'>
                        <div className='med-button1 flex-row'
                            onClick={() => buyNow(1)}>
                            <span>立即购买</span>
                        </div>
                        <div className='med-button2 flex-row'
                            onClick={() => addShopCart()}>
                            <span>加入购物车</span>
                        </div>
                        {medDetail.toB === 1 ? (
                            <div className='med-button3 flex-row'
                                onClick={() => buyNow(medDetail.num_package)}>
                                <span>按件购买({medDetail.num_package})</span>
                            </div>) : ""}
                    </div> :
                    <div className='med-buy-btn'>
                        <div className='med-down'>该药品已下架</div>
                    </div> : null :
                    <div className='med-buy-btn1'>
                        <div className='price'>
                            {allprice<=500?<p>起发价：500元（还差<span>{500-allprice}元</span>）</p>:
                            <p>起发价：500元（合计<span>{allprice}元</span>）</p>}
                            {allprice<=500?<p>邮费：10元（还差<span>{500-allprice}元</span>包邮）</p>:
                            <p>以满足包邮金额</p>}
                        </div>
                        <div className='gouwucar'>
                            <img src={gouwucar}  onClick={() => toCart()} alt="" />
                            <span>{count}</span>
                        </div>
                        {/* <input type="radio" /> */}
                        {visible=='none'?<div className='med-button5 flex-row'
                            onClick={() => addShopCart()}>
                            <span>加入购物车</span>
                        </div>:
                         <p style={{marginTop:'15px',marginRight:'10px'}}>
                            <button onClick={() => movenum(1)} style={{ width: "26px", height: "27px", border: "none",color:'#fff', borderTopLeftRadius: "5px", borderBottomLeftRadius: "5px" ,backgroundColor:'#FF5555'}}>-</button><input type="number" value={count1} onChange={(e) => cahngenum(e)} style={{ height: "25px", textAlign: "center", width: "50px", borderWidth: "1px", borderColor: "rgb(239, 239, 239)" }} /><button onClick={() => movenum(2)} style={{ width: "26px", height: "27px", border: "none",color:'#fff', borderTopRightRadius: "5px", borderBottomRightRadius: "5px",backgroundColor:'#FF5555' }}>+</button>
                        </p> }
                    </div>
                }
                {type!='2'?<div className='med-icon flexr'>
                    {env === 'single' ? null :
                        <ul className='clear-fix'>
                            <li onClick={() => toPharmacy()}>
                                <i className='icon-drugstore iconfont'></i>
                                去药房
                            </li>
                            <li onClick={() => toDoctor()}>
                                <i className='icon-yisheng iconfont'></i>
                                问医师
                            </li>
                            <li onClick={() => toCart()}>
                                <i className='icon-tubiaozhizuomoban iconfont'></i>
                                购物车
                            </li>
                        </ul>
                    }
                </div>:null}
            </div>
        </>
    )
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
    return {
        redirectDispatch(data) {
            dispatch(resetdirect(data))
        },
    }
}

// 将ui组件包装成容器组件
export default connect(mapStateToProps, mapDispatchToProps)(Medcine)