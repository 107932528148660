import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../static/img/add.png"
import icon2 from "../../static/img/bianji.png"
import icon3 from "../../static/img/dizhi.png"
import FootNav from '../../component/FootNav'
import { getnewslist,userdelWznews } from "../../api/hospitalapi/chat"
import { Toast } from 'antd-mobile-v5';
import { Modal } from 'antd-mobile';
import Nodata from "../../component/Nodata";
const { alert } = Modal
export default class index extends Component {
  constructor() {
    super()
    this.state = {
      patientId: localStorage.getItem("patientId"),
      newslist: [],
      timer:localStorage.getItem('timer'),
      data:['png','jpg','peg','bmp','gif','pdf']
    }
  }
  componentDidMount = async () => {
      const { patientId,timer } = this.state
      window.clearInterval(timer)

      if (!patientId) {
        Toast.show({
            content:"当前未登录，请先登录",
            duration:1000
        })
        this.props.history.replace("/")
        this.props.history.push("/login")
      }else{
        this.wzNews()
      }

  }
  wzNews=async()=>{
    const { patientId,data } = this.state
    const res = await getnewslist({
      userId:patientId
    })

   if (res&&res.code==1) {
      var temp = [];
      var temp1 = [];
      var a = [];
      var index = [];
      let temp2=res.data[0]
       temp2.map((item,i)=>{
           if (item.consultation_type=='1'){
               if (!item.order_number&&item.consultation_stu!='-2'&&item.consultation_stu!='4'){
                   a.push(item);
               }
               if (item.order_number){
                   temp1.push(item);
               }
           }
       })

       var l = a.length;
       var l1 = temp1.length;
       let nArr = a.filter((currentValue, currentIndex, selfArr) => {
           return selfArr.findIndex(x => x.docter_id === currentValue.docter_id) === currentIndex
       });
       let nArr1 = temp1.filter((currentValue, currentIndex, selfArr) => {
           return selfArr.findIndex(x => x.docter_id === currentValue.docter_id) === currentIndex
       });
 
       temp=nArr.concat(nArr1)
      //  for(var i = 0; i < l; i++) {
      //     for(var j = i + 1; j < l; j++){
      //         if (a[i].docter_id == a[j].docter_id ){
      //             ++i;
      //             j = i;
      //         }
      //     }
      //     temp.push(res.data[0][i]);
      //     index.push(i);
      // }
      //
      // for(var i = 0; i < temp.length; i++) {
      //   for(var j = i + 1; j < temp.length; j++){
      //       if (temp[i].user_id === temp[j].user_id && temp[i].order_number){
      //           i++;
      //           j = i;
      //       }
      //   }
      //   temp1.push(temp[i]);
      // }
      // console.log(res,temp);
      // console.log(temp1);
      let arr=[]
      if (temp.length) {
        temp.map(item=>{
          if (item.userDelete==1) {
              item.state=0
              if (item.detail_type=='2'){
                  let data0=item.detail_content.slice(-3)
             
                  data.map(i=>{
                      if (i==data0){
                          item.state=1
                      }
                  })
              }
            arr.push(item)
          }
        })

        this.setState({
          newslist: arr
        })
      }
    }
  }
  goinfo(news){

    localStorage.setItem("item",JSON.stringify(news))
    localStorage.setItem("consultationId",news.consultation_id)
      this.props.history.push(`/doctorchat?consultationId=${news.consultation_id}&docterId=${news.docter_id}`)
    // if (news.consultation_stu=='0'||news.consultation_stu=='-1') {
    //     this.props.history.push({
    //           pathname:"/doctorinfo/chat",
    //           params:{
    //             news
    //           }
    //         })
    // }else{
    //     this.props.history.push({
    //       pathname:"/doctorchat",
    //       params:{
    //         news,
    //         docterId:news.docter_id
    //       }
    //     })
    // }
  }
  //删除消息
  delete=(e,consultationId)=>{
    e.stopPropagation()
    const { newslist } = this.state
    alert('确认取消该消息?','', [
      { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
      { text: '确定', onPress: () => {
        
        // 删除问诊消息	
        userdelWznews(consultationId).then(res=>{
 
          let arr=[]
          if (res.code==1) {
            const newTimer = window.setTimeout(() => {
              this.wzNews()
              window.clearTimeout(newTimer)
            }, 50);
            newslist.map(item=>{
              if (item.userDelete==1) {
                arr.push(item)
              }
            })
  
            this.setState({
              newslist: arr
            })
          }
          
        })
      } },
    ],)
  }
  render() {
    const { newslist } = this.state
    return (
      <div className='newsbox'>
        <div className='box-top'>
          消息
        </div>
        <div className='box-container'>
          {/*<div className='container-item'>*/}
          {/*  <img src={icon1} alt="" />*/}
          {/*  <div>*/}
          {/*    <p>*/}
          {/*      <span>系统通知</span><span>2分钟前</span>*/}
          {/*    </p>*/}
          {/*    <p>*/}
          {/*      这里显示消息中最新的信息*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className='container-item'>*/}
          {/*  <img src={icon2} alt="" />*/}
          {/*  <div>*/}
          {/*    <p>*/}
          {/*      <span>优惠促销</span><span>2分钟前</span>*/}
          {/*    </p>*/}
          {/*    <p>*/}
          {/*      这里显示消息中最新的信息*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {newslist.length?newslist.map((news, index) => {
            return (
              <div className='container-item' key={news.consultation_id} onClick={()=>this.goinfo(news)}>
                <img src={news.docter_avatar?news.docter_avatar:icon3} alt="" />
                <div>
                  <p>
                    <span>{news.docter_name}</span><span>{news.content_time}</span>
                  </p>
                  <p style={{overflow: 'hidden',textOverflow: 'ellipsis',whiteSpace: 'nowrap'}}>
                    {news.detail_type=='3'?'处方单':
                        news.detail_type=='2'?'[图片]':news.detail_content}
                  </p>
                </div>
                <p className='delete' onClick={(e)=>this.delete(e,news.consultation_id)}>删除</p>
              </div>
            )
          }):<Nodata type='消息'/>}

        </div>
        <FootNav></FootNav>
      </div>
    )
  }
}
