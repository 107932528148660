import React, { Component } from 'react'
import "./style.less"
import { CheckList, Toast } from 'antd-mobile-v5'
import { SmileOutline } from 'antd-mobile-icons'
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/xia.png"
import icon3 from "../../static/img/yy/xia2.png"
import icon4 from "../../static/img/yy/sy.png"
import icon5 from "../../static/img/yy/sh.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            code: false,
            val: "全部状态",
            val2:"全部患者",
            valtitle: "",
            titlecode1: false,
            titlecode2: false,
        }
    }
    openchiose = (e, num) => {
        e.stopPropagation()
        if (num == 1) {
            this.setState({
                code: true,
                titlecode1: true,
                titlecode2: false
            })
        } else {
            this.setState({
                code: true,
                titlecode1: false,
                titlecode2: true
            })
        }

    }
    getvalue = (val) => {
        const {titlecode1} =this.state
        if (titlecode1) {
            this.setState({
                val: val[0],
                code: false
            })
        }else{
            this.setState({
                val2: val[0],
                code: false
            })
        }
        // this.setState({
        //     val: val[0],
        //     code: false
        // })
        // Toast.show({
        //     content: "选择成功",
        //     duration: 800
        // })
    }
    gobank=()=>{
        window.history.back()
    }
    render() {
        const { code, titlecode1, titlecode2,val,val2 } = this.state
        return (
            <div className='prescriptionlistbox' >
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>处方清单</h1>
                </div>
                <div className='box-listtitle'>
                    <div className={titlecode1 ? 'color' : null} onClick={(e) => this.openchiose(e, 1)}>
                        <span>{val}</span><img src={titlecode1 ? icon2 : icon3} alt="" />
                    </div>
                    <div className={titlecode2 ? 'color' : null} onClick={(e) => this.openchiose(e, 2)}>
                        <span>{val2}</span><img src={titlecode2 ? icon2 : icon3} alt="" />
                    </div>
                </div>
                <div className='box-content'>
                    <h1>
                        <span>李炜</span><span>男</span><span>27岁</span>
                        <img src={icon4} alt="" />
                    </h1>
                    <div>
                        <p>
                            <span>就诊医生：</span>
                            <span>医生名字</span>
                            <span>职位</span>
                        </p>
                        <p>
                            <span>就诊科室：</span>
                            <span>科室名称</span>
                        </p>
                        <p>
                            <span>诊断结果：</span>
                            <span>肺炎</span>
                        </p>
                        <p>
                            <span>下单时间：</span>
                            <span>2022.01.12 14:58</span>
                            <span>云益健互联网医院</span>
                        </p>
                    </div>
                </div>
                <div className='box-content'>
                    <h1>
                        <span>李炜</span><span>男</span><span>27岁</span>
                        <img src={icon5} alt="" />
                    </h1>
                    <div>
                        <p>
                            <span>就诊医生：</span>
                            <span>医生名字</span>
                            <span>职位</span>
                        </p>
                        <p>
                            <span>就诊科室：</span>
                            <span>科室名称</span>
                        </p>
                        <p>
                            <span>诊断结果：</span>
                            <span>肺炎</span>
                        </p>
                        <p>
                            <span>下单时间：</span>
                            <span>2022.01.12 14:58</span>
                            <span>云益健互联网医院</span>
                        </p>
                    </div>
                </div>
                <div className='box-content'>
                    <h1>
                        <span>李炜</span><span>男</span><span>27岁</span>
                        <a className='content-tips'>
                            已过期
                        </a>
                    </h1>
                    <div>
                        <p>
                            <span>就诊医生：</span>
                            <span>医生名字</span>
                            <span>职位</span>
                        </p>
                        <p>
                            <span>就诊科室：</span>
                            <span>科室名称</span>
                        </p>
                        <p>
                            <span>诊断结果：</span>
                            <span>肺炎</span>
                        </p>
                        <p>
                            <span>下单时间：</span>
                            <span>2022.01.12 14:58</span>
                            <span>云益健互联网医院</span>
                        </p>
                    </div>
                </div>
                {code ? <div className='box-chiose'>
                    <CheckList activeIcon={<SmileOutline />} onChange={(val) => this.getvalue(val)} defaultValue={['B']} style={{ backgroundColor: "#d3d3d3" }}>
                        {titlecode1? <>
                            <CheckList.Item value='全部状态'>全部状态</CheckList.Item>
                            <CheckList.Item value='审核中'>审核中</CheckList.Item>
                            <CheckList.Item value='可使用'>可使用</CheckList.Item>
                            <CheckList.Item value='未通过'>未通过</CheckList.Item>
                            <CheckList.Item value='已使用'>已使用</CheckList.Item>
                            <CheckList.Item value='已过期'>已过期</CheckList.Item>
                        </>:<>
                            <CheckList.Item value='全部患者'>全部患者</CheckList.Item>
                            <CheckList.Item value='患者姓名'>患者姓名  男  22岁</CheckList.Item>
                        </>}
                    </CheckList>
                </div> : null}

            </div>
        )
    }
}
