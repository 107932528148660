import React ,{ useState, useEffect } from 'react'
import { Modal} from 'antd-mobile'
import { adressListRequest, updateAdressRequest } from '../../../api/request/adress'

import sure from '../../../static/img/sure.png'
import shuoming from '../../../static/img/shuoming.png'

import './style.less'
const { alert } = Modal
export default function Nowaddress(props){
    const userId = localStorage.type=="1"?localStorage.userId1:localStorage.type=="2"?localStorage.userId2:localStorage.userId3
    const [orderDetail, setOrderDetail] = useState(null)
    const [refresh,setRefresh] = useState(false)
    const [cartArr,setCartArr]=useState([])
    let detail;

    
    useEffect(() => {
        adressListRequest(userId).then(res => {
            if(res.code === 1) {
                setOrderDetail(res.data[0])
            }
        })
    }, [userId])
    useEffect(()=>{
        refresh && setTimeout(() => setRefresh(false))
    },[refresh])
    const backPage=()=>{
        window.history.back()
    }
//   选择地址
    const changeaddress = (e, item,index) => {
        let medList = orderDetail
        let newCartArr = cartArr
        medList[index].checked = e.target.checked

        if(e.target.checked) {
            newCartArr.push({
                medList: [{
                  address: item.address,
                    addressee:item.addressee,
                    phone: item.phone,
                    addressId:item.addressId,
                    province:item.province,
                    district:item.district,
                    city:item.city,
                    longitude:item.longitude,
                    latitude:item.latitude,
                    checked: true
                }]
            })
        } else {
            newCartArr.push({
                medList: [{
                  address: item.address,
                    addressee:item.addressee,
                    phone: item.phone,
                    addressId:item.addressId,
                    province:item.province,
                    district:item.district,
                    city:item.city,
                    longitude:item.longitude,
                    latitude:item.latitude,
                    checked: false
                }]
            })
        }
        setCartArr(newCartArr)
        setRefresh(true)
       
    }

    const toKeepaddress = () => {
        const params = {
            addressDefault: '1',
            address: cartArr[0].medList[0].address,
            addressee:cartArr[0].medList[0].addressee,
            phone:cartArr[0].medList[0].phone,
            addressId:cartArr[0].medList[0].addressId,
            province:cartArr[0].medList[0].province,
            district:cartArr[0].medList[0].district,
            longitude:cartArr[0].medList[0].longitude,
            latitude:cartArr[0].medList[0].latitude,
            city:cartArr[0].medList[0].city,
        }
        alert('是否确认修改收货地址？', '', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            {
                text: '确认', onPress: () => {
                    updateAdressRequest(params).then(res => {
        
                        if (res.code === 1) {
                            window.history.back()
                        }
                    })
                }
            },
        ])
    }
    orderDetail&&orderDetail.map((item, index) => (
        item.addressDefault==='1'?detail=item:null
        // detail=item
    ))

    const editAddress = (type, id=0) => {
        props.history.push(`/mine/adress/edit/${type}/${id}`)
    }
    return(
        <>
            {detail?<div className='header2'>
                <div className='drawback-header'>
                    <div className='drawback-header-left' onClick={backPage}>
                        <i className='icon-xiangzuo iconfont'></i>
                        <span>修改收货地址</span>
                    </div> 
                    <div className='drawback-header-right'>
                        <span onClick={()=> editAddress('add')}>新增地址</span>
                    </div>
                </div>
                <div className='shoufei'>
                    <p>仅能修改一次：当前订单支持修改距离原地址3km内的地址，1km内可免费修改，超出1km需收费</p>
                </div>
                <div className='nowaddress'>
                    <h3>当前下单地址</h3>
                    <p className='address'>{orderDetail&&detail.address}</p>
                    <p className='name-phone'>
                        <span>{orderDetail&&detail.addressee}</span>
                        <span className='phone'>{orderDetail&&detail.phone.toString().substr(0,3)}****{orderDetail&&detail.phone.toString().substr(7,11)}</span>
                    </p>
                </div>
                {orderDetail&&orderDetail.length>1?
                <div className='more-address'>
                    <p className='xiugai'>
                        <h3>可更改地址</h3>
                        <p className='shuoming'>
                            <img src={shuoming} alt="" />
                            <span>修改地址收费说明</span>
                        </p>
                    </p>
                    {orderDetail && orderDetail.map((item, index) => (
                    <div key={index}>
                        <div className='all-address' onClick={(v)=>changeaddress(v,item, index)}>
                            <input type="radio" name='b' />
                            <p className='all-address-phone'>
                                <p className='address'>{item.address}</p>
                                <p className='name-phone'>
                                    <span>{item.addressee}</span>
                                    <span className='phone'>{item.phone.toString().substr(0,3)}****{item.phone.toString().substr(7,11)}</span>
                                </p>
                            </p>
                        </div>
                        {index<orderDetail.length-1?<div className='xian'></div>:null}
                    </div>
                    ))}
                </div>
                :
                <div className='no-more-address'>
                    <p className='xiugai'>
                        <h3>可更改地址</h3>
                        <p className='shuoming'>
                            <img src={shuoming} alt="" />
                            <span>修改地址收费说明</span>
                        </p>
                    </p>
                    <p className='no-xiugai'>暂无符合更改条件的地址，请点击新增地址</p>
                </div>
                }
                <div className='sure-bottom' onClick={()=>toKeepaddress()}>
                    <img src={sure} alt="" />
                    <span>保持地址</span>
                </div>
            </div>:null}
        </>
    )
}