import React, { Component } from 'react'
import "./style.less"
import { ImageUploader, Toast, Popup } from "antd-mobile-v5"
import { ImagePicker } from "antd-mobile"
import icon1 from "../../static/img/yy/fanhui2.png"
import icon2 from "../../static/img/yy/right.png"
import icon3 from "../../static/img/yy/bz.png"
import icon4 from "../../static/img/yy/dian.png"
import icon5 from "../../static/img/yy/pic.png"
import icon6 from "../../static/img/yy/write.png"
import icon7 from "../../static/img/tk.png"
import { getuserinfo, changeuserinfo } from "../../api/hospitalapi/user"
import { zipImg } from '../../component/zipImage'
import { uploadpic } from "../../api/hospitalapi/upload"
import { whethernews, addchat, getchat,phoneTowenzheb } from "../../api/hospitalapi/chat"
import { zfUrl,code } from "../../publicurl"
import { getBase64Image } from "../../api/config"
import getUrl from '../../component/GetUrlParam'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            fileList: [],
            visible1: false,
            visible2: false,
            patientId: localStorage.getItem("patientId"),
            item: JSON.parse(localStorage.getItem("item")),
            userlist: [],
            nowuser: null,
            date: null,
            contenttext: "",
            evaluatePic: [],
            timer: localStorage.getItem('timer'),
            record: '',
            gzDocter: '',
            vedio: '',
            date1: '',
            consultOrg: '',
            accompanyDocName: '',
            accompanyCertificateNum: '',
            knowcode: 1,//知情同意书是否浏览1否 2是
            agecode: 2,//小于6岁监护人告知 1否 2是
            checkfx:1////勾选知情同意书是否浏览1否 2是
        }
    }

    componentDidMount = async () => {
        const {item, timer} = this.state
        // console.log(item);
        if (getUrl('vedio', this.props.location.search)) {
            this.setState({
                vedio: getUrl('vedio', this.props.location.search)
            })
        }
        if (getUrl('record', this.props.location.search)) {
            this.setState({
                record: getUrl('record', this.props.location.search)
            })
        }
        if (getUrl('gzDocter', this.props.location.search)) {
            this.setState({
                gzDocter: getUrl('gzDocter', this.props.location.search)
            })
        }
        window.clearInterval(timer)
        this.getuserlist()
    }
    //获取就诊人信息列表
    getuserlist = async () => {
        const myDate = new Date()
        const {patientId} = this.state
        // 获取就诊人信息列表
        const res = await getuserinfo({
            patientId
        })

        let datalist = res.data[0]
        let nowuser = null
        datalist.forEach((item) => {
            item.age = 1
            let b = item.patient_birthday
            let a = (new Date(b)).getFullYear() + '-' + (((new Date(b)).getMonth() + 1) < 10 ? '0' + ((new Date(b)).getMonth() + 1) : ((new Date(b)).getMonth() + 1)) + '-' + ((new Date(b)).getDate() < 10 ? '0' + (new Date(b)).getDate() : (new Date(b)).getDate())
            // 将出生日期的字符串通过"-"分割成数组
            const strBirthdayArr = a.split("-")
            // 拿到出生日期的年
            const birthYear = strBirthdayArr[0]
            // 拿到出生日期的月
            const birthMonth = strBirthdayArr[1]
            // 拿到出生日期的日
            const birthDay = strBirthdayArr[2]
            // 创建一个时间对象
            const d = new Date()
            // 拿到当前时间的年
            const nowYear = d.getFullYear()
            // 拿到当前时间的月
            const nowMonth = d.getMonth() + 1
            // 拿到当前时间的日
            const nowDay = d.getDate()
            // 如果出生日期的年等于当前时间的年
            if (nowYear === birthYear) {
                item.age = 1
            } else {
                // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
                const ageDiff = nowYear - birthYear; // 年之差
                // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
                // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
                // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
                if (nowMonth != birthMonth) {
                    // 拿到出生日期的日与当前时间的月之差
                    const monthDiff = nowMonth - birthMonth; // 月之差
                    // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
                    item.age = monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                } else {

                    // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
                    const dayDiff = nowDay - birthDay;
                    // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
                    item.age = dayDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                }
                if ( item.age==0){
                    item.age=1
                }
            }
            if (item.patient_default != 0) {
  
                nowuser = item
                if (item.age<6){
                    this.setState({
                        agecode:1
                    })
                }
            }
        })
        this.setState({
            userlist: res.data[0],
            date: myDate.getFullYear(),
            nowuser
        })
    }
    mockUpload = async (file) => {
        return {
            url: URL.createObjectURL(file),
        }
    }
    // setFileList = (list) => {
    //     console.log(list);
    //     this.setState({
    //         fileList: list
    //     })
    // }
    //图片上传发送
    setFileList = async (files, type, index) => {
        const {fileList,compressThreshold = 1 } = this.state
        let imgList = []
        let pictureQuality = 0.5
        if (type === 'add') {
            files.map(item=>{
                let file = item.file
                let fileSize = file.size / 1024 / 1024;
     
                if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
                    if ((fileSize >= compressThreshold) ) {
                        if (fileSize*0.9<=1){
                            pictureQuality=0.9
                        }else if(fileSize*0.8<=1){
                            pictureQuality=0.8
                        }else if(fileSize*0.7<=1){
                            pictureQuality=0.7
                        }else if(fileSize*0.6<=1){
                            pictureQuality=0.6
                        }else if(fileSize*0.5<=1){
                            pictureQuality=0.5
                        }else if(fileSize*0.4<=1){
                            pictureQuality=0.4
                        }else if(fileSize*0.3<=1){
                            pictureQuality=0.3
                        }else if(fileSize*0.2<=1){
                            pictureQuality=0.2
                        }else if(fileSize*0.1<=1){
                            pictureQuality=0.1
                        }else{
                            pictureQuality=0.5
                        }
              
                        //判断浏览器内核是否支持base64图片压缩
                        if (typeof (FileReader) === 'undefined') {
                            return file;
                        } else {
                            try {
                                this.setState({
                                    spinLoading: true
                                });
                                return new Promise(resolve => {
                                    //压缩图片再上传
                                    let a=getBase64Image(file,pictureQuality,callback1)
                                    let that=this
                                    function callback1(val) {
                                        //base64转png图片
                                        zipImg(val).then(res1 => {
                                    
                                            let formData = new FormData()
                                            formData.append('file', res1)
                                            uploadpic(formData).then(res2 => {
                                   
                                                if(res2.code === 2) {
                                                    that.setState({
                                                        fileList: files
                                                    })
                                                    imgList.push(res2.data)
                                                } else {
                                                    Toast.info('上传失败', 1)
                                                }
                                                that.setState({
                                                    evaluatePic: imgList
                                                },()=>{
                                                    // console.log(imgList);
                                                })
                                                // Toast.hide()
                                            }).catch(err => {
                                                // Toast.hide()
                                            })
                                        })
                                    }
                                }).then(res => {
                                    this.setState({
                                        spinLoading: false
                                    });
                                    // console.log(res)

                                    // return res;
                                }).catch(() => {
                                    this.setState({
                                        spinLoading: false
                                    });
                                    return file;
                                });
                            } catch (e) {
                                this.setState({
                                    spinLoading: false
                                });
                                //压缩出错,直接返回原file对象
                                return file;
                            }
                        }
                    }else{
                        zipImg(file).then(res => {
                            let formData = new FormData()
                            // console.log(res)
                            formData.append('file', res)
                            uploadpic(formData).then(res1 => {
                                if (res1.code === 2) {
                                    this.setState({
                                        fileList: files
                                    })
                                    imgList.push(res1.data)
                                } else {
                                    Toast.info('上传失败', 1)
                                }
                                this.setState({
                                    evaluatePic: imgList
                                })
                                // console.log(imgList);
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                } else {
                    Toast.show('请上传png/jpg/jpeg格式的图片', 1)
                }
            })

        } else {
            let imgList1=[]
            imgList.splice(index, 1)
            files.map(item=>{
                let file = item.file
                let fileSize = file.size / 1024 / 1024;
                // console.log(files);
                if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
                    if ((fileSize >= compressThreshold) ) {
                        if (fileSize*0.9<=1){
                            pictureQuality=0.9
                        }else if(fileSize*0.8<=1){
                            pictureQuality=0.8
                        }else if(fileSize*0.7<=1){
                            pictureQuality=0.7
                        }else if(fileSize*0.6<=1){
                            pictureQuality=0.6
                        }else if(fileSize*0.5<=1){
                            pictureQuality=0.5
                        }else if(fileSize*0.4<=1){
                            pictureQuality=0.4
                        }else if(fileSize*0.3<=1){
                            pictureQuality=0.3
                        }else if(fileSize*0.2<=1){
                            pictureQuality=0.2
                        }else if(fileSize*0.1<=1){
                            pictureQuality=0.1
                        }else{
                            pictureQuality=0.5
                        }
                        // console.log(pictureQuality)
                        //判断浏览器内核是否支持base64图片压缩
                        if (typeof (FileReader) === 'undefined') {
                            return file;
                        } else {
                            try {
                                this.setState({
                                    spinLoading: true
                                });
                                return new Promise(resolve => {
                                    //压缩图片再上传
                                    let a=getBase64Image(file,pictureQuality,callback1)
                                    let that=this
                                    function callback1(val) {
                                        //base64转png图片
                                        zipImg(val).then(res1 => {
                                            // console.log(res1)
                                            let formData = new FormData()
                                            formData.append('file', res1)
                                            uploadpic(formData).then(res2 => {
                                                // console.log(res2)
                                                if(res2.code === 2) {
                                                    that.setState({
                                                        fileList: files
                                                    })
                                                    imgList.push(res2.data)
                                                } else {
                                                    Toast.info('上传失败', 1)
                                                }
                                                that.setState({
                                                    evaluatePic: imgList
                                                },()=>{
                                                    // console.log(imgList);
                                                })
                                                // Toast.hide()
                                            }).catch(err => {
                                                // Toast.hide()
                                            })
                                        })
                                    }
                                }).then(res => {
                                    this.setState({
                                        spinLoading: false
                                    });
                                    // console.log(res)

                                    // return res;
                                }).catch(() => {
                                    this.setState({
                                        spinLoading: false
                                    });
                                    return file;
                                });
                            } catch (e) {
                                this.setState({
                                    spinLoading: false
                                });
                                //压缩出错,直接返回原file对象
                                return file;
                            }
                        }
                    }else{
                        zipImg(file).then(res => {
                            let formData = new FormData()
                            // console.log(res)
                            formData.append('file', res)
                            uploadpic(formData).then(res1 => {
                                if (res1.code === 2) {
                                    this.setState({
                                        fileList: files
                                    })
                                    imgList.push(res1.data)
                                } else {
                                    Toast.info('上传失败', 1)
                                }
                                this.setState({
                                    evaluatePic: imgList
                                })
                                // console.log(imgList);
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                } else {
                    Toast.show('请上传png/jpg/jpeg格式的图片', 1)
                }
            })
            this.setState({
                fileList: files,
                evaluatePic: imgList1
            })
            // console.log(files);
            // Toast.hide()
        }


        // if (type == "add") {
        //     let file = files[files.length - 1].file
        //     let formData = new FormData()
        //     const res = await zipImg(file)
        //     // console.log(res);
        //     formData.append('file', res)
        //     const res2 = await uploadpic(formData)
        //     //  console.log(res2);
        //     this.setState({
        //         fileList: [...files]
        //     })
        //     console.log([...files]);
        //     // if (item) {
        //     //     const res = await addchatnote({
        //     //         consultationId: consultationId,
        //     //         Identity: 1,
        //     //         detailType: 2,
        //     //         detailContent: res2.data
        //     //     })
        //     //     this.getchatnews()
        //     //     this.setState({
        //     //         detailContent: ""
        //     //     })
        //     // } else {
        //     //     Toast.fail("数据错误", 1)
        //     //     this.props.history.push("/")
        //     // }
        // } else {
        //     this.setState({
        //         fileList: []
        //     })
        // }
    }
    beforeUpload = (files) => {
        // console.log(files);
        return files.filter(file => {
            if (file.size > 1024 * 1024) {
                Toast.show('请选择小于 1M 的图片')
                return false
            }
            return true
        })
    }
    // 打开就诊人列表弹窗
    openbox = () => {
        this.setState({
            visible1: true
        })
    }
    // 返回
    gobank = () => {
        const {record, gzDocter} = this.state
        this.props.history.push(`/doctorinfo?record=${record}&gzDocter=${gzDocter}`)
    }
    //更改就诊人信息状态
    changedefault = async (item) => {
        const {nowuser} = this.state
        if (nowuser) {
            const res = await changeuserinfo({
                memberId: nowuser.member_id,
                patientDefault: 0
            })
        }
        const res = await changeuserinfo({
            memberId: item.member_id,
            patientDefault: 1
        })
        this.getuserlist()
        if (res.code == 1) {
            // Toast.show({
            //     content: "操作成功",
            //     duration: 1000
            // })
            if (item.age < 6) {
                this.setState({
                    agecode: 1
                })
            }
            this.setState({
                visible1: false
            })
        }
        // console.log(res);
    }
    // 获取病情内容
    getvalue = (e) => {
        this.setState({
            contenttext: e.target.value.replace(/\s*/g, "")
        })
    }
    //去支付、聊天
    topay = async () => {
        const {
            nowuser,
            contenttext,
            item,
            patientId,
            knowcode,
            vedio,
            fileList,
            orderNumber,checkfx,
            date1,
            consultOrg,
            accompanyDocName,
            accompanyCertificateNum,
            evaluatePic,
        } = this.state
        // console.log(evaluatePic.toString());
        if (nowuser) {
            if (contenttext) {
                if (evaluatePic.length) {
                    if (date1) {
                        if (consultOrg) {
                            if ((nowuser.age < 6 && accompanyDocName && accompanyCertificateNum) || nowuser.age >= 6) {
                                if (knowcode == 2&&checkfx==2) {
                                    if (item) {
                                        if (vedio) {
                                            let params1 = {
                                                memberId: nowuser.member_id,
                                                docterId: item.docterId,
                                                userId: patientId,
                                                consultationAmount: item.docterVideoCharge,
                                                consultationDetail: contenttext,
                                                consultationFiles: evaluatePic.toString(),
                                                consultTime: date1,
                                                consultationType:'3',
                                                consultOrg,
                                                patientName: nowuser.patient_name,
                                                patientSex: nowuser.patient_sex == '男' ? 1 : 2,
                                                patientIdcardNum: nowuser.patient_id_card,
                                                guardianName: nowuser.age < 6 ? nowuser.guardianName : '',
                                                guardianIdcardNum: nowuser.age < 6 ? nowuser.guardianIdcardNum : '',
                                                accompanyDocName: nowuser.age < 6 ? accompanyDocName : '',
                                                accompanyCertificateNum: nowuser.age < 6 ? accompanyCertificateNum : '',
                                                // patientBirthday:nowuser.patient_birthday,
                                                patientBirthday: (new Date(nowuser.patient_birthday)).getFullYear() + '-' + (((new Date(nowuser.patient_birthday)).getMonth() + 1) < 10 ? '0' + ((new Date(nowuser.patient_birthday)).getMonth() + 1) : ((new Date(nowuser.patient_birthday)).getMonth() + 1)) + '-' + ((new Date(nowuser.patient_birthday)).getDate() < 10 ? '0' + (new Date(nowuser.patient_birthday)).getDate() : (new Date(nowuser.patient_birthday)).getDate()),
                                                patientCity: nowuser.patient_city,
                                                patientPhone: nowuser.patient_phone,
                                                pastHistory: nowuser.past_history,
                                                pastHistoryNote: nowuser.past_history_note,
                                                allergicHistory: nowuser.allergic_history,
                                                allergicHistoryNote: nowuser.allergic_history_note,
                                                familyGeneticHistory: nowuser.family_genetic_history,
                                                familyGeneticHistoryNote: nowuser.family_genetic_history_note,
                                                abnormalLiverFunction: nowuser.abnormal_liver_function,
                                                abnormalRenalFunction: nowuser.abnormal_renal_function,
                                                patientAge: nowuser.age,
                                                cityId: nowuser.city_id
                                            }
                                            let res = await phoneTowenzheb(params1)
                                            // console.log(res)
                                            if (res && res.data && res.data[1]) {
                                                let orderNumber = res.data[1][0].consultationNumber
                                                let b = (new Date()).getTime()
                                                let orderName = '视频问诊支付'
                                                window.location.href = `${zfUrl}/route?client_id=${code}&pharmacyId=${1}&channel=&client_trade_no=${orderNumber}&total_fee=${item.docterVideoCharge}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
                                            }
                                        } else {
                                            const res = await whethernews({
                                                userId: patientId,
                                                docterId: this.state.item.docterId
                                            })
                                            // console.log(res);
                                            if (res.data[0][0].chatNum == 0) {
                                                const res2 = await addchat({
                                                    userId: patientId,
                                                    docterId: this.state.item.docterId,
                                                    consultationAmount: item.docterPicCharge,
                                                    consultationDetail: contenttext,
                                                    consultationFiles:  evaluatePic.toString(),
                                                    consultTime: date1,
                                                    consultationType:'1',
                                                    consultOrg,
                                                    patientName: nowuser.patient_name,
                                                    patientSex: nowuser.patient_sex == '男' ? 1 : 2,
                                                    patientIdcardNum: nowuser.patient_id_card,
                                                    guardianName: nowuser.age < 6 ? nowuser.guardianName : '',
                                                    guardianIdcardNum: nowuser.age < 6 ? nowuser.guardianIdcardNum : '',
                                                    accompanyDocName: nowuser.age < 6 ? accompanyDocName : '',
                                                    accompanyCertificateNum: nowuser.age < 6 ? accompanyCertificateNum : '',
                                                    patientBirthday: (new Date(nowuser.patient_birthday)).getFullYear() + '-' + (((new Date(nowuser.patient_birthday)).getMonth() + 1) < 10 ? '0' + ((new Date(nowuser.patient_birthday)).getMonth() + 1) : ((new Date(nowuser.patient_birthday)).getMonth() + 1)) + '-' + ((new Date(nowuser.patient_birthday)).getDate() < 10 ? '0' + (new Date(nowuser.patient_birthday)).getDate() : (new Date(nowuser.patient_birthday)).getDate()),
                                                    patientCity: nowuser.patient_city,
                                                    patientPhone: nowuser.patient_phone,
                                                    pastHistory: nowuser.past_history,
                                                    pastHistoryNote: nowuser.past_history_note,
                                                    allergicHistory: nowuser.allergic_history,
                                                    allergicHistoryNote: nowuser.allergic_history_note,
                                                    familyGeneticHistory: nowuser.family_genetic_history,
                                                    familyGeneticHistoryNote: nowuser.family_genetic_history_note,
                                                    abnormalLiverFunction: nowuser.abnormal_liver_function,
                                                    abnormalRenalFunction: nowuser.abnormal_renal_function,
                                                    patientAge: nowuser.age,
                                                    cityId: nowuser.city_id
                                                })
                                                // console.log(res2);
                                                if (res2.code == 1) {
                                                    window.localStorage.removeItem('orderNumber')
                                                    window.localStorage.removeItem('consultationId')
                                                    let b = (new Date()).getTime()
                                                    let orderName = '问诊支付'
                                                    window.location.href = `${zfUrl}/route?client_id=${code}&pharmacyId=${1}&channel=&client_trade_no=${res2.data[2][0].consultationNumber}&total_fee=${item.docterPicCharge}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
                                                }
                                            }
                                        }

                                        // this.props.history.push("/doctorinfo/chat")
                                    }
                                } else {
                                    Toast.show({
                                        content: "请先仔细阅读并勾选风险告知及患者知情同意书",
                                        duration: 1000
                                    })
                                }

                            } else {
                                Toast.show({
                                    content: "请输入陪伴医师信息",
                                    duration: 1000
                                })
                            }
                        } else {
                            Toast.show({
                                content: "请输入首诊机构",
                                duration: 1000
                            })
                        }
                    } else {
                        Toast.show({
                            content: "请选择首诊时间",
                            duration: 1000
                        })
                    }

                } else {
                    Toast.show({
                        content: "请上传线下首诊凭证",
                        duration: 1000
                    })
                }
            } else {
                Toast.show({
                    content: "请填写病史描述",
                    duration: 1000
                })
            }
        } else {
            Toast.show({
                content: "请选择或添加用药人",
                duration: 1000
            })
        }
    }
    toWrite = (e, item) => {
        const {vedio, record, gzDocter} = this.state
        e.preventDefault()
        e.stopPropagation()
        this.props.history.replace(`/writelist?record=${record}&gzDocter=${gzDocter}&vedio=${vedio}`)
        this.props.history.push(`/patient?memberId=${item.member_id}`)
        // this.props.history.push({
        //     pathname: "/patient",
        //     params: {
        //         list0: item
        //     }
        // })
    }
    toRisknotification = () => {
        // this.props.history.push(`/risknotification`)
        this.setState({
            visible2: true
        })
    }
    setvalue = (e, type) => {
        switch (type) {
            case "date1":
                // console.log(e.target.value);
                let b = e.target.value.replaceAll("-", "")
                let a = (new Date()).getFullYear() + '-' + (((new Date()).getMonth() + 1) < 10 ? '0' + ((new Date()).getMonth() + 1) : ((new Date()).getMonth() + 1)) + '-' + ((new Date()).getDate() < 10 ? '0' + (new Date()).getDate() : (new Date()).getDate())
                // console.log(a);
                let c = a.replaceAll("-", "")

                if (b > c) {
                    this.setState({
                        date1: a,
                    })
                    Toast.show({
                        content: "请选择正确的日期",
                        duration: 1000
                    })
                } else {
                    this.setState({
                        date1: e.target.value,
                    })
                }
                break;
            case "consultOrg":
                this.setState({
                    consultOrg: e.target.value.replace(/\s*/g, ""),
                })
                break;
            case "accompanyDocName":
                this.setState({
                    accompanyDocName: e.target.value.replace(/\s*/g, ""),
                })
                break;
            case "accompanyCertificateNum":
                this.setState({
                    accompanyCertificateNum: e.target.value.replace(/\s*/g, ""),
                })
                break;
            default:
                return;
        }
    }
    //知情同意书确认
    toKnow = () => {
        this.setState({
            visible2: false,
            knowcode: 2
        })
    }
    //小于6岁监护人告知
    knowAge=()=>{
        this.setState({
            agecode: 2
        })
    }
    //勾选知情同意书确认
    isKnow=()=>{
        const {checkfx,knowcode} = this.state
        if(checkfx==2){
            this.setState({
                checkfx:1
            })
        }else{
            if (knowcode==1){
                Toast.show({
                    content: "请仔细阅读并勾选风险告知及患者知情同意书",
                    duration: 1000
                })
            }
            this.setState({
                checkfx:2
            })
        }
    }
    render() {
        const {
            fileList,
            visible1,
            agecode,
            visible2,
            userlist,
            item,
            date,checkfx,
            date1,
            vedio,
            nowuser,
            contenttext
        } = this.state
        // console.log(checkfx)
        return (
            <>
                {visible2 ? <div className='setbox risknotification' style={{backgroundColor: '#fff'}}>
                    <div className='box-top box-top33'>
                        <img src={icon1} alt="" onClick={() => this.props.history.push("/writelist")}/> 互联网诊疗风险告知及知情同意书
                    </div>
                    <h2 className='risk-title'>风险告知及患者知情同意书</h2>
                    <p className='risk-body risk-body1'>
                        <p>亲爱的患者及家属朋友，您好！</p>
                        <p>
                            欢迎来到中江云益健互联网医院平台（下称“平台”或“我们”）。本平台上的互联网医院为患者（下称“患者”或“您”）提供包括但不限于互联网诊疗咨询、复诊开方、健康管理等服务（下称“在线诊疗”）。我们特将在线诊疗的相关重要事项告知您及您的家属，请认真阅读并理解，尤其是下划线部分文字。
                        </p>
                        <p>一、在线诊疗具有相当的局限性</p>
                        <p>
                            由于在线诊疗过程中不能进行现场查体，对某些隐蔽性症状体征可能会有所疏忽，不能及时在线开展实验室生化检查和影像检查等，不能对患者急危重症采取快速现场应对；故医方在线诊疗服务中能够采取的有效措施较为有限。
                        </p>
                        <p>
                            在线诊疗中医生获取的信息完全有赖于您及您家属通过语音、图文的准确表述；故请您和您的家属务必配合医务人员进行必要、准确的病情描述，并按要求提供相应的首诊、复诊凭证。若因您及您家属对病情的描述不准确、不完整、不配合等，均有可能因对病情描述不当导致误诊误治的不利后果；若因此则需患者对此承担相应责任。
                        </p>
                        <p>二、疾病的自身风险</p>
                        <p>
                            疾病的病程演变是复杂多变，且往往因为个体具有一定差异性。同时，常会有病情恶化、治疗不效、合并症加重、并发症出现，医疗意外等风险随时发生。有的可能是可以预见，有的是不能预见，有的是难以预见也难以预防
                            的，这些都是疾病本身产生的风险。若您在在线诊疗中或在线诊疗后，若发现自身的症状和体征发生改变或恶化，或有明显的身体不适，请您立刻中断在线诊疗或停止相应治疗方案，并及时就近急诊就医，以免贻误病情。
                        </p>
                        <p>三、患者端线上行为的当然效果</p>
                        <p>
                            由于线上诊疗中，不同于线下现场诊疗，我们虽然已经对患者的实名信息予以核验，但平台难以时时核验患者端操作人的实人身份。对于患者端操作人开展病情告知、医患问答、发表选择和决定性意见、签署平台内的各种法律文件和办理在线诊疗中的流程手续，互联网医院将视同为该患者端操作人为患者本人，或已经取得患者本人充分授权，或作为患者的监护人有权操作；故该患者端操作人所进行的任何操作行为，对患者均具有相应的法律效力。
                        </p>
                        <p>
                            若患者端操作并非患者本人，为患者的授权人或患者的监护人，则应充分了解患者的症状体征，并尽量全面完整的提供相应的线下诊疗书面资料，以便接诊医生获取充分疾病信息并开展相应诊治措施。
                        </p>
                        <p>四、对个人健康信息的处理及保护</p>
                        <p>
                            我们非常重视并致力于对个人健康信息的保护，且遵循合法、正当、必要的原则来收集您提交的各项个人健康信息，用于线上医生为您服务。我们采取符合业界标准、合理可行的方式对患者的个人健康信息进行存储及保护。
                        </p>
                        <p> 五、其他在线诊疗的风险告知</p>
                        <p>
                            疾病的治愈需要患者谨遵医嘱、健康管理方案，并积极配合。如果患者未完全遵医嘱，则可能导致诊疗效果不理想，甚至出现病情反复、恶化等不良后果。
                        </p>
                        <p>
                            患者自采药品的品牌、规格、性状、使用方式等可能影响本次诊疗方案的效果，同时还可能出现危害生命健康的风险。
                        </p>
                        <p>
                            医生主要解决本专业领域的医疗问题，非本专业的疾病需要到其它专业科室进行诊治或接受远程医疗服务。
                        </p>
                        <p>
                            本患者知情同意书未能一一列出所有风险，如果患者有疑问应与医生讨论。
                        </p>
                        <p>
                            您理解并同意，互联网医院将对您的个人健康信息等与病历相关的信息依法予以保存，该信息将保存至中江云益健互联网医院平台的健康档案中心（该名称如发生变化则适用新的名称）；您在享受中江云益健互联网医院平台内为您展示的其他服务时，您可以选择调用或使用在本次服务中的相关个人信息，帮助您便捷得享受其他服务；例如：您在使用中江云益健互联网医院平台提供的线上问诊服务时，可以调用互联网医院电子档案的就诊人的姓名、身份照号、性别、年龄等信息，便于您准确和及时得提供信息；您在使用线上购药的服务时，可以调用互联网医院及电子档案的就诊人的姓名、身份照号、性别、年龄、历史就诊信息或主诉描述等信息，便于您快速问诊或选择对应的药品；届时您将可以直接选择调用健康档案内的信息，或者自行填写新的信息，我们均按您的需求予以支持。
                        </p>
                        <p>六、您确认如下：</p>
                        <p>
                            若您选择在本平台上的互联网医院享受在线诊疗服务，您确认您已经在线下实体医疗机构进行过诊疗且有明确临床诊断结论。您可以提供或上传您的相关的病历资料，愿意接受在线诊疗服务。您确认既往发生过与本次发病类似的常见病、慢性病症，且本次参与在线诊疗的症状和体征均和既往线下就诊情况基本一致，您不会向医生提供虚假、不实的信息。您愿意接受互联网医院提供旳在线诊疗服务，并协助平台上的互联网医院完善健康档案。
                        </p>
                        <p>
                            您确认已经完全理解并同意以上全部內容，已经知悉相关的风险，愿意接受在线诊疗服务，并知悉和同意本风险告知及知情同意书。
                        </p>
                        <p className='back' onClick={() => this.toKnow()}>我已知晓</p>
                    </p>
                </div> : null}
                <div className='writebox'>
                    <div className='box-top box-top33'>
                        <img src={icon1} alt="" onClick={this.gobank}/>
                    </div>
                    {nowuser != null ? <div className='box-chiose' onClick={this.openbox}>
                        <div>
                            <p>{nowuser?.patient_name}</p>
                            <p style={{color: "#999999"}}><span>{nowuser?.patient_sex}</span>
                                <span>{nowuser?.age}岁</span>
                                <span>{nowuser?.patient_phone}</span></p>
                        </div>
                        <img src={icon2} alt=""/>
                    </div> : <div className='box-chiose' onClick={this.openbox}>
                        <div>
                            <p><span style={{color: "#ff0000", marginRight: '5px', fontSize: '16px'}}>*</span>选择就诊人</p>
                        </div>
                        <img src={icon2} alt=""/>
                    </div>}
                    <div style={{height: "20px"}}></div>
                    <div className='information-all'>
                        <div className='information-item'>
                            <div><span style={{color: "#ff0000", marginRight: '5px', fontSize: '16px'}}>*</span>首诊时间
                            </div>
                            <div>
                                <input type="date" value={date1} onChange={e => this.setvalue(e, "date1")}/>
                            </div>
                        </div>
                        <div className='information-item'>
                            <div><span style={{color: "#ff0000", marginRight: '5px', fontSize: '16px'}}>*</span>首诊机构
                            </div>
                            <div>
                                <input className='jhr' type="text" placeholder='请输入首诊机构'
                                       onChange={e => this.setvalue(e, "consultOrg")}/>
                            </div>
                        </div>
                        {nowuser && nowuser.age < 6 ? <>
                            <div className='information-item'>
                                <div><span style={{color: "#ff0000", marginRight: '5px', fontSize: '16px'}}>*</span>陪伴医师姓名
                                </div>
                                <div>
                                    <input className='jhr' type="text" placeholder='请输入陪伴医师姓名（小于6岁必填）'
                                           onChange={e => this.setvalue(e, "accompanyDocName")}/>
                                </div>
                            </div>
                            <div className='information-item'>
                                <div><span style={{color: "#ff0000", marginRight: '5px', fontSize: '16px'}}>*</span>陪伴医师执业证号
                                </div>
                                <div>
                                    <input className='jhr' type="text" placeholder='请输入陪伴医师执业证号码（小于6岁必填）'
                                           onChange={e => this.setvalue(e, "accompanyCertificateNum")}/>
                                </div>
                            </div>
                        </> : null}
                    </div>
                    <div className='box-text'>
                        <h1><span style={{color: "#ff0000", marginRight: '5px', fontSize: '16px'}}>*</span>病史描述</h1>
                        <textarea style={{backgroundColor: "#e5e5e5"}} name="" id="" rows={6} maxLength={200}
                                  value={contenttext} onChange={(e) => this.getvalue(e)}
                                  placeholder='为了更好的获得医生帮助，请尽可能详细描述您 的病情。(最多输入200字)'></textarea>
                        <p className='text-title'><span
                            style={{color: "#ff0000", marginRight: '5px', fontSize: '16px'}}>*</span>上传线下首诊凭证(最多可传4张)</p>
                        <div className='text-pic'>
                            {/* <ImageUploader
                                value={fileList}
                                maxCount={1}
                                beforeUpload={this.beforeUpload}
                                onChange={(list) => this.setFileList(list)}
                                upload={this.mockUpload}
                            /> */}

                            <ImagePicker
                                files={fileList}
                                length={4}
                                style={{width: "300px", height: "100px", padding: "0", display: "inline-block"}}
                                onChange={this.setFileList}
                                selectable={fileList.length < 4}
                                accept='image/gif,image/jpeg,image/jpg,image/png'/>
                        </div>
                    </div>
                    <div className='box-tips'>
                        <img src={icon3} alt="" className='tips-img'/>
                        <div className='tips-item'>
                            <div>
                                <img src={icon4} alt=""/><span>实名医生</span>
                            </div>
                            <div>
                                <img src={icon4} alt=""/><span>及时回复</span>
                            </div>
                            <div>
                                <img src={icon4} alt=""/><span>多次沟通</span>
                            </div>
                            <div>
                                <img src={icon4} alt=""/><span>退款保障</span>
                            </div>
                        </div>
                    </div>
                    <div className='read'>
                        <input type='radio' name='a' checked={checkfx==2} onClick={()=>this.isKnow()}/>
                        <span className='aa'>
                             根据国家互联网诊疗相关政策的要求，请您在使用服务前仔细阅
                        读<span onClick={() => this.toRisknotification()}>《风险告知及患者知情同意书》</span>，如您并非线上复诊，平台上
                        的医师仅为您提供健康咨询服务，咨询意见可供参考。继续操作
                        表示您知悉并同意上述告知内容
                        </span>
                    </div>
                    {vedio ? <div className='box-money'>
                        <img src={icon5} alt=""/>
                        <div>
                            <h1>视频问诊</h1>
                            <p className='price-pay'>￥{item && (item.docterVideoCharge / 100).toFixed(2)}</p>
                            <p>最长15分钟视频沟通</p>
                        </div>
                    </div> : <div className='box-money'>
                        <img src={icon5} alt=""/>
                        <div>
                            <h1>图文问诊</h1>
                            <p className='price-pay'>￥{item && (item.docterPicCharge / 100).toFixed(2)}</p>
                            <p>最长48小时图文多次沟通</p>
                        </div>
                    </div>}
                    <div className='box-bottom'>
                        <button onClick={this.topay}>支付</button>
                    </div>
                </div>
                {agecode == 1 ?
                    <div className='agecode-outer'>
                        <div className='agecode-inner'>
                            <p className='agecode-inner-one'>关于6岁以下儿童开具互联网诊疗须知</p>
                            <p className='agecode-inner-two'>为 6 岁以下儿童进行互联网诊疗时， 应当确定患儿有监护人和相关专业医师陪伴。</p>
                            <p className='agecode-inner-konw' onClick={()=>this.knowAge()}>好，我已了解</p>
                        </div>
                    </div>
                    : null}
                <Popup
                    visible={visible1}
                    className='weitepopup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{
                        height: '60vh',
                        overflow: 'scroll',
                        borderTopRightRadius: '10px',
                        borderTopLeftRadius: '10px'
                    }}
                >
                    <div className='popup-box'>
                        <h1>选择就诊人</h1>
                        {userlist.map((item, index) => {
                            return (
                                <div className='pbox-item' key={item.create_time}
                                     onClick={() => this.changedefault(item)}>
                                    <div>
                                        <p>{item.patient_name}</p>
                                        <p>
                                            <span>{item.patient_sex}</span>
                                            <span>{item.age}岁</span>
                                            <span>{item.patient_phone}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <span style={{color: '#0076FF'}}>{item.patient_default == 1 ? "已选择" : ""}</span><img
                                        src={icon6} alt="" onClick={(e) => this.toWrite(e, item)}/>
                                    </div>
                                </div>
                            )
                        })}
                        <div className='pbox-btn'>
                            <button onClick={() => {
                                this.props.history.push("/patient")
                            }}>新增就诊人
                            </button>
                        </div>
                    </div>
                </Popup>
            </>
        )
    }
}