import React, { Component } from "react";
import "./style.less";
import moment from "moment";
import { ImageViewer, Popup, Rate, TextArea } from "antd-mobile-v5";
import { Toast, ImagePicker, Modal, Button } from "antd-mobile";
import icon1 from "../../static/img/yy/fanhuihei.png";
import icon2 from "../../static/img/yy/touxiang.png";
import icon3 from "../../static/img/yy/pt.png";
import icon4 from "../../static/img/off-wenzhen.png";
import icon5 from "../../static/img/offtouxiang.png";
import icon6 from "../../static/img/add-offimg.png";
import icon7 from "../../static/img/offdoc.png";
import icon12 from "../../static/img/hascf.png";
import fhhome from "../../static/img/icon/fhhome.png";
import Draggable from "react-draggable";
// import vConsole from '../../../src/api/vconsole'

import icon02 from "../../static/img/close1.png";
import icon03 from "../../static/img/contact-complaint.png";
import gdphone from "../../static/img/guaduanphone.png";


import {
  getchat,
  wzstate,
  getDocid,
  addchatnote,
  getChatnote,
  allPrespribingids,
  patientDocdiagnosis,
  getStatus,
  patientWz,
  patientStar,
  lookStar,
  allMedicinal,
  prescribingDetail,
  caWrite,
  getOrdernumber,
  isChineseMeds,
  chineseMethod,
  chineseMethodDoc,
  prescribingDetails,
  dialogueTemplate
} from "../../api/hospitalapi/chat";
import { zipImg } from "../../component/zipImage";
import { uploadpic, videoSetting } from "../../api/hospitalapi/upload";
import { allmedsNews, addvideoMessage } from "../../api/hospitalapi/offline";
import {
  changeuser,
  getdoctorlist,
  savevideoTime,
} from "../../api/hospitalapi/home";
import { callWenzhen } from "../../api/hospitalapi/videowz";
import getUrl from "../../component/GetUrlParam";
import { getBase64Image } from "../../api/config";
import VideoForReact from "trtc-simple-react";
import wzz from "../../static/img/wzz.png";
import { map } from "lodash";
const alert = Modal.alert;
export default class index extends Component {
  constructor() {
    super();
    this.state = {
      item: getUrl("patientPhone", window.location.href)
        ? ""
        : JSON.parse(localStorage.getItem("item")),
      visible1: false,
      visible2: false,
      visible5: false,
      value: "",
      consultationId: "",
      consultationStu: "",
      detailContent: "",
      detailContent0: localStorage.getItem("detailContent0")
        ? localStorage.getItem("detailContent0")
        : "",
      newslist: [],
      userpic: localStorage.getItem("patientAvatar")
        ? localStorage.getItem("patientAvatar")
        : "",
      userId: localStorage.getItem("patientId1")
        ? localStorage.getItem("patientId1")
        : "",
      fileList: [],
      listtext: getUrl("patientPhone", window.location.href)
        ? ""
        : JSON.parse(localStorage.getItem("listtext")),
      consultationDetail: "",
      timer: 0,
      docterDiagnosis: "",
      star: "",
      commentValue: "",
      stateStar: 0,
      starValue: "",
      memberId: "",
      imgFd: 0,
      index1: -1,
      bottomcode: "",
      item00: "",
      orderNumber: localStorage.getItem("orderNumber")
        ? localStorage.getItem("orderNumber")
        : "",
      docterId: "",
      details: "",
      chatLength: 0,
      consultationFiles: "",
      image: {
        url: "",
        visible: false,
      },
      record: "",
      record1: "",
      gzDocter: "",
      consultationReason: "",
      docuserType: "",
      list: [
        { value: "回复及时", key: 1 },
        { value: "服务专业", key: 1 },
        { value: "非常热心", key: 1 },
        {
          value: "问题解答很有帮助",
          key: 1,
        },
        { value: "服务热情专业度高", key: 1 },
      ],
      medsNews: "",
      list2: "",
      detail: "",
      docterAutograph: "",
      docterAutograph1: "",
      video: null,
      videofig: 0, //视频状态1视频中
      useTime: 0, //问诊时长
      duration: 0, //最终视频时长
      videoTime: 0, //视频时长
      videocode: 1, //视频最小，默认1,2 视频最大化
      userData: {
        sdkAppId: "",
        userSig: "",
      },
      ischinese:[],//是否是中药问诊单
      chinesemethod:[],//用法用量
      preDetails:'', // 问诊单详情
      supplementVisible:true,//是否有补充弹窗
    };
    this.inputRef = React.createRef();
    this.initUser = (element) => {
      this.video = element;
    };
  }

  componentDidMount = async () => {
    const { userId, item, consultationId, docterId, orderNumber,supplementVisible } = this.state;
    // this.video.handleJoin();//初始化视频

    if (getUrl("consultationId", this.props.location.search)) {
      this.setState(
        {
          consultationId: getUrl("consultationId", this.props.location.search),
          docterId: getUrl("docterId", this.props.location.search),
          docuserType: getUrl("docuserType", this.props.location.search)
            ? getUrl("docuserType", this.props.location.search)
            : "",
        },
        () => {
          // if (getUrl('docuserType', this.props.location.search)){
          //     this.props.history.replace(`/offlineDoctorchat?consultationId=${ getUrl('consultationId', this.props.location.search)}`)
          // }
        }
      );
    }

    // 患者获取图文信息
    const res = await patientWz({
      userId,
      docterId: getUrl("docterId", this.props.location.search),
      consultationId: getUrl("consultationId", this.props.location.search),
    });

    let dataChat = res.data[1]
    let lastDataChat = dataChat[dataChat.length - 1];
    let lastchat = lastDataChat.detailContent
    // console.log(lastDataChat.detailContent,'查询最后一句对话是什么',supplementVisible);
    let reDialogDataThree = await dialogueTemplate({dialogSequence:3})
    let isInResData = reDialogDataThree.data[0].every(item => item.template_content != lastchat)
    // 如果最后一句话不是"请问您是否还有其它信息需要补充？如无，我将依据您的病历资料开具处方。"，那么需要关闭
    if(isInResData){
      this.setState({
        supplementVisible:false
      })
    }
    
    if (res && res.data && res.data[0].length) {
      if (
        res.data[0][0].consultation_type == "3" &&
        getUrl("docuserType", this.props.location.search) &&
        getUrl("docuserType", this.props.location.search) == 2 &&
        !res.data[0][0].duration
      ) {
        this.user();
        this.setState(
          {
            videofig: 1,
          },
          () => {
            this.getchatnews();
          }
        );
      }

      //获取聊天内容
      const res2 = await getChatnote({
        consultationId: getUrl("consultationId", this.props.location.search),
      });

      let res04 = await isChineseMeds({consultation_id: res2.data[0][0].consultation_id})

      let res05 = await chineseMethodDoc({consultation_id: res2.data[0][0].consultation_id})

      this.setState({
        ischinese:res04.data[0],
        chinesemethod:res05.data[0]
      })

      localStorage.setItem("listtext", JSON.stringify(res2.data[1][0]));
      this.setState({
        // consultationId: res.data[0][0].consultationId,
        consultationStu: res.data[0][0].consultation_stu,
        bottomcode:
          res.data[0].length &&
          (res.data[0][0].consultation_stu == "0" ||
            res.data[0][0].consultation_stu == "-1")
            ? true
            : false,
        consultationFiles:
          res2.data[0][0].consultationFiles &&
          res2.data[0][0].consultationFiles.split(","),
        listtext: res2.data[1][0],
        consultationDetail: res2.data[0][0].consultationDetail,
        memberId: res.data[0][0].member_id,
        details: res.data[0][0],
      });
    }
    let res2 = await allmedsNews({
      consultationId: getUrl("consultationId", this.props.location.search),
    });

    let a = [];

    let res06 = await isChineseMeds({consultation_id: getUrl("consultationId", this.props.location.search)})


    if(res06.data[0].length){
      if (res2 && res2.data && res2.data[1].length) {
          res2.data[1].map(item => {
              a.push(item.med_com_name + " " + item.med_quantity + "g" + "；")
          })
          this.setState({
              medsNews: a,
          })
      }
    }else{
      if (res2 && res2.data && res2.data[1].length) {
        res2.data[1].map((item) => {
          a.push(item.med_name + item.med_spec + " X" + item.med_quantity);
        });
        this.setState({
          medsNews: a,
        });
      }
    }

    
    lookStar({
      userId,
      docterId: getUrl("docterId", this.props.location.search),
      consultationId: getUrl("consultationId", this.props.location.search),
    }).then((res) => {
      if (res && res.data && res.data[0] && res.data[0].length) {
        this.setState({
          stateStar: 1,
          starValue: res.data[0][0],
        });
      }
    });
    this.getchatnews();
    this.scrollToBottom();
  };

  // 获取当前里聊天状态
  getnewsstatus = async () => {
    const { docterId, userId, item, orderNumber, consultationId } = this.state;
    const res = await getStatus({
      docterId: item.docterId,
      userId,
      consultationId: consultationId,
    });
    this.setState({
      bottomcode:
        res.data[0].length &&
        (res.data[0][0].consultationStu == "0" ||
          res.data[0][0].consultationStu == "-1")
          ? true
          : false,
    });
  };
  //获取聊天节点设置默认到最底部
  scrollToBottom = () => {
    if (this.container) {
      const scrollHeight = this.container.scrollHeight;
      const clientHeight = this.container.clientHeight;
      const scrollTop = scrollHeight - clientHeight;
      this.container.scrollTop = scrollTop > 0 ? scrollTop : 0;
    }
  };
  totalk = () => {
    const { item, userId, commentValue, star, consultationId, docterId, list } =
      this.state;
    let a = [];
    list.map((i, v) => {
      if (i.key == 2) {
        a.push(i.value);
      }
    });
    if (a.length && star && parseInt(star)) {
      let params = {
        userId,
        docterId: docterId,
        consultationId: consultationId,
        docEvaluateText: "",
        docEvaluateQuick: a.length ? a.toString() : "",
        docEvaluateStar: star,
      };
      patientStar(params).then((res) => {
        if (res.code == 1) {
          lookStar({
            userId,
            docterId: docterId,
            consultationId: consultationId,
          }).then((res1) => {
            if (res1.data[0].length) {
              this.setState({
                stateStar: 1,
                starValue: res1.data[0][0],
              });
            }
          });
        }
      });
      this.setState({
        visible1: false,
      });
    } else {
      Toast.info("请先选择评价和星级");
    }
  };
  toinfo = async (detailContent, createTime) => {
    const { listtext, consultationId, docterId, record, timer } = this.state;
    this.getchatnews();
    let res = await allMedicinal({ prescribingId: detailContent });
    if (res.code == 1 && res.data[0] && res.data[0].length) {
      let res1 = await prescribingDetail({
        prescribingId: res.data[0][0].prescribing_id,
      });
      this.setState({
        list2: res.data[0],
        detail: res1.data[0][0],
        year: res1.data[0][0].year,
        month: res1.data[0][0].month,
        day: res1.data[0][0].day,
        visible2: true,
      });
      if (res1.data[2]) {
        this.setState({
          docterAutograph: res1.data[2][0].docterAutograph,
        });
      }
      if (res1.data[0][0].pharmacist_id) {
        caWrite({ docterId: res1.data[0][0].pharmacist_id }).then((res2) => {
          if (res2 && res2.data && res2.data[0]) {
            this.setState({
              docterAutograph1: res2.data[0][0].docterAutograph,
            });
          }
        });
      }
    }
  };
  goback = () => {
    const { timer, item, record, record1, gzDocter, docuserType } = this.state;
    window.clearInterval(timer);
    if (docuserType == 2) {
      this.props.history.push(`/patientinformation`);
    } else {
      this.props.history.push("/drugselection");
    }
  };
  //发送信息
  sendout = async () => {
    const { item, consultationId, detailContent, userpic, consultationStu } =
      this.state;

    if (item) {
      // eslint-disable-next-line no-undef
      this.inputRef.current.focus();

      if (detailContent && detailContent.trim() !== "") {
        // 检查 detailContent 是否为空格
        const res = await addchatnote({
          consultationId: consultationId,
          Identity: 1,
          detailType: 1,
          detailContent,
        });

        this.setState(
          {
            detailContent: "",
          },
          () => {
            const newTimer = window.setTimeout(() => {
              window.clearTimeout(newTimer);
              this.scrollToBottom();
              this.getchatnews();
            }, 200);
          }
        );
      } else {
        Toast.fail("请先输入内容", 1);
      }
    } else {
      Toast.fail("数据错误", 1);
      this.props.history.push("/");
    }
  };
  // 获取聊天信息
  getchatnews = async () => {
    const {
      timer,
      item,
      userId,
      videofig,
      videoTime,
      useTime,
      duration,
      consultationId,
      docterDiagnosis,
      memberId,
      docterId,
      chatLength,
      newslist,
      supplementVisible
    } = this.state;
    window.clearInterval(timer);
    const res = await patientWz({
      userId,
      docterId: docterId,
      consultationId: consultationId,
    });

    if(res.data[0].length == 0){
      this.setState({
        stateStar:1
      })
      this.props.history.push('/offawait')
    }

    setTimeout(async ()=>{
      const resultLastChat = await patientWz({
        userId,
        docterId: docterId,
        consultationId: consultationId,
      });

      let dataChat = resultLastChat.data[1]
      let lastDataChat = dataChat[dataChat.length - 1];
      // console.log(lastDataChat.detailContent,'最后一句---supplementVisible',supplementVisible);
      let lastchat = lastDataChat.detailContent
      let reDialogDataThree = await dialogueTemplate({dialogSequence:3})
      let isInResData = reDialogDataThree.data[0].every(item => item.template_content != lastchat)
      // 如果最后一句话不是"请问您是否还有其它信息需要补充？如无，我将依据您的病历资料开具处方。"，那么需要关闭
      if(isInResData){
        this.setState({
          supplementVisible:false
        })
      }
    },500)


    // 等待一秒钟
    setTimeout(async () => {

      const resultChat = await patientWz({
        userId,
        docterId: docterId,
        consultationId: consultationId,
      });

      let dataChat = resultChat.data[1]
      let lastDataChat = dataChat[dataChat.length - 1];
      let lastchat = lastDataChat.detailContent

      let lastDataChatTime = lastDataChat.createTime;
      let lastChatTime =moment(lastDataChatTime).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
      let delayTime = moment(lastChatTime).add( 1 , 'seconds')
      let chatTime = delayTime.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

      let reDialogDataThree = await dialogueTemplate({dialogSequence:3})
      let isInResData = reDialogDataThree.data[0].some(item => item.template_content == lastchat); //查看是否存在是否补充

      if(isInResData){
        this.setState({
          supplementVisible:false
        })
        const resChat = await addchatnote({
            consultationId: item.consultation_id,
            Identity: 1,
            detailType: 1,
            detailContent: `没有补充`,
            createTime:chatTime
        });
  
        if (resChat.code === 1) {
            this.scrollToBottom();
            this.getchatnews();
        }
      } 
    }, 1500);

    

    
    if (res && res.data[1]) {
      if (chatLength == 0) {
          this.setState({
              chatLength: res.data[1].length,
              newslist: res.data[1] ? res.data[1] : [],
          })
      } else if (chatLength == res.data[1].length) {
          // this.setState({
          //     newslist: res.data[1] ? res.data[1] : [],
          // })
      } else {
          this.setState({
              chatLength: res.data[1].length,
              newslist: res.data[1] ? res.data[1] : [],
          }, () => {
              if (res.data[1][res.data[1].length - 1].Identity == '2') {
                  Toast.info('您有新消息')
              }
          })
      }
    }
    
    let res1 = await prescribingDetails({
      consultationId: consultationId,
    });
    if(res1 && res1.data && res1.data[0] && res1.data[0][0]){
      this.setState({
        preDetails: res1.data[0][0]
      })
    }


    if (res && res.data[0] && res.data[0].length) {
      if (res.data[0][0].reception_time) {
        let h =
          Math.floor(
            (new Date() - new Date(res.data[0][0].reception_time)) /
              1000 /
              60 /
              60
          ) < 10
            ? "0" +
              Math.floor(
                (new Date() - new Date(res.data[0][0].reception_time)) /
                  1000 /
                  60 /
                  60
              )
            : Math.floor(
                (new Date() - new Date(res.data[0][0].reception_time)) /
                  1000 /
                  60 /
                  60
              );
        let m =
          Math.floor(
            ((new Date() - new Date(res.data[0][0].reception_time)) /
              1000 /
              60) %
              60
          ) < 10
            ? "0" +
              Math.floor(
                ((new Date() - new Date(res.data[0][0].reception_time)) /
                  1000 /
                  60) %
                  60
              )
            : Math.floor(
                ((new Date() - new Date(res.data[0][0].reception_time)) /
                  1000 /
                  60) %
                  60
              );
        let s =
          Math.floor(
            (((new Date() - new Date(res.data[0][0].reception_time)) / 1000) %
              60) %
              60
          ) < 10
            ? "0" +
              Math.floor(
                (((new Date() - new Date(res.data[0][0].reception_time)) /
                  1000) %
                  60) %
                  60
              )
            : Math.floor(
                (((new Date() - new Date(res.data[0][0].reception_time)) /
                  1000) %
                  60) %
                  60
              );

              const receptionTime = new Date(res.data[0][0].reception_time);
              const currentTime = new Date();
              
              let hours = Math.floor((currentTime - receptionTime) / 1000 / 60 / 60);
              let minutes = Math.floor(((currentTime - receptionTime) / 1000 / 60) % 60);
              
              if (hours < 0 || (hours === 0 && minutes < 0)) {
                hours = 0;
                minutes = 0;
              }

        this.setState(
          {

            useTime: hours + "小时" + minutes + "分钟",
            videoTime: videofig != 1 ? "" : h + ":" + m + ":" + s,
            duration:
              res.data[0][0].consultation_type == "3" && res.data[0][0].duration
                ? (res.data[0][0].duration / 60 >= 0 &&
                  res.data[0][0].duration / 60 < 10
                    ? "0" + Math.floor(res.data[0][0].duration / 60)
                    : Math.floor(res.data[0][0].duration / 60)) +
                  ":" +
                  (res.data[0][0].duration % 60 >= 0 &&
                  res.data[0][0].duration % 60 < 10
                    ? "0" + (res.data[0][0].duration % 60)
                    : res.data[0][0].duration % 60)
                : 0,
          },
          () => {}
        );
      }
      this.setState({
        consultationReason: res.data[0][0].consultation_reason,
      });
      // 获取问诊单所有电子处方id
      let res1 = await allPrespribingids(consultationId);
      let arr = [];
      if (res1 && res1.data && res1.data[0] && res1.data[0].length) {
        res1.data[0].map((item) => {
          // 患者获取医生诊断详情
          patientDocdiagnosis({ consultationId: item.detail_content }).then(
            (res2) => {
              arr.push({
                prescribingId: item.detail_content,
                docterDiagnosis: res2.data[0][0].docter_diagnosis,
                createTime: res2.data[0][0].createTime,
              });
              if (arr.length > docterDiagnosis.length) {
                this.setState({
                  docterDiagnosis: arr,
                });
              }
            }
          );
        });
      }
      this.setState(
        {
          // consultationId: res.data[0][0].consultationId,
          consultationStu: res.data[0][0].consultation_stu,
        },
        () => {
          if (
            res.data[0][0].consultation_stu == "-1" ||
            res.data[0][0].consultation_stu == "0"
          ) {
            const newTimer = window.setInterval(() => {
              this.getchatnews();
              //   this.scrollToBottom();
            }, 3000);
            this.setState({
              timer: newTimer,
            });
          }
          // this.scrollToBottom()
        }
      );
    } else {
      wzstate({
        docterId: item && item.docterId ? item.docterId : item.docter_id,
        userId: userId,
        memberId: memberId,
      }).then((res) => {
        if (res.data[0].length) {
          this.setState({
            consultationStu: res.data[0][0].consultationStu,
          });
        }
      });
    }
  };



  getdetailContent = (e) => {
    this.setState({
      detailContent: e.target.value,
    });
  };
  componentWillUnmount = () => {
    const { timer, item, docuserType, videofig, docterId, userId } = this.state;
    window.clearInterval(timer);
    if (item.consultation_type == "3" && docuserType == 2 && videofig == 1) {
      savevideoTime({ consultationId: item.consultation_id }).then((res) => {});
      addvideoMessage({ consultation_id: item.consultation_id }).then(
        (res1) => {
          if (res1.code == 1) {
            this.getnews();
          }
        }
      );
      this.video.handleLeave(); //关闭视频流
      this.getchatnews();
      Toast.info("已结束视频");
    }
  };
  //图片上传发送
  imgChange = async (files, type, index) => {
    const {
      item,
      consultationId,
      detailContent,
      compressThreshold = 1,
    } = this.state;
    let imgList = [];
    let pictureQuality = 0.5;
    if (type == "add") {
      let file = files[files.length - 1].file;
      let fileSize = file.size / 1024 / 1024;
      if (
        file.type == "image/png" ||
        file.type == "image/jpeg" ||
        file.type == "image/jpg"
      ) {
        if (fileSize >= compressThreshold) {
          if (fileSize * 0.9 <= 1) {
            pictureQuality = 0.9;
          } else if (fileSize * 0.8 <= 1) {
            pictureQuality = 0.8;
          } else if (fileSize * 0.7 <= 1) {
            pictureQuality = 0.7;
          } else if (fileSize * 0.6 <= 1) {
            pictureQuality = 0.6;
          } else if (fileSize * 0.5 <= 1) {
            pictureQuality = 0.5;
          } else if (fileSize * 0.4 <= 1) {
            pictureQuality = 0.4;
          } else if (fileSize * 0.3 <= 1) {
            pictureQuality = 0.3;
          } else if (fileSize * 0.2 <= 1) {
            pictureQuality = 0.2;
          } else if (fileSize * 0.1 <= 1) {
            pictureQuality = 0.1;
          } else {
            pictureQuality = 0.5;
          }
          //判断浏览器内核是否支持base64图片压缩
          if (typeof FileReader === "undefined") {
            return file;
          } else {
            try {
              return new Promise((resolve) => {
                //压缩图片再上传
                let a = getBase64Image(file, pictureQuality, callback1);
                let that = this;

                function callback1(val) {
                  //base64转png图片
                  zipImg(val).then((res1) => {
                    let formData = new FormData();
                    formData.append("file", res1);
                    uploadpic(formData)
                      .then(async (res2) => {
                        if (res2.code === 2) {
                          const res3 = await addchatnote({
                            consultationId: consultationId,
                            Identity: 1,
                            detailType: 2,
                            detailContent: res2.data,
                          });
                          // this.scrollToBottom()
                          if (res3.code == 1) {
                            const newTimer = window.setTimeout(() => {
                              window.clearTimeout(newTimer);
                              this.scrollToBottom();
                              this.getchatnews();
                            }, 200);
                          }
                        } else {
                          Toast.info("上传失败", 1);
                        }
                      })
                      .catch((err) => {
                        // Toast.hide()
                      });
                  });
                }
              })
                .then((res) => {})
                .catch(() => {
                  this.setState({
                    spinLoading: false,
                  });
                  return file;
                });
            } catch (e) {
              //压缩出错,直接返回原file对象
              return file;
            }
          }
        } else {
          // if (file.size < 1024 * 1024) {
          let formData = new FormData();
          const res = await zipImg(file);
          formData.append("file", res);
          const res2 = await uploadpic(formData);

          if (res2.code === 2) {
            const res3 = await addchatnote({
              consultationId: consultationId,
              Identity: 1,
              detailType: 2,
              detailContent: res2.data,
            });
            if (res3.code == 1) {
              const newTimer = window.setTimeout(() => {
                window.clearTimeout(newTimer);
                this.scrollToBottom();
                this.getchatnews();
              }, 200);
            }
          }
        }
      } else {
        Toast.show("请上传png/jpg格式的图片", 1);
      }
    } else {
      this.setState({
        fileList: [],
      });
    }
  };
  starCount = (v) => {
    this.setState({
      star: v,
    });
  };
  comment = (v) => {
    this.setState({
      commentValue: v.target.value.replace(/\s*/g, ""),
    });
  };
  imgFd = (index) => {
    const { imgFd } = this.state;
    if (imgFd == 0) {
      this.setState({
        imgFd: 1,
        index1: index,
      });
    } else {
      this.setState({
        imgFd: 0,
        index1: -1,
      });
    }
  };
  sxImg = () => {
    const { imgFd } = this.state;
    if (imgFd == 1) {
      this.setState({
        imgFd: 0,
        index1: -1,
      });
    }
  };
  //再次咨询
  toAgain = () => {
    const { item, consultationId, record } = this.state;
    if (item.docterState == 1) {
      localStorage.setItem("docterId", item.docterId);
      getdoctorlist({ docterId: item.docterId }).then((res2) => {
        if (res2.code == 1) {
          localStorage.setItem("item", JSON.stringify(res2.data[0][0]));
          this.props.history.replace(
            `/doctorchat?consultationId=${consultationId}&docterId=${item.docterId}&record=${record}`
          );
          this.props.history.push(
            `/doctorinfo?doctorchat=${1}&consultationId=${consultationId}&docterId=${
              item.docterId
            }&record=${record}`
          );
        }
      });
    } else {
      Toast.info("该医生已下架，无法再次咨询");
    }
  };
  //快捷评价
  addList = (i, v) => {
    const { list } = this.state;
    list.map((m, n) => {
      if (n == v) {
        if (i.key == 1) {
          m.key = 2;
        } else {
          m.key = 1;
        }
      }
    });
    this.setState({
      list,
    });
  };
  //重新选购
  buyAgain = () => {
    localStorage.removeItem("selectmeds");
    localStorage.removeItem("item");
    localStorage.removeItem("consultationId");
    localStorage.removeItem("listtext");
    this.props.history.push("/drugselection");
  };
  //开启视频
  user = () => {
    const { userId } = this.state;

    videoSetting(
      // 2024年3月10日修改前
      // { institutionCode: "ZJYYJ", userId: "user" + userId },
      // data.data.token

      // 2024年3月10日修改
      userId
    ).then((res) => {
      this.setState(
        {
          userData: {
            sdkAppId: parseInt(res.body.appId),
            userSig: res.body.userSig,
          },
        },
        () => {
          this.video.handleJoin(); //初始化视频
        }
      );
    });

    // callWenzhen({}).then((data) => {
    //   if (data.data.token) {
        
    //   }
    // });


  };
  //结束视频
  overVideo = () => {
    const { item } = this.state;
    alert("确认提示", "确认结束视频问诊？", [
      {
        text: "取消",
        onPress: () => {},
      },
      {
        text: "确定",
        onPress: () => {
          addchatnote({
            consultationId: item.consultation_id,
            Identity: 1,
            detailType: 4,
            detailContent: "结束视频",
          }).then((res) => {
            // console.log(res);
          });
          this.video.handleLeave(); //关闭视频流
          Toast.info("视频问诊已结束");
          this.props.history.replace("/news");
          this.props.history.push("/drugselection");
        },
      },
    ]);
  };

  handleWatchStatus = (status) => {
    const { item, docterId, videofig } = this.state;
    if (status) {
      if (
        status.type == "remote" &&
        status.msg == "unpublish" &&
        videofig == 1
      ) {
        this.video.handleLeave(); //关闭视频流
        savevideoTime({ consultationId: item.consultation_id }).then(
          (res) => {}
        );
        addvideoMessage({ consultation_id: item.consultation_id }).then(
          (res1) => {
            if (res1.code == 1) {
              this.setState(
                {
                  videofig: 0,
                },
                () => {
                  this.scrollToBottom();
                  this.getchatnews();
                }
              );
            }
          }
        );
      }
    }
  };
  handleWatchLocalStatus = (status) => {
    const { videofig, item } = this.state;
    if (status) {
      // Toast.info(status.msg)
      if (status.msg == "localStream stop and remove") {
        Toast.info("已结束视频");
      }
    }
  };
  handleWatchClientStatus = (status) => {
    const { item, videofig } = this.state;
    if (status) {
      // Toast.info(status.msg)
      // if (videofig==1){
      //     this.video.handleLeave();//关闭视频流
      //     savevideoTime({consultationId: item.consultation_id}).then(res => {
      //     })
      //     addvideoMessage({consultation_id: item.consultation_id}).then(res1 => {
      //         if (res1.code == 1) {
      //
      //             this.setState({
      //                 videofig: 0
      //             }, () => {
      //                 this.scrollToBottom();
      //                 this.getchatnews()
      //             })
      //         }
      //     })
      // }
    }
  };
  handleWatchOtherStatus = (status) => {
    const { item } = this.state;
    // if (status){
    //     Toast.info(status.msg)
    // }
  };
  // 复制客服电话
  copykefuhone = () => {
    const spanText = document.getElementById("contactPhone").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.info("复制成功");
  };

  // 有补充
  handleSupplement = async() =>{
    const { supplementVisible,item,userId,docterId } = this.state
    // 有补充发送：我要补充：关闭
    this.setState({
      supplementVisible:false
    })

    const res = await patientWz({
      userId,
      docterId: docterId,
      consultationId: item.consultation_id,
    });

    let dataChat = res.data[1]
    let lastDataChat = dataChat[dataChat.length - 1];
    let lastchat = lastDataChat.detailContent

    let lastDataChatTime = lastDataChat.createTime;
    let lastChatTime =moment(lastDataChatTime).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
    let delayTime = moment(lastChatTime).add( 1 , 'seconds')
    let chatTime = delayTime.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间


    const resChat = await addchatnote({
      consultationId : item.consultation_id,
      Identity: 1,
      detailType: 1,
      detailContent:`我要补充`,
      createTime:chatTime
    });

    if(resChat.code == 1){
      this.scrollToBottom();
      this.getchatnews();


      const resDoc = await patientWz({
        userId,
        docterId: docterId,
        consultationId: item.consultation_id,
      });
  
      let dataChatDoc = resDoc.data[1]
      let lastDataChatDoc = dataChatDoc[dataChatDoc.length - 1];
      let lastchatDoc = lastDataChatDoc.detailContent
  
      let lastDataChatTimeDoc = lastDataChat.createTime;
      let lastChatTimeDoc =moment(lastDataChatTimeDoc).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
      let delayTimeDoc = moment(lastChatTimeDoc).add( 1 , 'seconds')
      let chatTimeDoc = delayTimeDoc.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间


      const resChatTwo = await addchatnote({
        consultationId : item.consultation_id,
        Identity: 2,
        detailType: 1,
        detailContent:`请描述您的问题。`,
        createTime:chatTimeDoc
      });

        if(resChatTwo.code==1){
          this.scrollToBottom();
          this.getchatnews();
        }
    }
  
  }

  // 没有补充
  handleNoSupplement = async()=>{
    const { supplementVisible,item,docterId,userId } = this.state

    this.setState({
      supplementVisible:false
    })

    const res = await patientWz({
      userId,
      docterId: docterId,
      consultationId: item.consultation_id,
    });

    let dataChat = res.data[1]
    let lastDataChat = dataChat[dataChat.length - 1];
    let lastchat = lastDataChat.detailContent

    let lastDataChatTime = lastDataChat.createTime;
    let lastChatTime =moment(lastDataChatTime).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
    let delayTime = moment(lastChatTime).add( 1 , 'seconds')
    let chatTime = delayTime.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间


    const resChat = await addchatnote({
      consultationId : item.consultation_id,
      Identity: 1,
      detailType: 1,
      detailContent:`没有补充。`,
      createTime:chatTime
    });
    if(resChat.code == 1){
      this.scrollToBottom();
      this.getchatnews();
    }

  }

  render() {
    const {
      item,
      docterAutograph,
      docterAutograph1,
      userId,
      visible2,
      year,
      month,
      day,
      list2,
      detail,
      image,
      star,
      consultationFiles,
      docterDiagnosis,
      commentValue,
      docuserType,
      detailContent,
      stateStar,
      consultationDetail,
      newslist,
      userpic,
      videocode,
      fileList,
      listtext,
      videofig,
      visible5,
      list,
      consultationReason,
      userData,
      duration,
      videoTime,
      consultationStu,
      details,
      medsNews,
      ischinese,
      chinesemethod,
      useTime,
      preDetails,
      supplementVisible
    } = this.state;


    let a = [];
    list.map((i, v) => {
      if (i.key == 2) {
        a.push(i.value);
      }
    });
    let config = {
      roomId: item.consultation_id, //房间号，两端相同(问诊单号）
      // userId: "user" + userId,
      userId: userId,
      ...userData,
      showLocal: true, //是否显示自己视频
      showRemote: true, //是否显示对方视频
      showDevice: true, //是否显示设备切换按钮
      showCamera: true, //是否显示摄像头切换按钮 ，showDevice为true时有效
      showMic: false, //是否显示麦克风切换按钮，showDevice为true时有效
    };


    return (
      <>
        <div
          className={
            consultationStu == "1" && stateStar == 0
              ? "offlineDocbox offlineDocbox1"
              : "offlineDocbox"
          }
          onClick={this.sxImg}
        >
          <div className="offlineDocbox-top">
            <div className="toptit">
              <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
              {/* <span className='return-right' onClick={this.props.history.goBack}>❮</span> */}
              <h1>{item ? item.docter_name : ""}</h1>
            </div>
          </div>

          <div className="offlineDocbox-two">
              <h1>{item ? item.docter_name : ""} | {item.dep_name}</h1>
              <div className="toptit1">
                <div className={"time"}>已用时{useTime} | {consultationStu == '1' ? "已结束" :"进行中"}</div>
                {/* { details.consultation_stu=="0"||details.consultation_stu=="-2"?"进行中":"已结束"} */}
              </div>
          </div>

          <p className="offlineDocbox-tips">
            提示：医生实时在线，建议连贯咨询中途不要离开
          </p>
          {/*<button onClick={()=>this.user()}>用户</button>*/}

          {/*<div className='video-outer'>*/}
          {/*/!*    /!*滑动组件*!/*!/*/}
          {/*    <Draggable*/}
          {/*    // bounds={{left: 0, top: 0, right: 75, bottom: 365}}*/}
          {/*    >*/}

          {item.consultation_type == "3" &&
          docuserType == 2 &&
          videofig == 1 ? (
            <div
              className="video-inner"
              onClick={() =>
                this.setState({
                  videocode: videocode == 1 && videofig == 1 ? 2 : 1,
                })
              }
            >
              {videocode == 2 ? (
                <div 
                  className="over-video"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.video.handleLeave(); //关闭视频流
                    this.setState(
                      {
                        videofig: 0,
                        videocode: 1,
                      },
                      async () => {
                        let res = await savevideoTime({
                          consultationId: item.consultation_id,
                        });
                        Toast.info("已结束视频");
                        // this.getnewsstatus();
                      }
                    );
                  }}
                >
                  <span>{videoTime}</span>
                  <img src={gdphone} style={{ width:'35px',height:'35px'}} />
                </div>
              ) : null}
              <VideoForReact
                ref={this.initUser}
                config={{
                  roomId: item.consultation_id,
                  // userId: "user" + userId,
                  userId: userId,
                  ...userData,
                  showLocal: true,
                  showRemote: true,
                  showDevice: true,
                  showCamera: true, //是否显示摄像头切换按钮 ，showDevice为true时有效
                  showMic: false, //是否显示麦克风切换按钮，showDevice为true时有效
                }}
                customStyle={{
                  width: "100vw", //整个video的宽 单位px 默认300px
                  height: videocode == 1 ? "150px" : "100vh", //整个video的高单位px 默认300px
                  localStreamWidth: videocode == 1 ? "50vw" : "120px", //本地流宽度px 默认80px
                  localStreamHeight: "150px", //本地流高度px 默认80px
                  remoteStreamWidth: videocode == 1 ? "50vw" : "100vw", //远端流宽度px 默认300px
                  remoteStreamHeight: videocode == 1 ? "150px" : "100vh", //远端流高度px 默认300px
                  mode: videocode == 1 ? "symmetry-R" : "around", //around----常规   symmetry-横向， vertical-上下 vertical和symmetry时，不能展示设备切换按钮 默认aroud
                }}
                remoteStatus={(status) => this.handleWatchStatus(status)}
                localStatus={(status) => this.handleWatchLocalStatus(status)}
                clientStatus={(status) => this.handleWatchClientStatus(status)}
                otherStatus={(status) => this.handleWatchOtherStatus(status)}
              ></VideoForReact>
            </div>
          ) : item.consultation_type == "3" && duration ? (
            <p className="video-duration">
              <i className="iconfont icon-shipinbofang" />
              <span>视频通话已结束，通话时长 {duration}</span>
            </p>
          ) : null}

          {/*</Draggable>*/}

          {/*</div>*/}

          {/*<VideoForReact*/}
          {/*    ref={this.initUser}*/}
          {/*    config={{*/}
          {/*        roomId: '001',*/}
          {/*        userId: 'yy01',*/}
          {/*        ...userData,*/}
          {/*        showLocal: 'true',*/}
          {/*        showRemote: 'true',*/}
          {/*        showDevice: 'true',*/}
          {/*    }}*/}
          {/*    customStyle={{*/}
          {/*        width: '',*/}
          {/*        height: '',*/}
          {/*        localStreamWidth: '',*/}
          {/*        localStreamHeight: '',*/}
          {/*        remoteStreamWidth: '',*/}
          {/*        remoteStreamHeight: '',*/}
          {/*        mode: '',*/}
          {/*    }}*/}
          {/*/>*/}
          <div
            className={
              item.consultation_type == "3" &&
              docuserType == 2 &&
              videofig == 1 &&
              !duration
                ? "offlineDoc-container offlineDoc-container1"
                : "offlineDoc-container"
            }
            ref={(el) => (this.container = el)}
          >
            {details ? (
              <div className="offlineDoc-container-dan">
                <p className="offlineDoc-top">
                  <p className="offlineDoc-top-left">
                    <img src={icon4} />
                    <span>问诊信息</span>
                  </p>
                  <p className="offlineDoc-top-right">
                    <span className="name">
                      {details ? details.patientName : ""}
                    </span>
                    <span className="sex1">
                      {details ? (details.patientSex == 1 ? "男" : "女") : ""}
                    </span>
                    <span className="sex1">
                      {details ? details.patient_age : ""}
                    </span>
                  </p>
                </p>

         <p className="offlineDoc-sure">
                  患者已确认本次为复诊，并使用过该药品，且无相关禁忌和不良反应
                </p>
                {/* 疾病史、过敏史 */}
                <p className="offlineDoc-body">
                  <span className="ill-one">
                    疾病史：
                    <span
                      className={details.past_history == 1 ? "blue" : "blue1"}
                    >
                      {details.past_history_note}
                    </span>
                  </span>
                  <span className="ill-one">
                    过敏史：
                    <span
                      className={
                        details.allergic_history == 1 ? "blue" : "blue1"
                      }
                    >
                      {details.allergic_history_note}
                    </span>
                  </span>
                </p>
                <p className="offlineDoc-body">
                  <span className="ill-one">
                    肝肾功能：
                    <span
                      className={
                        details.abnormal_liver_function == 0 ? "blue1" : "blue"
                      }
                    >
                      {details.abnormal_liver_function == 0 ? "无异常" : "异常"}
                    </span>
                  </span>
                  <span className="ill-one">
                    {details.patientSex == 2 ? (
                      <p>
                        怀孕情况：
                        <span className="blue1">
                          {details.pregnancy_status == 1
                            ? "未怀孕"
                            : details.pregnancy_status == 2
                            ? "备孕中"
                            : details.pregnancy_status == 3
                            ? "已怀孕"
                            : "哺乳期"}
                        </span>
                      </p>
                    ) : null}
                  </span>
                </p>
                {/* 体温、体重 */}
                <p className="offlineDoc-body">
                  <span className="ill-one">
                    体温：
                    <span
                      className={details.bodytemperature == 1 && details.bodytemperaturenum && (details.bodytemperaturenum < 36 || details.bodytemperaturenum > 37) ? "redcolor" : "blue1"}
                    >
                      {details.bodytemperature == 1 && details.bodytemperaturenum ? details.bodytemperaturenum + '℃' : '正常'}
                      {/* {details.bodytemperature == '0' ? '正常' : details.bodytemperaturenum + '℃'} */}
                    </span>
                  </span>
                  {details.patient_age < 7 ? 
                  <span className="ill-one">
                    体重：
                    <span
                      className='blue1'
                    >
                      {details.bodyweight + 'KG'}
                    </span>
                  </span> : null }
                </p>
                {/* 是否医保统筹 */}
                <p className="offlineDoc-body">
                  <span className="ill-one">
                    是否医保统筹：
                    <span
                      className={
                        details.medical_nsurance_coordination == 1
                          ? "blue1"
                          : "blue"
                      }
                    >
                      {details.medical_nsurance_coordination == 1 ? "是" : "否"}
                    </span>
                  </span>
                  <span className="ill-one">
                    身份证号：
                    <span
                      className={details.patientIdcardNum ? "blue1" : "blue"}
                    >
                      {details.patientIdcardNum ? "有" : "无"}
                    </span>
                  </span>
                </p>

                <p className="line"></p>
                {ischinese.length ? (
                  <>
                    <p className="offlineDoc-meds">
                      <span>中药需求：</span>
                      <span>{medsNews}</span>
                    </p>
                    <p className="offlineDoc-meds">
                      <span>用法用量：</span>
                      <p>
                        {chinesemethod.filter(i => i.consultation_id == details.consultation_id).map(i=>(
                          <span>
                            共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                            每次{i.at_time}{i.unit}，{i.med_method}，
                            {i.med_time == 1
                            ? "饭前用药"
                            : i.med_time == 2
                            ? "饭后用药"
                            : i.med_time == 3
                            ? "睡前用药"
                            : i.med_time == 4
                            ? "晨起用药"
                            : ""}
                          </span>                          
                        ))}
                      </p>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="offlineDoc-meds">
                      <span>西药需求：</span>
                      <span>{medsNews}</span>
                    </p>
                  </>
                )}
                
                <p className="offlineDoc-meds">
                  <span>病情描述：</span>
                  <span style={{ color: "#8E8E8E" }}>{consultationDetail}</span>
                </p>
              </div>
            ) : null}
            {newslist.length ? (
              <div
                className={
                  newslist[0].Identity == "1"
                    ? "offlineDoc-right"
                    : "offlineDoc-left"
                }
              >
                {newslist[0].Identity == "1" ? (
                  <img src={icon5} alt="" />
                ) : (
                  <img
                    src={
                      item.docter_avatar != "null" ? item.docter_avatar : icon2
                    }
                    alt=""
                  />
                )}
                <div
                  className={
                    newslist[0].Identity == "1" ? "right-text" : "left-text"
                  }
                  style={{ wordWrap: "break-word" }}
                >
                  {newslist[0].detailContent}
                </div>
              </div>
            ) : null}
            <div className="offdoc-details">
              <p className="offdoc-details-title">
                <img src={icon7} />
                <span>医生信息</span>
              </p>
              <p className="offdoc-details-news">
                <p className="offdoc-details-left">
                  <img
                    src={
                      item.docter_avatar != "null" ? item.docter_avatar : icon2
                    }
                  />
                </p>
                <p className="offdoc-details-right">
                  <p className="offdoc-details-right-top">
                    <span>{item.docter_name}</span>
                    <span>
                      执业证编号：
                      {item.certificateNum
                        ? item.certificateNum.substr(0, 8) +
                          "****" +
                          item.certificateNum.substr(-3)
                        : ""}
                    </span>
                  </p>
                  <p className="offdoc-details-right-bottom">
                    <span>{item.docter_hospital}</span>
                    <span>|</span>
                    <span>{item.dep_name}</span>
                    <span>|</span>
                    <span>{item.docter_title}</span>
                  </p>
                </p>
              </p>
              <p className="offdoc-details-word">简介：{item.docter_profile}</p>
            </div>
            {newslist.length
              ? newslist.map((item1, index) => {
                  return (
                    <>
                      {index > 0 ? (
                        <div
                          className={
                            item1.Identity == "1"
                              ? "offlineDoc-right"
                              : "offlineDoc-left"
                          }
                          key={index}
                        >
                          {item1.Identity == "1" ? (
                            <img src={icon5} alt="" />
                          ) : item1.detailType == "3" ? null : (
                            <img
                              src={
                                item.docter_avatar != "null"
                                  ? item.docter_avatar
                                  : icon2
                              }
                              alt=""
                            />
                          )}
                          {item1.detailType == "1" ? (
                            <div
                              className={
                                item1.Identity == "1"
                                  ? "right-text"
                                  : "left-text"
                              }
                              style={{ wordWrap: "break-word" }}
                            >
                              {item1.detailContent}
                            </div>
                          ) : item1.detailType == "2" ? (
                            <div
                              className={
                                item1.Identity == "1"
                                  ? "right-text1"
                                  : "left-text1"
                              }
                            >
                              <img
                                src={item1.detailContent}
                                alt=""
                                onClick={() =>
                                  this.setState({
                                    image: {
                                      url: item1.detailContent,
                                      visible: true,
                                    },
                                  })
                                }
                              />
                            </div>
                          ) : item1.detailType == "3" && preDetails.prescring_pha_state == 1 && preDetails.prescribing_state == 1 ? (
                            <div
                              className="offcontainer-cfd"
                              // onClick={(v) => this.toinfo(item1.detailContent)}
                            >
                              <div className="offdan-left">
                                <div className="left-top">
                                  <img src={icon4} />
                                  <p>电子处方</p>
                                </div>
                                <img className='biao' src={icon12}/>
                              </div>
                              <p className="offuser">
                                患者：{item?.patientName}（
                                {item?.patientSex == 1 ? "男" : "女"}{" "}
                                {item?.patient_age}岁）
                              </p>
                              <p className="offuser">
                                {docterDiagnosis &&
                                  docterDiagnosis.map((i) => (
                                    <>
                                      {item1.detailContent ==
                                      i.prescribingId ? (
                                        <>
                                          <p>诊断： {i.docterDiagnosis}</p>
                                          <p>开方时间：{i.createTime}</p>
                                        </>
                                      ) : null}
                                    </>
                                  ))}
                              </p>
                              <div className="left-title">
                                <button
                                  onClick={(v) =>
                                    this.toinfo(item1.detailContent)
                                  }
                                >
                                  查看详情
                                </button>
                              </div>
                            </div>
                          ) : item1.detailType == "4" ? (
                            <div className="right-text2">
                              <p>
                                <i className="iconfont icon-shipinbofang" />
                                <span>视频已挂断，通话时长 {duration}</span>
                              </p>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  );
                })
              : null}
            {/* {consultationStu == "1" && stateStar == 0 ? (
              <div className="offbox-evaluate1">
                <div className="offbox-top">
                  <p className="offbox-title">
                    问诊已结束，本次开方服务您还满意吗？
                  </p>
                  <p>
                    <div className="offbox-pingfen">
                      <p>综合评分：</p>
                      <Rate
                        style={{
                          "--active-color": "#1F87FF",
                        }}
                        onChange={(val) => this.starCount(val.toString())}
                      />
                      <p className="ping">
                        {!parseInt(star)
                          ? ""
                          : parseInt(star) == 1 || parseInt(star) == 2
                          ? "差评"
                          : parseInt(star) == 3
                          ? "一般"
                          : "好评"}
                      </p>
                    </div>
                    <div className="offbox-tips">
                      {list.map((i, v) => (
                        <span
                          className={i.key == 1 ? "before" : "end"}
                          onClick={() => this.addList(i, v)}
                        >
                          {i.value}
                        </span>
                      ))}
                    </div>
                    <div className="offbox-btn">
                      <button
                        className={a.length && parseInt(star) ? "after" : ""}
                        onClick={this.totalk}
                      >
                        提交评论
                      </button>
                    </div>
                  </p>
                </div>
              </div>
            ) : consultationStu == "1" && stateStar > 0 ? (
              <p className="oppagain">
                <button onClick={this.buyAgain}>重新选购</button>
              </p>
            ) : null} */}
            {consultationStu == "1" && stateStar > 0 ? (
              <p className="oppagain">
                <button onClick={this.buyAgain}>重新选购</button>
              </p>
            ) : null}
            <p
              className="lxkf"
              onClick={() =>
                this.setState({
                  visible5: true,
                })
              }
            >
              联系客服
            </p>
            {/*{consultationStu=='1'&&stateStar==1?<div className='container-evaluate'>*/}
            {/*    <div>*/}
            {/*        <h1 style={{marginBottom:'5px'}}>已完成评价</h1>*/}
            {/*    </div>*/}
            {/*</div>:null}*/}
            {/*{consultationStu=='1'?<div>*/}
            {/*    <div className='chatAgin'>*/}
            {/*        {item.order_number||orderNumber?null:<button onClick={() => this.props.history.push("/writelist")}>再次咨询</button>}*/}
            {/*    </div>*/}
            {/*</div>:null}*/}
            {consultationStu == "0" && item.consultation_type == "1" ? (
              <div className="offbox-bottom">
                <input
                  type="text"
                  placeholder="输入信息"
                  value={detailContent}
                  ref={this.inputRef}
                  onChange={(e) => this.getdetailContent(e)}
                />
                <p>
                  <img src={icon6} onClick={this.imgChange} />
                </p>
                <ImagePicker
                  files={fileList}
                  length={1}
                  style={{
                    width: "55px",
                    height: "55px",
                    padding: "0",
                    display: "inline-block",
                    verticalAlign: "middle",
                    opacity: 0,
                    // position: 'absolute',
                    marginLeft: "-50px",
                    marginTop: "-10px",
                  }}
                  onChange={this.imgChange}
                  selectable={fileList.length < 1}
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                />
                <button onClick={this.sendout}>发送</button>
              </div>
            ) : docuserType == 2 &&
              item.consultation_type == "3" &&
              videofig == 1 ? (
              <p className="video-bottom">
                视频接诊中请勿离开，否则视频将自动结束
              </p>
            ) : null}
          </div>
        </div>

        {/* 是否有补充 */}
        {supplementVisible ? (
          <>
            <div className="supplement-box">
              <div className="have-supplement" onClick={this.handleSupplement}>有补充</div>
              <div className="no-supplement" onClick={this.handleNoSupplement}>没有补充</div>
            </div>
          </>
        ) : null}

        {visible5 ? (
          <div className="contact-complaint-outer">
            <div className="contact-complaint-inner">
              <img
                className="close"
                src={icon02}
                onClick={() =>
                  this.setState({
                    visible5: false,
                  })
                }
              />
              <h2>联系或投诉</h2>
              <p className="copy-phone">
                <span>客服电话：</span>
                <span id="contactPhone">19048012565</span>
                <i
                  className="iconfont icon-baocun"
                  onClick={() => this.copykefuhone()}
                />
              </p>
              <p className="tips-contact-complaint">
                在使用中如有问题或疑问，您可以拨打电话联系我们。
              </p>
              <p className="chat-contact-complaint">
                <img src={icon03} />
              </p>
              <p className="tips-contact-complaint">
                添加客服微信，及时处理您的问题
              </p>
            </div>
          </div>
        ) : null}

        <Popup
          visible={visible2}
          onMaskClick={() => {
            this.setState({
              visible2: false,
            });
          }}
          bodyStyle={{
            maxHeight: "80vh",
            borderTopRightRadius: "14px",
            borderTopLeftRadius: "14px",
          }}
        >
          <div className="offline-popup">
            <h1 className="title">电子处方</h1>
            <span
              onClick={() => {
                this.setState({
                  visible2: false,
                });
              }}
              style={{
                position: "absolute",
                right: "12px",
                top: "12px",
                fontSize: "16px",
                color: "#177FFF",
              }}
            >
              确定
            </span>
          </div>

          <div className="offline-body">
            <div className="off-body-title">中江云益健互联网医院——处方笺</div>
            <div className="bianhao">处方编号：{detail.prescribing_number}</div>
            <div className="huanzhe">
              <p className="hz-words">患者：{detail.patient_name}</p>
              <p className="hz-two">
                <span>科室：{detail.docter_department}</span>
                <span>医生：{item.docter_name}</span>
              </p>
              <p className="hz-words">诊断：<span className="hz-words-zd">{detail.docter_diagnosis}</span></p>
              <p className="hz-words">开方时间：{year}年{month}月{day}日</p>
            </div>
            {ischinese.length ? (
              <>
                <div className="zhyao-lists">
                  <p className="zhyao-tit">中药清单</p>
                  <p className="zhyao-lists-cont">
                    <p className="zhyao-lists-cont-one">
                      {list2
                          ? list2.map((item, index) => (
                              <p className="zyyp-info">
                                <span>
                                  {item.med_com_name
                                        ? item.med_com_name
                                        : item.medicinal_name}
                                </span>
                                <span> {item.medicinal_quantity}g</span>
                              </p>
                            ))
                      : null}
                    </p>
                  </p>
                </div>
                <div className="zhyao-lists">
                  <p className="zhyao-tit">用法用量</p>
                  <p className="zhyao-methods">
                      {chinesemethod.filter(i => i.consultation_id == detail.consultation_id).map(i=>(
                          <span>
                            共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                            每次{i.at_time}{i.unit}，{i.med_method}，
                            {i.med_time == 1
                            ? "饭前用药"
                            : i.med_time == 2
                            ? "饭后用药"
                            : i.med_time == 3
                            ? "睡前用药"
                            : i.med_time == 4
                            ? "晨起用药"
                            : ""}
                          </span>                          
                      ))}
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className="xiyao-lists">
                  <p className="xy-tit">西药清单</p>
                    {list2
                        ? list2.map((item, index) => (
                            <p className="xyyp-info">
                              <p className="info-one">
                                <p className="info-one-one">
                                  <span className="info-one-one1">
                                    {item.med_com_name
                                      ? item.med_com_name
                                      : item.medicinal_name}</span>
                                  <span className="info-one-one2">
                                    {item.medicinal_spec}
                                    {item.packing_unit && item.packing_unit != "null"
                                      ? item.packing_unit
                                      : ""}</span>
                                </p>
                                <span className="info-one-two">X{item.medicinal_quantity}</span>
                              </p>
                              <p className="info-two">
                                用法：{item.medicinal_frequency},每次
                                {item.medicinal_usage},{item.medicinal_day}天的量,{" "}
                                {item.medicinal_method}
                              </p>
                              {item.medicinal_supplementary ? (
                                <p className="info-two">
                                  备注：{item.medicinal_supplementary}
                                </p>
                              ) : null}
                            </p>
                          ))
                    : null}
                </div>
              </>
            )}
            
            <div className="docbeizhu">
              <p className="doc-bztit">医生备注</p>
              <p className="doc-bzcont">请严格按照原处方和药品说明书使用，严禁超量超范围使 用；如用药过程中出现病情变化或其他不适症状，请立即 停药并及时就医。</p>
            </div>
            <div className="zy-attention">
              <span>注：本处方当日生效，仅限{detail.pharmacy_name}使用</span>
            </div>
            <div className="yz-bot">
              <p className="yz-bot-one"><span>医嘱</span></p>
              <p className="yz-bot-two">考虑用药安全:1、请您再次确认用过该药品且无不良反应。 如未用过，请到实体医疗机构就诊;2、应严格按原处方和 《药品说明书》使用(严格对照用法用量、不良反应、禁忌、注意事项和药物相互作用)。3、如病情发生变化，或用药期间如有不适，请立即停药并及时到当地医院就诊。</p>
            </div>
          </div>
          
          {/* <div className="offline-body">
            <div className="offprescription-outer">
              <h1 className="title">中江云益健互联网医院</h1>
              <p className="offprescription-jian">
                <h1>处方笺</h1>
                <img src={icon3} />
              </p>
              <p className="offprescription-number">
                处方编号：{detail.prescribing_number}
              </p>
              <p className="offprescription-one">
                <p className="offprescription-one-left">
                  <span>科室:</span>
                  <p>{detail.docter_department}</p>
                </p>
                <p className="offprescription-one-right">
                  <span>开具日期:</span>
                  <p>{year}</p>
                  <span>年</span>
                  <p>{month}</p>
                  <span>月</span>
                  <p>{day}</p>
                  <span>日</span>
                </p>
              </p>
              <p className="offprescription-one">
                <p className="offprescription-one-left">
                  <span>姓名:</span>
                  <p>{detail.patient_name}</p>
                </p>
                <p className="offprescription-one-right2">
                  <span>性别:</span>
                  <p>{detail.patient_sex == "1" ? "男" : "女"}</p>
                  <span>年龄:</span>
                  <p>{detail.patient_age} 岁</p>
                </p>
              </p>
              <p className="offprescription-one2">
                <span>初步诊断：</span>
                <p>{detail.docter_diagnosis}</p>
              </p>
              <p className="offprescription-RP">RP</p>
              <p className="all-meds">
                {list2
                  ? list2.map((item, index) => (
                      <p className="offprescription-meds">
                        <p className="offprescription-meds-news">
                          <p className="offprescription-meds-left">
                            <span>
                              {index + 1}、
                              {item.med_com_name
                                ? item.med_com_name
                                : item.medicinal_name}
                            </span>
                            <span>
                              {item.medicinal_spec} x{item.medicinal_quantity}
                              {item.packing_unit && item.packing_unit != "null"
                                ? item.packing_unit
                                : ""}
                            </span>
                          </p>
                        </p>
                        <p className="offprescription-meds-details">
                          用法用量：{item.medicinal_frequency},每次
                          {item.medicinal_usage},{item.medicinal_day}天的量,{" "}
                          {item.medicinal_method}
                        </p>
                        {item.medicinal_supplementary ? (
                          <p className="offprescription-meds-details">
                            补充说明：{item.medicinal_supplementary}
                          </p>
                        ) : null}
                        {index + 1 == list2.length && index < 4 ? (
                          <p className="xu-line"></p>
                        ) : null}
                        {index + 1 == list2.length && index < 4 ? (
                          <p className="kongbai">(以下空白)</p>
                        ) : null}
                      </p>
                    ))
                  : null}
              </p>
              <p className="offprescription-doctor">
                <p className="offprescription-doctor1">
                  <span>医师:</span>
                  <p className="qianming">
                    <img src={docterAutograph} />
                    <span className="xia-line"></span>
                  </p>
                </p>
                <p className="offprescription-doctor1">
                  <span>药师:</span>
                  <p className="qianming">
                    <img src={docterAutograph1} />
                    <span className="xia-line"></span>
                  </p>
                </p>
                <p className="offprescription-doctor11">
                  <span>调配药师:</span>
                  <p className="qianming">
                    <span className="xia-line"></span>
                  </p>
                </p>
              </p>
              <p className="offprescription-doctor2">
                <p className="offprescription-doctor3">

                  <span className="customstyle customstyle">核对:</span>
                  <p></p>

                </p>
                <p className="offprescription-doctor3">
                  <span className="customstyle customstyle">发药:</span>
                  <p></p>
                </p>
                <p className="offprescription-doctor4">
                  <span>金额:</span>
                  <p></p>
                </p>
              </p>
              <p className="offprescription-tips">
                <span>备注：</span>
                <p>{detail.docter_proposal}</p>
              </p>
              <p className="offprescription-tips1">
                1、医师处方仅开具当日有效（医生注明除外）。
              </p>
              <p className="offprescription-tips1">
                2、处方超过7日用量为病情需要。
              </p>
            </div>
          </div> */}
        </Popup>
        <ImageViewer
          image={image.url}
          visible={image.visible}
          onClose={() => {
            this.setState({
              image: {
                url: "",
                visible: false,
              },
            });
          }}
        />
      </>
    );
  }
}
