import React, { Component } from "react";
import "./style.less";
import icon1 from "../../static/img/add.png";
import icon2 from "../../static/img/bianji.png";
import icon3 from "../../static/img/offtouxiang.png";
import FootNav from "../../component/FootNav";
import {
  getnewslist,
  userdelWznews,
  addchatnote,
} from "../../api/hospitalapi/chat";
import { allNewslist } from "../../api/hospitalapi/offline";
import { Toast } from "antd-mobile-v5";
import { Modal } from "antd-mobile";
import Nodata from "../../component/Nodata";
import icon02 from "../../static/img/close1.png";
import icon03 from "../../static/img/contact-complaint.png";
import fhhome from "../../static/img/icon/fhhome.png";
const { alert } = Modal;
export default class index extends Component {
  constructor() {
    super();
    this.state = {
      patientId: localStorage.getItem("patientId1"),
      newslist: [],
      newslist1: [],
      timer: localStorage.getItem("timer"),
      data: ["png", "jpg", "peg", "bmp", "gif", "pdf"],
      newsstate: 1,
      visible5: false,
      answeredDetails: [],
      flag:false,
      questions: {
        '是否复诊且服用过此类药品并无过敏反应？': 'is_review_drug_allergic',
        '有无疾病史？': 'past_history_note',
        '有无过敏史？': 'allergic_history_note',
        '有无肝肾功能异常？': 'abnormal_liver_function'
      }
    };
  }

  // componentDidMount = async () => {
  //   const { patientId, timer } = this.state;
  //   window.clearInterval(timer);
  //   console.log(patientId, "patientId===");

  //   if (!patientId) {
  //     Toast.show({
  //       content: "当前未登录，请先登录",
  //       duration: 1000,
  //     });
  //   } else {
  //     // this.wzNews();
  //     setInterval(() => {
  //       if(this.state.flag){
  //         return
  //       }
  //       this.wzNews();
  //     }, 5000);
  //   }
  // };

  componentDidMount = async () => {
    const {patientId, timer} = this.state
    window.clearInterval(timer)

    if (!patientId) {
        Toast.show({
            content: "当前未登录，请先登录",
            duration: 1000
        })
    } else {
        this.wzNews()
    }

  }

  wzNews = async () => {
    const {patientId, data} = this.state
    const res = await allNewslist({
        userId: patientId
    })

    if (res && res.code == 1) {
        if (res&&res.data&&res.data[0]&&res.data[1]){
            this.setState({
                newslist: res.data[0],
                newslist1: res.data[1],
            })
        }
    }
}

  
  
  // wzNews = async () => {
  //     const {patientId, data} = this.state
  //     const res = await allNewslist({
  //         userId: patientId
  //     })
  //     if (res && res.code == 1) {
  //         if (res&&res.data&&res.data[0]&&res.data[1]){
  //             const arr=  ['是否复诊且服用过此类药品并无过敏反应？','有无疾病史？','有无过敏史？','有无肝肾功能异常？']
  //             const sendmessage = ['是','past_history_note','allergic_history_note','abnormal_liver_function']
  //             setTimeout(()=>{
  //               this.setState({
  //                 newslist: res.data[0],
  //                 newslist1: res.data[1],
  //             })
  //             })
  //               res.data && res.data[0].forEach((item, index) => {
  //                 let indexInfo = arr.indexOf(item.detail_content)
  //                 if(indexInfo<0) return
  //                 let detailContent = null
  //                 if(indexInfo == 0) {
  //                 detailContent = '是'
  //                 }else if(indexInfo == 3){
  //                     detailContent = item.abnormal_liver_function?'是':'无'
  //                 }else{
  //                     detailContent = item[sendmessage[indexInfo]]
  //                 }
  //                 this.setState({
  //                   flag:true
  //                 },()=>{
  //                   addchatnote({
  //                     consultationId: item.consultation_id,
  //                     Identity: 1,
  //                     detailType: 1,
  //                     detailContent,
  //                   }).then(()=>{
  //                     this.setState({
  //                       flag:false
  //                     })
  //                   })
  //                 })
                  

  //              });
             
  //         }
  //     }
  // }

  goinfo(news) {

    localStorage.setItem("item", JSON.stringify(news));
    localStorage.setItem("consultationId", news.consultation_id);
    this.props.history.push(
      `/offlineDoctorchat?consultationId=${news.consultation_id}&docterId=${
        news.docter_id
      }&docuserType=${3}`
    );
  }
  goinfo1(news) {

    localStorage.setItem("consultationId", news.consultation_id);
    this.props.history.push(`/offawait`);
  }

  //删除消息
  delete = (e, consultationId) => {
    e.stopPropagation();
    const { newslist } = this.state;
    alert("确认取消该消息?", "", [
      { text: "取消", onPress: () => console.log("cancel"), style: "default" },
      {
        text: "确定",
        onPress: () => {
          // 删除问诊消息
          userdelWznews(consultationId).then((res) => {
            let arr = [];
            if (res.code == 1) {
              const newTimer = window.setTimeout(() => {
                this.wzNews();
                window.clearTimeout(newTimer);
              }, 50);
              newslist.map((item) => {
                if (item.userDelete == 1) {
                  arr.push(item);
                }
              });
              this.setState({
                newslist: arr,
              });
            }
          });
        },
      },
    ]);
  };

  // 复制客服电话
  copykefuhone = () => {
    const spanText = document.getElementById("contactPhone").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.show({
      content: "复制成功",
      duration: 1000,
    });
  };
  render() {
    const { newslist, newsstate, newslist1, visible5 } = this.state;
    return (
      <div className="newsbox1">
        <div className="box-top12">
          <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
          {/* <span className='return-right' onClick={this.props.history.goBack}>❮</span> */}
          <span className="title-middle">消息</span>
        </div>
        <div className="news-inend">
          <p
            className={newsstate == 1 ? "news-top-left" : "news-top-left1"}
            onClick={() => this.setState({ newsstate: 1 })}
          >
            进行中
          </p>
          <p
            className={newsstate == 2 ? "news-top-right" : "news-top-right1"}
            onClick={() => this.setState({ newsstate: 2 })}
          >
            等待中
          </p>
        </div>
        {newsstate == 1 ? (
          <div className="box-container12">
            {newslist.length ? (
              newslist.map((news, index) => {
                return (
                  <div
                    className="container-item1"
                    onClick={() => this.goinfo(news)}
                  >
                    <img
                      src={news.docter_avatar ? news.docter_avatar : icon3}
                      alt=""
                    />
                    <div className="news-detail1">
                      <p className="user-name">
                        <span className="user-name-left">
                          {news.docter_name}
                        </span>
                        <span className="user-name-right">
                          <span className="nowchat">进行中</span>
                          <span>{news.content_time}</span>
                        </span>
                      </p>
                      <p className="news-onemore">
                        <p className="news-onemore-left">
                          {news.detail_type == "3"
                            ? "处方单"
                            : news.detail_type == "2"
                            ? "[图片]"
                            : news.detail_content}
                        </p>
                        {news.consultation_type == "1" ? (
                          <p className="wz-type wz-type1">图文问诊</p>
                        ) : (
                          <p className="wz-type wz-type2">视频问诊</p>
                        )}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <Nodata type="消息" />
            )}
          </div>
        ) : (
          <div className="box-container12">
            {newslist1.length ? (
              newslist1.map((news, index) => {
                return (
                  <div
                    className="container-item1"
                    onClick={() => this.goinfo1(news)}
                  >
                    <img
                      src={news.docter_avatar ? news.docter_avatar : icon3}
                      alt=""
                    />
                    <div className="news-detail1">
                      <p className="user-name">
                        <span className="user-name-left">
                          {news.patientName}
                        </span>
                        <span className="user-name-right">
                          <span
                            className="nowchat"
                            style={{ backgroundColor: "#FF9E2E" }}
                          >
                            等待中
                          </span>
                          <span>{news.content_time}</span>
                        </span>
                      </p>
                      <p className="news-onemore">
                        <p className="news-onemore-left">
                          病情：{news.consultation_detail}
                        </p>
                        {news.consultation_type == "1" ? (
                          <p className="wz-type wz-type1">图文问诊</p>
                        ) : (
                          <p className="wz-type wz-type2">视频问诊</p>
                        )}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <Nodata type="消息" />
            )}
          </div>
        )}
        <p
          className="lxkf"
          onClick={() =>
            this.setState({
              visible5: true,
            })
          }
        >
          联系客服
        </p>
        {visible5 ? (
          <div className="contact-complaint-outer">
            <div className="contact-complaint-inner">
              <img
                className="close"
                src={icon02}
                onClick={() =>
                  this.setState({
                    visible5: false,
                  })
                }
              />
              <h2>联系或投诉</h2>
              <p className="copy-phone">
                <span>客服电话：</span>
                <span id="contactPhone">19048012565</span>
                <i
                  className="iconfont icon-baocun"
                  onClick={() => this.copykefuhone()}
                />
              </p>
              <p className="tips-contact-complaint">
                在使用中如有问题或疑问，您可以拨打电话联系我们。
              </p>
              <p className="chat-contact-complaint">
                <img src={icon03} />
              </p>
              <p className="tips-contact-complaint">
                添加客服微信，及时处理您的问题
              </p>
            </div>
          </div>
        ) : null}
        {/*<FootNav></FootNav>*/}
      </div>
    );
  }
}
