import React, { Component } from 'react'
import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import { queryDepartment ,ejDepartment} from "../../../api/hospitalapi/department"
import "./style.less"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            list: [],
            activeKey: "1",
            list2:[],
        }
    }
    componentDidMount = async () => {
        const res0 = await queryDepartment()
        // console.log(res0);
        let list = res0.data[0]
        list = list.map(item => {
            return {
                key: item.dep_id+"",
                title: item.dep_name,
            }
        })
        // console.log(list);
        this.setState({
            list,
            activeKey: list[0].key+"",
        })
        const res = await ejDepartment({depParentId:list[0].key})
        // console.log(res);
        if (res) {
            if (res.data[0].length) {
                let list2 = res.data[0]
                list2 = list2.map(item => {
                    return {
                        key: item.dep_id+"",
                        title: item.dep_name,
                    }
                })
                // console.log(list2);
                this.setState({
                    list2:list2
                },()=>{
                    // console.log(list2);
                })
            }else{
                this.setState({
                    list2:[]
                })
            }
        }
    }
    setActiveKey = async (activeKey) => {
        const { list } = this.state
        // console.log(activeKey);
        let a;
        list.map(item=>{
            if (item.key==activeKey) {
               a=item
            }
        })
        // console.log(a);
        const res = await ejDepartment({depParentId:activeKey})
        // console.log(res);
        if (res) {
            if (res.data[0].length) {
                let list2 = res.data[0]
                list2 = list2.map(item => {
                    return {
                        key: item.dep_id+"",
                        title: item.dep_name,
                    }
                })
                // console.log(list2);
                this.setState({
                    activeKey,
                    list2:list2
                },()=>{
                    // console.log(list2);
                })
            }else{
                this.setState({
                    activeKey,
                    list2:[]
                })
            }
        }
    }
    render() {
        const { list,list2, activeKey } = this.state
        return (
            <div className='typebox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/")} /><h1>全部科室</h1>
                </div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <SideBar activeKey={activeKey} onChange={(activeKey) => this.setActiveKey(activeKey)} >
                            {list.map((item) => (
                                <SideBar.Item key={item.key} title={item.title}/>
                            ))}
                        </SideBar>
                    </div>
                    <div className='depart-right'>
                        <p className='depart-all'>全部</p>
                        {list2.map(item => (
                            <p>{item.title}</p>
                        ))}
                        
                    </div>
                </div>
            </div>
        )
    }
}
