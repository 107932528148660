import React, { Component } from 'react'
import "./style1.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/login/login1-bgc.png"
import icon3 from "../../static/img/login/phone.png"
import icon4 from "../../static/img/login/yzm.png"
import { Toast } from "antd-mobile-v5"
import { WhiteSpace, Checkbox, Modal, List } from 'antd-mobile'
import { getlocode, getrecode, inspectonly, verificationcode, register, userlogin, getuserinfo,getdoctorsh,updatesxVerify } from "../../api/hospitalapi/login"
import moment, { duration } from 'moment'
import localStorage from 'redux-persist/es/storage'
import { sync } from 'resolve'
const CheckboxItem = Checkbox.CheckboxItem
export default class login extends Component {
    constructor() {
        super()
        this.state = {
            number: "",
            status: false,
            code: "",
            time: 60,
            type: 0,
            tip:0,
            valid:true,//获取验证码状态位
            valid1:true,//登录或注册状态位
            timer:'',
            selectcode:0,
            readcode:0
        }
    }
    getinputvalue = (e) => {
        if (e.target.value.length>11) {
            this.setState({
                number: e.target.value.toString().substr(0,11)
            })
        }else{
            this.setState({
                number: e.target.value
            })
        }
    }
    getpassword=(e)=>{
        this.setState({
            password: e.target.value
        })
    }
    getcodenum = (e) => {
        this.setState({
            code: e.target.value,
            tip:1
        })
    }
    //点击获取验证码
    code = async () => {
        const { number, status, time } = this.state
        const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        // if (status) {
        // 	this.setState({
        // 		type: 1
        // 	})
        // 	Toast.show({
        // 		content: `请${time}秒后点击获取`,
        // 		duration: 1000
        // 	})
        // } else {
        // 	this.setState({
        // 		status: true,
        // 		time:60
        // 	},()=>{
        if (number) {
            if (reg_tel.test(number)) {
                if (status) {
                    this.setState({
                        type: 1
                    })
                    Toast.show({
                        content: `请${time}秒后点击获取`,
                        duration: 1000
                    })
                }else {
                    // 更新失效验证码状态
                    updatesxVerify(number).then(res0 => {
                        // console.log(res0);
                        if (res0.code == 1) {
                            this.setState({
                                status: true,
                            }, () => {
                                //获取登录验证码
                                getlocode({
                                    userPhone: number
                                }).then(res => {
                                    // console.log(res);
                                    if (res.code == 1) {
                                        this.setState({
                                            time: 60
                                        }, () => {
                                            let code = this.state.time
                                            const timer = setInterval(() => {
                                                code -= 1
                                                this.setState({
                                                    time: code,
                                                })
                                                if (code == 0) {
                                                    clearInterval(timer)
                                                    // updatesxVerify(number).then(res=>{
                                                    // 	console.log(res);
                                                    // })
                                                    this.setState({
                                                        status: false,
                                                        type: 0
                                                    })
                                                }
                                            }, 1000);
                                        })
                                    } else {
                                        Toast.show({
                                            content: "您验证码发送太频繁，请稍后再试",
                                            duration: 800
                                        })
                                    }
                                })
                            })
                        }
                    })
                }
            } else {
                Toast.show({
                    content: "请输入正确的手机号",
                    duration: 800
                })
                this.setState({
                    type: 0
                })
            }
            // }

        } else {
            Toast.show({
                content: "请输入手机号",
                duration: 800
            })
            this.setState({
                type: 0
            })
        }
        // 	})
        // }

    }
    // 节流，一秒内只执行一次获取验证码操作
    // getcode = () => {
    // 	const { type, time, status,valid } = this.state
    // 	if (!valid) {
    // 		return ;
    // 	}else{
    // 		if (status) {
    // 			Toast.show({
    // 				content: `请${time}秒后点击获取`,
    // 				duration: 1000
    // 			})
    // 		}else{
    // 			this.setState({
    // 				valid :false//工作期间状态码设置为false
    // 			},()=>{
    // 				this.code()
    // 				setTimeout(() => {
    // 					this.setState({
    // 						valid :true
    // 					})
    // 				}, 200)
    // 			})
    // 		}
    // 	}
    // }
    //点击登录或注册
    login = async () => {
        const { type, number, code } = this.state
        if (code) {
            if (type == 0) {
                const res = await verificationcode(number, code)
                // console.log(res);
                if (res.data[0].length==0&&res.data[0][0].smsCode==code) {
                    const res1 = await register({
                        phone: number
                    })
                    if (res1.code == 1) {
                        userlogin({patientPhone:number,smsCode:code}).then(res=>{
                            // console.log(res);
                        })
                        const res2 = await getuserinfo({
                            patientPhone: number
                        })
                        if (res2.code == 1) {
                            localStorage.setItem("patientPhone1", res2.data[0][0].patientPhone)
                            localStorage.setItem("patientAvatar", res2.data[0][0].patientAvatar)
                            localStorage.setItem("patientName", res2.data[0][0].patientName)
                            localStorage.setItem("patientId1", res2.data[0][0].patientId)
                            localStorage.setItem("pharmacyId", 1)
                            this.props.history.push("/drugselection")
                        } else {
                            Toast.show({
                                content: "网络错误",
                                duration: 800
                            })
                        }
                    }
                    // console.log(res1);
                } else {
                    Toast.show({
                        content: "验证码错误",
                        duration: 800
                    })
                }
            } else {
                const res = await verificationcode(number, code)
                // console.log(res);
                if (res.data[0].length&&res.data[0][0].smsCode==code) {
                    userlogin({patientPhone:number,smsCode:code}).then(res=>{
                        // console.log(res);
                    })
                    const res2 = await getuserinfo({
                        patientPhone: number
                    })
                    if (res2.code == 1) {
                        localStorage.setItem("patientPhone1", res2.data[0][0].patientPhone)
                        localStorage.setItem("patientAvatar", res2.data[0][0].patientAvatar)
                        localStorage.setItem("patientName", res2.data[0][0].patientName)
                        localStorage.setItem("patientId1", res2.data[0][0].patientId)
                        localStorage.setItem("pharmacyId", 1)
                        this.props.history.push("/drugselection")
                    } else {
                        Toast.show({
                            content: "网络错误",
                            duration: 800
                        })
                    }

                    // const res1=await userlogin({
                    // 	patientPhone:number,
                    // 	smsCode:code
                    // })
                    // if(res1.code==1){

                    // }
                    // console.log(res1);
                } else {
                    Toast.show({
                        content: "验证码错误",
                        duration: 800
                    })
                }
            }
        } else {
            Toast.show({
                content: "请输入验证码",
                duration: 800
            })
        }

    }
    // 节流，一秒内只执行一次登录或注册操作
    userLogin = () => {
        const { type, number, code,valid1,readcode,tip } = this.state
        if (tip==1){
            if (readcode==1){
                if (!valid1) {
                    return ;
                }else{
                    this.setState({
                        valid1 :false//工作期间状态码设置为false
                    })
                    setTimeout(() => {
                        this.login()
                        this.setState({
                            valid1 :true
                        })
                    }, 500)
                }
            }else {
                Toast.show({
                    content: "请勾选协议",
                    duration: 800
                })
            }
        }else{
            Toast.show({
                content: "请先输入手机号和验证码",
                duration: 800
            })
        }

    }
    //点击重新获取验证码
    codeagain = async () => {
        const { type, number, time } = this.state
        this.setState({
            type:1
        })
        if (time == 0) {
            let res
            if (res == 0) {
                res = await getrecode({
                    userPhone: number
                })
            } else {
                res = await getlocode({
                    userPhone: number
                })
            }
            //console.log(res,number);
            if (res.code == 1) {
                Toast.show({
                    content: "获取成功",
                    duration: 800
                })
                let code = 60
                const timer = setInterval(() => {
                    code -= 1
                    this.setState({
                        time: code
                    })
                    if (code == 0) {
                        clearInterval(timer)
                        this.setState({
                            status: false,
                            type:0
                        })
                    }
                }, 1000);
            } else {
                Toast.show({
                    content: "网络错误",
                    duration: 800
                })
            }
        } else {
            Toast.show({
                content: `${time}秒后再次获取`,
                duration: 800
            })
        }
    }
    // 节流，一秒内只执行一次获取验证码操作
    getcodeagain=()=>{
        const { type, number, code,valid } = this.state
        if (!valid) {
            return ;
        }else{
            this.setState({
                valid :false//工作期间状态码设置为false
            })
            setTimeout(() => {
                this.codeagain()
                this.setState({
                    valid :true
                })
            }, 500)
        }
    }
    toSelect=()=>{
        this.setState({
            selectcode:this.state.selectcode==0?1:0,
            readcode:this.state.readcode==0?1:0,
        })
    }
    componentWillUnmount = () => {
        const { timer } = this.state
        window.clearInterval(timer)
    }
    render() {
        const { number, status, code, time ,tip,selectcode} = this.state
        return (
            <div className='loginbox1'>
                <div className='login1-bgc'>
                    <img src={icon2}/>
                </div>
                <div className='login1-body'>
                    <p className='login1-title'>手机号登录</p>
                    <div className='box-input'>
                        <p className='login1-phone'>
                            <img src={icon3}/>
                            <input type="number" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
                        </p>
                        <p className='login1-phone'>
                            <img src={icon4}/>
                            <input type="number" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
                            <button className={status?'getcode1':'getcode'} onClick={this.code}>{status ? `${time}秒后重新获取` : "获取验证码"}</button>
                        </p>
                        {/*<input type="pwd" value={password} onChange={(e) => this.getpassword(e)} placeholder='请输入至少8位由字母数字和特殊符号组成的密码' />*/}
                    </div>
                    <div className='box-btn'>
                        <button className={tip==1?'dianji':'weidian'} onClick={this.userLogin}>登录</button>
                    </div>
                    <div className='box-bottom'>
                        {/*<input type='radio' name='a' checked={selectcode==1} onClick={()=>this.toSelect()}/>*/}
                        <CheckboxItem checked={selectcode==1} onClick={()=>this.toSelect()}  className='my-radio'/>
                        <p>我已知情并同意 <a onClick={() => this.props.history.push("/agrenagreement")}>《医药服务协议》</a></p>
                    </div>
                    <div className='login1-hos'>云益健互联网医院有限公司</div>
                </div>
            </div>
        )
    }
}
