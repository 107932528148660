import React, { Component } from 'react'
import Axios from 'axios';
import {getToken,getJMURL} from "../../api/request/user"
export default class getUserInfo extends Component {
    constructor(){
        super();
        this.state={
        }
    }
    componentDidMount=async()=>{
        const arr = window.location.href.split("&")
        
        let url = arr[0].substring(52, arr[0].length)
        const res=await getJMURL({
            url
        })
        //console.log(res)
        const wximg=res.data.headimgurl
        const wxuserName=res.data.nickname
        const wxuserOpenid=res.data.openid
        //console.log(wximg,wxuserName,wxuserOpenid)
        localStorage.setItem("wxuserName",wxuserName)
        localStorage.setItem("wximg",wximg)
        localStorage.setItem("wxuserOpenid",wxuserOpenid)
        if(localStorage.getItem("type")=="1"){
            window.location.href="http://h5.sczyzk.com/index.html#/"
        }else{
            window.location.href="http://h5.sczyzk.com/index.html#/?env=single&pharmacyId=2"
        }
    }
    render() {
        return (
            <div>
               <h1>请稍等</h1>
            </div>
        )
    }
}
