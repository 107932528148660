import { axiosInstance,postLogin } from '../config'
// 获取所有线下药店医生id
export const allOfflinedoc = () => {
    return axiosInstance.get(`/api/hosp/offline-pharmacy/all-offlinedoc`)
}
//查询线下药店用户当前问诊单状态
export const nowState = (consultationId) => {
    return axiosInstance.get(`/api/hosp/offline-pharmacy/now-state?consultationId=${consultationId}`)
}
//查询线下药店问诊单选择的药品信息
export const allmedsNews = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/allmeds-news`,data)
}
//更新订单的问诊id和问诊单的医生id
export const updateId = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/update-id`,data)
}
//患者问诊列表
export const allNewslist = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/list`,data)
}
//患者保存身份证号和openid
export const addPatientOpenid = (data) => {
    return axiosInstance.post(`/api/hosp/offline-pharmacy/add-patient-openid`,data)
}
//扫一维码
export const oneqrcode = (qrcode) => {
    return axiosInstance.get(`/faas/hosp/offline-pharmacy/oneqrcode?qrcode=${qrcode}`)
}
//药房查询店铺信息
export const offDetail = (pharmacyId) => {
    return axiosInstance.get(`/faas/hosp/offline-pharmacy/off-detail?pharmacyId=${pharmacyId}`)
}
//查询所有订单及药品
export const allOrders = (token) => {
    return axiosInstance.get(`/faas/hosp/offline-pharmacy/allorder-meds?token=${token}`)
}
//查询当天所有订单及药品
export const allNowdayorders = (token) => {
    return axiosInstance.get(`/faas/hosp/offline-pharmacy/all-nowday-orders?token=${token}`)
}
//查询视频消息
export const addvideoMessage = (data) => {
    return axiosInstance.post(`/api/hosp/doctor-prescription/add-video-message`,data)
}
//店员查询当天店铺所有订单及药品
export const dayAllorder = (data) => {
    return axiosInstance.post(`/faas/hosp/shopassistant/day-allorder`,data)
}
//待接处方详情
export const dutyConsultationDetails = (data) => {
    return axiosInstance.post('/api/hosp/hospital-duty/duty-consultation-details', data)
}
// 查询店员新建一个问诊单所需信息
export const storenewOrder = (data) => {
    return axiosInstance.post('/faas/hosp/shopassistant/store-setorder', data)
}
//店员进入查看列表保存验证码
export const saveCode = (data) => {
    return axiosInstance.post('/api/hosp/shopassistant/save-code', data)
}
//查看是否入驻店铺
export const isSelfphamacy = (pharmacyId) => {
    return axiosInstance.get(`/api/hosp/pharmacy-management/is-selfphamacy?pharmacyId=${pharmacyId}`)
}
//未续费店铺用户支付修改问诊状态和价格	/api/hosp/offline-pharmacy/update-state-price
export const updateStateprice = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/update-state-price', data)
}
//店员查看所有已开出处方
export const dutyStatisticsAll = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/store-statistics-all', data)
}
//提交问诊前查询药品要用信息
export const getOrderallways = (data) => {
    return axiosInstance.post('/faas/hosp/offline-pharmacy/get-orderallways', data)
}
//审⽅API
export const prescriptionValidation = (data) => {
    return axiosInstance.post('/faas/hosp/wzdocter/prescription-validation', data)
}
//选择接诊医生
export const selectDoctor = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/select-doctor', data)
}
// 修改指定接诊医生
export const updateDoctor = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/update-doctor', data)
}
//调配签名保存	/api/hosp/offline-pharmacy/save-allocate-signature
export const saveAllocateSignature = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/save-allocate-signature', data)
}
//查询药品对应医生类型
export const selectType = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/select-type', data)
}

// 查询所有医生
export const selectAllDoc = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/select-all-doctor', data)
}


// 查询限制级药品
export const selectSeriousmeds = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/select-serious-meds', data)
}


// 保存店员签名图片
export const updateStaff = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-staff', data)
}

// 查询店铺中是否已经存在店员签名selectPhaid
export const selectPhaid = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/select-staff', data)
}

// 更新已有店员签名setStaffsi
export const setStaffsi = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/set-update-staff', data)
}

// 查询店铺中是否存在药店药师签名selectPharmacistId
export const selectPharmacistId = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/select-pharmacistid', data)
}


// 更新已有药店药师签名setPharmacistSi
export const setPharmacistSi = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/set-update-pharmacist', data)
}


// 保存药店药师签名图片updatePharmacistSi
export const updatePharmacistSi = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-pharmacist', data)
}


//查询疾病对应的科室
export const disDepartment = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/select-dis-department', data)
}



// 12月11日新增
// 新增药店药师签名
export const addPhaMenber = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-phamenber', data)
}

//修改药师药店选中状态updataPhaCheck
export const updataPhaCheck = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-phacheck', data)
}
// 修改店员签名选中状态updataStaffCheck
export const updataStaffCheck = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-staffcheck', data)
}

// 查询对应药店药师信息selectPhainfo
export const selectPhainfo = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/select-pha-info', data)
}

// 查询是否已存在该药店药师
export const selectPhaAl = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/select-pha-alealy', data)
}

// 修改药师签名及信息
export const updatePhaMenber = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-pha-info', data)
}

// 查询对应店员信息
export const selectStfinfo = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/select-stf-info', data)
}

// 查询是否已存在该店员签名
export const selectStfAl = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/select-stf-alealy', data)
}

// 修改店员签名及信息
export const updateStfMenber = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/update-stf-info', data)
}

// 新增店员签名
export const addStfMenber = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-stfmenber', data)
}


// 查询是否中药问诊单isChineseMeds
export const isChineseMeds = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/is-chinesemeds-consu', data)
}

//查询用法用量chineseMethod
export const chineseMethod = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method', data)
}

// 12月21日新增
//查询店铺驳回处方
export const dayRejectorder = (data) => {
    return axiosInstance.post(`/faas/hosp/shopassistant/day-reject-order`,data)
}


// 查询店铺所属权限 selectFiltering
export const selectFiltering = (data) => {
    return axiosInstance.post(`/api/hosp/store-permissions/select-filtering`,data)
}

// 查询处方笺是否需要签名
export const selectIsSignature = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/select-is-signature`,data)
}

// 修改药房是否需要显示处方笺签名  updateIsSignture
export const updateIsSignture = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/update-is-signature`,data)
}

// 修改药房是否需要显示店员处方笺签名 
export const updateIsSigntureStore = (data) => {
    return axiosInstance.post(`/api/hosp/pharmacy/update-is-signature-store`,data)
}

// 查询处方单店员、药店药师签名图
export const selectSignaturePic = (data) => {
    return axiosInstance.post('/api/hosp/wzpharmacist/select-signature-pic', data)
}

//查询用法用量chineseMethod
export const chineseMethodDoc = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method-doc', data)
}

//查询药房所属区域
export const selectPharmacyAear = (data) => {
    return axiosInstance.post('/api/hosp/pharmacy/select-pharmacy-aear', data)
}

// 查询店铺已接问诊数量	
export const phaCountNum = (data) => {
    return axiosInstance.post('/api/hosp/pharmacy/received-diagnosis-count', data)
}
// 查询店铺驳回处方数量	
export const phaRefuseCountNum = (data) => {
    return axiosInstance.post('/api/hosp/pharmacy/refuse-diagnosis-count', data)
}

// 查询店铺信息 
export const selectPharmacyName = (data) => {
    return axiosInstance.post('/api/hosp/pharmacy/select-pharmacy-name', data)
}

// 查询该患者今天是否已经购买过药品
export const todayBuyName = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/today-buy-name', data)
}

// 店铺查询所有订单
export const shopAllOrders = (data) => {
    return axiosInstance.post('/faas/hosp/shop-port/shop-all-orders', data)
}

// 将当前患者更改为默认就诊人
export const changeDefaultMember = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/change-default-member', data)
}