import React ,{Component}from 'react'
import { HashRouter } from "react-router-dom"
// import BMap from 'BMap'

import getUrl from '../component/GetUrlParam'
import { wxConfig } from '../component/scan/index'

import Layout from "../container/Layout"
import SubRouter from "./SubRouter"

class AppRouter  extends Component{

    componentDidMount() {
        // var map = new BMap.Map("allmap")
        // var point = new BMap.Point(116.331398, 39.897445)
        // map.centerAndZoom(point, 12)

        // var geolocation = new BMap.Geolocation()
        // geolocation.getCurrentPosition(function (r) {
        //     localStorage.posProvince = r.address.province
        //     localStorage.posCity = r.address.city
        //     localStorage.longitude = r.point.lng
        //     localStorage.latitude = r.point.lat
        //     console.log('您的位置：' + r.address.province + r.address.city + r.address.district + r.address.street + r.address.street_number)
        //     console.log('您的位置：' + r.point.lng + ',' + r.point.lat)
        //     // 创建地理编码实例      
        //     var myGeo = new BMap.Geocoder()
        //     // 根据坐标得到地址描述    
        //     myGeo.getLocation(new BMap.Point(r.point.lng, r.point.lat), function(result){
        //         if (result){
        //             // alert(result.address)
        //             console.log(result)
        //         }
        //     })
        // })
        const urlHash = window.location.hash
        const str = urlHash.substr(urlHash.indexOf('?'))
        const env = getUrl('env', str)
        const lng = getUrl('lng', str)
        const lat = getUrl('lat', str)
        // if(env === 'app') {
        //     wxConfig('appPosition', {}, {lng:lng, lat:lat})
        // } else {
        //     wxConfig('position')
        // }
    }
    render(){
        return(
            <HashRouter>
                <Layout path='/'>
                    <SubRouter/>
                </Layout>
            </HashRouter>
        )
    }
}

export default AppRouter