import React, { Component, createRef, useRef } from "react";
import "./style.less";
import {
  Form,
  Input,
  DatePicker,
  CascadePicker,
  Popover,
} from "antd-mobile-v5";
import icon1 from "../../static/img/yy/fanhuihei.png";
import icon2 from "../../static/img/icon/false1.png";
import fhhome from "../../static/img/icon/fhhome.png";
import { Toast, Modal, Picker, Button } from "antd-mobile";
// import { basicColumns } from "./relationship-data";
import {
  adduserinfo,
  changeuserinfo,
  delPatientMember,
  getMemberNews,
  getuserinfo,
  addUsersOpersLogs,
  switchDefaultPatient
} from "../../api/hospitalapi/user";
import {
  selectFiltering,//查询店铺所属权限
} from "../../api/hospitalapi/offline";
import {
  addElectronicIdcard,
  isElectronic,
} from "../../api/hospitalapi/electronic";
import {
  getProvince,
  getCity,
  getDistrict,
  selectPharmacyAear
} from "../../api/request/qualifications";
import getUrl from "../../component/GetUrlParam";
import touxiang from "../../static/img/home/touxiang.png";
import del from "../../static/img/home/del.png";
import update from "../../static/img/home/update.png";
import search from "../../static/img/home/search1.png";
import Nodata from "../../component/Nodata";
import { replace } from "lodash";
const alert = Modal.alert;
let userAgeRe;
export default class index extends Component {

  constructor() {
    super();

    this.state = {

      visible6: true //是否显示input
      ,
      name: "",
      sex: 1, //1男 2女
      date: "",
      userAge: '',
      idcard: "",
      idcard1: "",
      idcardcode: 0,
      address: "",
      alladdress: [],
      
      phone: "",
      phone1: "",
      phonecode: 0,
      patientId: localStorage.getItem("patientId1"),
      bodyweight: '',//体重
      guardianIdcardNum: '',//监护人身份证
      guardianname: '',//监护人姓名
      bodytemperature: 0, //体温有无异常：0：无，1：有
      bodytemperaturenum:'',//体温摄氏度数
      pasthistory: "", //既往史
      pasthistorycode: 0, //既往史 0无 1有
      allergichistory: "", //过敏史
      allergichistorycode: 0, //过敏史 0无 1有
      familyhistory: "", //遗传史
      familyhistorycode: 0, //遗传史 0无 1有
      liver: 0, //肝功能 0正常 1异常
      kidney: 0, //肾功能
      memberId: "",
      province: localStorage.getItem("province"),//省
      city: localStorage.getItem("city"),//市
      county: localStorage.getItem("district"),//区
      selectmeds:
        localStorage.getItem("selectmeds") &&
          localStorage.getItem("selectmeds") != "undefined"
          ? JSON.parse(localStorage.getItem("selectmeds"))
          : [],
      provinces: "",
      cities: "",
      counties: "",
      parentId1: "",
      parentId2: "",
      cityId: "",
      username: "",
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      userlist: [],
      type: 1, //1用药人列表 2 新增 3修改
      illmiaosu: "",
      pregnancyStatus: 1, //    怀孕情况 1未怀孕 2 备孕中 3已怀孕 4哺乳期
      coordinationcode: 2,
      entitycode: 2,
      docterName: "",
      docterId: "",
      relationship: "", //与患者关系
      pickerVisible: false,
      chamedarr:
        localStorage.getItem("chamedarr") &&
          localStorage.getItem("chamedarr") != "undefined"
          ? JSON.parse(localStorage.getItem("chamedarr"))
          : [],
      medType: 1,//1西药  2中药
      isDisabled: false,//限制输入年龄
      filteringArr:[],
      pharmacyId: localStorage.getItem("pharmacyId"),
      isNewPatient:2,//1:从首页进来添加患者，2，不是
    };
  }

  componentDidMount = async () => {
    const { patientId, province, city, county, selectmeds, chamedarr, medType,cityId } = this.state;

    const res12 = await selectFiltering({pharmacyId:localStorage.getItem("pharmacyId")})

    if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
      this.setState({
        filteringArr:res12.data[0]
      })
    }
    if (!this.state.filteringArr.some(item => item.label === "疾病史/无")) {
      this.setState({
        pasthistorycode:3,
        allergichistorycode:3,
        liver:3,
      });
    }

    if (getUrl("isNewPatient", this.props.location.search)) {
      this.setState({
        isNewPatient: getUrl(
          "isNewPatient",
          this.props.location.search
        ),
      });
    }

    if (getUrl("medType", this.props.location.search)) {
      this.setState({
        medType: getUrl(
          "medType",
          this.props.location.search
        ),
      });
    }
    if (getUrl("illmiaosu", this.props.location.search)) {
      this.setState({
        illmiaosu: getUrl("illmiaosu", this.props.location.search),
      });
    }
    if (getUrl("coordinationcode", this.props.location.search)) {
      this.setState({
        coordinationcode: getUrl(
          "coordinationcode",
          this.props.location.search
        ),
      });
    }
    if (getUrl("entitycode", this.props.location.search)) {
      this.setState({
        entitycode: getUrl("entitycode", this.props.location.search),
      });
    }
    if (getUrl("docterId", this.props.location.search)) {
      this.setState({
        docterId: getUrl("docterId", this.props.location.search),
        docterName: getUrl("docterName", this.props.location.search),
      });
    }

    if (getUrl("memberId", this.props.location.search)) {
      this.setState({
        memberId: getUrl("memberId", this.props.location.search),
      });
      let res = await getMemberNews(
        getUrl("memberId", this.props.location.search)
      );

      if (res && res.data && res.data[0]) {
        let b = res.data[0][0].patient_birthday;
        let a =
          new Date(b).getFullYear() +
          "-" +
          (new Date(b).getMonth() + 1 < 10
            ? "0" + (new Date(b).getMonth() + 1)
            : new Date(b).getMonth() + 1) +
          "-" +
          (new Date(b).getDate() < 10
            ? "0" + new Date(b).getDate()
            : new Date(b).getDate());
        this.setState(
          {
            list: res.data[0][0],
            province: res.data[0][0].patient_province,
            city1: res.data[0][0].patient_cities,
            county: res.data[0][0].patient_county,
            cityId: res.data[0][0].city_id,
            date: a,
          },
          () => {
            // 将出生日期的字符串通过"-"分割成数组
            const strBirthdayArr = a.split("-");
            // 拿到出生日期的年
            const birthYear = strBirthdayArr[0];
            // 拿到出生日期的月
            const birthMonth = strBirthdayArr[1];
            // 拿到出生日期的日
            const birthDay = strBirthdayArr[2];
            // 创建一个时间对象
            const d = new Date();
            // 拿到当前时间的年
            const nowYear = d.getFullYear();
            // 拿到当前时间的月
            const nowMonth = d.getMonth() + 1;
            // 拿到当前时间的日
            const nowDay = d.getDate();
            // 如果出生日期的年等于当前时间的年
            if (nowYear === birthYear) {
              this.setState({
                age: 0,
              });
              return 0; // 返回周岁年龄 0,并终止函数执行
            }
            // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
            const ageDiff = nowYear - birthYear; // 年之差
            // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
            // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
            // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
            if (nowMonth != birthMonth) {
              // 拿到出生日期的日与当前时间的月之差
              const monthDiff = nowMonth - birthMonth; // 月之差
              // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
              this.setState({
                age: monthDiff < 0 ? ageDiff - 1 : ageDiff, // 返回周岁年龄,并终止函数执行
              });
              return monthDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
            }
            // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
            const dayDiff = nowDay - birthDay;
            // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
            this.setState({
              age: dayDiff < 0 ? ageDiff - 1 : ageDiff, // 返回周岁年龄,并终止函数执行
            });
            return dayDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          }
        );
      }
    } else {
  
      if (!province) {
        // console.log(medType, '225=====');
        // if (getUrl("medType", this.props.location.search)) {
        //   this.setState({
        //     province: chamedarr[0].province,
        //     city: chamedarr[0].city,
        //     county: chamedarr[0].district,
        //   });
        // } else {
        //   this.setState({
        //     province: selectmeds[0].province,
        //     city: selectmeds[0].city,
        //     county: selectmeds[0].district,
        //   });
        // }
        // 查询药房所属区域
        let res = await selectPharmacyAear({pharmacyId:localStorage.getItem("pharmacyId")})
    
        if(res.code == 1 && res.data && res.data[0] && res.data[0].length){
          if(res.data[0][0].pharmacy_area){
            let parts = res.data[0][0].pharmacy_area.split('/')
            let province = parts[0]
            let city = parts[1]
            let county = parts[2]
            let cityId = res.data[0][0].city_id
            this.setState({
              province:province ? province : '',
              city:city ? city : '',
              county:county ? county : '',
              cityId:cityId ? cityId : '',
            })
          }
        }
      }
    }
    //获取就诊人信息列表
    const myDate = new Date();
    // 获取就诊人信息列表

    this.getuserlist(1);
    this.getAddress();
  };

  getuserlist = async (type) => {
    const { patientId, username } = this.state;
    const res = await getuserinfo({
      patientId,
      patientName: username,
    });

    if ((res && res.data && res.data[0] && res.data[0].length) || type == 2) {
      let datalist = res.data[0];
      let nowuser = null;
      datalist.forEach((item) => {
        item.age = 1;
        let b = item.patient_birthday;
        let a =
          new Date(b).getFullYear() +
          "-" +
          (new Date(b).getMonth() + 1 < 10
            ? "0" + (new Date(b).getMonth() + 1)
            : new Date(b).getMonth() + 1) +
          "-" +
          (new Date(b).getDate() < 10
            ? "0" + new Date(b).getDate()
            : new Date(b).getDate());
        // 将出生日期的字符串通过"-"分割成数组
        const strBirthdayArr = a.split("-");
        // 拿到出生日期的年
        const birthYear = strBirthdayArr[0];
        // 拿到出生日期的月
        const birthMonth = strBirthdayArr[1];
        // 拿到出生日期的日
        const birthDay = strBirthdayArr[2];
        // 创建一个时间对象
        const d = new Date();
        // 拿到当前时间的年
        const nowYear = d.getFullYear();
        // 拿到当前时间的月
        const nowMonth = d.getMonth() + 1;
        // 拿到当前时间的日
        const nowDay = d.getDate();
        // 如果出生日期的年等于当前时间的年
        if (nowYear === birthYear) {
          item.age = 1;
        } else {
          // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
          const ageDiff = nowYear - birthYear; // 年之差
          // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
          // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
          // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
          if (nowMonth != birthMonth) {
            // 拿到出生日期的日与当前时间的月之差
            const monthDiff = nowMonth - birthMonth; // 月之差
            // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
            item.age = monthDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          } else {
            // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
            const dayDiff = nowDay - birthDay;
            // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
            item.age = dayDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          }
          if (item.age == 0) {
            item.age = 1;
          }
        }
      });
      this.setState({
        userlist: res.data[0],
      });
    } else {
      this.setState({
        type: 2,
      });
    }
  };
  getAddress = async () => {
    let arrayProvinces = [];
    let arrayCities = [];
    let arrayDistricts = [];
    getProvince().then((res) => {

      if (res && res.data && res.data[0]) {
        res.data[0].map((item) => {
          let arr = {
            id: item.id,
            label: item.name,
            value: item.name,
            parentId: item.parent_id,
          };
          arrayProvinces.push(arr);
        });
        res.data[1].map((item) => {
          let arr1 = {
            id: item.id,
            label: item.name,
            value: item.name,
            parentId: item.parent_id,
          };
          arrayCities.push(arr1);
        });
        res.data[2].map((item) => {
          let arr1 = {
            id: item.id,
            label: item.name,
            value: item.name,
            parentId: item.parent_id,
          };
          arrayDistricts.push(arr1);
        });
        arrayProvinces.map((item) => {
          item.children = [];
          arrayCities.map((i) => {
            if (i.parentId == item.id) {
              item.children.push(i);
            }
          });
        });
        arrayCities.map((item) => {
          item.children = [];
          arrayDistricts.map((i) => {
            if (i.parentId == item.id) {
              item.children.push(i);
            }
          });
        });
        this.setState({
          alladdress: arrayProvinces,
          counties: arrayDistricts,
        });
      }
    });
  };
  onFinish = (value) => {
    // console.log(value);
  };

  /**
   * 输入身份证得到用户的年龄
   * @param {} 
   * @returns 
   */

  getUserAge = (e = null, idCard = null, age = null) => {
    if (e.target.value.replace(/\s*/g, "") != '') {
      // debugger
      this.setState({
        isDisabled: true
      })
    } else {
      this.setState({
        isDisabled: false
      })
    }

    if (age != null) {
      return age
    }

    if (e == null && idCard == null) {
      return
    }
    let dateNotIsContainEmty
    if (e != null) {
      dateNotIsContainEmty
        = e.target.value.replace(/\s*/g, "")
      // debugger
      if (dateNotIsContainEmty.length != 18) {
        return
      }
    } else if (idCard != null) {
      dateNotIsContainEmty = idCard
    }
    let currentYear = new Date().getFullYear()
    let currentMonth = new Date().getMonth() + 1; // 月份从0开始，因此加1
    let currentDay = new Date().getDate();
    let birthYear = dateNotIsContainEmty.substring(6, 10)
    let birthMonth = dateNotIsContainEmty.substring(10, 12)
    let birthDay = dateNotIsContainEmty.substring(12, 14)

    // console.log(birthYear,birthMonth,birthDay);  
    
    // 计算年龄
    let currentDate = new Date(); // 当前日期
    let birthDate = new Date(birthYear, birthMonth - 1, birthDay)
    let currentAge = currentYear - birthYear;

    // 判断是否已过生日
    if (
        currentDate < new Date(currentYear, birthMonth - 1, birthDay) // 今年的还未到
    ) {
        currentAge--; // 如果当前日期在今年的生日之前，年龄-1
    }

    // console.log(`年龄: ${currentAge}`);
    if(currentAge < 7){
      this.setState({
        relationship:'子女'
      })
    }
    return currentAge;

    
    // if((currentYear - birthYear) < 7){
    //   this.setState({
    //     relationship:'子女'
    //   })
    // }
    // return currentYear - birthYear
  }


  setvalue = (e, type, age = null) => {
    switch (type) {
      //姓名
      case "name":
        if (e.target.value.replace(/\s*/g, "")) {
          this.setState({
            name: e.target.value.replace(/\s*/g, ""),
          });
        } else {
          this.setState({
            name: "",
          });
        }
        break;
      //性别
      case "sex1":
        this.setState({
          sex: 1,
        });
        break;
      case "sex2":
        this.setState({
          sex: 2,
        });
        break;
      //    怀孕情况
      case "pregnancyStatus1":
        this.setState({
          pregnancyStatus: 1,
        });
        break;
      case "pregnancyStatus2":
        this.setState({
          pregnancyStatus: 2,
        });
        break;
      case "pregnancyStatus3":
        this.setState({
          pregnancyStatus: 3,
        });
        break;
      case "pregnancyStatus4":
        this.setState({
          pregnancyStatus: 4,
        });
        break;
      //    身份证号
      case "idcard":
        this.setState({
          userAge: this.getUserAge(e, null, age)
        })
        if (e == null)
          return
 
        if (e.target.value.replace(/\s*/g, "")) {
          this.setState({
            idcard: e.target.value.replace(/\s*/g, ""),
          });
          if (e.target.value.replace(/\s*/g, "").length == 18) {
            this.setState({
              date:
                e.target.value.replace(/\s*/g, "").substring(6, 10) +
                "-" +
                e.target.value.replace(/\s*/g, "").substring(10, 12) +
                "-" +
                e.target.value.replace(/\s*/g, "").substring(12, 14),

            });
          }
        } else {
          this.setState({
            idcard: "",
          });
        }
        break;
      //    手机号
      case "phone":
        if (e.target.value.replace(/\s*/g, "")) {
          if (e.target.value.replace(/\s*/g, "").length < 12) {
            this.setState({
              phone: e.target.value.replace(/\s*/g, ""),
            });
          }
        } else {
          this.setState({
            phone: "",
          });
        }
        break;
      //    既往史
      case "pasthistorycode0":
        this.setState({
          pasthistorycode: 0,
        });
        break;
      case "pasthistorycode1":
        this.setState({
          pasthistorycode: 1,
        });
        break;
      case "pasthistory":
        if (e.target.value.replace(/\s*/g, "")) {
          this.setState({
            pasthistory: e.target.value.replace(/\s*/g, ""),
          });
        } else {
          this.setState({
            pasthistory: "",
          });
        }
        break;
      //    过敏史
      case "allergichistorycode0":
        this.setState({
          allergichistorycode: 0,
        });
        break;
      case "allergichistorycode1":
        this.setState({
          allergichistorycode: 1,
        });
        break;
      case "allergichistory":
        if (e.target.value.replace(/\s*/g, "")) {
          this.setState({
            allergichistory: e.target.value.replace(/\s*/g, ""),
          });
        } else {
          this.setState({
            allergichistory: "",
          });
        }
        break;
      //    遗传史
      case "familyhistorycode0":
        this.setState({
          familyhistorycode: 0,
        });
        break;
      case "familyhistorycode1":
        this.setState({
          familyhistorycode: 1,
        });
        break;
      case "familyhistory":
        if (e.target.value.replace(/\s*/g, "")) {
          this.setState({
            familyhistory: e.target.value.replace(/\s*/g, ""),
          });
        } else {
          this.setState({
            familyhistory: "",
          });
        }
        break;
      //    肝功能
      case "liver1":
        this.setState({
          liver: 1,
        });
        break;
      case "liver0":
        this.setState({
          liver: "0",
        });
        break;
      //    肾功能
      case "kidney1":
        this.setState({
          kidney: 1,
        });
        break;
      case "kidney0":
        this.setState({
          kidney: "0",
        });
        break;
      case "date":
  
        let b = e.target.value.replaceAll("-", "");
        let a =
          new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1) +
          "-" +
          (new Date().getDate() < 10
            ? "0" + new Date().getDate()
            : new Date().getDate());

        let c = a.replaceAll("-", "");

        if (b > c) {
          this.setState({
            date: a,
            age: 0,
          });
          Toast.info("请选择正确的出生日期");
        } else {
          // 将出生日期的字符串通过"-"分割成数组
          const strBirthdayArr = e.target.value.split("-");
          // 拿到出生日期的年
          const birthYear = strBirthdayArr[0];
          // 拿到出生日期的月
          const birthMonth = strBirthdayArr[1];
          // 拿到出生日期的日
          const birthDay = strBirthdayArr[2];
          // 创建一个时间对象
          const d = new Date();
          // 拿到当前时间的年
          const nowYear = d.getFullYear();
          // 拿到当前时间的月
          const nowMonth = d.getMonth() + 1;
          // 拿到当前时间的日
          const nowDay = d.getDate();
          // 如果出生日期的年等于当前时间的年
          if (nowYear === birthYear) {
            this.setState({
              date: e.target.value,
              age: 0,
            });
            return 0; // 返回周岁年龄 0,并终止函数执行
          }
          // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
          const ageDiff = nowYear - birthYear; // 年之差
          // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
          // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
          // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
          if (nowMonth != birthMonth) {
            // 拿到出生日期的日与当前时间的月之差
            const monthDiff = nowMonth - birthMonth; // 月之差
            // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
            this.setState({
              date: e.target.value,
              age: monthDiff < 0 ? ageDiff - 1 : ageDiff, // 返回周岁年龄,并终止函数执行
            });
            return monthDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          }
          // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
          const dayDiff = nowDay - birthDay;
          // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
          this.setState({
            date: e.target.value,
            age: dayDiff < 0 ? ageDiff - 1 : ageDiff, // 返回周岁年龄,并终止函数执行
          });
          return dayDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
        }
        break;
      case "city":
        if (e.target.value) {
          this.setState({
            city: e.target.value,
            city0: 2,
          });
        } else {
          this.setState({
            city: e.target.value,
            city0: 1,
          });
        }
        break;
      case "userAge":
        const date = new Date()
        const year = date.getFullYear()
        const day = date.getDate()
        const month = date.getMonth()
        const userAge = e.target.value
        const patientBirthday = `${(year - userAge)}-${(month + 1)}-${day}`
   
        if(userAge>=0){
          this.setState({
            date: patientBirthday,
            userAge,
            relationship:((userAge <7 && userAge) || (userAge == '0')) ? '子女' : ''
          })
        }
        break;
      // 体重
      case "bodyweight":
        // 去除空格
        let bodyweightpnum = e.target.value.replace(/\s*/g, "");
        // 只能输入数字和小数点
        const bodyweightpnumSaniti = bodyweightpnum.replace(/[^0-9.]/g, '')
        if (bodyweightpnum.replace(/\s*/g, "")) {
          this.setState({
            bodyweight: bodyweightpnumSaniti,
          });
        } else {
          this.setState({
            bodyweight: "",
          });
        }
        break;
      // 监护人姓名
      case "guardianname":
        if (e.target.value.replace(/\s*/g, "")) {
          this.setState({
            guardianname: e.target.value.replace(/\s*/g, ""),
          });
        } else {
          this.setState({
            guardianname: "",
          });
        }
        break;
      // 监护人身份证
      case "guardianIdcardNum":
        // 去除空格
        let inputValue = e.target.value.replace(/\s*/g, "");
        // 身份证号码正则表达式，只允许数字和 X/x
        const sanitizedValue = inputValue.replace(/[^0-9Xx]/g, '')
        if (inputValue.replace(/\s*/g, "")) {
          this.setState({
            guardianIdcardNum: sanitizedValue,
          });
        } else {
          this.setState({
            guardianIdcardNum: "",
          });
        }
        break;
      // 体温是否异常 
      case "bodytemperature0":
        this.setState({
          bodytemperature:1
        })
        break;
      case "bodytemperature1":
        this.setState({
          bodytemperature:0
        })
        break;
      // 体温摄氏度
      case "bodytemperaturenum":
        // 去除空格
        let bodytempnum = e.target.value.replace(/\s*/g, "");
        // 只能输入数字和小数点
        const bodytempnumSaniti = bodytempnum.replace(/[^0-9.]/g, '')
        if (bodytempnum.replace(/\s*/g, "")) {
          this.setState({
            bodytemperaturenum: bodytempnumSaniti,
          });
        } else {
          this.setState({
            bodytemperaturenum: "",
          });
        }
        break;
    }
  };  
  // 点击修改

  //点击新增
  uploadvalue = async () => {
    const {
      userAge,
      name,
      sex,
      date,
      docterId,
      guardianName,
      illmiaosu,
      coordinationcode,
      city,
      type,
      province,
      entitycode,
      county,
      cityId,
      pregnancyStatus,
      phone,
      docterName,
      idcard,
      pasthistory,
      allergichistory,
      familyhistory,
      liver,
      memberId,
      counties,
      kidney,
      patientId,
      pasthistorycode,
      allergichistorycode,
      familyhistorycode,
      relationship, //与患者的关系
      medType,
      isNewPatient,
      guardianIdcardNum,//监护人身份证
      guardianname,//监护人姓名
      bodyweight,//体重
      bodytemperature,//体温是否异常
      bodytemperaturenum,//体温摄氏度
    } = this.state;

    let id0 = "";
    counties.map((i) => {
      if (i.value == county) {
        id0 = i.id;
        this.setState({
          cityId: i.id,
        });
      }
    });
    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    let ncode = /^(([a-zA-Z+\.?\.?a-zA-Z+]{2,30}$)|([\u4e00-\u9fa5+\.?\u4e00-\u9fa5+]{2,30}$))/;
    var sexAndAge = {};
    let age0 = 0;
    let b = date.replaceAll("-", "");
    let a = new Date().getFullYear() + "-" + (new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1) + "-" +
      (new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate());
    // console.log(a);
    let c = a.replaceAll("-", "");

    if (b > c) {
      age0 = 0;
      this.setState({
        age: 0,
      });
      Toast.info("请输入正确的身份证号");
    } else {
      // 将出生日期的字符串通过"-"分割成数组
      const strBirthdayArr = date.split("-");
      // 拿到出生日期的年
      const birthYear = strBirthdayArr[0];
      // 拿到出生日期的月
      const birthMonth = strBirthdayArr[1];
      // 拿到出生日期的日
      const birthDay = strBirthdayArr[2];
      // 创建一个时间对象
      const d = new Date();
      // 拿到当前时间的年
      const nowYear = d.getFullYear();
      // 拿到当前时间的月
      const nowMonth = d.getMonth() + 1;
      // 拿到当前时间的日
      const nowDay = d.getDate();
      // 如果出生日期的年等于当前时间的年
      if (nowYear === birthYear) {
        age0 = 0;
        this.setState({
          age: 0,
        });
      }
      // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
      const ageDiff = nowYear - birthYear; // 年之差
      // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
      // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
      // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
      if (nowMonth != birthMonth) {
        // 拿到出生日期的日与当前时间的月之差
        const monthDiff = nowMonth - birthMonth; // 月之差
        // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
        age0 = monthDiff < 0 ? ageDiff - 1 : ageDiff;
        this.setState({
          age: monthDiff < 0 ? ageDiff - 1 : ageDiff, // 返回周岁年龄,并终止函数执行
        });
      }
      // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
      const dayDiff = nowDay - birthDay;
      age0 = dayDiff < 0 ? ageDiff - 1 : ageDiff;
      // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
      this.setState({
        age: dayDiff < 0 ? ageDiff - 1 : ageDiff, // 返回周岁年龄,并终止函数执行
      },
        () => {
          age0 = this.state.age;
        }
      );
    }

    let params = {
  
      patientId,
      patientName: name,
      patientSex: sex == 2 ? "女" : "男",
      patientBirthday: date,
      // patientCity: province + city + county,
      patientPhone: phone ? phone : null,
      pastHistory: pasthistorycode == 0 ? "0" : 1,
      pastHistoryNote: pasthistorycode == 0 ? "无" : pasthistory,
      allergicHistory: allergichistorycode == 0 ? "0" : 1,
      allergicHistoryNote: allergichistorycode == 0 ? "无" : allergichistory,
      familyGeneticHistory: familyhistorycode == 0 ? "0" : 1,
      familyGeneticHistoryNote: familyhistorycode == 0 ? "无" : familyhistory,
      abnormalLiverFunction: liver === 0 ? "0" : liver === 3 ? "" : 1,
      abnormalRenalFunction: kidney == 0 ? "0" : 1,
      patientDefault: 1,
      patientIdCard: idcard,
      pregnancyStatus: ((userAge <7 && userAge) || (userAge == '0')) ? 1 : pregnancyStatus,
      // guardianName:'',
      // guardianIdcardNum:'',
      relationship: relationship,
      cityId:cityId, //行政编码
      guardianIdcardNum,//监护人身份证
      guardianname,//监护人姓名
      bodyweight,//体重
      bodytemperature: bodytemperature == 0 ? '0' : 1,//体温是否异常
      bodytemperaturenum: bodytemperature == 1 ? bodytemperaturenum : '',//体温摄氏度
      userAge,
    };

    // console.log(
    //   '年龄:',userAge,
    //   '监护人身份证:',guardianIdcardNum,
    //   '监护人姓名:',guardianname,
    //   '体重:',bodyweight,
    //   '体温是否异常:',bodytemperature,
    //   '体温摄氏度:',bodytemperaturenum,
    //   '怀孕情况：',pregnancyStatus
    // );

    // console.log('params',params);
    // return 
       

    let aDate = new Date(params.patientBirthday) //用户的生日日期
    let bDate = new Date(a) //今天的日期
    // 如果用户的生日日期大于今天那么不能提交
    if(aDate > bDate){
      Toast.fail("身份证存在问题，请检查");
      return;
    }else if(userAge<0){
      Toast.fail("年龄存在问题，请检查");
      return;
    }else if(userAge==0){
      Toast.fail("1岁以下儿童暂未开通线上就诊");
      return;
    }else if(userAge<14 && !params.patientIdCard){
      Toast.fail("根据相关规定儿童购药必须填写身份证信息");
      return;
    }


    if (!params.patientName) {
      Toast.fail("姓名不能为空");
      return;
    }else if(params.patientIdCard&&params.patientIdCard.length<18){
      Toast.fail("身份证填写不完整");
      return;
    }else if (!params.patientBirthday) {
      Toast.fail("年龄不能为空");
      return;
    }else if(userAge<7 && !params.patientPhone){
      Toast.fail("手机号不能为空");
      return;
    }else if(userAge<7 && !params.bodyweight){
      Toast.fail("体重不能为空");
      return;
    }else if(userAge<7 && params.bodyweight && params.bodyweight[0] == '0'){
      Toast.fail("体重存在问题，请检查");
      return;
    }else if(userAge<7 && !params.relationship){
      Toast.fail("关系不能为空");
      return;
    }else if(userAge<7 && !params.guardianname){
      Toast.fail("监护人姓名不能为空");
      return;
    }else if(userAge<7 && !params.guardianIdcardNum){
      Toast.fail("监护人身份证号不能为空");
      return;
    }else if (!params.pastHistoryNote) {
      Toast.fail("疾病史不能为空");
      return;
    } else if (!params.allergicHistoryNote) {
      Toast.fail("过敏史不能为空");
      return;
    } else if(!params.abnormalLiverFunction){
      Toast.fail("肝肾功能不能为空");
      return;
    }else if (!params.familyGeneticHistoryNote) {
      Toast.fail("家族遗传史不能为空");
      return;
    }else if(params.bodytemperature == 1 && !params.bodytemperaturenum){
      Toast.fail("体温异常，体温不能为空，请输入您目前的体温");
      return;
    }

    if (params.guardianIdcardNum) {
      if (!p.test(params.guardianIdcardNum)) {
        Toast.fail("请填写正确的监护人身份证");
        return;
      }
    }

    const res12 = await selectFiltering({pharmacyId:localStorage.getItem("pharmacyId")})

    if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
      this.setState({
        filteringArr:res12.data[0]
      })
    }
    // debugger
    if (this.state.filteringArr.some(item => item.label === "身份证号码")) {
      if (params.patientIdCard == "" || params.patientIdCard == null) {
        Toast.fail("请填写完整的身份证号");
        return;
      }
    }
    if (this.state.filteringArr.some(item => item.label === "手机号")) {
      if (!params.patientPhone) {
        Toast.fail("请填写手机号");
        return;
      }
    }

    params.patientProvince = province;
    params.patientCities = city;
    params.patientCounty = county;


    if (phone) {
      if (!reg.test(phone)) {
        Toast.fail("请填写正确的手机号");
        return;
      }
    }

    // if (reg.test(phone)) {
    if (ncode.test(name)) {
      if (p.test(idcard) || !idcard) {
        if (userAge < 0) {
          Toast.show("0岁以下儿童暂时无法添加");
          return;
        } else if(userAge >120){
          Toast.show("年龄限制0-120岁");
          return;
        } else {
          if (idcard) {
            //获取用户身份证号码
            var userCard = idcard;
            //如果身份证号码为undefind则返回空
            if (!userCard) {
              return sexAndAge;
            }
            //获取到性别
            if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
              sexAndAge.sex = "男";
            } else {
              sexAndAge.sex = "女";
            }
            //获取出生年月日
            //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
            var yearBirth = userCard.substring(6, 10);
            var monthBirth = userCard.substring(10, 12);
            var dayBirth = userCard.substring(12, 14);
            //获取当前年月日并计算年龄
            var myDate = new Date();
            var monthNow = myDate.getMonth() + 1;
            var dayNow = myDate.getDay();
            var yearNow = myDate.getFullYear();
            var age = yearBirth + "-" + monthBirth + "-" + dayBirth;
            if (
              yearNow < yearBirth ||
              (yearNow == yearBirth && monthNow < monthBirth) ||
              (yearNow == yearBirth &&
                monthNow == monthBirth &&
                dayNow < dayBirth)
            ) {
              a = -1;
            } else {
              sexAndAge.age = age;
              a = 1;
            }
            if (
              sexAndAge &&
              sexAndAge.age &&
              sexAndAge.sex == (sex == 1 ? "男" : "女")
            ) {
              // type:表示1：用药人列表 2：新增 3：修改
              if (type == 2) {

                // 用户端添加操作日志
                const resLogs = await addUsersOpersLogs({
                  pharmacyId:localStorage.getItem("pharmacyId"),
                  pharmacyName:localStorage.getItem("pharmacyName"),
                  usersOpersRecord:`新增患者信息-${params.patientName}`

                })
                const res = await adduserinfo(params);

                if (res.code == 1) {
                  this.props.history.replace("/drugselection");
                  if(isNewPatient == 1){
                    this.props.history.replace("/drugselection");
                  }else{
                    if (docterId) {
                      if (medType == 2) {
                        this.props.history.push(
                          `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterName=${docterName}&docterId=${docterId}`
                        );
                      } else {
                        this.props.history.push(
                          `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
                        );
                      }
                    } else {
                      if (medType == 2) {
                        this.props.history.push(
                          `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
                        );
                      } else {
                        this.props.history.push(
                          `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}`
                        );
                      }
                    }
                  }
                  Toast.success("保存成功");
                }
              } else {
                // 用户端添加操作日志
                const resLogs = await addUsersOpersLogs({
                  pharmacyId:localStorage.getItem("pharmacyId"),
                  pharmacyName:localStorage.getItem("pharmacyName"),
                  usersOpersRecord:`修改患者信息-${params.patientName}`

                })

                params.memberId = memberId;
                const res = await changeuserinfo(params);

                if (res.code == 1) {
                  this.props.history.replace("/drugselection");
                  if(isNewPatient==1){
                    this.props.history.replace("/drugselection");
                  }else{
                    if (docterId) {
                      if (medType == 2) {
                        this.props.history.push(
                          `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterName=${docterName}&docterId=${docterId}`
                        );
                      } else {
                        this.props.history.push(
                          `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
                        );
                      }
  
                    } else {
                      if (medType == 2) {
                        this.props.history.push(
                          `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
                        );
                      } else {
                        this.props.history.push(
                          `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}`
                        );
                      }
  
                    }
                  }
                  Toast.success("修改成功");
                }
              }
            } else {
              Toast.show("证件号和性别不一致");
            }
          } else {
            if (type == 2) {
              // 用户端添加操作日志
              const resLogs = await addUsersOpersLogs({
                pharmacyId:localStorage.getItem("pharmacyId"),
                pharmacyName:localStorage.getItem("pharmacyName"),
                usersOpersRecord:`新增患者信息-${params.patientName}`

              })
              const res = await adduserinfo(params);
   
              if (res.code == 1) {
                this.props.history.replace("/drugselection");
                if(isNewPatient == 1){
                  this.props.history.replace("/drugselection");
                }else{
                  if (docterId) {
                    if (medType == 2) {
                      this.props.history.push(
                        `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterName=${docterName}&docterId=${docterId}`
                      );
                    } else {
                      this.props.history.push(
                        `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
                      );
                    }
                  } else {
                    if (medType == 2) {
                      this.props.history.push(
                        `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
                      );
                    } else {
                      this.props.history.push(
                        `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}`
                      );
                    }
  
                  }
                }
                Toast.success("保存成功");
              }
            } else {
              const resLogs = await addUsersOpersLogs({
                pharmacyId:localStorage.getItem("pharmacyId"),
                pharmacyName:localStorage.getItem("pharmacyName"),
                usersOpersRecord:`修改患者信息-${params.patientName}`

              })
              params.memberId = memberId;
              const res = await changeuserinfo(params);

              if (res.code == 1) {
                this.props.history.replace("/drugselection");
                if(isNewPatient == 1){
                  this.props.history.replace("/drugselection");
                }else{
                  if (docterId) {
                    if (medType == 2) {
                      this.props.history.push(
                        `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterName=${docterName}&docterId=${docterId}`
                      );
                    } else {
                      this.props.history.push(
                        `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
                      );
                    }
  
                  } else {
                    if (medType == 2) {
                      this.props.history.push(
                        `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
                      );
                    } else {
                      this.props.history.push(
                        `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}`
                      );
                    }
  
                  }
                }
                Toast.success("修改成功");
              }
            }
          }
        }
      } else {
        Toast.show("请输入正确的身份证号");
      }
    } else {
      Toast.show("请输入正确的姓名");
    }
    // } 
    // else {
    //   Toast.fail("请填写正确的手机号");
    // }



  };
  gobank = () => {
    window.history.back();
  };
  // 删除
  delOrder = (e, memberId) => {
    e.stopPropagation();
    this.setState({
      visible2: true,
      memberId,
    });
  };
  //
  updateDefaultuser = async (memberId, patientIdCard) => {
    const {
      patientId,
      illmiaosu,
      coordinationcode,
      entitycode,
      docterId,
      docterName,
      medType,
    } = this.state;
    // console.log(patientIdCard,memberId,patientId,'-----');
    // return 
    // switchDefaultPatient
    
    const res = await changeuserinfo({
      patientIdCard: patientIdCard,
      memberId: memberId,
      patientDefault: 1,
      patientId,
      type:1,
    });

    
    if (res.code == 1) {
      this.props.history.replace("/drugselection");
      if (docterId) {
        if (medType == 2) {
          this.props.history.push(
            `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterName=${docterName}&docterId=${docterId}`
          );
        } else {
          this.props.history.push(
            `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
          );
        }
      } else {
        if (medType == 2) {
          this.props.history.push(
            `/patientinformationzhong?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
          );
        } else {
          this.props.history.push(
            `/patientinformation?illmiaosu=${illmiaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}`
          );
        }
      }
    }
  };
  toDel = () => {
    const { memberId } = this.state;
    delPatientMember({ memberId }).then((res) => {
      if (res.code == 1) {
        Toast.info("删除成功");
        this.setState({
          visible2: false,
        });
        this.getuserlist();
      }
    });
  };
  //修改
  toUpdate = async (e, item) => {
    const { alladdress, counties, phone,province,city,cityId,county } = this.state;
    e.stopPropagation();

    // console.log('修改：',e,item);

    let birthday = item.patient_birthday
    // 计算用户年龄
    let age
    let receiveYear = birthday.substring(0, 4)
    if (receiveYear != null) {
      let currentYear = new Date().getFullYear()
      let receiveYear = birthday.substring(0, 4)
      age = currentYear - receiveYear
      // userAgeRe = age
      // this.setvalue(null, "idcard", age)
    }

    this.setState({
      isDisabled: item.patient_id_card ? true : false,
      userAge: age,
      type: 3,
      name: item.patient_name,
      sex: item.patient_sex == "男" ? 1 : 2, //1男 2女
      date:new Date(item.patient_birthday).getFullYear() + "-" + (new Date(item.patient_birthday).getMonth() + 1 > 9 ? new Date(item.patient_birthday).getMonth() + 1
          : "0" + (new Date(item.patient_birthday).getMonth() + 1)) + "-" + (new Date(item.patient_birthday).getDate() > 9
          ? new Date(item.patient_birthday).getDate() : "0" + new Date(item.patient_birthday).getDate()),
      // idcard: item.patientIdCard,
      idcard: item.patient_id_card,
      address: item.patient_city,
      city: city,
      // phone: item.patientPhone,
      phone: item.patient_phone,
      pasthistory: item.past_history ? item.past_history_note : "", //既往史
      pasthistorycode: item.past_history, //既往史 0无 1有
      allergichistory: item.allergic_history ? item.allergic_history_note : "", //过敏史
      allergichistorycode: item.allergic_history, //过敏史 0无 1有
      familyhistory: item.family_genetic_history
        ? item.family_genetic_history_note
        : "", //遗传史
      familyhistorycode: item.family_genetic_history, //遗传史 0无 1有
      liver: item.abnormal_liver_function, //肝功能 0正常 1异常
      kidney: item.abnormal_renal_function, //肾功能
      province: province,
      county: county,
      memberId: item.member_id,
      cityId: cityId ,
      pregnancyStatus: item.pregnancyStatus,
      relationship: item.relationship, //与患者关系
      bodyweight:item.bodyweight,
      bodytemperature:item.bodytemperature ? item.bodytemperature : 0,
      bodytemperaturenum:item.bodytemperaturenum,
      guardianname:item.guardianName,
      guardianIdcardNum:item.guardianIdcardNum
    });
  };
  setValue = (e) => {
    this.setState({
      username: e.target.value.replace(
        /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,
        ""
      ),
    });
  };
  //搜索
  toSearch = () => {
    var s = document.getElementById("iosinput").value;
    if (s === "") {
      return;
    }
    document.getElementById("iosinput").blur();
    this.getuserlist(2);
  };
  // 选择出生日期
  seleceDate = () => {
    const { idcard } = this.state;
    if (idcard) {
    } else {
      this.setState({
        visible: true,
      });
    }
  };

  // 与患者关系
  handleRelationshipChange = (value) => {
    // console.log(value, "value1041===");
    this.setState({ relationship: value });
  };

  handlePickerVisibleChange = (visible) => {
    this.setState({ pickerVisible: visible });
  };

  handleConfirm = (value) => {
    const relationship = value[0];
    this.setState({ relationship }, () => {
      this.handlePickerVisibleChange(false);
    });
  };

  render() {
    const {
      userlist,
      patientId,
      name,
      idcard,
      visible,
      visible2,
      visible5,
      date,
      list,
      sex,
      username,
      city,
      phone,
      alladdress,
      pasthistory,
      allergichistory,
      allergichistory0,
      pregnancyStatus,
      familyhistory,
      familyhistory0,
      visible3,
      visible4,
      idcard1,
      liver,
      kidney,
      pasthistorycode,
      allergichistorycode,
      familyhistorycode,
      province,
      phone1,
      county,
      address,
      selectmeds,
      cityId,
      type,
      relationship, //与患者关系
      pickerVisible,
      userAge,
      filteringArr,
      bodytemperature,
      bodytemperaturenum,
      guardianname,
      guardianIdcardNum,
      bodyweight
    } = this.state;

    //与患者关系
    const relationshipOptions = [
      { label: "本人", value: "本人" },
      { label: "子女", value: "子女" },
      { label: "父母", value: "父母" },
      { label: "配偶", value: "配偶" },
      { label: "朋友", value: "朋友" },
      { label: "其他", value: "其他" },
    ];

    const now = new Date();
    const now1 = new Date("1900-01-01");
    let c = 1;
    let params = {

      patientId,
      patientName: name,
      patientSex: sex == 2 ? "女" : "男",
      patientBirthday: date,
      // patientCity: province + city + county,
      patientPhone: phone,
      pastHistory: pasthistorycode == 0 ? 0 : 1,
      pastHistoryNote: pasthistorycode == 0 ? "无" : pasthistory,
      allergicHistory: allergichistorycode == 0 ? 0 : 1,
      allergicHistoryNote: allergichistorycode == 0 ? "无" : allergichistory,
      familyGeneticHistory: familyhistorycode == 0 ? 0 : 1,
      familyGeneticHistoryNote: familyhistorycode == 0 ? "无" : familyhistory,
      abnormalLiverFunction: liver == 0 ? "0" : 1,
      abnormalRenalFunction: kidney == 0 ? "0" : 1,
      patientDefault: 1,
      patientIdCard: idcard,
      // guardianName:'',
      // guardianIdcardNum:'',
      patientProvince: province,
      patientCities: city,
      patientCounty: county,
      cityId,
      relationship: relationship, //与患者关系
    };
    for (let item in params) {
      if (params[item] === "") {
        c = 0;
      }
    }

    return (
      <>
        {type == 1 ? (
          <div className="newpatient-outer1">
            <div className='box-top12'>
              <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
              <span className='title-middle'>患者列表</span>
            </div>
            <div className="home-header-middle2">
              <div className="search-container">
                <div className="flexr">
                  <img src={search} />
                  <form
                    target="#"
                    id="search_from"
                    action="javascript:return true"
                    target="frameFile"
                  >
                    <input
                      id="iosinput"
                      value={username}
                      style={{ lineHeight: "20px" }}
                      onChange={(e) => this.setValue(e)}
                      placeholder="搜索用户名称"
                    />
                    <iframe
                      id="frameFile"
                      name="frameFile"
                      style={{ display: "none" }}
                    ></iframe>
                  </form>
                  {username ? (
                    <img
                      className="false1"
                      src={icon2}
                      onClick={() =>
                        this.setState({ username: "" }, () => {
                          this.getuserlist(2);
                        })
                      }
                    />
                  ) : null}

                  <span className="search-word" onClick={() => this.toSearch()}>
                    搜索
                  </span>
                </div>
              </div>
            </div>
            {userlist.length ? (
              userlist.map((item) => (
                <p
                  className="patientinformation-user1"
                  onClick={() =>
                    this.updateDefaultuser(item.member_id, item.patient_id_card)
                  }
                >
                  {/* 与患者关系 */}
                  <p className="news-user-top-right">
                    {item.relationship === null && (
                      <span
                        style={{
                          display: "inline-block",
                          width: "8px",
                          height: "14px",
                          // overflow: "hidden",
                        }}
                      >
                        &nbsp;
                      </span>
                    )}
                    {item.relationship !== null && (
                      <span
                        style={{
                          backgroundColor: "#FFEED6",
                          padding: "2px 8px",
                          color: "#FFA625",
                          fontWeight: "500",
                          // fontSize: "12px",
                          borderRadius: "0 10px 0 10px",
                        }}
                      >
                        {item.relationship}
                      </span>
                    )}
                  </p>
                  <p className="news-user-top">
                    <span className="news-user-left">
                      <img src={touxiang} />
                      <span>{item.patient_name}</span>
                    </span>
                    <span className="news-user-right">
                      <span>{item.patient_sex}</span>
                      <span>{item.age}岁</span>
                      {item.patientIdCard ? (
                        <span>{item.patientIdCard}</span>
                      ) : (
                        <span style={{ backgroundColor: "#fff" }}></span>
                      )}
                    </span>
                  </p>
                  <p className="news-user-two">
                    <p>
                      疾病史：
                      {item.past_history_note != "无" ? (
                        <span className="news-user-blue">
                          {item.past_history_note}
                        </span>
                      ) : (
                        <span>{item.past_history_note}</span>
                      )}
                    </p>
                    <p>
                      过敏史：
                      {item.allergic_history_note != "无" ? (
                        <span className="news-user-blue">
                          {item.allergic_history_note}
                        </span>
                      ) : (
                        <span>{item.allergic_history_note}</span>
                      )}
                    </p>
                  </p>
                  <p className="news-user-two">
                    <p>
                      肝肾功能：
                      {item.abnormal_liver_function == 0 ? (
                        "无异常"
                      ) : (
                        <span className="news-user-blue">异常</span>
                      )}
                    </p>
                    <p>
                      {item.patient_sex == "女" ? (
                        <p>
                          怀孕情况：
                          {item.pregnancyStatus == 1
                            ? "未怀孕"
                            : item.pregnancyStatus == 2
                              ? "备孕中"
                              : item.pregnancyStatus == 3
                                ? "已怀孕"
                                : "哺乳期"}
                        </p>
                      ) : null}
                    </p>
                  </p>
                  <p className="news-button">
                    <p
                      className="news-button-left"
                      onClick={(e) => this.delOrder(e, item.member_id)}
                    >
                      <img src={del} />
                      <span>删除</span>
                    </p>
                    <p
                      className="news-button-right"
                      onClick={(e) => this.toUpdate(e, item)}
                    >
                      <img src={update} />
                      <span>修改</span>
                    </p>
                  </p>
                </p>
              ))
            ) : (
              <Nodata type="用药人" />
            )}
            <div className="ill-box-btn1">
              <p className="blue" onClick={() => this.setState({ type: 2 })}>
                新增患者
              </p>
            </div>
            {visible2 ? (
              <div className="tip-pop-outer">
                <div className="tip-pop-inner">
                  <p className="tip-pop-one">提示弹窗</p>
                  <p className="tip-pop-sure">确定删除该就诊人信息吗？</p>
                  <p className="tip-pop-button">
                    <span
                      onClick={() =>
                        this.setState({
                          visible2: false,
                        })
                      }
                    >
                      取消
                    </span>
                    <span onClick={() => this.toDel()}>确定</span>
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : type == 2 ? (
          <div className="newpatient-outer">
            <div className='box-top12'>
              <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
              <span className='title-middle'>患者信息</span>
            </div>
            <div className="newpatient-top">
              提示：7岁以下儿童需要填写监护人信息。
            </div>
            <p className="med-lists-title" style={{ marginTop: 0 }}>
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">基本信息</span>
              </p>
              <p className="med-lists-title-right">基本信息为必填项</p>
            </p>
            <div className="newpatient-information">
              {/* 姓名 */}
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  姓名
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请输入姓名"
                    defaultValue={list ? list.patient_name : ""}
                    onChange={(e) => this.setvalue(e, "name")}
                  />
                </div>
              </div>
              {/* 性别 */}
              <p className="xian"></p>
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  性别
                </div>
                <div className="sex0">
                  <span className="sex1">
                    <input
                      type="radio"
                      name="sex"
                      value="男"
                      checked={sex == 1}
                      onChange={(e) => this.setvalue(e, "sex1")}
                    />
                    <span>男</span>
                  </span>
                  <span className="sex1">
                    <input
                      type="radio"
                      name="sex"
                      value="女"
                      checked={sex == 2}
                      onChange={(e) => this.setvalue(e, "sex2")}
                    />
                    <span>女</span>
                  </span>
                </div>
              </div>
              {/* 身份证号 */}
              <p className="xian"></p>
              <div className="information-item">
                {filteringArr.some(item => item.label === "身份证号码")? <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span> : ''}
                <div>身份证号</div>
                <div>
                  <input
                    type="text"
                    placeholder="请输入身份证号"
                    maxLength={18}
                    onChange={(e) => this.setvalue(e, "idcard")}
                  />
                </div>
              </div>
              {/* 年龄 */}
              <p className="xian"></p>
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  年龄
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请输入年龄"
                    value={userAge}
                    maxLength={3}
                    disabled={this.state.isDisabled}
                    onChange={(e) => this.setvalue(e, "userAge")}
                  />
                </div>
              </div>
              <DatePicker
                title="时间选择"
                visible={visible}
                onClose={() => {
                  this.setState({
                    visible: false,
                  });
                }}
                defaultValue={now}
                max={now}
                min={now1}
                precision="day"
                onConfirm={(value) => {
                  this.setState({
                    date:
                      new Date(value).getFullYear() +
                      "-" +
                      (new Date(value).getMonth() + 1 > 9
                        ? new Date(value).getMonth() + 1
                        : "0" + (new Date(value).getMonth() + 1)) +
                      "-" +
                      (new Date(value).getDate() > 9
                        ? new Date(value).getDate()
                        : "0" + new Date(value).getDate()),
                  });
                }}
              />

              {/* 手机号码 */}
              <p className="xian"></p>
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  手机号码
                </div>
                <div>
                  <input
                    type="number"
                    oncut="return false"
                    placeholder="请输入号码"
                    maxLength={11}
                    value={phone}
                    onChange={(e) => this.setvalue(e, "phone")}
                  />
                </div>
              </div>
              
              {/* 体重 */}
              {((userAge <7 && userAge) || (userAge == '0')) ? 
                <>
                  <p className="xian"></p>
                  <div className="information-item">
                    <div>
                      <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                      体重(KG)
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="请输入体重"
                        maxLength='4'
                        // max='4'
                        value={bodyweight}
                        onChange={(e) => this.setvalue(e, "bodyweight")}
                      />
                    </div>
                  </div> 
                </> : null}

              {/* 与患者关系 */}
              <p className="xian"></p>
              <div className="information-item">
                <div style={{ width: "200px" }}>
                  {((userAge <7 && userAge) || (userAge == '0')) ? <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span> : null}
                  与您关系
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请选择关系>"
                    value={this.state.relationship}
                    onClick={() => this.handlePickerVisibleChange(true)}
                    readOnly
                  />
                </div>
                <Picker
                  title="与您关系"
                  data={relationshipOptions}
                  visible={pickerVisible}
                  value={[relationship]}
                  onOk={this.handleConfirm}
                  onDismiss={() => this.handlePickerVisibleChange(false)}
                  cols={1}
                />
              </div>
            </div>
            {/* 监护人信息 */}
            {((userAge <7 && userAge) || (userAge == '0')) ? (
              <>
                <p className="med-lists-title" style={{ marginTop: 0 }}>
                  <p className="med-lists-title-left">
                    <span className="dian"></span>
                    <span className="name">监护人信息</span>
                  </p>
                  <p className="med-lists-title-right"></p>
                </p>
                <p className="jhr-tips">为保障用药人安全，请登记监护人信息并确保在监护下用药</p>
                <div className="newpatient-information">
                  {/* 监护人姓名 */}
                  <div className="information-item">
                    <div style={{ whiteSpace:'nowrap'}}>
                      <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                      监护人姓名
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="请输入监护人姓名"
                        defaultValue={guardianname}
                        onChange={(e) => this.setvalue(e, "guardianname")}
                        maxLength='15'
                      />
                    </div>
                  </div>
                  <p className="xian"></p>
                  {/* 监护人身份证号 */}
                  <div className="information-item">
                    <div>
                      <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                      身份证号
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="请输入监护人身份证"
                        value={guardianIdcardNum}
                        onChange={(e) => this.setvalue(e, "guardianIdcardNum")}
                        maxLength={18}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {/* 怀孕情况 */}
            {sex == 1 || ((userAge <7 && userAge) || (userAge == '0')) ? null : (
              <>
                <p className="med-lists-title" style={{ marginTop: 0 }}>
                  <p className="med-lists-title-left">
                    <span className="dian"></span>
                    <span className="name">怀孕情况</span>
                  </p>
                  <p className="med-lists-title-right"></p>
                </p>
                <div className="newpatient-information">
                  <div className="information-item">
                    <div className="pregnancyStatus">
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="男未怀孕"
                          checked={pregnancyStatus == 1}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus1")}
                        />
                        <span>未怀孕</span>
                      </span>
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="备孕中"
                          checked={pregnancyStatus == 2}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus2")}
                        />
                        <span>备孕中</span>
                      </span>
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="已怀孕"
                          checked={pregnancyStatus == 3}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus3")}
                        />
                        <span>已怀孕</span>
                      </span>
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="哺乳期"
                          checked={pregnancyStatus == 4}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus4")}
                        />
                        <span>哺乳期</span>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* 病史信息 */}
            <p className="med-lists-title" style={{ marginTop: 0 }}>
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">病史信息</span>
              </p>
              <p className="med-lists-title-right"></p>
            </p>
            <div className="newpatient-information">
              {/* 有无疾病史 */}
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无疾病史？</p>
                  <p className="chose-have">
                    <span
                      className={pasthistorycode == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "pasthistorycode1")}
                    >
                      有
                    </span>
                    <span
                      className={pasthistorycode == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "pasthistorycode0")}
                    >
                      无
                    </span>
                  </p>
                </p>
                {pasthistorycode == 1 ? (
                  <p className="ill-textarea">
                    <textarea
                      placeholder="请输入疾病史"
                      maxLength={100}
                      onChange={(e) => this.setvalue(e, "pasthistory")}
                    ></textarea>
                  </p>
                ) : null}
              </div>
              {/* 有无过敏史 */}
              <p className="xian"></p>
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无过敏史？</p>
                  <p className="chose-have">
                    <span
                      className={allergichistorycode == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "allergichistorycode1")}
                    >
                      有
                    </span>
                    <span
                      className={allergichistorycode == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "allergichistorycode0")}
                    >
                      无
                    </span>
                  </p>
                </p>
                {allergichistorycode == 1 ? (
                  <p className="ill-textarea">
                    <textarea
                      placeholder="请输入过敏史"
                      maxLength={100}
                      onChange={(e) => this.setvalue(e, "allergichistory")}
                    ></textarea>
                  </p>
                ) : null}
              </div>
              {/* 有无肝肾功能异常 */}
              <p className="xian"></p>
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无肝肾功能异常？</p>
                  <p className="chose-have">
                    <span
                      className={liver == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "liver1")}
                    >
                      有
                    </span>
                    <span
                      className={liver == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "liver0")}
                    >
                      无
                    </span>
                  </p>
                </p>
              </div>
              {/* 有无体温异常 */}
              <p className="xian"></p>
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无体温异常？</p>
                  <p className="chose-have">
                    <span
                      className={bodytemperature == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "bodytemperature0")}
                    >
                      有
                    </span>
                    <span
                      className={bodytemperature == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "bodytemperature1")}
                    >
                      无
                    </span>
                  </p>
                </p>
                {bodytemperature == 1 ? (
                  <>
                    <div className="twbox">
                      <input
                        type="text"
                        placeholder="请输入体温"
                        maxLength='4'
                        // max="4"
                        onChange={(e) => this.setvalue(e, "bodytemperaturenum")}
                        style={{
                          border:'none',
                          background: '#EEEEEE',
                          width:'100%',
                          padding:'12px',
                          borderRadius: '8px',
                          fontSize:'14px'
                        }}
                        value={bodytemperaturenum}
                      />
                      <span className="danwei">℃</span>
                    </div>
                    
                    
                  </>
                ) : null}
              </div>

            </div>
            <div className="ill-box-btn">
              <p
                className={c == 1 ? "blue" : "blue1"}
                onClick={() => {
                  this.uploadvalue()
                }}
              >
                保存
              </p>
            </div>
          </div>
        ) : (
          <div className="newpatient-outer">
            <div className='box-top12'>
              <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
              <span className='title-middle'>患者信息</span>
            </div>
            <div className="newpatient-top">
              提示：7岁以下儿童需要填写监护人信息。
            </div>
            <p className="med-lists-title" style={{ marginTop: 0 }}>
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">基本信息</span>
              </p>
              <p className="med-lists-title-right">基本信息为必填项</p>
            </p>
            <div className="newpatient-information">
              {/* 姓名 */}
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  姓名
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请输入姓名"
                    defaultValue={name}
                    onChange={(e) => this.setvalue(e, "name")}
                  />
                </div>
              </div>

              {/* 性别 */}
              <p className="xian"></p>
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  性别
                </div>
                <div className="sex0">
                  <span className="sex1">
                    <input
                      type="radio"
                      name="sex"
                      value="男"
                      checked={sex == 1}
                      onChange={(e) => this.setvalue(e, "sex1")}
                    />
                    <span>男</span>
                  </span>
                  <span className="sex1">
                    <input
                      type="radio"
                      name="sex"
                      value="女"
                      checked={sex == 2}
                      onChange={(e) => this.setvalue(e, "sex2")}
                    />
                    <span>女</span>
                  </span>
                </div>
              </div>

              {/* 身份证号 */}
              <p className="xian"></p>
              <div className="information-item">
                {filteringArr.some(item => item.label === "身份证号码")? <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span> : ''}
                <div>身份证号</div>
                <div>
                  {/*<Popover style={{*/}
                  {/*    marginLeft:'50px'*/}
                  {/*}} content={idcard1} placement='bottom-start' mode='dark' trigger='click' visible={visible3}>*/}
                  <input
                    type="text"
                    placeholder="请输入身份证号"
                    maxLength={18}
                    value={idcard}
                    onChange={(e) => this.setvalue(e, "idcard")}
                  />
                  {/*</Popover>*/}
                </div>
              </div>

              {/* 年龄 */}
              <p className="xian"></p>
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  年龄
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请输入年龄"
                    value={userAge}
                    maxLength={3}
                    disabled={this.state.isDisabled}
                    onChange={(e) => { this.setvalue(e, "userAge") }}
                  />

                </div>
              </div>

              <DatePicker
                title="时间选择"
                visible={visible}
                onClose={() => {
                  this.setState({
                    visible: false,
                  });
                }}
                defaultValue={now}
                max={now}
                min={now1}
                precision="day"
                onConfirm={(value) => {
                  this.setState({
                    date:
                      new Date(value).getFullYear() +
                      "-" +
                      (new Date(value).getMonth() + 1 > 9
                        ? new Date(value).getMonth() + 1
                        : "0" + (new Date(value).getMonth() + 1)) +
                      "-" +
                      (new Date(value).getDate() > 9
                        ? new Date(value).getDate()
                        : "0" + new Date(value).getDate()),
                  });
                }}
              />

              {/* 手机号码 */}
              <p className="xian"></p>
              <div className="information-item">
                <div>
                  <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                  手机号码
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请输入号码"
                    onCut="return false"
                    maxLength={11}
                    value={phone}
                    onChange={(e) => this.setvalue(e, "phone")}
                  />
                  {/*</Popover>*/}
                </div>
              </div>

              {/* 体重 */}
              {((userAge <7 && userAge) || (userAge == '0')) ? 
                <>
                  <p className="xian"></p>
                  <div className="information-item">
                    <div>
                      <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                      体重(KG)
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="请输入体重"
                        maxLength='4'
                        // max='4'
                        value={bodyweight}
                        onChange={(e) => this.setvalue(e, "bodyweight")}
                      />
                    </div>
                  </div> 
                </> : null}

              {/* 与患者关系 */}
              <p className="xian"></p>
              <div className="information-item">
                <div style={{ width: "200px" }}>
                  {((userAge <7 && userAge) || (userAge == '0')) ? <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span> : null } 
                  与您关系
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="请选择关系>"
                    value={this.state.relationship}
                    onClick={() => this.handlePickerVisibleChange(true)}
                    readOnly
                  />
                </div>
                <Picker
                  title="与您关系"
                  data={relationshipOptions}
                  visible={pickerVisible}
                  value={[relationship]}
                  onOk={this.handleConfirm}
                  onDismiss={() => this.handlePickerVisibleChange(false)}
                  cols={1}
                />
              </div>

            </div>

            {/* 监护人信息 */}
            {((userAge <7 && userAge) || (userAge == '0')) ? (
              <>
                <p className="med-lists-title" style={{ marginTop: 0 }}>
                  <p className="med-lists-title-left">
                    <span className="dian"></span>
                    <span className="name">监护人信息</span>
                  </p>
                  <p className="med-lists-title-right"></p>
                </p>
                <p className="jhr-tips">为保障用药人安全，请登记监护人信息并确保在监护下用药</p>
                <div className="newpatient-information">
                  {/* 监护人姓名 */}
                  <div className="information-item">
                    <div style={{ whiteSpace:'nowrap'}}>
                      <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                      监护人姓名
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="请输入监护人姓名"
                        defaultValue={guardianname}
                        onChange={(e) => this.setvalue(e, "guardianname")}
                        maxLength='15'
                      />
                    </div>
                  </div>
                  <p className="xian"></p>
                  {/* 监护人身份证号 */}
                  <div className="information-item">
                    <div>
                      <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                      身份证号
                    </div>
                    <div>
                      <input
                        type="text"
                        placeholder="请输入监护人身份证"
                        value={guardianIdcardNum}
                        onChange={(e) => this.setvalue(e, "guardianIdcardNum")}
                        maxLength={18}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            {/* 怀孕情况 */}
            {sex == 1 || ((userAge <7 && userAge) || (userAge == '0')) ? null : (
              <>
                <p className="med-lists-title" style={{ marginTop: 0 }}>
                  <p className="med-lists-title-left">
                    <span className="dian"></span>
                    <span className="name">怀孕情况</span>
                  </p>
                  <p className="med-lists-title-right"></p>
                </p>
                <div className="newpatient-information">
                  <div className="information-item">
                    <div className="pregnancyStatus">
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="男未怀孕"
                          checked={pregnancyStatus == 1}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus1")}
                        />
                        <span>未怀孕</span>
                      </span>
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="备孕中"
                          checked={pregnancyStatus == 2}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus2")}
                        />
                        <span>备孕中</span>
                      </span>
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="已怀孕"
                          checked={pregnancyStatus == 3}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus3")}
                        />
                        <span>已怀孕</span>
                      </span>
                      <span className="pregnancyStatus1">
                        <input
                          type="radio"
                          name="pregnancyStatus"
                          value="哺乳期"
                          checked={pregnancyStatus == 4}
                          onChange={(e) => this.setvalue(e, "pregnancyStatus4")}
                        />
                        <span>哺乳期</span>
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* 病史信息 */}
            <p className="med-lists-title" style={{ marginTop: 0 }}>
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">病史信息</span>
              </p>
              <p className="med-lists-title-right"></p>
            </p>

            <div className="newpatient-information">
              {/* 有无疾病史 */}
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无疾病史？</p>
                  <p className="chose-have">
                    <span
                      className={pasthistorycode == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "pasthistorycode1")}
                    >
                      有
                    </span>
                    <span
                      className={pasthistorycode == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "pasthistorycode0")}
                    >
                      无
                    </span>
                  </p>
                </p>
                {pasthistorycode == 1 ? (
                  <p className="ill-textarea">
                    <textarea
                      placeholder="请输入疾病史"
                      maxLength={100}
                      defaultValue={pasthistory}
                      onChange={(e) => this.setvalue(e, "pasthistory")}
                    ></textarea>
                  </p>
                ) : null}
              </div>
              {/* 有无过敏史 */}
              <p className="xian"></p>
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无过敏史？</p>
                  <p className="chose-have">
                    <span
                      className={allergichistorycode == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "allergichistorycode1")}
                    >
                      有
                    </span>
                    <span
                      className={allergichistorycode == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "allergichistorycode0")}
                    >
                      无
                    </span>
                  </p>
                </p>
                {allergichistorycode == 1 ? (
                  <p className="ill-textarea">
                    <textarea
                      placeholder="请输入过敏史"
                      maxLength={100}
                      defaultValue={allergichistory}
                      onChange={(e) => this.setvalue(e, "allergichistory")}
                    ></textarea>
                  </p>
                ) : null}
              </div>
              {/* 有无肝肾功能异常 */}
              <p className="xian"></p>
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无肝肾功能异常？</p>
                  <p className="chose-have">
                    <span
                      className={liver == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "liver1")}
                    >
                      有
                    </span>
                    <span
                      className={liver == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "liver0")}
                    >
                      无
                    </span>
                  </p>
                </p>
              </div>

              {/* 有无体温异常 */}
              <p className="xian"></p>
              <div className="ill-news">
                <p className="ill-news-select">
                  <p>有无体温异常？</p>
                  <p className="chose-have">
                    <span
                      className={bodytemperature == 1 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "bodytemperature0")}
                    >
                      有
                    </span>
                    <span
                      className={bodytemperature == 0 ? "have" : "no"}
                      onClick={(e) => this.setvalue(e, "bodytemperature1")}
                    >
                      无
                    </span>
                  </p>
                </p>
                {bodytemperature == 1 ? (
                  <>
                    <div className="twbox">
                      <input
                        type="text"
                        placeholder="请输入体温"
                        maxLength='4'
                        // max='4'
                        onChange={(e) => this.setvalue(e, "bodytemperaturenum")}
                        style={{
                          border:'none',
                          background: '#EEEEEE',
                          width:'100%',
                          padding:'12px',
                          borderRadius: '8px',
                          fontSize:'14px'
                        }}
                        value={bodytemperaturenum}
                      />
                      <span className="danwei">℃</span>
                    </div>
                    
                    
                  </>
                ) : null}
              </div>
              
            </div>
            <div className="ill-box-btn">
              <p
                className={c == 1 ? "blue" : "blue1"}
                onClick={() => this.uploadvalue()}
              >
                保存
              </p>
            </div>
          </div>
        )}
      </>
    );
  }
}
