import React from 'react'

import './style.less'

import img1 from '../../static/img/nodata/1.png'
import xiaoxi from '../../static/img/nodata/xiaoxi.png'
import dingdan from '../../static/img/nodata/dingdan.png'
import xinxi from '../../static/img/nodata/xinxi.png'
import nomed from '../../static/img/nomed.png'

const Nodata = ({ type }) => (
  <>
    { (()=>{
      switch(type){
        case '收藏':return (
          <div className='no-data'>
            <img src={img1} alt=''/>
            <p>暂时没有收藏</p>
            
          </div>
          )
        case '网络':return <img src={img1} alt=''/>
        case '购物车':return (
          <div className='no-data'>
            <img src={img1} alt=''/>
            <p>您的购物车太干净了~</p>
            <div className='flex-row'>去逛逛</div>
          </div>
          )
        case '订单':return (
          <div className='no-data'
               style={{marginTop:'100px'}}>
            <img src={dingdan} alt=''/>
            <p>亲，还没问订单记录呢~</p>
          </div>
          )
        case '问诊':return (
            <div className='no-data'>
              <img src={dingdan} alt=''/>
              <p>亲，还没问诊记录呢~</p>
            </div>
        )
        case '疾病':return (
            <div className='no-data'>
              <img src={dingdan} alt=''/>
              <p>亲，没有对应疾病呢~</p>
            </div>
        )
        case '挂号':return (
          <div className='no-data'
               style={{marginTop:'100px'}}>
            <img src={xiaoxi} alt=''/>
            <p>亲，还没挂号记录呢~</p>
          </div>
          )
        case '挂号2':return (
          <div className='no-data'>
            <img src={xiaoxi} alt=''/>
            <p>亲，还没可挂号的医生呢~</p>
          </div>
          )
        case '信息':return (
          <div className='no-data'>
            <img src={xinxi} alt=''/>
            <p>抱歉，暂无相关信息~</p>
          </div>
          )
        case '消息':return (
            <div className='no-data'
                 >
              <img src={xinxi} alt=''/>
              <p>抱歉，暂无相关消息~</p>
            </div>
        )
        case '医生':return (
            <div className='no-data'
                 style={{marginTop:'100px'}}>
              <img src={xinxi} alt=''/>
              <p>抱歉，暂无相关医生信息~</p>
            </div>
        )
        case '处方':return (
            <div className='no-data'
                 style={{marginTop:'100px'}}>
              <img src={xinxi} alt=''/>
              <p>抱歉，暂无相关处方信息~</p>
            </div>
        )
        case '地址':return (
            <div className='no-data'
                 style={{marginTop:'100px'}}>
              <img src={xinxi} alt=''/>
              <p>抱歉，暂无相关地址信息~</p>
            </div>
        )
        case '就诊人':return (
            <div className='no-data'>
              <img src={xinxi} alt=''/>
              <p>抱歉，暂无就诊人信息~</p>
            </div>
        )
        case '报告':return (
            <div className='no-data'
            style={{marginTop:'100px'}}>
              <img src={xinxi} alt=''/>
              <p>抱歉，暂无报告信息~</p>
            </div>
        )
        case '评价':return (
            <div className='no-data'>
              <img src={xinxi} alt=''/>
              <p>抱歉，暂无评价~</p>
            </div>
        )
        case '药房搜索':return (
            <div className='no-data no-data1'
                 style={{marginTop:'-100px'}}>
              <img src={nomed} alt=''/>
              <p className='off-nomed'>未搜到该药品</p>
            </div>
        )
        case '用药人':return (
            <div className='no-data no-data1'
                 style={{marginTop:'50px'}}>
              <img src={nomed} alt=''/>
              <p className='off-nomed'>无用药人信息</p>
            </div>
        )
        case '单量统计':return (
            <div className='no-data'
                 style={{marginTop:'-50px'}}>
              <img src={nomed} alt=''/>
              <p className='off-nomed'>暂无处方信息</p>
            </div>
        )
        default:return null
      }
    }
    )()}
  </>
)

export default Nodata