import React, { Component } from 'react'
import "./style.less"
import { Popup, Input, Button, DatePicker,Divider,FloatingBubble } from "antd-mobile-v5";
import { MessageFill } from 'antd-mobile-icons';
import icon1 from "../../static/img/store/success-cf.png"
import icon2 from "../../static/img/store/add-meds.png"
import icon3 from "../../static/img/store/setqm.png"
import fhhome from "../../static/img/icon/fhhome.png"
import updown from "../../static/img/icon/updown.png"
import {Toast, Modal, Checkbox} from 'antd-mobile'
import getUrl from '../../component/GetUrlParam'
import { wxConfig } from '../../component/scan/index'
import {defaultMember} from '../../api/hospitalapi/meds'
import {addAllmeds} from '../../api/hospitalapi/order'
import {newState,getnewslist} from '../../api/hospitalapi/chat'
import {allOfflinedoc,nowState,
    dayAllorder,storenewOrder,
    isChineseMeds,chineseMethod,
    dayRejectorder,selectFiltering,
    chineseMethodDoc,
    phaCountNum,
    phaRefuseCountNum,
    shopAllOrders
} from '../../api/hospitalapi/offline'
import moment from 'moment'
import '../../static/fonts/font_3834206_6nqwrwayg0q/iconfont.css'
import Nodata from "../../component/Nodata";
const CheckboxItem = Checkbox.CheckboxItem
const alert = Modal.alert
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientId: localStorage.getItem("patientId1"),
            pharmacyId: localStorage.getItem("pharmacyId"),
            patientPhone: localStorage.getItem("patientPhone1"),
            consultationId: localStorage.getItem("consultationId"),
            visible1: false,
            visible2: false,
            newsstate: 1,
            newslist: [],
            newslist1: [], //已接问诊
            newslist2: [], //驳回处方
            page: 1,
            size: 20,
            createTime: '',
            dateList: '',
            visible6: false,
            selcetcf : 1,   //1：全部处方，2：西药处方，3：中药材处方
            visible7: false,
            selcetcfbh: 1,   //1：全部处方，2：西药处方，3：中药材处方
            createTime2:'',
            createTimes: '',
            offset: { x: -24, y: -24 },
            isPhaExamine: false, //是否需要药店药师审核
            filteringArr:[],
            currentDate:'', //今天的日期
            choseDateList:[],//筛选的日期数组
            alCountNum:[],//已接问诊总数
            refuseCountNum:[],//驳回处方总数
        }
    }

    componentDidMount = async () => {
        const {patientId, pharmacyId} = this.state

        const currentNow = new Date()
        const year = currentNow.getFullYear().toString();
        const month = (currentNow.getMonth() + 1).toString().padStart(2, '0');
        const date = currentNow.getDate().toString().padStart(2, '0');
        const currentDate = `${year}-${month}-${date}`;
        this.setState({
            currentDate:currentDate
        })

        // 查询店铺权限
        const res12 = await selectFiltering({pharmacyId:pharmacyId})
        if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
            this.setState({
                filteringArr:res12.data[0]
            })
        }
        if(this.state.filteringArr.some(item => item.label === '药店药师审核' && item.label_type == 4)){
            this.setState({
            isPhaExamine: true
            })
        }

        if (getUrl("medType", this.props.location.search)) {
            this.setState({
              medType: getUrl(
                "medType",
                this.props.location.search
              ),
            });
          }

        if (getUrl('newsstate', this.props.location.search)) {
            this.setState({
                newsstate: getUrl('newsstate', this.props.location.search)
            })
        }
        this.dayAllorders()
    }


    dayAllorders = async () => {
        let {
          pharmacyId,
          size,
          createTime,
          createTimes,
          selcetcf,
          selcetcfbh,
          currentDate,
          choseDateList,
          newsstate,
          alCountNum,
          refuseCountNum,
          isPhaExamine,
          
        } = this.state; 

        // 将 createTime 和 createTimes 转换为 Date 对象
        let choseStartDate = new Date(createTime); //开始日期
        let choseEndDate = new Date(createTimes); //结束日期

        let startTimes = createTime.replace(/\-/g, "");
        let endTimes = createTimes.replace(/\-/g, "");   
        
        if (createTime && createTimes) {

            // 计算日期差
            let timeDifference = choseEndDate - choseStartDate; // 结果是毫秒数
            // 将时间差转换为天数
            let daysDifference = timeDifference / (1000 * 60 * 60 * 24); // 1天=1000毫秒*60秒*60分钟*24小时
            
            if(daysDifference > 30){
                Toast.info("只支持查询30天，请重新选择查询的起始时间");
                this.setState({
                    createTime: "",
                    createTimes: "",
                });
                return;
            }
            if (startTimes > endTimes) {
                Toast.info("起始日期必须小于等于结束日期");
                this.setState({
                    createTime: "",
                    createTimes: "",
                });
                return;
            }
        }

        let datalist=[]
        if(startTimes && endTimes){          
            
            // 循环生成日期并添加到 choseDateList 数组中
            for (let a = choseStartDate; a <= choseEndDate; a.setDate(a.getDate() + 1)) {
                // datalist.unshift({choseTime:a.toISOString().slice(0, 10)});
                datalist.unshift({
                    choseTime:moment(a).format("YYYY-MM-DD")
                })
                this.setState({
                    choseDateList:datalist
                })
            } 

            let obj=[];

            if(datalist && newsstate!=1){
                datalist.map((item)=>{
                    obj.push({
                        choseTime:item.choseTime,
                        selcetcf:selcetcf,
                        selcetcfbh:selcetcfbh,
                        pharmacyId:pharmacyId
                    })
                })

                if(newsstate == 2){
                    let rescount= await phaCountNum({
                        array:obj&& obj.length > 0 ? obj : null
                    })
    
                    if(rescount.data){
                        let b = rescount.data.flatMap(innerArray => innerArray.map(item => item));  
                        b = b
                        .filter(item => item.create_time !== null) // 过滤掉 create_time 为 null 的项
                        .map(item => ({
                            ...item,
                            create_time: new Date(item.create_time) // 转换 create_time 为 Date 对象
                        }))
                        .sort((a, b) => b.create_time - a.create_time); // 从大到小排序
    
                        b = b.map(item => ({
                            ...item,
                            create_time: moment(item.create_time).format("YYYY-MM-DD") // 格式化为 YYYY-MM-DD
                        }));
                        
    
    
                        this.setState({
                            alCountNum:b,
                        })
                    }
                }else if(newsstate == 3){
                    let rescount1= await phaRefuseCountNum({
                        array:obj&& obj.length > 0 ? obj : null
                    })
    
                    if(rescount1.data){
                        let b = rescount1.data.flatMap(innerArray => innerArray.map(item => item));   
                        b = b
                        .filter(item => item.create_time !== null) // 过滤掉 create_time 为 null 的项
                        .map(item => ({
                            ...item,
                            create_time: new Date(item.create_time) // 转换 create_time 为 Date 对象
                        }))
                        .sort((a, b) => b.create_time - a.create_time); // 从大到小排序
    
                        b = b.map(item => ({
                            ...item,
                            create_time: moment(item.create_time).format("YYYY-MM-DD") // 格式化为 YYYY-MM-DD
                        })); 
                        this.setState({
                            refuseCountNum:b
                        })
                    }
                }
                
                
            }
            
        }else{ 
            let datalist=[]        
            datalist.push({choseTime:currentDate})
            this.setState({
                choseDateList:datalist
            })
            let obj=[];

            if(datalist && newsstate!=1){
                datalist.map((item)=>{
                    obj.push({
                        choseTime:item.choseTime,
                        selcetcf:selcetcf,
                        selcetcfbh:selcetcfbh,
                        pharmacyId:pharmacyId
                    })
                })

                if(newsstate==2){
                    let rescount= await phaCountNum({
                        array:obj&& obj.length > 0 ? obj : null
                    })
    
                    if(rescount.data){
                        let b = rescount.data.flatMap(innerArray => innerArray.map(item => item));  
                        b = b
                        .filter(item => item.create_time !== null) // 过滤掉 create_time 为 null 的项
                        .map(item => ({
                            ...item,
                            create_time: new Date(item.create_time) // 转换 create_time 为 Date 对象
                        }))
                        .sort((a, b) => b.create_time - a.create_time); // 从大到小排序
    
                        b = b.map(item => ({
                            ...item,
                            create_time: moment(item.create_time).format("YYYY-MM-DD") // 格式化为 YYYY-MM-DD
                        }));
                        
    
    
                        this.setState({
                            alCountNum:b,
                        })
                    }
                }else if(newsstate == 3){                    
                    let rescount1= await phaRefuseCountNum({
                        array:obj&& obj.length > 0 ? obj : null
                    })
    
                    if(rescount1.data){
                        let b = rescount1.data.flatMap(innerArray => innerArray.map(item => item)); 
                        b = b
                        .filter(item => item.create_time !== null) // 过滤掉 create_time 为 null 的项
                        .map(item => ({
                            ...item,
                            create_time: new Date(item.create_time) // 转换 create_time 为 Date 对象
                        }))
                        .sort((a, b) => b.create_time - a.create_time); // 从大到小排序
    
                        b = b.map(item => ({
                            ...item,
                            create_time: moment(item.create_time).format("YYYY-MM-DD") // 格式化为 YYYY-MM-DD
                        }));

                        this.setState({
                            refuseCountNum:b
                        })
                    }
                }

            }
        }

        let res = await shopAllOrders({
            token:localStorage.getItem('jwtTokenh5'), 
            pharmacyId, 
            page:1, 
            size,
            createTime, //开始时间
            createTimes, //结束时间
            selcetcf, //已接查询状态
            selcetcfbh, //驳回查询状态
            // dateString: newsstate != 1 ? currentDate : '', //当天日期
            dateString:(!createTime && !createTimes) && newsstate != 1 ? currentDate : '',
            newsstate //tabs
        })

        if(res.code == 1 && res.data){
            if(newsstate==1){
                this.setState({
                    newslist:res.data,
                    flag:false,
                })
            }else if(newsstate==2){
                this.setState({
                    newslist1:res.data,
                    flag:false,
                })
            }else if(newsstate==3){
                this.setState({
                    newslist2:res.data,
                    flag:false,
                })
            }
            
        }       

    }

    // 触底更新数据
    onscroll = e => {
        const {flag,} = this.state
        e.preventDefault()
        e.stopPropagation()        
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5) {
            if (!flag) {
                this.pageBrowselist()
            }
        }
    }
    // 触底时换页
    pageBrowselist = () => {
        const {
          size,
          page,
          newslist,
          pharmacyId,
          createTime,
          createTimes,
          selcetcf,
          selcetcfbh,
          currentDate,
          newsstate,
          alCountNum,
          refuseCountNum,
          choseDateList,
          newslist1,
          newslist2
        } = this.state;        

        let array = []
        let array1 = []
        this.setState({
            flag: true
        }
        , () => {
            const params = {
                token:localStorage.getItem('jwtTokenh5'),
                size: size,
                page: page + 1,
                pharmacyId,
                createTime: createTime ? createTime : '', //开始时间
                createTimes: createTimes ? createTimes : '', //结束时间
                selcetcf,
                selcetcfbh,
                dateString:(!createTime && !createTimes) && newsstate != 1 ? currentDate : '',
                newsstate //tabs
            }
            shopAllOrders(params).then(res => {
                if(res.code == 1 && res.data){
                    if(newsstate==1){
                        this.setState({
                            newslist: [...newslist, ...res.data],
                        })
                        this.setState({
                            page: params.page,
                            flag:false
                        })
                    }else if(newsstate==2){
                        this.setState({
                            newslist1: [...newslist1, ...res.data],
                        })
                        this.setState({
                            page: params.page,
                            flag:false
                        })
                    }else if(newsstate==3){
                        this.setState({
                            newslist2: [...newslist2, ...res.data],
                        })
                        this.setState({
                            page: params.page,
                            flag:false
                        })
                    }
                    
                }
                
            })
        })

        

    }
    goback = () => {
        const {timer0} = this.state
        window.clearTimeout(timer0)
        this.props.history.push(`/drugselection`)
    }
    //查看详情
    toDetails = (consultationId, prescribingId, isTorestaff, consultationStu) => {
        
        const {patientId,createTime,createTimes,selcetcf,selcetcfbh} = this.state
        if (isTorestaff == 2) {
            // this.props.history.replace(`/shopassistant?newsstate=${2}`)
            if (prescribingId) {
                this.props.history.push(`/shopassistantdetail?consultationId=${consultationId}&prescribingId=${prescribingId}&consultationStu=${consultationStu}`)
            } else {
                localStorage.setItem("flushPage",true)
                this.props.history.push(`/shopassistantdetail?consultationId=${consultationId}&consultationStu=${consultationStu}`)
            }
        } else {
            Toast.info('该问诊已失效，无法查看详情')
        }
    }
    //再次咨询
    toAgain = (consultationId, pharmacyId, patientIdcardNum,medType) => {
        const {patientId} = this.state
        alert('提示弹窗', '确认再次咨询', [
            {
                text: '取消', onPress: () => {
                    console.log('取消')
                }
            },
            {
                text: '确认', onPress: async () => {
                    let array = []
                    let res = await storenewOrder({consultationId, patientId, pharmacyId, patientIdcardNum})
                    // console.log(res)


                    if (res && res.data && res.data.istoreStaff == 1) {
                        Toast.info('该问诊已重新提交，无法再次咨询')
                        this.dayAllorders()
                    } else {
                        if (res && res.data && res.data[4].length) {

                            // 查询是否属于中药问诊单
                            let res07 = await isChineseMeds({consultation_id: consultationId})

                            if(res07.data[0].length>0){
                                res.data[4].map(item => {
                                    let params = {}
                                    params.contacts = item.contacts
                                    params.classify_id = item.is_otc == 2 ? '1' : '0'
                                    params.libraryId = item.library_id
                                    params.medApparatus = item.med_apparatus
                                    params.medApplySymptoms = item.med_apply_symptoms
                                    params.pro_approval = item.med_approval
                                    params.pro_com_name = item.med_com_name
                                    params.med_company = item.med_company
                                    params.medDosageForm = item.med_dosage_form
                                    params.medEnterprise = null
                                    params.pro_id = item.med_id
                                    params.peo_name = item.med_name
                                    params.medPacking = item.med_packing
                                    params.pro_photo = item.med_photo
                                    params.med_price = item.med_price
                                    params.med_qrcode = item.med_qrcode
                                    params.jiacountValue = item.med_quantity
                                    params.med_spec = item.med_spec
                                    params.med_type = item.med_type
                                    params.packing_unit = item.packing_unit
                                    params.supervision_type = item.supervision_type
                                    params.thirdPartyId = item.third_party_id
                                    array.push(params)
                                })
                                

                                // 查询用法用量
                                let res08 = await chineseMethodDoc({consultation_id: consultationId})

                                let allarr = res08.data[0][0].co_demand
                                let dayarr = res08.data[0][0].daily
                                let mlarr1 = res08.data[0][0].each_dose
                                let mlarr2 = res08.data[0][0].at_time
                                // let methodarr = res08.data[0][0].med_method
                                let meiday = res08.data[0][0].meiday
                                let units = res08.data[0][0].unit
                                let selectedValue = res08.data[0][0].med_time

                                localStorage.setItem("allarr", allarr)
                                localStorage.setItem("dayarr",dayarr)
                                localStorage.setItem("mlarr1",mlarr1)
                                localStorage.setItem("mlarr2",mlarr2)
                                // localStorage.setItem("methodarr",methodarr)
                                localStorage.setItem("meiday",meiday)
                                localStorage.setItem("units",units)
                                localStorage.setItem("selectedValue",selectedValue)


                            }else{
                                res.data[4].map(item => {
                                    let params = {}
                                    params.contacts = item.contacts
                                    params.classifyId = item.is_otc == 2 ? '1' : '0'
                                    params.libraryId = item.library_id
                                    params.medApparatus = item.med_apparatus
                                    params.medApplySymptoms = item.med_apply_symptoms
                                    params.medApproval = item.med_approval
                                    params.medComName = item.med_com_name
                                    params.medCompany = item.med_company
                                    params.medDosageForm = item.med_dosage_form
                                    params.medEnterprise = item.med_enterprise
                                    params.medId = item.med_id
                                    params.medName = item.med_name
                                    params.medPacking = item.med_packing
                                    params.medPhoto = item.med_photo
                                    params.medPrice = item.med_price
                                    params.medQrcode = item.med_qrcode
                                    params.count = item.med_quantity
                                    params.medSpec = item.med_spec
                                    params.medType = item.med_type
                                    params.packingUnit = item.packing_unit
                                    params.supervisionType = item.supervision_type
                                    params.thirdPartyId = item.third_party_id
                                    array.push(params)
                                })
                            }
                            localStorage.setItem("selectmeds", JSON.stringify(array))
                            localStorage.setItem("chamedarr", JSON.stringify(array))


                            // 查询是否属于中药问诊单
                            let res06 = await isChineseMeds({consultation_id: consultationId})
                            if(res06.data[0].length>0){
                                localStorage.removeItem("selectmeds")
                                this.props.history.push(`/patientinformationzhong?storeagain=${1}&medType=${2}&consultationDetail=${res.data[1][0].consultation_detail}&consultationType=${res.data[1][0].consultation_type}&medicalNsurancecoordination=${res.data[1][0].medical_nsurance_coordination}`)
                            }else{
                                localStorage.removeItem("chamedarr")
                                this.props.history.push(`/patientinformation?storeagain=${1}&consultationDetail=${res.data[1][0].consultation_detail}&consultationType=${res.data[1][0].consultation_type}&medicalNsurancecoordination=${res.data[1][0].medical_nsurance_coordination}`)
                            }

                           
                        }
                    }

                }
            },
        ])
    }
    setTimenull=()=>{
        this.setState({
            createTime:'',
            createTimes:'',
            selcetcf:1,
            selcetcfbh:1,
        },()=>{
            this.dayAllorders()
        })
    }
    toallcf=()=>{
        this.props.history.push(`/allprescribed`)
    }

    handleOffsetChange = (offset) => {
        this.setState({ offset });
    };

    handleReturn = () => {
        this.props.history.push('/drugselection');
    };

    render() {
        const {
            newsstate,
            newslist,
            newslist1,
            dateList,
            createTime,
            visible,
            miaosu,
            selectcode,
            visible2,
            listchose,
            visible6,
            selcetcf, //已接问诊
            visible7,
            selcetcfbh, //驳回处方
            visible10,
            createTimes,
            offset,
            newslist2,
            isPhaExamine,
            choseDateList,
            alCountNum,
            refuseCountNum,
        } = this.state
        const now = new Date()
        const now1 = new Date('2022-01')
        const year = now.getFullYear().toString();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const date = now.getDate().toString().padStart(2, '0');
        const dateString = `${year}-${month}-${date}`;

        // console.log('起始日期：',createTime,'结束日期：',createTimes);
        // console.log('今天的日期：',dateString);
        // console.log('已接问诊：',selcetcf);
        // console.log('驳回处方：',selcetcfbh);
        // console.log('筛选日期列表：',choseDateList);
        // console.log('已接问诊列表',newslist1);
        // console.log('三个tabs:',newsstate);
        // console.log(refuseCountNum,'总数');
        // console.log('拒诊列表：',newslist2);

        // console.log('alCountNum',alCountNum);

        return (
            <div className='newsbox1'>
                <div className='box-top12'>
                    <img className='return-img' src={fhhome} onClick={this.handleReturn} />
                    <span className='title-middle'>问诊记录</span>
                </div>
                <div className='success-add'>
                    <p onClick={()=>this.toallcf()}>
                        <img src={icon1}/>
                        <span>成功处方</span>
                    </p>
                    <p onClick={()=>this.props.history.push(`/westernm`)}>
                        <img src={icon2}/>
                        <span>添加药品</span>
                    </p>
                    <p onClick={()=>this.props.history.push(`/setsignature`)}>
                        <img src={icon3}/>
                        <span>设置签名</span>
                    </p>
                </div>
                <div className='news-inend news-inend1'>
                    <p className={newsstate == 1 ? 'news-top-left' : 'news-top-left1'}
                       onClick={() => this.setState({
                                        newsstate: 1, 
                                        visible6:false, 
                                        visible7:false,
                                        createTime:"",
                                        createTimes:"",
                                        selcetcf:1,
                                        selcetcfbh:1},()=>{this.dayAllorders()}
                                    )}>待接问诊</p>
                    <p className={newsstate == 2 ? 'news-top-right2' : 'news-top-right22'}
                       onClick={() => this.setState({
                                        newsstate: 2, 
                                        visible6:false, 
                                        visible7:false,
                                        createTime:"",
                                        createTimes:"",
                                        selcetcf:1,
                                        selcetcfbh:1},()=>{this.dayAllorders()}
                                    )}>已接问诊</p>
                    <p className={newsstate == 3 ? 'news-top-middle' : 'news-top-middle1'}
                       onClick={() => this.setState({
                                        newsstate: 3, 
                                        visible6:false, 
                                        visible7:false,
                                        createTime:"",
                                        createTimes:"",
                                        selcetcf:1,
                                        selcetcfbh:1},()=>{this.dayAllorders()}
                                    )}>驳回处方</p>
                    {/*<p onClick={()=>this.toallcf()} className='news-top-cf'>*/}
                    {/*    <i className='iconfont icon-chufang2'/>*/}
                    {/*    <span>已开处方</span>*/}
                    {/*</p>*/}

                </div>
                {newsstate == 3 ?<div className='container-shopassistant-createtime'>
                    <p className='all-chufang' 
                        onClick={() => this.setState({
                            visible7: true
                        })}
                    >
                        <span style={{color:'#346EF4'}}>{selcetcfbh === 1 ? '全部处方' : selcetcfbh === 2 ? '西/中成药' : '中药'}</span>
                        <img src={updown}/>
                    </p>

                    <p className='all-chufang2'>
                        <span 
                                onClick={() => this.setState({
                                    visible: true
                                })} 
                                style={{color:createTime?'#346EF4':'#817F7F'}}>{createTime ? createTime : '起始日期'}</span>
                        <img src={updown}/>
                        <div className='lianjiexian'>-</div>
                        <span 
                                onClick={() => this.setState({
                                    visible10: true
                                })} 
                                style={{color:createTimes?'#346EF4':'#817F7F'}}>{createTimes ? createTimes : '结束日期'}</span>
                        <img src={updown}/>
                    </p>
                    <p className='dutyaccountbox-null' onClick={() => this.setTimenull()}>重置</p>
                </div>:null}

                {newsstate == 2 ? <div className='container-shopassistant-createtime'>
                    <p className='all-chufang' 
                        onClick={() => this.setState({
                            visible6: true
                        })}
                    >
                        <span style={{color:'#346EF4'}}>{selcetcf === 1 ? '全部处方' : selcetcf === 2 ? '西/中成药' : '中药'}</span>
                        <img src={updown}/>
                    </p>
                    <p className='all-chufang2'>
                        <span 
                                onClick={() => this.setState({
                                    visible: true
                                })} 
                                style={{color:createTime?'#346EF4':'#817F7F'}}>{createTime ? createTime : '起始日期'}</span>
                        <img src={updown}/>
                        <div className='lianjiexian'>-</div>
                        <span 
                                onClick={() => this.setState({
                                    visible10: true
                                })} 
                                style={{color:createTimes?'#346EF4':'#817F7F'}}>{createTimes ? createTimes : '结束日期'}</span>
                        <img src={updown}/>
                        
                    </p>
                    <p className='dutyaccountbox-null' onClick={() => this.setTimenull()}>重置</p>
                </div>:null}

                {visible6 && (
                    <div>
                        <div className='all-chose'>                        
                            <span 
                                onClick={() => this.setState({ visible6: false, selcetcf: 1 },()=>{this.dayAllorders()})} 
                                className={(selcetcf === 1) ? 'selectcf-yes' : ''}>全部处方</span>
                            <span 
                                onClick={() => this.setState({ visible6: false, selcetcf: 2 },()=>{this.dayAllorders()})} 
                                className={(selcetcf === 2) ? 'selectcf-yes' : ''}>西/中成药</span>
                            <span 
                                onClick={() => this.setState({ visible6: false, selcetcf: 3 },()=>{this.dayAllorders()})} 
                                className={(selcetcf === 3) ? 'selectcf-yes' : ''}>中药</span>

                        </div>
                    </div>
                )}

                {visible7 && (
                    <div>
                        <div className='all-chose'>                        
                            <span 
                                onClick={() => this.setState({ 
                                        visible7: false, 
                                        selcetcfbh: 1 
                                    },
                                    ()=>{this.dayAllorders()}
                                )} 
                                className={(selcetcfbh === 1) ? 'selectcf-yes' : ''}>全部处方</span>
                            <span 
                                onClick={() => this.setState({ visible7: false, selcetcfbh: 2 },()=>{this.dayAllorders()})} 
                                className={(selcetcfbh === 2) ? 'selectcf-yes' : ''}>西/中成药</span>
                            <span 
                                onClick={() => this.setState({ visible7: false, selcetcfbh: 3 },()=>{this.dayAllorders()})} 
                                className={(selcetcfbh === 3) ? 'selectcf-yes' : ''}>中药</span>

                        </div>
                    </div>
                )}


                {/* 待接问诊 */}
                {newsstate == 1 &&
                    <div className='box-container-shopassistant' onScroll={(e) => this.onscroll(e)}>
                        {newslist.length ? newslist.map((news, index) => {
                            return (
                                <div className='container-shopassistant'>
                                    <p className='name-news'>
                                        <span className='name'>患者姓名：</span>
                                        <p className='spec-news'>
                                            <span style={{color: '#177FFF'}}>{news.patientName} &nbsp;</span>
                                            <span>{news.patientSex == 1 ? '男' : '女'}/{news.patient_age}岁</span>
                                            {news.patientIdcardNum ? <i className='iconfont icon-a-yibaoyibaoka'/> : null}
                                        </p>
                                        {news.consultation_type == '1' ? <span className='shopassistant-type'>图文</span> :
                                            <span className='shopassistant-type'
                                                  style={{backgroundColor: '#05AC73'}}>视频</span>}
                                    </p>
                                    <p className='name-news'>
                                        <span className='name'>问诊时间：</span>
                                        <span className='spec-news'>
                                            <span>{news.createTime}</span>
                                        </span>
                                    </p>
                                    {news.is_chinese_meds == 1 ? (
                                        <>
                                            <p className='name-news'>
                                                <span className='name'>中药需求：</span>
                                                <span className='xuqiu'>
                                                    {news.array.map((i,index) => (
                                                        <span key={index}>
                                                            <span className="classfi-tip">{i.supertype}</span>
                                                            <span>{i.namespace}；</span>
                                                        </span>
                                                    ))}
                                                </span>
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p className='name-news'>
                                                <span className='name'>西药需求：</span>
                                                <span className='xuqiu'>
                                                    {news.array.map((i,index) => (
                                                        <span key={index}>
                                                            <span className="classfi-tip">{i.supertype}</span>
                                                            <span>{i.namespace}；</span>
                                                        </span>
                                                    ))}
                                                </span>
                                            </p>
                                        </>
                                    )}
                                </div>
                            )
                        }) : <Nodata type='消息'/>}

                    </div> }

                {/* 已接问诊 */}
                {newsstate == 2 && (
                    <>
                        <div className='box-container-shopassistant' onScroll={(e) => this.onscroll(e)}>
                            {newslist1 && newslist1.length > 0 ? (
                                <>
                                    {alCountNum && alCountNum.map((el,indexs)=>{
                                        return(
                                            <div key={indexs}>
                                                {el.create_time && 
                                                    <div className='count-data'>
                                                        <span>{el.create_time}  接诊总数：{el.num}</span>
                                                    </div>}
                                                
                                                {newslist1.filter(news => news.nowTime == el.create_time).map((news,index)=>{
                                                    return (
                                                        <div className='container-shopassistant'>
                                                            <p className='name-news'>
                                                                <span className='name'>患者姓名：</span>
                                                                <p className='spec-news'>
                                                                    <span style={{color: '#177FFF'}}>{news.patientName} &nbsp;</span>
                                                                    <span>{news.patientSex == 1 ? '男' : '女'}/{news.patient_age}岁</span>
                                                                    {news.patientIdcardNum ?
                                                                        <i className='iconfont icon-a-yibaoyibaoka'/> : null}
                                                                </p>
                                                                {isPhaExamine ? (
                                                                    <>
                                                                        {(news.prescribingState == '1' && news.prescringPhaState == 1) ? <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#05AC73'}}>开方成功</span> :
                                                                            (news.prescribingState == '0'&&news.originalPrescribing) || (!news.prescribingId && news.consultation_stu == '0') || (news.prescribingState == '1' && news.prescringPhaState == 0) ?
                                                                        <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#FF9C00'}}>处理中</span> :
                                                                            news.is_tore_staff == '1' ?
                                                                        <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#D4D4D4'}}>失效</span> :
                                                                            news.prescribingState == '-1' || news.consultation_stu == '3' || ((!news.prescribingId||!news.originalPrescribing) && news.consultation_stu == '1') || (news.prescribingState == '1' && news.prescringPhaState == 2) ?
                                                                        <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#FF3030'}}>未成功</span> : null
                                                                        }
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {(news.prescribingState == '1') ? <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#05AC73'}}>开方成功</span> :
                                                                            (news.prescribingState == '0'&&news.originalPrescribing) || (!news.prescribingId && news.consultation_stu == '0') ?
                                                                        <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#FF9C00'}}>处理中</span> :
                                                                            news.is_tore_staff == '1' ?
                                                                        <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#D4D4D4'}}>失效</span> :
                                                                            news.prescribingState == '-1' || news.consultation_stu == '3' || ((!news.prescribingId||!news.originalPrescribing) && news.consultation_stu == '1') ?
                                                                        <span className='shopassistant-type1'
                                                                            style={{backgroundColor: '#FF3030'}}>未成功</span> : null
                                                                        }
                                                                    </>
                                                                )}
                                                            </p>
                                                            <p className='name-news'>
                                                                <span className='name'>科室：</span>
                                                                <span className='spec-news'>
                                                                    <span>{news.docterDepartment}</span>
                                                                </span>
                                                            </p>
                                                            <p className='name-news'>
                                                                <span className='name'>问诊时间：</span>
                                                                    <span className='spec-news'>
                                                                    <span>{news.createTime}</span>
                                                                </span>
                                                            </p>                    
                                                            {isPhaExamine ? (
                                                                <>
                                                                    {news.prescringPhaState != 2 && news.prescribingState == '1' || news.prescribingState == '0' || (!news.prescribingId && news.consultation_stu != '3') ?
                                                                    <p className='name-news'>
                                                                        <span className='name'>接诊医生：</span>
                                                                        <span className='xuqiu'>{news.docterName}</span>
                                                                    </p> : (news.consultation_stu == '3' || news.prescribingState == '-1' || news.prescringPhaState == 2) ?
                                                                        <p className='name-news'>
                                                                            <span
                                                                                className='name'>{news.consultation_stu == '3' ? '医生驳回：' : '药师驳回：'}</span>
                                                                            <span className='xuqiu'
                                                                                    style={{color: news.is_tore_staff == '1' ? '#B5B5B5' : '#FF3030'}}>
                                                                            {news.consultation_stu == '3' ? news.consultation_reason:
                                                                                news.prescribingState == '-1' ? news.reason : news.prescringPhaState == 2 ? news.pha_refu_reson : null
                                                                            }</span>
                                                                        </p> : null}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {news.prescribingState == '1' || news.prescribingState == '0' || (!news.prescribingId && news.consultation_stu != '3') ?
                                                                    <p className='name-news'>
                                                                        <span className='name'>接诊医生：</span>
                                                                        <span className='xuqiu'>{news.docterName}</span>
                                                                    </p> : (news.consultation_stu == '3' || news.prescribingState == '-1') ?
                                                                        <p className='name-news'>
                                                                            <span
                                                                                className='name'>{news.consultation_stu == '3' ? '医生驳回：' : '药师驳回：'}</span>
                                                                            <span className='xuqiu'
                                                                                    style={{color: news.is_tore_staff == '1' ? '#B5B5B5' : '#FF3030'}}>
                                                                            {news.consultation_stu == '3' ? news.consultation_reason:
                                                                                news.prescribingState == '-1' ? news.reason : null
                                                                            }</span>
                                                                        </p> : null}
                                                                </>
                                                            )}
                                                            {((!news.prescribingId||!news.originalPrescribing) && news.consultation_stu == '1') ? <p className='name-news'>
                                                                <span className='name'>问诊状态：</span>
                                                                <span className='xuqiu' style={{color: '#FF3030'}}>
                                                                {!news.prescribingId?'医生已结束问诊，未开方':'医生已结束问诊，开方未成功'}</span>
                                                            </p> : null}
                                                            <p className='shopassistant-button-al'>
                                                                <p className='al-one'>
                                                                    {(news.consultation_stu == '0' && news.consultation_uploadcf=='0') ? 
                                                                        <p style={{color:'#FF8D30'}}>等待医生开方</p> : 
                                                                        (news.consultation_stu == '0' && news.consultation_uploadcf=='1' && news.prescribingState == '0') ? <p style={{color:'#FF8D30'}}>等待平台药师审方</p> : 
                                                                        (news.prescribingState == '1' && news.prescringPhaState == '0') ? <p style={{color:'#FF8D30'}}>等待药店药师审核</p> : null}
                                                                </p>
                                                                
                                                                
                                                                <p className='al-two'>
                                                                    {news.is_tore_staff == '2' && ((news.prescringPhaState == 2 && isPhaExamine == true) || news.prescribingState == '-1' || news.consultation_stu == '3' || ((!news.prescribingId||!news.originalPrescribing) && news.consultation_stu == '1')) ?
                                                                        <p className='shopassistant-again'
                                                                            onClick={() => this.toAgain(news.consultation_id, news.pharmacy_id, news.patientIdcardNum)}>再次咨询</p> : null}
                                                                    {news.is_tore_staff == '1' ?
                                                                        <p className='shopassistant-shixiao'>本单失效，已重新发起咨询</p> : null}
                                                                    <p className='shopassistant-check'
                                                                        style={{backgroundColor: news.is_tore_staff == '1' ? '#B5B5B5' : ''}}
                                                                        onClick={() => this.toDetails(news.consultation_id, news.prescribingId, news.is_tore_staff, news.consultation_stu)}>查看详情</p>
                                                                </p>
                                                                
                                                            </p>
                                                        </div>                                                                                                                                                                           
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </>
                            ) : <Nodata type='消息'/>}
                        </div>
                    </>
                )}
                                   

                {/* 驳回处方 */}
                {newsstate == 3 && (
                    <>
                        <div className='box-container-shopassistant' onScroll={(e) => this.onscroll(e)}>
                            {newslist2 && newslist2.length > 0 ? (
                                <>
                                    {refuseCountNum && refuseCountNum.map((el,indexs)=>{
                                        return (
                                            <div key={indexs}>
                                                {el.create_time && 
                                                    <div className='count-data'>
                                                        <span>{el.create_time}  接诊总数：{el.num}</span>
                                                    </div>}
                                                
                                                {newslist2.filter(news => news.nowTime == el.create_time ).map((news,index)=>{
                                                        return (
                                                            <div className='container-shopassistant'>
                                                                <p className='name-news'>
                                                                    <span className='name'>患者姓名：</span>
                                                                    <p className='spec-news'>
                                                                        <span style={{color: '#177FFF'}}>{news.patientName} &nbsp;</span>
                                                                        <span>{news.patientSex == 1 ? '男' : '女'}/{news.patient_age}岁</span>
                                                                        {news.patientIdcardNum ?
                                                                            <i className='iconfont icon-a-yibaoyibaoka'/> : null}
                                                                    </p>
                                                                    {isPhaExamine ? (
                                                                        <>
                                                                            { news.prescribingState == '-1' || news.prescringPhaState == 2 ?
                                                                                <span className='shopassistant-type1'
                                                                                        style={{backgroundColor: '#FF3030'}}>药师驳回</span> : 
                                                                                <span className='shopassistant-type1'
                                                                                        style={{backgroundColor: '#FF3030'}}>医生驳回</span>
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            { news.prescribingState == '-1' ?
                                                                                <span className='shopassistant-type1'
                                                                                        style={{backgroundColor: '#FF3030'}}>药师驳回</span> : 
                                                                                <span className='shopassistant-type1'
                                                                                        style={{backgroundColor: '#FF3030'}}>医生驳回</span>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </p>
                                                                <p className='name-news'>
                                                                    <span className='name'>科室：</span>
                                                                    <span className='spec-news'>
                                                                        <span>{news.docterDepartment}</span>
                                                                    </span>
                                                                </p>
                                                                <p className='name-news'>
                                                                    <span className='name'>问诊时间：</span>
                                                                    <span className='spec-news'>
                                                                    <span>{news.createTime}</span>
                                                                </span>
                                                                </p>
                                                                {isPhaExamine ? (
                                                                    <>
                                                                        {news.prescringPhaState != 2 && news.prescribingState == '1' || news.prescribingState == '0' || (!news.prescribingId && news.consultation_stu != '3') ?
                                                                            <p className='name-news'>
                                                                                <span className='name'>接诊医生：</span>
                                                                                <span className='xuqiu'>{news.docterName}</span>
                                                                            </p> : (news.consultation_stu == '3' || news.prescribingState == '-1' || news.prescringPhaState == 2) ?
                                                                                <p className='name-news'>
                                                                                    <span
                                                                                        className='name'>{news.consultation_stu == '3' ? '医生驳回：' : '药师驳回：'}</span>
                                                                                    <span className='xuqiu'
                                                                                        style={{color: news.is_tore_staff == '1' ? '#B5B5B5' : '#FF3030'}}>
                                                                                    {news.consultation_stu == '3' ? news.consultation_reason:
                                                                                        news.prescribingState == '-1' ? news.reason : news.prescringPhaState == 2 ? news.pha_refu_reson :  null
                                                                                    }</span>
                                                                                </p> : null}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                    {news.prescribingState == '1' || news.prescribingState == '0' || (!news.prescribingId && news.consultation_stu != '3') ?
                                                                            <p className='name-news'>
                                                                                <span className='name'>接诊医生：</span>
                                                                                <span className='xuqiu'>{news.docterName}</span>
                                                                            </p> : (news.consultation_stu == '3' || news.prescribingState == '-1') ?
                                                                                <p className='name-news'>
                                                                                    <span
                                                                                        className='name'>{news.consultation_stu == '3' ? '医生驳回：' : '药师驳回：'}</span>
                                                                                    <span className='xuqiu'
                                                                                        style={{color: news.is_tore_staff == '1' ? '#B5B5B5' : '#FF3030'}}>
                                                                                    {news.consultation_stu == '3' ? news.consultation_reason:
                                                                                        news.prescribingState == '-1' ? news.reason : null
                                                                                    }</span>
                                                                                </p> : null} 
                                                                    </>
                                                                )}
                                                                {((!news.prescribingId||!news.originalPrescribing) && news.consultation_stu == '1') ? <p className='name-news'>
                                                                    <span className='name'>问诊状态：</span>
                                                                    <span className='xuqiu' style={{color: '#FF3030'}}>
                                                                {!news.prescribingId?'医生已结束问诊，未开方':'医生已结束问诊，开方未成功'}</span>
                                                                </p> : null}
                                                                <p className='shopassistant-button'>
                                                                    {news.is_tore_staff == '2' && ((news.prescringPhaState == 2 && isPhaExamine == true) || news.prescribingState == '-1' || news.consultation_stu == '3' || ((!news.prescribingId||!news.originalPrescribing) && news.consultation_stu == '1')) ?
                                                                        <p className='shopassistant-again'
                                                                        onClick={() => this.toAgain(news.consultation_id, news.pharmacy_id, news.patientIdcardNum)}>再次咨询</p> : null}
                                                                    {news.is_tore_staff == '1' ?
                                                                        <p className='shopassistant-shixiao'>本单失效，已重新发起咨询</p> : null}
                                                                    <p className='shopassistant-check'
                                                                    style={{backgroundColor: news.is_tore_staff == '1' ? '#B5B5B5' : ''}}
                                                                    onClick={() => this.toDetails(news.consultation_id, news.prescribingId, news.is_tore_staff, news.consultation_stu)}>查看详情</p>
                                                                </p>
                                                            </div>
                                                        )
                                                })}
                                            </div>
                                        )
                                    })}
                                </>
                            ) : <Nodata type='消息'/>}
                        </div>
                    </>
                )}   

                


                <DatePicker
                    visible={visible}
                    onClose={() => {
                        this.setState({
                            visible: false,
                        })
                    }}
                    defaultValue={now}
                    max={now}
                    min={now1}
                    precision='day'
                    onConfirm={value => {
                        const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                        // if (time20) {
                        const num = new Date(value).getDate() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                        this.setState({
                            createTimes:'',
                            page: 1,
                            dateList: [],
                            createTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1)) + '-' + ((new Date(value).getDate()) > 9 ? (new Date(value).getDate()) : '0' + (new Date(value).getDate())),
                            visible10:true,
                        })
                    }}
                />

                <DatePicker
                    // title='时间选择'
                    visible={visible10}
                    onClose={() => {
                        this.setState({
                            visible10: false,
                        })
                    }}
                    defaultValue={now}
                    max={now}
                    min={now1}
                    precision='day'
                    onConfirm={value => {
                        const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                        // if (time20) {
                        const num = new Date(value).getDate() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                        this.setState({
                            page: 1,
                            dateList: [],
                            createTimes: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1)) + '-' + ((new Date(value).getDate()) > 9 ? (new Date(value).getDate()) : '0' + (new Date(value).getDate()))
                        }, () => {
                            this.dayAllorders()
                        })
                    }}
                />

                {/* 申请问诊浮窗 */}
                <div onClick={()=>{
                        this.props.history.push(`/drugselection`)
                    }}
                >
                    <FloatingBubble
                        axis='xy'
                        magnetic='x'
                        style={{
                        '--initial-position-bottom': '15px',
                        '--initial-position-right': '15px',
                        '--edge-distance': '15px',
                        '--background': '#FF9239',
                        '--size': '48px'
                        }}
                    >
                        <p>申请<br/>问诊</p>
                    </FloatingBubble>
                </div>
                
            </div>
        )
    }
}
