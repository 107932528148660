import React, { Component } from 'react'
import {Modal, SideBar} from "antd-mobile-v5"
import "./appointment.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/head.png"
import {allGhDeperdment} from '../../../api/hospitalapi/chat'
import getUrl from '../../../component/GetUrlParam'
/*
*  挂号
* */

export default class appointment extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            activeKey: "0",
            userId: localStorage.getItem("patientId"),
            list:[]
        }
    }
    componentDidMount =async () => {
        const {userId} =this.state
        if (getUrl('index', this.props.location.search)){
            this.setState({
                activeKey:getUrl('index', this.props.location.search).toString()
            })
        }
        let res=await allGhDeperdment()
        // console.log(res)
        if (res&&res.data&&res.data[0]){
            this.setState({
                list:res.data[0]
            })
        }
    }
    setActiveKey=(activeKey,id)=>{
        this.setState({
            activeKey:activeKey.toString()
        })
        // console.log(activeKey,id)
        this.props.history.replace(`/appointment?index=${activeKey}`)
        this.props.history.push(`/register?id0=${id}&index=${activeKey}`)
    }
    goBack=()=>{
        // window.history.back()
        this.props.history.push(`/`)
    }
    render() {
        const { list,activeKey} = this.state
        return (
            <div className='setbox queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.goBack()} />   预约挂号
                </div>
                <div className='appointment-outer' >
                    {list.map((item,index) => (<div style={{ display: 'flex' }} className='appointment' onClick={(activeKey,id) => this.setActiveKey(index,item.id)}>
                        <div className='appointment-left'>
                            <SideBar activeKey={activeKey}  >

                                <SideBar.Item key={index} title={item.name}/>
                                 {/*<span>＞</span>*/}

                            </SideBar>
                        </div>
                        <div className='appointment-right'>＞</div>
                    </div> ))}
                </div>
            </div>
        )
    }
}
