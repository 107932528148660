import React, { Component } from 'react'
import "./style.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/moren.png"
import icon3 from "../../static/img/yy/right.png"
import icon4 from "../../static/img/yy/touxiang.png"
import icon5 from "../../static/img/yy/success.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            code: false,
            list:[],
        }
    }
    componentDidMount=async () => {
        // console.log(this.props.location.params);
        // this.setState({
        //     list:this.props.location.params.list,
        // })
    }
    gobank=()=>{
        const {list} = this.state
        // console.log(list);
        // this.props.history.push({pathname:'/chiosestore',params:{list,news:this.props.location.params.news}})
    }
    render() {
        return (
            <div className='submitbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>提交订单</h1>
                </div>
                <div className='box-content'>
                    <div className='content-name'>
                        <p>
                            <img src={icon2} alt="" /><span>四川省成都市新都区</span>
                        </p>
                        <p className='store-name'>
                            福瑞民心堂大药房3楼302
                        </p>
                        <p>
                            <span>客服小吴</span><span>19048012565</span>
                        </p>
                        <img src={icon3} alt="" className='text-right' />
                    </div>
                    <div className='content-name' onClick={()=>this.props.history.push("/prescriptionlist")}>
                        <p>
                            用药人信息
                        </p>
                        <p className='store-name'>
                            XXX
                        </p>
                        <p>
                            <span>处方订单：</span><span>155412485423</span>
                        </p>
                        <img src={icon3} alt="" className='text-right' />
                    </div>
                    <div className='content-goods'>
                        <p className='goods-title'>
                            <span>药店名字药店名字</span>  <span>共3件商品</span>
                        </p>
                        <div className='goods-item'>
                            <img src={icon4} alt="" />
                            <div>
                                <p>
                                    <span>商品名字</span><span>￥16.83</span>
                                </p>
                                <p>
                                    <span>规格：200丸x1g/盒</span><span>x2</span>
                                </p>
                            </div>
                        </div>
                        <div className='goods-item'>
                            <img src={icon4} alt="" />
                            <div>
                                <p>
                                    <span>商品名字</span><span>￥16.83</span>
                                </p>
                                <p>
                                    <span>规格：200丸x1g/盒</span><span>x2</span>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <div className='goods-other'>
                            <p>配送费</p>
                            <p>￥0.5 <img src={icon3} alt="" /></p>
                        </div>
                        <div className='goods-other'>
                            <p>优惠券</p>
                            <p>优惠￥6.00<img src={icon3} alt="" /></p>
                        </div>
                        <div className='goods-other'>
                            <p>积分</p>
                            <p>-￥6.00<img src={icon5} alt="" style={{ width: "11px", height: "11px" }} /></p>
                        </div>
                        <p className='goods-jifen'>
                            您有600积分可使用
                        </p>
                    </div>
                    <div className='content-remarks'>
                        <div>
                            <p>备注</p>
                            <p>
                                <span>补充说明</span>
                                <img src={icon3} alt="" />
                            </p>
                        </div>
                        <div>
                            <p>发票</p>
                            <p>
                                <span>未选择</span>
                                <img src={icon3} alt="" />
                            </p>
                        </div>
                        <div>
                            <p>支付方式</p>
                            <p>
                                <span>未选择</span>
                                <img src={icon3} alt="" />
                            </p>
                        </div>
                    </div>
                </div>
                <div className='box-bottom'>
                    <div>
                        <h1>待支付：<span>￥60.52</span></h1>
                        <p>已优惠：<span>￥12.00</span></p>
                    </div>
                    <button>去支付</button>
                </div>
            </div>
        )
    }
}
