import React, { Component } from 'react'
import { ImageViewer, Button } from "antd-mobile-v5"
import "./style.less"
import FH from "../../../static/img/mine/fanhui.png"
import { getstoreinfo } from "../../../api/request/store"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            storetext: {},
            isModalVisible: false,
            picnow:null
        }
    }

   
    componentDidMount = async () => {
        const companyId = this.props.location.params.pharmacyId
        //const companyId = 11
        const res = await getstoreinfo(companyId)
        this.setState({
            storetext: res.data[0][0]
        })
        console.log(res);
    }
    render() {
        const { storetext, isModalVisible,picnow } = this.state
        return (
            <div className="storeinfobox">

                <div className="storeinfobox-top">
                    <img src={FH} alt="" onClick={() => window.history.back()} /><h2>商家信息资质</h2>
                </div>
                <div className="storeinfobox-icon">
                    <img src={storetext.pharmacyPhoto} alt="" /><p>{storetext.pharmacyName}</p>
                </div>
                <div className="storeinfobox-text">
                    <div className="storeinfobox-text-item">
                        <p>公司名称</p>
                        <p>{storetext.accountName}</p>
                    </div>
                    <div className="storeinfobox-text-item">
                        <p>联系人</p>
                        <p>{storetext.contacts}</p>
                    </div>
                    <div className="storeinfobox-text-item">
                        <p>联系电话</p>
                        <p>{storetext.contactPhone}</p>
                    </div>
                    <div className="storeinfobox-text-item">
                        <p>公司地址</p>
                        <p>{storetext.companyAddress}</p>
                    </div>
                </div>
                <div className="storeinfobox-content">
                    <h3>公司资质</h3>
                    <div className="storeinfobox-content-item">
                        <img src={storetext.businessPic} alt="" onClick={() => {
                        this.setState({
                            isModalVisible:true,
                            picnow:storetext.businessPic
                        })
                    }}/>
                        <img src={storetext.circulationPic} alt="" onClick={() => {
                        this.setState({
                            isModalVisible:true,
                            picnow:storetext.circulationPic
                        })
                    }}/>
                        <img src={storetext.licensePic} alt="" onClick={() => {
                        this.setState({
                            isModalVisible:true,
                            picnow:storetext.licensePic
                        })
                    }}/>
                    </div>
                </div>
                <ImageViewer
                    image={picnow}
                    visible={isModalVisible}
                    onClose={() => {
                        this.setState({
                            isModalVisible:false
                        })
                    }}
                />
            </div>
        )
    }
}
