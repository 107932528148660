import React, { Component } from "react";
import "./style.less";
import { Popup, Input, Button, TextArea } from "antd-mobile-v5";
import icon1 from "../../static/img/icon/ybtc.png";
import xiala from "../../static/img/xiala.png";
import { Toast, Modal, Checkbox,Picker,Dialog } from "antd-mobile";
import getUrl from "../../component/GetUrlParam";
import {zipImg} from '../../component/zipImage'
import { wxConfig } from "../../component/scan/index";
import {uploaddoctor, uploadpic} from "../../api/hospitalapi/upload"
import {
  defaultMember,
  getAllills,
  getHighmeds,
  getCollectmedicinal,
  allClassifyid,
  isHightmed,
  getSpiritmeds,//精神类
  getInjectionmeds,//注射剂药品
  getHighRiskmeds,//新高危药品
  getMedsills,//药品对应的所有疾病
  allChamed,//中药对应的疾病
} from "../../api/hospitalapi/meds";
import {
  addAllmeds,
  saveIcd,
  submitOrdernews,
  submitChaMedOrder,
  subChaifen,
  sumbitLogs,
  chineseMedsTaboo
} from "../../api/hospitalapi/order";
import { newState, getnewslist } from "../../api/hospitalapi/chat";
import {
  allOfflinedoc,
  isSelfphamacy,
  allNowdayorders,
  getOrderallways,//提交问诊前查询药品要用信息
  prescriptionValidation,
  selectDoctor,
  selectFiltering,//查询店铺所属权限
  selectPharmacyAear,
  selectPharmacyName,
  changeDefaultMember
} from "../../api/hospitalapi/offline";
import HeaderNav from "../../component/HeaderNav";
import { Link } from "react-router-dom";
import jia2 from "../../static/img/jia2.png";
import fhicon1 from "../../static/img/fhicon1.png";
import zysc from "../../static/img/zysc.png";
import gou from "../../static/img/gou.png";
import pingzhen from "../../static/img/pingzhen.png";
import touxiang from "../../static/img/home/touxiang.png";
import awaitDoc from "../../static/img/await-doc.gif";
import noDoc from "../../static/img/nodoc.png";
import { addchat } from "../../api/hospitalapi/chat";
import defaultImage from "../../static/img/defaultImage.jpg";
import "../../static/fonts/font_3834206_omr9d7z9z9/iconfont.css";
import { code05, zfUrl } from "../../publicurl";
import { gzDoc } from "../../api/hospitalapi/user";
import search from "../../static/img/home/search1.png";
import icon2 from "../../static/img/close1.png";
import icon3 from "../../static/img/contact-complaint.png";
import Nodata from "../../component/Nodata";
const CheckboxItem = Checkbox.CheckboxItem;
const prompt = Modal.prompt;
const alert = Modal.alert;
let isClick = false
let isClickA = false
export default class index extends Component {
  constructor() {
    super();
    // this.scrollHandler = this.scrollHandler.bind(this);
    this.state = {
      unit: ['mg', 'g', 'ml', '片', '丸', '粒', '袋', '枚', '喷', '支', 'ug', '滴', '帖', '瓶', '张', '剂', 'IU'],//药品单位
      patientId: localStorage.getItem("patientId1"),
      pharmacyId: localStorage.getItem("pharmacyId"),
      patientPhone: localStorage.getItem("patientPhone1"),
      supervisionType: localStorage.getItem("supervisionType"),
      selectmeds: [],
        // localStorage.getItem("selectmeds") &&
        // localStorage.getItem("selectmeds") != "undefined"
        //   ? JSON.parse(localStorage.getItem("selectmeds"))
        //   : [],
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      miaosu: localStorage.getItem("miaosu")
        ? localStorage.getItem("miaosu")
        : "", //病情描述
      allmiaosu: "", //病情描述
      selectcode: 0, //是否勾选风险告知书 1是  0否
      isuse: 0, //是否服用过 1是 0否
      isadverse: 0, //是否有不良反应 1有 0无
      defultUser: "",
      awaitdoc: 1, //2等待医生接诊中
      allDocId: "",
      time: 300,
      consultationId: "",
      docterId: "",
      index: 0,
      timer: "",
      // list: [{value: '感冒症状', index: 1}, {value: '心血管疾病', index: 1}, {value: '高血压症状', index: 1}, {
      //     value: '肠胃疾病',
      //     index: 1
      // }, {value: '消化系统疾病', index: 1},
      //     {value: '呼吸系统类', index: 1}, {value: '妇科用药', index: 1},],
      listchose: [],
      // list:localStorage.getItem("listicd")?JSON.parse(localStorage.getItem("listicd")): [],
      list: [],
      icdlists: [],

      tips: 0,
      videocode: localStorage.getItem("videocode") ? localStorage.getItem("videocode") : 0,
      // coordinationcode: 2, //1、医保统筹 2、个人支付
      coordinationcode: localStorage.getItem('visitType') ? localStorage.getItem('visitType') : 2, //1、医保统筹 2、个人支付
      selectdoctor: 1, //1、快速问诊 2、选择医生
      entitycode: 0, //1、复诊 2、首诊
      storeagain: 0, //1、店员重新问诊
      consultationDetail: "",

      spiritMeds: [], //精神类药品，无法提交
      spiritMedslength: "", //精神类药品数量
      spiritMedsArray: [], //精神类药品

      injectionMeds:[],//注射剂药品
      injectionMedsArray: [], //注射剂药品
      injectionMedslength: "", //注射剂药品数量

      highriskMeds:[],//新高危药品
      highriskMedsArray: [], //新高危药品
      highriskMedslength: "", //新高危药品数量

      highMeds: [], //AB级高危药品，无法提交
      highMedsC: [], //c级高危药品，可以提交
      specialMedicine: [], //特殊药品
      highMedslength: "", //高危药品数量

      highMedsArray: [],
      allbeginMeds: [],
      allMeds: [],
      allotherMeds: [],
      onewsMeds: [],
      isnonews: 0, //是否有没维护的商品，0，没有，1有
      icdList: [],
      index0: "",
      icdname: "",
      reviewercode: false, //是否已阅读
      visible6: false,
      visible5: false,
      visible7: false,
      scrollTop: 0,
      docterName: "",
      selectdoctormiaosu: "",
      falseSex: [],
      selectcodetime: 0, //选择用药时间 1：饭前服用 2：饭后服用  3：睡前服用   4：晨起服用
      // selectmedsmethod: parseInt(localStorage.getItem("selectmedsmethod")) || 1, //1:水煎内服  2：水煎外用
      selectmedsmethod:localStorage.getItem("selectmedsmethod")|| [],
      chamedarr:
        localStorage.getItem("chamedarr") &&
        localStorage.getItem("chamedarr") != "undefined"
          ? JSON.parse(localStorage.getItem("chamedarr"))
          : [],
      showAll:false,//不显示所有的中药
      selectedValue: parseInt(localStorage.getItem("selectedValue")) || "",//用药时间
      chamedlists:[],//中药病情描述
      allarr:localStorage.getItem("allarr") || [],
      dayarr:localStorage.getItem("dayarr") || [],
      mlarr1:localStorage.getItem("mlarr1") || [],
      mlarr2:localStorage.getItem("mlarr2") || '150',
      methodarr:localStorage.getItem("methodarr") || [],
      zhongyaomeds:2,//表示中药
      medType: 1,//1西药  2中药
      meiday:localStorage.getItem("meiday")
      ? localStorage.getItem("meiday")
      : "一日",
      pickerVisible: false,
      imageUrl:localStorage.getItem("imageUrl") || '',
      isUploaded: localStorage.getItem("isUploaded") || false, // 标识图片是否已上传
      showPreview: false,
      currentPage: 1, // 当前页索引，默认为第 1 页
      pageSize: 20, 
      visible10:false,
      i:0,
      selectedUnit: "", // 记录选中的值
      units:localStorage.getItem("units")
      ? localStorage.getItem("units")
      : "ml",
      filteringArr:[],
      visible11:false,//用法用量选择弹窗提醒
      visiblecontype: false,//问诊提醒弹窗提醒
      patientsProvince:'',//就诊人所在省
      patientsCities:'',//就诊人所在市
      patientsCounty:'',//就诊人所在区
      pharmacyNameAll:'',
      chineseAuto:1,
      chineseUserSubmit:1,
      scrolledToBottom:false,//同意书是否滑动到底部
      timeNotice: 10,//同意告知书倒计时
      isRunningTime: false, // 倒计时状态
      noticeBtnState: true, //我已知晓 按钮状态:初始是禁用状态true,能用之后变为false （需要倒计时结束并且用户滑到底部才能变为false）
      filterNotice: false,//告知书权限是否开启，true开启，false未开启,
      isReadNotice:false,//未阅读告知书
    };
    this.useRef = React.createRef();
    this.inputRef = React.createRef();
    this.fileInputRef = React.createRef();
  }

  componentDidMount = async () => {
    const { patientId, selectmeds, defultUser,chamedarr,meiday,allarr } = this.state;
    // 查询店铺名称pharmacy_name
    const res20 = await selectPharmacyName({pharmacyId:localStorage.getItem("pharmacyId")})
    if(res20.code == 1 && res20.data && res20.data[0] && res20.data[0].length){
      this.setState({
        pharmacyNameAll:res20.data[0][0].pharmacy_name
      })
    }
    // 查询店铺所属区域
    const res16 = await selectPharmacyAear({pharmacyId:localStorage.getItem("pharmacyId")})
    if(res16.code == 1 && res16.data && res16.data[0] && res16.data[0].length){
      this.setState({
        patientsProvince:res16.data[0][0].province,
        patientsCities:res16.data[0][0].city,
        patientsCounty:res16.data[0][0].district
      })
    }

    const res12 = await selectFiltering({pharmacyId:localStorage.getItem("pharmacyId")})
    if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
      this.setState({
        filteringArr:res12.data[0]
      })
    }
    if (this.state.filteringArr.some(item => item.label === "复诊/是")) {
      this.setState({
        entitycode: 1
      });
    }

    if (this.state.filteringArr.some(item => item.label === "告知书")) {
      this.setState({
        selectcode: 1,
        filterNotice:true,
        isReadNotice:true
      });
    }else{
      this.setState({
        filterNotice:false
      })
    }

    if (!this.state.filteringArr.some(item => item.label === "问诊方式/图文")) {
      this.setState({
        videocode: 3
      });
    }
    
    if (!this.state.filteringArr.some(item => item.label === "药物过敏")) {
      this.setState({
        isadverse: 2,
        isuse:2
      });
    }

    const savedValue = localStorage.getItem("meiday");
    if (savedValue) {
      this.setState({ meiday: savedValue });
    }

    if (getUrl("selectmedsmethod", this.props.location.search)) {
      this.setState({
        selectmedsmethod: getUrl(
          "selectmedsmethod",
          this.props.location.search
        ),
      });
    }

    if (getUrl("medType", this.props.location.search)) {
      this.setState({
        medType: getUrl(
          "medType",
          this.props.location.search
        ),
      });
    }

    if (getUrl("illmiaosu", this.props.location.search)) {
      this.setState({
        miaosu: getUrl("illmiaosu", this.props.location.search),
      });
    }
    if (getUrl("storeagain", this.props.location.search)) {
      this.setState({
        storeagain: 1,
        coordinationcode: getUrl(
          "medicalNsurancecoordination",
          this.props.location.search
        ),
        videocode:
          getUrl("consultationType", this.props.location.search) == 1 ? 0 : 1,
      });
    }
    if (getUrl("medicalNsurancecoordination", this.props.location.search)) {
      this.setState({
        coordinationcode: getUrl(
          "medicalNsurancecoordination",
          this.props.location.search
        ),
      });
    }
    if (getUrl("coordinationcode", this.props.location.search)) {
      this.setState({
        coordinationcode: getUrl(
          "coordinationcode",
          this.props.location.search
        ),
      });
    }
    if (getUrl("docterId", this.props.location.search)) {
      this.setState({
        docterId: getUrl("docterId", this.props.location.search),
        docterName: getUrl("docterName", this.props.location.search),
        selectdoctor: 2,
        selectdoctormiaosu: localStorage.getItem("selectdoctormiaosu"),
      });
    }
    if (getUrl("entitycode", this.props.location.search)) {
      this.setState({
        reviewercode:
          getUrl("entitycode", this.props.location.search) == 1 ? true : false,
        entitycode: getUrl("entitycode", this.props.location.search),
      });
    }
    let arr = [];
    let arr1 = [];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        // params.name = i.medComName;
        params.name = i.medComName ? i.medComName : i.medName;
        params.spec = i.medSpec;
        arr.push(params);
      });
    }

    // 精神类药品
    let spiritarr = [];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        params.name = i.medName;
        params.spec = i.medSpec;
        spiritarr.push(params);
      });
    }

    // 注射剂药品
    let injectionarr = [];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        params.name = i.medName;
        params.spec = i.medSpec;
        injectionarr.push(params);
      });
    }

    // 新高危药品查询
    let highriskarr =[];
    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        params.name = i.medName;
        params.spec = i.medSpec;
        highriskarr.push(params);
      });
    }

    //高危药品查询、特殊药品
    let res01 = await getHighmeds({ allNews: arr });
    if (res01 && res01.data && res01.data.length) {
      this.setState({
        highMeds: res01.data[1],
        highMedsC: res01.data[0],
        specialMedicine: res01.data[2],
        highMedsArray: [...res01.data[0], ...res01.data[1], ...res01.data[2]],
        highMedslength:
          res01.data[1].length + res01.data[0].length + res01.data[2].length,
      });
    }

    // 精神类药品查询
    let res02 = await getSpiritmeds({ allNews: spiritarr });
    if (res02 && res02.data && res02.data.length) {
      this.setState({
        spiritMeds: res02.data[0],
        spiritMedsArray: [...res02.data[0]],
        spiritMedslength: res02.data[0].length,
      });
    }

    // 注射剂药品查询
    let res03=await getInjectionmeds({ allNews: injectionarr });
    if (res03 && res03.data && res03.data.length) {
      this.setState({
        injectionMeds: res03.data[0],
        injectionMedsArray: [...res03.data[0]],
        injectionMedslength: res03.data[0].length,
      });
    }

    // 新高危药品查询
    let res04=await getHighRiskmeds({ allNews: highriskarr });
    if (res04 && res04.data && res04.data.length) {
      this.setState({
        highriskMeds: res04.data[0],
        highriskMedsArray: [...res04.data[0]],
        highriskMedslength: res04.data[0].length,
      });
    }

    if (patientId) {
      let res = await defaultMember(patientId);
      if (res && res.data && res.data[0] && res.data[0].length) {
        let item = res.data[0][0];
        item.age = 1;
        let b = item.patient_birthday;
        let a =
          new Date(b).getFullYear() +
          "-" +
          (new Date(b).getMonth() + 1 < 10
            ? "0" + (new Date(b).getMonth() + 1)
            : new Date(b).getMonth() + 1) +
          "-" +
          (new Date(b).getDate() < 10
            ? "0" + new Date(b).getDate()
            : new Date(b).getDate());
        // 将出生日期的字符串通过"-"分割成数组
        const strBirthdayArr = a.split("-");
        // 拿到出生日期的年
        const birthYear = strBirthdayArr[0];
        // 拿到出生日期的月
        const birthMonth = strBirthdayArr[1];
        // 拿到出生日期的日
        const birthDay = strBirthdayArr[2];
        // 创建一个时间对象
        const d = new Date();
        // 拿到当前时间的年
        const nowYear = d.getFullYear();
        // 拿到当前时间的月
        const nowMonth = d.getMonth() + 1;
        // 拿到当前时间的日
        const nowDay = d.getDate();
        // 如果出生日期的年等于当前时间的年
        if (nowYear === birthYear) {
          item.age = 1;
        } else {
          // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
          const ageDiff = nowYear - birthYear; // 年之差
          // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
          // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
          // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
          if (nowMonth != birthMonth) {
            // 拿到出生日期的日与当前时间的月之差
            const monthDiff = nowMonth - birthMonth; // 月之差
            // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
            item.age = monthDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          } else {
            // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
            const dayDiff = nowDay - birthDay;
            // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
            item.age = dayDiff < 0 ? ageDiff - 1 : ageDiff; // 返回周岁年龄,并终止函数执行
          }
          if (item.age == 0) {
            item.age = 1;
          }
        }
        if (item.age < 0) {
          Toast.show("0岁以下儿童暂时无法添加");
        } else {
          this.setState(
            {
              defultUser: res.data[0][0],
              // coordinationcode:res.data[0][0].patient_id_card?1:2
            },
            () => {
              this.getallIlls();
            }
          );
        }

      }
    }
  };

  // 同意告知书倒计时
  startCountdown = () => {
    this.setState({ isRunningTime: true }); // 设置倒计时状态为正在进行
    this.interval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.timeNotice === 1) {
          clearInterval(this.interval); // 清除定时器
          return { isRunningTime: false, timeNotice: 0 }; // 倒计时结束，设置为 0
        }
        return { timeNotice: prevState.timeNotice - 1 }; // 减少倒计时时间
        // if (prevState.timeNotice <= 1) {
        //   clearInterval(this.interval); // 清除定时器
        //   return { isRunningTime: false }; // 倒计时结束
        // }
        // return { timeNotice: prevState.timeNotice - 1 }; // 减少倒计时时间
      });
    }, 1000); // 每秒更新一次
  };

  // 实现查找相同病情描述、不同病情描述、药品分类
  getallIlls = async () => {
    // debugger
    const { patientId, selectmeds, defultUser } = this.state;
    let arr = [];
    let arr1 = [];
    let falseSex = [];

    if (selectmeds.length) {
      selectmeds.forEach((i) => {
        let params = {};
        // params.name = i.medComName;
        params.name = i.medComName ? i.medComName : i.medName;
        params.spec = i.medSpec;
        params.classifyId = i.classifyId;
        arr.push(params);
      });
    }

    //查询药品对应疾病
    let res0 = await getAllills({
      allNews: arr,
      sex: defultUser ? (defultUser.patient_sex == "男" ? 1 : 2) : 3,
    });
    let againarr = [];
    let nArr1 = [];
    let againindex = [];
    let medsname = [];
    let othermedsname = [];
    let nomedsname = [];
    let allindex = [];
    var subset2 = [];
    let larr = [];
    let arr0 = [];

    if (res0.data && res0.data.length) {
      res0.data.forEach((i) => {
        allindex.push(i.index0);
      });
      // currentValue当前正在被处理的元素
      // currentIndex当前正在被处理的元素在数组中的索引
      // selfArr正在被处理的数组本身
      allindex = allindex.filter((currentValue, currentIndex, selfArr) => {
        return selfArr.findIndex((x) => x === currentValue) === currentIndex;
      });

      if (allindex.length < arr.length) {
        let arr2 = [];
        if (arr.length == 1) {
          arr2 = [0];
        } else if (arr.length == 2) {
          arr2 = [0, 1];
        } else if (arr.length == 3) {
          arr2 = [0, 1, 2];
        } else if (arr.length == 4) {
          arr2 = [0, 1, 2, 3];
        } else if (arr.length == 5) {
          arr2 = [0, 1, 2, 3, 4];
        }
        nArr1 = againarr.filter((currentValue, currentIndex, selfArr) => {
          return (
            selfArr.findIndex((x) => x.name === currentValue.name) ===
            currentIndex
          );
        });
        var set2 = new Set(allindex);
        var set1 = new Set(arr2);

        var subset = [];

        for (let item of set1) {
          // debugger
          if (!set2.has(item)) {
            subset.push(item);
          }
          if (set2.has(item)) {
            subset2.push(item);
          }
        }
        arr.forEach((item, v) => {
          subset.forEach((i) => {
            if (v == i) {
              nomedsname.push(item.name);
            }
          });
        });

        arr.forEach((item, v) => {
          subset2.forEach((i) => {
            if (v == i) {
              larr.push(item);
            }
          });
        });
        nomedsname = nomedsname.filter(
          (currentValue, currentIndex, selfArr) => {
            return (
              selfArr.findIndex((x) => x === currentValue) === currentIndex
            );
          }
        );

        arr0 = larr.filter((currentValue, currentIndex, selfArr) => {
          return (
            selfArr.findIndex((x) => x.name === currentValue.name) ===
            currentIndex
          );
        });

      }
      if (arr.length > 1 && res0.data.length) {
        for (let i = 0; i < res0.data.length; i++) {
          for (let j = 1; j < res0.data.length; j++) {
            if (
              res0.data[i].icd_id == res0.data[j].icd_id &&
              i != j &&
              res0.data[i].index0 != res0.data[j].index0
            ) {
              res0.data[j].state = res0.data[i].state;
              againarr.push(res0.data[j]);
            }
          }
        }
        againarr.map((item) => {
          item.data = 1;
          againindex.push(item.index0);
          againindex.push(item.state);
        });
        let nArr2 = againindex.filter((currentValue, currentIndex, selfArr) => {
          return selfArr.findIndex((x) => x === currentValue) === currentIndex;
        });
        if (nArr2.length == 0) {
          //没有相同病症
          if (allindex.length == arr.length) {
            arr.forEach((i, v) => {
              othermedsname.push({ name: i.name, index: v });
            });
            nArr1 = res0.data.filter((currentValue, currentIndex, selfArr) => {
              return (
                selfArr.findIndex((x) => x.name === currentValue.name) ===
                currentIndex
              );
              // return true;
            });
          } else {
            if (subset2.length) {
              arr.forEach((item, v) => {
                subset2.forEach((i) => {
                  if (v == i) {
                    othermedsname.push({ name: item.name, index: v });
                  }
                });
              });
              othermedsname = othermedsname.filter(
                (currentValue, currentIndex, selfArr) => {
                  return (
                    selfArr.findIndex((x) => x.name === currentValue.name) ===
                    currentIndex
                  );
                }
              );
              nArr1 = res0.data.filter(
                (currentValue, currentIndex, selfArr) => {
                  return true;
                }
              );
            }
          }
        } else if (nArr2.length == arr.length) {
          //全部药品都含有相同病症

          arr.forEach((i) => {
            medsname.push(i.name);
          });
          nArr1 = res0.data;
        } else if (nArr2.length && nArr2.length < arr.length) {
          //部分药品含有相同病症
          arr.forEach((i, v) => {
            nArr2.forEach((j) => {
              if (v == j) {
                medsname.push(i.name);
              }
            });
          });

          medsname = medsname.filter((currentValue, currentIndex, selfArr) => {
            return (
              selfArr.findIndex((x) => x === currentValue) === currentIndex
            );
          });
          let arr2 = [];
          if (arr.length == 2) {
            arr2 = [0, 1];
          } else if (arr.length == 3) {
            arr2 = [0, 1, 2];
          } else if (arr.length == 4) {
            arr2 = [0, 1, 2, 3];
          } else if (arr.length == 5) {
            arr2 = [0, 1, 2, 3, 4];
          }
          nArr1 = againarr.filter((currentValue, currentIndex, selfArr) => {
            return (
              selfArr.findIndex((x) => x.name === currentValue.name) ===
              currentIndex
            );
          });
          var set2 = new Set(nArr2);
          var set1 = new Set(arr2);

          var subset3 = [];
          var subset4 = [];

          for (let item of set1) {
            if (!set2.has(item)) {
              subset3.push(item);
            }
          }

          let arr4 = [];
          arr.forEach((i, v) => {
            subset3.forEach((j) => {
              if (nomedsname && nomedsname.length) {
                nomedsname.forEach((k) => {
                  if (v == j && i.name != k) {
                    // othermedsname.push({name: i.name, index: v})
                    arr4.push(v);
                  }
                });
              } else {
                if (v == j) {
                  othermedsname.push({ name: i.name, index: v });
                  // arr4.push(v)
                }
              }
            });
          });
          if (nomedsname && nomedsname.length) {
            var set4 = new Set(subset);
            var set3 = new Set(arr4);
            for (let item of set3) {
              if (!set4.has(item)) {
                subset4.push(item);
              }
            }
            arr.forEach((k, v) => {
              subset4.forEach((i) => {
                if (i == v) {
                  othermedsname.push({ name: k.name, index: v });
                }
              });
            });
          }
          othermedsname = othermedsname.filter(
            (currentValue, currentIndex, selfArr) => {
              return (
                selfArr.findIndex((x) => x.name === currentValue.name) ===
                currentIndex
              );
            }
          );
          let arr3 = [];
          res0.data.forEach((item) => {
            subset3.forEach((i) => {
              if (item.index0 == i) {
                arr3.push(item);
              }
            });
          });
          let nArr3 = arr3.filter((currentValue, currentIndex, selfArr) => {
            return (
              selfArr.findIndex((x) => x.name === currentValue.name) ===
              currentIndex
            );
          });
          nArr1 = res0.data;
        }
      } else if (arr.length == 1 && res0.data.length) {
        othermedsname.push({ name: arr[0].name, index: 0 });
        nArr1 = res0.data.filter((currentValue, currentIndex, selfArr) => {
          return (
            selfArr.findIndex((x) => x.name === currentValue.name) ===
            currentIndex
          );
        });
      }
      this.setState({
        allbeginMeds: medsname,
        allotherMeds: othermedsname,
        isnonews: nomedsname.length ? nomedsname.length : 0,
        onewsMeds: nomedsname,
        allMeds: res0.data,
      });

      let arrayill = [];
      nArr1.forEach((i) => {
        let params1 = {};
        params1.index = 1;
        if (getUrl("consultationDetail", this.props.location.search)) {
          arrayill = getUrl(
            "consultationDetail",
            this.props.location.search
          ).split(",");
          arrayill.forEach((j) => {
            if (j == i.name) {
              params1.index = 2;
            }
          });
        }
        params1.value = i.name;
        let id0 = {};
        id0.icdName = JSON.parse(i.icd_id)[0].icd_name;
        id0.icdValue = JSON.parse(i.icd_id)[0].icd_value;
        params1.id = id0;
        params1.index0 = i.index0;
        params1.data = i.data ? 1 : 0;
        params1.dis_id = i.dis_id;
        params1.sex_flag = i.sex_flag;
        arr1.push(params1);
      });
      let code0 = 1;
      if (localStorage.getItem("listicd")) {
        if (
          JSON.parse(localStorage.getItem("listicd")).length == nArr1.length
        ) {
          arr1.forEach((i) => {
            JSON.parse(localStorage.getItem("listicd")).forEach((j) => {
              if (i.icdName != j.icdName) {
                code0 = 2;
              }
            });
          });
        } else {
          code0 = 2;
        }
      } else if (getUrl("consultationDetail", this.props.location.search)) {
        code0 = 1;
        localStorage.setItem("listicd", JSON.stringify(arr1));
      } else {
        code0 = 2;
      }
      this.setState(
        {
          list:[]
            // code0 == 2 || getUrl("storeagain", this.props.location.search)
            //   ? arr1
            //   : localStorage.getItem("listicd") &&
            //     JSON.parse(localStorage.getItem("listicd")),
        },
        () => {
          if (code0 == 1) {
            let arr2 = [];
            let arr3 = [];
            let list0 = localStorage.getItem("listicd")
              ? JSON.parse(localStorage.getItem("listicd"))
              : arr1;
            list0.map((item) => {
              if (item.index == 2) {
                arr2.push(item.value);
                arr3.push(item.id);
              }
            });
            let nArr10 = arr3.filter((currentValue, currentIndex, selfArr) => {
              return (
                selfArr.findIndex(
                  (x) => x.icdValue === currentValue.icdValue
                ) === currentIndex
              );
            });
            this.setState({ listchose: arr2, icdlists: nArr10 });
          }
        }
      );
    } else {
      arr.forEach((item, v) => {
        nomedsname.push(item.name);
      });
      this.setState({
        isnonews: nomedsname.length ? nomedsname.length : 0,
        onewsMeds: nomedsname,
      });
    }

    // 查询药品对应的所有疾病
    let res10 = await getMedsills({
      allNews: arr,
    });
    
    if(arr.length){
      arr.forEach((item,i)=>{
        if(res10.data.length == 0){
          if((defultUser && defultUser.patient_sex === '女' && (item.classifyId === 5 || item.classifyId === 7 )) ||
          (defultUser && defultUser.patient_sex === '男' && (item.classifyId === 6 || item.classifyId === 8 ))){
            falseSex.push(item.name)
          }
        }else{
          res10.data.forEach((el,j)=>{
            if(i == el.index0 || 
              (defultUser && defultUser.patient_sex === '女' && (item.classifyId === 5 || item.classifyId === 7 )) ||
              (defultUser && defultUser.patient_sex === '男' && (item.classifyId === 6 || item.classifyId === 8 ))
              ){
              falseSex.push(item.name)
            }

          })
        }
      })
      this.setState({
        falseSex,
      });
    }
  };
  
  fuzhi = (e) => {
    const { listchose, miaosu } = this.state;
    this.setState(
      {
        miaosu: e.replace(/\s*/g, ""),
      },
      () => {}
    );
    localStorage.setItem("miaosu", e.replace(/\s*/g, ""));
  };
  toSelect = () => {
    const { selectcode,filterNotice,isReadNotice } = this.state 
    if(selectcode == 0 && filterNotice == false){
      this.setState({
        visible2:true
      })
      if(isReadNotice==false){
        this.startCountdown();
      }
    }
    this.setState({
      selectcode: this.state.selectcode == 0 ? 1 : 0,
    });
  };

  // 就诊凭证上传
  handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (!file || !file.type.startsWith('image/')) {
      // 如果选择的文件不是图片类型，给出错误提示
      Toast.show('仅支持图片上传')
      return;
    }

    zipImg(file).then(res => {
      let formData = new FormData()
      formData.append('file', res)
      uploadpic(formData).then(res1 => {
          if(res1&&res1.code == 2) {
            const imageUrl = res1.data; // 从服务器返回的数据中获取上传后的图片地址
            localStorage.setItem("imageUrl", imageUrl); 
            this.setState({ imageUrl , isUploaded: true }); // 将图片地址保存到组件状态中
            localStorage.setItem("isUploaded", true); 
          }
      }).catch(err => {
        // console.error(err,123,'931=====');
      });
    })

  };

  handleYlimg =() =>{
    const {imageUrl} = this.state;
    this.setState(prevState => ({
      showPreview: !prevState.showPreview
    }));

    if (this.state.showPreview) {
      this.setState({
        showPreview: false
      });
    }


  }

  handleJzimg =() =>{
    const {imageUrl} = this.state;
    localStorage.removeItem("imageUrl");
    localStorage.removeItem("isUploaded");
    this.setState({
      imageUrl:'',
      isUploaded: false,
    })
  }


  handleInputChange = (e, arrayName) => {
    const value = e.target.value;
    const pattern = /^[0-9]{0,4}$/;
  
    if (value === "") {
      this.setState({ [arrayName]: [] });
    } else if (pattern.test(value) && parseInt(value) > 0) {
      this.setState({ [arrayName]: [value] }, () => {
        localStorage.setItem(arrayName, value);
      });
    } else {
      e.target.value = "";
    }
  };

  getvalue=(e)=>{
    const {units} =this.state
    const value = e;
    this.setState({
      units:value
    },()=>{
      localStorage.setItem("units", value);
    })
  }

  // 关闭自填用药弹窗
  handleSure=(e)=>{
    const { units } = this.state
    if(e !== 'ml'){
      this.setState({
        visible11:true
      })
    }
    this.setState({
      visible10:false
    })
  }


// 用药方法
  clickMethod = (value) => {
    let selectmedsmethod;
    let methodarr;
  
    if (value === 1) {
      selectmedsmethod = "水煎内服";
      methodarr = "";
    } else if (value === 2) {
      selectmedsmethod = "水煎外用";
      methodarr = "";
    } else {
      selectmedsmethod = this.state.methodarr;
      methodarr = this.state.methodarr;
    }
  
    this.setState(
      {
        selectmedsmethod: selectmedsmethod,
        methodarr: methodarr,
      },
      () => {
        localStorage.setItem("selectmedsmethod", selectmedsmethod);
        localStorage.setItem("methodarr", methodarr);
      }
    );
  }
  
  handleInputMethod = (e) => {
    const value = e.target.value;

    setTimeout(() => {
      // 只在输入中文时更新 methodarr
      const methodarr = value.replace(/[^\u4e00-\u9fa5]/g, '').slice(0, 6);
  
      this.setState({
        methodarr: methodarr,
        selectmedsmethod: methodarr,
      }, () => {
        localStorage.setItem("methodarr", methodarr);
        localStorage.setItem("selectmedsmethod", methodarr);
      });
    }, 100); // 设置延时500ms，等待用户输入完成
  }

  // 选择视频问诊
  toSelectvideo = () => {
    this.setState({
      videocode: 1,
      visiblecontype:true
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };
  // 选择图文问诊
  toSelecttuwen = () => {
    this.setState({
      videocode: 0,
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };
  // 弹窗选择视频问诊
  toSelectvideotype = () =>{
    this.setState({
      videocode: 1,
      visiblecontype:false
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };
  // 弹窗选择图文问诊
  toSelecttuwentype = () =>{
    this.setState({
      videocode: 0,
      visiblecontype:false
    },()=>{
      localStorage.setItem("videocode",this.state.videocode)
    });
  };

  toAddpatient = () => {
    const {
      coordinationcode,
      miaosu,
      entitycode,
      selectdoctor,
      docterName,
      docterId,
      medType,
      selectmedsmethod
    } = this.state;
    if (selectdoctor == 2) {
      if(medType == 2){
        this.props.history.replace(
          `/patientinformationzhong?coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterName=${docterName}&docterId=${docterId}`
        );
      }else{
        this.props.history.replace(
          `/patientinformation?coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
        );
      }
      if(medType == 2){
        this.props.history.push(
          `/newpatient?illmiaosu=${miaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterName=${docterName}&docterId=${docterId}`
        );
      }else{
        this.props.history.push(
          `/newpatient?illmiaosu=${miaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterName=${docterName}&docterId=${docterId}`
        );
      }
    } else {
      if(medType == 2){
        this.props.history.replace(
          `/patientinformationzhong?coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
        );
      }else{
        this.props.history.replace(
          `/patientinformation?coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
        );
      }
      this.props.history.push(
        `/newpatient?illmiaosu=${miaosu}&coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
      );
    }
    // window.history.go(0)
  };
  toTips = () => {
    const {
      defultUser,
      listchose,
      coordinationcode,
      allcount,
      isuse,
      isadverse,
      selectmeds,
      entitycode,
      miaosu,
      selectcode,
      spiritMedslength, //精神类药品数量
      onewsMeds,
      onewsMeds1,
      list,
      falseSex,
      selectcodetime,
      allarr,
      dayarr,
      mlarr1,
      mlarr2,
      selectmedsmethod,
      selectedValue,
      methodarr
    } = this.state;


    // 判断用法用量是否为null
    let usage1 = {
      allarr,
      dayarr,
      mlarr1,
      mlarr2,
      selectmedsmethod,
      selectedValue,
    }

     // 男性/女性用药提醒
     let onewsMeds2 = [];
     onewsMeds.map((i) => {
       onewsMeds2.push({ value: i, index: 0 });
     });
     onewsMeds2.map((i) => {
       falseSex.map((j) => { 
         if (i.value == j) {
           i.index = 1;
         }
       });
     });

    let tiparr = [];
    onewsMeds2.map((i)=>{
      if(i.index == 1){
        tiparr.push(i.value);
      }
    }) 

    console.log(
      listchose.length
        ? listchose.toString() + (miaosu ? ";" + miaosu : "")
        : miaosu
    );

    let params = {
      miaosu: listchose.length
        ? listchose.toString() + (miaosu ? ";" + miaosu : "")
        : miaosu, //病情描述
      // miaosu: 
      selectcode, //是否勾选风险告知书 1是  0否
      isuse, //是否服用过 1是 0否
      isadverse: isadverse == 0 ? true : false, //是否有不良反应 1有 0无
      defultUser,
      onesmedsarr:onewsMeds2
    };

    if(tiparr.length){
      if (onewsMeds2.length > 0 ) { 
        let tiparr = [];
        onewsMeds2.map((i)=>{
          if(i.index == 1){
            tiparr.push(i.value);
          }
        })
        const gender = defultUser.patient_sex === "男" ? "女性" : "男性";
        const medication = tiparr.join(", ");
        const message = `【${medication}】为${gender}用药，请删除重新选药问诊`;
        if(medication){
          Toast.info(message);
        }
        return;
      }
    }else{
      if (params.miaosu) {
        if (entitycode == 1) {
          if (selectcode) {
            if (isuse == 0 && isadverse == 0) {
              if (defultUser) {
              } else {
                Toast.show("请先选择用药人");
              }
            } else {
              Toast.show("病史资料异常，请去医疗机构完成就诊");
            }
          } else {
            Toast.show("请先阅读并勾选风险告知书");
          }
        } else {
          this.useRef.current.scrollTop = 0;
          Toast.show(
            "是否已在实体医院就诊，本次为复诊？选择是才能在本平台开方，选择否请到线下实体医院就诊。"
          );
        }
      } else {
        Toast.show("请选择病情描述");
      }
    }
      
      
  };
  //下一步，去咨询医生
  nextStep = async () => {

    isClick = true
    const {
      tips,
      coordinationcode,
      highMedslength,
      highMeds,
      highMedsC,
      specialMedicine,
      allotherMeds,
      selectmeds,
      defultUser,
      onewsMeds,
      list,
      spiritMedslength, //精神类药品数量
      injectionMeds,//注射剂
      injectionMedslength,//注射剂数量
      falseSex,
      onewsMeds1,
      chamedarr,
      allarr,
      dayarr,
      mlarr1,
      mlarr2,
      selectmedsmethod,
      selectedValue,
      methodarr,
      filteringArr,
      videocode,
      chineseAuto,
      chineseUserSubmit
    } = this.state;
    
    if(defultUser.age<7 && defultUser.age>=0){
      if(!defultUser.guardianName){
        Toast.show('患者年龄小于7岁，请先添加患者监护人信息')
        return
      }else if(!defultUser.guardianIdcardNum){
        Toast.show('患者年龄小于7岁，请先添加患者监护人信息')
        return
      }else if(!defultUser.relationship){
        Toast.show('患者年龄小于7岁，请先患者与您的关系')
        return
      }else if(!defultUser.bodyweight){
        Toast.show('患者年龄小于7岁，请先添加患者的体重')
        return
      }
    }

    // 找到选中的疾病
    const choseList = list.find(item => item.index == 2);

    // 再从选中的疾病中判断是否有急性病存在
    if (choseList && list.some(item => item.index == 2 && item.is_acute == 1)) {
      Toast.show("当前用药人处于急性发作期，建议线下就诊或拨打急救电话，避免错过最佳救助时机");
      return;
    }

    if (this.state.filteringArr.some(item => item.label === "手机号") &&
        this.state.filteringArr.some(item => item.label === "身份证号码")) {
      if (defultUser.patient_phone == null && defultUser.patient_id_card == null) {
        Toast.show("根据相关规定，需要填写患者完整的身份证号码/手机号才能提交，请前往患者信息填写");
        return;
      }
    }
    if (this.state.filteringArr.some(item => item.label === "身份证号码")) {
      if (defultUser.patient_id_card == null) {
        Toast.show("根据相关规定，需要填写患者完整的身份证号码才能提交，请前往患者信息填写");
        return;
      }
    }
    if (this.state.filteringArr.some(item => item.label === "手机号")) {
      if (defultUser.patient_phone == null) {
        Toast.show("根据相关规定，需要填写患者完整的手机号才能提交，请前往患者信息填写");
        return;
      }
    }

    if(videocode == 3){
      Toast.show("请选择问诊方式")
      return;
    }

    
    if(!allarr.length || !dayarr.length || !mlarr1.length || !mlarr2.length || selectedValue == "" || !selectmedsmethod.length){
      this.useRef.current.scrollTop = 500;
      Toast.info("请填写用法用量");
      return;
    }

    let arraymed = []
    chamedarr.forEach((item,key) => {
      arraymed.push({
        medId: item.pro_id,
        medName: item.pro_name ? item.pro_name : item.pro_com_name,
        medApproval: item.pro_approval ? item.pro_approval : null,
        medCompany: item.pro_company ? item.pro_company : null,
        medPrice: item.pro_price ? item.pro_price : 0,
        medQuantity: item.jiacountValue ? item.jiacountValue : 0,
        medComName: item.pro_com_name ? item.pro_com_name : item.pro_name,
        medSpec: item.pro_spec ? item.pro_spec : null,
        contacts: localStorage.getItem("contacts"),
        supervision_type: 1,
        packingUnit: item.packing_unit ? item.packing_unit : null,
        medPacking: item.medPacking ? item.medPacking : null,
        libraryId: item.libraryId ? item.libraryId : "0",
        medEnterprise: item.medEnterprise ? item.medCompany : null,
        thirdPartyId: item.thirdPartyId ? item.thirdPartyId : "0",
        medQrcode: item.medQrcode ? item.medQrcode : null,
        medDosageForm: item.medDosageForm ? item.medDosageForm : null,
        medType: item.medType ? item.medType : null,
        medApplySymptoms: item.medApplySymptoms ? item.medApplySymptoms : null,
        isOtc: 2,
        supervisionType: 1,
        serial_number:0,
      })
    })
    let restaboo = await chineseMedsTaboo({ array1:arraymed })

    if(restaboo.data && restaboo.data.chineseAuto){
      this.setState({
        chineseAuto:restaboo.data.chineseAuto
      })
    }

    if(restaboo.data.twoBind && restaboo.data.twoBind.length > 0){
      let combinedStrings = restaboo.data.twoBind.map(item => {
        return `${item.medComNameb}与${item.med_com_name}；`;
      })
      alert('中药配伍禁忌',
        <div>
          <div style={{ textAlign:'left',fontSize:'14px'}}>
            根据中药配伍禁忌十八反十九畏，以下药品有冲突：
          </div>
          <div style={{ color:'red',textAlign:'left',fontSize:'14px'}}>
            {combinedStrings}
          </div>
        </div>,[
        {
          text: "取消",
          onPress: () => {
            this.setState({
              tips: 0,
            });
          },
        },
        {
          text: "继续提交",
          onPress: () => {
            this.setState({
              chineseUserSubmit: 2
            })
            this.handleSubmitTip()
          },
        },
      ])
    }else{
      this.handleSubmitTip()
    }
   
  };

  // 确认提交
  handleSubmitTip = async ()=>{
    const {
      tips,
      coordinationcode,
      highMedslength,
      highMeds,
      highMedsC,
      specialMedicine,
      allotherMeds,
      selectmeds,
      defultUser,
      onewsMeds,
      list,
      spiritMedslength, //精神类药品数量
      injectionMeds,//注射剂
      injectionMedslength,//注射剂数量
      falseSex,
      onewsMeds1,
      chamedarr,
      allarr,
      dayarr,
      mlarr1,
      mlarr2,
      selectmedsmethod,
      selectedValue,
      methodarr,
      filteringArr,
      videocode,
      chineseAuto
    } = this.state;


    if(selectmedsmethod != '水煎内服' && selectmedsmethod != '水煎外用'){
      this.setState({
        chineseAuto:2
      })
    }

    if (tips) {
      Toast.info("请勿重复提交");
    } else {
      let arrzy= [];
      let arr = [];
      let arr1 = [];
      let tbclass = []; //头孢类
      let qmsclass = []; //青霉素类  头孢类与青霉素类不能一起开
      let sxclass = []; //沙星类
      let tnbclass = []; //糖尿病类  沙星类与糖尿病类不能一起开
      let kjxyclass = []; //抗菌消炎类的同类不能一起开
      let qrclass = []; //清热类（二类）
      let byclass = []; //补益类   清热类（二类）与补益类不能一起开
      let ansbnclass = []; //安神补脑类  清热类（二类）与安神补脑类不能一起开

      list.map((i) => {
        if (i.index == 2) {
          arr.push(i.index0);
          arr1.push(i.id);
        }
      });

      let medIcdCountMap = {};
      list.map((i) => {
        if (i.index == 2) {
          const medName = i.index0;
          const icdValue = i.id;

          if (medIcdCountMap.hasOwnProperty(medName)) {
            medIcdCountMap[medName]++;
          } else {
            medIcdCountMap[medName] = 1;
          }
        }
      });

      // 判断是否有某个药品对应的 icd 数量大于 3
      const exceedMsg = Object.keys(medIcdCountMap).find(
        (medName) => medIcdCountMap[medName] > 3
      );
      if (exceedMsg) {
        Toast.show("单个药品病情描述不能超过3个，请删减");
        return;
      }


      arr = arr.filter((currentValue, currentIndex, selfArr) => {
        return selfArr.findIndex((x) => x === currentValue) === currentIndex;
      });
      arr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
          currentIndex
        );
      });

      chamedarr.map((i)=>{
        arrzy.push(i.pro_name)
      })
      

      let res0;
      if (defultUser.patient_id_card) {
        res0 = await isHightmed({
          patientIdcardNum: defultUser.patient_id_card,
        });
      }
      
      // 16岁以下不能提交问诊信息
      // if(defultUser.age < 16){
      //   Toast.show("16岁以下不能提交问诊信息");
      //   return;
      // }

      // 男性/女性用药提醒
      let onewsMeds2 = [];
      onewsMeds.map((i) => {
        onewsMeds2.push({ value: i, index: 0 });
      });
      onewsMeds2.map((i) => {
        falseSex.map((j) => { 
          if (i.value == j) {
            i.index = 1;
          }
        });
      });

      let tiparr = [];
      onewsMeds2.map((i)=>{
        if(i.index == 1){
          tiparr.push(i.value);
        }
      }) 


      if(tiparr.length){
        if (onewsMeds2.length > 0 ) { 
          let tiparr = [];
          onewsMeds2.map((i)=>{
            if(i.index == 1){
              tiparr.push(i.value);
            }
          })
          const gender = defultUser.patient_sex === "男" ? "女性" : "男性";
          const medication = tiparr.join(", ");
          const message = `【${medication}】为${gender}用药，请删除重新选药问诊`;
          if(medication){
            Toast.info(message);
          }
          return;
        }
      }else{
        if (arrzy.length < selectmeds.length) {
          Toast.info("所有药品都必须选择病情描述");
          // return;
        } else {
          // if (arr1.length < selectmeds.length) { 
            let kjxyclass0 = [];
            let kjxyclass1 = [];
            let kjxyclass2 = [];

              let containsHighMeds = false;
              let containsSpecial = false;


              if ((coordinationcode == 1 && defultUser.patient_id_card) || coordinationcode == 2) {
                if (coordinationcode == 1) {
                  let allarray = [];
                  if (selectmeds.length) {
                    selectmeds.forEach((i) => {
                      let params = {};
                      // params.medName = i.medComName;
                      params.medName = i.medComName ? i.medComNamen :i.medName;
                      params.medSpec = i.medSpec;
                      params.medPrice = i.medPrice;
                      params.medCompany = i.medCompany;
                      allarray.push(params);
                    });
                  }

                  //采集药品查询
                  let res = await getCollectmedicinal({ allNews: allarray });

                  if (res && res.data && res.data.length) {
                    alert(
                      "提示",
                      "【" +
                        res.data.toString() +
                        "】 不属于门诊统筹范围，不在报销范围，是否继续购买？",
                      [
                        {
                          text: "取消",
                          onPress: () =>
                            this.setState({
                              tips: 0,
                            }),
                          style: "default",
                        },
                        {
                          text: "确定",
                          onPress: async () => {
                            this.setState(
                              {
                                tips: 1,
                              },
                              async () => {
                                this.toAwaitDoc();
                              }
                            );
                          },
                        },
                      ]
                    );
                  } else {
                    this.setState(
                      {
                        tips: 1,
                      },
                      async () => {
                        this.toAwaitDoc();
                      }
                    );
                  }
                } else {
                  this.setState(
                    {
                      tips: 1,
                    },
                    async () => {
                      this.toAwaitDoc();
                    }
                  );
                }
              } else {
                this.setState({
                  tips: 0,
                });
                Toast.info("医保统筹需填写身份证号码");
              }


                
              // }
        }
      }

      
    }
  }



  //创建订单和问诊单（无医生id）
  toAwaitDoc = async () => {
    if(isClick){
      const {
        defultUser,
        index,
        icdlists,
        supervisionType,
        videocode,
        docterId,
        docterName,
        patientId,
        patientPhone,
        pharmacyId,
        selectdoctor,
        coordinationcode,
        isadverse,
        highMedsArray,
        selectmeds,
        miaosu,
        highMeds,
        highMedsC,
        highMedslength,
        list,
        specialMedicine,
        listchose,
        chamedarr,
        allarr,
        dayarr,
        mlarr1,
        mlarr2,
        selectmedsmethod,
        selectedValue,
        meiday,
        imageUrl,
        methodarr,
        unit,
        units,
        patientsProvince,
        patientsCities,
        patientsCounty,
        pharmacyNameAll,
        chineseAuto,
        chineseUserSubmit,
        tips
      } = this.state;


      if(list.length == 0){
        Toast.info("请选择疾病描述");
        this.setState({
          tips:0
        })
        return 
      }

      let usage = {
        co_demand:parseInt(allarr[0]),
        meiday:meiday,
        daily:parseInt(dayarr[0]),
        each_dose:parseInt(mlarr1[0]),
        at_time:parseInt(mlarr2),
        med_method:selectmedsmethod,
        med_time:parseInt(selectedValue),
        unit:units,
      }
 
      let electronicIcd = JSON.stringify(icdlists);
  
      let params = {
        userId: parseInt(patientId),
        orderShipping: "1",
        phone: patientPhone,
        pharmacyId: parseInt(pharmacyId),
        orderPrice: 0,
        orderAmount: 0,
        // supervisionType: parseInt(supervisionType),
        supervisionType: 1,  //4表示中药材
      };

      let listchose0 = [];
      listchose0 = listchose.filter((currentValue, currentIndex, selfArr) => {
        return selfArr.findIndex((x) => x === currentValue) === currentIndex;
      });

      let array4 = [];
      let array1 = [];
      let array2 = [];
      let array3 = [];
      let classifyId0 = "";
      let codeId = 1;
      if (defultUser.patient_sex == "男") {
        classifyId0 = 7;
      } else {
        classifyId0 = 8;
      }
      let code0 = 1;
      let code1 = 2;
      let code2 = 2;
      let code3 = 2;
      let count2 = 0;
      let childrencode = 0;
  
      let highMedscount = 0;
  
      let highountArr=[];
      let highcCountArr=[];
      let specialCountArr=[];

      chamedarr.forEach((item,key) => {
        let icdId = {};
        let disease = '';
        let disId = '';
        list.map((i) => {
          if (i.index == 2 && i.index0 == key) {
            icdId.icd_name = i.id.icdName;
            icdId.icd_value = i.id.icdValue;
            disease=i.value;
            disId=i.dis_id;
            return;
          }
        });

        array4.push({
          medId: item.pro_id,
          medName: item.pro_name ? item.pro_name : item.pro_com_name,
          medApproval: item.pro_approval ? item.pro_approval : null,
          medCompany: item.pro_company ? item.pro_company : null,
          medPrice: item.pro_price ? item.pro_price : 0,
          medQuantity: item.jiacountValue ? item.jiacountValue : 0,
          medComName: item.pro_com_name ? item.pro_com_name : item.pro_name,
          medSpec: item.pro_spec ? item.pro_spec : null,
          contacts: localStorage.getItem("contacts"),
          supervision_type: 1,
          packingUnit: item.packing_unit ? item.packing_unit : null,
          icdId:icdId,
          disease:disease,
          medPacking: item.medPacking ? item.medPacking : null,
          libraryId: item.libraryId ? item.libraryId : "0",
          medEnterprise: item.medEnterprise ? item.medCompany : null,
          thirdPartyId: item.thirdPartyId ? item.thirdPartyId : "0",
          medQrcode: item.medQrcode ? item.medQrcode : null,
          medDosageForm: item.medDosageForm ? item.medDosageForm : null,
          medType: item.medType ? item.medType : null,
          medApplySymptoms: item.medApplySymptoms ? item.medApplySymptoms : null,
          isOtc: 2,
          disId:disId,
          supervisionType: 1,
          serial_number:0,
        })
      })


      selectmeds.forEach((item,key) => {
  
        if (specialMedicine.length) {
          specialMedicine.forEach((i) => {
            if (item.medComName.replace(/^\s*|\s*$/g, "") == i.replace(/^\s*|\s*$/g, "")) {
              specialCountArr.push({
                medComName: item.medComName,
                count: item.count
              });
            }
          });
        }
        if (highMeds.length) {
          highMeds.forEach((i) => {
            if (item.medComName.replace(/^\s*|\s*$/g, "") == i.replace(/^\s*|\s*$/g, "")) {
              highountArr.push({
                medComName: item.medComName,
                count: item.count
              });
            }
          });
        }
        if (highMedsC.length) {
          highMedsC.forEach((i) => {
            if (item.medComName.replace(/^\s*|\s*$/g, "") == i.replace(/^\s*|\s*$/g, "")) {
              highcCountArr.push({
                medComName: item.medComName,
                count: item.count
              });
            }
          });
        }

        
        if (highMedsArray.length) {
          highMedsArray.forEach((i) => {
            if (
              item.medComName.replace(/^\s*|\s*$/g, "") ==
              i.replace(/^\s*|\s*$/g, "")
            ) {
              highMedscount += item.count;
            }
          });
        }
        if (
          item.classifyId == 56 ||
          item.classifyId == 6 ||
          item.classifyId == 9 ||
          item.classifyId == 10 ||
          item.classifyId == 105 
        ) {
          code1 = 1;
        }
        if (item.classifyId == 114) {
          //注射药品
          code2 = 1;
          count2 += 1;
        }
        if (item.classifyId == 9) {
          //儿童用药
          childrencode = 1;
        }
        if (item.classifyId == 11 || item.classifyId == 116) {
          //内科
          code3 = 1;
        }
        let a = item.medComName + item.medSpec;
        array2.push(a);
  
        let icdId = {};
        let disease = '';
        let disId = '';
        list.map((i) => {
          if (i.index == 2 && i.index0 == key) {
            icdId.icd_name = i.id.icdName;
            // icdId.icd_name = i.value;
            icdId.icd_value = i.id.icdValue;
            disease=i.value;
            disId=i.dis_id;
            return;
          }
        });
        array3.push({
          medComName: item.medComName,
          medSpec: item.medSpec,
          classifysonName: item.classifysonName,
          medQuantity: item.count,
          icdId: JSON.stringify([icdId]),
        });
       
  
        array1.push({
          medId: item.medId,
          medName: item.medName ? item.medName : item.medComName,
          medSpec: item.medSpec ? item.medSpec : null,
          medPacking: item.medPacking ? item.medPacking : null,
          medApproval: item.medApproval ? item.medApproval : null,
          medCompany: item.medCompany ? item.medCompany : null,
          medPrice: item.medPrice ? item.medPrice : 0,
          medQuantity: item.count,
          libraryId: item.libraryId ? item.libraryId : "0",
          medEnterprise: item.medEnterprise ? item.medCompany : null,
          thirdPartyId: item.thirdPartyId ? item.thirdPartyId : "0",
          medQrcode: item.medQrcode ? item.medQrcode : null,
          medComName: item.medComName ? item.medComName : item.medName,
          medDosageForm: item.medDosageForm ? item.medDosageForm : null,
          medType: item.medType ? item.medType : null,
          contacts: localStorage.getItem("contacts"),
          medApplySymptoms: item.medApplySymptoms ? item.medApplySymptoms : null,
          supervisionType: parseInt(item.supervisionType),
          packingUnit: item.packingUnit ? item.packingUnit : null,
          isOtc: item.medClassify == "1" ? 2 : 1,
          icdId:icdId,
          disease:disease,
          disId:disId,
        });

      });

  
      let res0 = await allNowdayorders(localStorage.getItem("jwtTokenh5"));
      isClick = false
      if (res0 && res0.data && res0.data && res0.data.length) {
        res0.data.map((item) => {
          if (
            item.patientName == defultUser.patient_name &&
            item.array.length == array2.length
          ) {
            let count = 0;
            item.array.map((i) => {
              array2.map((j) => {
                if (i == j) {
                  count += 1;
                }
              });
            });
            if (count == array2.length) {
              code0 = 2;
            }
          }
        });
      }
      // 将当前患者更改为默认就诊人
      let resMember = await changeDefaultMember({
        memberId: defultUser.member_id,
        patientDefault: 1,
        patientId:patientId,
      })
      
      if (code0 == 2) {
        alert(
          "提示弹窗",
          "该患者已提交过相同药品的问诊，确认是否再次提交？",
          [
            {
              text: "取消",
              onPress: () =>
                this.setState({
                  tips: 0,
                }),
            },
            {
              text: "确认",
              onPress: async () => {
                let res00 = await isSelfphamacy(
                  localStorage.getItem("pharmacyId")
                );
                if (res00.code == 1) {
                  if (
                    res00 &&
                    res00.data &&
                    res00.data[0] &&
                    (res00.data[0][0].is_self_operated == 1 ||
                      (res00.data[1] && res00.data[1][0].iscode == 1))
                  ) {
                    let res = await getOrderallways({
                      arrayMeds: array3,
                      sex: defultUser.patient_sex == "男" ? 1 : 2,
                    });
                      let paramsall = {
                        memberIdNew:defultUser.member_id,
                        chineseUserSubmit:chineseUserSubmit ? chineseUserSubmit : 1,
                        chineseAuto:chineseAuto ? chineseAuto : 1,
                        submitPort:2,
                        isExcessArr: null,
                        userId: patientId,
                        isChildrenmed: childrencode ? 1 : 2,
                        hasHighmed: highMedsArray.length ? 1 : 2,
                        isSpecialmed: specialMedicine.length ? 1 : 2,
                        pharmacyName:pharmacyNameAll,
                        pharmacyId: parseInt(pharmacyId),
                        docterId: docterId,
                        isAppointDoctor: selectdoctor == 2 ? 1 : 2,
                        consultationAmount: 0,
                        consultationDetail: listchose0.toString(),
                        consultationFiles: imageUrl ? imageUrl : "",
                        isclassifyId: 1,
                        // isSurgery: code4 == 1 ? 1 : 2,
                        isInternal:
                          code1 == 1 ? 2 : code2 == 1 || code3 == 1 ? 1 : 2,
                        consultationType: videocode ? "3" : "1",
                        consultationStu: "-1",
                        patientName: defultUser.patient_name,
                        pregnancyStatus: defultUser.pregnancy_status,
                        patientSex:
                          defultUser.patient_sex == "男" ? 1 : 2,
                        patientIdcardNum: defultUser.patient_id_card,
                        patientBirthday:
                          new Date(defultUser.patient_birthday).getFullYear() +"-" +
                          (new Date(defultUser.patient_birthday).getMonth() + 1 < 10
                            ? "0" + (new Date(defultUser.patient_birthday).getMonth() + 1)
                            : new Date(defultUser.patient_birthday).getMonth() + 1) + "-" +
                          (new Date(defultUser.patient_birthday).getDate() < 10
                            ? "0" + new Date(defultUser.patient_birthday).getDate()
                            : new Date(defultUser.patient_birthday).getDate()),
                        patientCity: defultUser.patient_city,
                        patientPhone: defultUser.patient_phone,
                        pastHistory: defultUser.past_history,
                        pastHistoryNote: defultUser.past_history_note,
                        allergicHistory: defultUser.allergic_history,
                        allergicHistoryNote:
                          defultUser.allergic_history_note,
                        familyGeneticHistory:
                          defultUser.family_genetic_history,
                        familyGeneticHistoryNote:
                          defultUser.family_genetic_history_note,
                        abnormalLiverFunction:
                          defultUser.abnormal_liver_function,
                        abnormalRenalFunction:
                          defultUser.abnormal_renal_function,
                        patientAge: defultUser.age,
                        cityId: defultUser.city_id,
                        patientProvince:defultUser.patient_province ? defultUser.patient_province : patientsProvince,//就诊人所在省
                        patientCities:defultUser.patient_cities ? defultUser.patient_cities : patientsCities,//就诊人所在市
                        patientCounty:defultUser.patient_county ? defultUser.patient_county : patientsCounty,//就诊人所在区
                        medicalnsuranceCoordination: coordinationcode,
                        orderFormType: 2,
                        is_chinese_meds:1,
                        // consultationFiles: imageUrl,
                        guardianName: defultUser.guardianName,//监护人姓名
                        guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
                        bodyweight: defultUser.bodyweight,//体重
                        bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
                        bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
                      };
                      if (array4.length == 0) {
                        this.props.history.push(`/drugselection`);
                        Toast.show("请先选择药品");
                      } else {
                        sumbitLogs({
                          arrayMeds: '中药材',
                          sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                          params,//基础参数
                          array1:array1,//药品参数
                          paramsall,//问诊参数
                          electronicIcd
                        }).then((reslogs)=>{
                          // console.log('reslogs--4',reslogs);
                        })

                        // chineseMedsTaboo({})

                        submitChaMedOrder
                        ({
                          sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                          params,//基础参数
                          array1:array4,//药品参数
                          paramsall,//问诊参数
                          // isCheck,//是否检查
                          usage,//用法用量
                          // supervisionType:4,//中药材
                          electronicIcd,
                        })
                          .then((res2) => {
                            if ( res2 && res2.data && res2.data[0] && res2.data[2][0].consultationId ) {
                              localStorage.setItem(
                                "consultationId", res2.datares2.data[2][0].consultationId
                              );
                              localStorage.removeItem("selectmeds");
                              localStorage.removeItem("listicd");
                              localStorage.removeItem("illmiaosu");
                              localStorage.removeItem("miaosu");
                              localStorage.removeItem(
                                "selectdoctormiaosu"
                              );
                              // localStorage.removeItem("videocode");
                              localStorage.removeItem("chamedarr");
                              localStorage.removeItem("meiday");
                              localStorage.removeItem("mlarr2");
                              localStorage.removeItem("mlarr1");
                              localStorage.removeItem("dayarr");
                              localStorage.removeItem("selectmedsmethod");
                              localStorage.removeItem("selectedValue");
                              localStorage.removeItem("allarr");
                              localStorage.removeItem("list");
                              localStorage.removeItem("imageUrl");
                              localStorage.removeItem("methodarr");
                              localStorage.removeItem("isUploaded");
                              localStorage.removeItem("units");
                              
                              this.setState({
                                consultationId: res2.datares2.data[2][0].consultationId,
                                awaitdoc: 2,
                              });
                              this.toAwaittime();
                            }else{
                              Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                              this.setState({
                                tips: 0,
                              });
                            }
                          })
                          .catch((res) => {
                            Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                            this.setState({
                              tips: 0,
                            });
                          });
                      }
                    // }
                  } else {
                    this.setState(
                      {
                        tips: 0,
                      },
                      () => {
                        Toast.show("该店铺为非会员，无法进行问诊");
                      }
                    );
                  }
                } else {
                  this.setState({
                    tips: 0,
                  });
                }
              },
            },
          ]
        );
      } else {
        let res00 = await isSelfphamacy(
          localStorage.getItem("pharmacyId")
        );
        if (res00.code == 1) {
          if (
            res00 &&
            res00.data &&
            res00.data[0] &&
            (res00.data[0][0].is_self_operated == 1 ||
              (res00.data[1] && res00.data[1][0].iscode == 1))
          ) {
            let res = await getOrderallways({
              arrayMeds: array3,
              sex: defultUser.patient_sex == "男" ? 1 : 2,
            });
              let paramsall = {
                memberIdNew:defultUser.member_id,
                chineseUserSubmit:chineseUserSubmit ? chineseUserSubmit : 1,
                chineseAuto:chineseAuto ? chineseAuto : 1,
                submitPort:2,
                isExcessArr: null,
                userId: patientId,
                hasHighmed: highMedsArray.length ? 1 : 2,
                isSpecialmed: specialMedicine.length ? 1 : 2,
                pharmacyName: pharmacyNameAll,
                pharmacyId: parseInt(pharmacyId),
                docterId: docterId,
                isChildrenmed: childrencode ? 1 : 2,
                isAppointDoctor: selectdoctor == 2 ? 1 : 2,
                isclassifyId: 1,
                // isSurgery: code4 == 1 ? 1 : 2,
                isInternal:
                  code1 == 1 ? 2 : code2 == 1 || code3 == 1 ? 1 : 2,
                consultationAmount: 0,
                consultationDetail: listchose0.toString(),
                consultationFiles: imageUrl ? imageUrl : "",
                consultationType: videocode ? "3" : "1",
                consultationStu: "-1",
                patientName: defultUser.patient_name,
                pregnancyStatus: defultUser.pregnancy_status,
                patientSex: defultUser.patient_sex == "男" ? 1 : 2,
                patientIdcardNum: defultUser.patient_id_card,
                patientBirthday:
                  new Date( defultUser.patient_birthday ).getFullYear() + "-" +
                  (new Date(defultUser.patient_birthday).getMonth() + 1 < 10
                    ? "0" +
                      (new Date( defultUser.patient_birthday ).getMonth() + 1)
                    : new Date( defultUser.patient_birthday ).getMonth() + 1) + "-" +
                  (new Date(defultUser.patient_birthday).getDate() < 10 ? "0" +
                  new Date(defultUser.patient_birthday).getDate() : 
                  new Date( defultUser.patient_birthday).getDate()),
                patientCity: defultUser.patient_city,
                patientPhone: defultUser.patient_phone,
                pastHistory: defultUser.past_history,
                pastHistoryNote: defultUser.past_history_note,
                allergicHistory: defultUser.allergic_history,
                allergicHistoryNote: defultUser.allergic_history_note,
                familyGeneticHistory:
                  defultUser.family_genetic_history,
                familyGeneticHistoryNote:
                  defultUser.family_genetic_history_note,
                abnormalLiverFunction:
                  defultUser.abnormal_liver_function,
                abnormalRenalFunction:
                  defultUser.abnormal_renal_function,
                patientAge: defultUser.age,
                cityId: defultUser.city_id,
                patientProvince:defultUser.patient_province ? defultUser.patient_province : patientsProvince,//就诊人所在省
                patientCities:defultUser.patient_cities ? defultUser.patient_cities : patientsCities,//就诊人所在市
                patientCounty:defultUser.patient_county ? defultUser.patient_county : patientsCounty,//就诊人所在区
                orderFormType: 2,
                medicalnsuranceCoordination: coordinationcode,
                is_chinese_meds:1,
                // consultation_files: imageUrl,
                guardianName: defultUser.guardianName,//监护人姓名
                guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
                bodyweight: defultUser.bodyweight,//体重
                bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
                bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
              };
              if (array4.length == 0) {
                this.props.history.push(`/drugselection`);
                Toast.show("请先选择药品");
              } else {

                sumbitLogs({
                  arrayMeds: '中药材',
                  sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                  params,//基础参数
                  array1:array1,//药品参数
                  paramsall,//问诊参数
                  electronicIcd
                }).then((reslogs)=>{
                  // console.log('reslogs--4',reslogs);
                })


                submitChaMedOrder
                ({
                  // arrayMeds:array3,
                  sex: defultUser.patient_sex == "男" ? 1 : 2,//性别
                  params,//基础参数
                  array1:array4,//药品参数
                  paramsall,//问诊参数
                  // isCheck,//是否检查
                  usage,//用法用量
                  // supervisionType:4,//中药材
                  electronicIcd,
                })
                  .then((res2) => {
                    console.log(res2.data[0],res2.data.length,'长度2');
                    if ( res2 && res2.data && res2.data[0] && res2.data[2][0].consultationId ) {
                      localStorage.setItem(
                        "consultationId",
                        res2.data[2][0].consultationId
                      );
                      localStorage.removeItem("selectmeds");
                      localStorage.removeItem("listicd");
                      localStorage.removeItem("illmiaosu");
                      localStorage.removeItem("miaosu");
                      localStorage.removeItem("selectdoctormiaosu");
                      // localStorage.removeItem("videocode");
                      localStorage.removeItem("chamedarr");
                      localStorage.removeItem("meiday");
                      localStorage.removeItem("mlarr2");
                      localStorage.removeItem("mlarr1");
                      localStorage.removeItem("dayarr");
                      localStorage.removeItem("selectmedsmethod");
                      localStorage.removeItem("selectedValue");
                      localStorage.removeItem("allarr");
                      localStorage.removeItem("list");
                      localStorage.removeItem("imageUrl");
                      localStorage.removeItem("methodarr");
                      localStorage.removeItem("isUploaded");
                      localStorage.removeItem("units");
                      this.setState({
                        consultationId:
                        res2.data[2][0].consultationId,
                        awaitdoc: 2,
                      });
                      this.toAwaittime();
                    }else{
                      Toast.show('当前用户多次使用提交失败，请换个用户再次进行提交')
                      this.setState({
                        tips: 0,
                      });
                    }
                  })
                  .catch((res) => {
                    this.setState({
                      tips: 0,
                    });
                  });
              }
            // }
          } else {
            this.setState(
              {
                tips: 0,
              },
              () => {
                Toast.show("该店铺为非会员，无法进行问诊");
              }
            );
          }
        } else {
          this.setState({
            tips: 0,
          });
        }
      }

    }
   
  };

  //查询问诊单是否有医生接诊，有就跳转到聊天
  toAwaittime = async () => {
    const { consultationId, icdlists, patientId, docterId } = this.state;
    if (icdlists.length) {
      let res = await saveIcd({
        consultationId,
        electronicIcd: JSON.stringify(icdlists),
        type: 1,
      });
    }
    this.props.history.replace(`/drugselection`);
    if (docterId) {
      this.props.history.push(`/offawait?docterId=${docterId}`);
    } else {
      this.props.history.push(`/offawait`);
    }
  };

  //病情描述选择快捷
  // changeKj = (i) => {
    changeKj = (value,index0, dis_id) => {
    const { list, miaosu } = this.state;
    let arr = [];
    let arr1 = [];

    // 找到点击的对象
    // 找到点击的对象以及具有相同 value 的对象
    // clickedItem:被点击的对象
    // sameValueItems:值相同的对象
    let flag =  list.every(item=>{
      return item.index === 1
    })
    let clickedItem =  list.find((item) => item.value === value && item.index0 === index0 && item.dis_id == dis_id );
    let sameValueItems =flag? list.filter((item) => item.value === value) : [clickedItem];

    if (clickedItem) {
      // 找到具有相同 index0 值的对象
      // clickedItem.index=2;
      let sameIndex0Items = list.filter((item) => item.index0 === clickedItem.index0);
      sameValueItems.forEach((item) => {
        item.index=2;
      });
      // 将具有相同 index0 值的对象的 index 设置为 1
      sameIndex0Items.forEach((item) => {
        if (item !== clickedItem) {
          item.index = 1;
          sameValueItems.forEach((i) => {
            list.forEach((j)=>{
              if(i.index0 == j.index0  && i.value !== j.value){
                j.index = 1;
              }
            })
          })
        }
      });
    }

    // 更新相关的状态
    list.forEach((item) => {
      if (item.index === 2) {
        arr.push(item.value);
        arr1.push(item.id);
      }
    });

    let nArr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
        currentIndex
      );
    });
    this.setState({ list, listchose: arr, icdlists: nArr1 }, () => {
      localStorage.setItem("listicd", JSON.stringify(list));
    });
  };

  // 中药病情描述选择
  selectChaMedKj = async () =>{
    const { currentPage, pageSize,defultUser } = this.state;
    // 计算分页查询的起始索引
    const startIndex = (currentPage - 1) * pageSize;
    let res11 = await allChamed({
      sex: defultUser ? (defultUser.patient_sex == "男" ? 1 : 2) : 3,
      page: currentPage,
      size: pageSize,
    });

    // 计算总页数
    const totalPage = Math.ceil(res11.data[0].length / pageSize);
    this.setState({
      visible4: true,
      icdList: res11.data[0],
      page: totalPage,
    });

  }


  // 中药病情描述搜索
  toSearchzy = async () => {
    const { icdname, classifyId, defultUser } = this.state;
    let res = await allChamed({
      icdname,
      sex:defultUser? (defultUser.patient_sex == '男' ? 1 : 2) :3
    });

    this.setState({
      icdList: res.data[0],
    });
  };
  // 中药icd选择
  toSelectchamedsicd = async (item) => {

    // debugger
    const { list, index0, allotherMeds, medname, onewsMeds,chamedlists } = this.state;
    // const chamedlists=[...list]  //暂时注释
    let icdId = item.icd_id && JSON.parse(item.icd_id)[0];
    let id = { icdName: icdId.icd_name, icdValue: icdId.icd_value };
    let array = [];

    allotherMeds.push({ name: medname, index: index0, selectcode: 1 });
    onewsMeds.map((item) => {
      if (item != medname) {
        array.push(item);
      }
    });


    chamedlists.push({ 
      id: id, 
      index: 1, 
      index0, 
      value: item.name, 
      data: 0,
      is_acute:item.is_acute
    });
    let arr = [];
    let arr1 = [];
    // let newlist = [];

    const i = item.name;

    chamedlists.map((item) => {
      if(item.value == i){
        item.index  =  2;
      }
      if (item.index == 2) {
        arr.push(item.value);
        arr1.push(item.id);
      }
    });

    let nArr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
        currentIndex
      );
    });

    this.setState(
      {
        visible4: false,
        list: chamedlists,
        listchose: arr,
        icdlists: nArr1,
        allotherMeds,
        onewsMeds: array,
      },
      () => {
        localStorage.setItem("listicd", JSON.stringify(chamedlists));
        // localStorage.setItem('chamedlists', JSON.stringify(chamedlists));
        localStorage.setItem("listchose", JSON.stringify(arr));
      }
      
    );
  };
  // 中药清空选择
  selectAgainCha = (value) => {
    const { list, index0, allotherMeds, medname, onewsMeds } = this.state;
    const array1 = list.filter((item) => {
      if (item.value == value) {
        item.index = 1;
        return false; 
      } else {
        return true; 
      }
    });
    let arrlist = [];
    array1.map((item)=>{
      if(item,index == 2){
        arrlist.push(item.id);
      }
    })

    this.setState(
      {
        list:array1,
        icdlists:arrlist,
      },
      () => {
        localStorage.setItem("listicd", JSON.stringify(array1));
      }
    );
  };

  //未维护药品病情描述搜索
  selectKj = async (item) => {
    const { selectmeds, icdname, defultUser } = this.state;
    let classifyId = 0;
    let index0 = 0;
    selectmeds.map((i, v) => {
      let medName = i.medComName ? i.medComName : i.medName;
      if (medName == item) {
        classifyId = i.classifyId;
        index0 = v;
      }
    });
    let res = await allClassifyid({
      classifyId,
      sex: defultUser.patient_sex == "男" ? 1 : 2,
    });
    let nArr1 = [];
    if (res && res.data && res.data[0] && res.data[0].length) {
      nArr1 = res.data[0].filter((currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.name === currentValue.name) ===
          currentIndex
        );
      });
    }
    this.setState({
      index0,
      visible4: true,
      icdList: nArr1.length ? nArr1 : res.data[0],
      classifyId,
      medname: item,
    });
  };
  toSearch = async () => {
    const { icdname, classifyId, defultUser } = this.state;
    let res = await allClassifyid({
      classifyId,
      icdname,
      sex: defultUser.patient_sex == "男" ? 1 : 2,
    });
    let nArr1 = [];
    if (res && res.data && res.data[0] && res.data[0].length) {
      nArr1 = res.data[0].filter((currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.name === currentValue.name) ===
          currentIndex
        );
      });
    }
    this.setState({
      icdList: nArr1.length ? nArr1 : res.data[0],
    });
  };
  
  toSelecticd = async (item) => {
    // debugger
    const { list, index0, allotherMeds, medname, onewsMeds } = this.state;
    let icdId = item.icd_id && JSON.parse(item.icd_id)[0];
    let id = { icdName: icdId.icd_name, icdValue: icdId.icd_value };
    let array = [];
    allotherMeds.push({ name: medname, index: index0, selectcode: 1 });
    onewsMeds.map((item) => {
      if (item != medname) {
        array.push(item);
      }
    });
    list.push({ id: id, index: 1, index0, value: item.name, data: 0 });
    let arr = [];
    let arr1 = [];
    const i = item.name;

    list.map((item) => {
      if(item.value == i){
        item.index  =  2;
      }
      if (item.index == 2) {
        arr.push(item.value);
        arr1.push(item.id);
      }
    });

    let nArr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
        currentIndex
      );
    });
    this.setState(
      {
        visible4: false,
        list: list,
        listchose: arr,
        icdlists: nArr1,
        allotherMeds,
        onewsMeds: array,
      },
      () => {
        localStorage.setItem("listicd", JSON.stringify(list));
      }
    );
  };


  selectAgain = (name, value) => {
    const { list, index0, allotherMeds, medname, onewsMeds } = this.state;
    let array = [];
    let array1 = [];
    onewsMeds.push(name);
    allotherMeds.map((item) => {
      if (item.name != name) {
        array.push(item);
      }
    });
    list.map((item) => {
      if (item.value != value) {
        array1.push(item);
      }
      else if (item.value === value && item.dis_id) {
        array1.push(item);
      }
    });
    let arr = [];
    let arr1 = [];
    array1.map((item) => {
      if (item.index == 2) {
        arr.push(item.value);
        arr1.push(item.id);
      }
    });
    let nArr1 = arr1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.icdValue === currentValue.icdValue) ===
        currentIndex
      );
    });
    this.setState(
      {
        allotherMeds: array,
        onewsMeds,
        list: array1,
        listchose: arr,
        icdlists: nArr1,
      },
      () => {
        localStorage.setItem("listicd", JSON.stringify(array1));
      }
    );
  };
  setValue = (e) => {
    this.setState({
      icdname: e.target.value.replace(
        /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,
        ""
      ),
    });
  };
  // stop=()=>{
  //     document.body.style.overflow='hidden';  e){e.preventDefault();},false);//禁止页面滑动
  //     document.addEventListener("pointerup",function(e){e.preventDefault();},false);//禁止页面滑动
  // }
  reducecount = (item) => {
    const { selectmeds, coordinationcode, reviewercode, entitycode } =
      this.state;
    if (item.count > 1) {
      selectmeds.map((i) => {
        if (i.medId == item.medId) {
          i.count = i.count - 1;
        }
      });
      this.setState(
        {
          selectmeds,
        },
        () => {
          localStorage.setItem("selectmeds", JSON.stringify(selectmeds));
        }
      );
    } else {
      alert(
        "提示",
        selectmeds.length > 1
          ? "确认删除该药品吗?"
          : "确认删除该药品吗？确认后将跳转到首页重新选药",
        [
          {
            text: "取消",
            onPress: () => console.log("cancel"),
            style: "default",
          },
          {
            text: "确定",
            onPress: () => {
              let array = [];
              selectmeds.map((i) => {
                if (i.medId != item.medId) {
                  array.push(i);
                }
              });
              this.setState(
                {
                  selectmeds: array,
                },
                async () => {
                  let arr = [];
                  if (array.length) {
                    array.forEach((i) => {
                      let params = {};
                      // params.name = i.medComName;
                      params.name = i.medComName ? i.medComName : i.medName;
                      params.spec = i.medSpec;
                      arr.push(params);
                    });
                  }
                  //高危药品查询
                  let res01 = await getHighmeds({ allNews: arr });
                  if (res01 && res01.data && res01.data.length) {
                    this.setState({
                      highMeds: res01.data[1],
                      highMedsC: res01.data[0],
                      specialMedicine: res01.data[2],
                      highMedslength:
                        res01.data[1].length +
                        res01.data[0].length +
                        res01.data[2].length,
                    });
                  }
                  localStorage.setItem("selectmeds", JSON.stringify(array));
                  if (array.length == 0) {
                    localStorage.removeItem("selectmeds");
                    localStorage.removeItem("listicd");
                    this.props.history.push("/drugselection");
                    localStorage.removeItem("selectdoctormiaosu");
                  } else {
                    localStorage.removeItem("list");
                    localStorage.removeItem("listicd");
                    if (entitycode == 1) {
                      this.props.history.replace(
                        `/patientinformation?coordinationcode=${coordinationcode}&reviewercode=${reviewercode}&entitycode=${entitycode}`
                      );
                    } else {
                      this.props.history.replace(
                        `/patientinformation?coordinationcode=${coordinationcode}&reviewercode=${reviewercode}`
                      );
                    }
                    window.location.reload();
                  }
                }
              );
            },
          },
        ]
      );
    }
  };
  addcount = (item) => {
    const { selectmeds } = this.state;
    selectmeds.map((i) => {
      if (i.medId == item.medId) {
        i.count = i.count + 1;
      }
    });
    this.setState(
      {
        selectmeds,
      },
      () => {
        localStorage.setItem("selectmeds", JSON.stringify(selectmeds));
      }
    );
  };
  // 删除中药克数
  handleShancShow = (item)=>{
    const {selectedMed,chamedList,chamedarr} = this.state
    alert(
      "提示",
      chamedarr.length>1  ? "确认删除该药品吗?"
      : "确认删除该药品吗？确认后将跳转到中药列表重新选药",
      [
        {
          text: "取消",
          onPress: () => console.log("cancel"),
          style: "default",
        },
        {
          text: "确定",
          onPress: () => {
            item.jiacountValue = null;
            const updatedChamedarr = chamedarr.filter((med) => med.pro_id !== item.pro_id);
            localStorage.setItem('chamedarr', JSON.stringify(updatedChamedarr));
            this.setState({ chamedarr: updatedChamedarr });
            if(!updatedChamedarr.length){
              this.props.history.replace(`/drugselection?medType=2`)
            }
          },
        }
      ]
    )
    
  }

  // 阻止回车键盘事件
  handleJiacountKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // 阻止默认的回车换行事件
    }
  }
  handleJiacountKeyPress = (e) => {
    const pattern = /^[0-9]$/;
  
    if (!pattern.test(e.key) || e.target.innerText.length >= 3) {
      e.preventDefault();
    }
  };
  // 更新中药清单的数量
  handleJiacountChange = (e, item) => {
    const { chamedarr } = this.state;
    const pro_id = item.pro_id;
    const newValue = e.target.innerText;

    // 保存原本的值
    const originalValue = item.jiacountValue;
      
    // 判断输入值是否为大于0的数且不以零开头的数字
    const pattern = /^(?!0\d{2})[1-9]\d{0,2}$/;
    if (!pattern.test(newValue)) {
      // 将原本的值赋值给输入框
      e.target.innerText = originalValue;
      return; // 停止执行后面的代码
    }
    // 如果输入的数字大于3，保留前3位数字
    if (newValue.length > 3) {
      e.target.innerText = originalValue;
      return;
  }

    const updatedChamedarr = chamedarr.map(chamedItem => {
      if (chamedItem.pro_id === pro_id) {
        return {
          ...chamedItem,
          jiacountValue: newValue
        }
      }
      return chamedItem;
    });
    localStorage.setItem('chamedarr', JSON.stringify(updatedChamedarr));
    
    this.setState({
      chamedarr: updatedChamedarr
    });
  }
  // 返回首页中药列表
  toHomeChaMed = () =>{
    const { medType } = this.state;
    this.props.history.replace(`/drugselection?medType=2`)
    localStorage.removeItem("videocode");
    localStorage.removeItem("obj");
  }
  // 顶部返回首页
  toReturnHome = () =>{
    this.props.history.replace(`/drugselection?medType=2`)
    localStorage.removeItem("videocode");
    localStorage.removeItem("obj");
  }
  // 用药时间
  handleRadioChange = (event) => {
    const selectedValue = event.target.value.toString();
    this.setState({
      selectedValue: selectedValue,
    },() => {
      localStorage.setItem("selectedValue", selectedValue);
    });
  }
  //去支付
  topay = async () => {
    const {
      defultUser,
      index,
      docterId,
      selectdoctor,
      supervisionType,
      videocode,
      patientId,
      patientPhone,
      pharmacyId,
      coordinationcode,
      icdlists,
      selectmeds,
      tips,
      miaosu,
      listchose,
      imageUrl,
      patientsProvince,
      patientsCities,
      patientsCounty,
      pharmacyNameAll
    } = this.state;
    let params = {
      userId: parseInt(patientId),
      orderShipping: "1",
      phone: patientPhone,
      pharmacyId: parseInt(pharmacyId),
      orderPrice: 0,
      orderAmount: 0,
      supervisionType: parseInt(supervisionType),
    };
    let array1 = [];
    let array2 = [];
    let classifyId0 = "";
    let codeId = 1;
    let code0 = 1;
    let code1 = 2;
    let code2 = 2;
    let code3 = 2;
    let count2 = 0;

    selectmeds.forEach((item) => {
      if (
        item.classifyId == 56 ||
        item.classifyId == 6 ||
        item.classifyId == 9 ||
        item.classifyId == 10 ||
        item.classifyId == 105
      ) {
        code1 = 1;
      }
      if (item.classifyId == 114) {
        //注射药品
        code2 = 1;
        count2 += 1;
      }
      if (item.classifyId == 11 || item.classifyId == 116) {
        //内科
        code3 = 1;
      }
      let a = item.medComName + item.medSpec;
      array2.push(a);
      array1.push({
        medId: item.medId,
        medName: item.medName ? item.medName : item.medComName,
        medSpec: item.medSpec ? item.medSpec : null,
        medPacking: item.medPacking ? item.medPacking : null,
        medApproval: item.medApproval ? item.medApproval : null,
        medCompany: item.medCompany ? item.medCompany : null,
        medPrice: item.medPrice ? item.medPrice : 0,
        medQuantity: item.count,
        libraryId: item.libraryId ? item.libraryId : "0",
        medEnterprise: item.medEnterprise ? item.medCompany : null,
        thirdPartyId: item.thirdPartyId ? item.thirdPartyId : "0",
        medQrcode: item.medQrcode ? item.medQrcode : null,
        medComName: item.medComName ? item.medComName : item.medName,
        medDosageForm: item.medDosageForm ? item.medDosageForm : null,
        medType: item.medType ? item.medType : null,
        contacts: localStorage.getItem("contacts"),
        medApplySymptoms: item.medApplySymptoms ? item.medApplySymptoms : null,
        supervisionType: parseInt(item.supervisionType),
        packingUnit: item.packingUnit ? item.packingUnit : null,
        isOtc: item.medClassify == "1" ? 2 : 1,
      });
    });
    addAllmeds({ ...params, array: array1 }).then((res) => {
      if (res && res.code == 1) {
        addchat({
          memberIdNew:defultUser.member_id,
          userId: patientId,
          pharmacyName: pharmacyNameAll,
          pharmacyId: parseInt(pharmacyId),
          docterId: docterId,
          isAppointDoctor: selectdoctor == 2 ? 1 : 2,
          consultationAmount: 0,
          consultationDetail: listchose.length
            ? listchose.toString() + (miaosu ? ";" + miaosu : "")
            : miaosu,
          consultationFiles: imageUrl ? imageUrl : "",
          isclassifyId: 1,
          isInternal: code1 == 1 ? 2 : code2 == 1 || code3 == 1 ? 1 : 2,
          consultationType: videocode ? "3" : "1",
          consultationStu: "-2",
          patientName: defultUser.patient_name,
          patientSex: defultUser.patient_sex == "男" ? 1 : 2,
          patientIdcardNum: defultUser.patient_id_card,
          patientBirthday:
            new Date(defultUser.patient_birthday).getFullYear() +
            "-" +
            (new Date(defultUser.patient_birthday).getMonth() + 1 < 10
              ? "0" + (new Date(defultUser.patient_birthday).getMonth() + 1)
              : new Date(defultUser.patient_birthday).getMonth() + 1) +
            "-" +
            (new Date(defultUser.patient_birthday).getDate() < 10
              ? "0" + new Date(defultUser.patient_birthday).getDate()
              : new Date(defultUser.patient_birthday).getDate()),
          patientCity: defultUser.patient_city,
          patientPhone: defultUser.patient_phone,
          pastHistory: defultUser.past_history,
          pastHistoryNote: defultUser.past_history_note,
          allergicHistory: defultUser.allergic_history,
          allergicHistoryNote: defultUser.allergic_history_note,
          familyGeneticHistory: defultUser.family_genetic_history,
          familyGeneticHistoryNote: defultUser.family_genetic_history_note,
          abnormalLiverFunction: defultUser.abnormal_liver_function,
          abnormalRenalFunction: defultUser.abnormal_renal_function,
          patientAge: defultUser.age,
          cityId: defultUser.city_id,
          patientProvince:defultUser.patient_province ? defultUser.patient_province : patientsProvince,//就诊人所在省
          patientCities:defultUser.patient_cities ? defultUser.patient_cities : patientsCities,//就诊人所在市
          patientCounty:defultUser.patient_county ? defultUser.patient_county : patientsCounty,//就诊人所在区
          medicalnsuranceCoordination: coordinationcode,
          orderFormType: 2,
          orderNumber1: res.data[1][0].orderNumber,
          guardianName: defultUser.guardianName,//监护人姓名
          guardianIdcardNum: defultUser.guardianIdcardNum,//监护人身份证
          bodyweight: defultUser.bodyweight,//体重
          bodytemperature: defultUser.bodytemperature ? defultUser.bodytemperature : '0',//体温是否异常
          bodytemperaturenum: defultUser.bodytemperaturenum,//体温摄氏度
        }).then((res2) => {
          if (res2 && res2.data && res2.data[2] && res2.data[2].length) {
            localStorage.setItem(
              "consultationId",
              res2.data[2][0].consultationId
            );

            this.setState(
              {
                consultationId: res2.data[2][0].consultationId,
                visible3: false,
                tips: 1,
              },
              async () => {
                if (icdlists.length) {
                  let res3 = await saveIcd({
                    consultationId: res2.data[2][0].consultationId,
                    electronicIcd: JSON.stringify(icdlists),
                    type: 1,
                  });
                }
                let orderName = "未续费店铺问诊支付";
                let b = new Date().getTime();
                window.location.href = `${zfUrl}/route?client_id=${code05}&pharmacyId=${localStorage.getItem(
                  "pharmacyId"
                )}&channel=&client_trade_no=${
                  res2.data[2][0].consultationNumber
                }&total_fee=${1}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`;
              }
            );
          }
        });
      }
    });
  };
  // 复制客服电话
  copykefuhone = () => {
    const spanText = document.getElementById("contactPhone").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.info("复制成功");
  };
  onscroll = (e) => {
    const { flag } = this.state;
    e.preventDefault();
    e.stopPropagation();
    this.setState();
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      5
    ) {
    }
  };

  toselectDoctor = async () => {
    const { coordinationcode, miaosu, entitycode,selectmeds,zhongyaomeds,medType } = this.state;
    let hasClassifyId1 = false;
    let hasClassifyId2 = false;
    for (const item of selectmeds){
      if(item.classifyId==6 ||
          item.classifyId == 56 ||
          item.classifyId==8 ||
          item.classifyId==9 ||
          item.classifyId==10 ||
          item.classifyId==3 ||
          item.classifyId==105 ||
          item.classifyId==108 
          )
          {
            hasClassifyId1=true;
      }
      if(item.classifyId==11 ||
        item.classifyId==116 ||
        item.classifyId==114)
        {
        hasClassifyId2=true;
      }
    }
    if( hasClassifyId1 && hasClassifyId2 ){
      Toast.show('所选药品暂仅支持快速问诊');
      return
    }

    let hasClassifyId7 = false;
    let hasClassifyId8 = false;
    for (const item of selectmeds){
      if(item.classifyId==5 ||
          item.classifyId==7 ||
          item.classifyId==12 ||
          item.classifyId==105 )
          {
            hasClassifyId7=true;
      }
      if(item.classifyId==11 ||
        item.classifyId==116 )
        {
        hasClassifyId8=true;
      }
    }
    if( hasClassifyId7 && hasClassifyId8 ){
      Toast.show('所选药品暂仅支持快速问诊');
      return
    }

    let hasClassifyId3 = false;
    let hasClassifyId4 = false;
    for (const item of selectmeds){
      if(item.classifyId==11 ||
          item.classifyId==116 ||
          item.classifyId==114 )
          {
            hasClassifyId3=true;
      }
      if(item.classifyId==5 ||
        item.classifyId==7 ||
        item.classifyId==12)
        {
        hasClassifyId4=true;
      }
    }
    if( hasClassifyId3 && hasClassifyId4 ){
      Toast.show('所选药品暂仅支持快速问诊');
      return
    }

    // let hasClassifyId5 = false;
    // let hasClassifyId6 = false;
    // for (const item of selectmeds){
    //   if(item.classifyId == 11 || item.classifyId == 116 || item.classifyId == 114)
    //       {
    //         hasClassifyId5=true;
    //   }
    //   if(item.classifyId==5 ||
    //     item.classifyId==7 ||
    //     item.classifyId==12)
    //     {
    //     hasClassifyId6=true;
    //   }
    // }
    // if( hasClassifyId5 && hasClassifyId6 ){
    //   Toast.show('所选药品暂仅支持快速问诊');
    //   return
    // }

    if(medType == 2){
      this.props.history.replace(
        `/patientinformationzhong?coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
      );
    }else{
      this.props.history.replace(
        `/patientinformation?coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
      );
    }
    

    this.props.history.push(
      `/appointedmedicalpractitioner?coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}`
    );
  };



  handleRelationshipChange = (value) => {
    this.setState({ meiday: value });
  };

  handlePickerVisibleChange = (visible) => {
    this.setState({ pickerVisible: visible });
  };

  handleConfirm = (value) => {
    const meiday = value[0];
    this.setState({ meiday }, () => {
      this.handlePickerVisibleChange(false);
      localStorage.setItem("meiday",meiday)
    });
  };

  handlePickerChange = (value) => {
    this.setState({ selectedItem: value[0] });
  };

  // 获取单位
  dianji1 = (index) => {
    const { danwei, i } = this.state
    this.setState({
        i: index
    })
  }
  // 隐藏单位
  closeDanwei = () => {
    const { danwei, i, medId, somegoods } = this.state
    somegoods.map(item => {
        if (item.medId == medId) {
            item.useSpec = danwei[i]
            item.doseUnit = danwei[i]
            item.medicinalUsage = item.useQuantity + danwei[i]
            this.setState({
                somegoods,
                visible1: false,
                i: 0
            })
            // localStorage.setItem("somegoods", somegoods)
        }
    })
  }   

  /**
     * 用户中药用药单位自定义
     * 用药单位自填
     * @param {String} title 输入框标题
     * @param {String} stateName 修改的状态名称
     * @param {Array} stateData 修改状态的数据
     */
  useMedicineAndUnit = (title, stateName, stateData) => {
    this.setState(
      {
        selectedUnit: this.state.units[index] // 设置已选择的单位为 selectedUnit
      },
      () => {
        prompt(title, '请输入用药单位', [
          { text: '取消' },
          {
            text: '确认',
            onPress: value => {
              if (value.replace(/\s/g, "") == '') {
                Toast.show('请输入正确的单位');
                return;
              }
              if(value != "ml"){
                this.setState({
                  visible11:true
                })
              }
              let newData = stateData;
              newData.unshift(value);
              this.setState(
                {
                  [stateName]: newData,
                  selectedUnit: value.replace(/\s/g, "") // 将输入的值设置为 selectedUnit
                },
                () => {
                  this.inputRef.value = this.state.selectedUnit; // 设置 input 框的值
                  this.getvalue(this.state.selectedUnit, index, 'units'); // 触发一次 onChange 事件更新 selectedUnit 的值
                }
              );
              
            }
          }
        ]);
      }
    );
  };

  // 确定用法用量弹窗
  sureUnits=()=>{
    this.setState({
      visible11:false
    })
  }


  // 告知书滚动到底部
  onscrollNotice = e => {
    const { visible2,scrolledToBottom } = this.state;
    // e.target.scrollHeight,//滚动元素的内容高度，包括未在视口内显示的部分
    // e.target.scrollTop, //元素顶部到可视区域顶部的距离，向下滚动时，值会增加，向上滚动时，会减少
    // e.target.clientHeight, //可见高度，用户当前能看到的高度，是固定的
    if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 3 && visible2) {
      // console.log('按钮可以使用');
      this.setState({
        scrolledToBottom:true
      })
    }

  }

  // 关闭告知书
  handleCloseVis = () => {
    clearInterval(this.interval);
    this.setState({
      visible2:false,
      isReadNotice:true,
      selectcode: 1,
    })
  }
  // 打开风险告知书
  handleOpenVis = ()=>{
    const {isReadNotice} = this.state
    this.setState({
      visible2:true
    })
    if(isReadNotice==false){
      this.startCountdown();
    }
  }

  render() {
    const {
      unit,//药品单位
      defultUser,
      coordinationcode,
      icdlists,
      isnonews,
      falseSex,
      list,
      allbeginMeds,
      allotherMeds,
      onewsMeds,
      isuse,
      videocode,
      icdList,
      icdname,
      visible6,
      reviewercode,
      isadverse,
      entitycode,
      selectmeds,
      selectdoctor,
      miaosu,
      allMeds,
      visible7,
      docterName,
      selectdoctormiaosu,
      selectcode,
      storeagain,
      visible5,
      visible2,
      visible3,
      visible4,
      listchose,
      allDoctors,
      selectcodetime,
      selectmedsmethod,
      chamedarr,
      allarr,
      dayarr,
      mlarr1,
      mlarr2,
      methodarr,
      pickerVisible,
      meiday,
      selectItem,
      imageUrl,
      isUploaded,
      visible10,
      visible11,
      i,
      units,
      visiblecontype,
      scrolledToBottom,
      timeNotice,
      isRunningTime,
      noticeBtnState,
      filterNotice,
      isReadNotice
    } = this.state;

      // 中药用药天数
    const meidayOptions = [
      { label: "一日", value: "一日" },
      { label: "两日", value: "两日" },
      { label: "三日", value: "三日" },
      { label: "四日", value: "四日" },
      { label: "五日", value: "五日" },
      { label: "六日", value: "六日" },
      { label: "七日", value: "七日" },
    ];
    
    let a = 0;
    selectmeds.length &&
      selectmeds.map((item) => {
        if (allbeginMeds.length) {
          allbeginMeds.map((i) => {
            if (i == item.medComName) {
              item.data = 1;
            }
          });
        }
        a += item.count;
      });
    let nArr1 = selectmeds.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex(
          (x) =>
            x.medComName.replace(/^\s+|\s+$/g, "") ==
            currentValue.medComName.replace(/^\s+|\s+$/g, "")
        ) === currentIndex
      );
    });

    let b = listchose.length ? listchose.toString().length : 0;
    let list1 = [];

    list.forEach((i) => {
      if (i.index == 2) {
        list1.push(i);
      }
    });
    list1 = list1.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.value === currentValue.value) ===
        currentIndex
      );
    });

    let allotherMeds0 = allotherMeds;
    allotherMeds0 = allotherMeds0.filter(
      (currentValue, currentIndex, selfArr) => {
        return (
          selfArr.findIndex((x) => x.name === currentValue.name) ===
          currentIndex 
          // && !falseSex.includes(currentValue.name)
        );
      }
    );

    let onewsMeds1 = [];

    onewsMeds.map((i) => {
      onewsMeds1.push({ value: i, index: 0 });
    });
    
    onewsMeds1.map((i) => {
      falseSex.map((j) => { 
        if (i.value == j) {
          i.index = 1;
        }
      });
    });


    return (
      <>
        <p
          className="patientinformation-outer"
          onScroll={(e) => this.onscroll(e)}
          ref={this.useRef}
        >
          {visible11 ? (
            <>
              <div className="cons-type-box-wrap">
                <div className="cons-type-box">
                  <p className="cons-type-tit">用法用量提醒</p>
                  <p className="cons-type-tip">您现在用药单位为<span className="tip-units">{units}</span>,请注意</p>
                  <p className="cons-type">
                    <span className="type-three" onClick={() => this.sureUnits()}>确认</span>
                  </p>
                </div>
              </div>
            </>
          ) : null}
          {visiblecontype ? (
            <>
              <div className="cons-type-box-wrap">
                <div className="cons-type-box">
                <p className="cons-type-tit">提醒</p>
                <p className="cons-type-tip">选择视频问诊等待时间可能较长，建议您使用<span className="tip-tuwen">图文问诊</span>更方便快捷。</p>
                <p className="cons-type">
                  <span className="type-one" onClick={() => this.toSelectvideotype()}>视频问诊</span>
                  <span className="type-two" onClick={() => this.toSelecttuwentype()}>图文问诊</span>
                </p>
                </div>
              </div>
            </>
          ) : null}

          {visible2 ? (
            <div
              className="notice-box"
              style={{ backgroundColor: "#fff" }}
            >
              <p 
                className="notice-box-content"
                // ref={this.noticeBoxRef} 
                onScroll={(e) => this.onscrollNotice(e)}
              >
                <h2 className="risk-title" style={{ marginTop: "10px" }}>
                  风险告知及患者知情同意书
                </h2>
                <p>亲爱的患者及家属朋友，您好！</p>
                <p>
                  欢迎来到中江云益健互联网医院问诊平台（下称“平台”或“我们”）。本平台上的互联网医院为患者（下称“患者”或“您”）提供包括但不限于互联网诊疗咨询、复诊开方、健康管理等服务（下称“在线诊疗”）。我们特将在线诊疗的相关重要事项告知您及您的家属，请认真阅读并理解，尤其是下划线部分文字。
                </p>
                <p>一、在线诊疗具有相当的局限性</p>
                <p>
                  由于在线诊疗过程中不能进行现场查体，对某些隐蔽性症状体征可能会有所疏忽，不能及时在线开展实验室生化检查和影像检查等，不能对患者急危重症采取快速现场应对；故医方在线诊疗服务中能够采取的有效措施较为有限。
                </p>
                <p>
                  在线诊疗中医生获取的信息完全有赖于您及您家属通过视频、图文的准确表述；故请您和您的家属务必配合医务人员进行必要、准确的病情描述。若因您及您家属对病情的描述不准确、不完整、不配合等，均有可能因对病情描述不当导致误诊误治的不利后果；若因此则需患者对此承担相应责任。
                </p>
                <p>二、疾病的自身风险</p>
                <p>
                  疾病的病程演变是复杂多变，且往往因为个体具有一定差异性。同时，常会有病情恶化、治疗不效、合并症加重、并发症出现，医疗意外等风险随时发生。有的可能是可以预见，有的是不能预见，有的是难以预见也难以预防的，这些都是疾病本身产生的风险。若您在在线诊疗中或在线诊疗后，若发现自身的症状和体征发生改变或恶化，或有明显的身体不适，请您立刻中断在线诊疗或停止相应治疗方案，并及时就近急诊就医，以免贻误病情。
                </p>
                <p>三、线上行为的当然效果</p>
                <p>
                  由于线上诊疗中，不同于线下现场诊疗，我们虽然已经对患者的实名信息予以核验，但平台难以时时核验患者端操作人的实人身份。对于患者端操作人开展病情告知、医患问答、发表选择和决定性意见、签署平台内的各种法律文件和办理在线诊疗中的流程手续，互联网医院将视同为该患者端操作人为患者本人，或已经取得患者本人充分授权，或作为患者的监护人有权操作；故该患者端操作人所进行的任何操作行为，对患者均具有相应的法律效力。
                </p>
                <p>
                  若患者端操作并非患者本人，为患者的授权人或患者的监护人，则应充分了解患者的症状体征，并尽量全面完整的提供相应的线下诊疗书面资料，以便接诊医生获取充分疾病信息并开展相应诊治措施。
                </p>
                <p>四、对个人健康信息的处理及保护</p>
                <p>
                  我们非常重视并致力于对个人健康信息的保护，且遵循合法、正当、必要的原则来收集您提交的各项个人健康信息，用于线上医生为您服务。我们采取符合业界标准、合理可行的方式对患者的个人健康信息进行存储及保护。
                </p>
                <p>五、在线就诊关于精麻类药品的说明</p>
                <p>
                  根据《四川省互联网医院管理办法》第三十条规定“互联网医院不得开展以下服务：首诊患者诊疗服务；危急重症患者诊疗服务；麻醉药品、精神药品等用药风险较高、有其他特殊管理规定的药品处方开具和配送（用于检查类且交付实体医疗机构使用的药品除外）。互联网医院不开具精麻类的药品。
                </p>
                <p> 六、其他在线诊疗的风险告知</p>
                <p>
                  疾病的治愈需要患者谨遵医嘱、健康管理方案，并积极配合。如果患者未完全遵医嘱，则可能导致诊疗效果不理想，甚至出现病情反复、恶化等不良后果。
                </p>
                <p>
                  患者自采药品的品牌、规格、性状、使用方式等可能影响本次诊疗方案的效果，同时还可能出现危害生命健康的风险。请遵医嘱，若医生告知此次处方不适用等情况时，请在所在药店问询店员更换药品。
                </p>
                <p>
                  本患者知情同意书未能一一列出所有风险，如果患者有疑问应与医生讨论。
                </p>
                <p>
                  您理解并同意，互联网医院将对您的个人健康信息等与病历相关的信息依法予以保存，该信息将保存至中江云益健互联网医院平台的健康档案中心（该名称如发生变化则适用新的名称）；您在享受中江云益健互联网医院平台内为您展示的其他服务时，您可以选择调用或使用在本次服务中的相关个人信息，帮助您便捷得享受其他服务；例如：您在使用中江云益健互联网医院平台提供的线上问诊服务时，可以调用互联网医院电子档案的就诊人的姓名、身份照号、性别、年龄等信息，便于您准确和及时得提供信息；您在使用线上购药的服务时，可以调用互联网医院及电子档案的就诊人的姓名、身份照号、性别、年龄、历史就诊信息或主诉描述等信息，便于您快速问诊或选择对应的药品；届时您将可以直接选择调用健康档案内的信息，或者自行填写新的信息，我们均按您的需求予以支持。
                </p>
                <p>七、您确认如下：</p>
                <p>
                  若您选择在本平台上的互联网医院享受在线诊疗服务，您确认您已经在线下实体医疗机构进行过诊疗且有明确临床诊断结论。您可以提供或上传您的相关的病历资料，愿意接受在线诊疗服务。您确认既往发生过与本次发病类似的常见病、慢性病症，且本次参与在线诊疗的症状和体征均和既往线下就诊情况基本一致，您不会向医生提供虚假、不实的信息。您愿意接受互联网医院提供旳在线诊疗服务，并协助平台上的互联网医院完善健康档案。
                </p>
                <p>
                  您确认已经完全理解并同意以上全部內容，已经知悉相关的风险，愿意接受在线诊疗服务，并知悉和同意本风险告知及知情同意书。
                </p>
              </p>
              {filterNotice ? (
                <>
                  <p className="notice-btn">
                    <Button 
                      block shape='rounded' color='primary'
                      style={{ width:'30vw'}}
                      onClick={this.handleCloseVis}
                    >
                      我已知晓
                    </Button>
                  </p>
                </>
              ) : (
                <>
                  <p className="notice-btn">
                    <Button 
                      block shape='rounded' color='primary'
                      style={{ width:'30vw'}}
                      disabled={timeNotice == 0 && scrolledToBottom ? false : true}
                      onClick={this.handleCloseVis}
                    >
                      我已知晓{timeNotice == 0 && !isRunningTime ? null : "(" + timeNotice + "s)"}
                    </Button>
                  </p>
                </>
              )}
              
            </div>
          ) : null}

          {/* {visible2 ? (
            <div
              className="setbox risknotification"
              style={{ backgroundColor: "#fff" }}
            >
              <h2 className="risk-title" style={{ marginTop: "10px" }}>
                风险告知及患者知情同意书
              </h2>
              <p className="risk-body risk-body1">
                <p>亲爱的患者及家属朋友，您好！</p>
                <p>
                  欢迎来到中江云益健互联网医院问诊平台（下称“平台”或“我们”）。本平台上的互联网医院为患者（下称“患者”或“您”）提供包括但不限于互联网诊疗咨询、复诊开方、健康管理等服务（下称“在线诊疗”）。我们特将在线诊疗的相关重要事项告知您及您的家属，请认真阅读并理解，尤其是下划线部分文字。
                </p>
                <p>一、在线诊疗具有相当的局限性</p>
                <p>
                  由于在线诊疗过程中不能进行现场查体，对某些隐蔽性症状体征可能会有所疏忽，不能及时在线开展实验室生化检查和影像检查等，不能对患者急危重症采取快速现场应对；故医方在线诊疗服务中能够采取的有效措施较为有限。
                </p>
                <p>
                  在线诊疗中医生获取的信息完全有赖于您及您家属通过视频、图文的准确表述；故请您和您的家属务必配合医务人员进行必要、准确的病情描述。若因您及您家属对病情的描述不准确、不完整、不配合等，均有可能因对病情描述不当导致误诊误治的不利后果；若因此则需患者对此承担相应责任。
                </p>
                <p>二、疾病的自身风险</p>
                <p>
                  疾病的病程演变是复杂多变，且往往因为个体具有一定差异性。同时，常会有病情恶化、治疗不效、合并症加重、并发症出现，医疗意外等风险随时发生。有的可能是可以预见，有的是不能预见，有的是难以预见也难以预防的，这些都是疾病本身产生的风险。若您在在线诊疗中或在线诊疗后，若发现自身的症状和体征发生改变或恶化，或有明显的身体不适，请您立刻中断在线诊疗或停止相应治疗方案，并及时就近急诊就医，以免贻误病情。
                </p>
                <p>三、线上行为的当然效果</p>
                <p>
                  由于线上诊疗中，不同于线下现场诊疗，我们虽然已经对患者的实名信息予以核验，但平台难以时时核验患者端操作人的实人身份。对于患者端操作人开展病情告知、医患问答、发表选择和决定性意见、签署平台内的各种法律文件和办理在线诊疗中的流程手续，互联网医院将视同为该患者端操作人为患者本人，或已经取得患者本人充分授权，或作为患者的监护人有权操作；故该患者端操作人所进行的任何操作行为，对患者均具有相应的法律效力。
                </p>
                <p>
                  若患者端操作并非患者本人，为患者的授权人或患者的监护人，则应充分了解患者的症状体征，并尽量全面完整的提供相应的线下诊疗书面资料，以便接诊医生获取充分疾病信息并开展相应诊治措施。
                </p>
                <p>四、对个人健康信息的处理及保护</p>
                <p>
                  我们非常重视并致力于对个人健康信息的保护，且遵循合法、正当、必要的原则来收集您提交的各项个人健康信息，用于线上医生为您服务。我们采取符合业界标准、合理可行的方式对患者的个人健康信息进行存储及保护。
                </p>
                <p> 五、其他在线诊疗的风险告知</p>
                <p>
                  疾病的治愈需要患者谨遵医嘱、健康管理方案，并积极配合。如果患者未完全遵医嘱，则可能导致诊疗效果不理想，甚至出现病情反复、恶化等不良后果。
                </p>
                <p>
                  患者自采药品的品牌、规格、性状、使用方式等可能影响本次诊疗方案的效果，同时还可能出现危害生命健康的风险。请遵医嘱，若医生告知此次处方不适用等情况时，请在所在药店问询店员更换药品。
                </p>
                <p>
                  本患者知情同意书未能一一列出所有风险，如果患者有疑问应与医生讨论。
                </p>
                <p>
                  您理解并同意，互联网医院将对您的个人健康信息等与病历相关的信息依法予以保存，该信息将保存至中江云益健互联网医院平台的健康档案中心（该名称如发生变化则适用新的名称）；您在享受中江云益健互联网医院平台内为您展示的其他服务时，您可以选择调用或使用在本次服务中的相关个人信息，帮助您便捷得享受其他服务；例如：您在使用中江云益健互联网医院平台提供的线上问诊服务时，可以调用互联网医院电子档案的就诊人的姓名、身份照号、性别、年龄等信息，便于您准确和及时得提供信息；您在使用线上购药的服务时，可以调用互联网医院及电子档案的就诊人的姓名、身份照号、性别、年龄、历史就诊信息或主诉描述等信息，便于您快速问诊或选择对应的药品；届时您将可以直接选择调用健康档案内的信息，或者自行填写新的信息，我们均按您的需求予以支持。
                </p>
                <p>六、您确认如下：</p>
                <p>
                  若您选择在本平台上的互联网医院享受在线诊疗服务，您确认您已经在线下实体医疗机构进行过诊疗且有明确临床诊断结论。您可以提供或上传您的相关的病历资料，愿意接受在线诊疗服务。您确认既往发生过与本次发病类似的常见病、慢性病症，且本次参与在线诊疗的症状和体征均和既往线下就诊情况基本一致，您不会向医生提供虚假、不实的信息。您愿意接受互联网医院提供旳在线诊疗服务，并协助平台上的互联网医院完善健康档案。
                </p>
                <p>
                  您确认已经完全理解并同意以上全部內容，已经知悉相关的风险，愿意接受在线诊疗服务，并知悉和同意本风险告知及知情同意书。
                </p>
                <p className="back1">
                  <span onClick={() => this.setState({ visible2: false })}>
                    我已知晓
                  </span>
                </p>
              </p>
            </div>
          ) : null} */}

          <>
            <div className="patientinformation-top-bgc">
              <p className="patientinformation-top">
                <span className="fanhui" onClick={() => this.toReturnHome()}>
                  <img className="left-jt" src={fhicon1} />
                  <span>返回</span>
                </span>
                <span onClick={() => this.toAddpatient()}>选择/新增患者＞</span>
              </p>
              {!defultUser ? (
                <p
                  className="patientinformation-user"
                  onClick={() => this.toAddpatient()}
                >
                  <img className="add-img" src={jia2} />
                  <p className="add-news">添加患者信息</p>
                </p>
              ) : (
                <p
                  className={
                    defultUser.patient_sex == "男"
                      ? "patientinformation-user"
                      : "patientinformation-user patientinformation-user01"
                  }
                  onClick={() => this.toAddpatient()}
                  // style={{backgroundColor:'yellow'}}
                >
                  <p className="news-user-top-right">
                    {defultUser.relationship === null && (
                      <span
                        style={{
                          display: "inline-block",
                          width: "8px",
                          height: "14px",
                          // overflow: "hidden",
                        }}
                      >
                        &nbsp;
                      </span>
                    )}
                    {defultUser.relationship !== null && (
                      <span
                        style={{
                          backgroundColor: "#FFEED6",
                          padding: "2px 8px",
                          color: "#FFA625",
                          fontWeight: "500",
                          // fontSize: "12px",
                          borderRadius: "0 10px 0 10px",
                        }}
                      >
                        {defultUser.relationship}
                      </span>
                    )}
                  </p>
                  <p className="news-user-top">
                    <span className="news-user-left">
                      <img src={touxiang} />
                      <span>{defultUser.patient_name}</span>
                    </span>
                    <span className="news-user-right">
                      <span>{defultUser.patient_sex}</span>
                      <span>{defultUser.age}岁</span>
                      {defultUser.patientIdCard ? (
                        <span>{defultUser.patientIdCard}</span>
                      ) : (
                        <span style={{ backgroundColor: "#fff" }}></span>
                      )}
                    </span>
                  </p>
                  <p className="news-user-two">
                    <p>
                      疾病史：
                      {defultUser.past_history_note != "无" ? (
                        <span className="news-user-blue">
                          {defultUser.past_history_note}
                        </span>
                      ) : (
                        <span>{defultUser.past_history_note}</span>
                      )}
                    </p>
                    <p>
                      过敏史：
                      {defultUser.allergic_history_note != "无" ? (
                        <span className="news-user-blue">
                          {defultUser.allergic_history_note}
                        </span>
                      ) : (
                        <span>{defultUser.allergic_history_note}</span>
                      )}
                    </p>
                  </p>
                  <p className="news-user-two">
                    <p>
                      肝肾功能：
                      {defultUser.abnormal_liver_function == 0 ? (
                        "无异常"
                      ) : (
                        <span className="news-user-blue">异常</span>
                      )}
                    </p>
                    {/* <p>
                      肾功能：
                      {defultUser.abnormal_renal_function == 0 ? (
                        "无异常"
                      ) : (
                        <span className="news-user-blue">异常</span>
                      )}
                    </p> */}
                    <p>
                      {defultUser.patient_sex == "女" ? (
                        <p>
                          怀孕情况：
                          {defultUser.pregnancy_status == 1
                            ? "未怀孕"
                            : defultUser.pregnancy_status == 2
                            ? "备孕中"
                            : defultUser.pregnancy_status == 3
                            ? "已怀孕"
                            : "哺乳期"}
                        </p>
                      ) : null}
                    </p>
                  </p>
                  {/* {defultUser.patient_sex == "女" ? (
                    <p className="news-user-two">
                      <p>
                        怀孕情况：
                        {defultUser.pregnancy_status == 1
                          ? "未怀孕"
                          : defultUser.pregnancy_status == 2
                          ? "备孕中"
                          : defultUser.pregnancy_status == 3
                          ? "已怀孕"
                          : "哺乳期"}
                      </p>
                    </p>
                  ) : null} */}
                </p>
              )}
            </div>
            <div className="med-lists-title-way">
              {/* <p className="med-lists-title">
                <p className="med-lists-title-left">
                  <span className="dian"></span>
                  <span className="name">选择支付方式</span>
                </p>
                <p className="med-lists-title-right">
                  （医保统筹需填写身份证号码）
                </p>
              </p>
              <p className="medical-nsurance-coordination">
                <p
                  className="all-pay-ways"
                  onClick={() =>
                    this.setState({
                      coordinationcode: 2,
                    })
                  }
                >
                  <span className={coordinationcode == 2 ? "blue-pay" : ""}>
                    个人支付
                  </span>
                </p>
                <p
                  className="all-pay-ways"
                  onClick={() =>
                    this.setState({
                      coordinationcode: 1,
                    })
                  }
                >
                  <span className={coordinationcode == 1 ? "blue-pay" : ""}>
                    门诊统筹
                  </span>
                </p>
              </p>
              <p className="line"></p> */}
              <p className="med-lists-title">
                <p className="med-lists-title-left">
                  <span className="dian"></span>
                  <span className="name">医生选择</span>
                </p>
                <p className="med-lists-title-right"></p>
              </p>
              <p className="medical-nsurance-coordination">
                <p
                  className={
                    selectdoctor == 1
                      ? "coordination coordination1"
                      : "coordination coordinationc2"
                  }
                  onClick={() =>
                    this.setState({
                      selectdoctor: 1,
                      docterId: "",
                      docterName: "",
                    })
                  }
                >
                  {selectdoctor == 1 ? <img src={icon1} /> : null}
                  <span className="doc-select">
                    <span>快速问诊</span>
                    <span>随机分配医生，快速接诊</span>
                  </span>
                </p>
                <p
                  className={
                    selectdoctor == 2
                      ? "coordination coordination1"
                      : "coordination coordinationc2"
                  }
                  onClick={() => this.toselectDoctor()}
                >
                  {selectdoctor == 2 ? <img src={icon1} /> : null}
                  {!docterName ? (
                    <span className="doc-select">
                      <span>选择医生</span>
                      <span>指定医生接诊</span>
                    </span>
                  ) : (
                    <span className="doc-select">
                      <span>{docterName}</span>
                      <span>
                        {selectdoctormiaosu.length <= 10
                          ? selectdoctormiaosu
                          : selectdoctormiaosu.substr(0, 10) + "..."}
                      </span>
                    </span>
                  )}
                </p>
              </p>
              <p className="line"></p>
              <p className="is-has-entity">
                <span className="is-has-entity-left">是否已在实体医院就诊，本次为复诊</span>
                <span className="is-has-entity-right">
                  <span className="is-has-entity-right-radio">
                    <input
                      type="radio"
                      name="entity"
                      checked={entitycode == 1 ? true : false}
                      onClick={() =>
                        this.setState({
                          entitycode: 1,
                          visible6: reviewercode ? false : true,
                        })
                      }
                    />
                    <span>是</span>
                  </span>
                  <span className="is-has-entity-right-radio">
                    <input
                      type="radio"
                      name="entity"
                      checked={entitycode == 2 ? true : false}
                      onClick={() =>
                        this.setState({
                          entitycode: 2,
                        })
                      }
                    />
                    <span>否</span>
                  </span>
                </span>
              </p>
              {entitycode != 1 ? (
                <p className="use-med-tips">
                  为保障您的用药安全，初诊请至线下医院就诊开药
                </p>
              ) : null}
            </div>
            {/*<p className='med-lists-title'>*/}
            {/*    <p className='med-lists-title-left'>*/}
            {/*        <span className='dian'></span>*/}
            {/*        <span className='name'>选择支付方式</span>*/}
            {/*    </p>*/}
            {/*    <p className='med-lists-title-right'>（医保统筹需填写身份证号码）</p>*/}
            {/*</p>*/}
            {/*<p className='medical-nsurance-coordination'>*/}
            {/*    <p className={coordinationcode == 2 ? 'coordination coordination1' : 'coordination coordinationc2'}*/}
            {/*       onClick={() => this.setState({*/}
            {/*           coordinationcode: 2*/}
            {/*       })}>*/}
            {/*        {coordinationcode == 2 ? <img src={icon1}/> : null}*/}
            {/*        <span>个人支付</span>*/}
            {/*    </p>*/}
            {/*    <p className={coordinationcode == 1 ? 'coordination coordination1' : 'coordination coordinationc2'}*/}
            {/*       onClick={() => this.setState({*/}
            {/*           coordinationcode: 1*/}
            {/*       })}>*/}
            {/*        {coordinationcode == 1 ? <img src={icon1}/> : null}*/}
            {/*        <span>门诊统筹</span>*/}
            {/*    </p>*/}

            {/*</p>*/}
            <p className="med-lists-title">
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">选择问诊方式</span>
              </p>
            </p>

            <p className="wenzhen-way">
              {videocode == 0 ? (
                <p
                  className="wenzhen-way-one wenzhen-way-one0"
                  onClick={() => this.toSelecttuwen()}
                >
                  <p className="select-way">
                    <span>选择 ✔</span>
                  </p>
                  <p className="tuwen">
                    <i className="iconfont icon-tuwenxiangqing" />
                    <span>图文问诊</span>
                  </p>
                  <p className="doc">医生根据病例问诊开方</p>
                </p>
              ) : (
                <p
                  className="wenzhen-way-one wenzhen-way-one00"
                  onClick={() => this.toSelecttuwen()}
                >
                  <p className="tuwen">
                    <i className="iconfont icon-tuwenxiangqing" />
                    <span>图文问诊</span>
                  </p>
                  <p className="doc">医生根据病例问诊开方</p>
                </p>
              )}
              {videocode == 1 ? (
                <p
                  className="wenzhen-way-one wenzhen-way-one1"
                  onClick={() => this.toSelectvideo()}
                >
                  <p className="select-way">
                    <span>选择 ✔</span>
                  </p>
                  <p className="video">
                    <i className="iconfont icon-shipinhuiyi" />
                    <span>视频问诊</span>
                  </p>
                  <p className="doc">医生面对面开方</p>
                </p>
              ) : (
                <p
                  className="wenzhen-way-one wenzhen-way-one11"
                  onClick={() => this.toSelectvideo()}
                >
                  <p className="video">
                    <i className="iconfont icon-shipinhuiyi" />
                    <span>视频问诊</span>
                  </p>
                  <p className="doc">医生面对面开方</p>
                </p>
              )}
            </p>

            {/* 中药-姜雨杰 */}
            <p className="chamed-lists-title">
              <p className="chamed-lists-title-left">
                <span className="dian"></span>
                <span className="name">药品清单</span>
                <span className="chamed-lists-title-right">以下用量为1剂，1剂共计{chamedarr.length}味</span>
              </p>
              <p className="chamed-lists-title-right2" onClick={() => this.toHomeChaMed()}>
                <img className="chamed-lists-img" src={jia2} />
                <span className="name">添加药品</span>
              </p>
            </p>
            <div className="allchameds-list">
              <div className="all-lists">
                {chamedarr.length ? (
                  chamedarr.slice(0, this.state.showAll ? chamedarr.length : 6).map((item, index) => (
                    <div className="one-meds">
                      <p className='cha-med-list-p'>
                        <span className='cha-med-list-name'>{item.pro_name ? item.pro_name : item.pro_com_name}</span>
                        <span className='cha-med-input'>
                          <span             
                            className='cha-med-keshu'
                            contenteditable="true" 
                            onBlur={e => this.handleJiacountChange(e, item)}
                            onKeyDown={this.handleJiacountKeyDown}
                            onKeyPress={this.handleJiacountKeyPress}>
                            {item.jiacountValue}
                          </span>
                          <span>g</span>
                        </span>                                                                       
                        <img src={zysc} onClick={() => this.handleShancShow(item)}/>                                     
                      </p>
                    </div>
                  ))
                ) : null}
              </div>
            
              {chamedarr.length > 6 ? (
                this.state.showAll ? (
                  <p className="zhankaiall" onClick={() => this.setState({ showAll: false })}>收起》</p>
                ) : (
                  <p className="zhankaiall" onClick={() => this.setState({ showAll: true })}>展开全部》</p>
                )
              ) : null}

            </div>
            <p className="chamed-lists-title">
              <p className="chamed-lists-title-left">
                <span className="dian"></span>
                <span className="name">用法用量</span>
              </p>
            </p>
            <div className="allchameds-list">
              <div className="yongfa">
                <p className="yongfa1">
                  中药共需<input type="text" value={this.state.allarr} onChange={(e) => this.handleInputChange(e, "allarr")} />剂;
                </p>
                <p className="yongfa2">
                  <p className="input-one">
                    <input 
                      className="input1"
                      placeholder="每日"
                      value={this.state.meiday}
                      onClick={() => this.handlePickerVisibleChange(true)}
                      readOnly
                    />
                    <Picker
                      title=""
                      data={meidayOptions}
                      visible={pickerVisible}
                      value={[meiday]}
                      onOk={this.handleConfirm}
                      onDismiss={() => this.handlePickerVisibleChange(false)}
                      cols={1}
                      
                    />
                    <img src={xiala} />
                  </p>
                  {/* 每日 */}
                  <input className="input2" type="text" value={this.state.dayarr} onChange={(e) => this.handleInputChange(e, "dayarr")} />剂
                </p>
              </div>
              <p className="yongfaline1"></p>
              <div className="yongfa">
                <p className="yongfa1">每剂分<input type="text" value={this.state.mlarr1} onChange={(e) => this.handleInputChange(e, "mlarr1")} />次使用;</p>
                <p className="yongfa2">每次<input type="text" value={this.state.mlarr2} onChange={(e) => this.handleInputChange(e, "mlarr2")} /> 
                      <input  className='yfunit' 
                          type="text"
                          readOnly 
                          value={this.state.units}
                          onChange={(e) => this.getvalue(e, index, 'units')}
                          ref={input => this.inputRef = input}
                          onClick={()=>{
                            this.setState({
                              visible10:true
                            })
                          }}
                      />
                      <img className="yfunit-img" src={xiala} />      
                    
                {visible10 ? 
                  <div className='up-bottom'>
                    <div>
                        <div className='use-medicine' onClick={() => {
                            this.setState({ visible10: false }, () => {
                                this.useMedicineAndUnit("自填用药单位", "units", unit)
                            })
                        }}
                        ><span>自填用药单位</span></div>

                        {unit.map((item, index) => (
                          <span  
                            className={item == this.state.selectedUnit ? 'red' : ''}
                            onClick={() => this.setState({ selectedUnit: item })} >
                              {item}
                          </span>
                        ))}
                        <p onClick={()=>{
                          // 将选中的值作为参数传递给 `getvalue` 方法
                          this.getvalue(this.state.selectedUnit, index, 'units');
                          this.inputRef.value = this.state.selectedUnit;

                          this.handleSure(this.state.selectedUnit,index,'units')

                          // this.setState({
                          //   visible10:false
                          // })
                        }}>确定</p>
                    </div>
                  </div> : null}


                </p>
             
              </div>
              <p className="yongfaline1"></p>
              <div className="yongfa3">
                <span>用药方式：</span>
                <p className={selectmedsmethod == '水煎内服' ? "yongfa3-method2" : "yongfa3-method1"} 
                    onClick={() =>
                      this.clickMethod(1)
                    }
                >
                  {selectmedsmethod == '水煎内服' ? <img src={gou} alt="" /> : null }
                  <span className="yongfa4">水煎内服</span>
                </p>
                <p className={selectmedsmethod == '水煎外用' ? "yongfa3-method2" : "yongfa3-method1"} 
                  onClick={() =>
                    this.clickMethod(2)
                  }
                >
                  {selectmedsmethod == '水煎外用' ? <img src={gou} alt="" /> : null }
                  <span className="yongfa4">水煎外用</span>
                </p>
              </div>

              <div className="yongfa33">
                <p className={selectmedsmethod == this.state.methodarr ? "yongfa3-method2" : "yongfa3-method1"} 
                    onClick={() =>
                      {
                        this.clickMethod(3);
                      }
                    }
                >
                  {selectmedsmethod == this.state.methodarr ? <img src={gou} alt="" /> : null }
                  <span className="yongfa4">其他</span>
                </p>
                <input type="text" 
                  placeholder="请输入用药方式" 
                  value={this.state.methodarr} 
                  onChange={(e) => this.handleInputMethod(e)} 
                  onClick={()=>{
                      this.setState({
                        selectmedsmethod : this.state.methodarr
                      })                    
                  }}
                />
              </div>


              <p className="yongfaline"></p>
              <div className="yongfa5">
                <span style={{ whiteSpace: 'nowrap' }}>用药时间：</span>
                <p className="yongfa6">
                  <label className="yongfa6-time">
                    <input type="radio" name="radiotime" className="radio-time" value="1" checked={this.state.selectedValue == "1"} onChange={this.handleRadioChange} />
                    <span>饭前用药</span>
                  </label>
                  <label className="yongfa6-time">
                    <input type="radio" name="radiotime" className="radio-time" value="2" checked={this.state.selectedValue == "2"} onChange={this.handleRadioChange} />
                    <span>饭后用药</span>
                  </label>
                  <label className="yongfa6-time">
                    <input type="radio" name="radiotime" className="radio-time" value="3" checked={this.state.selectedValue == "3"} onChange={this.handleRadioChange} />
                    <span>睡前用药</span>
                  </label>
                  <label className="yongfa6-time">
                    <input type="radio" name="radiotime" className="radio-time" value="4" checked={this.state.selectedValue == "4"} onChange={this.handleRadioChange} />
                    <span>晨起用药</span>
                  </label>
                </p>
              </div>
            </div>
            <p className="chamed-lists-title">
              <p className="chamed-title">
                请选择线下已确诊疾病<span style={{ color: "red" }}>*</span>
                <span className="tit">疾病需选一种，总数不超过3种</span>
              </p>
            </p>
            <div className="allchameds-list1">
            {list1 && list1.length > 0 ? (
                <>
                  {list1.filter((item) => item.index === 2).map((i, v) => (
                    <>
                      <span
                        key={v}
                        className={i.index === 1 ? "nocolor" : "hascolor"}
                        onClick={() => {
                          this.selectChaMedKj();
                        }}
                      >
                        {i.value}
                      </span>
                      <i
                        className="iconfont icon-guanbi2fill"
                        style={{
                          marginLeft: "3px",
                          fontSize: "18px",
                          color: "#AEAEAE",
                          marginRight:'10px'
                        }}
                        onClick={() => {
                          this.selectAgainCha(i.value);
                        }}
                      />
                    </>
                  ))}
                  {list1.length < 3 && (
                    <span
                      className="chaxuanze"
                      onClick={() => {
                        this.selectChaMedKj();
                      }}
                    >
                      选择+
                    </span>
                  )}
                </>
              ) : (
                <span
                  className="chaxuanze"
                  onClick={() => {
                    this.selectChaMedKj();
                  }}
                >
                  选择+
                </span>
              )}
            </div>

            <p className="med-lists-title" style={{ marginTop: 0 }}>
              <p className="med-lists-title-left">
                <span className="dian"></span>
                <span className="name">病史资料</span>
              </p>
              <p className="med-lists-title-right"></p>
            </p>
            <div className="ill-history">

              <p className="ill-history-title-one">
                <span className="ill-history-title">上传就诊凭证</span>
                <span className="right-tip-upload">上传线下就诊历史处方</span>
              </p>
              <p className="pingzhentit">
                <p className="pingzhen">
                  <p className="pingzhen-cont">
                    {isUploaded ? (
                      <>
                        <>
                          <img
                            className="yulan-img"
                            src={imageUrl}
                            alt="预览图"
                            onClick={this.handleYlimg}
                          />
                          {this.state.showPreview && (
                            <div className="preview">
                              <img className="preview-image" src={this.state.imageUrl} alt="预览" onClick={this.handleYlimg} />
                            </div>
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        <label htmlFor="imageInput">
                          {imageUrl ? (
                            <img className="yulan-img" src={this.state.imageUrl} alt="" />
                          ) : (
                            <img src={pingzhen} alt="" />
                          )}
                        </label>
                        <input
                          id="imageInput"
                          type="file"
                          style={{ opacity: 0, position: 'absolute', top: 0, left: 0 }}
                          ref={this.fileInputRef}
                          onChange={this.handleImageUpload}
                        />
                      </>
                    )}
                  </p>
                </p>
                {imageUrl ? <p className="pingzhen-conts1"><img className="pzsc" src={zysc} onClick={this.handleJzimg} /></p> :null}
              </p>
              
              <p className="yongfaline2"></p>

              <p className="ill-history-title">是否使用过此类药物？</p>
              <p className="ill-history-ishave ill-history-ishave1">
                <span
                  className={isuse == 0 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isuse: 0 })}
                >
                  是
                </span>
                <span
                  className={isuse == 1 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isuse: 1 })}
                >
                  否
                </span>
              </p>
              <p className="yongfaline2"></p>
              <p className="ill-history-title">是否对此类药品过敏？</p>
              <p className="ill-history-ishave ill-history-ishave1">
                <span
                  className={isadverse == 1 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isadverse: 1 })}
                >
                  是
                </span>
                <span
                  className={isadverse == 0 ? "nouse" : "ishave"}
                  onClick={() => this.setState({ isadverse: 0 })}
                >
                  否
                </span>
              </p>
            </div>
            {/*<div className='patientinformation-bottom'>*/}
            {/*    /!*<input type='radio' name='a' checked={selectcode==1} onClick={()=>this.toSelect()}/>*!/*/}
            {/*    <CheckboxItem checked={videocode == 1} onClick={() => this.toSelectvideo()} className='my-radio'/>*/}
            {/*    <p>视频问诊</p>*/}
            {/*</div>*/}
            <div className="patientinformation-bottom">
              {/*<input type='radio' name='a' checked={selectcode==1} onClick={()=>this.toSelect()}/>*/}
              <CheckboxItem
                checked={selectcode == 1}
                onClick={() => this.toSelect()}
                className="my-radio"
              />
              <p>
                我已阅读并同意{" "}
                <a onClick={this.handleOpenVis}>
                  《风险告知及患者知情同意书》
                </a>
                ，并确认以上信息填写属实。
              </p>
            </div>
            <p className="contact-service">
              <span>如有问题或建议，点击</span>
              <span
                onClick={() =>
                  this.setState({
                    visible5: true,
                  })
                }
              >
                联系我们
              </span>
            </p>
            <div className="patientinformation-sure">
              {(listchose.length
                ? listchose.toString() + (miaosu ? ";" + miaosu : "")
                : miaosu) &&
              selectcode &&
              isuse == 0 &&
              isadverse == 0 &&
              defultUser &&
              entitycode == 1 ? (
                <p
                  className="patientinformation-sure-bottom"
                  onClick={() => this.nextStep()}
                >
                  确认/咨询医生
                </p>
              ) : (
                <p
                  className="patientinformation-sure-bottom1"
                  onClick={() => this.toTips()}
                >
                  确认/咨询医生
                </p>
              )}
            </div>
          </>
          {/*:*/}
          {/*    awaitdoc == 2 ? <div className='await-doc-outer'>*/}
          {/*            <p className='await-doc-img'>*/}
          {/*                <img src={awaitDoc}/>*/}
          {/*            </p>*/}
          {/*            <p className='await-doc-xt'>系统正在为你匹配医生</p>*/}
          {/*            <p className='await-doc'>医生火速接诊中，请务离开此界面...</p>*/}
          {/*            <p className='await-quxiao' onClick={this.toquxiao}>取消问诊</p>*/}
          {/*        </div> :*/}
          {/*        awaitdoc == 3 ? <div className='await-doc-outer'>*/}
          {/*            <p className='await-doc-img'>*/}
          {/*                <img src={noDoc}/>*/}
          {/*            </p>*/}
          {/*            <p className='await-doc-xt'>很遗憾，暂时没有医生接诊</p>*/}
          {/*            <p className='await-doc await-doc1' onClick={this.toNewWz}>重新匹配医生</p>*/}
          {/*            <p className='await-quxiao1'>点击按钮可以从新匹配医生</p>*/}
          {/*        </div> : null}*/}

          {visible6 ? (
            <div className="Reviewer-sure-outer">
              <div className="Reviewer-sure-inner">
                <h2>问诊须知</h2>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    1.按照国家相关法律法规，本次医疗服务，仅向复诊与服用过所需药品并无不良反应的患者提供。{" "}
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    2.患者需在实体医院就诊且已有明确诊断，取得病例记录或诊断处方后方可使用平台服务。未在实体医院就诊，将无法使用该服务。
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    3.因您购买的药品为处方类药物，平台将收集您的用户信息(患者姓名，性别，联系方式，患者身份证号，用药信息，疾病史就诊凭证等)，前述信息您同意授权向互联网医院提供，且前述信息仅该药店及互联网医院可见。{" "}
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    4.本次您提供填写的信息将会被严格保密除为您提供诊疗服务使用，不会用作其他目的。
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    5.患者需承诺填写的内容均为真实有效，否则将承担一切法律责任。
                  </span>
                </p>
                <p className="sure-Reviewer sure-Reviewer1">
                  <span>
                    6.本平台仅提供在线问诊开方服务，不提供药品销售，如用药过程中出现病情变化或其它不适症状，请立即停药并及时到医院就医。
                  </span>
                </p>
                <p
                  className="already-Reviewer"
                  onClick={() =>
                    this.setState({
                      visible6: false,
                      reviewercode: true,
                      // selectcode:1
                    })
                  }
                >
                  我已确定
                </p>
              </div>
            </div>
          ) : null}
          {visible5 ? (
            <div className="contact-complaint-outer">
              <div className="contact-complaint-inner">
                <img
                  className="close"
                  src={icon2}
                  onClick={() =>
                    this.setState({
                      visible5: false,
                    })
                  }
                />
                <h2>联系或投诉</h2>
                <p className="copy-phone">
                  <span>客服电话：</span>
                  <span id="contactPhone">19048012565</span>
                  <i
                    className="iconfont icon-baocun"
                    onClick={() => this.copykefuhone()}
                  />
                </p>
                <p className="tips-contact-complaint">
                  在使用中如有问题或疑问，您可以拨打电话联系我们。
                </p>
                <p className="chat-contact-complaint">
                  <img src={icon3} />
                </p>
                <p className="tips-contact-complaint">
                  添加客服微信，及时处理您的问题
                </p>
              </div>
            </div>
          ) : null}
          <Popup
            visible={visible4}
            onMaskClick={() => {
              this.setState({
                visible4: false,
              });
            }}
            bodyStyle={{
              height: "60vh",
              borderTopRightRadius: "14px",
              borderTopLeftRadius: "14px",
              overflow: "scroll",
            }}
          >
            <div className="icdoffline-popup">
              <h1 className="title" style={{ color: "#177FFF" }}>
                请选择确诊疾病
              </h1>
              <i
                className="iconfont icon-guanbi2fill"
                style={{
                  float: "right",
                  marginRight: "12px",
                  marginTop: "-18px",
                  fontSize: "18px",
                  color: "#AEAEAE",
                }}
                onClick={() => {
                  this.setState({
                    visible4: false,
                  });
                }}
              />
              <div className="home-header-icd">
                <div className="search-container">
                  <div className="flexr">
                    <img src={search} />
                    <input
                      id="iosinput"
                      ref={this.inputRef}
                      defaultValue={icdname}
                      style={{ lineHeight: "20px" }}
                      onChange={(e) => {
                        this.setValue(e)
                      }}
                      placeholder="请输入线下确诊的疾病"
                    />
                    {icdname ? (
                      <i
                        className="iconfont icon-guanbi2fill"
                        style={{
                          float: "right",
                          marginRight: "12px",
                          marginTop: "2px",
                          fontSize: "18px",
                          color: "#AEAEAE",
                        }}
                        onClick={() => {
                          this.setState({ icdname: "" })
                          if (this.inputRef.current) {
                            this.inputRef.current.value = "";
                          }
                          const { selectedItemValue } = this.state; // 获取存储的selectedItemValue
                          if (selectedItemValue) {
                            this.selectChaMedKj(selectedItemValue); // 调用selectKj方法并传递selectedItemValue
                          }
                        }}
                      />
                    ) : null}

                    <span
                      className="search-icd"
                      onClick={() => {
                        this.toSearchzy()
                      }}
                    >
                      搜索
                    </span>
                  </div>
                </div>
              </div>
              {icdList.length ? (
                icdList.map((item) => (
                  <p className="icd-name">
                    <p onClick={() => {
                      this.toSelectchamedsicd(item);
                      if (this.inputRef.current) {
                        this.inputRef.current.value = "";
                      }
                    }}>
                      <span>{item.name}</span>
                      <input type="radio" name="icdcode" />
                    </p>
                  </p>
                ))
              ) : (
                <Nodata type="疾病" />
              )}
            </div>
          </Popup>
          <Popup
            visible={visible3}
            onMaskClick={() => {
              this.setState({
                visible3: false,
              });
            }}
            bodyStyle={{
              Height: "40vh",
              borderTopRightRadius: "14px",
              borderTopLeftRadius: "14px",
            }}
          >
            <div className="offline-popup">
              <h1 className="title" style={{ color: "#177FFF" }}>
                支付提示
              </h1>
              <i
                className="iconfont icon-guanbi2fill"
                style={{
                  position: "absolute",
                  right: "12px",
                  top: "12px",
                  fontSize: "18px",
                  color: "#AEAEAE",
                }}
                onClick={() => {
                  this.setState({
                    visible3: false,
                  });
                }}
              />
            </div>
            <p className="pay-tips">该店铺为未签约用户，支付两元即可问诊</p>
            <p className="pay-money">￥2.00</p>
            <p className="pay-button">
              <button onClick={() => this.topay()}>确认支付</button>
            </p>
          </Popup>
        </p>
      </>
    );
  }
}
// []
