import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import fhhome from "../../../../static/img/icon/fhhome.png";
import search from "../../../../static/img/home/search1.png";
import icon2 from "../../../../static/img/icon/false1.png";

import './style.less'
import { searchDrugs } from "../../../../api/hospitalapi/drugs"
import medicine from './images/medicine.png'
import medicine1 from './images/medicine1.png'

/**
 * 药品搜索框
 */
class Index extends Component {

    constructor() {
        super()

        this.state = {
            records: 1,  //1:西/中成药记录,2:中药材记录
            isCollapsed: false,
            medsname: '',
            data: [],
            flag: 0,//0：第一次未搜索状态，1：未搜到状态
            page: 1,
            size: 20,
            scrollTop: 0,
        }
    }

    /**
     * 收集检索关键字
     * @param {} e 
     */
    setValue = (e) => {
        // debugger
        e.preventDefault();
        const value = e.target.value.replace(/\s/g, '')
        this.setState({
            medsname: value
        });

    }
    /**
     * 触发药品检索
     */
    triggerSearch = () => {
        let keyword = this.state.medsname
        this.searchMedicine(keyword)

    }

    /**
     * 药品检索
     * @param {} keyword 
     */
    searchMedicine = async (keyword) => {

        const { page, size } = this.state
        let res = await searchDrugs(keyword, page, size)
        // console.log(res,);
        if (res.code === 1) {
            this.setState({
                data: res.data[0],
                flag: 1
            })
        }
    }

    searchRef = createRef()


    // 触底更新数据
    onscroll = (e) => {
        const { flag, medsname } = this.state
        // console.log(111111);
        e.preventDefault()
        e.stopPropagation()
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5) {
            this.pageSearchMedicine(medsname)
        }
    }
    // 触底换页
    pageSearchMedicine = async (medsname) => {
        const { page, size, data } = this.state
        const params = {
            page: page + 1,
            size: size,
            keyword: medsname
        }
        // console.log(params, '80');
        let res = await searchDrugs(params.keyword, params.page, size)
        // console.log(res)
        if (res && res.data && res.data[0]) {
            this.setState({
                data: [...data, ...res.data[0]],
                page: params.page
            })
        }
    }


    componentDidMount() {
        if (this.state.flag == 0) {
            this.searchRef.current.focus();
        }
    }


    render() {
        const { records, isCollapsed, medsname, data, flag } = this.state
        return (
            <div className='sidebox'>
                <div className='top-tit'>
                    <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                    <span className='title-middle'>搜索药品</span>
                </div>

                <div className='headerbox'>
                    <p className='searchbox'>
                        <img src={search} />
                        <p className='p-input'>
                            <input type="text" defaultValue={medsname} onChange={(e) => this.setValue(e)} ref={this.searchRef} />
                            {medsname ? <img src={icon2} onClick={() => this.setState({ medsname: "" })} /> : null}
                        </p>
                        <span onClick={() => this.triggerSearch()}>搜索</span>
                    </p>
                </div>

                <div className='meds-middlebox' onScroll={(e) => this.onscroll(e)}>
                    {
                        data.map(item => (
                            <div className='meds-middlebox-one' key={item.med_id}>
                                <p className='one-hang'>
                                    <p>
                                        <span>药品名称：</span><span>{item.med_name}</span>
                                    </p>

                                    <p onClick={
                                        () => this.props.history.push(`/westernm?med_id=${item.med_id}&med_name=${item.med_name}&med_approval=${item.med_approval}&supervision_type=${item.supervision_type}&unit=${item.unit}&packing_unit=${item.packing_unit}&med_company=${item.med_company}&categoryId=${item.categoryId}&classifyId=${item.classifyId}&classifysonId=${item.classifysonId}&categoryName=${item.categoryName?item.categoryName:""}&classifyName=${item.classifyName?item.classifyName:""}&classifysonName=${item.classifysonName?item.classifysonName:""} `)}>选择</p>
                                        </p>
                                <p>批准文号：{item.med_approval}</p>
                                <p>药品类型：{item.supervision_type === 1 && ("中药")}{item.supervision_type === 2 && ("西药")}{item.supervision_type === 3 && ("中成药")}</p>
                                <p className='four-hang'>
                                    <p>
                                        <span>计量单位：{item.unit}</span>
                                    </p>
                                    <p>
                                        <span>包装单位：{item.packing_unit}</span>
                                    </p>
                                </p>
                                <p>生产厂家：{item.med_company}</p>
                            </div>

                        ))
                    }


                    {
                        data == '' && flag == 1 &&
                        <div class="meds-middlebox-search">
                            <p><img className='img2' src={medicine} /></p>
                            <p class="search-content1">未搜索到药品，请手动添加</p>
                        </div>
                    }

                    {
                        data == '' && flag == 0 && (<div class="meds-middlebox-search">
                            <p><img className='img1' src={medicine1} /></p>
                            <p class="search-content">请输入药品名称或国药准字点击搜索</p>
                        </div>)

                    }





                </div>


            </div>

        );
    }
}

export default withRouter(Index)