import React, { Component } from 'react'
import { Modal,  } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./style.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon3 from "../../static/img/yy/five.png"
import { prescribed } from '../../api/hospitalapi/chat'
import { oneDocpj, gzDoc, getGzdoc } from "../../api/hospitalapi/user"
import { getdoctorlist } from "../../api/hospitalapi/home"
import icon9 from "../../static/img/yy/jilu.png";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientId: localStorage.getItem("patientId"),
            docList: [],
            list: [],
            timeNow:''
        }
    }
    componentDidMount = () => {
        const { patientId, docList } = this.state
        if (!patientId) {
            Toast.show({
                content: "当前未登录，请先登录",
                duration: 1000
            })
            this.props.history.push("/login")
        }
    }
    toOrderdetails=()=>{
        let number='166354225626526523695'
        this.props.history.push(`/mine/order/id?tradeno=${number}`)
    }
    render() {
        const { list,timeNow } = this.state
        return (
            <div className='setbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/writelist")} />   支付完成
                </div>
                {/*<div>*/}
                {/*    <div className='get-erweima'>*/}
                {/*        <p className='get-erweima-pic'>*/}
                {/*            <img src={icon9} />*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <p className='talk-med'>凭此二维码到窗口取药</p>*/}
                {/*</div>*/}
                <div className='pay-success'>
                    <p>支付成功</p>
                    <p>请耐心等待药品配送</p>
                </div>
                <p className='success-over' onClick={()=>this.toOrderdetails()}>完成</p>
            </div>
        )
    }
}
