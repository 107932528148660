import React, { Component } from "react";
import chaalertstyle from "./chaalertstyle.less";
import { Toast,InputItem,  } from 'antd-mobile'

export default class ChaAlert extends Component {
  constructor(){
    super();
    this.state={
      chamedList:[],//存储中药列表
      isVisible:true,
      inputText:'',
      showTip:false,
    }
  }
  handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (/^$|^[1-9]\d{0,2}$/.test(inputValue)) {
      this.setState({
        inputText: inputValue
      });
    }
    // this.setState({ inputText: event.target.value });
  };
  handleOk=()=>{

    const { onConfirm } = this.props;
    const { inputText } = this.state;

    if (inputText == "") {
      this.setState({
        showTip:true,
      })
      return;
    }
    onConfirm(inputText); // 通过props将克数传递给父组件
    this.setState({
      showAlert: false,
      inputText: ''
    });
    
  }

  handleCancel=()=>{

    // this.setState({isVisible:false})
    const { onClose } = this.props;
    onClose(); // 通知父组件关闭所有弹窗
  }
  
  render() {  
    const {chamedList,isVisible,inputText,showTip}=this.state;

    if(!isVisible){
      return null;
    }
    return (
      <div>
        <div className="modal-alertbox">
          <div className="alert-box">
            <p className="alert-title">中药克数</p>
            <p className="cha-med-name1">中药名称：{this.props.chaMedName}</p>
            <p className="k-count">
              <span className="cha-med-count1">需要克数：</span>
              <input 
                type="text" 
                placeholder="请输入"
                value={this.state.inputText}
                onChange={this.handleInputChange}
              />
              <span className="cha-med-count1">g</span>
            </p>
            {this.state.showTip && this.state.inputText == "" && (
              <p className="input-error">请输入克数</p>
            )}
            <div className="btnbox">
              <button className="btn-cancel" onClick={this.handleCancel}>
                取消
              </button>
              <button className="btn-ok" onClick={this.handleOk}>
                确认
              </button>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}
