import React, { useEffect, useState, useTransition } from 'react'
import {NavLink,useHistory} from "react-router-dom"
import { baseUrl, jumpUrl } from "../../publicurl";

import { Radio,Button,Checkbox } from 'antd-mobile'
import {
    CheckCircleFill,
    CheckCircleOutline,
    SmileOutline,
} from 'antd-mobile-icons'

import './style.less'
import '../../static/css/iconfont.css'
import selected from "../../static/img/selected.png";

import {cartlistRequest} from "../../api/request/cart"

export default function Index() {

    const go = useHistory()
    const [ordinary, setOrdinary] = useState(true) //选择普通复诊
    const [special, setSpecial] = useState(false) //选择门特复诊
    const [visitType, setVisitType] = useState(2) //就诊类型 2：普通复诊 1：门特复诊

    const pharmacyId = localStorage.getItem('pharmacyId')
    const patientPhone = localStorage.getItem('patientPhone1')

    useEffect(() => {
        localStorage.setItem('visitType',visitType)
    }, [visitType])

    const handleChose = (flag)=>{
        switch (flag) {
            case '普通复诊':
                setOrdinary(true)
                setSpecial(false)
                setVisitType(2)
                localStorage.setItem('visitType',2)
                break;
            case '门特复诊':
                setSpecial(true)
                setOrdinary(false)
                setVisitType(1)
                localStorage.setItem('visitType',1)
                break;
        } 
    }

    const handleSumbit = () => {
        if(visitType == 1){ //二类跳转
            window.location.href = `https://c-line.yyj-health.com/index.html#/drugselection?pharmacyId=${pharmacyId}&patientPhone=${patientPhone}`;
        }else{ //三类跳转
            go.replace(`/drugselection?pharmacyId=${pharmacyId}&patientPhone=${patientPhone}`)
        } 
    }
    
    return (
        <>
            <div className='entry-wrap-box'>
                <div className='entry-title'>请选择复诊类型</div>
                <div 
                    className={ordinary ? 'entery-card-list-chose' : 'entery-card-list'} 
                    onClick={()=>{handleChose('普通复诊')}}
                >
                    <div className='left-box'>
                        <div className='title'>普通复诊</div>
                        <div className='descrip'>一般性疾病的复诊</div>
                    </div>
                    {ordinary && <img src={selected} className='img-box' alt="" />}
                </div>
                <div 
                    className={special ? 'entery-card-list-chose' : 'entery-card-list'} 
                    onClick={()=>{handleChose('门特复诊')}}
                >
                    <div className='left-box'>
                        <div className='title'>门特复诊</div>
                        <div className='descrip'>符合规定的慢性病的复诊</div>
                    </div>
                    {special && <img src={selected} className='img-box' alt="" />}
                </div>
                <div className='sure-btn'>
                    <div className='btn' onClick={()=>{handleSumbit()}}>确认</div>
                </div>
            </div>
        </>
    )
}

