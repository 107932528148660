import React, {useState} from 'react'
import cls from 'classnames'
import {useHistory} from 'react-router'
import { withRouter } from 'react-router-dom'
import getUrl from '../GetUrlParam'

import './style.less'
import '../../static/css/iconfont.css'

 function Head(props){
    const urlHash = window.location.hash
    const str = urlHash.substr(urlHash.indexOf('?'))
    const env = getUrl('env', str)
    const [searchText, setSearchText] = useState('')
    const {isclear,title,searchword,tabs,iconname,ability,clickmethod,medNum}=props
    const location=useHistory()
    // console.log(props);
    const backPage=()=>{
        if (title=='订单') {
            location.push('/mine')
        }else{
            window.history.back()
        }
    }

    const searchMed = ({target:{value,name,type}}) => {
        setSearchText(value)
    }
    return(
        <div id='common-header' style={isclear===true?{background:'rgba(220,38,38,0)'}:{}}>
            {env === 'single' ? null :
            <div className='back-icon flexr' onClick={backPage}>
                <i className='icon-xiangzuo iconfont'></i>
            </div>}
            <div className='middle-header'>
                {title?<span>{title}{medNum?`(${medNum})`:null}</span>:null}
                {searchword
                ?<div className='search-container flexr'>
                    <i className='iconfont icon-suosou'></i>
                    <input type='text'
                    placeholder={searchword}
                    onChange={searchMed}/>
                </div>
                :null
                }
                {tabs
                ?<div className='header-tab'>
                    {tabs.map((v,index)=>{
                    return(
                        <div key={index}>
                            <span>{v}</span>
                            {index===1?<div className='active-line'></div>:null}
                        </div>
                        
                    )
                    })
                    }
                </div>
                :null
                }
                {props.children}
            </div>
            <div className='right-header'>
                {iconname
                ?<div className='right-icon' onClick={clickmethod}>
                    <i className={cls(`${iconname}`,'iconfont-l')}></i>
                </div>
                :null
                }
                {ability
                ?<div onClick={()=>clickmethod(searchText)}>{ability}</div>
                :null
                }
                {iconname||ability
                ?null:<div className='header-zhanwei'></div>
                }
            </div>
        </div>
    )
    
}
export default withRouter(Head)