import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import { InputItem, Checkbox, Toast } from 'antd-mobile'
import "./registrationconfirmation.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/head.png"
import icon4 from "../../../static/img/yy/ts.png"
import icon5 from "../../../static/img/yy/wxpay.png"
import icon6 from "../../../static/img/yy/select.png"
import {} from '../../../api/hospitalapi/user'
import {getAll,} from '../../../api/hospitalapi/electronic'
import {hospitaldocterDetails, addPayment, lastNum, changeNum, changeStatus} from '../../../api/hospitalapi/rejister'
import getUrl from '../../../component/GetUrlParam'
import { zfUrl,code } from "../../../publicurl"
/*
*  挂号
* */

export default class registrationconfirmation extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:'',
            visible:false,
            array:['张三','沙发上分为各位','个无法完全二分区无法','第三方的','都波动'],
            userList:'',
            oneuserNews:'',
            id:''
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        if (getUrl('id', this.props.location.search)){
            this.setState({
                id:getUrl('id', this.props.location.search)
            })
        }

        hospitaldocterDetails({docterId:getUrl('docterId', this.props.location.search),
            id:getUrl('id', this.props.location.search)}).then(res=>{
            // console.log(res)
            if (res&&res.data&&res.data[0].length){
                this.setState({
                    list:res.data[0][0]
                })
            }
        })
        getAll({patientId:userId}).then(res=>{
            // console.log(res)
            if (res&&res.data&&res.data[0]){
                this.setState({
                    userList:res.data[0]
                })
            }
        })
    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification=()=>{
        const {value1,value2} =this.state
        // console.log(value1,value2);
    }
    changevalue=(e,code)=>{
        if (code==1) {
            this.setState({
                value1:e.target.value
            })
        }else{
            this.setState({
                value2:e.target.value
            })
        }
    }
    tolook=(consultationId,prescribingId)=>{
        localStorage.setItem('detailContent0',prescribingId)
        this.props.history.push({
            pathname: "/doctorinfo/chat/chatinfo",
            params: {
                yktext: 1,
                detailContent:consultationId,
            }
        })
    }
    showNews=()=>{
        this.props.history.push("/healthcardnews")
    }

    handleChange=(key,val)=>{
        //   console.log(val);

        if (key=='druInformation'){
        //    setDruInformation(val)
        }
    }
    //添加就诊人
    toAdd=()=>{
        const { visible,oneuserNews,list,userList } = this.state
        this.props.history.replace(`/registrationconfirmation?docterId=${list.docterId}&id=${list.id}&id0=${getUrl('id0', this.props.location.search)}&index=${getUrl('index', this.props.location.search)}`)
        this.props.history.push(`/healthcard2?registrationconfirmation=${1}&docterId=${list.docterId}&id=${list.id}&id0=${getUrl('id0', this.props.location.search)}&index=${getUrl('index', this.props.location.search)}`)
    }
    oneUser=(item)=>{
        // console.log(item)
        this.setState({
            oneuserNews:item,
            visible:true
        })
    }
    //支付
    toPay=async ()=>{
        const { visible,oneuserNews,list,userList,userId,id } = this.state
        let res0=await lastNum({docterId:list.docterId,dutyTime:list.dutyTime,clinicId:oneuserNews.id})
        // console.log(res0)
        if (res0&&res0.data&&res0.data[0]&&res0.data[0].length){
            if (res0.data[0][0].quantity>0) {
                if (res0.data[1]&&res0.data[1][0].num==0) {
                    let params = {
                        clinicId: oneuserNews.id,
                        department: list.name,
                        amount: list.cost,
                        doctorId: list.docterId,
                        userId: userId,
                        scheduleId: id,
                        clinicTime: list.dutyTime,
                        patientName: oneuserNews.patientName
                    }
                    // console.log(params)
                    let res = await addPayment(params)
                    // console.log(res)
                    if (res && res.code == 1) {
                        if (list.cost>0){
                            let b = (new Date()).getTime()
                            let orderName = '挂号支付'
                            window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${res.data[1][0].outpatient_number}&total_fee=${list.cost}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
                        }else{
                            changeNum({outpatientNumber:res.data[1][0].outpatient_number}).then(res01=>{
                                // console.log(res01)
                                if (res01&&res01.code==1){
                                    changeStatus({state:1,outpatientNumber:res.data[1][0].outpatient_number}).then(res1=>{
                                        if (res1&&res1.code==1){
                                            this.props.history.push(`/mine/historicaldetails?outpatientNumber=${res.data[1][0].outpatient_number}`)
                                        }
                                    })
                                }
                            })
                        }
                    }
                }else {
                    Toast.info('请勿重复预约')
                }
            }else {
                Toast.info('医生预约人数已满，无法继续预约')
            }
        }
    }
    goBack=()=>{
        this.props.history.push( `/register?id0=${getUrl('id0', this.props.location.search)}&index=${getUrl('index', this.props.location.search)}`)
    }
    render() {
        const { visible,oneuserNews,list,userList } = this.state
        return (
            <div className='setbox queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.goBack()} />   挂号确认
                </div>
                <div className='registrationconfirmation-outer'>
                    {list?<>
                        <div className='registrationconfirmation-details'>
                            <p className='registrationconfirmation-img'>
                                <img src={icon4}/>
                            </p>
                            <p className='registrationconfirmation-name'>{list.docterName}</p>
                            <p className='registrationconfirmation-doc'>{list.title}</p>
                        </div>
                        <div className='registrationconfirmation-news'>
                            <p>
                                <span>就诊医院</span>
                                <span>中江民瑞医院</span>
                            </p>
                            <p>
                                <span>就诊科室</span>
                                <span>{list.name}</span>
                            </p>
                            <p>
                                <span>就诊日期</span>
                                <span>{list.dutyTime}</span>
                            </p>
                            <p>
                                <span>挂号费用</span>
                                <span>￥{(list.cost/100).toFixed(2)}</span>
                            </p>
                        </div>
                        {!visible?<>
                            <div className='select-user'>选择就诊人</div>
                            <div className='confirmed'>
                                {userList&&userList.length?userList.map((item,index)=>(
                                    <span onClick={()=>this.oneUser(item)} key={index} className='chose-user'>{item.patientName}</span>)):null}
                                <span onClick={() => this.toAdd()}>+添加</span>
                            </div>
                        </>:<>
                            <div className='already-user'>
                                <span>就诊人</span>
                                <span>{oneuserNews.patientName} ＞</span>
                            </div>
                            <div className='already-user'>
                                <span>电子健康卡</span>
                                <span>{oneuserNews.idCard} ＞</span>
                            </div>
                        </>}
                        <div className='tishi'>
                            <span>!</span>
                            <span>选错电子健康卡将无法就诊，请仔细检查您的电子健康卡号</span>
                        </div>
                        {visible?<>
                            <p className='pay-way' >支付方式</p>
                            <p className='pay-weixin'>
                                <img src={icon5}/>
                                <span>微信支付</span>
                                <img src={icon6}/>
                            </p>
                            <div className='pay-sure'>
                                <p>支付金额 <span style={{color:'#FF3030'}}>￥{(list.cost/100).toFixed(2)}</span></p>
                                <p className='pay' onClick={()=>this.toPay()}>确认预约</p>
                            </div>
                        </>:null}
                    </>:null}
                </div>
            </div>
        )
    }
}
