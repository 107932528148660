import { axiosInstance } from '../config'
// 地址列表	
export const addressLists = (patientId) => {
    return axiosInstance.get(`/api/hosp/wzaddress/list?patientId=${patientId}`)
}
//  修改地址	
export const updateAdressRequest = (data) => {
    return axiosInstance.post('/api/hosp/wzaddress/update', data)
}
// 添加地址	
export const addadressRequest = (data) => {
    return axiosInstance.post('/api/hosp/wzaddress/add', data)
}
//  删除地址	
export const delAdressRequest = (addressId) => {
    return axiosInstance.get(`/api/hosp/wzaddress/delete?addressId=${addressId}`)
}
// 地址详情	
export const addressDetailsRequest = (addressId) => {
    return axiosInstance.get(`/api/hosp/wzaddress/details?addressId=${addressId}`)
}
//获取省
export const getProvince = (data) => {
    return axiosInstance.post('/api/hosp/address/get-province', data)
}
//获取市
export const getCity = (data) => {
    return axiosInstance.post('/api/hosp/address/get-city', data)
}
//获取区
export const gitDistrict = (data) => {
    return axiosInstance.post('/api/hosp/address/get-district', data)
}