import React, { Component } from 'react'
import FootNav from '../../component/FootNav'
import "./style.less"
import icon1 from "../../static/img/yy/touxiang.png"
import icon2 from "../../static/img/yy/set.png"
import icon3 from "../../static/img/yy/right.png"
import icon4 from "../../static/img/yy/adress.png"
import icon5 from "../../static/img/yy/lishi.png"
import icon6 from "../../static/img/yy/guanzhu.png"
import icon7 from "../../static/img/yy/chufang.png"
import icon8 from "../../static/img/yy/kefu.png"
import icon9 from "../../static/img/yy/jilu.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone:"",
            patientAvatar:"",
            patientName:"",
            patientId:""
        }
    }
    componentDidMount = () => {
        this.setState({
            patientPhone:localStorage.getItem("patientPhone"),
            patientAvatar:localStorage.getItem("patientAvatar"),
            patientName:localStorage.getItem("patientName"),
            patientId:localStorage.getItem("patientId"),
        })
    }
    tologin=()=>{
        const {patientName} =this.state
        if(!patientName){
            this.props.history.push("/login")
        }
    }
    //设置
    toSet=()=>{
        const {patientId}=this.state
        if(patientId){
        this.props.history.push("/mine/set")
        }else{
            this.props.history.push("/login")
        }
    }
    //就诊人管理
    toPatientManagement=()=>{
        const {patientId}=this.state
        if(patientId){
        this.props.history.push("/mine/patientManagement")
        }else{
            this.props.history.push("/login")
        }
    }
    //我的挂号
    toHistoricalregistration=()=>{
        const {patientId}=this.state
        if(patientId){
        this.props.history.push("/mine/historicalregistration")
        }else{
            this.props.history.push("/login")
        }
    }
    //地址
    toAdress=()=>{
        const {patientId}=this.state
        if(patientId){
        this.props.history.push("/mine/adress")
        }else{
            this.props.history.push("/login")
        }
    }
    //问诊历史
    toRecord=()=>{
        const {patientId}=this.state
        if(patientId){
        this.props.history.push("/mine/record")
        }else{
            this.props.history.push("/login")
        }
    }
    //关注医生
    togzDocter=()=>{
        const {patientId}=this.state
        if(patientId){
            this.props.history.push("/mine/gzDocter")
        }else{
            this.props.history.push("/login")
        }
    }
    //已开处方
    toPrescribed=()=>{
        const {patientId}=this.state
        if(patientId){
        this.props.history.push("/mine/prescribed")
        }else{
            this.props.history.push("/login")
        }
    }
    //购药记录
    toDrugrecord=()=>{
        const {patientId}=this.state
        if(patientId){
            this.props.history.push(`/mine/order?tabId=0`)
        }else{
            this.props.history.push("/login")
        }
    }
    render() {
        const {patientPhone,patientName,patientId,patientAvatar}=this.state
        return (
            <div className='minebox'>
                <div className='box-top' onClick={this.tologin}>
                    <img src={patientAvatar!="null"&&patientAvatar?patientAvatar:icon1} alt="" /> <span>{patientName!="null"&&patientName?patientName:patientPhone?patientPhone:"暂未登录"}</span>
                    <img src={icon2} alt="" className='top-set' onClick={() => this.toSet()} />
                </div>
                <div className='box-man' onClick={() => this.toPatientManagement()}>
                    <p>就诊人管理</p><img src={icon3} alt="" />
                </div>
                <div className='box-man' onClick={() => this.toHistoricalregistration()}>
                    <p>我的挂号</p><img src={icon3} alt="" />
                </div>
                <div className='box-content'>
                    <div onClick={() => this.toAdress()}>
                        <img src={icon4} alt="" />
                        <p>我的地址</p>
                    </div>
                    <div onClick={() => this.toRecord()}>
                        <img src={icon5} alt="" />
                        <p>问诊记录</p>
                    </div>
                    <div onClick={() => this.togzDocter()} >
                        <img src={icon6} alt="" />
                        <p>关注医生</p>
                    </div>
                    <div onClick={() => this.toPrescribed()}>
                        <img src={icon7}  alt="" />
                        <p>已开处方</p>
                    </div>
                    {/*<div><a href={`tel:${19048012565}`}>*/}
                    {/*    <img src={icon8} alt="" />*/}
                    {/*    <p>联系客服</p>*/}
                    {/*    </a >*/}
                    {/*</div>*/}
                    {/*<div onClick={() => this.toDrugrecord()}>*/}
                    {/*    <img src={icon9}  alt="" />*/}
                    {/*    <p>购药记录</p>*/}
                    {/*</div>*/}
                </div>
                <FootNav></FootNav>
            </div>

        )
    }
}
