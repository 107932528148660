import React, { Component } from 'react';
import fhhome from "./img/fhhome.png";
import zk from "./img/zk.png";
import sq from "./img/sq.png";
import './style.less'
import getUrl from "../../../../component/GetUrlParam";
import { getWesternDrugs,getChineseDrugs } from '../../../../api/hospitalapi/drugs'
import Nodata from "../../../../component/Nodata";
export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            records: 1,  //1:西/中成药记录,2:中药材记录
            isCollapsed: -1,
            data: [],
            isCollapsedZh: -1,//中药饮片的收起展开
            datatime: []
        }
    }

    /**
     * 西药记录
     * @param {*} index 
     */
    handleClickSqzk = (index) => {
        if (index === this.state.isCollapsed) {
            index = -1
        }

        this.setState({
            isCollapsed: index
        })
    }

    /**
     * 西药记录数据
     * @returns 
     */
    loadData = async () => {
        let res = await getWesternDrugs(localStorage.getItem("pharmacyId"))
        if (res.data[0] == null) {
            return
        }
        let data = res.data[0]

        this.setState({
            data
        })
    }

    /**
     * 中药记录展开收起
     */
    handleClickSqzkZh = (index) => {
        if (index === this.state.isCollapsedZh) {
            index = -1
        }

        this.setState({
            isCollapsedZh: index
        })
    }

    /**
     * 中药记录数据
     */
    loadDataZh = async () => {
        let res = await getChineseDrugs(localStorage.getItem("pharmacyId"))
        if (res.data[0] == null) {
            return
        }
        let datatime = res.data[0]

        this.setState({
            datatime
        })
    }
    

    /**
     * 数据初始化
     */
    componentDidMount() {
        if (getUrl("records", this.props.location.search)) {
            this.setState({
                records: getUrl("records", this.props.location.search),
            });
        }
        
        this.loadData()
        this.loadDataZh()

    }

    render() {
        const {
            records,
            isCollapsed,
            data,
            datatime,
            isCollapsedZh
         } = this.state

            // console.log('records',records);
        return (
            <div className='sidebox'>
                <div className='top-tit'>
                    <img className='return-img' src={fhhome} 
                        // onClick ={this.props.history.push(`/westernm`)}
                        onClick={this.props.history.goBack} 
                    />
                    <span className='title-middle'>添加记录</span>
                </div>

                <div className='headerbox'>
                    <span 
                        className={records == 1 ? 'header-left1' : 'header-left2'}
                        onClick={()=>{
                            this.setState({records:1})
                        }}
                    >西/中成药记录</span>
                    <span
                        className={records == 2 ? 'header-right1' : 'header-right2'} 
                        onClick={()=>{
                            this.setState({records:2})
                        }}
                    >中药饮片记录</span>
                </div>

                <div className='middlebox'>

                    {/* 西药记录 */}
                    {records == 1 ? (
                        <>
                            {
                                data.length>0 ? (
                                    data.map((item,index) => (

                                        <div className='medsbox' key={item.pro_id} >
                                            <div className='medsbox-one'>
            
                                                <p className='one-left1'>
                                                    <span>添加时间：{item.create_time}</span><span></span>
                                                </p>
                                                <p className='one-right1' onClick={()=>this.handleClickSqzk(index)}>
                                                    {isCollapsed===index ? (
                                                        <>
                                                            <img src={zk} />
                                                            <span>收起</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img src={sq} />
                                                            <span>展开</span>
                                                        </>
                                                    )}
            
                                                </p>
                                            </div>
                                            <p>药品名称：<span className='meds-one-name'>{item.pro_com_name}</span></p>
                                            <p>药品分类：<span>{item.category_name}{item.classify_name&&(`/`+item.classify_name)}{item.classifyson_name&&(`/`+item.classifyson_name)}</span></p>
                                            <p>药品规格：<span>{item.pro_spec}</span></p>
                                            {isCollapsed===index && (
                                                <>
                                                    <p>批准文号：<span>{item.pro_approval}</span></p>
                                                    <p>药品类型：<span>{item.supervision_type===1&&("中药")}{item.supervision_type===2&&("西药")}{item.supervision_type===3&&("中成药")}</span></p>
                                                    <p>包装单位：<span>{item.packing_unit}</span></p>
                                                    <p>生产厂家：<span>{item.pro_company}</span></p>
                                                    <p>计量单位：<span>{item.unit}</span></p>
                                                    <p>建议零售价：<span>{item.origin_price/100}元</span></p>
                                                    <p>商品条形码：<span>{item.pro_qrcode}</span></p>
                                                </>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <Nodata type='消息'/>
                                )
                                
                            }
                        </>
                    ) : null}
                    


                    {/* 中药饮片记录 */}
                    {records == 2 ? (
                        <>
                            {
                                datatime.length>0 ? (
                                    datatime.map((item, index) => (

                                        <div className='medsbox' key={index}>
                                            <div className='medsbox-one'>
                                                <p className='one-left1'>
                                                    <span>添加时间：</span><span>{item.create_time}</span>
                                                </p>
                                                <p className='one-right1' onClick={() => this.handleClickSqzkZh(index)}>
                
                                                    {index === isCollapsedZh ? (
                                                        <>
                                                            <img src={zk} />
                                                            <span>收起</span>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <img src={sq} />
                                                            <span>展开</span>
                                                        </>
                                                    )}
                                                </p>
                                            </div>
                                            <p>中药饮片名称：<span className='meds-one-name'>{item.pro_com_name}</span></p>
                                            <p>药品规格：<span className='meds-one-name'>{item.pro_spec}</span></p>
                                            <p>包装：<span>{item.packing_unit}</span></p>
                                            {index === isCollapsedZh && (
                                                <>
                                                    <p>生产厂家：<span>{item.pro_company}</span></p>
                                                    <p>执行标准：<span>{item.pro_approval}</span></p>
                                                    <p>产地：<span>{item.place_origin}</span></p>
                                                    <p>存储：<span>{item.pro_storage}</span></p>
                                                </>
                                            )}     
                                        </div>
                                    ))
                                ) : <Nodata type='消息'/>
                            }
                        </>
                    ) : null}
                    

                </div>

            </div>

        );
    }
}

