import React, { Component } from 'react'
import {DatePicker, Popup, Steps, } from "antd-mobile-v5"
import { Tabs,Switch } from 'antd-mobile'
import { Link, withRouter } from 'react-router-dom'
import './style.less'
import getUrl from '../../component/GetUrlParam'
import {Modal,Toast} from "antd-mobile";
import {dayAllorder, dutyStatisticsAll,saveAllocateSignature,selectFiltering,selectSignaturePic} from '../../api/hospitalapi/offline'
import moment from 'moment'
import {zipImg} from "../../component/zipImage";
import {uploadImg} from "../../api/request/upload";
import SignatureCanvas from 'react-signature-canvas'
import Nodata from "../../component/Nodata";
import Patientnews from "../../component/offline";
import icon1 from "../../static/img/yy/pt.png";
import icon2 from "../../static/img/store/zf.png";
import fhhome from "../../static/img/icon/fhhome.png"
import updown from "../../static/img/icon/updown.png"
import {imgToCanvas, rotateBase64Img, dealImage, dealParams} from '../../api/config'
import {allMedicinal, caWrite, prescribingDetail,isChineseMeds,chineseMethod,belongStore,
    selectPharmaIdStaff,
    selectPrescPhaSign,
    selectPharmaIdPha} from "../../api/hospitalapi/chat";
const { Step } = Steps
const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            docterId: localStorage.getItem("docterId"),
            pharmacyId: localStorage.getItem("pharmacyId"),
            code: 1,
            dateList: '',
            browseList: '',
            page: 1,
            size: 20,
            countallNum: 0,//接诊总数
            countNum: 0,
            createTime: '',
            prescribingState: '',
            visible: false,
            visible1: false,
            visible2: false,
            flag: false,
            listsum: '',
            list3: [],
            newsstate: 1,
            prescribingId:'',
            ischinese:[],
            chinesemethod:[],//用法用量
            staff_img:'',
            pharmacist_img:'',
            visible6:false,
            selcetcf:1,
            visible7:false,
            selcetcfbh:1,
            createTimes:'',
            pharmacist_img1:'',
            filteringArr:[],
            prescringPhaState:'',
            isPhaExamine: false, //是否需要药店药师审核
            phaStaffIdStoreImg:'', //核对签名
            phaStaffIdStore:'',//如果为null,那么就是自动，存在就是手动
        }
    }

    componentDidMount = async () => {
        const {size, dateList, docterId,pharmacyId} = this.state
        // 查询店铺权限
        const res12 = await selectFiltering({pharmacyId:pharmacyId})
        if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
            this.setState({
                filteringArr:res12.data[0]
            })
        }
        if(this.state.filteringArr.some(item => item.label === '药店药师审核' && item.label_type == 4)){
            this.setState({
                isPhaExamine: true
            })
        }

        this.dayAllorders(1);
        this.getAllList(2)
    }
    resetBrowseList = (data, type, data1) => {
        const {page, dateList, browseList, code} = this.state
        //获取日期
        const year = moment().year() + ''
        const month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : '' + (moment().month() + 1)
        const date = moment().date() < 10 ? '0' + moment().date() : '' + moment().date()
        const browseDate = year + month + date
        let newData = type === 'get' ? data : [...browseList, ...data]
        let newDateList = [...dateList]
        newData.forEach(item => {
            item.checked = false
            item.createTime0 = item.phaReviewerTime.slice(0, 4) + '-' + item.phaReviewerTime.slice(5, 7) + '-' + item.phaReviewerTime.slice(8, 10)
        })
        for (let i = 0; i < newData.length; i++) {
            for (let j = i + 1; j < newData.length; j++) {
                if (newData[i].createTime === newData[j].createTime) {
                    ++i
                }
            }
            newDateList.push({
                createTime: newData[i].createTime0,
                allCount: newData[i].allCount,
                successCount: newData[i].successCount,
                state: 0
            })
        }
        let obj = {}
        // newDateList = [...dateList, ...newDateList]
        newDateList = [...newDateList]
        newDateList = newDateList.filter((x, index, self) => {
            var arrids = []
            newDateList.forEach((item, i) => {
                arrids.push(item.createTime)
            })
            return arrids.indexOf(x.createTime) === index
        })
        newDateList.map(item => {
            data1.map(i => {
                if (item.createTime == i.day) {
                    item.count = i.num
                }
            })
        })
        let arrData = []
        newDateList.map(item => {
            newData.map(j => {
                if (item.createTime == j.createTime0) {
                    item.state = 1
                    arrData.push(item)
                }
            })
        })
        let arrData1 = arrData.filter((currentValue, currentIndex, selfArr) => {
            return selfArr.findIndex(x => x.createTime === currentValue.createTime) === currentIndex
        });

        // setDateList(newDateList)
        // setDateList([...newDateList])
        // setBrowseList(type === 'get' ? newData : [...browseList, ...newData])
        this.setState({
            dateList: [...arrData1],
            browseList: [...newData],
            // browseList:type === 'get' ? newData : [...browseList, ...newData],
            flag: false
        })
    }
    getAllList = async (type) => {
        const {size, page, docterId, content, createTime,selcetcf,selcetcfbh,createTimes,isPhaExamine} = this.state
        let params = {}
        if(isPhaExamine){
            params = {
                page: 1,
                size: size,
                prescribingState: 1,
                prescringPhaState:1,
                pharmacyId: localStorage.getItem("pharmacyId"),
                createTime: createTime ? createTime : '',
                isAllocate: type,
                isChineseMeds:selcetcf,
                isChineseMedss:selcetcfbh,
                createTimes:createTimes ? createTimes :'',
            }
        }else{
            params = {
                page: 1,
                size: size,
                prescribingState: 1,
                pharmacyId: localStorage.getItem("pharmacyId"),
                createTime: createTime ? createTime : '',
                isAllocate: type,
                isChineseMeds:selcetcf,
                isChineseMedss:selcetcfbh,
                createTimes:createTimes ? createTimes :'',
            }
        }
        
        let res = await dutyStatisticsAll(params)
        let data1 = []
        if (res && res.data && res.data[0] && res.data[0].length) {
            if (res.data[1] && res.data[1].length) {
                this.setState({
                    listsum: res.data[1]
                })
                data1 = res.data[1]
            }
            let data = res.data[0]

            this.resetBrowseList(data, 'get', data1)
        } else {
            this.setState({
                dateList: [],
                browseList: [],
            })
        }

    }
    // 触底更新数据
    onscroll = e => {
        const {flag,} = this.state
        e.preventDefault()
        e.stopPropagation()
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 5) {
            if (!flag) {
                this.pageBrowselist()
            }
        }
    }
    // 触底时换页
    pageBrowselist = () => {
        const {size, page, newsstate, content, createTime,createTimes,selcetcf,selcetcfbh,isPhaExamine} = this.state
        this.setState({
            flag: true
        }, () => {
            const year = moment().year() + ''
            const month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : '' + (moment().month() + 1)
            const date = moment().date() < 10 ? '0' + moment().date() : '' + moment().date()

            let params = {}
            if(isPhaExamine){
                params = {
                    size: size,
                    page: page + 1,
                    pharmacyId: localStorage.getItem("pharmacyId"),
                    prescribingState: 1,
                    prescringPhaState: 1,
                    // createTime: newsstate==1?year + '-' + month + '-' + date:createTime ? createTime : '',
                    createTime: createTime ? createTime : '',
                    isAllocate: newsstate==1?2:1,
                    createTimes:createTimes ? createTimes : '',
                    isChineseMeds:selcetcf,
                    isChineseMedss:selcetcfbh,
                }
            }else{
                params = {
                    size: size,
                    page: page + 1,
                    pharmacyId: localStorage.getItem("pharmacyId"),
                    prescribingState: 1,
                    // createTime: newsstate==1?year + '-' + month + '-' + date:createTime ? createTime : '',
                    createTime: createTime ? createTime : '',
                    isAllocate: newsstate==1?2:1,
                    createTimes:createTimes ? createTimes : '',
                    isChineseMeds:selcetcf,
                    isChineseMedss:selcetcfbh,
                }
            }
            
            dutyStatisticsAll(params).then(res => {
                if (res.code === 1) {
                    let data1 = []
                    if (res.data && res.data[0] && res.data[0].length > 0) {
                        if (res.data[1] && res.data[1].length) {
                            this.setState({
                                listsum: res.data[1]
                            })
                            data1 = res.data[1]
                        }
                        const data = res.data[0]
                        this.resetBrowseList(data, 'limit', data1)
                        this.setState({
                            page: params.page
                        })
                    }
                }
            })
        })
    }
    gobank = () => {
        window.history.back()
    }
    changeuserinfo = (e) => {
        this.setState({
            content: e.target.value.replace(/\s*/g, "")
        })
    }
    onSearch = () => {
        this.getAllList(1)
    }

    // 置空待调配
    setTimenull = () => {
        this.setState({
            createTime: '',
            browseList: [],
            dateList: [],
            selcetcf:1,
            selcetcfbh:1,
            createTimes: '',
        }, () => {
            this.getAllList(2)
        })
    }

    // 置空已调配
    setTimenulls = () => {
        this.setState({
            createTime: '',
            browseList: [],
            dateList: [],
            selcetcf:1,
            selcetcfbh:1,
            createTimes: '',
        }, () => {
            this.getAllList(1)
        })
    }
    // 查看详情
    topreDetails = async (consultationId, prescribingId, prescribingState, consultationStu) => {
        this.props.history.push(`/allprescribeddetail?consultationId=${consultationId}&prescribingId=${prescribingId}&prescribingState=${prescribingState}&consultationStu=${consultationStu}`)
    }
    // 查看处方详情
    toDetails = async (consultationId, prescribingId, isToreStaff, consultationStu) => {
        if (prescribingId != 'null') {
            let res = await allMedicinal({prescribingId})
            if (res.code == 1 && res.data[0]) {
                let res1 = await prescribingDetail({prescribingId})

                let res04 = await isChineseMeds({consultation_id: res1.data[0][0].consultation_id})
                let res05 = await chineseMethod({consultation_id: res1.data[0][0].consultation_id})
                this.setState({
                    ischinese:res04.data[0],
                    chinesemethod:res05.data[0]
                })

                let time1 = new Date(res1.data[0][0].create_time).getTime()
                if (res1 && res1.data && res1.data[0]) {
                    this.setState({
                        list3: res.data[0],
                        detail: res1.data[0][0],
                        originalPrescribing: res1.data[0][0].original_prescribing ? res1.data[0][0].original_prescribing : '',
                        year: res1.data[0][0].year,
                        month: res1.data[0][0].month,
                        day: res1.data[0][0].day,
                        visible1: true
                    })
                    if (res1.data[2]) {
                        this.setState({
                            docterAutograph: res1.data[2][0].docterAutograph
                        })
                    }
                    if (res1.data[0][0].pharmacist_id) {
                        caWrite({docterId: res1.data[0][0].pharmacist_id}).then(res2 => {
                            if (res2 && res2.data && res2.data[0]) {
                                this.setState({
                                    docterAutograph1: res2.data[0][0].docterAutograph
                                })
                            }
                        })
                    }

                    if(res1.data[0][0].pha_pharmacist_id_store){
                        // 查询药店药师对应
                        selectPrescPhaSign({ 
                            docter_id:res1.data[0][0].pha_pharmacist_id_store
                        }).then((resPhas)=>{
                            if(resPhas && resPhas.data && resPhas.data[0] && resPhas.data[0][0].docterAutograph3){
                                this.setState({
                                    docterAutograph3:resPhas.data[0][0].docterAutograph3
                                })
                            }
                        })
                    }

                }

            }

            let res06 = await belongStore({prescribingId:res.data[0][0].prescribing_id})
            if(res06.data[0][0].pharmacy_id != null){
                const res12 = await selectFiltering({pharmacyId:res06.data[0][0].pharmacy_id})
                if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
                    this.setState({
                        filteringArr:res12.data[0]
                    })
                }
                // 查询处方单店员、药店药师签名图
                let res88 = await selectSignaturePic({prescribingId:res.data[0][0].prescribing_id})

                if(res88.data && res88.data[0] && res88.data[0][0].phaStaffIdStore){
                    this.setState({
                        phaStaffIdStoreImg:res88.data[1][0].phaStaffIdStoreImg,
                        phaStaffIdStore:res88.data[0][0].phaStaffIdStore
                    })
                }else{
                    this.setState({
                        phaStaffIdStoreImg:''
                    })
                }

                if (this.state.filteringArr.some(item => item.label === "核对/发药")) {
                    if(res88.data[0][0].phyStaffImg){
                        this.setState({
                            staff_img:res88.data[0][0].phyStaffImg
                        })
                    } 
                }else{
                    this.setState({
                        staff_img:'',
                    })
                }

                if (this.state.filteringArr.some(item => item.label === "药店药师审核" && item.label_type == 3)) {
                    if(res88.data[0][0].phyPharmacistImg){
                        this.setState({
                            pharmacist_img:res88.data[0][0].phyPharmacistImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img:"",
                    })
                }
                
                if (this.state.filteringArr.some(item => item.label === "调配")) {
                    if(res88.data[0][0].phyPharmacistsImg){
                        this.setState({
                            pharmacist_img1:res88.data[0][0].phyPharmacistsImg
                        })
                    }
                }else{
                    this.setState({
                        pharmacist_img1:"",
                    })
                }
            }  


        }
    }
    dayAllorders = async (type) => {
        const {pharmacyId, size, createTime,createTimes} = this.state
        if (type == 1) {
            //获取日期
            const year = moment().year() + ''
            const month = moment().month() + 1 < 10 ? '0' + (moment().month() + 1) : '' + (moment().month() + 1)
            const date = moment().date() < 10 ? '0' + moment().date() : '' + moment().date()
            this.setState({
                // createTime: year + '-' + month + '-' + date,
                createTime: '',
                browseList: [],
                dateList: [],
                createTimes:''
            }, () => {
                this.getAllList(2)
            })
        } else {
            this.setState({
                createTime: '',
                browseList: [],
                dateList: [],
                createTimes:''
            }, () => {
                this.getAllList(1)
            })

        }
    }
    writeName=(prescribingId)=>{
        this.setState({
            visible2:true,
            prescribingId
        })
    }
    sureName=async ()=>{
        let aaa = this.sigCanvas.isEmpty();
        let img0=''
        if (aaa){
            Toast.info('请先签名')
        }else{
            this.setState({
                trimmedDataURL: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
            },()=>{
                let res0= this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
                setTimeout(()=>{
                    let a=dealImage(res0,callback1)
                    function callback1(val) {
                        //base64转png图片
                        let res2= imgToCanvas(val)
                        let file=res2
                        // if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                        zipImg(file).then(res => {
                            let formData = new FormData()
                            formData.append('file', res)
                            uploadImg(formData).then(res1 => {
                                if(res1&&res1.code == 2) {
                                    img0=res1.data
                                }
                            }).catch(err => {
                            })
                        })
                    }
                },500)
                setTimeout(()=>{
                    if (img0){
                        saveAllocateSignature({
                            prescribingId:this.state.prescribingId,
                            allocateSignature:img0
                        }).then(res3=>{
                            if (res3&&res3.code==1){
                                this.setState({
                                    visible1: false,
                                    visible2: false,
                                    prescribingId:''
                                },()=>{
                                    Toast.info('调配成功')
                                    this.dayAllorders(1)
                                })
                            }
                        })
                    }
                },1000)
            });
        }
    }
    render() {
        const {
            list3, dateList, browseList, visible1, visible, createTime, newsstate,visible2,
            year, month, day, detail, docterAutograph1, docterAutograph,ischinese,chinesemethod,staff_img,docterAutograph3,
            pharmacist_img,visible6,selcetcf,visible7,selcetcfbh,createTimes,visible10,visible11,pharmacist_img1,isPhaExamine,phaStaffIdStoreImg,
            phaStaffIdStore
        } = this.state
        const now = new Date()
        const now1 = new Date('2022-01')

        const years = now.getFullYear().toString();
        const months = (now.getMonth() + 1).toString().padStart(2, '0');
        const date = now.getDate().toString().padStart(2, '0');
        const dateString = `${years}-${months}-${date}`;

        return (
            <>
                <div className='newsbox1'>
                    <div className='news-inend news-inend2'> 
                        <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                        {/* <span className='return-right' onClick={this.props.history.goBack}>❮</span> */}
                        <p className={newsstate == 1 ? 'news-top-left' : 'news-top-left1'}
                           onClick={() => this.setState({
                                                newsstate: 1,
                                                visible6:false, 
                                                visible7:false,
                                                createTime:"",
                                                createTimes:"",
                                                selcetcf:1,
                                                selcetcfbh:1
                                            }, () => {
                               this.dayAllorders(1)
                           })}>待调配</p>
                        <p className={newsstate == 2 ? 'news-top-right' : 'news-top-right1'}
                           onClick={() => this.setState({
                                                newsstate: 2,
                                                visible6:false, 
                                                visible7:false,
                                                createTime:"",
                                                createTimes:"",
                                                selcetcf:1,
                                                selcetcfbh:1
                                            }, () => {
                               this.dayAllorders(2)
                           })}>已调配</p>
                        {/*<p onClick={()=>this.toallcf()} className='news-top-cf'>*/}
                        {/*    <i className='iconfont icon-chufang2'/>*/}
                        {/*    <span>已开处方</span>*/}
                        {/*</p>*/}
                    </div>
                </div>
                <div className={newsstate == 1 ? 'accountbox dutyaccountbox1' : 'accountbox dutyaccountbox'}>

                    {newsstate == 2 ? <div className='container-shopassistant-createtime' style={{marginTop: '-65px'}}>
                        <p className='all-chufang'
                            onClick={() => this.setState({
                                visible6: true
                            })}
                        >
                            <span style={{color:'#346EF4'}}>{selcetcf === 1 ? '全部处方' : selcetcf === 2 ? '西/中成药' : '中药'}</span>
                            <img src={updown}/>
                        </p>
                        <p className='all-chufang2'>
                            <span 
                                    onClick={() => this.setState({
                                        visible: true
                                    })} 
                                    style={{color:createTime?'#346EF4':'#817F7F'}}>{createTime ? createTime : '起始日期'}</span>
                            <img src={updown}/>
                            <div className='lianjiexian'>-</div>
                            <span 
                                    onClick={() => this.setState({
                                        visible10: true
                                    })} 
                                    style={{color:createTimes?'#346EF4':'#817F7F'}}>{createTimes ? createTimes : '结束日期'}</span>
                            <img src={updown}/>
                            
                        </p>
                        <p className='dutyaccountbox-null' onClick={() => this.setTimenulls()}>重置</p>
                        <div className='duty-bgc'></div>
                    </div> : null}

                    {newsstate == 1 ? <div className='container-shopassistant-createtime' style={{marginTop: '-8px'}}>
                        <p className='all-chufang'
                            onClick={() => this.setState({
                                visible7: true
                            })}
                        >
                            <span style={{color:'#346EF4'}}>{selcetcfbh === 1 ? '全部处方' : selcetcfbh === 2 ? '西/中成药' : '中药'}</span>
                            <img src={updown}/>
                        </p>
                        <p className='all-chufang2'>
                            <span 
                                    onClick={() => this.setState({
                                        visible: true
                                    })} 
                                    style={{color:createTime?'#346EF4':'#817F7F'}}>{createTime ? createTime : '起始日期'}</span>
                            <img src={updown}/>
                            <div className='lianjiexian'>-</div>
                            <span 
                                    onClick={() => this.setState({
                                        visible11: true
                                    })} 
                                    style={{color:createTimes?'#346EF4':'#817F7F'}}>{createTimes ? createTimes : '结束日期'}</span>
                            <img src={updown}/>
                        </p>
                        <p className='dutyaccountbox-null' onClick={() => this.setTimenull()}>重置</p>
                        <div className='duty-bgc'></div>
                    </div> : null}

                    {visible6 && (
                        <div>
                            <div className='all-chose'>                        
                                <span onClick={() => this.setState({ visible6: false, selcetcf: 1 },()=>{this.getAllList(1)})} className={(selcetcf === 1) ? 'selectcf-yes' : ''}>全部处方</span>
                                <span onClick={() => this.setState({ visible6: false, selcetcf: 2 },()=>{this.getAllList(1)})} className={(selcetcf === 2) ? 'selectcf-yes' : ''}>西/中成药</span>
                                <span onClick={() => this.setState({ visible6: false, selcetcf: 3 },()=>{this.getAllList(1)})} className={(selcetcf === 3) ? 'selectcf-yes' : ''}>中药</span>

                            </div>
                        </div>
                    )}

                    {visible7 && (
                        <div>
                            <div className='all-chose'>                        
                                <span onClick={() => this.setState({ visible7: false, selcetcfbh: 1 },()=>{this.getAllList(2)})} className={(selcetcfbh === 1) ? 'selectcf-yes' : ''}>全部处方</span>
                                <span onClick={() => this.setState({ visible7: false, selcetcfbh: 2 },()=>{this.getAllList(2)})} className={(selcetcfbh === 2) ? 'selectcf-yes' : ''}>西/中成药</span>
                                <span onClick={() => this.setState({ visible7: false, selcetcfbh: 3 },()=>{this.getAllList(2)})} className={(selcetcfbh === 3) ? 'selectcf-yes' : ''}>中药</span>

                            </div>
                        </div>
                    )}


                    {dateList ? <div className='dutyaccountbox-body' onScroll={(e) => this.onscroll(e)}>
                        {createTime && createTimes ? (
                            <>
                                {dateList.length > 0 ?
                                    <div className='dutystatistics-all-outer'>
                                        {dateList.map((item, index) => {
                                            return (
                                                <div key={index} className='dutystatistics-all-details'>
                                                    <div className='dutystatistics-all-details-date1'>
                                                        <span>{item.createTime}</span>
                                                        <span>数量：{item.count}</span>
                                                    </div>
                                                    {browseList.map((v, i) => {
                                                        return (item.createTime === v.createTime0 ?
                                                                <div key={i}>
                                                                    <div className='container-shopassistant'>
                                                                        <p className='name-news'>
                                                                            <span className='name'>患者姓名：</span>
                                                                            <p className='spec-news'>
                                                                                <span
                                                                                    style={{color: '#177FFF'}}>{v.patientName} &nbsp;</span>
                                                                                <span>{v.patientSex == 1 ? '男' : '女'}/{v.patientAge}岁</span>
                                                                                {v.patientIdcardNum ?
                                                                                    <i className='iconfont icon-a-yibaoyibaoka'/> : null}
                                                                            </p>
                                                                            {v.isState == 1 ?
                                                                                <span className='shopassistant-type1'
                                                                                    style={{backgroundColor: '#05AC73'}}>开方成功</span> :
                                                                                <span className='shopassistant-type1'
                                                                                    style={{backgroundColor: '#FF3C3B'}}>作废处方</span>}
                                                                        </p>
                                                                        <p className='name-news'>
                                                                            <span className='name'
                                                                                style={{width: '130px'}}>是否医保统筹：</span>
                                                                            <span className='spec-news'>
                                                                        <span>{v.medical_nsurance_coordination == 1 ? '是' : '否'}</span>
                                                                        </span>
                                                                        </p>
                                                                        <p className='name-news'>
                                                                            <span className='name'>审方时间：</span>
                                                                            <span className='spec-news'>
                                                                            <span>{v.phaReviewerTime}</span>
                                                                        </span>
                                                                        </p>
                                                                        <p className='shopassistant-button'>
                                                                            {v.medical_nsurance_coordination == 1 ?
                                                                                <p className='shopassistant-check00'
                                                                                onClick={() => this.topreDetails(v.consultationId, v.prescribingId, v.prescribingState)}>查看详情</p> : null}
                                                                            <p className='shopassistant-check'
                                                                            style={{backgroundColor: v.is_tore_staff == '1' ? '#B5B5B5' : ''}}
                                                                            onClick={() => this.toDetails(v.consultation_id, v.prescribingId, v.is_tore_staff, v.consultation_stu)}>查看处方</p>
                                                                        </p>
                                                                    </div>
                                                                </div> : null
                                                        )
                                                    })}
                                                </div>
                                            )
                                          })
                                        }
                                    </div> : <Nodata type='单量统计'/>
                                }
                            </>
                        ) : (
                            <>
                            {dateList.map((item, index) => {
                                return (item.createTime === dateString && item.count > 0 ? (
                                    <div key={index} className='dutystatistics-all-details'>
                                        <div className='dutystatistics-all-details-date1'>
                                                        <span>{item.createTime}</span>
                                                        <span>数量：{item.count}</span>
                                        </div>
                                        {browseList.map((v, i) => {
                                            return (item.createTime === v.createTime0 ?
                                                    <div key={i}>
                                                        <div className='container-shopassistant'>
                                                            <p className='name-news'>
                                                                <span className='name'>患者姓名：</span>
                                                                <p className='spec-news'>
                                                                    <span
                                                                        style={{color: '#177FFF'}}>{v.patientName} &nbsp;</span>
                                                                    <span>{v.patientSex == 1 ? '男' : '女'}/{v.patientAge}岁</span>
                                                                    {v.patientIdcardNum ?
                                                                        <i className='iconfont icon-a-yibaoyibaoka'/> : null}
                                                                </p>
                                                                {v.isState == 1 ?
                                                                    <span className='shopassistant-type1'
                                                                        style={{backgroundColor: '#05AC73'}}>开方成功</span> :
                                                                    <span className='shopassistant-type1'
                                                                        style={{backgroundColor: '#FF3C3B'}}>作废处方</span>}
                                                            </p>
                                                            <p className='name-news'>
                                                                <span className='name'
                                                                    style={{width: '130px'}}>是否医保统筹：</span>
                                                                <span className='spec-news'>
                                                            <span>{v.medical_nsurance_coordination == 1 ? '是' : '否'}</span>
                                                            </span>
                                                            </p>
                                                            <p className='name-news'>
                                                                <span className='name'>审方时间：</span>
                                                                <span className='spec-news'>
                                                                <span>{v.phaReviewerTime}</span>
                                                            </span>
                                                            </p>
                                                            <p className='shopassistant-button'>
                                                                {v.medical_nsurance_coordination == 1 ?
                                                                    <p className='shopassistant-check00'
                                                                    onClick={() => this.topreDetails(v.consultationId, v.prescribingId, v.prescribingState)}>查看详情</p> : null}
                                                                <p className='shopassistant-check'
                                                                style={{backgroundColor: v.is_tore_staff == '1' ? '#B5B5B5' : ''}}
                                                                onClick={() => this.toDetails(v.consultation_id, v.prescribingId, v.is_tore_staff, v.consultation_stu)}>查看处方</p>
                                                            </p>
                                                        </div>
                                                    </div> : null
                                            )
                                        })}
                                        
                                    </div>
                                ) : null)
                            })}
                            {dateList.filter(item => item.createTime === dateString).length === 0 ? <Nodata type='单量统计' /> : null}
                            </>
                        )}
                        
                        <DatePicker
                            // title='时间选择'
                            visible={visible}
                            onClose={() => {
                                this.setState({
                                    visible: false,
                                })
                            }}
                            defaultValue={now}
                            max={now}
                            min={now1}
                            precision='day'
                            onConfirm={value => {
                                const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                                // if (time20) {
                                const num = new Date(value).getDate() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                this.setState({
                                    page: 1,
                                    dateList: [],
                                    createTimes:'',
                                    createTime: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1)) + '-' + ((new Date(value).getDate()) > 9 ? (new Date(value).getDate()) : '0' + (new Date(value).getDate()))
                                })
                            }}
                        />

                        <DatePicker
                            // title='时间选择'
                            visible={visible10}
                            onClose={() => {
                                this.setState({
                                    visible10: false,
                                })
                            }}
                            defaultValue={now}
                            max={now}
                            min={now1}
                            precision='day'
                            onConfirm={value => {
                                const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                                // if (time20) {
                                const num = new Date(value).getDate() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                this.setState({
                                    page: 1,
                                    dateList: [],
                                    createTimes: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1)) + '-' + ((new Date(value).getDate()) > 9 ? (new Date(value).getDate()) : '0' + (new Date(value).getDate()))
                                }, () => {
                                    this.getAllList(1)
                                })
                            }}
                        />
                        <DatePicker
                            // title='时间选择'
                            visible={visible11}
                            onClose={() => {
                                this.setState({
                                    visible11: false,
                                })
                            }}
                            defaultValue={now}
                            max={now}
                            min={now1}
                            precision='day'
                            onConfirm={value => {
                                const num2 = new Date(now).getFullYear() + '-' + ((new Date(now).getMonth() + 1) > 9 ? (new Date(now).getMonth() + 1) : '0' + (new Date(now).getMonth() + 1))
                                // if (time20) {
                                const num = new Date(value).getDate() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1))
                                this.setState({
                                    page: 1,
                                    dateList: [],
                                    createTimes: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1) > 9 ? (new Date(value).getMonth() + 1) : '0' + (new Date(value).getMonth() + 1)) + '-' + ((new Date(value).getDate()) > 9 ? (new Date(value).getDate()) : '0' + (new Date(value).getDate()))
                                }, () => {
                                    this.getAllList(2)
                                })
                            }}
                        />

                        <Popup
                            visible={visible1}
                            onMaskClick={() => {
                                this.setState({
                                    visible1: false
                                })
                            }}
                            bodyStyle={{maxHeight: '100vh'}}
                        >
                            <div className='off-pop'>
                                <div className='offline-popup'>
                                    {/* <span className='return-right' onClick={this.props.history.goBack}>❮</span> */}
                                    <img className='return-img' src={fhhome} onClick={()=>{
                                        this.setState({
                                            visible1:false
                                        })
                                    }} />
                                    <span className='title-middle'>处方详情</span>
                                    {/* <h1 className='title'>处方详情</h1> */}
                                    {/* <span onClick={() => {
                                        this.setState({
                                            visible1: false
                                        })
                                    }} style={{
                                        position: 'absolute',
                                        right: '12px',
                                        top: '12px',
                                        fontSize: '16px',
                                        color: '#177FFF'
                                    }}>确定</span> */}
                                </div>
                                {detail ?
                                    <div className='off-body'>
                                        <div className='off-body-head'> 
                                            <p className='offprescription-jian'>
                                                <p className='jian-tit'>
                                                    <h1 className='title'>中江云益健互联网医院</h1>
                                                    <h1 className='title'>处方笺</h1>
                                                </p>
                                            </p>
                                            <p className='chufangnumber'>处方编号：{detail.prescribing_number}</p>
                                            <div className='huanzeinfo'>
                                                <p className='p-one'>
                                                    <span>姓名：{detail.patient_name}</span>
                                                    <span>性别：{detail.patient_sex == '1' ? '男' : '女'}</span>
                                                    <span>年龄：{detail.patient_age} 岁</span>
                                                </p>
                                                <p className='p-one'>
                                                    <span>科室：{detail.docter_department}</span>
                                                    <span>开具日期：{year}年{month}月{day}日</span>
                                                </p>
                                                <p className='p-one'>
                                                    <span>诊断：{detail.docter_diagnosis}</span>
                                                </p>
                                            </div>
                                            <p className='rp-words'>RP</p>
                                            {ischinese.length ? (
                                                <>
                                                    <div className='yaopin-zhongyao'>
                                                        <div className='zy-lists'>
                                                            {list3 ? list3.map((item, index) => (
                                                                <>
                                                                    <p className='zy-yaopin-tit'>
                                                                        <span>{item.med_com_name ? item.med_com_name : item.medicinal_name} </span>
                                                                        <span>{item.medicinal_quantity}g</span>
                                                                    </p>                                              
                                                                </>)) : null}   
                                                        </div> 
                                                    </div>
                                                    <div className='zy-yongfa'>
                                                        {chinesemethod.filter(i => i.consultation_id == detail.consultation_id).map(i=>(
                                                            <>
                                                                <span>
                                                                    共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，每次{i.at_time}{i.unit}，
                                                                    {i.med_method}，
                                                                    {i.med_time == 1
                                                                    ? "饭前用药"
                                                                    : i.med_time == 2
                                                                    ? "饭后用药"
                                                                    : i.med_time == 3
                                                                    ? "睡前用药"
                                                                    : i.med_time == 4
                                                                    ? "晨起用药"
                                                                    : ""}
                                                                </span>  
                                                            </>                 
                                                        ))}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className='yaopin-lists'>
                                                        {list3 ? list3.map((item, index) => (
                                                            <>
                                                                <p className='yaopin-tit'>
                                                                    <span>{index + 1}.{item.med_com_name ? item.med_com_name : item.medicinal_name} </span>
                                                                    <span>{item.medicinal_spec}【x{item.medicinal_quantity}{item.packing_unit && item.packing_unit != "null" ? item.packing_unit : ''}】</span>
                                                                </p>
                                                                <p className='yongfa-methods'>用法用量：{item.medicinal_frequency}，每次{item.medicinal_usage}，{item.medicinal_day}天的量， {item.medicinal_method}</p>
                                                                {item.medicinal_supplementary ?
                                                                    <p className='yongfa-methods'>备注：{item.medicinal_supplementary}</p> : null}                                                
                                                            </>)) : null}           
                                                    </div>
                                                </>
                                            )}
                                            
                                            <p className='kongbailine'>
                                            <hr></hr>
                                            <p>以下空白</p>
                                            <hr></hr>
                                            </p>
                                            <div className='yizhu'>
                                                <span>医嘱：请严格按照原处方和药品说明书使用，严禁超量超范 围使用；如用药过程中出现病情变化或其他不适症状，请立 即停药并及时就医。</span>
                                            </div>
                                            <div className='qianming'>
                                                <div className='qian-one'>
                                                    <p className='qian-one-one'>
                                                        <span>医师：</span>
                                                        <p className='qian-img'>
                                                            <img src={docterAutograph}/>
                                                        </p>
                                                    </p>
                                                    <p className='qian-one-one'>
                                                        <span>医院药师：</span>
                                                        <p className='qian-img'>
                                                            {detail.prescribing_state == 1 ?
                                                                <img src={docterAutograph1}/> :
                                                                <span></span>}
                                                        </p>
                                                    </p>
                                                </div>
                                                <div className='qian-one'>
                                                    <p className='qian-one-two'>
                                                        <span>药店药师审核：</span>
                                                        <p className='qian-img'>
                                                            {detail.prescring_pha_state == 1 && docterAutograph3 ?
                                                                <img src={docterAutograph3}/> :
                                                                detail.prescring_pha_state == 1 && pharmacist_img ?
                                                                <img src={pharmacist_img}/> :
                                                                <span></span>}  
                                                        </p>
                                                    </p>
                                                    <p className='qian-one-two'>
                                                        <span>调配：</span>
                                                        { detail.prescring_pha_state == 1 && docterAutograph3 ?
                                                            <img src={docterAutograph3}/> :
                                                            detail.prescring_pha_state == 1 && pharmacist_img1 ?
                                                            <img src={pharmacist_img1}/> :
                                                            <span></span>}
                                                    </p>                                                                                    
                                                </div>
                                                <div className='qian-one'>
                                                    <p className='qian-one-two'>
                                                        <span>核对/发药：</span>
                                                        <p className='qian-img'>
                                                            {/* {detail.prescring_pha_state == 1 && staff_img ?
                                                                <img src={staff_img}/> :
                                                                <span></span>} */}
                                                            {detail.prescring_pha_state == 1 && phaStaffIdStore && phaStaffIdStoreImg ? <img src={phaStaffIdStoreImg}/> : 
                                                                detail.prescring_pha_state == 1 && staff_img ? <img src={staff_img}/> : <span></span>}
                                                        </p>
                                                    </p>
                                                    <p className='qian-one-two'>
                                                        <span>金额：</span>
                                                    </p>                                                                                     
                                                </div>
                                            </div>
                                            <div className='zhu-bot2'>
                                                <p>1、医师处方仅开具当日内有效(医生注明除外)</p>
                                                <p>2、处方超过7日用量为病情需要。</p>
                                            </div>
                                            <div className='zhu-bot'>
                                                <span>注：本处方当日生效，仅限{detail.pharmacy_name}使用</span>
                                            </div>
                                        </div>
                                    </div>

                                        // <div className='offline-body11 offline-body12'>
                                        //     <div className='offprescription-outer11'>
                                        //         <h1 className='title'>中江云益健互联网医院</h1>
                                        //         <p className='offprescription-jian'>
                                        //             <h1>处方笺</h1>
                                        //             <img src={icon1}/>
                                        //         </p>
                                        //         <p className='offprescription-number'>处方编号：{detail.prescribing_number}</p>
                                        //         {/*<p className='offprescription-line'></p>*/}
                                        //         <p className='offprescription-one'>
                                        //             <p className='offprescription-one-left'>
                                        //                 <span>科室:</span>
                                        //                 <p>{detail.docter_department}</p>
                                        //             </p>
                                        //             <p className='offprescription-one-right'>
                                        //                 <span>开具日期:</span>
                                        //                 <p>{year}</p>
                                        //                 <span>年</span>
                                        //                 <p>{month}</p>
                                        //                 <span>月</span>
                                        //                 <p>{day}</p>
                                        //                 <span>日</span>
                                        //             </p>
                                        //         </p>
                                        //         <p className='offprescription-one'>
                                        //             <p className='offprescription-one-left'>
                                        //                 <span>姓名:</span>
                                        //                 <p>{detail.patient_name}</p>
                                        //             </p>
                                        //             <p className='offprescription-one-right2'>
                                        //                 <span>性别:</span>
                                        //                 <p>{detail.patient_sex == '1' ? '男' : '女'}</p>
                                        //                 <span>年龄:</span>
                                        //                 <p>{detail.patient_age} 岁</p>
                                        //             </p>
                                        //         </p>
                                        //         <p className='offprescription-one2'>
                                        //             <span>初步诊断：</span>
                                        //             <p>{detail.docter_diagnosis}</p>
                                        //         </p>
                                        //         {detail.is_state == 1 ? null : <img className='zf' src={icon2}/>}
                                        //         {/*<img className='zj-zhang1' src={icon13}/>*/}
                                        //         {/*<p className='offprescription-line'></p>*/}
                                        //         <p className='offprescription-RP'>RP</p>
                                        //         <p className='all-meds'>
                                        //             {list3 ? list3.map((item, index) => (<p className='offprescription-meds'>
                                        //                 <p className='offprescription-meds-news'>
                                        //                     <p className='offprescription-meds-left'>
                                        //                         <span>{index + 1}、{item.med_com_name ? item.med_com_name : item.medicinal_name}</span>
                                        //                         <span>{item.medicinal_spec} x{item.medicinal_quantity}{item.packing_unit && item.packing_unit != "null" ? item.packing_unit : ''}</span>
                                        //                     </p>
                                        //                 </p>
                                        //                 <p className='offprescription-meds-details'>用法用量：{item.medicinal_frequency},每次{item.medicinal_usage},{item.medicinal_day}天的量, {item.medicinal_method}</p>
                                        //                 {item.medicinal_supplementary ?
                                        //                     <p className='offprescription-meds-details'>补充说明：{item.medicinal_supplementary}</p> : null}
                                        //                 {index + 1 == list3.length && index < 4 ?
                                        //                     <p className='xu-line'></p> : null}
                                        //                 {index + 1 == list3.length && index < 4 ?
                                        //                     <p className='kongbai'>(以下空白)</p> : null}
                                        //             </p>)) : null}
                                        //         </p>
                                        //         {/*<p className='offprescription-line'></p>*/}
                                        //         <p className='offprescription-doctor'>
                                        //             <p className='offprescription-doctor20'>
                                        //                 <span>医师:</span>
                                        //                 <p>
                                        //                     <img src={docterAutograph}/>
                                        //                     <span className='xia-line00'></span>
                                        //                 </p>
                                        //             </p>
                                        //             <p className='offprescription-doctor20'>
                                        //                 <span>药师:</span>
                                        //                 <p>
                                        //                     {detail.prescribing_state == 1 ?
                                        //                         <img src={docterAutograph1}/> :
                                        //                         <span className='no-line'></span>}
                                        //                     <span className='xia-line00'></span>
                                        //                 </p>
                                        //             </p>

                                        //             <p className='offprescription-doctor21'>
                                        //                 <span>调配药师:</span>
                                        //                 <p>
                                        //                     <span className='xia-line00'></span>
                                        //                 </p>
                                        //             </p>
                                        //         </p>
                                        //         <p className='offprescription-doctor2'>
                                        //             <p className='offprescription-doctor33'>
                                        //                 {/* <span className='customstyle customstyle'>调配药师:</span>
                                        //                 <p></p> */}
                                        //                 <span className='customstyle customstyle'>核对:</span>
                                        //                 <p></p>
                                        //                 {/* <span className='customstyle customstyle'>发药:</span>
                                        //                 <p></p> */}
                                        //             </p>
                                        //             <p className='offprescription-doctor33'>
                                        //                 <span className='customstyle customstyle'>发药:</span>
                                        //                 <p></p>
                                        //             </p>
                                        //             <p className='offprescription-doctor44'>
                                        //                 <span>金额:</span>
                                        //                 <p></p>
                                        //             </p>
                                        //         </p>
                                        //         <p className='offprescription-tips'>
                                        //             <span>备注：</span>
                                        //             <p>{detail.docter_proposal}</p>
                                        //         </p>
                                        //         <p className='offprescription-tips1'>1、医师处方仅开具当日有效（医生注明除外）。</p>
                                        //         <p className='offprescription-tips1'>2、处方超过7日用量为病情需要。</p>
                                        //         <p className='offprescription-nowdayuse'>注：本处方当日生效，仅限{localStorage.getItem('pharmacyName')}使用</p>
                                        //         {newsstate == 1 ? <p className='offprescription-tiaopei'>
                                        //             {/* <button onClick={()=>this.writeName(detail.prescribing_id)}>调配</button> */}
                                        //         </p> : null}
                                        //     </div>
                                        // </div>
                                    : null}
                            </div>

                            {visible2?<div className='electronicsignature-outer'
                                   onClick={()=>
                                       this.setState({
                                            visible2:false
                                       })}>
                                <div className='electronicsignature' onClick={(e)=>e.stopPropagation()}>
                                    <div className='electronicsignature-top'>
                                        <span>手写签名</span>
                                        <span>
                                            <i className='iconfont icon-shuaxin1' onClick={()=>this.sigCanvas.clear()} />
                                            <span>重写</span>
                                        </span>
                                    </div>
                                    {/*<div className='electronicsignature-body'>*/}
                                        <div className='electronicsignature-body-center'>
                                            <SignatureCanvas
                                                penColor="#000"  // 笔刷颜色
                                                minWidth={4} // 笔刷粗细
                                                maxWidth={7}
                                                ref={(ref) => { this.sigCanvas = ref }} //用于调用插件的API
                                                canvasProps={{width: window.outerWidth*1, height: window.outerHeight*.5, style:{backgroundColor:'#fff', },
                                                    className: 'sigCanvas'}} />
                                        </div>
                                        <div className='electronicsignature-body-right'>
                                            <button onClick={()=>this.sureName()}>确定</button>
                                        </div>
                                    {/*</div>*/}
                                </div>
                            </div>:null}
                        </Popup>

                    </div> : null}
                </div>
            </>

        )
    }
}
