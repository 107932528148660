import React, { useState, useEffect } from 'react'
import FucButton from '../../../baseui/fuctionbutton/index'

import './style.less'
import {Tabs} from "antd-mobile-v5";
import Nodata from "../../../component/Nodata";

export default function Odersome(props) {
    const {newslist,list1,list2,list3,list4 } = props

    return (
        <div>
            <Tabs>
                <Tabs.Tab title='全部' key='all'>
                    {newslist.length?newslist.map(item=>(<div className='content-item' onClick={()=>this.toInchat(item)}>
                        <div className='content-top'>
                            <img src={item.docter_avatar} alt="" />
                            <p className='content-news'>
                                <p>
                                    <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                    <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                </p>
                                <p>
                                    <span>{item.docter_title}</span>
                                    {/* <span>呼吸科</span> */}
                                </p>
                            </p>
                            <p className='content-over'>{item.consultation_stu=='1'?'已结束':item.consultation_stu=='0'?'进行中':item.consultation_stu=='-1'?'进行中':
                                item.consultation_stu=='-2'?'待支付':item.consultation_stu=='4'||item.consultation_stu=='3'?'已取消':null}</p>
                        </div>
                        <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                        <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patient_name} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                        <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                        <div className='content-bottom'>
                            {item.consultation_stu!='4'&&item.consultation_stu!='1'&&item.consultation_stu!='3'?<p></p>:
                                <p className='content-delete' onClick={()=>this.delOrder(item.consultation_id)}>删除订单</p>}
                            {item.consultation_stu=='1'?
                                <>
                                    {item.doc_evaluate_star?
                                        <p className='content-right'>
                                            <span></span>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id)}>再次咨询</span>
                                        </p>
                                        :<p className='content-right'>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id)}>再次咨询</span>
                                            <span className='pingjia' onClick={() => this.setState({
                                                visible1: true,
                                                item1:item
                                            })}>评价</span>
                                        </p>}
                                </>
                                :
                                item.consultation_stu=='-1'||item.consultation_stu=='0'?<p className='content-right'>
                                    <span></span>
                                    <span className='agin' onClick={()=>this.goinfo(item)}>回到咨询</span>
                                </p>:item.consultation_stu=='-2'?<p className='content-right'>
                                    <span className='pingjia' style={{color:'#353A42'}} onClick={(e)=>this.quxiao(e,item.consultation_id)}>取消</span>
                                    <span className='agin' style={{color:'#1F87FF',borderColor:"#1F87FF"}} onClick={(e)=>this.toPay(e,item)}>立即支付</span>
                                </p>:item.consultation_stu=='4'||item.consultation_stu=='3'?<p className='content-right'>
                                    <span></span>
                                    <span className='agin' style={{color:'#1F87FF',borderColor:"#1F87FF"}} onClick={(e)=>this.again(e,item.docter_id)}>重新咨询</span>
                                </p>:null}
                        </div>
                    </div>)):<Nodata type='订单'/>}
                </Tabs.Tab>
                <Tabs.Tab title='待支付' key='no'>
                    {list1.length?list1.map(item=>(
                        <div  onClick={(e)=>this.toInchat(e,item)}>
                            {item.consultation_stu=='-2'?<div className='content-item'>
                                <div className='content-top'>
                                    <img src={item.docter_avatar} alt="" />
                                    <p className='content-news'>
                                        <p>
                                            <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                            <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                        </p>
                                        <p>
                                            <span>{item.docter_title}</span>
                                            {/* <span>呼吸科</span> */}
                                        </p>
                                    </p>
                                    <p className='content-over'>待支付</p>
                                </div>
                                <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patient_name} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                <div className='content-bottom'>
                                    <p></p>
                                    {/*<p className='content-delete' onClick={()=>this.delOrder(item.consultation_id)}>删除订单</p>*/}
                                    <p className='content-right'>
                                        <span className='pingjia' style={{color:'#353A42'}} onClick={(e)=>this.quxiao(e,item.consultation_id)}>取消</span>
                                        <span className='agin' style={{color:'#1F87FF',borderColor:"#1F87FF"}} onClick={(e)=>this.toPay(e,item)}>立即支付</span>
                                    </p>
                                </div>
                            </div>:null}
                        </div>)):<Nodata type='订单'/>}
                </Tabs.Tab>
                <Tabs.Tab title='待收货' key='pic'>
                    {list2.length?list2.map(item=>(
                        <div  onClick={()=>this.toInchat(item)}>
                            {item.consultation_stu=='0'||item.consultation_stu=='-1'?<div className='content-item'>
                                <div className='content-top'>
                                    <img src={item.docter_avatar} alt="" />
                                    <p className='content-news'>
                                        <p>
                                            <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                            <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                        </p>
                                        <p>
                                            <span>{item.docter_title}</span>
                                            {/* <span>呼吸科</span> */}
                                        </p>
                                    </p>
                                    <p className='content-over'>{item.consultation_stu=='1'?'已结束':item.consultation_stu=='0'?'进行中':item.consultation_stu=='-1'?'进行中':null}</p>
                                </div>
                                <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patient_name} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                <div className='content-bottom'>
                                    <p></p>
                                    {/*<p className='content-delete' onClick={()=>this.delOrder(item.consultation_id)}>删除订单</p>*/}
                                    <p className='content-right'>
                                        <span></span>
                                        <span className='agin' onClick={()=>this.goinfo(item)}>回到咨询</span>
                                    </p>
                                </div>
                            </div>:null}
                        </div>)):<Nodata type='订单'/>}
                </Tabs.Tab>
                <Tabs.Tab title='已完成' key='phone'>
                    {list3.length?list3.map(item=>(
                        <div  onClick={()=>this.toInchat(item)}>
                            {item.consultation_stu=='1'?<div className='content-item'>
                                <div className='content-top'>
                                    <img src={item.docter_avatar} alt="" />
                                    <p className='content-news'>
                                        <p>
                                            <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                            <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                        </p>
                                        <p>
                                            <span>{item.docter_title}</span>
                                            {/* <span>呼吸科</span> */}
                                        </p>
                                    </p>
                                    <p className='content-over'>{item.consultation_stu=='1'?'已结束':item.consultation_stu=='0'?'进行中':item.consultation_stu=='-1'?'进行中':null}</p>
                                </div>
                                <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patient_name} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                <div className='content-bottom'>
                                    {/*<p></p>*/}
                                    <p className='content-delete' onClick={()=>this.delOrder(item.consultation_id)}>删除订单</p>
                                    {item.doc_evaluate_star?
                                        <p className='content-right'>
                                            <span></span>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id)}>再次咨询</span>
                                        </p>
                                        :<p className='content-right'>
                                            <span className='agin' onClick={(e)=>this.again(e,item.docter_id)}>再次咨询</span>
                                            <span className='pingjia' onClick={() => this.setState({
                                                visible1: true,
                                                item1:item
                                            })}>评价</span>
                                        </p>}
                                </div>
                            </div>:null}
                        </div>)):<Nodata type='订单'/>}
                </Tabs.Tab>
                <Tabs.Tab title='已取消' key='quxiao'>
                    {list4.length?list4.map(item=>(
                        <div  onClick={()=>this.toInchat(item)}>
                            {item.consultation_stu=='4'||item.consultation_stu=='3'?<div className='content-item'>
                                <div className='content-top'>
                                    <img src={item.docter_avatar} alt="" />
                                    <p className='content-news'>
                                        <p>
                                            <span style={{fontSize:'14px',fontWeight:'bolder',marginRight:'10px'}}>{item.docter_name}</span>
                                            <span className='tuwen'>{item.consultation_type=='1'?'图文':item.consultation_type=='2'?'语音':item.consultation_type=='3'?'视频':null}</span>
                                        </p>
                                        <p>
                                            <span>{item.docter_title}</span>
                                            {/* <span>呼吸科</span> */}
                                        </p>
                                    </p>
                                    <p className='content-over'>已取消</p>
                                </div>
                                <p className='content-miaosu'><span style={{color:'#888'}}>病情描述：</span><span>{item.consultation_detail}</span></p>
                                <p className='content-miaosu'><span style={{color:'#888',marginRight:'10px'}}>就诊人：</span><span>{item.patient_name} </span><span>{item.patient_sex} </span><span>{(new Date()).getFullYear()-(new Date(item.patient_birthday)).getFullYear()}岁</span></p>
                                <p className='content-miaosu'><span style={{color:'#888'}}>下单时间：</span><span>{item.create_time}</span></p>
                                <div className='content-bottom'>
                                    <p className='content-delete' onClick={()=>this.delOrder(item.consultation_id)}>删除订单</p>
                                    <p className='content-right'>
                                        <span></span>
                                        <span className='agin' onClick={(e)=>this.again(e,item.docter_id)}>重新咨询</span>
                                    </p>
                                </div>
                            </div>:null}
                        </div>)):<Nodata type='订单'/>}
                </Tabs.Tab>
            </Tabs>
        </div>
    )
}