import React, { Component } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Empty,Radio,Space,Selector,FloatingBubble } from 'antd-mobile-v5'
// import vConsole from "../../../../api/vconsole";
import search from "../../../../static/img/home/search1.png";
import fhhome from "../../../../static/img/icon/fhhome.png";
import icon2 from "../../../../static/img/icon/false1.png";
import { ImagePicker, Toast, Popover, Button, Picker, List } from "antd-mobile"
import { uploaddoctor, uploadpic } from "../../../../api/hospitalapi/upload"
import { getBase64Image } from '../../../../api/config'
import { zipImg } from '../../../../component/zipImage'
import { addWesternDrugs, addWesternDrugstoRetail } from '../../../../api/hospitalapi/drugs';
import getUrl from '../../../../component/GetUrlParam'
import { medLevel2Category, medLevel3Category, medLevel1Category } from '../../../../api/request/med'
import { searchDrugs } from "../../../../api/hospitalapi/drugs"
import MedicineSearch from '../medicine-search'
import _ from 'lodash'
import '../style.less'

export default class Index extends Component {

    constructor() {
        super()
        this.state = {
            data1: [],
            docterId: localStorage.getItem("docterId"),
            code: 1,
            dateList: '',
            browseList: '',
            page: 1,
            size: 20,
            countallNum: 0,//接诊总数
            countNum: 0,
            createTime: '',
            prescribingState: '',
            visible: false,
            visible1: false,
            visible2: false,
            flag: false,
            listsum: '',
            list3: [],
            newsstate: 1,
            prescribingId: '',
            fileList1: [],
            fileList2: [],
            evaluatePic1: [],
            evaluatePic2: [],

            proComName: "",     //药品通用名
            proClassify: "",    //药品规格
            proSpec: null,        //建议零售价
            originPrice: "",    //批准文号
            proApproval: "",    //生产厂家
            proCompany: "",     //计量单位
            unit: "",           //单位
            packingUnit: '',    //包装单位 1：盒 2：袋 3：瓶 4：支
            supervisionType: "",//药品类别
            proQrcode: "",      //药品一维码

            categoryId: null,                //一级分类id
            categoryName: "",              //一级分类名称
            classifyId: null,                //二级分类ID
            classifyName: "",              //二级分类名称
            classifysonId: null,             //三级分类ID
            classifysonName: "",           //三级分类id
            pharmacyId: localStorage.getItem("pharmacyId"),                //店铺ID
            data: [],
            categoryLevel1: [],
            categoryLevel2: [],
            categoryLevel3: [],
            collectParams: [],
            showChose: "请选择>",
            showChoseOne: "请选择>",
            medschange: 1,  //1:西/中药添加   2:中药材添加

            showChoseApp: "请选择点击药品代码>",
            radioValue:1,//1:国药准字，2:注册药品注册证号

            pro_name: '',
            pro_price: '',
            pro_packing: null,
            pro_type: null,
            pro_dosage_form: null,
            pro_apply_symptoms: null,
            pro_classify: '0',
            pharmacyName: localStorage.getItem("pharmacyName"),

            initialValues: {
                proComName: "",
                proClassify: "",
                proSpec: null,
                originPrice: "",
                proApproval: "",
                proCompany: "",
                unit: "",
                packingUnit: 1,
                supervisionType: "",
                proQrcode: "",
                categoryId: null,
                categoryName: "",
                classifyId: null,
                classifyName: "",
                classifysonId: null,
                classifysonName: "",
                showChose: "请选择>",
                showChoseOne: "请选择>",
                showChoseApp: '请选择点击药品代码>'
            }
        }
    }


    imgChange1 = async (files, type, index) => {
        const { fileList4, compressThreshold = 1 } = this.state
        // console.log(files)
        let imgList = []
        let pictureQuality = 0.5
        if (type === 'add') {
            let file = files[files.length - 1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
                //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
                if ((fileSize >= compressThreshold)) {
                    if (fileSize * 0.9 <= 1) {
                        pictureQuality = 0.9
                    } else if (fileSize * 0.8 <= 1) {
                        pictureQuality = 0.8
                    } else if (fileSize * 0.7 <= 1) {
                        pictureQuality = 0.7
                    } else if (fileSize * 0.6 <= 1) {
                        pictureQuality = 0.6
                    } else if (fileSize * 0.5 <= 1) {
                        pictureQuality = 0.5
                    } else if (fileSize * 0.4 <= 1) {
                        pictureQuality = 0.4
                    } else if (fileSize * 0.3 <= 1) {
                        pictureQuality = 0.3
                    } else if (fileSize * 0.2 <= 1) {
                        pictureQuality = 0.2
                    } else if (fileSize * 0.1 <= 1) {
                        pictureQuality = 0.1
                    } else {
                        pictureQuality = 0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a = getBase64Image(file, pictureQuality, callback1)
                                let that = this
                                function callback1(val) {
                                    // console.log(val)
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        // console.log(res1)
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            // console.log(res2)
                                            if (res2.code === 2) {
                                                that.setState({
                                                    fileList1: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic1: imgList
                                            }, () => {
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                } else {
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList1: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic1: imgList
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList1: files,
                evaluatePic1: imgList
            })
            // console.log(files);
        }
    }
    imgChange2 = async (files, type, index) => {
        const { fileList4, compressThreshold = 1 } = this.state
        // console.log(files)
        let imgList = []
        let pictureQuality = 0.5
        if (type === 'add') {
            let file = files[files.length - 1].file
            let fileSize = file.size / 1024 / 1024;
            // console.log(file);
            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
                //当开启图片压缩且图片大小大于等于压缩阈值,进行压缩
                if ((fileSize >= compressThreshold)) {
                    if (fileSize * 0.9 <= 1) {
                        pictureQuality = 0.9
                    } else if (fileSize * 0.8 <= 1) {
                        pictureQuality = 0.8
                    } else if (fileSize * 0.7 <= 1) {
                        pictureQuality = 0.7
                    } else if (fileSize * 0.6 <= 1) {
                        pictureQuality = 0.6
                    } else if (fileSize * 0.5 <= 1) {
                        pictureQuality = 0.5
                    } else if (fileSize * 0.4 <= 1) {
                        pictureQuality = 0.4
                    } else if (fileSize * 0.3 <= 1) {
                        pictureQuality = 0.3
                    } else if (fileSize * 0.2 <= 1) {
                        pictureQuality = 0.2
                    } else if (fileSize * 0.1 <= 1) {
                        pictureQuality = 0.1
                    } else {
                        pictureQuality = 0.5
                    }
                    // console.log(pictureQuality)
                    //判断浏览器内核是否支持base64图片压缩
                    if (typeof (FileReader) === 'undefined') {
                        return file;
                    } else {
                        try {
                            this.setState({
                                spinLoading: true
                            });
                            return new Promise(resolve => {
                                //压缩图片再上传
                                let a = getBase64Image(file, pictureQuality, callback1)
                                let that = this
                                function callback1(val) {
                                    // console.log(val)
                                    //base64转png图片
                                    zipImg(val).then(res1 => {
                                        // console.log(res1)
                                        let formData = new FormData()
                                        formData.append('file', res1)
                                        uploadpic(formData).then(res2 => {
                                            // console.log(res2)
                                            if (res2.code === 2) {
                                                that.setState({
                                                    fileList2: files
                                                })
                                                imgList.push(res2.data)
                                            } else {
                                                Toast.info('上传失败', 1)
                                            }
                                            that.setState({
                                                evaluatePic2: imgList
                                            }, () => {
                                                // console.log(imgList);
                                            })
                                            // Toast.hide()
                                        }).catch(err => {
                                            // Toast.hide()
                                        })
                                    })
                                }
                            }).then(res => {
                                this.setState({
                                    spinLoading: false
                                });
                                // console.log(res)

                                // return res;
                            }).catch(() => {
                                this.setState({
                                    spinLoading: false
                                });
                                return file;
                            });
                        } catch (e) {
                            this.setState({
                                spinLoading: false
                            });
                            //压缩出错,直接返回原file对象
                            return file;
                        }
                    }
                } else {
                    zipImg(file).then(res => {
                        let formData = new FormData()
                        formData.append('file', res)
                        uploadpic(formData).then(res1 => {
                            if (res1.code === 2) {
                                this.setState({
                                    fileList2: files
                                })
                                imgList.push(res1.data)
                            } else {
                                Toast.info('上传失败', 1)
                            }
                            this.setState({
                                evaluatePic2: imgList
                            })
                            // console.log(imgList);
                            // Toast.hide()
                        }).catch(err => {
                            // Toast.hide()
                        })
                    })
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            imgList.splice(index, 1)
            this.setState({
                fileList2: files,
                evaluatePic2: imgList
            })
            // console.log(files);
        }
    }
    /**
     * value 等于1：添加中药
     * value 等于2：添加西药
     * @param {} value 
     */
    changeAddMedicine = (value) => {
        if (value == 1) {
            this.props.history.push('')
        } else {

        }

    }
    /**
     * ****************添加药品准备工作**************
     */

    /**
     * 接收用户输入的药品信息
     * 改变状态数据
     * @param {any} value 
     */
    setStateData = (even, stateName) => {
        // debugger
        if (even == null || stateName == null) {
            return
        }
        

        let stateData = even.target.value.trim()
        // if(stateName != ('originPrice' || 'proSpec')){
        //      stateData = even.target.value.trim();
        // }
        // debugger
        if (stateName === "originPrice") {
            const input = even.target.value.replace(/\D/g, ''); // 只保留数字字符
            stateData = input;
            even.target.value = input; // 更新输入框的值
        }

        this.setState({
            [stateName]: stateData
        })
    }

    /**
     * 添加药品分类
     */
    addCategory = (e) => {
        // console.log(e)
        let name =""
        let cname = ""
        switch (e.length) {

            case 3:
                // debugger
                name = this.getCategoryNameById(this.state.categoryLevel3, e[2], "classifysonName", "classifysonId")
                cname = name
                this.setState({
                    classifysonId: e[2],
                    classifysonName: name,

                })
            case 2:
                name = this.getCategoryNameById(this.state.categoryLevel2, e[1], "classifyName", "classifyId")
                cname = name + "/" + cname
                this.setState({
                    classifyId: e[1],
                    classifyName: name,

                })
            case 1:
                name = this.getCategoryNameById(this.state.categoryLevel1, e[0], "categoryName", "categoryId")
                cname = name + "/" + cname
                this.setState({
                    categoryId: e[0],
                    categoryName: name,
                    showChoseOne: cname
                })

        }
        return cname
    }


    /**
     * 根据分类id获取分类名称
     * @param {*} arr 分类数组
     * @param {*} id 选种的分类id
     * @param {*} attrName 分类名称
     * @param {*} attrKey 分类id属性名
     * @returns 
     */
    getCategoryNameById(arr, id, attrName, attrKey) {
        // debugger
        let name
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][attrKey] == id) {
                name = arr[i][attrName]
                return name
            }
        }
    }

    /**
     * 查询所有【所有一级药品分类】
     */
    getLevel1 = async () => {
        // debugger
        const res = await medLevel1Category()
        // console.log("一级分类", res)

        let result = res.data[0]

        const categoryLevel1 = _.cloneDeep(result)

        for (let i = 0; i < result.length; i++) {
            result[i]["label"] = result[i].categoryName
            result[i]["value"] = result[i].categoryId
        }

        this.setState({
            categoryLevel1: categoryLevel1,
            data: result
        }, () => {
            this.getLevel2()
        })
    }

    /**
     * 查询指定【二级药品分类】
     */
    getLevel2 = async () => {

        let res = await medLevel2Category()

        // console.log("二级级分类", res)
        let result = res.data[0]
        const categoryLevel2 = _.cloneDeep(result)
        for (let i = 0; i < result.length; i++) {
            result[i]["label"] = result[i].classifyName
            result[i]["value"] = result[i].classifyId
        }


        let newData = this.state.data

        for (let i = 0; i < newData.length; i++) {
            result.map((level2, index) => {
                if (newData[i].categoryId === level2.categoryId) {
                    // debugger
                    if (!newData[i].hasOwnProperty("children")) {
                        newData[i]["children"] = []
                    }
                    newData[i].children.push(level2)
                }
            })
        }

        this.setState({
            categoryLevel2: categoryLevel2,
            data: newData
        }, () => {
            this.getLevel3()
        })
    }
    /**
     * 查询指定【三级药品分类】
     */
    getLevel3 = async () => {
        let res = await medLevel3Category()
        // console.log("三级级分类", res)


        // categoryLevel3 = result
        this.setState({
            categoryLevel3: res.data[0]
        })
        let result = res.data[0]
        for (let i = 0; i < result.length; i++) {
            result[i]["label"] = result[i].classifysonName
            result[i]["value"] = result[i].classifysonId
        }
        let newData = this.state.data
        for (let i = 0; i < newData.length; i++) {
            if (newData[i].children) {
                for (let j = 0; j < newData[i].children.length; j++) {
                    result.map((item) => {
                        // debugger
                        if (item.classifyId === newData[i].children[j].classifyId) {
                            if (!newData[i].children[j].hasOwnProperty("children")) {
                                // debugger
                                newData[i].children[j]["children"] = []
                                // console.log(newData)
                            }
                            newData[i].children[j].children.push(item)
                        }
                    })
                }
            }

        }
        this.setState({
            data: newData
        })

    }

    /**
     * 药品类别
     * @param {*} e 
     */
    addMedicinalCategory = (e) => {
        let arr = ["中药", "西药", "中成药"]
        // console.log(e)

        this.setState({
            supervisionType: e[0],
            showChose: arr[e[0] - 1]
        })

    }
    
    /**
     * 批准文号类型选择
     */
    handleRadioChange = (e) => {
        let { radioValue } = this.state
        this.setState({
            radioValue:e
        })
    }

    /**
     * 包装单位选择
     */
    handleSelectChange = (e) => {
        let { packingUnit } = this.state
        this.setState({
            packingUnit:e[0]
        })
    }

    /**
     * 选择国药准字
     * @param e
     */
    addMedApproval = (e) => {
        let arr = ["国药准字H", "国药准字HJ","国药准字J","国药准字S","国药准字Z","国药准字B","国药准字C","国药准字ZJ"]
        this.setState({
            showChoseApp: arr[e[0] - 1]
        })
    }


    /**
     * 发送请求，向数据库添加药品数据
     * 添加药品
     */
    handleSubmit = async () => {
        const includes = ["pro_qrcode", "classify_name", "classify_id", "classifyson_name", "classifyson_id"]
        const {
            proComName,
            proClassify,
            proSpec,
            originPrice,
            proApproval,
            proCompany,
            unit,
            packingUnit,
            supervisionType,
            proQrcode,

            categoryId,                //一级分类id
            categoryName,              //一级分类名称
            classifyId,                //二级分类ID
            classifyName,              //二级分类名称
            classifysonId,             //三级分类ID
            classifysonName,           //三级分类id
            pharmacyId,                //店铺ID
            pharmacyName,
            initialValues,
            showChoseApp,
            radioValue

        } = this.state
        
        let newPackingUnit = packingUnit == 1 ? '盒' : packingUnit == 2 ? '袋' : packingUnit==3 ? '瓶' : packingUnit==4 ? '支' : null
        let newSupervisionType = radioValue == 2 ? 2 : (showChoseApp == "国药准字H" || showChoseApp == "国药准字HJ" || showChoseApp == "国药准字J" || showChoseApp == "国药准字S") ? 2 : 3
        let newProType = radioValue == 2 ? '西药' : (showChoseApp == "国药准字H" || showChoseApp == "国药准字HJ" || showChoseApp == "国药准字J" || showChoseApp == "国药准字S") ? '西药' : '中成药'
        

        let newProApproval = radioValue == 2 ? '进口药品注册证号'+ proApproval : showChoseApp+proApproval 
        
        let params;

        params = {
            pro_com_name: proComName,    //药品通用名
            category_id: categoryId,                //一级分类id
            category_name: categoryName,              //一级分类名称
            classify_id: classifyId,                //二级分类ID
            classify_name: classifyName,              //二级分类名称
            classifyson_id: classifysonId,             //三级分类ID
            classifyson_name: classifysonName,           //三级分类id
            pro_spec: proSpec,          //药品规格
            origin_price: originPrice ? originPrice : 0,  //建议零售价
            pro_approval: newProApproval == '请选择点击药品代码>' ? null : newProApproval,  //批准文号

            pro_app:proApproval,
            pro_company: proCompany,    //生产厂家
            unit: unit,                 //计量单位
            packing_unit: newPackingUnit,  //单位
            supervision_type: newSupervisionType, //药品类别
            pro_qrcode: proQrcode,      //药品一维码
            pharmacy_id: pharmacyId,                //店铺ID

            pro_name: proComName,
            pro_price: originPrice ? originPrice : 0,
            pro_packing: null,
            pro_type: newProType,
            pro_dosage_form: null,
            pro_apply_symptoms: null,
            pro_classify: '0',
            create_user: pharmacyName

        }

        for (let key in params) {

            let str = params[key]
            if (!includes.includes(key)) {
                if (!str) {
                    if (key == "pro_com_name") {
                        Toast.fail("药品通用名不能为空");
                        return;
                    } else if (key == "category_name") {
                        Toast.fail("药品分类不能为空");
                        return;
                    } else if (key == "pro_spec") {
                        Toast.fail("药品规格不能为空");
                        return;
                    } else if (key =='packing_unit'){
                        Toast.fail("包装单位不能为空");
                        return;
                    } else if (key == "pro_approval") {
                        Toast.fail("批准文号不能为空");
                        return;
                    } else if (key == 'pro_app'){
                        Toast.fail("批准文号不能为空");
                        return;
                    }
                }

            }
        }

        // let res = await addWesternDrugs(params)
        // console.log('药品详情',params);
        let res = await addWesternDrugstoRetail(params)
        // console.log(res);
        if (res.code == 1 && res.data.data[0][0].total != 0) {
            Toast.info("您添加的药品已存在，可以正常开方")
        }
        if (res.code == 1 && res.data.data[0][0].total === 0) {
            Toast.success("添加药品成功，可在添加记录查看或者点击申请开方可搜索该药品问诊")
            setTimeout(() => {
                this.setState({
                  ...this.state.initialValues
                });
                this.props.history.replace('/westernm') // 重新加载页面
                window.location.reload();
            }, 200); 
        }


    }
    /**
     * 获取页面传参
     */
    getPageParams = () => {
        const { showChoseApp } = this.state
        const searchParams = new URLSearchParams(this.props.location.search);

        if (searchParams) {
            const medapproval = searchParams.get('med_approval')
            let parts = []
            if(medapproval){
                parts = medapproval.match(/(\d+)|([^\dA-Za-z]+)|([A-Za-z]+)/g);
                if (parts) {
                    for (let i = 0; i < parts.length; i++) {
                        // 如果中文部分存在冒号，去掉冒号
                        if (/[：:]/.test(parts[i]) && /[\u4e00-\u9fa5]/.test(parts[i])) {
                            parts[i] = parts[i].replace(/[:：]/g, '');
                        }
                    }
                }

            }
            const med_name = searchParams.get('med_name');
            const med_approval = searchParams.get('med_approval')
            const supervision_type = searchParams.get('supervision_type')
            const unitD = searchParams.get('unit')
            const categoryId =searchParams.get('categoryId')
            const classifyId = searchParams.get('classifyId')
            const classifysonId = searchParams.get('classifysonId')
            const categoryName = searchParams.get('categoryName')
            const classifyName = searchParams.get('classifyName')
            const classifysonName = searchParams.get('classifysonName')
            const packing_unit = searchParams.get('packing_unit')
            const med_company = searchParams.get('med_company')

            this.addMedicinalCategory(supervision_type + 1);
            this.addCategory([
                parseInt(categoryId),
                parseInt(classifyId),
                parseInt(classifysonId),
            ]);
            this.setState({
                proComName: med_name,
                // proApproval: med_approval,
                proCompany: med_company,
                unit: unitD,
                packingUnit: packing_unit,
                supervisionType: supervision_type,
                categoryId: categoryId, //一级分类id
                classifyId: classifyId, //二级分类ID
                classifysonId: classifysonId, //三级分类ID
                categoryName: categoryName,
                classifyName: classifyName,
                classifysonName: classifysonName,
                showChoseOne: categoryName&&classifyName&&classifysonName ? categoryName + "/" + classifyName + "/" + classifysonName 
                                : categoryName&&classifyName ? categoryName + "/" + classifyName : categoryName ? categoryName : "",
                showChoseApp: (med_approval && parts.length) ? (parts[0] == '国药准字' ? parts[0]+parts[1] : (parts[0] == '进口药品注册证号' ? 2 : 1)) : '请选择点击药品代码>',
                radioValue:(med_approval && parts.length) ? (parts[0] == '国药准字' ? 1 : (parts[0] == '进口药品注册证号' ? 2 : 1)) : 1,
                proApproval:(med_approval && parts.length)? (parts[0] == '国药准字' ? parts[2] : (parts[0] == '进口药品注册证号' ? parts[1] : '')) : ''
            });
        }
    }
    /**
     * ****************添加药品结束**************
     */

    componentDidMount() {
        this.getPageParams()
        this.getLevel1()
    }



    render() {

        let { 
            data, 
            showChose, 
            showChoseOne,
            showChoseApp,
            radioValue,
            proComName,
            proClassify, 
            proSpec, 
            originPrice, 
            proApproval, 
            proCompany, 
            unit, 
            packingUnit, 
            supervisionType, 
            medschange, 
            proQrcode,
            categoryName,
            classifyName,
            classifysonName
        } = this.state

        let medicinalCategory = [
            {
                label: '中药',
                value: 1
            },
            {
                label: '西药',
                value: 2
            },
            {
                label: '中成药',
                value: 3
            },
        ]

        let approvalData = [
            {
                label: '国药准字H',
                value: 1
            },
            {
                label: '国药准字HJ',
                value: 2
            },
            {
                label: '国药准字J',
                value: 3
            },
            {
                label: '国药准字S',
                value: 4
            },
            {
                label: '国药准字Z',
                value: 5
            },
            {
                label: '国药准字B',
                value: 6
            },
            {
                label: '国药准字C',
                value: 7
            },
            {
                label: '国药准字ZJ',
                value: 8
            },
        ]

        return (
          <div>
            {/* 西药添加 */}
            <div className="home-header-addmed-outer11">
              <div className="top-tit">
                <img
                  className="return-img"
                  src={fhhome}
                  onClick={this.props.history.goBack}
                />
                <span className="title-middle">添加药品</span>
                <div
                  className="add-cord"
                  onClick={() => this.props.history.push(`/ShowUsMedicine?records=${1}`)}
                >
                  添加记录
                </div>
              </div>
              <div className="home-header-addmed">
                <div className="home-header-addmed-inner"></div>
                <div className="top-nav">
                  <div
                    className="top-nav-left1"
                    onClick={() => this.props.history.push("/westernm")}
                  >
                    西/中成药添加
                  </div>
                  <div
                    className="top-nav-right1"
                    onClick={
                      () => {
                        this.setState({ medschange: 2 });
                        this.props.history.replace("/traditionalm");
                      }
                    }
                  >
                    中药饮片添加
                  </div>
                </div>
              </div>

              <div className="top-tips-secrch">
                <span>
                  可通过药品名称或药品批准文号检索药品库快捷添加药品;若无药品信息,可直接手动添加药品。
                </span>
              </div>
              <div
                className="sousuobox"
                onClick={() => this.props.history.push("/MedicineSearch")}
              >
                <img src={search} />
                <span>请输入药品名称或批准文号</span>
              </div>

              <div className='k-line'></div>

              {/* 药品通用名 */}
              <p className="top-title">
                <span>*</span>
                <span>药品通用名：</span>
              </p>
              <p className="top-input">
                <input
                  placeholder="请输入商品通用名"
                  defaultValue={proComName}
                  onChange={(e) => this.setStateData(e, "proComName")}
                  autoCorrect="off"
                  autoComplete="off"
                  autoCapitalize="none"
                />
              </p>

              {/* 药品分类 */}
              <p className="top-title">
                <span>*</span>
                <span>药品分类：</span>
              </p>
              <p className="top-input">
                <List className="my-list">
                  <Picker
                    extra={showChoseOne}
                    data={data}
                    cols={3}
                    onOk={(e) => this.addCategory(e)}
                    onDismiss={(e) => console.log("dismiss", e)}
                  >
                    <List.Item></List.Item>
                  </Picker>
                </List>
              </p>

              {/* 药品规格 */}
              <p className="top-title">
                <span>*</span>
                <span>药品规格：</span>
              </p>
              <p className="top-input">
                <input
                  placeholder="请输入药品规格（例：0.47g*24片）"
                  defaultValue={proSpec}
                  onChange={(e) => this.setStateData(e, "proSpec")}
                />
              </p>

              {/* 包装单位 */}
              <p className="top-title">
                <span>*</span>
                <span>包装单位：</span>
              </p>
              <p className='packgeUnitBox'>
                <Selector
                    style={{
                        '--border-radius': '100px',
                        '--checked-text-color': '#FFFFFF',
                        '--checked-color': '#1989FA',
                        '--checked-border': 'solid transparent 1px',
                        '--padding': '5px 24px',
                        '--color':'transparent',
                        '--text-color':'#434343',
                        '--border':'solid #DCDEE0 1px'
                    }}
                    showCheckMark={false}
                    columns={4}
                    options={[
                        {
                            label: '盒',
                            value: 1,
                        },
                        {
                            label: '袋',
                            value: 2,
                        },
                        {
                            label: '瓶',
                            value: 3,
                        },
                        {
                            label: '支',
                            value: 4,
                        },
                    ]}
                    defaultValue={[packingUnit]}
                    onChange={(e) => {this.handleSelectChange(e)}}
                />
              </p>

              {/* 批准文号 */}
              <p className="top-title-pzwh">
                <span className='xh'>*</span>
                <span className='tit-pzwh'>批准文号：</span>
                <p className='radiobox'>
                    <Radio.Group defaultValue={radioValue} onChange={(e)=>{this.handleRadioChange(e)}}>
                        <Space direction='horizontal'>
                            <Radio value={1} style={{
                                '--icon-size': '13px',
                                '--font-size': '14px',
                                '--gap': '6px',
                                textAlign: 'center',
                                lineHeight: '1' ,
                                fontWeight:'500'
                            }}>国药准字</Radio>
                            <Radio value={2} style={{
                                '--icon-size': '13px',
                                '--font-size': '14px',
                                '--gap': '6px',
                                textAlign: 'center',
                                lineHeight: '1' ,
                                fontWeight:'500'
                            }}>进口药品注册证号</Radio>
                        </Space>
                    </Radio.Group>
                </p>
              </p>
              {radioValue == 1 ? (
                <>
                    <p className="top-input">
                        <List>
                            <Picker
                                extra={showChoseApp}
                                data={approvalData}
                                title="请选择点击药品代码"
                                cols={1}
                                onOk={(e) => this.addMedApproval(e)}
                                onDismiss={(e) => console.log("取消", e)}
                            >
                                <List.Item></List.Item>
                            </Picker>
                        </List>
                    </p>
                    {radioValue == 1 && showChoseApp != '请选择点击药品代码>' ? (
                        <>
                            {showChoseApp == "国药准字H" || showChoseApp == "国药准字HJ" || showChoseApp == "国药准字J" || showChoseApp == "国药准字S" ? 
                                <p className='medTypeBox'>药品类别：西药</p> : 
                                <p className='medTypeBox'>药品类别：中成药</p>}
                        </>
                    ) : null}
                </>
              ) : null}

              {radioValue == 2 ? (
                <p className="top-input">
                    <input
                        placeholder="请输入批准文号"
                        defaultValue={proApproval}
                        onChange={(e) => this.setStateData(e, "proApproval")}
                    />
                </p>
              ) : <p className="top-input">
                    <input
                        type='number'
                        placeholder="请输入批准文号(数字)"
                        defaultValue={proApproval}
                        onChange={(e) => this.setStateData(e, "proApproval")}
                    />
                </p>}
              
              {radioValue == 2 ? (
                <p className='medTypeBox'>药品类别：西药</p>
              ) : null}


              {/* 生产厂家 */}
              <p className="top-title">
                <span>生产厂家：</span>
              </p>
              <p className="top-input">
                <input
                  placeholder="请输入生产厂家"
                  defaultValue={proCompany}
                  onChange={(e) => this.setStateData(e, "proCompany")}
                />
              </p>

              {/* 计量单位 */}
              <p className="top-title">
                <span>计量单位：</span>
              </p>
              <p className="top-input">
                <input
                  placeholder="请输入计量单位(例：0.7g/片)"
                  defaultValue={unit}
                  onChange={(e) => this.setStateData(e, "unit")}
                />
              </p>
              
              {/* 建议零售价（元） */}
              <p className="top-title">
                <span>建议零售价（元）：</span>
              </p>
              <p className="top-input">
                <input
                  type='text'
                  pattern="[0-9]*"
                  maxLength={5}
                  placeholder="请输入零售价格"
                  defaultValue={originPrice}
                  onChange={(e) => this.setStateData(e, "originPrice")}
                />
              </p>

              {/* 商品条形码 */}
              <p className="top-title">
                {/* <span>*</span> */}
                <span>商品条形码：</span>
              </p>
              <p className="top-input">
                <input
                  placeholder="请输入商品条形码"
                  defaultValue={proQrcode}
                  onChange={(e) => this.setStateData(e, "proQrcode")}
                />
              </p>

              {/* <p className="top-title">
                <span>*</span>
                <span>批准文号：</span>
              </p>
              <p className="top-input">
                <input
                  placeholder="请输入批准文号"
                  defaultValue={proApproval}
                  onChange={(e) => this.setStateData(e, "proApproval")}
                />
              </p> */}
              {/* <p className="top-title">
                <span>*</span>
                <span>包装单位：</span>
              </p>
              <p className="top-input">
                <input
                  placeholder="请输入包装单位(例：盒、瓶)"
                  defaultValue={packingUnit}
                  onChange={(e) => this.setStateData(e, "packingUnit")}
                />
              </p> */}
              {/* <p className="top-title">
                <span>*</span>
                <span>药品类别：</span>
              </p>
              <p className="top-input">
                <List>
                  <Picker
                    extra={showChose}
                    data={medicinalCategory}
                    cols={1}
                    onOk={(e) => this.addMedicinalCategory(e)}
                    onDismiss={(e) => console.log("dismiss", e)}
                  >
                    <List.Item></List.Item>
                  </Picker>
                </List>
              </p> */}
              
              <p className="add-save-tips">
                提示：新增商品不可修改，请认真核对信息后确认保存药品
              </p>
              <p className="add-save-button">
                <button onClick={() => this.handleSubmit()}>保存药品</button>
              </p>
            </div>

            {/* 悬浮窗-申请问诊 */}
            {/* <p
              className="sqwz-icon1"
              onClick={() => {
                this.props.history.push(`/drugselection`);
              }}
            >
              <span>申请问诊</span>
            </p> */}
            <div onClick={()=>{
                        this.props.history.push(`/drugselection`)
                    }}
                >
                    <FloatingBubble
                        axis='xy'
                        magnetic='x'
                        style={{
                        '--initial-position-bottom': '15px',
                        '--initial-position-right': '15px',
                        '--edge-distance': '15px',
                        '--background': '#FF9239',
                        '--size': '48px'
                        }}
                    >
                        <p>申请<br/>问诊</p>
                    </FloatingBubble>
            </div>

          </div>
        );
    }
}

