import React, { Component } from 'react'
import "./style.less"
import { Popup, Rate, TextArea,ImageViewer,SpinLoading } from "antd-mobile-v5"
import { Toast, ImagePicker, } from "antd-mobile"
import icon1 from "../../static/img/yy/fanhuihei.png"
import {lookStar, orderGetnews, patientStar, updatestu} from "../../api/hospitalapi/chat"
import { zipImg } from '../../component/zipImage'
import { getDoctorinfo } from "../../api/hospitalapi/user"
import { phoneLogin } from "../../api/hospitalapi/login"
import { changeStatus,changeNum,payUpdateState,zitiNum } from "../../api/hospitalapi/rejister"

import getUrl from '../../component/GetUrlParam'
import icon2 from "../../static/img/yy/touxiang.png";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: JSON.parse(localStorage.getItem("item")),
            patientPhone: localStorage.getItem("patientPhone"),
            visible1: false,
            value: "",
            consultationId: localStorage.getItem("consultationId"),
            consultationStu: "",
            detailContent: '',
            newslist: [],
            userpic: localStorage.getItem("patientAvatar"),
            userId: localStorage.getItem("patientId"),
            tradeno: '',
            doctorDetail: '',
            list: [{value: '回复及时', key: 1}, {value: '非常热心', key: 1}, {value: '解答很有帮助', key: 1}, {
                value: '服务专业',
                key: 1
            }],
            star: '',
            commentValue: '',
            stateStar: 0,
            starValue: '',
        }
    }

    componentDidMount = async () => {
        const {docterPhone} = this.state
        // console.log(getUrl('docterPhone', this.props.location.search))
        if (getUrl('consultationNumber', this.props.location.search)) {//
            let res = await orderGetnews({consultationNumber: getUrl('consultationNumber', this.props.location.search)})
            if (res && res.data && res.data[0] ) {
                this.setState({
                    consultationStu: res.data[0][0].consultation_stu
                })
                if (res.data[0][0].consultation_stu == -1){
                    this.props.history.push("/mine/record")
                }
            }
        }
        if (getUrl('docterPhone', this.props.location.search)) {
            let res = await getDoctorinfo({docterPhone: getUrl('docterPhone', this.props.location.search)})
            // console.log(res)
            if (res && res.data && res.data[0]) {
                this.setState({
                    doctorDetail: res.data[0][0]
                })
            }
        }
    }

    // 返回
    goback = () => {
        this.props.history.push("/")
    }
    //评价星数
    starCount = (v) => {
        // console.log(v);
        this.setState({
            star: v
        })
    }
    //快捷评价
    addList = (i, v) => {
        const {list} = this.state
        list.map((m, n) => {
            if (n == v) {
                if (i.key == 1) {
                    m.key = 2
                } else {
                    m.key = 1
                }
            }
        })
        this.setState({
            list
        })
    }
    //评价内容
    comment = (v) => {
        this.setState({
            commentValue: v.target.value.replace(/\s*/g, "")
        })
    }
    //提交评论
    totalk = () => {
        const {item, userId, commentValue, star, doctorDetail, docterId, list} = this.state
        let a = []
        list.map((i, v) => {
            if (i.key == 2) {
                a.push(i.value)
            }
        })
        //http://localhost:3001/#/evaluate?docterPhone=18181947648
        if (commentValue && star && parseInt(star)) {
            let params = {
                userId,
                docterId: doctorDetail.docter_id,
                consultationId: getUrl('consultationId', this.props.location.search),
                docEvaluateText: commentValue,
                docEvaluateQuick: a.length ? a.toString() : '',
                docEvaluateStar: star
            }
            // console.log(params)
            patientStar(params).then(res => {
                // console.log(res);
                if (res && res.code == 1) {
                    Toast.success('提交成功')
                    this.props.history.push("/")
                }
            })

        } else {
            Toast.info('请填写评论和星级')
        }
    }

    render() {
        const {item, list, consultationStu, doctorDetail} = this.state
        return (
            <>
                {consultationStu && consultationStu != -1 ? <div className='setbox'>
                    <div className='box-top'>
                        <span style={{fontSize: '16px', fontWeight: '600', marginLeft: '15px'}}>问诊服务评价</span>
                        <span className='fanh' onClick={this.goback}>返回</span>
                    </div>
                    <div className='evaluate-body'>
                        <div className='popup-box0'>
                            <div className='box-top0'>
                                <img src={doctorDetail ? doctorDetail.docter_avatar : icon2} alt=""/>
                                <div className='pj-doc1'>
                                    <h2>评价医生本次服务</h2>
                                    <p>
                                        <span>{doctorDetail ? doctorDetail.docter_name : ''}</span><span>{doctorDetail ? doctorDetail.docter_title : ''}</span><span>{doctorDetail ? doctorDetail.docter_department : ''}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='pingfen'>
                            <Rate
                                style={{
                                    '--active-color': '#1F87FF',
                                }}
                                onChange={val => this.starCount(val.toString())}/>
                        </div>
                        <div className='pop-tips'>
                            {list.map((i, v) => (
                                <span className={i.key == 1 ? 'before' : 'end'}
                                      onClick={() => this.addList(i, v)}>{i.value}</span>))}
                        </div>
                        <div className='popup-text'>
                            <textarea name="" id="" maxLength={100} rows="5" placeholder='谈谈你对本次就医医生的评价吧~(最多100字)'
                                      onChange={val => this.comment(val)}>
                            </textarea>

                        </div>
                        <div className='popup-btn'>
                            <button onClick={this.totalk}>提交评论</button>
                        </div>
                    </div>
                </div> : null}
            </>

        )
    }
}
