import React, { useState, useEffect } from 'react'
import { Toast, Modal, List, Radio, TextareaItem, Button, ImagePicker } from 'antd-mobile'
import { Popup } from "antd-mobile-v5"
import Card from '../../baseui/card/index'
import BottomButton from '../../baseui/fuctionbutton/index'
import Meditem from './orderitem/index'
import { adressListRequest } from '../../api/request/adress'
import { oderdetailRequest, upOrderStuRequest, applyRefundRequest, addRemittance, remittanceList, delRemittance, ytGetwl, jdGetwl,stGetwl,updatePartrefundState,applyPartRequest,changenum } from '../../api/request/order'
import { getTau, getDetails, getKnightInfo, getcancelreasonlist, getstatus, addstore, uploadimg, getstorestatus } from "../../api/request/logistics"
import { storeDetailsRequest } from "../../api/request/store"
import { addIntegralRequest } from '../../api/request/integral'
import { pharmacyStorecs } from '../../api/request/cart'
import { aftersaleMedList,aftersaleList,getState } from '../../api/request/aftersale'
import { orderDetails } from '../../api/hospitalapi/order'

import getUrl from '../../component/GetUrlParam'
import { uploadImg } from '../../api/request/upload'
import { zipImg } from '../../component/zipImage/index'
import './orderdetail.less'
import '../../static/css/iconfont.css'
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/close1.png"
import icon9 from "../../static/img/yy/jilu.png"
import {code, zfUrl} from "../../publicurl";
const { alert } = Modal
const RadioItem = Radio.RadioItem
const BMap = window.BMap

//订单状态 -2.待付款,-1.备货,-11.确认订单,0.运输,1.待自取,2.待评价,3.已完成,4.已取消,5.已申请退款,6.已退款,7.申请失败
export default function OrderState(props) {
    const userId = localStorage.getItem("patientId")
    const reasons = ['不想要了/临时有事', '拍错/多拍/不想要了', '地址电话填错', '忘记用优惠券', '商家联系我取消', '骑手联系我取消', '其它原因']
    const [files1, setFiles1] = useState([])
    const [evaluatePic1, setEvaluatePic1] = useState([])
    const [evaluatePic2, setEvaluatePic2] = useState()
    const [remittanceId, setremittanceId] = useState()
    const [state, setState] = useState('-2')
    const [orderDetail, setOrderDetail] = useState(null)
    const [orderAddress, setOrderAddress] = useState(null)
    const [medDetail, setMedDetail] = useState(null)
    const [radioVal, setRadioVal] = useState('')
    const [visible1, setvisible1] = useState(false)
    const [reasonContent, setReasonContent] = useState('')
    const orderNumber = getUrl('tradeno', props.location.search)


    useEffect(() => {
        getOrder()
    }, [])

    const getOrder = async () => {
        const res = await orderDetails(orderNumber)
        // console.log(res)
        if (res&&res.data[0]){
            setOrderDetail(res.data[0][0])
        }
        if (res&&res.data[1]){
            setMedDetail(res.data[1])
        }
    }

    // 复制订单号
    const copyDanhao = () => {
        const spanText = document.getElementById('dingdanhao').innerText;
        const oInput = document.createElement('input');
        oInput.value = spanText;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        document.body.removeChild(oInput);
        Toast.info('复制成功')
    }
    const goBack=()=>{
        window.history.back()
    }
    const toPay=()=>{
        const orderName = `${medDetail[0].medName}等`
        let b=(new Date()).getTime()
        window.location.href = `${zfUrl}/route?client_id=${code}&channel=&client_trade_no=${orderNumber}&total_fee=${orderDetail.orderAmount}&timestamp=${b}&order_name=${orderName}&sign=&order_extra=`
    }
    // console.log(orderDetail)
    return (orderDetail?
        <div className='setbox' style={{backgroundColor:'#fff',paddingBottom:'100px',position:'relative',height:'100vh',overflow:'scroll'}}>
            <div className='box-top'>
                <img src={icon1} alt="" onClick={() => goBack()} />   购药详情
            </div>
            <div className='ordertetails-title'>
                <p className='ordertetails-state'>{orderDetail.orderState=='-2'?'待支付':
                    orderDetail.orderState=='-1'?'待发货':
                    orderDetail.orderState=='0'?'待收货':
                        orderDetail.orderState=='1'?'待自提':
                            orderDetail.orderState=='-3'?'待接单':
                        orderDetail.orderState=='2'?'已完成':
                            orderDetail.orderState=='4'?'已取消' :null}</p>
                <p className='ordertetails-step'>{orderDetail.orderShipping=='2'?'配送':'自提'}
                    {orderDetail.orderState=='0'||(orderDetail.orderState=='0'&&orderDetail.orderShipping=='2')?<span className='ordertetails-wuliu'>
                        <span>| {orderDetail.orderCourier}</span>
                        <span>物流单号{orderDetail.orderCourierid} </span>
                    </span>:null}
                </p>
            </div>
            <div className='user-news'>
                <span>{orderDetail.userName}</span>
                <span>{orderDetail.phone}</span>
            </div>
            {orderDetail.orderShipping=='2'?<div className='ordertetails-user-news'>
                <p className='ordertetails-user-name'>
                    <span>{orderDetail.addressee}</span>
                    <span>{orderDetail.phone}</span>
                </p>
                <p className='ordertetails-user-address'>
                    <span>{orderDetail.province+orderDetail.city+orderDetail.district+orderDetail.address}</span>
                    {orderDetail.orderState=='0'?<span className='wuliu' onClick={()=>setvisible1(true)}>物流信息</span>:<span></span>}
                </p>
            </div>:null}
            {orderDetail.orderState=='1'&&orderDetail.orderShipping=='1'?
            <div className='get-erweima'>
                <p className='get-erweima-name'>取药号码</p>
                <p className='get-erweima-pic'>
                    {/*<img src={icon9} />*/}
                    {orderDetail.orderShippingCode}
                </p>
                <p className='get-erweima-pic2'>凭此号码到窗口取药</p>
            </div>:null}
            <div className='all-med-details'>
                <p className='med-title'>药品明细</p>
                {medDetail&&medDetail.map(item=>(
                    <p className='med-details'>
                        <p className='med-name'>{item.medName}{item.medSpec}</p>
                        <p className='med-count'>x{item.medQuantity}</p>
                        <p className='med-price'>￥{(item.medPrice/100).toFixed(2)}</p>
                    </p>
                ))}
            </div>
            <div className='order-fee'>
                <p className='order-fee-one'>
                    <span>配送费用</span>
                    <span>￥{orderDetail.orderFreight?(orderDetail.orderFreight/100).toFixed(2):0}</span>
                </p>
                <p className='order-fee-one'>
                    <span>药品费用</span>
                    <span>￥{(orderDetail.orderPrice/100).toFixed(2)}</span>
                </p>
                <p className='order-fee-one order-fee-two'>
                    <span>{orderDetail.orderState=='-2'?'应支付':'支付'}</span>
                    <span>￥{(orderDetail.orderAmount/100).toFixed(2)}</span>
                </p>
            </div>
            <div className='order-number-time'>
                <p>
                    <span>订单编号</span>
                    <span>{orderNumber}</span>
                </p>
                <p>
                    <span>创建时间</span>
                    <span>{orderDetail.createTime}</span>
                </p>
            </div>
            {orderDetail.orderState=='-2'?<div className='pay-bottom'>
                <p className='pay-bottom-left'>
                    <span>合计：</span>
                    <span>￥{(orderDetail.orderAmount/100).toFixed(2)}</span>
                </p>
                <p className='pay-bottom-right' onClick={()=>toPay()}>去支付</p>
            </div>:null}
            <Popup
                visible={visible1}
                // className="popup"
                onMaskClick={() => {
                    setvisible1(false)
                }}
                bodyStyle={{ minHeight: '50vh',maxHeight:'70vh', borderTopRightRadius: "10px", borderTopLeftRadius: "10px", overflow: 'scroll' }}
            >
                <div className="wuliu-top">
                    <h3>详细信息</h3>
                    <img src={icon2} onClick={()=>setvisible1(false)}/>
                </div>
                <div className='kuaidi-danhao'>
                    <p>
                        <span>顺丰快递</span>
                        <span  id='dingdanhao'>1234235413536535</span>
                    </p>
                    <p>
                        <span onClick={() => copyDanhao()}>复制</span>
                        <span className='line'></span>
                        <span>打电话</span>
                    </p>
                </div>
                <div className='wuliu-tips'>
                    <p className='wuliu-tips-left'>
                        <span className='lv'></span>
                        <span className='line'></span>
                        <span className='hui'></span>
                        <span className='line'></span>
                        <span className='hui'></span>
                        <span className='line'></span>
                        <span className='hui'></span>
                        <span className='line'></span>
                        <span className='hui'></span>
                    </p>
                    <p className='wuliu-tips-right'>
                        <p>
                            <span>已发货 09-24 23:23</span>
                            <span>包裹正在等待揽收</span>
                        </p>
                        <p>
                            <span>已发货 09-24 23:23</span>
                            <span>包裹正在等待揽收</span>
                        </p>
                        <p>
                            <span>已发货 09-24 23:23</span>
                            <span>包裹正在等待揽收</span>
                        </p>
                        <p>
                            <span>已发货 09-24 23:23</span>
                            <span>包裹正在等待揽收</span>
                        </p>
                        <p>
                            <span>已下单 09-24 23:23</span>
                            <span>商品已下单</span>
                        </p>
                    </p>
                </div>
                <div className='wuliu-bottom'>
                    <span className='name'>张三</span>
                    <span className='phone'>15828898269</span>
                    <span>四川省 成都市 新都区 大丰街道蓉北路一段68号</span>
                </div>
            </Popup>
        </div>:null)
}