import React, { Component } from 'react'
import "./style.less"
import {ImageViewer, Popup, Rate, TextArea} from "antd-mobile-v5"
import { Toast, ImagePicker } from "antd-mobile"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/yy/touxiang.png"
import icon3 from "../../static/img/yy/good.png"
import { getchat, wzstate,getDocid,addchatnote,getChatnote,allPrespribingids,patientDocdiagnosis,getStatus,patientTuwen,patientStar,lookStar,docInformation,getDanhao,getnewslist } from "../../api/hospitalapi/chat"
import { zipImg } from '../../component/zipImage'
import { uploadpic } from "../../api/hospitalapi/upload"
import { phoneLogin } from "../../api/hospitalapi/login"
import {changeuser, getdoctorlist} from "../../api/hospitalapi/home"
import getUrl from '../../component/GetUrlParam'
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: getUrl('patientPhone', window.location.href) ? '' :JSON.parse(localStorage.getItem("item")),
            visible1: false,
            value: "",
            consultationId: '',
            consultationStu: "",
            detailContent: "",
            detailContent0: localStorage.getItem("detailContent0")?localStorage.getItem("detailContent0"):'',
            newslist: [],
            userpic: localStorage.getItem("patientAvatar")?localStorage.getItem("patientAvatar"):'',
            userId: localStorage.getItem("patientId")?localStorage.getItem("patientId"):'',
            fileList: [],
            listtext: getUrl('patientPhone', window.location.href)?'': JSON.parse(localStorage.getItem("listtext")) ,
            consultationDetail: '',
            timer: 0,
            docterDiagnosis: '',
            star: '',
            commentValue: '',
            stateStar: 0,
            starValue: '',
            memberId: '',
            imgFd: 0,
            index1: -1,
            bottomcode: '',
            item00: '',
            orderNumber: localStorage.getItem("orderNumber")?localStorage.getItem("orderNumber"):'',
            docterId: '',
            details:'',
            chatLength:0,
            consultationFiles: '',
            image: {
                url: '',
                visible: false
            },
            record: '',
            record1: '',
            gzDocter:'',
            consultationReason:'',
            docuserType:'',
            list:[{value:'回复及时',key:1},{value:'非常热心',key:1},{value:'解答很有帮助',key:1},{value:'服务专业',key:1}]
        }
    }

    componentDidMount = async () => {
        const {userId, item, consultationId, docterId, orderNumber} = this.state

        this.scrollToBottom()
        if (getUrl('record', this.props.location.search)) {
            this.setState({
                record:  getUrl('record', this.props.location.search)
            })
        }
        if (getUrl('record1', this.props.location.search)) {
            this.setState({
                record1:  getUrl('record1', this.props.location.search)
            })
        }
        if (getUrl('gzDocter', this.props.location.search)) {
            this.setState({
                gzDocter:  getUrl('gzDocter', this.props.location.search)
            })
        }
        if (getUrl('consultationId', this.props.location.search)){
            this.setState({
                consultationId: getUrl('consultationId', this.props.location.search),
                docterId: getUrl('docterId', this.props.location.search)
            })
        }
        if (getUrl('docuserType', this.props.location.search)){
            this.setState({
                docuserType: getUrl('docuserType', this.props.location.search),
            })
        }
        //零售跳问诊
        if (getUrl('patientPhone', window.location.href)) {
            const patientName = getUrl('patientName', window.location.href)
            const consultationId = getUrl('consultationId', window.location.href)
            const patientPhone1 = getUrl('patientPhone', window.location.href)
            const patientAvatar = getUrl('patientAvatar', window.location.href)
            const patientId = getUrl('patientId', window.location.href)
            const orderNumber = getUrl('orderNumber', window.location.href)
            let docterId
            if (getUrl('docterId', window.location.href)){
                 docterId = getUrl('docterId', window.location.href)
                this.setState({
                    docterId: getUrl('docterId', window.location.href)
                })
            }else{
                let res0=await getDocid({orderNumber})
                if (res0&&res0.data&&res0.data[0]){
                    docterId= res0.data[0][0].docterId
                    this.setState({
                        docterId: res0.data[0][0].docterId
                    })
                }
            }
            const keyWord = decodeURIComponent(patientName)//单个参数解码
            localStorage.setItem("patientPhone", patientPhone1)
            localStorage.setItem("patientAvatar", patientAvatar)
            localStorage.setItem("patientName", keyWord)
            localStorage.setItem("patientId", patientId)
            localStorage.setItem("consultationId", consultationId)
            localStorage.setItem("orderNumber", orderNumber)
            this.setState({
                consultationId: consultationId,
                userpic: patientAvatar,
                userId: patientId,
                // docterId:docterId
            })
            // 手机号登录
            phoneLogin({patientPhone: patientPhone1}).then(res => {
                if (res.code == 1 && !patientName) {
                    changeuser({patientId: patientId, patientName: patientPhone1}).then(res1 => {
                        // console.log(res1);
                    })
                }
                if (res.code == 1) {
                    
                        // 获取医生信息
                        docInformation(docterId).then(res1 => {
                            if (res1&&res1.data&&res1.data[0]) {
                                localStorage.setItem("item", JSON.stringify(res1.data[0][0]))
                                this.setState({
                                    item: JSON.parse(localStorage.getItem("item")),
                                    // item00: res.data[0][0],
                                },()=>{
                                    // console.log(item);
                                })
                            }
                            this.getchatnews()
                            // 患者获取图文信息
                            patientTuwen({
                                userId,
                                docterId: docterId,
                                consultationId: consultationId
                            }).then(res2 => {
                                if (res2.data[0].length) {
                                    //获取聊天内容
                                    getChatnote({
                                        consultationId: consultationId,
                                    }).then(res20 => {
                                        localStorage.setItem("listtext", JSON.stringify(res20.data[1][0]))
                                        this.setState({
                                            // consultationId: res.data[0][0].consultationId,
                                            bottomcode: res2.data[0].length && (res2.data[0][0].consultation_stu == "0" || res2.data[0][0].consultation_stu == "-1") ? true : false,
                                            consultationStu: res2.data[0][0].consultation_stu,
                                            consultationFiles: res20.data[0][0].consultationFiles&&res20.data[0][0].consultationFiles.split(','),
                                            listtext: res20.data[1][0],
                                            consultationDetail: res20.data[0][0].consultationDetail,
                                            memberId: res2.data[0][0].member_id,
                                            details:res2.data[0][0]
                                        })
                                    })
                                }
                            })
                            lookStar({
                                userId,
                                docterId: docterId,
                                consultationId: consultationId
                            }).then(res3 => {
                                if (res3.data[0].length) {
                                    this.setState({
                                        stateStar: 1,
                                        starValue: res3.data[0][0]
                                    })
                                }
                            })
                        })
                        // })
                    // })
                }
            })
            // this.getnewsstatus()

        } else {
            // 获取医生信息
            docInformation(getUrl('docterId', this.props.location.search)).then(res => {
                // localStorage.setItem("item", JSON.stringify(res.data[0][0]))
                if (res&&res.data&&res.data[0]) {
                    this.setState({
                        item: res.data[0][0]
                    })
                }
            })
            this.getchatnews()
            // 患者获取图文信息
            const res = await patientTuwen({
                userId,
                docterId: getUrl('docterId', this.props.location.search) ,
                consultationId: getUrl('consultationId', this.props.location.search)
            })
            if (res&&res.data&&res.data[0].length) {
                //获取聊天内容
                const res2 = await getChatnote({
                    consultationId: getUrl('consultationId', this.props.location.search),
                })

                localStorage.setItem("listtext", JSON.stringify(res2.data[1][0]))
                this.setState({
                    // consultationId: res.data[0][0].consultationId,
                    consultationStu: res.data[0][0].consultation_stu,
                    bottomcode: res.data[0].length && (res.data[0][0].consultation_stu == "0" || res.data[0][0].consultation_stu == "-1") ? true : false,
                    consultationFiles: res2.data[0][0].consultationFiles&&res2.data[0][0].consultationFiles.split(','),
                    listtext: res2.data[1][0],
                    consultationDetail: res2.data[0][0].consultationDetail,
                    memberId: res.data[0][0].member_id,
                    details:res.data[0][0]
                })
            }
            lookStar({
                userId,
                docterId: getUrl('docterId', this.props.location.search),
                consultationId: getUrl('consultationId', this.props.location.search)
            }).then(res => {
                if (res&&res.data&&res.data[0]&&res.data[0].length) {
                    this.setState({
                        stateStar: 1,
                        starValue: res.data[0][0]
                    })
                }
            })
            this.getchatnews()
        }
        const newTimer = window.setTimeout(() => {
            this.scrollToBottom();
            window.clearTimeout(newTimer)
        }, 50);
    }
    // 获取当前里聊天状态
    getnewsstatus = async () => {
        const {docterId, userId, item, orderNumber, consultationId} = this.state
        const res = await getStatus({
            docterId: item.docterId,
            userId,
            consultationId: consultationId
        })
        this.setState({
            bottomcode: res.data[0].length && (res.data[0][0].consultationStu == "0" || res.data[0][0].consultationStu == "-1") ? true : false
        })
    }
    //获取聊天节点设置默认到最底部
    scrollToBottom = () => {
        const scrollHeight = this.container.scrollHeight
        const clientHeight = this.container.clientHeight
        const scrollTop = scrollHeight - clientHeight
        this.container.scrollTop = scrollTop > 0 ? scrollTop : 0
    }
    totalk   = () => {
        const {item, userId, commentValue, star, consultationId, docterId,list} = this.state
        let a=[]
        list.map((i,v)=>{
            if (i.key==2){
                a.push(i.value)
            }
        })
        if (commentValue && star&&parseInt(star)) {
            let params = {
                userId,
                docterId: docterId,
                consultationId: consultationId,
                docEvaluateText: commentValue,
                docEvaluateQuick:a.length?a.toString():'',
                docEvaluateStar: star
            }
            patientStar(params).then(res => {
                if (res.code == 1) {
                    lookStar({
                        userId,
                        docterId: docterId,
                        consultationId: consultationId,
                    }).then(res1 => {
                        if (res1.data[0].length) {
                            this.setState({
                                stateStar: 1,
                                starValue: res1.data[0][0]
                            })
                        }
                    })
                }
            })
            this.setState({
                visible1: false
            })
        } else {
            Toast.info('请填写评论和星级')
        }
    }
    toinfo = (detailContent,createTime) => {
        const {listtext,consultationId, docterId,record,timer} = this.state
        let time=new Date()
        let time1=time.getTime()
        let time2=(new Date(createTime)).getTime()
        if (createTime&&time1-time2<259200000) {
            window.clearInterval(timer)
            localStorage.setItem("detailContent0", detailContent)
            this.props.history.replace(`/doctorchat?consultationId=${consultationId}&docterId=${docterId}&record=${record}`)
            this.props.history.push(`/doctorinfo/chat/chatinfo?detailContent=${detailContent}&consultationId=${consultationId}&docterId=${docterId}`)
            this.props.history.push({
                pathname: "/doctorinfo/chat/chatinfo",
                params: {
                    listtext: listtext,
                    detailContent: detailContent,
                    consultationId:consultationId,
                    docterId:docterId
                    // news:this.props.location.params?.news?this.props.location.params.news:item00
                }
            })
        }else if(createTime&&time1-time2>259200000){
            Toast.info('处方单已失效，无法查看详情')
        }
    }
    goback = () => {
        const {timer, item, record,record1,gzDocter,docuserType} = this.state
        window.clearInterval(timer)
        if (record) {
            this.props.history.push("/mine/record")
        } else if(record1||gzDocter){
            this.props.history.push(`/doctorinfo?record=${record1}&gzDocter=${gzDocter}`)
        }else if(docuserType==2){
            this.props.history.push(`/patientinformation`)
        }else {
            this.props.history.push({
                pathname: "/news",
                params: {
                    item: item,
                }
            })
        }
    }
    //发送信息
    sendout = async () => {
        const {item, consultationId, detailContent, userpic, consultationStu} = this.state
        if (item) {
            if (detailContent) {
                const res = await addchatnote({
                    consultationId: consultationId,
                    Identity: 1,
                    detailType: 1,
                    detailContent,

                })
                this.getchatnews()
                const newTimer = window.setTimeout(() => {
                    this.scrollToBottom();
                    window.clearTimeout(newTimer)
                }, 50);
                this.setState({
                    detailContent: ""
                })
            } else {
                Toast.fail("请先输入内容", 1)
            }

        } else {
            Toast.fail("数据错误", 1)
            this.props.history.push("/")
        }
    }
    // 获取聊天信息
    getchatnews = async () => {
        const {timer, item, userId, consultationId,docterDiagnosis, memberId, docterId, chatLength} = this.state
        window.clearInterval(timer)
        const res = await patientTuwen({
            userId,
            docterId:docterId,
            consultationId: consultationId
        })
        if (res && res.data[1]) {
            if (chatLength==0){
                this.setState({
                    chatLength: res.data[1].length,
                    newslist: res.data[1] ? res.data[1] : [],
                })
            }else if (chatLength==res.data[1].length){
                this.setState({
                    newslist: res.data[1] ? res.data[1] : [],
                })
            }else{
                this.setState({
                    chatLength: res.data[1].length,
                    newslist: res.data[1] ? res.data[1] : [],
                },()=>{
                    if (res.data[1][res.data[1].length-1].Identity=='2') {
                        Toast.info('您有新消息')
                    }
                })
            }
        }

        if (res && res.data[0]&&res.data[0].length) {
            this.setState({
                consultationReason:res.data[0][0].consultation_reason
            })
            // 获取问诊单所有电子处方id	
            let res1 = await allPrespribingids(consultationId)
            let arr = []
            if (res1 && res1.data[0].length) {
                res1.data[0].map(item => {
                    // 患者获取医生诊断详情
                    patientDocdiagnosis({consultationId: item.detail_content}).then(res2 => {
                        arr.push({
                            prescribingId: item.detail_content,
                            docterDiagnosis: res2.data[0][0].docter_diagnosis
                        })
                        if (arr.length>docterDiagnosis.length){
                            this.setState({
                                docterDiagnosis: arr
                            })
                        }

                    })
                })
            }
            // if (res2.data[0].length) {
            //     this.setState({
            //         docterDiagnosis:res2.data[0][0].docter_diagnosis
            //     })
            // }
            this.setState({
                // consultationId: res.data[0][0].consultationId,
                consultationStu: res.data[0][0].consultation_stu,
                // newslist: res.data[1]?res.data[1].reverse():[],
            }, () => {
                if (res.data[0][0].consultation_stu == '-1' || res.data[0][0].consultation_stu == '0') {
                    const newTimer = window.setInterval(() => {
                        this.getchatnews()
                        //   this.scrollToBottom();
                    }, 5000);
                    // localStorage.setItem('timer',newTimer)
                    this.setState({
                        timer: newTimer
                    })
                }
                // this.scrollToBottom()
            })
        } else {
            wzstate({
                docterId: item&&item.docterId ? item.docterId : item.docter_id,
                userId: userId,
                memberId: memberId
            }).then(res => {
                if (res.data[0].length) {
                    this.setState({
                        consultationStu: res.data[0][0].consultationStu,
                    })
                }
            })
        }
    }
    getdetailContent = (e) => {
        this.setState({
            detailContent: e.target.value
        })
    }
    componentWillUnmount = () => {
        const {timer} = this.state
        window.clearInterval(timer)
    }
    //图片上传发送
    imgChange = async (files, type, index) => {
        const {item, consultationId, detailContent, userpic} = this.state
        if (type == "add") {
            let file = files[files.length - 1].file
            if (file.type == "image/png" || file.type == "image/jpeg" || file.type == "image/jpg") {
                if (file.size < 1024 * 1024) {
                    let formData = new FormData()
                    const res = await zipImg(file)
                    formData.append('file', res)
                    const res2 = await uploadpic(formData)

                    if (item) {
                        const res = await addchatnote({
                            consultationId: consultationId,
                            Identity: 1,
                            detailType: 2,
                            detailContent: res2.data
                        })
                        this.getchatnews()
                        // this.scrollToBottom()
                        this.setState({
                            detailContent: ""
                        })
                    } else {
                        Toast.fail("数据错误", 1)
                        this.props.history.push("/")
                    }
                } else {
                    Toast.show('请上传小于1M的图片', 1)
                }
            } else {
                Toast.show('请上传png/jpg格式的图片', 1)
            }
        } else {
            this.setState({
                fileList: []
            })
        }
    }
    starCount = (v) => {
        this.setState({
            star: v
        })
    }
    comment = (v) => {
        this.setState({
            commentValue: v.target.value.replace(/\s*/g,"")
        })
    }
    imgFd = (index) => {
        const {imgFd} = this.state
        if (imgFd == 0) {
            this.setState({
                imgFd: 1,
                index1: index
            })
        } else {
            this.setState({
                imgFd: 0,
                index1: -1
            })
        }
    }
    sxImg = () => {
        const {imgFd} = this.state
        if (imgFd == 1) {
            this.setState({
                imgFd: 0,
                index1: -1
            })
        }
    }
    //再次咨询
    toAgain = () => {
        const {item,consultationId,record} = this.state
        if (item.docterState==1){
            localStorage.setItem("docterId", item.docterId)
            getdoctorlist({docterId: item.docterId}).then(res2 => {
                if (res2.code == 1) {
                    localStorage.setItem("item", JSON.stringify(res2.data[0][0]))
                    this.props.history.replace(`/doctorchat?consultationId=${consultationId}&docterId=${item.docterId}&record=${record}`)
                    this.props.history.push(`/doctorinfo?doctorchat=${1}&consultationId=${consultationId}&docterId=${item.docterId}&record=${record}`)
                }
            })
        }else{
            Toast.info('该医生已下架，无法再次咨询')
        }
    }
    //快捷评价
    addList=(i,v)=>{
        const {list} = this.state
        list.map((m,n)=>{
            if (n==v){
                if (i.key==1){
                    m.key=2
                }else{
                    m.key=1
                }
            }
        })
        this.setState({
            list
        })
    }
    render() {
        const {
            item,
            item00,
            orderNumber,
            visible1,
            imgFd,
            index1,
            image,
            bottomcode,
            consultationFiles,
            docterDiagnosis,
            commentValue,
            docuserType,
            detailContent,
            stateStar,
            consultationDetail,
            newslist,
            userpic,
            fileList,
            listtext,
            list,consultationReason,
            consultationStu,details
        } = this.state
        return (
            <>
                <div className='chatbox' onClick={this.sxImg}>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.goback}/>
                        <h1>
                            {item00 ? <>
                                    <span>{item00.docterName ? item00.docterName :'' }</span><span>{item00 ? item00.title : ''}</span><span>{item00 ? item00.department : ''}</span></> :
                                <>
                                    <span>{item ? item.docterName : ''}</span><span>{item ? item.title : ''}</span><span>{item ? item.department : ''}</span></>}
                        </h1>
                    </div>
                    {consultationStu == '-1' && bottomcode ? <div className='box-tips'>病情已发送,等待医生接诊</div> :
                        consultationStu == '0' ? <div className='box-tips'>病情已发送,医生接诊中</div> :
                            consultationStu == '1' ? <div className='box-tips'>本次问诊已完成</div> :
                            consultationStu == '3' || ((consultationStu == '-1' || consultationStu == '0') && !bottomcode) ?
                                <div className='box-tips'>病情已发送,医生已退诊<span style={{color:'#ff0000',marginLeft:'5px'}}>{consultationReason}</span></div> :
                                item && item.patientNum == '0' ? <div className='box-tips'>病情已发送,医生接诊结束</div> :
                                    null}
                    <div className='box-container' ref={el => this.container = el}>
                        <div className='container-dan'>
                            <img src={userpic ? userpic : icon2} alt=""/>
                            <div className='dan-left'>
                                <div className='left-top'>
                                    <p>图文问诊</p>
                                    {item&&item.order_number?<p></p>:<p>￥{details && (details.consultation_amount / 100).toFixed(2)}</p>}
                                </div>
                                {listtext?<div className='itembox' style={{backgroundColor: "white"}}>
                                    <div className='left-item'>
                                        <p>患者</p>
                                        <p>{listtext.patient_name}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>确诊疾病</p>
                                        <p style={{wordWrap: 'break-word'}}>{details&&details.consultation_detail}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>既往史</p>
                                        <p style={{wordWrap: 'break-word'}}>{listtext.past_history_note}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>过敏史</p>
                                        <p style={{wordWrap: 'break-word'}}>{listtext.allergic_history_note}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>家族遗传史</p>
                                        <p style={{wordWrap: 'break-word'}}>{listtext.family_genetic_history_note}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>肝功能异常</p>
                                        <p>{listtext.abnormal_liver_function == 0 ? '无' : '异常'}</p>
                                    </div>
                                    <div className='left-item'>
                                        <p>肾功能异常</p>
                                        <p>{listtext.abnormal_renal_function == 0 ? '无' : '异常'}</p>
                                    </div>
                                    {consultationFiles ? <div className='left-item'>
                                        <p className='left-item-one'>电子病历</p>
                                        {consultationFiles.map(item=>(<p className='left-item-two'><img src={item} alt="" onClick={() => {
                                            this.setState({
                                                image: {
                                                    url: item,
                                                    visible: true
                                                }
                                            })
                                        }}/></p>))}
                                    </div> : null}
                                    <div className='left-title'>互联网医院将保证你的隐私安全,请放心与医生交流</div>
                                </div>:null}

                            </div>
                        </div>
                        {newslist.length ? newslist.map((item1, index) => {
                            return (
                                <div className={item1.Identity == "1" ? "container-right" : "container-left"}
                                     key={item1.createTime}>
                                    {item1.Identity == "1" ? <img src={userpic != 'null' ? userpic : icon2} alt=""/> :
                                        <img src={item.docterAvatar != 'null' ? item.docterAvatar : icon2} alt=""/>}
                                    {item1.detailType == "1" ?
                                        <div className='left-text' style={{wordWrap: 'break-word'}}>
                                            {item1.detailContent}
                                        </div> : item1.detailType == "2" ? <div className='left-text'>
                                            <img src={item1.detailContent} alt="" onClick={() => this.imgFd(index)}
                                                 className={imgFd == 1 && index == index1 ? 'fd-img' : 'right-img'}/>
                                        </div> : item1.detailType == "3" ? <div className='container-cfd' onClick={(v) => this.toinfo(item1.detailContent)}>
                                            <div className='dan-left'>
                                                <div className='left-top'>
                                                    <p>处方单</p>
                                                </div>
                                                <div className='itembox' style={{backgroundColor: "white"}}>
                                                    <div className='left-item'>
                                                        <p>患者</p>
                                                        <p>
                                                            <span>{listtext.patient_name}</span><span>{listtext.patient_sex}</span><span>{(new Date().getFullYear()) - (new Date(listtext.patient_birthday).getFullYear())}岁</span>
                                                        </p>
                                                    </div>
                                                    <div className='left-item left-item1'>
                                                        <p>诊断</p>
                                                        {docterDiagnosis && docterDiagnosis.map(i => (
                                                            <p>{item1.detailContent == i.prescribingId ? i.docterDiagnosis : ''}</p>
                                                        ))}
                                                        {/* <p>{docterDiagnosis}</p> */}
                                                    </div>
                                                    <div className='left-title'>
                                                        <button onClick={(v) => this.toinfo(item1.detailContent,item1.createTime)}>查看详情
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> : null}
                                </div>
                            )
                        }) : null}
                        {/*{consultationStu=='1'&&stateStar==0?<div className='container-evaluate'>*/}
                        {/*    <div>*/}
                        {/*        <h1>请您对医生的这次服务进行评价</h1>*/}
                        {/*        <button onClick={() => this.setState({*/}
                        {/*            visible1: true*/}
                        {/*        })}>给评价</button>*/}
                        {/*    </div>*/}
                        {/*</div>:null}*/}
                        {/*{consultationStu=='1'&&stateStar==1?<div className='container-evaluate'>*/}
                        {/*    <div>*/}
                        {/*        <h1 style={{marginBottom:'5px'}}>已完成评价</h1>*/}
                        {/*    </div>*/}
                        {/*</div>:null}*/}
                        {consultationStu == '1' ? <div className='container-evaluate1'>
                            <div className='container-top'>
                                <h1>订单明细</h1>
                                {stateStar == 0 ? <p onClick={() => this.setState({
                                    visible1: true
                                })}>
                                    <img src={icon3}/>
                                    <span>去评价</span>
                                </p> : <p>已完成评价</p>}
                            </div>
                            <div className='container-price'>
                                <p>订单总金额</p>
                                <p>￥{details && (details.consultation_amount / 100).toFixed(2)}</p>
                            </div>
                            <div className='container-price'>
                                <p>实际支付</p>
                                <p style={{color: "#FF5656"}}>￥{details && (details.consultation_amount / 100).toFixed(2)}</p>
                            </div>
                            <p className='container-again' onClick={() => this.toAgain()}>再次咨询</p>
                        </div> : null}
                        {/*{consultationStu=='1'&&stateStar==1?<div className='container-evaluate'>*/}
                        {/*    <div>*/}
                        {/*        <h1 style={{marginBottom:'5px'}}>已完成评价</h1>*/}
                        {/*    </div>*/}
                        {/*</div>:null}*/}
                        {/*{consultationStu=='1'?<div>*/}
                        {/*    <div className='chatAgin'>*/}
                        {/*        {item.order_number||orderNumber?null:<button onClick={() => this.props.history.push("/writelist")}>再次咨询</button>}*/}
                        {/*    </div>*/}
                        {/*</div>:null}*/}
                        {consultationStu == '0' ? <div className='box-bottom'>
                            <input type="text" placeholder='病情描述' value={detailContent}
                                   onChange={(e) => this.getdetailContent(e)}/>
                            <ImagePicker
                                files={fileList}
                                length={1}
                                style={{
                                    width: "50px",
                                    height: "50px",
                                    padding: "0",
                                    display: "inline-block",
                                    verticalAlign: "middle"
                                }}
                                onChange={this.imgChange}
                                selectable={fileList.length < 1}
                                accept='image/gif,image/jpeg,image/jpg,image/png'/>
                            <button onClick={this.sendout}>发送</button>
                        </div> : null}
                    </div>

                </div>
                <Popup
                    visible={visible1}
                    className='popup'
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{minHeight: '45vh'}}
                >
                    <div className='popup-box'>
                        <div className='box-top'>
                            <img src={item ? item.docterAvatar : icon2} alt=""/>
                            <div>
                                <h1>评价医生本次服务</h1>
                                <p>
                                    <span>{item ? item.docterName : ''}</span><span>{item ? item.title : ''}</span><span>{item ? item.department : ''}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='pingfen'>
                        <Rate
                            style={{
                                '--active-color': '#1F87FF',
                            }}
                            onChange={val => this.starCount(val.toString())}/>
                    </div>
                    <div className='pop-tips'>
                        {list.map((i,v)=>(
                            <span className={i.key==1?'before':'end'} onClick={()=>this.addList(i,v)}>{i.value}</span>))}
                    </div>
                    <div className='popup-text'>
                        <textarea name="" id="" maxLength={100} cols="28" rows="5" placeholder='谈谈你对本次就医医生的评价吧~(最多100字)'
                                  onChange={val => this.comment(val)}>

                        </textarea>

                    </div>
                    <div className='popup-btn'>
                        <button onClick={this.totalk}>提交评论</button>
                    </div>

                </Popup>
                <ImageViewer
                    image={image.url}
                    visible={image.visible}
                    onClose={() => {
                        this.setState({
                            image: {
                                url: '',
                                visible: false
                            }
                        })
                    }}
                />
            </>

        )
    }
}
