import { axiosInstance } from '../config'

//是否已有问诊信息
export const whethernews = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/is-chatinfo', data)
}

//添加问诊信息
export const addchat = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-chatinfo', data)
}

//获取问诊信息
export const getchat = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-chatinfo', data)
}

//添加聊天内容
export const addchatnote = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/add-chatnote', data)
}
//获取聊天内容
export const getChatnote = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-chatnote', data)
}
// 查询处方药品详情
export const allMedicinal = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/all-medicinal', data)
}
// 查询医生判断详情
export const prescribingDetail = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/prescribing-detail', data)
}
// 患者获取医生诊断详情
export const patientDocdiagnosis = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/patient-docdiagnosis', data)
}
//医生获取信息列表
export const getnewslist = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/list', data)
}
// 患者获取图文信息
export const patientTuwen = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/patient-tuwen', data)
}
//患者获取问诊信息
export const patientWz = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/patient-wz', data)
}
// 用户提交评论 
export const patientStar = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/patient-star', data)
}
// 查看评论
export const lookStar = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/look-star', data)
}
// 获取医生信息
export const docInformation = (docterId) => {
    return axiosInstance.get(`/api/hosp/wzdocter/information?docterId=${docterId}`)
}
// 获取问诊单号
export const getDanhao = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-danhao', data)
}
// 查看已开处方
export const prescribed = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/prescribed', data)
}
// 用户查看通过的处方单
export const passPrescribing = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/pass-prescribing', data)
}
// 查询问诊状态
export const wzstate = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/wzstate', data)
}
// 通过订单号查询医生id	
export const getDocid = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-docid', data)
}
// 患者删除问诊消息	
export const userdelWznews = (consultationId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/userdel-wznews?consultationId=${consultationId}`)
}
// 获取问诊单所有电子处方id	
export const allPrespribingids = (consultationId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/all-prespribingid?consultationId=${consultationId}`)
}
// 获取订单号	
export const getOrdernumber = (consultationId) => {
    return axiosInstance.get(`/api/hosp/wzpatient/get-ordernumber?consultationId=${consultationId}`)
}
// 患者删除问诊信息	
export const userDelwz = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/user-delwz', data)
}
//更新问诊状态
export const updatestu = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/updatestu', data)
}
//微信退款
export const refundApply = (data) => {
    return axiosInstance.post('/faas/hosp/register-record/refund-apply', data)
}
//查询问诊id
export const getId = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/get-id', data)
}
//获取当前问诊单状态
export const getStatus = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/get-status', data)
}
//通过单号查询问诊信息
export const orderGetnews = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/order-getnews', data)
}
//通过问诊单号查询订单信息
export const prescribingOrder = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/prescribing_order', data)
}
//查询问诊单号
export const getPrescribingId = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/get_prescribing_id', data)
}
//跳转视频问诊
export const callWenzhen = (data) => {
    return axiosInstance.post('/faas/hosp/Consultation/call-wenzhen', data)
}
//视频问诊
export const phoneTowenzheb = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/phone_towenzheb', data)
}
//获取ca签名
export const caWrite = (data) => {
    return axiosInstance.post('/api/hosp/Consultation/ca-write', data)
}
//查询可以挂号的科室
export const allGhDeperdment = (consultationId) => {
    return axiosInstance.get(`/api/hosp/register-record/all-gh-deperdment`)
}
//修改问诊状态
export const newState = (data) => {
    return axiosInstance.post('/api/hosp/offline-pharmacy/new-state', data)
}


// 查询是否中药问诊单isChineseMeds
export const isChineseMeds = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/is-chinesemeds-consu', data)
}

//查询用法用量chineseMethod
export const chineseMethod = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method', data)
}



// 查询审核通过处方所属店铺belongStore
export const belongStore = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/belong-store`,data)
}

// 插入店员id和店铺药师id
export const updateEprescrib = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/update-epresid`,data)
}

// 查询店铺对应的店员
export const selectPharmaIdStaff = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/select-pharmaid-staff`,data)
}

// 查询店铺对应的药店药师
export const selectPharmaIdPha = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/select-pharmaid-pha`,data)
}

// 查询问诊单详情
export const prescribingDetails = (data) => {
    return axiosInstance.post('/api/hosp/wzpatient/prescribing-details', data)
}

//查询用法用量
export const chineseMethodDoc = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/chinese-method-doc', data)
}


// 处方单查询药店药师签名
export const selectPrescPhaSign = (data) => {
    return axiosInstance.post(`/api/hosp/wzpharmacist/select_presc_pha_sign`,data)
}

// 查询问诊单的店铺名和患者名
export const conPharmacyPatient = (data) => {
    return axiosInstance.post(`/api/hosp/wzpatient/con-pharmacy-patient`,data)
}

// 查询医生对话模板
export const dialogueTemplate = (data) => {
    return axiosInstance.post('/api/hosp/wzdocter/select-dialogue-template', data)
}