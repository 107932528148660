import React, { Component } from 'react'
import {List, InputItem, Switch, Button, Toast, Modal} from 'antd-mobile'

import Head from '../../../../component/Head'
import ChooseButon from '../../../../baseui/choosebutton/index'
import './style.less'
import selectaddress from '../../../../static/img/selectaddress.png'
import selectyou from '../../../../static/img/selectyou.png'
import BaiduMap from '../../../../application/Map/BDMap'
import BDMapS from '../../../../application/Map/BDMapS'
import GDmaps from '../../../../application/Map/MapContainer'
import { addadressRequest,addressLists, addressDetailsRequest, updateAdressRequest, delAdressRequest, getProvince, getCity, gitDistrict } from '../../../../api/hospitalapi/address'
import {delPatientMember} from "../../../../api/hospitalapi/user";
const { alert } = Modal
export default class AdressEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // 接收类型，动态展示更新和添加地址模块
            type: this.props.match.params.type,
            addressId: this.props.match.params.addressId,
            patientId: localStorage.getItem('patientId'),
            user: '',
            tel: '',
            province: '',
            city: '',
            district: '',
            business: "",
            adress: '',
            addressDefault: false,
            longitude: '',
            latitude: '',
            city1: "",
            district1: "",
            business1: "",
            provinceArr: [],
            cityArr: [],
            districtArr: [],
            count:1,
            visiable:'none'
        }
    }

    componentDidMount() {
        if (this.state.type === 'refrash') {
            this.getAddressDetails()
        }
        this.getprovinceArr()
        addressLists(this.state.patientId).then(res => {
            // console.log(res);
            if(res.code === 1 && res.data[0].length == 0) {
                this.setState({
                    count:0
                })
            }
        }) 
    }
    // 获取用户默认地址
    getAddressDetails = () => {
        addressDetailsRequest(this.state.addressId).then(res => {
            // console.log(res);
            if (res.code === 1) {
                const data = res.data[0][0]
                this.setState({
                    user: data.addressee,
                    tel: data.phone,
                    province: data.province,
                    city: data.city,
                    district: data.district,
                    adress: data.address,
                    addressDefault: data.addressDefault === '1'
                })
            }
        })
    }
    //获取所有省份
    getprovinceArr = async () => {
        const res = await getProvince()
        // console.log(res)
        this.setState({
            provinceArr: res.data[0]
        })
    }
    //获取对应市
    getCity=async(code)=>{
        const res=await getCity({
            parentId:code
        })
       this.setState({
        cityArr:res.data[0]
       })
    }
    //获取对应区
    getDistrict=async(code)=>{
        const res=await gitDistrict({
            parentId:code
        })
       this.setState({
        districtArr:res.data[0]
       })
    }

    handleChange = (key, val) => {
        this.setState({
            [key]: val
        })
    }
    // 提交用户信息
    saveAddress = () => {
        let params = {
            patientId: this.state.patientId,
            addressDefault: this.state.addressDefault ? '1' : '0',
            province: this.state.province,
            city: this.state.city,
            district: this.state.district,
            address: this.state.business + this.state.adress,
            addressee: this.state.user,
            phone: this.state.tel,
            longitude: this.state.longitude,
            latitude: this.state.latitude,
            // business:this.state.business
        }
       
        // console.log(params);
        for (let item in params) {
            if (params[item] === ''&&item!="province") {
                Toast.fail("请将信息填写完整")
                return
            }
        }
        let reg = /^1[3456789]\d{9}$/;
        if (!reg.test(this.state.tel)) {
            Toast.fail('电话号码格式不正确')
        }else{
            //发请求存用户地址信息
            addadressRequest(params).then(res => {
                // console.log(res);
                if (res.code === 1) {
                    localStorage.address = params.province + params.city + params.district + params.address
                    this.props.history.go(-1)
                }
            })
        }
    }
    //修改更新地址
    updateAddre = () => {
        const params = {
            addressId: this.state.addressId,
            addressDefault: this.state.addressDefault ? '1' : '0',
            province: this.state.province,
            city: this.state.city,
            district: this.state.district,
            address: this.state.adress,
            addressee: this.state.user,
            phone: this.state.tel,
            longitude: this.state.longitude,
            latitude: this.state.latitude,
        }
        const params1 = {
            address: this.state.business1+this.state.adress,
            addressee: this.state.user,
            phone: this.state.tel,
        }
        // console.log(params)
        for (let item in params1) {
            if (params[item] === '') {
                Toast.fail("请将信息填写完整")
                return
            }
        }
        let reg =  /^1[3456789]\d{9}$/;;
        if (!reg.test(params.phone)) {
            Toast.fail('电话号码格式不正确')
        }else {
            // console.log(params)
            updateAdressRequest(params).then(res => {
                if (res.code === 1) {
                    this.props.history.go(-1)
                }
            })
        }
    }
    // 删除地址
    delAddre = () => {
        alert('确认删除该收货地址吗?','删除地址', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '确定', onPress: () => {
                delAdressRequest(this.state.addressId).then(res => {
                    if (res.code === 1) {
                        this.props.history.go(-1)
                    }
                })
            }},
        ],)

    }

    // 获取经纬度
    getCode = (longitude, latitude,province, city1, district1, business1) => {
        // console.log(city1, district1, business1);
        this.setState({
            longitude,
            latitude,
            province,
            city1,
            district1,
            business1
        }, () => {
            // console.log(this.state.city);
            this.setState({
                city: this.state.city1,
                district: this.state.district1,
                business: this.state.business1,

            })
        })
    }
    //获取用户选择得地址
    changeone = async (code, e) => {
        //   console.log(e.target.value)
        switch (code) {
            case 1:
                const text=e.target.value.substring(6)
                const num=e.target.value.substring(0,6)
                this.getCity(num)
                this.setState({
                    province:text
                })
                break;
            case 2:
                const text2=e.target.value.substring(6)
                const num2=e.target.value.substring(0,6)
                this.getDistrict(num2)
                this.setState({
                    city: text2
                })
                break;
            case 3:
                const text3=e.target.value.substring(6)
                this.setState({
                    district: text3
                })
                break;
        }
    }
    //展示下一个子地区
    shownext=async(code)=>{
        // console.log(444444)
    }
    render() {
        const { type, user, tel, adress, addressDefault, province, city, district, business } = this.state

        return (
            <div>
                {type === 'refrash' ?
                    <div style={{backgroundColor:'#fff'}}>
                        <Head title='更新地址' />
                        <GDmaps width='100%' height='300px' getCode={this.getCode}></GDmaps>
                        <List className='adressinput' style={{ boxSizing: "border-box" ,paddingTop:"300px"}}>
                            <InputItem value={province + city + district + business}

                            >地址</InputItem>
                            <InputItem value={user}
                                onChange={v => this.handleChange('user', v)}
                            >收货人</InputItem>
                            <InputItem value={tel}
                                onChange={v => this.handleChange('tel', v)}
                            >手机号码</InputItem>
                            <InputItem value={adress}
                                onChange={v => this.handleChange('adress', v)}
                            >门牌号</InputItem>
                            <div className='xian'></div>
                        </List>
                        <div className='addr-default' style={{ marginTop: "50px" }}>
                            <div className='addr-tip'>
                                <div>设置默认地址</div>
                                <div>提醒：每次下单会默认使用该地址</div>
                            </div>
                            <div>
                                <Switch color='#1F87FF'
                                    checked={addressDefault}
                                    onChange={() => {
                                        this.setState({
                                            addressDefault: !addressDefault,
                                        })
                                    }} />
                            </div>
                        </div>
                        <div className='btn'>
                            <div onClick={() => this.delAddre()}>删除</div>
                            <div onClick={() => this.updateAddre()}>保存</div>
                        </div>
                    </div> : null
                }
                {type === 'add' ?
                    <div>
                        <Head title='添加地址' />
                        <GDmaps width='100%' height='300px' getCode={this.getCode}></GDmaps>

                        <List className='adressinput' style={{ boxSizing: "border-box" ,paddingTop:"300px"}}>
                            <div className='select-address'>
                                <img src={selectaddress} alt="" />
                                <p className='shouhuo-address'>
                                    <span>{city?province + city + district + business:"请输入地址搜索并选择合适地址"}</span>
                                </p>
                            </div>
                            <InputItem placeholder='详细地址，例1层101室'
                                onChange={v => this.handleChange('adress', v)}
                            >门牌号</InputItem>
                            <InputItem
                                placeholder='请填写收货人的姓名'
                                onChange={v => this.handleChange('user', v)}
                            >联系人</InputItem>
                            <InputItem
                                placeholder='请填写收货手机号码'
                                onChange={v => this.handleChange('tel', v)}
                            >手机号</InputItem>
                            <div className='moren'>
                                <div className='addr-tip'>
                                    <div>设置默认地址</div>
                                    <div>提醒：每次下单会默认使用该地址</div>
                                </div>
                                <div>
                                    <Switch color='#1F87FF'
                                        checked={addressDefault}
                                        onChange={() => {
                                            this.setState({
                                                addressDefault: !addressDefault,
                                            })
                                        }} />
                                </div>
                            </div>
                        </List>
                        <div onClick={() => this.saveAddress()} className="cun">保存地址</div>
                    </div> : null
                }
            </div>
        )
    }

}