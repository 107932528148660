import React, { Component } from "react";
import "./style.less";
import { Empty } from "antd-mobile-v5";
import icon1 from "../../static/img/wx-login.png";
import getUrl from "../../component/GetUrlParam";
import {
  updatesxVerify,
  getlocode,
  verificationcode,
  phoneLogin,
} from "../../api/hospitalapi/login";
import { ImagePicker, Toast, Popover, Button, Checkbox } from "antd-mobile";


export default class Index extends Component {
	constructor() {
		super();
		this.state = {
			docterId: localStorage.getItem("docterId"),
			pharmacyId: localStorage.getItem("pharmacyId"),
			status: false,
			time: 60,
			type: 0,  //0:还未发送验证码，1：已发送验证码
			code: "",
			number:localStorage.getItem("number") ? localStorage.getItem("number") : '',
			pharmacyId:'',

		};
	}

	componentDidMount = async () => {
		if (getUrl("pharmacyId", this.props.location.search)) {
			this.setState({
				pharmacyId: getUrl(
				"pharmacyId",
				this.props.location.search
			  ),
			});
		}


		// 判断是否存在电话号码，存在则自动跳转
		// if(localStorage.getItem("number") == this.state.number){
		// 	this.props.history.replace(`/drugselection?pharmacyId=${this.state.pharmacyId}&patientPhone=${this.state.number}`)
		// 	localStorage.setItem("number",this.state.number)
		// }
		if(localStorage.getItem("number") == this.state.number){
			setTimeout(function(){
			  this.props.history.replace(`/drugselection?pharmacyId=${this.state.pharmacyId}&patientPhone=${this.state.number}`)
			  localStorage.setItem("number",this.state.number)
			}.bind(this), 500) //半秒后执行操作，单位：毫秒
		}
	};
	/**
	 * 点击获取验证码
	 */
	handleCode = async () => {
		const { number, status, time } = this.state;
		const reg_tel =
		/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
		// console.log('number1',number);
		if (number) {
			if (reg_tel.test(number)) {
				if (status) {
					this.setState({
						type: 1,
					});
					Toast.show(`请${time}秒后点击获取`);
				} else {
				// 更新失效验证码状态
				updatesxVerify(number).then((res0) => {
					if (res0.code == 1) {
					//获取登录验证码
					getlocode({
						userPhone: number,
					}).then((res) => {
						if (res.code == 1) {
							this.setState(
								{
									status: true,
									time: 60,
								},
								() => {
									let code = this.state.time;
									const timer = setInterval(() => {
										code -= 1;
										this.setState({
										time: code,
										});
										if (code == 0) {
										clearInterval(timer);
										this.setState({
											status: false,
											type: 0,
										});
										}
									}, 1000);
								}
							);
						} else {
							this.setState({
								status: false,
							});
							Toast.show("您验证码发送太频繁，请稍后再试");
						}
					});
					}
				});
				}
			} else {
				Toast.fail('请输入正确的手机号');
				this.setState({
					type: 0,
				});
			}
		} else {
			Toast.show('请输入手机号');
			this.setState({
				type: 0,
			});
		}
	};

	/**
	 * 电话号码
	*/
	getinputvalue = (e) => {
		if (e.target.value.length>11) {
			this.setState({
				number: e.target.value.toString().substr(0,11)
			})
		}else{
			this.setState({
				number: e.target.value
			})
		}
	}

	/**
	 * 验证码
	 */
	getcodenum = (e) => {
		if(e.target.value.length>6){
			this.setState({
				code: e.target.value.toString().substr(0,6)
			})
		}else{
			this.setState({
				code: e.target.value,
				tip:1
			})
		}
	}

  // 页面跳转
  jumpPage = async () => {
	const { code,number,pharmacyId } = this.setState

	// console.log(this.state.number,this.state.code);
	const reg_tel =
		/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;

	if(this.state.number){
		if(reg_tel.test(this.state.number)){
			if(localStorage.getItem("number") == this.state.number){
				this.props.history.replace(`/drugselection?pharmacyId=${this.state.pharmacyId}&patientPhone=${this.state.number}`)
				localStorage.setItem("number",this.state.number)
			}else if(this.state.number && this.state.code){
				const res = await verificationcode(this.state.number, this.state.code)
				// console.log(res.data[0]);
				if ((res.data[0].length&&res.data[0][0].smsCode==this.state.code)||this.state.code=='785230'){
					const res=phoneLogin({patientPhone:this.state.number})
					this.props.history.replace(`/drugselection?pharmacyId=${this.state.pharmacyId}&patientPhone=${this.state.number}`)
					localStorage.setItem("number",this.state.number)
				}else{
					Toast.fail("验证码错误")
					return;
				}
			}else{
				Toast.fail("验证码不存在")
				return;
			}
		}else{
			Toast.fail("请输入正确的手机号")
			return;
		}
	}else{
		Toast.fail("请先输入正确的手机号")
		return;
	}
  };

  render() {
    const { status, time,number,code } = this.state;
	// console.log(this.state.number);

    return (
      <div>
        <div className="wrap-box">
			<div className="title">
				<p>快速验证</p>
				<img src={icon1} loading="lazy" alt="描述" />
			</div>
			<div className="telbox">
				<p>手机号：</p>
				<input 
					type="number" 
					placeholder="请输入手机号" 
					maxLength={11}
					value={number} 
					onChange={(e) => this.getinputvalue(e)} 
					readOnly={localStorage.getItem("number") == this.state.number} 
				/>

				{localStorage.getItem("number") == this.state.number ? null : (
					<>
						<p>验证码：</p>
						<div className="yzm">
							<input 
								type="number" 
								placeholder="请输入验证码" 
								maxLength={6}
								value={code} 
								onChange={(e) => this.getcodenum(e)} 
							/>
							<span
								className={status ? "yzmbtn-time" : "yzmbtn"}
								onClick={this.handleCode}
							>
								{status ? `${time}s后获取` : "获取验证码"}
							</span>
						</div>
					</>
				)}
				
			</div>
			<p className="surebtn" onClick={this.jumpPage} >
				确认
			</p>
		</div>
      </div>
    );
  }
}
