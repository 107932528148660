import React, { Component } from 'react'
import "./style.less"
import {Popup,Rate, Tabs,Toast } from "antd-mobile-v5"
import { getnewslist,userdelWznews,userDelwz,patientStar,lookStar } from "../../../api/hospitalapi/chat"
import { getuserinfo, changeuserinfo ,delPatientMember} from "../../../api/hospitalapi/user"
import { getdoctorlist } from "../../../api/hospitalapi/home"
import { Modal } from 'antd-mobile';
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/write.png"
import icon3 from "../../../static/img/delete.png"
import Nodata from "../../../component/Nodata";

const { alert } = Modal
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            visible1: false,
            patientId: localStorage.getItem("patientId"),
            item: JSON.parse(localStorage.getItem("item")),
            userlist: [],
            nowuser: null,
            date: null,
            contenttext: "",
        }
    }
    componentDidMount = async () => {
        const { patientId } = this.state
        if (!patientId) {
          Toast.show({
              content:"当前未登录，请先登录",
              duration:1000
          })
          this.props.history.push("/login")
        }else{
            this.getuserlist()
        }
    }
    //获取就诊人信息列表
    getuserlist = async () => {
        const myDate = new Date()
        const { patientId } = this.state
        // 获取就诊人信息列表
        const res = await getuserinfo({
            patientId
        })
   
        let datalist = res.data[0]
        let nowuser = null
        datalist.forEach((item) => {
            if (item.patient_default != 0) {
                nowuser = item
            }
        })
    
        this.setState({
            userlist: res.data[0],
            date: myDate.getFullYear(),
            nowuser
        })
    }
    
    gobank=()=>{
        this.props.history.push("/mine")
    }
    // 删除
    delOrder=(memberId)=>{
   
        alert('确认删除此条用药人信息吗?','删除用药人信息', [
            { text: '取消', onPress: () => console.log('cancel'), style: 'default' },
            { text: '确定', onPress: () => {
                delPatientMember({memberId}).then(res=>{
        
                    if (res.code==1) {
                        this.getuserlist()
                    }
                })
            } },
        ],)
    }
    // 编辑
    toWrite=(e,item)=>{ 
        e.preventDefault()
        e.stopPropagation()
        this.props.history.replace(`/mine/patientManagement`)
        this.props.history.push(`/patient?memberId=${item.member_id}`)
        // this.props.history.push({
        //     pathname: "/patient",
        //     params: {
        //         list0: item,
        //         patientManagement:1
        //     }
        // })
    }
    render() {
        const { userlist }=this.state
        return (
            <div className='recordbox1'>
                <div className='box-top1'>
                    <img src={icon1} alt="" onClick={this.gobank} /><h1>就诊人管理</h1>
                </div>
                <div className='box-content1'>
                    {userlist.length?userlist.map(item=>(
                        <div className='patient-outer'>
                            <p className='patient-outer-left'>
                                <p className='name'>{item.patient_name}</p>
                                <p>
                                    <span>{item.patient_sex} </span>
                                    {/*<span>{item.age}岁 </span>*/}
                                    <span>{item.patient_phone}</span>
                                </p>
                            </p>
                            <p className='patient-outer-right'>
                                <img src={icon2} onClick={(e) => this.toWrite(e,item)} alt="" />
                                <img src={icon3} onClick={() => this.delOrder(item.member_id)} />
                            </p>
                        </div>
                    )):<Nodata type='就诊人'/>}
                </div>
                <div className='xinzeng' onClick={() => {
                    this.props.history.push({
                        pathname: "/patient",
                        params: {
                            patientManagement0:1
                        }})
                }}>新增就诊人</div>
            </div>
        )
    }
}
