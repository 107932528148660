import React, { Component } from 'react'
import "./style.less"
import {Form, Input, DatePicker, CascadePicker,Popover } from "antd-mobile-v5"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/icon/false1.png"
import { Toast,Modal,  } from 'antd-mobile'
import {selectDoctor, updateDoctor,selectType,selectAllDoc,selectSeriousmeds,disDepartment} from '../../api/hospitalapi/offline'
import getUrl from '../../component/GetUrlParam'
import touxiang from "../../static/img/home/touxiang.png";
import del from "../../static/img/home/del.png";
import update from "../../static/img/home/update.png";
import icon from "../../static/img/yy/suiji-doc.png";
import Nodata from "../../component/Nodata";
import icon3 from "../../static/img/contact-complaint.png";
import fhhome from "../../static/img/icon/fhhome.png";
const alert = Modal.alert
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            selectmeds: localStorage.getItem("selectmeds") && localStorage.getItem("selectmeds") != 'undefined' ? JSON.parse(localStorage.getItem("selectmeds")) : [],
            userlist: [],
            pregnancyStatus:1,//    怀孕情况 1未怀孕 2 备孕中 3已怀孕 4哺乳期
            coordinationcode:2,
            entitycode:2,
            allDoctors:[],
            visible5:false,
            awaitcode:'',
            consultationId:'',
            allDoc:[],
            chamedarr:
                localStorage.getItem("chamedarr") &&
                localStorage.getItem("chamedarr") != "undefined"
                ? JSON.parse(localStorage.getItem("chamedarr"))
                : [],
            zhongyaomeds:2,//表示中药材
            medType: 1,//1西药  2中药
            limitArr:[],
            list: localStorage.getItem("list") && localStorage.getItem("list") != 'undefined' ? JSON.parse(localStorage.getItem("list")) : [],
            array:[],
            patientage:'',
        }
    }

    componentDidMount = async () => {
        const {patientId,province,city,county,selectmeds,list} = this.state
        if (getUrl("medType", this.props.location.search)){
            this.setState({
              medType: getUrl(
                "medType",
                this.props.location.search
              ),
            });
        }
        if (getUrl('coordinationcode', this.props.location.search)) {
            this.setState({
                coordinationcode:getUrl('coordinationcode', this.props.location.search)
            })
        }
        if (getUrl('entitycode', this.props.location.search)) {
            this.setState({
                entitycode:getUrl('entitycode', this.props.location.search)
            })
        }
        if (getUrl('awaitcode', this.props.location.search)) {
            this.setState({
                awaitcode:getUrl('awaitcode', this.props.location.search),
                consultationId:getUrl('consultationId', this.props.location.search),
            })
        }

        if (getUrl('patientage', this.props.location.search)) {
            this.setState({
                patientage:getUrl('patientage', this.props.location.search)
            })
        }

        this.toselectDoctor()

    }
    toselectDoctor = async () =>{
        const {selectmeds,consultationId,medType,list,patientage} = this.state;

        // 查询所有医生
        let res03 = await selectAllDoc ();
        let allDoc = [];
        if (res03.data && res03.data.length > 0) {
            allDoc = res03.data[0];
            this.setState({ allDoc });
        }
        if (res03&&res03.data&&res03.data[0]&&res03.data[0].length){
            let workingSeniority=1
            res03.data[0].map(item=>{
                item.workingSeniority=1
                if (item.certificateTime){
                    // 将出生日期的字符串通过"-"分割成数组
                    const strBirthdayArr = item.certificateTime.split("-")
                    // 拿到出生日期的年
                    const birthYear = strBirthdayArr[0]
                    // 拿到出生日期的月
                    const birthMonth = strBirthdayArr[1]
                    // 拿到出生日期的日
                    const birthDay = strBirthdayArr[2]
                    // 创建一个时间对象
                    const d = new Date()
                    // 拿到当前时间的年
                    const nowYear = d.getFullYear()
                    // 拿到当前时间的月
                    const nowMonth = d.getMonth() + 1
                    // 拿到当前时间的日
                    const nowDay = d.getDate()
                    // 如果出生日期的年等于当前时间的年
                    if (nowYear === birthYear) {
                        workingSeniority=1
                        item.workingSeniority=workingSeniority
                        return 1 // 返回周岁年龄 0,并终止函数执行
                    }
                    // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
                    const ageDiff = nowYear - birthYear; // 年之差
                    // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
                    // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
                    // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
                    if (nowMonth != birthMonth) {
                        // 拿到出生日期的日与当前时间的月之差
                        const monthDiff = nowMonth - birthMonth; // 月之差
                        // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
                        workingSeniority= monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                        item.workingSeniority=workingSeniority
                        return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    }
                    // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
                    const dayDiff = nowDay - birthDay;
                    // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
                    workingSeniority= dayDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    item.workingSeniority=workingSeniority
                    return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
                }

            })
        }
        this.setState({
            allDoc:res03.data[0]
        })

        // 查询所有限制级药品
        let limitArr = [];
        for(const item of selectmeds){
            let med_name1 = item.medComName;
            let med_name2 =item.medName;
            let res05 = await selectSeriousmeds({
                med_name1:med_name1,
                med_name2:med_name2,
            });
            if(res05.data[0].length){
                limitArr.push(res05.data[0][0])
            }
            this.setState({
                limitArr:limitArr
            },()=>{
                localStorage.setItem("limitArr", JSON.stringify(limitArr))
            }
            )
        }
        // console.log(limitArr,'限制级药品========');


        let code1 = 2
        let code2 = 2
        let code3 = 2
        let code4 = 2
        let code5 = 2

        //根据疾病显示科室
        let code6 = 2  //中医科
        let code7 = 2  //内科
        let code8 = 2  //儿童用药 classifyId==9 
        let obj = [];
        selectmeds.map((item)=>{
            obj.push(item.classifyId)
        })
        if(obj.length>0){
            this.setState({
                array:obj
            },()=>{
                localStorage.setItem('obj', obj);
            })
        }



        // 儿童用药判断
        const filteredItems = selectmeds.filter(item => item.classifyId !== 9);
        // console.log(filteredItems,'182========');
        // console.log(filteredItems.length,'181-----------------------');
        if((filteredItems.length>0 && selectmeds.some(item => item.classifyId == 9)) ){
            // 获取筛选后的数据在 selectmeds 中的索引数组
            const indexArray = filteredItems.map(item => selectmeds.indexOf(item));
            const listEr = list.filter(item => indexArray.includes(item.index0));
            // console.log(listEr,'188=====');
            // 查询疾病对应的科室
            let disArrEr = [];
            listEr.map((i)=>{
                if(i.index == 2){
                    disArrEr.push(i);
                }
            })
            // console.log(disArrEr,'196======');
            let disDepartArrEr = [];
            for (let i = 0; i < disArrEr.length; i++) {
                let dis_id = disArrEr[i].dis_id;
                let res09 = await disDepartment({dis_id});
                disDepartArrEr.push(res09.data[0]);
            }    
            // console.log(disDepartArrEr,'203=============');
            let resArrEr = [];
            for (let i = 0; i < disDepartArrEr.length; i++) {
                const filteredItems = disDepartArrEr[i].filter(item => item.dep_name === '内科');
                resArrEr = resArrEr.concat(filteredItems);  
            }
            // console.log(resArrEr,'208==========');

            if(resArrEr.length < 1){
                code6 = 1;
            } 

        }else if(filteredItems.length == 0 && selectmeds.some(item => item.classifyId == 9)){
            code6=1;
        }else{
            // 普通用药
            // 查询疾病对应的科室 
            let disArr = [];
            list.map((i)=>{
                if(i.index == 2){
                    disArr.push(i);
                }
            }) 
            let disDepartArr = [];
            for (let i = 0; i < disArr.length; i++) {
                let dis_id = disArr[i].dis_id;
                let res04 = await disDepartment({dis_id});
                disDepartArr.push(res04.data[0]);
            }
            let resArr = [];
            for (let i = 0; i < disDepartArr.length; i++) {
                let innerArr = disDepartArr[i];  
                for (let j = 0; j < innerArr.length; j++) {
                let item = innerArr[j];
                if(item.dep_name === '外科'){
                    item.dep_name = '中医科'
                }
                resArr.push(item);
                }
            }
            let countClassify = 0;
            let countInternal = 0;
            for (let i = 0; i < resArr.length; i++) {
                let item = resArr[i];
                if (item.dep_name === '内科') {
                    countInternal++;
                }
                if (item.dep_name === '中医科') {
                    countClassify++;
                }
            }
            if(selectmeds.length == 1){
                if(countInternal == countClassify){
                    code6 = 1;
                    code7 = 1;
                }
            }
            if(countInternal < countClassify){
                code6 = 1;
            }
            if(countClassify < countInternal){
                code7 = 1;
            }
            if(countClassify >= 2 && countInternal >=2 && countInternal == countClassify){
                code6 = 1;
                code7 = 1;
            }

        }

        if(getUrl('medType', this.props.location.search) == '2'){
            code6 = 1
        }
        selectmeds.forEach(item => {
            if (item.classifyId == 56 ||
                item.classifyId ==6 ||
                item.classifyId ==8 ||
                item.classifyId ==9 ||
                item.classifyId ==10 ||
                item.classifyId ==105 ||
                item.classifyId == 3 ||
                item.classifyId == 108) {
                code1 = 1
            }
            if (item.classifyId == 114) {//注射药品
                code2 = 1
            }
            if (item.classifyId == 11 || item.classifyId == 116) {//内科
                code3 = 1
            }
            if(item.classifyId == 5 || item.classifyId == 7 ||item.classifyId == 12){ //男性用药、泌尿系统  中医科
                code4 = 1
            }
        })
        let res;
        if (getUrl('consultationId', this.props.location.search)){
            let res0=await selectType({consultationId:getUrl('consultationId', this.props.location.search)})
            if (res0&&res0.data&&res0.data[0]&&res0.data[0].length){
                res=await selectDoctor({
                    isClassifyId: res0.data[0][0].is_classify_id,
                    isInternal:res0.data[0][0].is_internal,
                    // isSurgery:res0.data[0][0].is_surgery,
                    videocode:localStorage.getItem('videocode'),
                    array:localStorage.getItem('obj'),
                    patientage:getUrl('patientage', this.props.location.search) ? getUrl('patientage', this.props.location.search) : '',
                })
            }

        }else{
            // isClassifyId中医科    isInternal内科  isSurgery  外科
            if(code6 == 1 && code7 != 1){
                res = await selectDoctor({
                    isClassifyId: 1,
                    isInternal: 2,
                    videocode:localStorage.getItem('videocode'),
                    array:obj,
                    patientage:getUrl('patientage', this.props.location.search) ? getUrl('patientage', this.props.location.search) : '',
                })
            }else if(code7 == 1 && code6 != 1){
                res = await selectDoctor({
                    isClassifyId: 2,
                    isInternal: 1,
                    videocode:localStorage.getItem('videocode'),
                    array:obj,
                    patientage:getUrl('patientage', this.props.location.search) ? getUrl('patientage', this.props.location.search) : '',
                })
            }else if(code6 ==1 && code7 == 1){
                res = await selectDoctor({
                    isClassifyId: 1,
                    isInternal: 1,
                    videocode:localStorage.getItem('videocode'),
                    array:obj,
                    patientage:getUrl('patientage', this.props.location.search) ? getUrl('patientage', this.props.location.search) : '',
                })
            } 
        }
        if (res&&res.data&&res.data[0]&&res.data[0].length){
            let workingSeniority=1
            res.data[0].map(item=>{
                item.workingSeniority=1
                if (item.certificateTime){
                    // 将出生日期的字符串通过"-"分割成数组
                    const strBirthdayArr = item.certificateTime.split("-")
                    // 拿到出生日期的年
                    const birthYear = strBirthdayArr[0]
                    // 拿到出生日期的月
                    const birthMonth = strBirthdayArr[1]
                    // 拿到出生日期的日
                    const birthDay = strBirthdayArr[2]
                    // 创建一个时间对象
                    const d = new Date()
                    // 拿到当前时间的年
                    const nowYear = d.getFullYear()
                    // 拿到当前时间的月
                    const nowMonth = d.getMonth() + 1
                    // 拿到当前时间的日
                    const nowDay = d.getDate()
                    // 如果出生日期的年等于当前时间的年
                    if (nowYear === birthYear) {
                        workingSeniority=1
                        item.workingSeniority=workingSeniority
                        return 1 // 返回周岁年龄 0,并终止函数执行
                    }
                    // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
                    const ageDiff = nowYear - birthYear; // 年之差
                    // // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
                    // if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
                    // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
                    if (nowMonth != birthMonth) {
                        // 拿到出生日期的日与当前时间的月之差
                        const monthDiff = nowMonth - birthMonth; // 月之差
                        // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
                        workingSeniority= monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                        item.workingSeniority=workingSeniority
                        return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    }
                    // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
                    const dayDiff = nowDay - birthDay;
                    // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
                    workingSeniority= dayDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
                    item.workingSeniority=workingSeniority
                    return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
                }

            })
        }
        this.setState({
            allDoctors:res.data[0]
        })
    }

    // 选择医生
    isInAllDoctors = (doc) => {
        const limitArr = JSON.parse(localStorage.getItem("limitArr") ?? "[]");
        // console.log(limitArr);          
        // console.log(limitArr,'290============');
        // console.log(doc.doc_level,'295============');
        if(limitArr.length && doc.doc_level == 1){
            return false;
        }
        return this.state.allDoctors.some(item => item.docter_id === doc.docter_id);
        // return this.state.allDoctors.some(item => item.docter_id === doc.docter_id);
    };
    selecttwoDoc = () =>{
        Toast.show({
            content: '该医生无法选择，请选择其他医生',
        });
        
    }
    // 复制客服电话
    copykefuhone = () => {
        const spanText = document.getElementById('contactPhone'). innerText;
        const oInput = document.createElement('input');
        oInput.value = spanText;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        document.body.removeChild(oInput);
        Toast.info('复制成功') 
    }
    selectoneDoctor=async (item)=>{
        const {coordinationcode,entitycode,awaitcode,consultationId,zhongyaomeds,medType} = this.state
        if (awaitcode){
            let res
            if (item==1){
                res=await updateDoctor({consultationId})
            }else{
                res=await updateDoctor({docterId:item.docter_id,consultationId})
            }

            if(res&&res.data&&res.data[0]&&res.data[0][0].consultation_stu=='-1'){
                if (item==1){
                    this.props.history.push(`/offawait`)
                }else{
                    this.props.history.push(`/offawait?docterId=${item.docter_id}`)
                }
                Toast.info('修改成功')
            }else{
                Toast.info('医生已接诊，无法修改指定接诊医生')
                this.props.history.push(`/offawait?docterId=${item.docter_id}`)
            }

        }else{
            localStorage.setItem('selectdoctormiaosu',item.docter_hospital+'|'+item.docter_department)
            // this.props.history.goBack();
            if(medType == 2){
                this.props.history.push(`/patientinformationzhong?coordinationcode=${coordinationcode}&entitycode=${entitycode}&medType=${medType}&docterId=${item.docter_id}&docterName=${item.docter_name}`)
            }else{
                this.props.history.push(`/patientinformation?coordinationcode=${coordinationcode}&entitycode=${entitycode}&docterId=${item.docter_id}&docterName=${item.docter_name}`)
            }
            
        }

    }
    render() {
        const { allDoctors,visible5,awaitcode,allDoc } = this.state;
        const sortedDoctors = [...allDoc].sort((a, b) => {
            if (!this.isInAllDoctors(a) && this.isInAllDoctors(b)) {
              return 1; // move a to the end
            } else if (this.isInAllDoctors(a) && !this.isInAllDoctors(b)) {
              return -1; // move b to the end
            } else {
              return 0; // no need to sort
            }
          });

        return (
            <div style={{width:'100vw',height:'100vh',backgroundColor:'#fff'}}>
                <div className='box-top12'>
                    <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                    {/* <span className='return-right' onClick={this.props.history.goBack}>❮</span> */}
                    <span className='title-middle'>选择医生</span>
                </div>

                <div className='Reviewer-all-doctors'>
                    {awaitcode?<div className='Reviewer-one-doctor'>
                        <p className='Reviewer-one-doctor-left'>
                            <img src={icon}/>
                        </p>
                        <p className='Reviewer-one-doctor-right'>
                            <p className='Reviewer-one-doctor-right-news'>
                                <h1>快速问诊</h1>
                            </p>
                            <p className='Reviewer-one-doctor-sc'>随机分配医生，快速响应</p>
                            <p className='Reviewer-one-doctor-year-num'>
                                <p>问诊开方更快更便捷</p>
                            </p>
                            <button onClick={()=>this.selectoneDoctor(1)}>选择</button>
                        </p>
                    </div>:null}

                    {allDoc.length ? sortedDoctors.map((doc)=>(
                        <>
                            <div className='Reviewer-one-doctor'>
                                <p className='Reviewer-one-doctor-left'>
                                    <img src={doc.docter_avatar}/>
                                </p>
                                <p className='Reviewer-one-doctor-right'>
                                    <p className='Reviewer-one-doctor-right-news'>
                                        <h1>{doc.docter_name}</h1>
                                        <span>|</span>
                                        <p>{doc.docter_title}</p>
                                        <span>|</span>
                                        <p>执业证编号：{doc.certificateNum.substr(0,2)+'******'+doc.certificateNum.substr(-2)}</p>
                                    </p>
                                    <p className='Reviewer-one-doctor-hos'>
                                        <p>{doc.first_practice_point}</p>
                                        <span>|</span>
                                        {/* <p>{doc.docter_department}</p> */}
                                        {/* <span>|</span> */}
                                        <p>{doc.dep_name}</p> 
                                    </p>
                                    <p className='Reviewer-one-doctor-sc'>专业擅长：{doc.docter_expert}</p>
                                    <p className='Reviewer-one-doctor-year-num'>
                                        <p>从业：{doc.workingSeniority}年</p>
                                        <span>|</span>
                                        <p>
                                            <span>接诊次数：</span>
                                            <span>{doc.countNum}</span>
                                        </p>
                                    </p>
                                    {this.isInAllDoctors(doc) ? (
                                        <button onClick={() => this.selectoneDoctor(doc)}>选择</button>
                                    ) : (
                                        <button onClick={() => {
                                                    Toast.info('所选药品不在该医生接诊范围，请选择其他医生')
                                                }} 
                                                style={{ backgroundColor: '#e4e4e4',color:'#ababab', cursor: 'not-allowed' }}>选择</button>
                                    )}
                                </p>
                            </div>
                        </>
                    )):null}

                </div>
                <p className='contact-service' style={{marginTop:'20px'}}>
                    <span>如有问题或建议，点击</span>
                    <span onClick={()=>this.setState({
                        visible5:true
                    })}>联系我们</span>
                </p>
                {visible5 ? <div className='contact-complaint-outer'>
                    <div className='contact-complaint-inner'>
                        <img className='close' src={icon2} onClick={() => this.setState({
                            visible5: false,
                        })}/>
                        <h2>联系或投诉</h2>
                        <p className='copy-phone'>
                            <span>客服电话：</span>
                            <span id='contactPhone'>19048012565</span>
                            <i className='iconfont icon-baocun' onClick={() => this.copykefuhone()}/>
                        </p>
                        <p className='tips-contact-complaint'>在使用中如有问题或疑问，您可以拨打电话联系我们。</p>
                        <p className='chat-contact-complaint'>
                            <img src={icon3}/>
                        </p>
                        <p className='tips-contact-complaint'>添加客服微信，及时处理您的问题</p>
                    </div>
                </div> : null}
            </div>
        )
    }
}

