import React, { Component } from 'react'
import { Modal,CascadePicker,DatePicker } from "antd-mobile-v5"
import { Toast} from "antd-mobile"
import "./cliniccard2.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/jiankangka.png"
import {nonameClinic} from '../../../api/hospitalapi/electronic'
/*
*  就诊卡
* */

export default class cliniccard2 extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            visible:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:[],
            cardAll:[
                { label: '身份证', value: '身份证'},
                { label: '军官证', value: '军官证'},
                { label: '驾驶证', value: '驾驶证'},
                { label: '出生证', value: '出生证'},
                { label: '户口溥', value: '户口溥'},
                { label: '港澳回乡证通行证', value: '港澳回乡证通行证'},
                { label: '外国护照', value: '外国护照'},
                ],
            card:'',
            name:'',
            jzId:'',
            zjId:''
        }
    }
    componentDidMount = () => {
        const {userId} =this.state

    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification=()=>{
        const {value1,value2} =this.state
        // console.log(value1,value2);
    }
    allNews=(e,v)=>{
        switch (v) {
            case 'name':
                this.setState({
                    name:e.target.value
                })
                break;
            case 'jzId':
                this.setState({
                    jzId:e.target.value
                })
                break;
            case 'zjId':
                this.setState({
                    zjId:e.target.value
                })
                break;
            default:
                break;
        }
    }
    commit=async ()=>{
        const {name,jzId,userId} =this.state
        //未实名认证
        if (name&&jzId){
            let params={
                patientName:name,
                cardNumber:jzId,
                patientId:userId
            }
            let res=await nonameClinic(params)
            // console.log(res)
            if (res&&res.code==1){
                this.props.history.push("/cliniccard")
            }
        }else{
            Toast.info('请将信息填写完整')
        }
    }
    render() {
        const { cardAll,visible,card,name,jzId,zjId} = this.state
        // console.log(card)
        return (
            <div className='setbox  queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/cliniccard")} />   就诊卡绑定电子健康卡
                </div>
                <div className='cliniccard-outer'>
                    <p className='tianxie-card'>填写就诊卡信息</p>
                    <div className='bd-card-outer'>
                        <p>
                            <span>卡类型</span>
                            <span className='last'>
                            <span>未实名制认证</span>
                            <span className='dayu dayu1'>＞</span>
                        </span>
                        </p>
                        <p>
                            <span>您的姓名</span>
                            <input type='text' placeholder='请输入您的姓名' value={name} onChange={(e,v)=>this.allNews(e,'name')}/>
                        </p>
                        <p>
                            <span>就诊卡号</span>
                            <input type='number' placeholder='请输入就诊卡号' value={jzId} onChange={(e,v)=>this.allNews(e,'jzId')}/>
                        </p>
                        {/*<p>*/}
                        {/*    <span>证件类型</span>*/}
                        {/*    <span className='last'>*/}
                        {/*        <span className={card?'dayu2':'dayu'}>{card?card:'请选择'}</span>*/}
                        {/*        <span className='dayu dayu1'onClick={() => this.setState({*/}
                        {/*            visible: true*/}
                        {/*        })} >＞</span>*/}
                        {/*        <CascadePicker*/}
                        {/*            // title='级联选择'*/}
                        {/*            options={cardAll}*/}
                        {/*            visible={visible}*/}
                        {/*            onClose={() => {*/}
                        {/*                this.setState({*/}
                        {/*                    visible: false*/}
                        {/*                })*/}
                        {/*            }}*/}
                        {/*            onConfirm={(val) => {*/}
                        {/*                console.log('onConfirm', val)*/}
                        {/*                this.setState({*/}
                        {/*                    card: val[0]*/}
                        {/*                })*/}
                        {/*            }}*/}
                        {/*        ></CascadePicker>*/}
                        {/*    </span>*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*    <span>证件号码</span>*/}
                        {/*    <input type='number' placeholder='请输入你的证件号码' value={zjId} onChange={(e,v)=>this.allNews(e,'zjId')}/>*/}
                        {/*</p>*/}
                    </div>
                </div>
                <p className='wx-tishi'>温馨提示：办卡或绑卡成功后，三个月可解绑一次！</p>
                <p className='submit' onClick={()=>this.commit()}>提交</p>
            </div>
        )
    }
}
