import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import {Toast} from "antd-mobile-v5"
import { savePhone, saveWx, logincodeRequest, verifyRequest, onlyAccountRequest, changeUser, getUser, getJMURL } from "../../api/request/user"
import { getUserSH } from "../../api/request/user"
import { phoneLogin } from "../../api/hospitalapi/login"
import { changeuser } from "../../api/hospitalapi/home"



import Yyhome from "./yyhome"
import FootNav from '../../component/FootNav'
// 获取url参数的函数
import getUrl from '../../component/GetUrlParam'

import './style.less'
import { storageUser } from './userstorage'
class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            patientPhone:localStorage.getItem("patientPhone")
        }
    }

    componentDidMount=()=>{
        const {patientPhone} = this.state
        // console.log(window.location.href);
        // getUrl(patientPhone)
        if (getUrl('patientPhone',window.location.href)) {
            const patientName=getUrl('patientName',window.location.href)
            const patientPhone1=getUrl('patientPhone',window.location.href)
            const patientAvatar=getUrl('patientAvatar',window.location.href)
            const patientId=getUrl('patientId',window.location.href)
            const keyWord=decodeURIComponent(patientName)//单个参数解码
            localStorage.setItem("patientPhone", patientPhone1)
            localStorage.setItem("patientAvatar", patientAvatar)
            localStorage.setItem("patientName", keyWord)
            localStorage.setItem("patientId", patientId)
            // console.log(patientPhone1);
            phoneLogin({patientPhone:patientPhone1}).then(res=>{
                // console.log(res);
                if (res.code==1&&patientName!='null') {
                    changeuser({patientId:patientId,patientName:patientPhone1}).then(res1=>{
                        // console.log(res1);
                    })
                }
            })
        }else{
            if (!patientPhone) {
                Toast.show({
                    content:"当前未登录，请先登录",
                    duration:1000
                })
            }
        }
    }
    // 获取微信登录状态
    // componentWillMount=async()=> {

    //     //京东获取token测试
    //     //window.location.href="https://oauth.jdl.cn/oauth/authorize?client_id=3126199b82c544f68e348463b04da301&redirect_uri=https%3A%2F%2Fpf-h5.sczyzk.com%2Findex.html%2523%2F%3Fenv%3Dsingle%26pharmacyId%3D25&response_type=code"
    //     this.setState({
    //         wxstate: window.localStorage.getItem("wxlogin") ? true : false,
    //         img: window.localStorage.getItem("wximg"),
    //         userName: window.localStorage.getItem("wxuserName"),
    //         userOpenid: window.localStorage.getItem("wxuserOpenid"),
    //         pharmacyId: window.localStorage.getItem("pharmacyId")
    //     }, async() => {
    //         //批发端判断用资质审核是否通过
    //         if (this.state.pharmacyId == "25"&&this.state.userId) {
    //             const res = await getUserSH({
    //                 userId: this.state.userId
    //             })
    //             console.log(res)
    //             if (res.data[0].length == 0) {
    //                 Toast.fail("未经资质审核")
    //                 this.props.history.push("/qualifications")
    //             } else if (res.data[0][0].entrust_state != 1 || res.data[0][0].card_state != 1 || res.data[0][0].license_state != 1) {
    //                 Toast.fail("资质审核未通过")
    //                 this.props.history.push("/await")
    //             }
    //         }
    //     })
    // }
  
   
    render() {
        return (
            <div>
                <Yyhome props={this.props} />
                {/* {divstate ? <div className="bgbox">
                    <div className="bgbox-main">
                        <div className="main-top">
                            <p>检测到您还没绑定手机请立即绑定您的手机号</p>
                        </div>
                        <div className="main-text">
                            <p>手机号码</p>
                            <InputItem
                                type="number"
                                placeholder="请输入手机号"
                                error={this.state.hasError}
                                onErrorClick={this.onErrorClick}
                                onChange={this.onChange}
                                value={this.state.phone}
                            ></InputItem>
                        </div>
                        <div className="main-text">
                            <p>验证码</p>
                            <InputItem
                                type="number"
                                placeholder="请输入验证码"
                                error={this.state.hasError2}
                                onErrorClick={this.onErrorClick2}
                                onChange={this.onChange2}
                                onBlur={this.blur}
                                value={this.state.newvalue}
                            ></InputItem>
                        </div>
                        <p className="title">
                            未注册的手机号验证后自动创建账户
                        </p>
                        <div className="main-bottom">
                            <button onClick={() => this.setState({ divstate: false })}>取消</button>
                            <button onClick={this.toNext}>确定</button>
                        </div>
                        {this.state.code == 0 ? <a className="ab" onClick={this.getnum}>获取验证码</a> : this.state.code == 1 ? <a className="ab">{this.state.time}后秒重新获取</a> : <a className="ab" onClick={this.getnum}>重新获取验证码</a>}
                    </div>
                </div> : null} */}

                {/* <FootNav onRef={this.onRef}/> */}
            </div>
        )
    }
}

export default withRouter(Home)