import React from 'react'

import IMG1 from '../../static/img/med/med01.png'
import IMG2 from '../../static/img/med/med02.png'
import IMG3 from '../../static/img/med/med03.png'

import useDraggable from './useDraggable'
import './drag.less'

const list=[{src:IMG1,title:'复方甘草胶囊'},{src:IMG2,title:'莲花清瘟胶囊'},{src:IMG3,title:'复方阿胶浆'}]

export default function App(params) {
    return(
        <div>
            <DraggableList list={list}/>
        </div>
    )
}

function DraggableList({list}) {
    const {dragList,createDropperProps,createDraggerProps}=useDraggable(list)

    return dragList.map((item,i)=>{
        if(item.type==="BAR"){
            return <Bar id={i} {...createDropperProps()} key={item.id} />
        } else {
            return (
            <Draggable {...createDraggerProps()}>
                <Card {...item.data}/>
            </Draggable>
            )
        }
    })
}

function Card({src,title}) {
    return(
        <div className='drag-card'>
            <img src={src} alt=''/>
            <span>{title}</span>
        </div>
    )
}

function Draggable({children,eventHandlers,dragging,id}) {
    return <div {...eventHandlers} draggable={true} className='draggable'>
        {children}
    </div>
}

function Bar() {
    return <div  className='draggable-bar'></div>
}

