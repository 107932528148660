import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import "./style.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import {prescribed,prescribingOrder,passPrescribing} from '../../../api/hospitalapi/chat'
import {Toast} from "antd-mobile";
import Nodata from "../../../component/Nodata";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            userId: localStorage.getItem("patientId"),
            list:[],
            list1:[],
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        if (userId){
            passPrescribing({userId}).then(res=>{
       
                if (res&&res.data&&res.data[0]){
                    this.setState({
                        list:res.data[0]
                    })
                }
                if (res&&res.data&&res.data[1]){
                    this.setState({
                        list1:res.data[1]
                    })
                }
            })
        }

    }
    clearuser = () => {
        localStorage.clear()
        this.props.history.push("/login")
    }
    verification=()=>{
        const {value1,value2} =this.state
 
    }
    changevalue=(e,code)=>{
        if (code==1) {
            this.setState({
                value1:e.target.value
            })
        }else{
            this.setState({
                value2:e.target.value
            })
        }
    }
    tolook=(consultationId,prescribingId,docterId)=>{
        localStorage.setItem('detailContent0',prescribingId)
        // this.props.history.push({
        //     pathname: "/doctorinfo/chat/chatinfo",
        //     params: {
        //         yktext: 1,
        //         detailContent:prescribingId,
        //     }
        // })
        this.props.history.replace(`/mine/prescribed?consultationId=${consultationId}&docterId=${docterId}&yktext=${1}`)
        this.props.history.push(`/doctorinfo/chat/chatinfo?detailContent=${prescribingId}&consultationId=${consultationId}&docterId=${docterId}&yktext=${1}`)
    }
    //立即购药
    toBuy=async (prescribingState,prescribingId)=>{
        if (prescribingState==1){
            let res=await prescribingOrder({prescribingId})
   
        }else{
            Toast.info('处方未生效，无法购药')
        }
    }
    tolook1=()=>{
        Toast.info('处方已失效，无法查看处方详情')
    }
    render() {
        const { list,list1} = this.state
        return (
            <div className='setbox'>
                <div className='box-top box-top2'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/mine")} />   已开处方
                </div>
                <div className='setprescribed-outer'>
                {list.length||list1.length?
                    <>
                    {list1.map((item,index)=>(
                        <div className='setprescribed'>
                            <div className='setprescribed-body'>
                                <p className='setprescribed-detail'>
                                    <span>诊断：</span>
                                    <span>{item.docter_diagnosis}</span>
                                </p>
                                {/* <p className='setprescribed-detail'>
                            <span>处方药：</span>
                        </p> */}
                                <p className='setprescribed-detail'>
                                    <span>就诊人：</span>
                                    <span>{item.patient_name}</span>
                                </p>
                                <p className='setprescribed-detail'>
                                    <span>医师：</span>
                                    <span>{item.docter_name}</span>
                                </p>
                                <p className='setprescribed-detail'>
                                    <span>日期：</span>
                                    <span>{item.create_time}</span>
                                </p>
                            </div>
                            <div className='setprescribed-bottom'>

                                {item.prescribing_state==1?<p style={{color:'#5184F7'}}>有效</p>:<p>无效</p>}
                                <div>
                                    <p className='concat' onClick={()=>this.tolook(item.consultation_id,item.prescribing_id,item.docter_id,)}>查看详情</p>
                                    <a className='concat' href={`tel:${item.docter_phone}`}>联系医生</a >
                                    {/*<p className='buy' onClick={()=>this.toBuy(item.prescribing_state,item.prescribing_id)}>立即购药</p>*/}
                                </div>
                            </div>
                        </div>))}
                        {list.map((item,index)=>(
                            <div className='setprescribed'>
                                <div className='setprescribed-body'>
                                    <p className='setprescribed-detail'>
                                        <span>诊断：</span>
                                        <span>{item.docter_diagnosis}</span>
                                    </p>
                                    {/* <p className='setprescribed-detail'>
                            <span>处方药：</span>
                        </p> */}
                                    <p className='setprescribed-detail'>
                                        <span>就诊人：</span>
                                        <span>{item.patient_name}</span>
                                    </p>
                                    <p className='setprescribed-detail'>
                                        <span>医师：</span>
                                        <span>{item.docter_name}</span>
                                    </p>
                                    <p className='setprescribed-detail'>
                                        <span>日期：</span>
                                        <span>{item.create_time}</span>
                                    </p>
                                </div>
                                <div className='setprescribed-bottom'>

                                    <p>无效</p>
                                    <div>
                                        <p className='concat' onClick={()=>this.tolook1()}>查看详情</p>
                                        <a className='concat' href={`tel:${item.docter_phone}`}>联系医生</a >
                                        {/*<p className='buy' onClick={()=>this.toBuy(item.prescribing_state,item.prescribing_id)}>立即购药</p>*/}
                                    </div>
                                </div>
                            </div>))}
                    </>:<Nodata type='处方'/>}
            </div>
            </div>
        )
    }
}
