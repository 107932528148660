import React, { Component } from 'react'
import { Modal } from "antd-mobile-v5"
import "./invoicedetails.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/head.png"
import {prescribed} from '../../../api/hospitalapi/chat'
// import { Pagination,} from "antd";
// import PDF from 'react-pdf-js';
/*
*  缴费详情
* */

export default class invoicedetails extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode: false,
            value1: "",
            value2: "",
            userId: localStorage.getItem("patientId"),
            list: [],
            visible: false,
            noticeUrl:'',
            noticeId : '',
            page: 1,
            current: 1,
            total: 1
        }
    }

    componentDidMount = () => {
        const {userId} = this.state

    }
    onDocumentComplete = totalPage => {
        this.setState({ total: totalPage });
    };
    onChange (page) {
        this.setState({
            page: page,
        });
    }
    pdfChange = page => {
        this.setState({
            page: page,
            current: page
        });
    };
    render() {
        const {noticeUrl} = this.state
        return (
            <div className='setbox queryreport-setbox'>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/outpatientpayment")}/> 电子发票详情
                </div>
                <div className='invoice-outer'>
                    {/*<PDF*/}
                    {/*    file={noticeUrl}*/}
                    {/*    onDocumentComplete={this.onDocumentComplete}*/}
                    {/*    page={this.state.page}></PDF>*/}
                    {/*//antd翻页组件(npm i antd)*/}
                    {/*<Pagination onChange={this.pdfChange}  current={this.state.current} pageSize={1} total={this.state.total} />*/}
                </div>
                <p className='paymentdetails-news'>发票信息</p>
                <p className='paymentdetails-top'>
                    <p className='invoice-news invoice-news1'>
                        <span>开票单位</span>
                        <span>XX医院</span>
                    </p>
                    <p className='invoice-news'>
                        <span>票据金额</span>
                        <span>￥185.60</span>
                    </p>
                    <p className='invoice-news invoice-news1'>
                        <span>开票日期</span>
                        <span>2022-08-10</span>
                    </p>
                    <p className='invoice-news'>
                        <span>票据代码</span>
                        <span>51048557</span>
                    </p>
                    <p className='invoice-news invoice-news1'>
                        <span>票据号码</span>
                        <span>0084515656</span>
                    </p>
                </p>
            </div>
        )
    }
}
