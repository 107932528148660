import React, { Component } from 'react'
import { Modal,  } from "antd-mobile-v5"
import { Toast } from "antd-mobile"
import "./style.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon3 from "../../static/img/yy/five.png"
import { prescribed } from '../../api/hospitalapi/chat'
import { oneDocpj, gzDoc, getGzdoc } from "../../api/hospitalapi/user"
import { getdoctorlist } from "../../api/hospitalapi/home"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            patientId: localStorage.getItem("patientId"),
            docList: [],
            list: [],
            timeNow:''
        }
    }
    componentDidMount = () => {
        const { patientId, docList } = this.state
        if (!patientId) {
            Toast.show({
                content: "当前未登录，请先登录",
                duration: 1000
            })
            this.props.history.push("/login")
        }
    }
    render() {
        const { list,timeNow } = this.state
        return (
            <div className='setbox' style={{backgroundColor:'#fff'}}>
                <div className='box-top'>
                    <img src={icon1} alt="" onClick={() => this.props.history.push("/writelist")} />   互联网诊疗风险告知及知情同意书
                </div>
                <h2 className='risk-title'>互联网诊疗风险告知及知情同意书</h2>
                <p className='risk-body'>
                    <p>亲爱的患者及家属朋友，您好！</p>
                    <p>
                        欢迎来到中江云益健互联网医院平台（下称“平台”或“我们”）。本平台上的互联网医院为患者（下称“患者”或“您”）提供包括但不限于互联网诊疗咨询、复诊开方、健康管理等服务（下称“在线诊疗”）。我们特将在线诊疗的相关重要事项告知您及您的家属，请认真阅读并理解，尤其是下划线部分文字。
                    </p>
                    <p>一、在线诊疗具有相当的局限性</p>
                    <p>
                        由于在线诊疗过程中不能进行现场查体，对某些隐蔽性症状体征可能会有所疏忽，不能及时在线开展实验室生化检查和影像检查等，不能对患者急危重症采取快速现场应对；故医方在线诊疗服务中能够采取的有效措施较为有限。
                    </p>
                    <p>
                        在线诊疗中医生获取的信息完全有赖于您及您家属通过语音、图文的准确表述；故请您和您的家属务必配合医务人员进行必要、准确的病情描述，并按要求提供相应的复诊凭证。若因您及您家属对病情的描述不准确、不完整、不配合等，均有可能因对病情描述不当导致误诊误治的不利后果；若因此则需患者对此承担相应责任。
                    </p>
                    <p>二、疾病的自身风险</p>
                    <p>
                        疾病的病程演变是复杂多变，且往往因为个体具有一定差异性。同时，常会有病情恶化、治疗不效、合并症加重、并发症出现，医疗意外等风险随时发生。有的可能是可以预见，有的是不能预见，有的是难以预见也难以预防的，这些都是疾病本身产生的风险。若您在在线诊疗中或在线诊疗后，若发现自身的症状和体征发生改变或恶化，或有明显的身体不适，请您立刻中断在线诊疗或停止相应治疗方案，并及时就近急诊就医，以免贻误病情。
                    </p>
                    <p>三、患者端线上行为的当然效果</p>
                    <p>
                        由于线上诊疗中，不同于线下现场诊疗，我们虽然已经对患者的实名信息予以核验，但平台难以时时核验患者端操作人的实人身份。对于患者端操作人开展病情告知、医患问答、发表选择和决定性意见、签署平台内的各种法律文件和办理在线诊疗中的流程手续，互联网医院将视同为该患者端操作人为患者本人，或已经取得患者本人充分授权，或作为患者的监护人有权操作；故该患者端操作人所进行的任何操作行为，对患者均具有相应的法律效力。
                    </p>
                    <p>
                        若患者端操作并非患者本人，为患者的授权人或患者的监护人，则应充分了解患者的症状体征，并尽量全面完整的提供相应的线下诊疗书面资料，以便接诊医生获取充分疾病信息并开展相应诊治措施。
                    </p>
                    <p>四、对个人健康信息的处理及保护</p>
                    <p>
                        我们非常重视并致力于对个人健康信息的保护，且遵循合法、正当、必要的原则来收集您提交的各项个人健康信息，用于线上医生为您服务。我们采取符合业界标准、合理可行的方式对患者的个人健康信息进行存储及保护。
                    </p>
                    <p> 五、其他在线诊疗的风险告知</p>
                    <p>
                        疾病的治愈需要患者谨遵医嘱、健康管理方案，并积极配合。如果患者未完全遵医嘱，则可能导致诊疗效果不理想，甚至出现病情反复、恶化等不良后果。
                    </p>
                    <p>
                        患者自采药品的品牌、规格、性状、使用方式等可能影响本次诊疗方案的效果，同时还可能出现危害生命健康的风险。
                    </p>
                    <p>
                        医生主要解决本专业领域的医疗问题，非本专业的疾病需要到其它专业科室进行诊治或接受远程医疗服务。
                    </p>
                    <p>
                        本患者知情同意书未能一一列出所有风险，如果患者有疑问应与医生讨论。
                    </p>
                    <p>六、您确认如下：</p>
                    <p>
                        若您选择在本平台上的互联网医院享受在线诊疗服务，您确认您已经在线下实体医疗机构进行过诊疗且有明确临床诊断结论。您可以提供或上传您的相关的病历资料，愿意接受在线诊疗服务。您确认既往发生过与本次发病类似的常见病、慢性病症，且本次参与在线诊疗的症状和体征均和既往线下就诊情况基本一致，您不会向医生提供虚假、不实的信息。您愿意接受互联网医院提供旳在线诊疗服务，并协助平台上的互联网医院完善健康档案。
                    </p>
                    <p>
                        您确认已经完全理解并同意以上全部內容，已经知悉相关的风险，愿意接受在线诊疗服务，并知悉和同意本风险告知及知情同意书。
                    </p>
                </p>
            </div>
        )
    }
}
