import React ,{ useState } from 'react'
import { ImagePicker, TextareaItem, Toast } from 'antd-mobile'

import { healthpostRequest } from '../../../api/request/health'
import { uploadImg } from '../../../api/request/upload'

import { zipImg } from '../../../component/zipImage/index'

import './style.less'

export default function Post(props){
    // 图片路径
    const [files, setFiles] = useState([])
    // 用户id
    const userId = localStorage.userId
    const topicId = props.match.params.topicId
    // 帖子内容设置
    const [postText, setPostText] = useState('')
    // 图片地址
    const [postPic, setPostPic] = useState([])
    const backPage=()=>{
        window.history.back()
    }
    // 帖子内容更新
    const healthPost = (val) => {
        setPostText(val)
    }
    // 发表帖子
    const subMit = () => {
        // 参数
        const params = {
            userId: userId,
            topicId: topicId,
            postText: postText,
            postPic: postPic.join(',')
        }
        if(params.postText === '') {
            Toast.info('未输入内容', 1)
        } else {
            healthpostRequest(params).then(res => {
                if(res.code === 1) {
                    backPage()
                }
            })
        }
    }
    // 处理上传图片
    const imgChange = (files, type, index) => {
        Toast.loading('Loading...', 0)
        let imgList = postPic
        if(type === 'add') {
            let file = files[files.length-1].file
            zipImg(file).then(res => {
                let formData = new FormData()
                formData.append('file', res)
                uploadImg(formData).then(res1 => {
                    if(res1.code === 2) {
                        setFiles(files)
                        imgList.push(res1.data)
                    } else {
                        Toast.info('上传失败', 1)
                    }
                    setPostPic(imgList)
                    Toast.hide()
                }).catch(err => {
                    Toast.hide()
                })
            })
        } else {
            setFiles(files)
            imgList.splice(index, 1)
            setPostPic(imgList)
            Toast.hide()
        }
    }

    return(
        <>
            <div className='post-header'>
                <div className='back-icon flexr' onClick={backPage}>
                    <i className='icon-xiangzuo iconfont'></i>
                </div> 
                <span>发表帖子</span>
                <div className='post-icon flex-row' onClick={subMit}>发表</div>
            </div>
            <div className='post-area'>
                <TextareaItem
                className='post-area-text'
                placeholder='我有话要说...'
                rows={10}
                count={500}
                onChange={healthPost}/>
            </div>
            <div className='post-bottom'>
                <ImagePicker
                files={files}
                length={3}
                onChange={imgChange}
                onImageClick={(index, fs) => console.log(index, fs)}
                selectable={files.length < 6}
                accept='image/gif,image/jpeg,image/jpg,image/png'/>
            </div>
        </>
    )
}