import React, { Component } from 'react';
import fhhome from "./img/fhhome.png";
import zk from "./img/zk.png";
import sq from "./img/sq.png";
import './style.less'
import { getChineseDrugs } from '../../../../api/hospitalapi/drugs'
import Nodata from "../../../../component/Nodata";
/**
 * 中药添加记录展示
 */
export default class Index extends Component {
    constructor() {
        super()
        this.state = {
            records: 1,  //1:西/中成药记录,2:中药材记录
            isCollapsed: -1,
            data: []
        }
    }
    handleClickSqzk = (index) => {
        if (index === this.state.isCollapsed) {
            index = -1
        }

        this.setState({
            isCollapsed: index
        })
    }


    loadData = async () => {
        let res = await getChineseDrugs(localStorage.getItem("pharmacyId"))
        if (res.data[0] == null) {
            return
        }
        let data = res.data[0]

        this.setState({
            data
        })
    }
    componentDidMount() {
        this.loadData()

    }


    render() {
        const {
            records,
            isCollapsed,
            data } = this.state
        return (
            <div className='sidebox'>
                <div className='top-tit'>
                    <img className='return-img' src={fhhome} onClick={this.props.history.goBack} />
                    <span className='title-middle'>添加记录2</span>
                </div>

                <div className='headerbox'>
                    <span className={records == 1 ? 'header-left2':'header-left1' } onClick={() => {
                        // console.log(111);
                        this.setState({
                            records: 1
                        })
                        this.props.history.push('/ShowUsMedicine')
                    }}>西/中成药记录</span>

                    <span className={records == 2 ? 'header-right2':'header-right1'  } onClick={() => {
                        // console.log(2222);
                        this.setState({
                            records: 2
                        })
                        this.props.history.push('/ShowZhMedicine')
                    }}>中药材记录</span>
                </div>

                <div className='middlebox'>



                    {/* 中药材记录 */}
                    {
                        data.length>0 ? (
                            data.map((item, index) => (

                                <div className='medsbox' key={index}>
                                    <div className='medsbox-one'>
                                        <p className='one-left1'>
                                            <span>添加时间：</span><span>{item.create_time}</span>
                                        </p>
                                        <p className='one-right1' onClick={() => this.handleClickSqzk(index)}>
        
                                            {index === isCollapsed ? (
                                                <>
                                                    <img src={zk} />
                                                    <span>收起</span>
                                                </>
                                            ) : (
                                                <>
                                                    <img src={sq} />
                                                    <span>展开</span>
                                                </>
                                            )}
                                        </p>
                                    </div>
                                    <p>中药饮片名称：<span className='meds-one-name'>{item.pro_name}</span></p>
                                    <p>药品规格：<span className='meds-one-name'>{item.pro_name}</span></p>
                                    <p>包装：<span>{item.packing_unit}</span></p>
                                    {index === isCollapsed && (
                                        <>
                                            <p>生产厂家：<span>{item.pro_company}</span></p>
                                            <p>执行标准：<span>{item.standards}</span></p>
                                            <p>产地：<span>{item.place_origin}</span></p>
                                            <p>存储：<span>{item.pro_storage}</span></p>
                                        </>
                                    )}     
                                </div>
                            ))
                        ) : <Nodata type='消息'/>
                    }

                </div>

            </div>

        );
    }
}

