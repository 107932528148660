import React, { Component } from 'react'
import { Popup,ImageViewer } from "antd-mobile-v5"
import {Toast, Pagination, Modal,} from 'antd-mobile'
import icon1 from "../../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../../static/img/yy/three.png"
import icon4 from "../../../../static/img/bhimg.png"
import icon5 from "../../../../static/img/successimg.png"
// import Pdf from '@mikecousins/react-pdf'
import "./style.less"
import getUrl from '../../../../component/GetUrlParam'
import {
    allMedicinal,
    prescribingDetail,
    getOrdernumber,
    getPrescribingId,
    caWrite,
    getChatnote
} from '../../../../api/hospitalapi/chat'
import {baseUrl0} from "../../../../publicurl"
import PDF from "react-pdf-js";
import icon6 from "../../../../static/img/yy/pt.png"
import icon7 from "../../../../static/img/yy/zj-zhang.png"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            visible1: false,
            detailContent: localStorage.getItem("detailContent0"),
            detailContent0: localStorage.getItem("detailContent0"),
            listtext:JSON.parse(localStorage.getItem("listtext")),
            list:[],
            detail:{},
            ill:{},
            a:'',
            yktext:getUrl('yktext', window.location.href),
            consultationId:getUrl('consultationId', window.location.href),
            orderNumber:'',
            orderNumber1:'',
            gqTime:'',
            visible:false,
            visible2:false,
            docterId:getUrl('docterId', window.location.href),
            prescribingId:"",
            prescribingNumber:"",
            currentPage:1,
            totalPage:1,
            docterAutograph:'',
            docterAutograph1:'',
        }
    }
    componentDidMount=async () => {

        const { consultationId ,detailContent,docterId,docterAutograph1} = this.state
        let res0=await getPrescribingId({id:detailContent})

        if (res0&&res0.data&&res0.data[0]&&res0.data[0].length){
            this.setState({
                prescribingNumber:res0.data[0][0].prescribingNumber
            })
        }
   
        if (this.props.location.params) {
            this.setState({
                a: this.props.location.params.a,
                yktext: this.props.location.params.yktext,
            })
        }
            let res=await allMedicinal({prescribingId:detailContent})

            if (res.code==1&&res.data[0]&&res.data[0].length) {
                let res1=await prescribingDetail({prescribingId:res.data[0][0].prescribing_id})
      
                let time1=new Date(res1.data[0][0].create_time).getTime()


                this.setState({
                    list:res.data[0],
                    detail:res1.data[0][0],
                    gqTime:new Date(time1+259200000).toLocaleString(),
                    year:new Date(res1.data[0][0].create_time).getFullYear(),
                    month:new Date(res1.data[0][0].create_time).getMonth()+1,
                    day:new Date(res1.data[0][0].create_time).getDate(),
                })
                if (res1&&res1.data&&res1.data[3]&&res1.data[3].length){
                    this.setState({
                        orderNumber:res1.data[3][0].orderNumber
                    })
                }
                if (res1.code==1&&res1.data[2]) {
                    this.setState({
                        docterAutograph: res1.data[2][0].docterAutograph,
                    })
                }
                if (res1&&res1.data&&res1.data[0]&&res1.data[0][0].pharmacist_id) {
                    caWrite({docterId:res1.data[0][0].pharmacist_id}).then(res2=>{
           
                        if (res2&&res2.data&&res2.data[0]){
                            this.setState({
                                docterAutograph1: res2.data[0][0].docterAutograph,
                            })
                        }
                    })

                }
                getOrdernumber(consultationId).then(res=>{
   
                    if (res&&res.data[0].length) {
                        this.setState({
                            orderNumber1:res.data[0][0].orderNumber
                        })
                    }
                })
            }
    }
    openbox = (item) => {

        this.setState({
            visible1: true,
            ill:item
        })
    }
    buysome = () => {
        this.setState({
            visible1: false
        })
    }
    goback = () => {
        const {yktext, a,listtext ,detailContent,consultationId,docterId} = this.state
   
        // if (yktext!=1) {
        //     this.props.history.replace('/news')
        //     this.props.history.push(`/doctorchat?consultationId=${consultationId}&docterId=${docterId}`)
        // }else{
        //     this.props.history.replace('/mine')
        //     this.props.history.push("/mine/prescribed")
        // }
        window.history.back()
    }
    onChangePage=(page)=>{
        this.setState({
            currentPage:page
        })
    }
    onDocumentLoadSuccess=(totalPage)=>{
        this.setState({
            totalPage:totalPage
        })
    }
    // 返回零售订单列表
    fanhuiOrder=()=>{
        const { orderNumber1, } = this.state
        let b=(new Date()).getTime()
        window.location.href=(`${baseUrl0}/index.html#/mine/order/id?tradeno=${orderNumber1}&a=${b}`)
    }
    //查看订单
    lookOrder=()=>{
        const { orderNumber, } = this.state
        let b = (new Date()).getTime()
        this.props.history.replace(`/doctorinfo/chat/chatinfo`)
        this.props.history.push(`/mine/order/id?tradeno=${orderNumber}&a=${b}&tabId=${0}&chatinfo=${1}`)
    }
    render() {
        const { orderNumber,yktext,visible,year,month,docterAutograph,docterAutograph1,day,visible2,prescribingNumber,visible1,list,detail,detailContent,gqTime ,ill,totalPage,currentPage} = this.state
        return (
            <>
                <div className='chatinfobox'>
                    <div className='box-top'>
                        <img src={icon1} alt="" onClick={this.goback} /><h1>处方详情</h1>
                    </div>
                    <div className='box-id'>
                        <p>处方编号：{prescribingNumber}</p>
                        <p>处方未使用（{gqTime} 过期）</p>
                    </div>
                    <div className='box-title'>
                        云益健互联网医院<br />
                        处方笺
                    </div>
                    <div className='box-info'>
                        <p>姓名：{detail.patient_name}</p>
                        <p>性别：{detail.patient_sex}</p>
                        <p>年龄：{detail.patient_age}</p>
                        <p>科室：{detail.docter_department}</p>
                        <p style={{width:'200px'}}>日期：{detail.create_time}</p>
                    </div>
                    <div className='box-explain'>
                        诊断
                    </div>
                    <div className='box-explain'>
                        {detail.docter_diagnosis}
                    </div>
                    <div className='box-listbox'>
                        <div className='listbox-top'>
                            处方清单
                        </div>
                        {list&&list.map(item=>(<div className='listbox-item'>
                            <p>{item.medicinal_name} x{item.medicinal_quantity}</p>
                            <p>规格： {item.medicinal_spec}</p>
                            <p style={{wordWrap:'break-word'}}>{item.medicinal_frequency},每次{item.medicinal_usage},{item.medicinal_day}天的量,{item.medicinal_method}</p>
                            <img src={icon2} alt="" onClick={()=>this.openbox(item)} />
                            {item.medicinal_supplementary?<p style={{wordWrap:'break-word'}}>补充说明：{item.medicinal_supplementary}</p>:null}

                        </div>))}
                       
                    </div>
                    <div className='box-sm'>
                        <div className='sm-top'>
                            补充说明
                        </div>
                        <div className='sm-text'>
                            <p>医生建议：{detail.docter_proposal}</p>
                        </div>
                    </div>
                    {detail.original_prescribing&&detail.prescribing_state==1?
                        <div className='look'>
                            <p className='look-cfd' onClick={()=>this.setState({
                                visible2:true
                            })}>查看原始处方单</p>
                            {/*<p className='look-dd' onClick={()=>this.lookOrder()}>查看订单</p>*/}
                        </div>
                        :null}
                    {detail.prescribing_state==-1?<div className='box-bh'>
                            <span>药师驳回原因：</span>
                            <span>{detail.reason}</span>
                        </div>:null}
                    <div className='ispass-img'>
                        { detail?.prescribing_state ==   1 ? <img src={icon5}/> : null}
                        {detail?.prescribing_state == -1 ? <img src={icon4}/> : null}
                    </div>
                    {/*<div className='box-gz'>*/}
                    {/*    <div className='gz-top'>*/}
                    {/*        签字盖章*/}
                    {/*    </div>*/}
                    {/*    <div className='gz-text'>*/}
                    {/*        <p>医师：{detail.docter_name}</p>*/}
                    {/*        <p>药师：{detail.pharmacist_name}</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*{yktext!=1?<div className='box-btn'>*/}
                    {/*    {detail.is_retail==2?<button onClick={()=>this.fanhuiOrder()}>返回订单列表</button>:null}*/}
                    {/*    /!* // <button onClick={() => this.props.history.push({pathname:"/chiosestore",params:{list,news:this.props.location.params.news}})}>预约药品</button>} *!/*/}
                    {/*</div>:<div className='box-btn'>*/}
                    {/*{detail.is_retail==2?<button onClick={()=>this.fanhuiOrder()}>返回订单列表</button>:null}</div>}*/}
                </div>
                <ImageViewer
                    image={detail.original_prescribing}
                    visible={visible}
                    onClose={() => {
                        this.setState({
                            visible: false
                        })
                    }}
                />
                <Popup
                    visible={visible1}
                    onMaskClick={() => {
                        this.setState({
                            visible1: false
                        })
                    }}
                    bodyStyle={{ minHeight: '48vh' }}
                >
                    <div className='chatinfo-popup'>
                        <h1 className='title'>药品详情</h1>
                        <span onClick={()=>{
                            this.setState({
                                visible1: false
                            })
                        }} style={{position:'absolute',right:'10px',top:'10px',fontSize:'18px',color:'#999'}}>x</span>
                        <div className='popup-text'>
                            <p>通用名</p>
                            <p>{ill.medicinal_name}</p>
                        </div>
                        <div className='popup-text'>
                            <p>规格</p>
                            <p>{ill.medicinal_spec}</p>
                        </div>
                        <div className='popup-text'>
                            <p>剂型</p>
                            <p>{ill.medicinal_dosage_form}</p>
                        </div>
                        <div className='popup-text'>
                            <p>类别</p>
                            <p>{ill.medicinal_type}</p>
                        </div>
                        <div className='popup-text'>
                            <p>适应症状</p>
                            <p>{ill.medicinal_apply_symptoms}</p>
                        </div>
                        <div className='popup-btn'>
                            {/* <button onClick={this.buysome}>预约药品</button> */}
                        </div>
                    </div>

                </Popup>
                {visible2&&detail.original_prescribing?<div className='Announcementdetails-outer' onClick={()=>this.setState({
                    visible2:false
                })}>
                    {/*<PDF className='pdf-top'  scale={0.8} file={detail.original_prescribing}*/}
                    {/*     onDocumentComplete={this.onDocumentLoadSuccess} page={currentPage} ></PDF>*/}
                    <Modal
                        className="aa"
                        visible={true}
                    >
                        <div id='demo'>
                            <div id="a">
                                <div className='prescription-outer' >
                                    {detail.patient_age<6?<p className='erke'>儿科</p>:null}
                                    <h1 className='title'>云益健互联网医院</h1>
                                    <p className='prescription-jian'>
                                        <h1>{detail.patient_age<6?'儿科':""}处方笺</h1>
                                        <img src={icon6}/>
                                    </p>
                                    <p className='prescription-number'>处方编号：{detail.prescribing_number}</p>
                                    <p className='prescription-line'></p>
                                    <p className='prescription-one'>
                                        <p className='prescription-one-left'>
                                            <span>科室:</span>
                                            <p>{detail.docter_department}</p>
                                        </p>
                                        <p className='prescription-one-right'>
                                            <span>开具日期:</span>
                                            <p>{year}</p>
                                            <span>年</span>
                                            <p>{month}</p>
                                            <span>月</span>
                                            <p>{day}</p>
                                            <span>日</span>
                                        </p>
                                    </p>
                                    <p className='prescription-one'>
                                        <p className='prescription-one-left'>
                                            <span>姓名:</span>
                                            <p>{detail.patient_name}</p>
                                        </p>
                                        <p className='prescription-one-right2'>
                                            <span>性别:</span>
                                            <p>{detail.patient_sex}</p>
                                            <span>年龄:</span>
                                            <p>{detail.patient_age} 岁</p>
                                        </p>
                                    </p>
                                    <p className='prescription-one2'>
                                        <span>初步诊断：</span>
                                        <p>{detail.docter_diagnosis}</p>
                                    </p>
                                    <img className='zj-zhang' src={icon7}/>
                                    <p className='prescription-line'></p>
                                    <p className='prescription-RP'>RP</p>
                                    <p className='all-meds'>
                                        {list?list.map((item,index)=>(<p className='prescription-meds'>
                                            <p className='prescription-meds-news'>
                                                <p className='prescription-meds-left'>
                                                    <span>{index+1}、{item.medicinal_name}</span>
                                                    <span>{item.medicinal_spec}</span>
                                                </p>
                                                <p className='prescription-meds-right'>x{item.medicinal_quantity}</p>
                                            </p>
                                            <p className='prescription-meds-details'>{item.medicinal_frequency},每次{item.medicinal_usage},{item.medicinal_day}天的量, {item.medicinal_method}</p>
                                            {item.medicinal_supplementary?<p className='prescription-meds-details'>补充说明：{item.medicinal_supplementary}</p>:null}
                                            {index+1<list.length?<p className='xu-line'></p>:null}
                                        </p>)):null}
                                    </p>
                                    <p className='prescription-line'></p>
                                    <p className='prescription-doctor'>
                                        <p className='prescription-doctor1'>
                                            <span>医师:</span>
                                            <p></p>
                                        </p>
                                        <p className='prescription-doctor1'>
                                            <span>药师:</span>
                                            <p></p>
                                        </p>
                                    </p>
                                    <p className='prescription-doctor2'>
                                        <p className='prescription-doctor3'>
                                            <span>调配/核对/发药:</span>
                                            <p></p>
                                        </p>
                                        <p className='prescription-doctor4'>
                                            <span>金额:</span>
                                            <p></p>
                                        </p>
                                    </p>
                                    <p className='prescription-tips'>
                                        <span>备注：</span>
                                        <p>{detail.docter_proposal}</p>
                                    </p>
                                    <p className='prescription-tips1'>1、医师处方仅开具当日有效（医生注明除外）。</p>
                                    <p className='prescription-tips1'></p>
                                </div>
                            </div>
                        </div>
                        <img className='ys-img1' src={docterAutograph}/>
                        <img className='ys-img' src={docterAutograph1}/>
                    </Modal>
                    {/*<Pagination className='pdf-bottom'  onChange={(page) => this.onChangePage(page)} total={totalPage} current={currentPage} />*/}
                </div> :null}
                {/*{visible&&detail.original_prescribing?<div className='Announcementdetails-outer'>*/}
                {/*    <Pdf className='pdf-top' scale={0.8} file={detail.original_prescribing}*/}
                {/*         onDocumentLoadSuccess={this.onDocumentLoadSuccess} page={currentPage} />*/}
                {/*    /!*<Pagination className='pdf-bottom'  onChange={(page) => this.onChangePage(page)} total={totalPage} current={currentPage} />*!/*/}
                {/*</div> :null}*/}
            </>

        )
    }
}
