import React, { Component } from 'react'
import { Modal,CascadePicker,DatePickerView ,DatePicker } from "antd-mobile-v5"
import { InputItem, Picker, Toast  } from 'antd-mobile'
import "./healthcard2.less"
import icon1 from "../../../static/img/yy/fanhuihei.png"
import icon2 from "../../../static/img/yy/addhealthcard.png"
import icon3 from "../../../static/img/you.png"
import icon4 from "../../../static/img/sl.png"
import {getProvince,getCity} from '../../../api/hospitalapi/address'
import {
    addElectronicIdcard,
    getAll, havenameClinic,
    healthClinic, healthClinicAdd,
    hisToArchives,
    isElectronic,addhispatientTemporary
} from '../../../api/hospitalapi/electronic'
import {logDOM} from "@testing-library/react";
import getUrl from '../../../component/GetUrlParam'
/*
*  挂号
* */

export default class healthcard2 extends Component {
    constructor() {
        super()
        this.state = {
            patientPhone: "",
            boxcode:false,
            value1:"",
            value2:"",
            patientId: localStorage.getItem("patientId"),
            list:[],
            name:'',
            idcard:'',
            borthDate:'',
            sexAll:[
                { label: '男', value: '男' },
                { label: '女', value: '女'}
            ],
            sex:'',
            phone:'',
            nationAll:[
                { label: '汉族', value: '汉族'},
                { label: '满族', value: '满族'},
                { label: '蒙古族', value: '蒙古族'},
                { label: '回族', value: '回族'},
                { label: '藏族', value: '藏族'},
                { label: '维吾尔族', value: '维吾尔族'},
                { label: '苗族', value: '苗族'},
                { label: '彝族', value: '彝族'},
                { label: '壮族', value: '壮族'},
                { label: '布依族', value: '布依族'},
                { label: '侗族', value: '侗族'},
                { label: '瑶族', value: '瑶族'},
                { label: '白族', value: '白族'},
                { label: '土家族', value: '土家族'},
                { label: '哈尼族', value: '哈尼族'},
                { label: '哈萨克族', value: '哈萨克族'},
                { label: '傣族', value: '傣族'},
                { label: '黎族', value: '黎族'},
                { label: '傈僳族', value: '傈僳族'},
                { label: '佤族', value: '佤族'},
                { label: '畲族', value: '畲族'},
                { label: '高山族', value: '高山族'},
                { label: '拉祜族', value: '拉祜族'},
                { label: '水族', value: '水族'},
                { label: '东乡族', value: '东乡族'},
                { label: '纳西族', value: '纳西族'},
                { label: '景颇族', value: '景颇族'},
                { label: '柯尔克孜族', value: '柯尔克孜族'},
                { label: '土族', value: '土族'},
                { label: '达斡尔族', value: '达斡尔族'},
                { label: '仫佬族', value: '仫佬族'},
                { label: '羌族', value: '羌族'},
                { label: '布朗族', value: '布朗族'},
                { label: '撒拉族', value: '撒拉族'},
                { label: '毛南族', value: '毛南族'},
                { label: '仡佬族', value: '仡佬族'},
                { label: '锡伯族', value: '锡伯族'},
                { label: '阿昌族', value: '阿昌族'},
                { label: '普米族', value: '普米族'},
                { label: '朝鲜族', value: '朝鲜族'},
                { label: '塔吉克族', value: '塔吉克族'},
                { label: '怒族', value: '怒族'},
                { label: '乌孜别克族', value: '乌孜别克族'},
                { label: '俄罗斯族', value: '俄罗斯族'},
                { label: '鄂温克族', value: '鄂温克族'},
                { label: '德昂族', value: '德昂族'},
                { label: '保安族', value: '保安族'},
                { label: '裕固族', value: '裕固族'},
                { label: '京族', value: '京族'},
                { label: '塔塔尔族', value: '塔塔尔族'},
                { label: '独龙族', value: '独龙族'},
                { label: '鄂伦春族', value: '鄂伦春族'},
                { label: '赫哲族', value: '赫哲族'},
                { label: '门巴族', value: '门巴族'},
                { label: '珞巴族', value: '珞巴族'},
                { label: '基诺族', value: '基诺族'},
            ],
            nation:'',
            residence:'',//户籍地
            address:'',
            visible:false,
            visible1:false,
            visible2:false,
            visible3:false,
            visible4:false,
            value:'',
            options : [
                {
                    label: '浙江',
                    value: '浙江',
                    children: [
                        {
                            label: '杭州',
                            value: '杭州',
                        },
                        {
                            label: '宁波',
                            value: '宁波',}
                    ],
                },
                {
                    label: '江苏',
                    value: '江苏',
                    children: [
                        {
                            label: '南京',
                            value: '南京',
                        },
                        {
                            label: '苏州',
                            value: '苏州',
                        },
                    ],
                },
            ],
            provinces:'',
            cities:'',
            id:'',
            queryreport:'',
            docterId:'',
            id0:'',
            id1:'',
            index:'',
            registrationconfirmation:""
        }
    }
    componentDidMount = () => {
        const {userId} =this.state
        if (getUrl('queryreport', this.props.location.search)){
            this.setState({
                queryreport:getUrl('queryreport', this.props.location.search),
            })
        }
        if (getUrl('registrationconfirmation', this.props.location.search)){
            this.setState({
                docterId:getUrl('docterId', this.props.location.search),
                id0:getUrl('id', this.props.location.search),
                id1:getUrl('id0', this.props.location.search),
                index:getUrl('index', this.props.location.search),
                registrationconfirmation:getUrl('registrationconfirmation', this.props.location.search),
            })
        }
        getProvince().then(res=>{
            // console.log(res)
            if (res&&res.data[0].length){
                this.setState({
                    provinces:res.data[0]
                },()=>{
                    getCity({parentId:res.data[0][0].id}).then(res1=>{
                        // console.log(res1)
                        if (res1.data[0].length){
                            this.setState({
                                cities:res1.data[0]
                            },()=>{
                                this.allCity()
                            })
                        }
                    })
                })
            }
        })
    }
    allCity=()=>{
        const {provinces,cities} = this.state
        let arr=[]
        let arr1=[]
        provinces.map(item=>{
            let params={}
            params.label=item.name
            params.value=item.name
            params.id=item.id
            params.children=[]
            arr.push(params)
        })
        cities.map(item=>{
            let params={}
            params.label=item.name
            params.value=item.name
            arr1.push(params)
        })
        arr[0].children=arr1
        // console.log(arr,arr1)
        this.setState({
            options:arr
        })
    }
    handleChange=(type,val)=>{
        switch (type) {
            case 'druName':
                this.setState({
                    name:val
                })
                break;
            case 'druIdcard':
                let a=val.replace(/[^\d.|Xx]/g,"")
                // console.log(a)
                this.setState({
                    idcard:a
                })
                break;
            case 'druPhone':
                this.setState({
                    phone:val
                })
                break;
            case 'address':
                this.setState({
                    address:val
                })
                break;
            default:
                break;
        }
    }
    changeselect = (val) => {
        this.setState({
            title: val[1]
        })
    }
    chioseone = (val) => {
        this.setState({
            selectnow: val[0]
        })
    }
    onSuccess=async()=> {
        const {name, idcard, borthDate, sex, phone, residence, nation, address, patientId} = this.state
        const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        let p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        var sexAndAge = {};
        let a = 0

        // console.log(a, sexAndAge)
        if (name && idcard && borthDate && sex && phone && residence && nation && address) {
            if (reg_tel.test(phone)) {
                if (p.test(idcard)) {
                    //获取用户身份证号码
                    var userCard = idcard;
                    //如果身份证号码为undefind则返回空
                    if (!userCard) {
                        return sexAndAge;
                    }
                    //获取到性别
                    if (parseInt(userCard.substr(16, 1)) % 2 == 1) {
                        sexAndAge.sex = '男'
                    } else {
                        sexAndAge.sex = '女'
                    }
                    //获取出生年月日
                    //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
                    var yearBirth = userCard.substring(6, 10);
                    var monthBirth = userCard.substring(10, 12);
                    var dayBirth = userCard.substring(12, 14);
                    //获取当前年月日并计算年龄
                    var myDate = new Date();
                    var monthNow = myDate.getMonth() + 1;
                    var dayNow = myDate.getDay();
                    var yearNow = myDate.getFullYear();
                    var age = yearBirth + '-' + monthBirth + '-' + dayBirth
                    if (yearNow < yearBirth || ((yearNow == yearBirth) && (monthNow < monthBirth)) || ((yearNow == yearBirth) && (monthNow == monthBirth) && (dayNow < dayBirth))) {
                        a = -1
                    } else {
                        sexAndAge.age = age;
                        a = 1
                    }
                    // console.log(a, sexAndAge)
                    if (sexAndAge && sexAndAge.age && sexAndAge.age == borthDate && sexAndAge.sex && sexAndAge.sex == sex) {
                        isElectronic({userId: patientId, patientName: name, idCard: idcard}).then(res0 => {
                            // console.log(res0)
                            if (res0 && res0.data && res0.data[0] && res0.data[0][0].sumCount == 0) {
                                let params = {
                                    patientName: name,
                                    idCard: idcard,
                                    birthday: borthDate,
                                    sex: sex,
                                    phone: phone,
                                    registeredResidence: residence,
                                    nation: nation,
                                    address: address,
                                    patientId
                                }
                                // console.log(params)
                                addElectronicIdcard(params).then(res => {
                                    if (res && res.code == 1) {
                                        hisToArchives(
                                            idcard,
                                            name
                                        ).then(res1 => {
                                            // console.log(res1)
                                            // let res1=await isClinic({
                                            //     cardNumber:clinicId,
                                            //     idCard:idCard
                                            // })
                                            if (res1 && res1.data && res1.data[0].length) {
                                                healthClinic({
                                                    hisNumber: res1.data[0][0].编号,
                                                    healthId: res.data[3][0].id
                                                }).then(res2 => {
                                                    // console.log(res2)
                                                    if (res2 && res2.data && res2.data[1] && res2.data[1][0].sumCount1 == 0) {
                                                        havenameClinic({
                                                            patientName: name,
                                                            cardNumber: res1.data[0][0].卡片编码,
                                                            hisNumber: res1.data[0][0].编号,
                                                            patientId: patientId,
                                                            idCard: idcard
                                                        }).then(res4 => {
                                                            // console.log(res4)
                                                        })
                                                    }
                                                    if (res2 && res2.data && res2.data[0] && res2.data[0][0].sumCount == 0) {
                                                        healthClinicAdd({
                                                            hisNumber: res1.data[0][0].编号,
                                                            healthId: res.data[3][0].id
                                                        }).then(res3 => {
                                                            // console.log(res3)
                                                            if (res3 && res3.code == 1) {
                                                                this.setState({
                                                                    visible: false
                                                                }, () => {
                                                                    Toast.info('健康卡创建并绑定就诊卡成功')
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                            } else {
                                                let params1 = {
                                                    name: name,
                                                    IDNumber: idcard,
                                                    birth: borthDate,
                                                    sex: sex,
                                                    phone: phone,
                                                    nation: nation,
                                                    accountAddress: residence,
                                                    birthAddress: residence,
                                                    occupation: '农民'
                                                }
                                                addhispatientTemporary(params1).then(res10 => {
                                                    if (res10 && res10.code == 1) {
                                                        hisToArchives(
                                                            idcard,
                                                            name
                                                        ).then(res1 => {
                                                            // console.log(res1)
                                                            // let res1=await isClinic({
                                                            //     cardNumber:clinicId,
                                                            //     idCard:idCard
                                                            // })
                                                            if (res1 && res1.data && res1.data[0].length) {

                                                                healthClinic({
                                                                    hisNumber: res1.data[0][0].编号,
                                                                    healthId: res.data[3][0].id
                                                                }).then(res2 => {
                                                                    // console.log(res2)
                                                                    if (res2 && res2.data && res2.data[1] && res2.data[1][0].sumCount1 == 0) {
                                                                        havenameClinic({
                                                                            patientName: name,
                                                                            cardNumber: res1.data[0][0].卡片编码,
                                                                            hisNumber: res1.data[0][0].编号,
                                                                            patientId: patientId,
                                                                            idCard: idcard
                                                                        }).then(res4 => {
                                                                            // console.log(res4)
                                                                        })
                                                                    }
                                                                    if (res2 && res2.data && res2.data[0] && res2.data[0][0].sumCount == 0) {
                                                                        healthClinicAdd({
                                                                            hisNumber: res1.data[0][0].编号,
                                                                            healthId: res.data[3][0].id
                                                                        }).then(res3 => {
                                                                            // console.log(res3)
                                                                            if (res3 && res3.code == 1) {
                                                                                this.setState({
                                                                                    visible: false
                                                                                }, () => {
                                                                                    Toast.info('健康卡创建并绑定就诊卡成功')
                                                                                })
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        })
                                        this.setState({
                                            visible4: true
                                        })
                                    }
                                })
                            } else {
                                Toast.show("该用户证件号已存在，请勿重复提交")
                            }
                        })

                    } else {
                        Toast.show("证件号和出生日期或性别不一致")
                    }

                } else {
                    Toast.show("请输入正确的身份证号")
                }
            } else {
                Toast.show("请输入正确的手机号")
            }
        } else {
            Toast.show('请将信息填写完整')
        }
    }
    toSure=async ()=>{
        const {queryreport,registrationconfirmation,docterId,id0} = this.state
        this.setState({
            visible4:false
        },()=>{
            if (queryreport){
                this.props.history.push(`/queryreport`)
            }else if(registrationconfirmation){
                this.props.history.push(`/registrationconfirmation?docterId=${docterId}&id=${id0}&id0=${getUrl('id1', this.props.location.search)}&index=${getUrl('index', this.props.location.search)}`)
            }else{
                this.props.history.push(`/healthcard`)
            }
        })
    }
    toBack=()=>{
        const {queryreport,registrationconfirmation,docterId,id0,} = this.state
        if (queryreport){
            this.props.history.push(`/queryreport`)
        }else if(registrationconfirmation){
            this.props.history.push(`/registrationconfirmation?docterId=${docterId}&id=${id0}&id0=${getUrl('id1', this.props.location.search)}&index=${getUrl('index', this.props.location.search)}`)
        }else{
            this.props.history.push(`/healthcard`)
        }
    }
    render() {
        const {name, provinces,idcard,visible,residence,sex,sexAll,visible1,options,visible2,borthDate,visible3,visible4,nationAll,nation} = this.state
        const now = new Date()
        const now1 = new Date('1800-01-01')
        return (
            <div className='healthcard2-setbox'>
                <div className='healthcard2-top'>
                    <img src={icon1} alt="" onClick={() => this.toBack()} />   电子健康卡
                </div>
                <div className='healthcard2-news'>
                    <InputItem
                        placeholder='输入姓名'
                        onChange={v=>this.handleChange('druName',v)}
                    >姓名</InputItem>
                    <InputItem
                        value='中国大陆居民身份证'
                    >证件类型</InputItem>
                    <InputItem
                        placeholder='输入证件号'
                        onChange={v=>this.handleChange('druIdcard',v)}
                        maxLength={18}
                        value={idcard}
                    >证件号</InputItem>
                    <div className='healthcard2-select'>
                        <span className='name'>出生日期</span>
                        <p>
                            {/*<input type='text' placeholder='选择出生日期'/>*/}
                            <span className={borthDate?'borth1':'borth2'}>{borthDate?borthDate:'选择出生日期'}</span>
                            <img src={icon3} onClick={() => this.setState({
                                visible2: true
                            })}/>

                            <DatePicker
                                visible={visible2}
                                onClose={() => {
                                    this.setState({
                                        visible2: false
                                    })
                                }}
                                onConfirm={value=>{
                                    this.setState({
                                       borthDate: new Date(value).getFullYear() + '-' + ((new Date(value).getMonth() + 1)>9?(new Date(value).getMonth() + 1):'0'+(new Date(value).getMonth() + 1))
                                           + '-' + ((new Date(value).getDate())>9?(new Date(value).getDate()):'0'+(new Date(value).getDate()))
                                    })
                                }}
                                // defaultValue={now}
                                max={now}
                                min={now1}
                            >
                            </DatePicker>
                        </p>

                    </div>
                    <div className='healthcard2-select'>
                        <span className='name'>性别</span>
                        <p>
                            {/*<input type='text' placeholder='选择性别' value={sex}/>*/}
                            <span className={sex?'borth1':'borth2'}>{sex?sex:'选择性别'}</span>
                            <img src={icon3} alt="" onClick={() => this.setState({
                                visible1: true
                            })} />
                            <CascadePicker
                                // title='级联选择'
                                options={sexAll}
                                visible={visible1}
                                onClose={() => {
                                    this.setState({
                                        visible1: false
                                    })
                                }}
                                // onConfirm={(val) => this.changeselect(val)}
                                // onSelect={(val) => this.chioseone(val)}
                                onConfirm={(val) => {
                                    // console.log('onConfirm', val)
                                    this.setState({
                                        sex: val[0]
                                    })
                                }}
                                // onSelect={(val) => {
                                //     // this.setState({
                                //     //     sex: val[0]
                                //     // })
                                // }}
                            ></CascadePicker>
                        </p>
                    </div>
                    <InputItem
                        placeholder='请输入手机号码'
                        onChange={v=>this.handleChange('druPhone',v)}
                        type='number'
                        maxLength={11}
                    >手机号</InputItem>
                    <div className='healthcard2-select'>
                        <span className='name'>户籍地</span>
                        <p>
                            {/*<input type='text' placeholder='选择户籍地'/>*/}
                            <span className={residence?'borth1':'borth2'}>{residence?residence:'选择户籍地'}</span>
                            <img src={icon3} alt="" onClick={() => this.setState({
                                visible: true
                            })} />
                            <CascadePicker
                                // title='级联选择'
                                options={options}
                                visible={visible}
                                onClose={() => {
                                    this.setState({
                                        visible: false
                                    })
                                }}
                                onConfirm={(val, extend) => {
                                    // console.log('onConfirm', val, extend.items)
                                    this.setState({
                                        residence:val[0]+val[1]
                                    })
                                }}
                                onSelect={(val, extend) => {
                                    let arr1=[]
                                    // console.log(options)
                                    options.map(item=>{
                                        if (item.value==val[0]){
                                            getCity({parentId:item.id}).then(res1=>{
                                                // console.log(res1)
                                                if (res1&&res1.data[0].length){
                                                    this.setState({
                                                        cities:res1.data[0]
                                                    },()=>{
                                                        res1.data[0].map(item1=>{
                                                            let params={}
                                                            params.label=item1.name
                                                            params.value=item1.name
                                                            arr1.push(params)
                                                        })
                                                        item.children=arr1
                                                        this.setState({
                                                            options:[...options]
                                                        })
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }}
                            />
                        </p>
                    </div>
                    <div className='healthcard2-select'>
                        <span className='name'>民族</span>
                        <p>
                            {/*<input type='text' placeholder='选择民族' value={nation}/>*/}
                            <span className={nation?'borth1':'borth2'}>{nation?nation:'选择民族'}</span>
                            <img src={icon3} alt="" onClick={() => this.setState({
                                visible3: true
                            })} />
                            <CascadePicker
                                // title='级联选择'
                                options={nationAll}
                                visible={visible3}
                                onClose={() => {
                                    this.setState({
                                        visible3: false
                                    })
                                }}
                                // onConfirm={(val) => this.changeselect(val)}
                                // onSelect={(val) => this.chioseone(val)}
                                onConfirm={(val) => {
                                    // console.log('onConfirm', val)
                                    this.setState({
                                        nation: val[0]
                                    })
                                }}
                                // onSelect={(val) => {
                                //     // this.setState({
                                //     //     sex: val[0]
                                //     // })
                                // }}
                            ></CascadePicker>
                        </p>
                    </div>
                    <InputItem
                        placeholder='请输入详细地址'
                        onChange={v=>this.handleChange('address',v)}
                        // type={number}
                    >地址</InputItem>
                </div>
                <div className='shenqing' onClick={()=>this.onSuccess()}>申请电子健康卡</div>
                {visible4?<div className='sl-outer'>
                    <div className='sl-inner'>
                        <img src={icon4}/>
                        <p className='sl-success'>申请成功</p>
                        <p className='sl-user'>已成功为<span>{name}</span>申领电子健康卡</p>
                        <p className='sl-okey' onClick={()=>this.toSure()}>好的</p>
                    </div>
                </div>:null}
            </div>
        )
    }
}
